import { MMSDisbursementPayer } from '../../enums/disbursementPayer';

export interface MMSDisbursementFetchResponse {
  disbursements: MMSDisbursement[];
}

export interface MMSDisbursement {
  disbursementType: string;
  payer?: MMSDisbursementPayer;
  reciever?: string;
  amount: number;
}
