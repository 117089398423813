import { assertUnreachable, PaymentFrequency } from '@fundmoreai/models';
import { getMonthlyPaymentByFrequency } from './mortgage-payment.calculator';

export const computeServicingVariableRatePaymentAmount = (
  existingAmount: number | undefined,
  existingPaymentFrequency: PaymentFrequency | undefined,
  newPaymentFrequency: PaymentFrequency | undefined,
): number | null => {
  if (!existingAmount || !existingPaymentFrequency || !newPaymentFrequency) {
    return null;
  }
  switch (existingPaymentFrequency) {
    case PaymentFrequency.DAILY:
    case PaymentFrequency.WEEKLY:
    case PaymentFrequency.ACCELERATED_WEEKLY:
    case PaymentFrequency.BI_WEEKLY:
    case PaymentFrequency.ACCELERATED_BI_WEEKLY:
    case PaymentFrequency.SEMI_MONTHLY:
    case PaymentFrequency.QUARTERLY:
    case PaymentFrequency.SEMI_ANNUALLY:
    case PaymentFrequency.ANNUALLY:
      const monthlyPayment = getMonthlyPaymentByFrequency(existingPaymentFrequency, existingAmount);
      return computeServicingVariableRatePaymentAmountForExistingMonthlyFrequency(
        monthlyPayment,
        newPaymentFrequency,
      );
    case PaymentFrequency.MONTHLY:
      return computeServicingVariableRatePaymentAmountForExistingMonthlyFrequency(
        existingAmount,
        newPaymentFrequency,
      );
    default:
      assertUnreachable(existingPaymentFrequency as never, 'Unhandled existingPaymentFrequency');
      return null;
  }
};

const computeServicingVariableRatePaymentAmountForExistingMonthlyFrequency = (
  existingAmount: number,
  newPaymentFrequency: PaymentFrequency,
) => {
  switch (newPaymentFrequency) {
    case PaymentFrequency.DAILY:
      return (existingAmount * 12) / 365;
    case PaymentFrequency.WEEKLY:
      return (existingAmount * 12) / 52;
    case PaymentFrequency.ACCELERATED_WEEKLY:
      return (existingAmount * 13) / 52;
    case PaymentFrequency.BI_WEEKLY:
      return (existingAmount * 12) / 26;
    case PaymentFrequency.ACCELERATED_BI_WEEKLY:
      return (existingAmount * 13) / 26;
    case PaymentFrequency.SEMI_MONTHLY:
      return existingAmount / 2;
    case PaymentFrequency.MONTHLY:
      return existingAmount;
    case PaymentFrequency.QUARTERLY:
      return existingAmount * 3;
    case PaymentFrequency.SEMI_ANNUALLY:
      return existingAmount * 6;
    case PaymentFrequency.ANNUALLY:
      return existingAmount * 12;
    default:
      assertUnreachable(newPaymentFrequency as never, 'Unhandled newPaymentFrequency');
      return null;
  }
};
