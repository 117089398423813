import { Mortgage, PaymentFrequency, RepaymentType } from '@fundmoreai/models';
import { compoundingMap, paymentFrequencyMap } from './mortgage-payment.calculator';

export type PrincipalInterestMortgageInput = Pick<
  Mortgage,
  | 'firstRegularPaymentDate'
  | 'maturityDate'
  | 'totalLoanAmount'
  | 'netRate'
  | 'paymentAmount'
  | 'paymentFrequency'
  | 'compounding'
  | 'amortizationMonths'
  | 'repaymentType'
  | 'termMonths'
  | 'closingDate'
  | 'interestAdjustmentDate'
>;

export interface PrincipalInterestMortgageOutput {
  principal: number;
  interest: number;
}

export function computePrincipalAndInterest(
  mortgage: PrincipalInterestMortgageInput | undefined,
): PrincipalInterestMortgageOutput {
  if (mortgage?.repaymentType === RepaymentType.INTEREST_ONLY) {
    return {
      principal: 0,
      interest: mortgage.paymentAmount ?? 0,
    };
  }
  let interest = 0;
  let principal = 0;
  const balance = mortgage?.totalLoanAmount;
  const interestRate = mortgage && mortgage.netRate != null ? mortgage.netRate / 100 : 0;
  const paymentFrequency = mortgage?.paymentFrequency;
  const compounding = mortgage?.compounding;
  const paymentAmount = mortgage?.paymentAmount;
  const balanceAfterPrincipal = balance != null ? Math.round(balance * 100) / 100 : 0;

  const frequencyNumber = paymentFrequency
    ? paymentFrequencyMap.get(paymentFrequency)
    : paymentFrequencyMap.get(PaymentFrequency.MONTHLY);
  const compoundingNumber = compounding ? compoundingMap.get(compounding) : 2;

  const rate =
    compoundingNumber != null && frequencyNumber != null
      ? (1 + interestRate / compoundingNumber) ** (compoundingNumber / frequencyNumber) - 1
      : 0;

  interest = Math.round(balanceAfterPrincipal * rate * 100) / 100;
  principal = Math.round(((paymentAmount ?? 0) - interest) * 100) / 100;

  return {
    principal,
    interest,
  };
}
