import {
  DatesProcessingType,
  Mortgage,
  CalculationAutomationSettings,
  PaymentFrequency,
} from '@fundmoreai/models';
import { addDays, addMonths, addYears, setDate, startOfMonth, startOfYear } from 'date-fns';
import { FundmoreCalculator } from '..';
import {
  fromLocalDateToShortISODate,
  fromShortISODateToMiddleLocalDate,
  toMiddleDate,
} from '@fundmoreai/helpers';

function getMiddleOfCurrentYear(startingDateForFirstPaymentCalculation: Date): Date {
  return toMiddleDate(new Date(startingDateForFirstPaymentCalculation.getFullYear(), 6, 2));
}

function getStartOfNextYear(startingDateForFirstPaymentCalculation: Date): Date {
  return toMiddleDate(startOfYear(addYears(startingDateForFirstPaymentCalculation, 1)));
}

export interface ForceDateRecalculationParams {
  forceIADRecalculation: boolean | null;
  forceFirstRegularPaymentDateRecalculation: boolean | null;
  forceMaturityDateRecalculation: boolean | null;
}

export function defaultMortgageDate(
  reqMortgage: Mortgage,
  calculationAutomationSettings: CalculationAutomationSettings,
  iadByFpdEnabled: boolean,
  forceDateRecalculationParams: ForceDateRecalculationParams | null = null,
) {
  const requestedMortgage = { ...reqMortgage };
  const paymentFrequency = requestedMortgage?.paymentFrequency;

  if (requestedMortgage.closingDate) {
    const closingDate: Date = fromShortISODateToMiddleLocalDate(requestedMortgage.closingDate);

    // Interest Adjustment Date automatic calculation
    if (
      (!requestedMortgage.interestAdjustmentDate ||
        forceDateRecalculationParams?.forceIADRecalculation) &&
      (!calculationAutomationSettings ||
        !calculationAutomationSettings[reqMortgage.id]?.isInterestAdjustmentDateDisabled)
    ) {
      requestedMortgage.interestAdjustmentDate =
        FundmoreCalculator.interestAdjustmentDate(requestedMortgage, DatesProcessingType.Default) ??
        null;
    }

    // First Regular Payment Payment automatic calculation
    if (
      !requestedMortgage.firstRegularPaymentDate ||
      forceDateRecalculationParams?.forceFirstRegularPaymentDateRecalculation
    ) {
      let firstRegularPaymentDate: Date | null = null;
      const startingDateForFirstPaymentCalculation = !requestedMortgage.interestAdjustmentDate
        ? closingDate
        : fromShortISODateToMiddleLocalDate(requestedMortgage.interestAdjustmentDate);

      switch (paymentFrequency) {
        case PaymentFrequency.MONTHLY:
          firstRegularPaymentDate = addMonths(startingDateForFirstPaymentCalculation, 1);
          break;
        case PaymentFrequency.SEMI_MONTHLY:
          firstRegularPaymentDate =
            startingDateForFirstPaymentCalculation.getDate() < 15
              ? setDate(startingDateForFirstPaymentCalculation, 15)
              : toMiddleDate(startOfMonth(addMonths(startingDateForFirstPaymentCalculation, 1)));
          break;
        case PaymentFrequency.BI_WEEKLY:
        case PaymentFrequency.ACCELERATED_BI_WEEKLY:
          firstRegularPaymentDate = addDays(startingDateForFirstPaymentCalculation, 14);
          break;
        case PaymentFrequency.WEEKLY:
        case PaymentFrequency.ACCELERATED_WEEKLY:
          firstRegularPaymentDate = addDays(startingDateForFirstPaymentCalculation, 7);
          break;
        case PaymentFrequency.DAILY:
          firstRegularPaymentDate = addDays(startingDateForFirstPaymentCalculation, 1);
          break;
        case PaymentFrequency.QUARTERLY:
          firstRegularPaymentDate = addMonths(startingDateForFirstPaymentCalculation, 3);
          break;
        case PaymentFrequency.ANNUALLY:
          firstRegularPaymentDate = addYears(startingDateForFirstPaymentCalculation, 1);
          break;
        case PaymentFrequency.SEMI_ANNUALLY:
          {
            const isMonthBeforeJuly = startingDateForFirstPaymentCalculation.getMonth() < 6;
            const isBeforeJulySecond =
              startingDateForFirstPaymentCalculation.getMonth() === 6 &&
              startingDateForFirstPaymentCalculation.getDate() < 2;
            const isBeforeMiddleOfYear = isMonthBeforeJuly || isBeforeJulySecond;
            firstRegularPaymentDate = isBeforeMiddleOfYear
              ? getMiddleOfCurrentYear(startingDateForFirstPaymentCalculation)
              : getStartOfNextYear(startingDateForFirstPaymentCalculation);
          }
          break;
        default:
          break;
      }

      requestedMortgage.firstRegularPaymentDate =
        fromLocalDateToShortISODate(firstRegularPaymentDate) ?? null;
    }

    // Interest Adjustment Date automatic calculation by First Payment Date
    if (
      iadByFpdEnabled &&
      requestedMortgage.firstRegularPaymentDate &&
      (!calculationAutomationSettings ||
        !calculationAutomationSettings[reqMortgage.id]?.isInterestAdjustmentDateDisabled)
    ) {
      requestedMortgage.interestAdjustmentDate =
        FundmoreCalculator.interestAdjustmentDateByFirstPaymentDate(requestedMortgage) ?? null;
    }

    // Maturity Date automatic recalculation
    if (
      (!requestedMortgage.maturityDate ||
        forceDateRecalculationParams?.forceMaturityDateRecalculation) &&
      (!calculationAutomationSettings ||
        !calculationAutomationSettings[reqMortgage.id]?.isMaturityDateDisabled)
    ) {
      if (calculationAutomationSettings?.ANY_MORTGAGE?.excludeExtraPaymentInTerm) {
        requestedMortgage.maturityDate =
          FundmoreCalculator.computeMaturityDateWithoutExtraPayment(requestedMortgage) ?? null;
      } else {
        requestedMortgage.maturityDate =
          FundmoreCalculator.computeMaturityDate(requestedMortgage) ?? null;
      }
    }
  }

  return requestedMortgage;
}

export function calvertMortgageDates(
  reqMortgage: Mortgage,
  calculationAutomationSettings: CalculationAutomationSettings,
  forceDateRecalculationParams: ForceDateRecalculationParams | null = null,
): Mortgage {
  const requestedMortgage = Object.assign({}, reqMortgage);
  if (requestedMortgage.closingDate) {
    // Interest Adjustment Date automatic calculation
    if (
      (!requestedMortgage.interestAdjustmentDate ||
        forceDateRecalculationParams?.forceIADRecalculation) &&
      (!calculationAutomationSettings ||
        !calculationAutomationSettings[reqMortgage.id]?.isInterestAdjustmentDateDisabled)
    ) {
      requestedMortgage.interestAdjustmentDate =
        FundmoreCalculator.interestAdjustmentDate(requestedMortgage, DatesProcessingType.Calvert) ??
        null;
    }

    // First Regular Payment Payment automatic calculation
    if (
      !requestedMortgage.firstRegularPaymentDate ||
      forceDateRecalculationParams?.forceFirstRegularPaymentDateRecalculation
    ) {
      const firstRegularPaymentDate = addMonths(
        fromShortISODateToMiddleLocalDate(requestedMortgage.closingDate),
        1,
      );
      requestedMortgage.firstRegularPaymentDate =
        fromLocalDateToShortISODate(firstRegularPaymentDate) ?? null;
    }

    // Maturity Date automatic recalculation
    if (
      (!requestedMortgage.maturityDate ||
        forceDateRecalculationParams?.forceMaturityDateRecalculation) &&
      requestedMortgage.termMonths &&
      (!calculationAutomationSettings ||
        !calculationAutomationSettings[reqMortgage.id]?.isMaturityDateDisabled)
    ) {
      requestedMortgage.maturityDate =
        FundmoreCalculator.computeMaturityDate(requestedMortgage) ?? null;
    }
  }
  return requestedMortgage;
}

export function addDefaultConditionDatesIfMissing(
  mortgage: Mortgage,
  processingDates: DatesProcessingType | undefined,
  calculationAutomationSettings: CalculationAutomationSettings,
  iadByFpdEnabled: boolean,
  forceDateRecalculationParams: ForceDateRecalculationParams | null = null,
) {
  if (processingDates && processingDates === DatesProcessingType.Calvert) {
    return calvertMortgageDates(
      mortgage,
      calculationAutomationSettings,
      forceDateRecalculationParams,
    );
  } else {
    return defaultMortgageDate(
      mortgage,
      calculationAutomationSettings,
      iadByFpdEnabled,
      forceDateRecalculationParams,
    );
  }
}

export function isSemiMonthlyOrAnnualyDate(
  date: Date,
  paymentFrequency: PaymentFrequency | undefined | null,
): boolean {
  if (!date || !paymentFrequency) {
    return true;
  }
  const day = new Date(date).getDate();

  if (paymentFrequency === PaymentFrequency.SEMI_MONTHLY) {
    return day === 1 || day === 15;
  }

  if (paymentFrequency === PaymentFrequency.SEMI_ANNUALLY) {
    const month = new Date(date).getMonth();
    return (day === 1 && month === 0) || (day === 2 && month === 6);
  }

  return true;
}
