import { InsuranceQuote, CGApplicationAdd, CGApplicationUpdate } from '@fundmoreai/models';
import { CmhcApplicationOptions, BorrowerBankingInfo } from './cmhc/cmhc.model';
import { SagenApplicationAdd, SagenApplicationUpdate } from './sagen/sagen-application.model';

export class MortgageInsuranceApplicationCancel {
  static readonly type = '@mortgageInsurance.applicationCancel';
  constructor(public insuranceQuote: InsuranceQuote) {}
}

export class MortgageInsuranceInquireStatus {
  static readonly type = '@mortgageInsurance.inquireStatus';
  constructor(public insuranceQuote: InsuranceQuote) {}
}

export class MortgageInsuranceInquireTestApproveStatus {
  static readonly type = '@mortgageInsurance.inquireTestApproveStatus';
  constructor(public insuranceQuote: InsuranceQuote) {}
}

export class MortgageInsuranceDownloadCertifax {
  static readonly type = '@mortgageInsurance.downloadCertifax';
  constructor(public insuranceQuote: InsuranceQuote) {}
}

export class MortgageInsuranceSagenApplicationAdd {
  static readonly type = '@mortgageInsurance.SagenApplicationAdd';
  constructor(public applicationId: string, public sagenApplicationAdd: SagenApplicationAdd) {}
}

export class MortgageInsuranceSagenApplicationUpdate {
  static readonly type = '@mortgageInsurance.SagenApplicationUpdate';
  constructor(public quoteId: string, public sagenApplicationUpdate: SagenApplicationUpdate) {}
}

export class MortgageInsuranceCmhcSubmitApplication {
  static readonly type = '@mortgageInsurance.CmhcSubmitApplication';
  constructor(
    public applicationId: string,
    public applicationOptions: CmhcApplicationOptions,
    public borrowersBankingInfos: BorrowerBankingInfo[],
  ) {}
}

export class MortgageInsuranceCmhcUpdateApplication {
  static readonly type = '@mortgageInsurance.CmhcUpdateApplication';
  constructor(
    public insuranceQuoteId: string,
    public applicationOptions: CmhcApplicationOptions,
    public borrowersBankingInfos: BorrowerBankingInfo[],
  ) {}
}

export class MortgageInsuranceApplicationQuotesGet {
  static readonly type = '@mortgageInsurance.ApplicationQuotesGet';
  constructor(public applicationId: string) {}
}

export class MortgageInsuranceCGApplicationAdd {
  static readonly type = '@mortgageInsurance.CGApplicationAdd';
  constructor(public applicationId: string, public cgApplicationAdd: CGApplicationAdd) {}
}

export class MortgageInsuranceCGApplicationUpdate {
  static readonly type = '@mortgageInsurance.CGApplicationUpdate';
  constructor(public quoteId: string, public cgApplicationUpdate: CGApplicationUpdate) {}
}

export class MortgageInsuranceCGApplicationInquire {
  static readonly type = '@mortgageInsurance.CGApplicationInquire';
  constructor(public quoteId: string) {}
}

export class MortgageInsuranceCGApplicationInquireTestApprove {
  static readonly type = '@mortgageInsurance.CGApplicationInquireTestApprove';
  constructor(public quoteId: string) {}
}
export class MortgageInsuranceCGApplicationCancel {
  static readonly type = '@mortgageInsurance.CGApplicationCancel';
  constructor(public quoteId: string) {}
}

export class MortgageInsuranceCGApplicationSetQuote {
  static readonly type = '@mortgageInsurance.CGApplicationSetQuote';
  constructor(public insuranceQuote: InsuranceQuote) {}
}

export class MortgageInsuranceCGApplicationReset {
  static readonly type = '@mortgageInsurance.CGApplicationReset';
}

export class MortgageInsuranceCGApplicationMappingsReview {
  static readonly type = '@mortgageInsurance.CGApplicationMappingsReview';
  constructor(public applicationId: string) {}
}
