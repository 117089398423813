import { InsurancePremiumProgram, InsuranceStatus } from '../enums';

export interface InsurancePremium {
  insurer: Insurer;
  ltvRatioStart: number;
  ltvRatioEnd: number;
  rate: number;
  topUpRate: number;
  programCode: InsurancePremiumProgram;
  isStandardProgram: boolean;
}

export interface Insurer {
  id: string;
  name: string;
}

export interface Insurance {
  insurancePremium: number;
  insuranceAmount: number;
}

export const InsuranceStatusRecord: Record<InsuranceStatus, string> = {
  [InsuranceStatus.APPROVED]: 'Approved',
  [InsuranceStatus.CANCEL_CONFIRMATION]: 'Cancel confirmation',
  [InsuranceStatus.DECLINED]: 'Declined',
  [InsuranceStatus.ERROR]: 'Error',
  [InsuranceStatus.RECEIVED]: 'Received',
  [InsuranceStatus.REFERRED_TO_UNDERWRITER]: 'Referred to Underwriter',
  [InsuranceStatus.SUBMITTED]: 'Submitted',
  [InsuranceStatus.MANUAL_UNDER_WRITING_REQUIRED]: 'Manual Underwriting Required',
  [InsuranceStatus.INSURABLE]: 'Insurable',
  [InsuranceStatus.NON_INSURABLE]: 'Non-insurable',
  [InsuranceStatus.FINALIZED]: 'Finalized',
};

export const InsurancePremiumProgramRecord: Record<InsurancePremiumProgram, string> = {
  [InsurancePremiumProgram.CG_STANDARD_PREMIUMS]: 'Standard Premiums',
  [InsurancePremiumProgram.CG_FLEX_95_ADVANTAGE]: 'Flex 95 Advantage',
  [InsurancePremiumProgram.CG_LOW_DOC_ADVANTAGE]: 'Low Doc Advantage',
  [InsurancePremiumProgram.CG_RENTAL_ADVANTAGE]: 'Rental Advantage',
  [InsurancePremiumProgram.CG_LOW_RATIO_UNINSURED]: 'Low Ratio Underwriting',
  [InsurancePremiumProgram.CG_DOWNPAYMENT_ADVANTAGE]: 'Down Payment Advantage',
  [InsurancePremiumProgram.CG_LIFESTYLE_ADVANTAGE]: 'Lifestyle Advantage',
  [InsurancePremiumProgram.CG_MAPLE_LEAF_ADVANTAGE]: 'Maple Leaf Advantage',
  [InsurancePremiumProgram.CG_PROGRESS_DRAW_ADVANTAGE]: 'Progress Draw Advantage',
  [InsurancePremiumProgram.CG_PURCHASE_ADVANTAGE_PLUS]: 'Purchase Advantage Plus',
  [InsurancePremiumProgram.CG_PORTABLE_ADVANTAGE]: 'Portable Advantage',
  [InsurancePremiumProgram.SAGEN_STANDARD_PREMIUMS]: 'Standard',
  [InsurancePremiumProgram.SAGEN_BUSINESS_FOR_SELF_ALT_A]: 'Business for Self (Alt A)',
  [InsurancePremiumProgram.SAGEN_BORROWED_DOWN_PAYMENT]: 'Borrowed Down Payment',
  [InsurancePremiumProgram.SAGEN_VACATION_SECONDARY_HOME_TYPE_B]:
    'Vacation/Secondary Homes (Type B)',
  [InsurancePremiumProgram.SAGEN_INVESTMENT_PROPERTY]: 'Investment Property',
  [InsurancePremiumProgram.SAGEN_LOW_RATIO_UNINSURED]: 'Low Ratio Underwriting',
  [InsurancePremiumProgram.SAGEN_HOMEBUYER_95]: 'Homebuyer 95',
  [InsurancePremiumProgram.SAGEN_NEW_TO_CANADA]: 'New to Canada',
  [InsurancePremiumProgram.SAGEN_FAMILY_PLAN]: 'Family Plan',
  [InsurancePremiumProgram.SAGEN_VACATION_SECONDARY_HOMES_TYPE_A]:
    'Vacation/Secondary Homes (Type A)',
  [InsurancePremiumProgram.SAGEN_PROGRESS_ADVANCE]: 'Progress Advance',
  [InsurancePremiumProgram.SAGEN_PURCHASE_PLUS_IMPROVEMENTS]: 'Purchase Plus Improvements',
  [InsurancePremiumProgram.SAGEN_SECOND_MORTGAGE]: 'Second Mortgage',
  [InsurancePremiumProgram.SAGEN_PORTABILITY_FEATURE]: 'Portability Feature',
  [InsurancePremiumProgram.CMHC_STANDARD_PREMIUMS]: 'Standard Premiums',
  [InsurancePremiumProgram.CMHC_LOW_RATIO_UNINSURED]: 'Low Ratio Underwriting',
};

export interface CertifaxModel {
  name: string;
  content: unknown; // Buffer, not using Buffer type to avoid importing node types
}
