import { FundmoreNotification } from '../shared';

export class MuteNotifications {
  static readonly type = '@notification.mute';
  constructor(public mute: boolean) {}
}

export class UnreadOnlyNotifications {
  static readonly type = '@notification.UnreadOnlyNotifications';
  constructor(public unread: boolean) {}
}
export class ReadAllNotifications {
  static readonly type = '@notification.readAll';
}

export class ReadNotification {
  static readonly type = '@notification.ReadNotification';
  constructor(public notification: FundmoreNotification) {}
}

export class ClearNotifications {
  static readonly type = '@notification.ClearNotifications';
}

export class UnreadNotification {
  static readonly type = '@notification.UnreadNotification';
  constructor(public notification: FundmoreNotification) {}
}

export class ClearNotification {
  static readonly type = '@notification.ClearNotification';
  constructor(public notification: FundmoreNotification) {}
}

export class ClearNotificationGroup {
  static readonly type = '@notification.ClearNotificationGroup';
  constructor(public groupKey: string) {}
}

export class ReadNotificationGroup {
  static readonly type = '@notification.ReadNotificationGroup';
  constructor(public groupKey: string) {}
}

export class UnreadNotificationGroup {
  static readonly type = '@notification.UnreadNotificationGroup';
  constructor(public groupKey: string) {}
}
