import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { SetSectionOpened } from './section-open.actions';

export interface SectionOpenStateModel {
  [key: string]: { isOpen: boolean };
}

@State<SectionOpenStateModel>({
  name: 'sectionOpen',
  defaults: {},
})
@Injectable()
export class SectionOpenedState {
  static isOpen(key: string) {
    return createSelector([SectionOpenedState], (state: SectionOpenStateModel) => {
      return state[key]?.isOpen ?? false;
    });
  }

  @Action(SetSectionOpened)
  setSectionOpened(ctx: StateContext<SectionOpenStateModel>, action: SetSectionOpened) {
    const state = ctx.getState();

    ctx.patchState({ ...state, [action.key]: { isOpen: action.enabled } });
  }
}
