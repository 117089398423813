import { Limit } from './conditions';
import { CreditTier } from './creditTier';
import { RateMatrixTerm } from './rateMatrixTerm';

export interface RateMatrix {
  id: string;
  tenantId: string;
  advancedProductId: string;
  creditTierId?: string;
  notes: string;
  conditions: Limit[];
  RateMatrixTerms?: RateMatrixTerm[];
  CreditTier?: CreditTier;
}
