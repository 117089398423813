import { Fee } from '@fundmoreai/models';

export function computeTotalFees(fees: Fee[]): number {
  let total = 0.0;
  fees.forEach((fee) => {
    total += fee.amount ?? 0;
  });

  return total;
}
