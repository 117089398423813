import {
  AggregatesLiabilityInput,
  AggregatesMortgageInput,
  ApplicationAggregate,
} from '@fundmoreai/models';
import { injectable } from 'tsyringe';

export type ComputedApplicationAggregate = Pick<
  ApplicationAggregate,
  | 'mortgageSecuredCurrentInput'
  | 'mortgageSecuredFutureInput'
  | 'computedMortgageSecuredCurrent'
  | 'computedMortgageSecuredFuture'
  | 'computedNonMortgageCashSecuredCurrent'
  | 'computedNonMortgageCashSecuredFuture'
  | 'nonMortgageCashSecuredCurrentInput'
  | 'nonMortgageCashSecuredFutureInput'
  | 'nonMortgageLiabilitiesCurrentInput'
  | 'nonMortgageLiabilitiesFutureInput'
  | 'computedNonMortgageLiabilitiesCurrent'
  | 'computedNonMortgageLiabilitiesFuture'
  | 'nonMortgageSecuredCurrentInput'
  | 'nonMortgageSecuredFutureInput'
  | 'computedNonMortgageSecuredCurrent'
  | 'computedNonMortgageSecuredFuture'
  | 'nonMortgageUnsecuredCurrentInput'
  | 'nonMortgageUnsecuredFutureInput'
  | 'computedNonMortgageUnsecuredCurrent'
  | 'computedNonMortgageUnsecuredFuture'
  | 'totalConnectionCurrentInput'
  | 'totalConnectionFutureInput'
  | 'computedTotalConnectionCurrent'
  | 'computedTotalConnectionFuture'
  | 'totalRetailAggregatesCurrentInput'
  | 'totalRetailAggregatesFutureInput'
  | 'computedRetailAggregatesCurrent'
  | 'computedRetailAggregatesFuture'
  | 'bridgeSecuredCurrentInput'
  | 'bridgeSecuredFutureInput'
  | 'computedBridgeSecuredCurrent'
  | 'computedBridgeSecuredFuture'
>;

export interface ApplicationAggregatesCalculationInput {
  liabilities: AggregatesLiabilityInput[];
  mortgages: AggregatesMortgageInput[];
  businessBankingRelationCurrent: number | undefined;
  businessBankingRelationFuture: number | undefined;
}

@injectable()
export class ApplicationAggregatesCalculationModel
  implements ApplicationAggregatesCalculationInput
{
  liabilities: AggregatesLiabilityInput[];
  mortgages: AggregatesMortgageInput[];
  businessBankingRelationCurrent: number | undefined;
  businessBankingRelationFuture: number | undefined;
}
