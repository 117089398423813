import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { DownPayment } from '../shared';
import { EMPTY, of } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { DownpaymentsService } from './downpayments.service';
import { Applicant, FinancialAsset } from '../shared/model';
import {
  FlipAddApplicationDownPayments,
  FlipDeleteDownPayment,
  FlipUpdateDownPayment,
} from './flip/flip.state.action';
import { RecomputeLoanAmountOnRequestedMortgage } from './mortgages-v2/mortgages-v2.actions';
import { FinancialAssetsState } from '../features/application/widgets/networth/financial-assets.state';
import { LoadingEnd, LoadingStart } from '../core/loading.state';
import {
  AddDownPaymentLocal,
  AddDownPaymentsBulk,
  AddLocalDownPayment,
  RemoveAssetDownPayment,
  RemoveDownPayment,
  UpdateAssetDownPayment,
  UpsertDownPayment,
  SetDownPayments,
  AddBulkDownPaymentsAndComputeFlip,
  DownPaymentStateModel,
  DownPaymentWithAsset,
} from './downpayments.actions';
import { ApplicationResetState } from '../shared/state.model';
import { FlipComputeAnalysisOnDownPaymentChange } from './flip/flip.state';
import { ApplicantsState } from './applicants.state';
import { AppFeaturesState } from '../shared/app-features.state';

const defaults = { downPayments: [] };

@State<DownPaymentStateModel>({
  name: 'downPayments',
  defaults: { ...defaults },
})
@Injectable()
export class DownPaymentState {
  constructor(private downPaymentServices: DownpaymentsService, private store: Store) {}

  @Selector()
  static downPaymentsList(state: DownPaymentStateModel) {
    return state.downPayments;
  }

  @Selector([DownPaymentState.downPaymentsList, FinancialAssetsState.financialAssetsList])
  static downPayments(
    downPayments: DownPayment[],
    financialAssets: FinancialAsset[],
  ): DownPaymentWithAsset[] {
    return downPayments.map((downPayment) => {
      return {
        ...downPayment,
        financialAsset: financialAssets.find((asset) => asset.id === downPayment.financialAssetId),
      };
    });
  }

  static totalDownPayment(applicantId?: string) {
    return createSelector(
      [DownPaymentState.downPayments, ApplicantsState.applicantsListExcludingThirdParty],
      (downPayments: DownPayment[], applicants: Applicant[] | undefined): number => {
        let totalDownPayment = downPayments.reduce(
          (sum, downPayment) =>
            sum + (downPayment && downPayment.amount ? downPayment.amount : 0.0),
          0.0,
        );

        if (applicantId && applicants?.length) {
          totalDownPayment = totalDownPayment / applicants.length;
        }

        return totalDownPayment;
      },
    );
  }

  @Action(AddLocalDownPayment)
  public addLocalDownPayment(
    ctx: StateContext<DownPaymentStateModel>,
    { applicationId, downPayment, options }: AddLocalDownPayment,
  ) {
    return this.createDownPayment(ctx, applicationId, [
      Object.assign({ ...downPayment, id: undefined, updatedAt: undefined }),
    ]).pipe(
      switchMap((newDownPayments) => {
        if (!options?.updateFlip) {
          return of(newDownPayments);
        }
        return ctx
          .dispatch(new FlipAddApplicationDownPayments(newDownPayments))
          .pipe(map(() => newDownPayments));
      }),
    );
  }

  @Action(RemoveDownPayment)
  public removeDownPayment(
    ctx: StateContext<DownPaymentStateModel>,
    { applicationId, downPayment, options }: RemoveDownPayment,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.downPaymentServices.deleteDownPayment(applicationId, downPayment.id).pipe(
      tap(() => {
        const state = ctx.getState();
        ctx.patchState({
          downPayments: [...state.downPayments.filter((d) => d.id !== downPayment.id)],
        });
      }),
      switchMap(() => {
        if (!options?.updateFlip) {
          return of(undefined);
        }
        return ctx.dispatch(new FlipDeleteDownPayment(downPayment.id));
      }),
      switchMap(() => {
        const automationDisabled = this.store.selectSnapshot(
          AppFeaturesState.isAutomaticLoanAmountCalculationDisabled,
        );

        if (automationDisabled) {
          return EMPTY;
        }
        return ctx.dispatch(new RecomputeLoanAmountOnRequestedMortgage());
      }),

      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(RemoveAssetDownPayment)
  public removeAssetDownPayment(
    ctx: StateContext<DownPaymentStateModel>,
    { assetId }: RemoveAssetDownPayment,
  ) {
    const downPayment = this.getAssetDownPayment(ctx, assetId);

    if (!downPayment) {
      return EMPTY;
    }

    const state = ctx.getState();

    ctx.patchState({
      downPayments: [...state.downPayments.filter((d) => d.id !== downPayment.id)],
    });

    return ctx.dispatch(new FlipDeleteDownPayment(downPayment.id));
  }

  @Action(AddDownPaymentLocal)
  public addDownPaymentLocal(
    ctx: StateContext<DownPaymentStateModel>,
    { downPayment }: AddDownPaymentLocal,
  ) {
    const state = ctx.getState();
    ctx.patchState({ downPayments: [...state.downPayments, downPayment] });

    return ctx.dispatch(new FlipAddApplicationDownPayments([downPayment]));
  }

  @Action(UpdateAssetDownPayment)
  public updateAssetDownPayment(ctx: StateContext<DownPaymentStateModel>, asset: FinancialAsset) {
    const downPayment = this.getAssetDownPayment(ctx, asset.id);

    if (!downPayment) {
      return;
    }

    const state = ctx.getState();
    const updatedDownPayment: DownPayment = Object.assign({}, downPayment, {
      source: asset.asset,
      description: asset.description,
      amount: asset.value,
    });

    ctx.patchState({
      downPayments: [
        ...state.downPayments.filter((d) => d.id !== updatedDownPayment.id),
        updatedDownPayment,
      ],
    });

    return ctx.dispatch(new FlipUpdateDownPayment(updatedDownPayment.id, updatedDownPayment));
  }

  @Action(AddDownPaymentsBulk)
  public addDownPaymentsBulk(
    ctx: StateContext<DownPaymentStateModel>,
    { applicationId, downPayments }: AddDownPaymentsBulk,
  ) {
    const newDownPaymentModels = downPayments.map((downpayment) =>
      Object.assign({ ...downpayment, id: undefined, updatedAt: undefined }),
    );
    return this.createDownPayment(ctx, applicationId, newDownPaymentModels);
  }

  @Action(UpsertDownPayment)
  public upsertDownPayment(
    ctx: StateContext<DownPaymentStateModel>,
    { applicationId, downPayment, options }: UpsertDownPayment,
  ) {
    if (!downPayment.id) {
      return EMPTY;
    }

    return this.updateDownPayment(ctx, applicationId, downPayment.id, downPayment).pipe(
      switchMap(() => {
        if (!options?.updateFlip) {
          return of(undefined);
        }
        return ctx.dispatch(new FlipUpdateDownPayment(downPayment.id, downPayment));
      }),
    );
  }

  @Action(AddBulkDownPaymentsAndComputeFlip) bulkAndCompute(
    ctx: StateContext<DownPaymentStateModel>,
    { applicationId, downPayments }: AddBulkDownPaymentsAndComputeFlip,
  ) {
    return this.createDownPayment(ctx, applicationId, downPayments).pipe(
      switchMap((newDownPayments) => {
        const updatedDownPayments = [...downPayments, ...newDownPayments];
        return ctx
          .dispatch(new FlipComputeAnalysisOnDownPaymentChange(updatedDownPayments))
          .pipe(map(() => newDownPayments));
      }),
    );
  }

  @Action(SetDownPayments) public setDownPayments(
    ctx: StateContext<DownPaymentStateModel>,
    { downPayments }: SetDownPayments,
  ) {
    ctx.patchState({ downPayments });
  }

  @Action(ApplicationResetState) resetDownPayments(ctx: StateContext<DownPaymentStateModel>) {
    ctx.patchState({ ...defaults });
  }

  private updateDownPayment(
    ctx: StateContext<DownPaymentStateModel>,
    applicationId: string,
    downPaymentId: string,
    downPayment: DownPayment,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.downPaymentServices
      .patchDownPayment(applicationId, downPaymentId, downPayment)
      .pipe(
        tap(() => {
          const state = ctx.getState();
          ctx.patchState({
            downPayments: [
              ...state.downPayments.filter((d) => d.id !== downPaymentId),
              downPayment,
            ],
          });
        }),
        switchMap(() => {
          const automationDisabled = this.store.selectSnapshot(
            AppFeaturesState.isAutomaticLoanAmountCalculationDisabled,
          );

          if (automationDisabled) {
            return EMPTY;
          }
          return ctx.dispatch(new RecomputeLoanAmountOnRequestedMortgage());
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  private createDownPayment(
    ctx: StateContext<DownPaymentStateModel>,
    applicationId: string,
    downPayment: DownPayment[],
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.downPaymentServices.postDownPayments(applicationId, downPayment).pipe(
      tap((downPayments) => {
        const state = ctx.getState();
        ctx.patchState({ downPayments: [...state.downPayments, ...downPayments] });
      }),
      switchMap((downPayments) => {
        const automationDisabled = this.store.selectSnapshot(
          AppFeaturesState.isAutomaticLoanAmountCalculationDisabled,
        );

        if (automationDisabled) {
          return of(downPayments);
        }

        return ctx
          .dispatch(new RecomputeLoanAmountOnRequestedMortgage())
          .pipe(map(() => downPayments));
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  private getAssetDownPayment(ctx: StateContext<DownPaymentStateModel>, assetId: string) {
    return Object.values(ctx.getState().downPayments).find((d) => d.financialAssetId === assetId);
  }
}
