import { Pipe, PipeTransform } from '@angular/core';
import { CustomerType } from '@fundmoreai/models';

@Pipe({
  name: 'isThirdParty',
  standalone: true,
})
export class IsThirdPartyPipe implements PipeTransform {
  static IsThirdParty(customerType: CustomerType | null): boolean {
    switch (customerType) {
      case CustomerType.CUSTOMER:
      case CustomerType.COMPANY:
      case CustomerType.CO_BORROWER:
      case CustomerType.GUARANTOR:
        return false;
      default:
        return true;
    }
  }

  transform(customerType: CustomerType): boolean {
    return IsThirdPartyPipe.IsThirdParty(customerType);
  }
}
