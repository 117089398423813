import {
  Job,
  ApplicationOtherIncome,
  Application,
  IncomePeriod,
  Property,
  AddressExpanded,
} from '@fundmoreai/models';
import {
  EmployedAndSelfEmployedIncome,
  OtherIncome,
  EmployedIncome,
  SelfEmployedIncome,
} from './income.model';

export class AddEmployedIncome {
  static readonly type = '@income.AddEmployedIncome';

  constructor(public applicationId: string, public job: Job) {}
}

export class AddOtherIncome {
  static readonly type = '@income.AddOtherIncome';

  constructor(public applicationId: string, public otherIncome: ApplicationOtherIncome) {}
}

export class ChangeIncomeTypeToOtherIncome {
  static readonly type = '@income.ChangeIncomeToOtherIncomeType';

  constructor(
    public applicationId: string,
    public income: EmployedAndSelfEmployedIncome,
    public openSidebar: boolean = false,
  ) {}
}

export class ChangeIncomeTypeToEmploymentOrSelf {
  static readonly type = '@income.ChangeIncomeTypeToEmploymentOrSelf';

  constructor(
    public applicationId: string,
    public income: EmployedAndSelfEmployedIncome | OtherIncome,
    public openSidebar: boolean = false,
  ) {}
}

export class AddSelfEmployedIncome {
  static readonly type = '@income.AddSelfEmployedIncome';

  constructor(public applicationId: string, public job: Job) {}
}

export class DeleteEmployedOrSelfEmployedIncome {
  static readonly type = '@income.DeleteEmployedOrSelfEmployedIncome';

  constructor(public applicationId: string, public income: EmployedIncome | SelfEmployedIncome) {}
}

export class DeleteOtherIncome {
  static readonly type = '@income.DeleteOtherIncome';

  constructor(public applicationId: string, public income: OtherIncome) {}
}

export class SetIncomeList {
  static readonly type = '@income.SetIncomeList';

  constructor(public application: Application) {}
}

export class UpdateEmployedIncome {
  static readonly type = '@income.UpdateEmployedIncome';

  constructor(public applicationId: string, public income: EmployedIncome) {}
}

export class UpdateIncomeFrequency {
  static readonly type = '@income.UpdateIncomeFrequency';

  constructor(public incomeFrequency: IncomePeriod) {}
}

export class UpdateIncomePropertiesList {
  static readonly type = '@income.UpdateIncomePropertiesList';

  constructor(public properties: Property[]) {}
}

export class UpdateOtherIncome {
  static readonly type = '@income.UpdateOtherIncome';

  constructor(public applicationId: string, public income: OtherIncome) {}
}

export class UpdateSelfEmployedIncome {
  static readonly type = '@income.UpdateSelfEmployedIncome';

  constructor(public applicationId: string, public income: SelfEmployedIncome) {}
}

export class AddOrUpdateEmployerAddressesExpanded {
  static readonly type = '@applicants.AddOrUpdateEmployerAddressesExpanded';

  constructor(public jobId: string, public addressExpanded: AddressExpanded) {}
}
