import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { AddressDetailsService } from './address-details.service';
import { PropertyAddressDetails } from '@fundmoreai/models';
import { finalize, map, switchMap, tap } from 'rxjs';
import { GoogleService } from './google.service';
import { PatchPropertyAddress } from 'src/app/portal/properties.actions';
import { LoadingStart, LoadingEnd } from '../../../core/loading.state';

export interface AddressDetailsStateModel {
  propertyAddress: {
    [key: string]: PropertyAddressDetails | null;
  };
  tempAddress: {
    [key: string]: PropertyAddressDetails | null;
  };
}

export class ClearPropertyAddress {
  static readonly type = '@propertyAddress.clearPropertyAddress';

  constructor(public propertyId: string) {}
}

export class ClearTempPropertyAddress {
  static readonly type = '@propertyAddress.clearTempPropertyAddress';

  constructor(public tempId: string) {}
}

export class FetchPropertyAddress {
  static readonly type = '@propertyAddress.fetchPropertyAddress';

  constructor(public propertyId: string) {}
}

export class SetPropertyAddress {
  static readonly type = '@propertyAddress.SetPropertyAddress';

  constructor(
    public propertyId: string,
    public propertyAddressDetails: PropertyAddressDetails | null,
  ) {}
}

export class FetchTempPropertyAddress {
  static readonly type = '@propertyAddress.fetchTempPropertyAddress';

  constructor(public tempId: string, public propertyAddress: string) {}
}

export class UpdatePropertyAddress {
  static readonly type = '@propertyAddress.updatePropertyAddress';

  constructor(public propertyAddressDetails: PropertyAddressDetails) {}
}

export class CreatePropertyAddress {
  static readonly type = '@propertyAddress.CreatePropertyAddress';

  constructor(public propertyId: string, public propertyAddressDetails: PropertyAddressDetails) {}
}

export class UpdateTempPropertyAddress {
  static readonly type = '@propertyAddress.updateTempPropertyAddress';

  constructor(
    public tempId: string,
    public propertyAddressDetails: PropertyAddressDetails | null,
  ) {}
}

export class ResetAddressDetails {
  static readonly type = '@propertyAddress.resetAddressDetails';
}

@State<AddressDetailsStateModel>({
  name: 'addressDetailsState',
  defaults: {
    propertyAddress: {},
    tempAddress: {},
  },
})
@Injectable()
export class AddressDetailsState {
  constructor(
    private addressDetailsService: AddressDetailsService,
    private googleService: GoogleService,
  ) {}

  static propertyAddress(propertyId: string) {
    return createSelector(
      [AddressDetailsState],
      (state: AddressDetailsStateModel): PropertyAddressDetails | null => {
        return state.propertyAddress[propertyId];
      },
    );
  }

  static tempAddress(tempId: string) {
    return createSelector(
      [AddressDetailsState],
      (state: AddressDetailsStateModel): PropertyAddressDetails | undefined | null => {
        return state.tempAddress[tempId];
      },
    );
  }

  @Action(ClearPropertyAddress)
  clearPropertyAddress(ctx: StateContext<AddressDetailsStateModel>, action: ClearPropertyAddress) {
    const state = ctx.getState();
    const propertyAddresses = { ...state.propertyAddress };
    const propertyAddress = propertyAddresses[action.propertyId];
    if (!propertyAddress) {
      return;
    }
    const clearedAddress: PropertyAddressDetails = {
      id: propertyAddress.id,
      propertyId: propertyAddress.propertyId,
      formattedAddress: null,
      streetDirection: null,
      streetName: null,
      streetNumber: null,
      streetNumberSuffix: null,
      streetType: null,
      unit: null,
      unitType: null,
      postalCode: null,
      city: null,
      country: null,
      province: null,
      lat: null,
      lng: null,
      rawGoogleGeocodeResponse: null,
      postalCodePrefix: null,
    };

    ctx.patchState({
      propertyAddress: {
        ...state.propertyAddress,
        [action.propertyId]: clearedAddress,
      },
    });
  }

  @Action(ClearTempPropertyAddress)
  clearTempPropertyAddress(
    ctx: StateContext<AddressDetailsStateModel>,
    action: ClearTempPropertyAddress,
  ) {
    const state = ctx.getState();
    const tempAddress = { ...state.tempAddress };

    delete tempAddress[action.tempId];

    ctx.patchState({
      tempAddress,
    });
  }

  @Action(FetchPropertyAddress)
  fetchPropertyAddress(ctx: StateContext<AddressDetailsStateModel>, action: FetchPropertyAddress) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.addressDetailsService.getPropertyAddress(action.propertyId).pipe(
      map((propertyAddressDetails: PropertyAddressDetails) => {
        const state = ctx.getState();

        ctx.patchState({
          propertyAddress: {
            ...state.propertyAddress,
            [action.propertyId]: propertyAddressDetails,
          },
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(SetPropertyAddress)
  SetPropertyAddress(ctx: StateContext<AddressDetailsStateModel>, action: SetPropertyAddress) {
    const state = ctx.getState();

    ctx.patchState({
      propertyAddress: {
        ...state.propertyAddress,
        [action.propertyId]: action.propertyAddressDetails,
      },
    });
  }

  @Action(FetchTempPropertyAddress)
  fetchTempPropertyAddress(
    ctx: StateContext<AddressDetailsStateModel>,
    action: FetchTempPropertyAddress,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.googleService.getPropertyAddressExpanded(action.propertyAddress).pipe(
      map((propertyAddressDetails: PropertyAddressDetails) => {
        const state = ctx.getState();
        const expandedAddress = { ...propertyAddressDetails, address: undefined };

        ctx.patchState({
          tempAddress: {
            ...state.tempAddress,
            [action.tempId]: expandedAddress,
          },
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdatePropertyAddress)
  updatePropertyAddress(
    ctx: StateContext<AddressDetailsStateModel>,
    action: UpdatePropertyAddress,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.addressDetailsService.updatePropertyAddress(action.propertyAddressDetails).pipe(
      tap((propertyAddressDetails: PropertyAddressDetails) => {
        const state = ctx.getState();

        ctx.patchState({
          propertyAddress: {
            ...state.propertyAddress,
            [propertyAddressDetails.propertyId]: propertyAddressDetails,
          },
        });
      }),
      switchMap((propertyAddressDetails: PropertyAddressDetails) => {
        return ctx.dispatch(new PatchPropertyAddress(propertyAddressDetails));
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(CreatePropertyAddress)
  createPropertyAddress(
    ctx: StateContext<AddressDetailsStateModel>,
    action: CreatePropertyAddress,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.addressDetailsService
      .createPropertyAddress({ ...action.propertyAddressDetails, propertyId: action.propertyId })
      .pipe(
        map((propertyAddressDetails: PropertyAddressDetails) => {
          return {
            ...propertyAddressDetails,
            createdAt: undefined,
            deletedAt: undefined,
            updatedAt: undefined,
            tenantId: undefined,
          };
        }),
        tap((propertyAddressDetails: PropertyAddressDetails) => {
          const state = ctx.getState();

          ctx.patchState({
            propertyAddress: {
              ...state.propertyAddress,
              [propertyAddressDetails.propertyId]: propertyAddressDetails,
            },
          });
        }),
        switchMap((propertyAddressDetails: PropertyAddressDetails) => {
          return ctx.dispatch(new PatchPropertyAddress(propertyAddressDetails));
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(UpdateTempPropertyAddress)
  updateTempPropertyAddress(
    ctx: StateContext<AddressDetailsStateModel>,
    action: UpdateTempPropertyAddress,
  ) {
    const state = ctx.getState();

    ctx.patchState({
      tempAddress: {
        ...state.tempAddress,
        [action.tempId]: action.propertyAddressDetails
          ? { ...action.propertyAddressDetails }
          : null,
      },
    });
  }

  @Action(ResetAddressDetails) resetAddressDetails(ctx: StateContext<AddressDetailsStateModel>) {
    ctx.patchState({
      propertyAddress: {},
      tempAddress: {},
    });
  }
}
