import { FetchType } from './enums';
import { FCTAppraisal } from './fct/fctAppraisal';

export enum PropertyAppraisalKey {
  APPRAISER = 'appraiser',
  APPRAISER_COMPANY = 'appraiserCompany',
  APPRAISAL_DATE = 'appraisalDate',
  APPRAISED_VALUE = 'appraisedValue',
  APPRAISAL_NOTES = 'appraisalNotes',
  APPRAISAL_TYPE = 'appraisalType',
  STATUS = 'status',
  IS_PROPERTY_VALUE = 'isPropertyValue',
  SOURCE = 'source',
  VALUATION_TIME = 'valuationTime',
  CONFIDENCE_SCORE = 'confidenceScore',
  FCT_INSURANCE_STATUS = 'FCTAppraisal?.lastStatus?.InsuranceStatus?.StatusCode',
}

export interface PropertyAppraisal {
  id: string;
  propertyId: string;
  appraisedValue: number;
  appraiser: string;
  appraisalDate?: string | null;
  createdAt: Date;
  updatedAt: Date;
  appraisalNotes: string | null;
  isPropertyValue: boolean;
  applicationId: string;
  externalSequenceNumber?: number | null;
  FCTAppraisal?: FCTAppraisal;
  valuationTime?: number | null;
  confidenceScore?: AppraisalConfidenceScore | null;
  fetchType?: FetchType;
  appraiserCompany: string | null;
  status: AppraisalStatus | null;
  appraisalType: AppraisalType | null;
  source: AppraisalSource | null;
  additionalData?: object | string | number | boolean | null;
}

export enum AppraisalType {
  AS_IS = 'AS_IS',
  AS_RENOVATED = 'AS_RENOVATED',
  AS_COMPLETE = 'AS_COMPLETE',
  LAND_VALUE = 'LAND_VALUE',
  THIRD_PARTY = 'THIRD_PARTY',
}

export enum AppraisalSource {
  MANUAL = 'MANUAL',
  OPTA = 'OPTA',
  FCT = 'FCT',
}

export enum AppraisalStatus {
  IN_PROCESS = 'IN_PROCESS',
  READY = 'READY',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
}

export enum AppraisalConfidenceScore {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}
