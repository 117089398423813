import { Application, Fee, LoanType, Mortgage, MortgageType } from '@fundmoreai/models';
import { FundmoreCalculator } from '..';

export function computeTotalLoanAmount(
  requestedMortgage:
    | Pick<Mortgage, 'loanAmount' | 'includePremiumInMortgage' | 'loanType' | 'transferAdjustment'>
    | null
    | undefined,
  fees: Fee[],
  insuranceAmount: number | null | undefined,
  capFeesMaxPercentage: number | undefined,
): number | undefined {
  let totalLoanAmount = computeTotalLoanAmountWithoutCapFees(
    requestedMortgage ?? null,
    insuranceAmount,
  );

  if (!totalLoanAmount) {
    return undefined;
  }

  if (requestedMortgage?.loanType === LoanType.MORTGAGE) {
    const capFees = FundmoreCalculator.computeCappedFees(fees, capFeesMaxPercentage);
    if (capFees) {
      totalLoanAmount += capFees;
    }
  }

  if (requestedMortgage?.transferAdjustment) {
    totalLoanAmount -= requestedMortgage.transferAdjustment;
  }

  return Math.round(totalLoanAmount * 100) / 100;
}

export function computeTotalLoanAmountWithoutCapFees(
  requestedMortgage: Pick<Mortgage, 'loanAmount' | 'includePremiumInMortgage' | 'loanType'> | null,
  insuranceAmount: number | null | undefined,
): number | undefined {
  let totalLoanAmount = requestedMortgage?.loanAmount;

  if (!totalLoanAmount) {
    return undefined;
  }

  if (requestedMortgage?.loanType === LoanType.MORTGAGE) {
    if (requestedMortgage?.includePremiumInMortgage && insuranceAmount) {
      totalLoanAmount += insuranceAmount;
    }
  }
  return totalLoanAmount;
}

export function computeTotalLoanAmountWithoutCapFeesFromApplication(
  application: Application,
): number | undefined {
  // TODO consider all requested mortgages for rate matrix?
  const requestedMortgage = application.Mortgages.find((m) => m.type == MortgageType.REQUESTED);
  const insuranceAmount = requestedMortgage?.insuranceAmount;

  return computeTotalLoanAmountWithoutCapFees(requestedMortgage ?? null, insuranceAmount);
}

export function computeTotalLoanAmountFromApplication(
  application: Application,
): number | undefined {
  let totalLoanAmount = computeTotalLoanAmountWithoutCapFeesFromApplication(application);
  if (!totalLoanAmount) {
    return undefined;
  }

  const capFees = FundmoreCalculator.computeCappedFeesFromApplication(application);
  if (capFees) {
    totalLoanAmount += capFees;
  }

  return totalLoanAmount;
}
