/* eslint-disable max-classes-per-file */
import { CustomerType } from './applicant';
import { PropertyType } from './enums';

export interface ApplicationDuplicate {
  id: string;
  createdAt: string;
  updatedAt: string;
  applicationDate: string;
  name?: string | null;
  code?: string | null;
  source?: string | null;
  currentStage?: string | null;
  isServicing: boolean;
  primaryApplicant: ApplicantForDuplicate;
  brokers: ApplicantForDuplicate[];
  underwriters: ApplicationAssignedUserForDuplicate[];
  requestedMortgage: MortgageForDuplicate;
  primaryProperty: PropertyForDuplicate;
  assignedUsers: ApplicationAssignedUserForDuplicate[];
}

export class ApplicantForDuplicate {
  name: string;
  surname: string;
  customerType: CustomerType;
  isPrimary: boolean;
  email: string;

  constructor(applicant: ApplicantForDuplicate) {
    if (!applicant) {
      return;
    }
    this.name = applicant.name;
    this.surname = applicant.surname;
    this.customerType = applicant.customerType;
    this.isPrimary = applicant.isPrimary;
    this.email = applicant.email;
  }
}

export class ApplicationAssignedUserForDuplicate {
  userId: string;
  User: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  role: {
    id: string;
    name: string;
  };

  constructor(user: ApplicationAssignedUserForDuplicate) {
    if (!user) {
      return;
    }
    this.userId = user.userId;
    if (user.User) {
      this.User = {
        id: user.User.id,
        firstName: user.User.firstName,
        lastName: user.User.lastName,
        email: user.User.email,
      };
    }
    if (user.role) {
      this.role = {
        id: user.role.id,
        name: user.role.name,
      };
    }
  }
}

export class MortgageForDuplicate {
  purpose: string;
  loanNumber: string;

  constructor(mortgage: MortgageForDuplicate) {
    if (!mortgage) {
      return;
    }
    this.purpose = mortgage.purpose;
    this.loanNumber = mortgage.loanNumber;
  }
}

export class PropertyForDuplicate {
  propertyAddress: string;
  type: PropertyType;

  constructor(property: PropertyForDuplicate) {
    if (!property) {
      return;
    }
    this.propertyAddress = property.propertyAddress;
    this.type = property.type;
  }
}
