import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, NgZone, Renderer2, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { FundmoreAITheme, ThemeState } from './core/store/theme.state';
import { CompanySettingsState } from './core/store/company-settings.state';
import { CompanySettingsModel, MaterialColor } from './core/store/company-settings.model';
import { forkJoin, tap } from 'rxjs';
import { PendoService } from './shared/services/pendo.service';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'fm-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  ngZone = inject(NgZone);
  constructor(
    overlayContainer: OverlayContainer,
    store: Store,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private titleService: Title,
    pendoService: PendoService,
  ) {
    this.registerSvgIcons();

    forkJoin([
      store.select(ThemeState.theme).pipe(
        tap((theme) => {
          if (theme === FundmoreAITheme.Light) {
            overlayContainer.getContainerElement().classList.remove(FundmoreAITheme.Dark);
            this.renderer.removeClass(this.document.body, FundmoreAITheme.Dark);
          } else {
            overlayContainer.getContainerElement().classList.add(FundmoreAITheme.Dark);
            this.renderer.addClass(this.document.body, FundmoreAITheme.Dark);
          }
        }),
      ),
      store.select(CompanySettingsState.companySettings).pipe(
        tap((settings: CompanySettingsModel) => {
          const faviconEl: HTMLLinkElement | null = this.document.querySelector('#favicon');

          if (faviconEl) {
            faviconEl.href = settings.websiteFavicon;
          }

          this.titleService.setTitle(settings.company.name);

          this.updateTheme(settings.stylePalette.primaryColorPalette, 'primary');
          this.updateTheme(settings.stylePalette.secondaryColorPalette, 'accent');
          this.updateTheme(settings.stylePalette.successColorPalette, 'success');
          this.updateTheme(settings.stylePalette.reviewColorPalette, 'review');
          this.updateTheme(settings.stylePalette.warnColorPalette, 'warn');
        }),
      ),
    ]).subscribe();

    // delay pendo script loading to leave more bandwidth for the initial page load
    setTimeout(() => {
      this.ngZone.runOutsideAngular(() => {
        pendoService.loadScript();
      });
    }, pendoService.INITIAL_DELAY_MS);
  }

  private updateTheme(colors: MaterialColor[], theme: string) {
    colors.forEach((color) => {
      this.document.documentElement.style.setProperty(`--theme-${theme}-${color.name}`, color.hex);
      this.document.documentElement.style.setProperty(
        `--theme-${theme}-contrast-${color.name}`,
        color.darkContrast ? 'rgba(00,00,00,87)' : '#fff',
      );
    });
  }

  private registerSvgIcons() {
    [
      'collapse',
      'dolphin-icon',
      'expand',
      'filogix-icon',
      'lendesk-icon',
      'velocity-icon',
      'fundmore-icon',
      'sagen-icon',
      'cmhc-icon',
      'canada-guaranty-icon',
      'opta-icon',
      'docuSign-icon',
    ].forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/${icon}.svg`),
      );
    });
  }
}
