/* eslint-disable max-len */
import {
  AdditionalPartyTypes,
  ApplicationStatusType,
  ApplicationWarningKeys,
  DwellingStyle,
  DwellingType,
  GarageType,
  MaritalType,
  PaymentFrequency,
  RentalOffsetType,
  RateType,
  RepaymentType,
  TaskPriority,
  ConditionField,
  LimitTargetField,
  CustomerType,
  CanadianProvinceCode,
  Province,
  CitizenshipStatusType,
  SupportedTypes,
  PropertyType,
  FinancialLiabilitySource,
  LiabilityType,
  PropertyTypes,
  DownPaymentType,
  TenureType,
  EmploymentType,
  IndustrySectorType,
  HeatingType,
  OccupancyType,
  StreetDirection,
  StreetType,
  GlobalMatchStrings,
  DescriptionType,
  ZoningTypes,
  AppraisalType,
  AppraisalSource,
  AdvancedProductInUseError,
  CreditTierInUseError,
  MortgagePosition,
  ConditionOfApprovalStatus,
  Country,
  ApplicationType,
  SewageType,
  TermType,
  ApplicationSource,
  FoundationType,
  State,
  FundmoreResultType,
  FundmoreRecommendationType,
  GarageSize,
  LocationTierType,
  MortgageClassificationType,
  AssetType,
  OccupationType,
  PayoffType,
  USStateCode,
  BrokerUserType,
  CompoundPeriod,
  PrepaymentType,
  LoanType,
  Gender,
  Salutation,
  RelationshipToPrimaryApplicant,
  FeePayer,
  FeeType,
  PropertyInsuranceType,
  TaxesPaidByType,
  WaterType,
  InsurerType,
  EmployedPaymentType,
  SelfEmployedPaymentType,
  TermMonths,
  InsuranceStatus,
  InsurerStatusUI,
  IncomeType,
  ApplicationPurposeType,
  ConstructionType,
  IncomePeriod,
  ApplicationConditionDocumentRejectReasons,
  ApplicationConditionDocumentStatus,
  PoliticallyExposedPersonType,
  FraudAMLRiskType,
  ThirdPartyDeterminationType,
  RequestNameScreeningType,
  AccountType,
  FinancialInstitutionType,
  DocumentManagementType,
  ApplicationNoteStatus,
  AddressExpandedType,
  ResidentialType,
  ResidentialStatusType,
  InsurancePaidByType,
  ApplicationApprovalStatus,
  ApplicationApprovalAction,
  LanguagePreference,
  OpenDocumentsStrategy,
  OneTimeLinkGenerationStrategy,
  EQClosingService,
  LendingTierType,
  PrivateDMDocumentRejectReasons,
  PrivateDMDocumentStatus,
  EzidoxApplicationStatusEnum,
  DeclineApplicationError,
  EQFraudAMLRiskType,
  CreditReportSource,
  IncomeVerificationType,
  AppraisalStatus,
  WarningActions,
  PayoffPaydownType,
  RiskFlagSeverity,
  ApplicationRiskFlagStatus,
  ResponsibleType,
  SourceOfBusinessType,
  PriorityType,
  ExcludeAssetFromCalculationReasonType,
  ExcludeLiabilityFromCalculationReasonType,
  ConformingType,
  ProgramCodeType,
  PurposeCode,
  PaymentSource,
  MortgageClosingInstruction,
  MortgageStatus,
  MortgageState,
  SecurityType,
  MMSNotificationType,
  AgentSource,
  MMSDocumentUploadStatus,
  AgentAddressExpandedType,
  AgentStatus,
  NotificationTypes,
} from '@fundmoreai/models';
import {
  ApplicationEntity,
  ApplicationLogAction,
  ClosedType,
  EzidoxStatusLocalizationOptions,
  LockReasonType,
  ProductFeatures,
} from './enums';

export const GlobalMatchStringsRecord: Record<GlobalMatchStrings, string> = {
  [GlobalMatchStrings.ALL_STAGES]: $localize`All Stages`,
};

export const ApplicationPurposeRecord: Record<ApplicationPurposeType, string> = {
  [ApplicationPurposeType.PURCHASE]: $localize`Purchase`,
  [ApplicationPurposeType.REFINANCE]: $localize`Refinance`,
  [ApplicationPurposeType.ETO]: $localize`ETO`,
  [ApplicationPurposeType.SWITCH_TRANSFER]: $localize`Switch/Transfer`,
  [ApplicationPurposeType.DEFICIENCY_SALE]: $localize`Deficiency Sale`,
  [ApplicationPurposeType.WORKOUT]: $localize`Workout`,
  [ApplicationPurposeType.RENEWAL]: $localize`Renewal`,
  [ApplicationPurposeType.CONSTRUCTION]: $localize`Construction`,
  [ApplicationPurposeType.BRIDGE_FINANCING]: $localize`Bridge Financing`,
  [ApplicationPurposeType.OTHER]: $localize`Other`,
  [ApplicationPurposeType.PAYMENT_CHANGE]: $localize`Payment Change`,
  [ApplicationPurposeType.SERVICING_RENEWAL]: $localize`Renewal`,
  [ApplicationPurposeType.COST_OF_BORROWING]: $localize`Cost of Borrowing`,
};

export const PriorityTypeRecord: Record<PriorityType, string> = {
  [PriorityType.CRITICAL]: $localize`Critical`,
  [PriorityType.HIGH]: $localize`High`,
  [PriorityType.MEDIUM]: $localize`Medium`,
  [PriorityType.LOW]: $localize`Low`,
};

export const MortgagePositionTypeRecord: Record<MortgagePosition, string> = {
  [MortgagePosition.FIRST]: $localize`First`,
  [MortgagePosition.SECOND]: $localize`Second`,
  [MortgagePosition.THIRD]: $localize`Third`,
  [MortgagePosition.UNKNOWN]: $localize`Unknown`,
};

export const PrepaymentTypeRecord: Record<PrepaymentType, string> = {
  [PrepaymentType.SPREAD_EVENLY]: $localize`Spread Evenly`,
  [PrepaymentType.APPLY_UPFRONT]: $localize`Apply Upfront`,
};

export const MortgageClassificationTypeRecord: Record<MortgageClassificationType, string> = {
  [MortgageClassificationType.ASSUMPTION]: $localize`Assumption`,
  [MortgageClassificationType.BRIDGE_FINANCING_WITH_PURCHASE]: $localize`Bridge Financing with Purchase`,
  [MortgageClassificationType.BRIDGE_FINANCING_WITHOUT_PURCHASE]: $localize`Bridge Financing without Purchase`,
  [MortgageClassificationType.CONSTRUCTION]: $localize`Construction`,
  [MortgageClassificationType.CONSTRUCTION_EXISTING_PROPERTY]: $localize`Construction - Existing Property`,
  [MortgageClassificationType.CONSTRUCTION_NEW_BUILD]: $localize`Construction - New Build`,
  [MortgageClassificationType.CONSTRUCTION_TAKEOVER]: $localize`Construction - Takeover`,
  [MortgageClassificationType.DEFICIENCY_SALE]: $localize`Deficiency Sale`,
  [MortgageClassificationType.ETO]: $localize`ETO`,
  [MortgageClassificationType.FLIP]: $localize`Flip`,
  [MortgageClassificationType.HELOC]: $localize`HELOC`,
  [MortgageClassificationType.PORT]: $localize`Port`,
  [MortgageClassificationType.PURCHASE]: $localize`Purchase`,
  [MortgageClassificationType.PURCHASE_PLUS_IMPROVEMENT]: $localize`Purchase plus Improvements`,
  [MortgageClassificationType.PURCHASE_WITH_HELOC]: $localize`Purchase with HELOC`,
  [MortgageClassificationType.NEW_BUILD]: $localize`New Build`,
  [MortgageClassificationType.PRIVATE]: $localize`Private`,
  [MortgageClassificationType.RENT_TO_OWN]: $localize`Rent to Own`,
  [MortgageClassificationType.REFINANCE]: $localize`Refinance`,
  [MortgageClassificationType.RENEWAL]: $localize`Renewal`,
  [MortgageClassificationType.REVERSE_MORTGAGE]: $localize`Reverse Mortgage`,
  [MortgageClassificationType.SWITCH_TRANSFER_COLLATERAL]: $localize`Switch Transfer Collateral`,
  [MortgageClassificationType.SWITCH_TRANSFER_STANDARD]: $localize`Switch Transfer Standard`,
  [MortgageClassificationType.OTHER]: $localize`Other`,
  [MortgageClassificationType.WORKOUT]: $localize`Workout`,
};

export const LiabilityTypeRecord: Record<LiabilityType, string> = {
  [LiabilityType.CREDIT]: $localize`Credit`,
  [LiabilityType.PERSONAL_LOAN]: $localize`Personal Loan`,
  [LiabilityType.AUTO_LOAN]: $localize`Auto Loan`,
  [LiabilityType.ALIMONY]: $localize`Alimony`,
  [LiabilityType.CHILD_SUPPORT]: $localize`Child Support`,
  [LiabilityType.STUDENT_LOAN]: $localize`Student Loan`,
  [LiabilityType.WAGE_GARNISHMENT]: $localize`Wage Garnishment`,
  [LiabilityType.UNSECURED_LINE_OF_CREDIT]: $localize`Unsecured Line of Credit`,
  [LiabilityType.INCOME_TAX]: $localize`Income Tax`,
  [LiabilityType.SECURED_LINE_OF_CREDIT]: $localize`Secured Line of Credit`,
  [LiabilityType.LEASE]: $localize`Lease`,
  [LiabilityType.AUTO_LEASE]: $localize`Auto Lease`,
  [LiabilityType.MORTGAGE]: $localize`Mortgage`,
  [LiabilityType.JUDGMENT]: $localize`Judgment`,
  [LiabilityType.OVERDUE_TAXES]: $localize`Overdue Taxes`,
  [LiabilityType.CREDIT_CARD]: $localize`Credit Card`,
  [LiabilityType.RENT]: $localize`Rent`,
  [LiabilityType.PROPERTY_FIRE_INSURANCE]: $localize`Property Fire Insurance`,
  [LiabilityType.PROPERTY_REPAIRS]: $localize`Property Repairs`,
  [LiabilityType.PROPERTY_HYDRO]: $localize`Property Hydro`,
  [LiabilityType.PROPERTY_MANAGEMENT]: $localize`Property Management`,
  [LiabilityType.PROPERTY_GENERAL]: $localize`Property General`,
  [LiabilityType.PROPERTY_INTEREST]: $localize`Property Interest`,
  [LiabilityType.OTHER]: $localize`Other`,
};

export const SecurityTypeRecord: Record<SecurityType, string> = {
  [SecurityType.MORTGAGE_SECURED]: $localize`Mortgage Secured`,
  [SecurityType.SECURED]: $localize`Secured`,
  [SecurityType.UNSECURED]: $localize`Unsecured`,
  [SecurityType.CASH_SECURED]: $localize`Cash Secured`,
};

export const PayoffRecord: Record<PayoffType, string> = {
  [PayoffType.PRIOR_TO_ADVANCE]: $localize`Prior to advance`,
  [PayoffType.FROM_PROCEEDS]: $localize`From proceeds`,
  [PayoffType.NONE]: $localize`None`,
};

export const PayoffPaydownRecord: Record<PayoffPaydownType, string> = {
  [PayoffPaydownType.NONE]: $localize`None`,
  [PayoffPaydownType.PAYOFF]: $localize`Payoff`,
  [PayoffPaydownType.PAYDOWN]: $localize`Paydown`,
  [PayoffPaydownType.PAYDOWN_INCREASE_LIMIT]: $localize`Paydown & Increase Limit`,
  [PayoffPaydownType.PAYDOWN_REDUCE_LIMIT]: $localize`Paydown & Reduce Limit`,
};

export const ConformingTypeRecord: Record<ConformingType, string> = {
  [ConformingType.CONFORMING]: $localize`Conforming`,
  [ConformingType.NONCONFORMING]: $localize`Non-Conforming`,
};

export const FinancialLiabilitySourceRecord: Record<FinancialLiabilitySource, string> = {
  [FinancialLiabilitySource.POS_BROKER]: $localize`Pos broker`,
  [FinancialLiabilitySource.POS_CREDIT_BUREAU]: $localize`Pos Credit Bureau`,
  [FinancialLiabilitySource.CREDIT_BUREAU]: $localize`Credit Bureau`,
  [FinancialLiabilitySource.EQUIFAX]: $localize`Equifax`,
  [FinancialLiabilitySource.MANUAL]: $localize`Manual`,
  [FinancialLiabilitySource.MORTGAGE_APPLICATION]: $localize`Mortgage Application`,
  [FinancialLiabilitySource.IDS_BANKING]: $localize`IDS Banking`,
};

export const SupportedTypesRecord: Record<SupportedTypes, string> = {
  [SupportedTypes.TEXT]: $localize`Text`,
  [SupportedTypes.NUMBER]: $localize`Number`,
  [SupportedTypes.CURRENCY]: $localize`Currency`,
  [SupportedTypes.PERCENT]: $localize`Percentage`,
  [SupportedTypes.SINGLESELECT]: $localize`Single-select`,
  [SupportedTypes.SINGLESELECT_NUMERIC]: $localize`Single-select (Numeric)`,
  [SupportedTypes.MULTISELECT]: $localize`Multi-select`,
  [SupportedTypes.DATE]: $localize`Date`,
  [SupportedTypes.BOOLEAN]: $localize`Boolean`,
  [SupportedTypes.DISABLED]: $localize`Disabled`,
  [SupportedTypes.TEXT_AUTO_COMPLETE]: 'Text Auto Complete',
};

export const ApplicationTypeRecord: Record<ApplicationType, string> = {
  [ApplicationType.APPROVAL]: $localize`Approval`,
  [ApplicationType.PREAPPROVAL]: $localize`Pre-Approval`,
};

export const CountryRecord: Record<Country, string> = {
  [Country.CANADA]: $localize`Canada`,
  [Country.UNITED_STATES]: $localize`United States`,
};

export const LockReasonTypeRecord: Record<LockReasonType, string> = {
  [LockReasonType.LOCK]: $localize`Lock`,
  [LockReasonType.UNLOCK]: $localize`Unlock`,
};

export const EmploymentTypeRecord: Record<EmploymentType, string> = {
  [EmploymentType.FULL_TIME]: $localize`Full Time`,
  [EmploymentType.CONTRACT]: $localize`Contract`,
  [EmploymentType.PART_TIME]: $localize`Part Time`,
  [EmploymentType.SEASONAL]: $localize`Seasonal Full/Part Time`,
  [EmploymentType.SELF_EMPLOYED]: $localize`Self Employed`,
};

export const IndustrySectorTypeRecord: Record<IndustrySectorType, string> = {
  [IndustrySectorType.CONSTRUCTION]: $localize`Construction`,
  [IndustrySectorType.GOVERNMENT]: $localize`Government`,
  [IndustrySectorType.HEALTH]: $localize`Health`,
  [IndustrySectorType.EDUCATION]: $localize`Education`,
  [IndustrySectorType.FARMING]: $localize`Farming`,
  [IndustrySectorType.HI_TECH]: $localize`Hi-Tech`,
  [IndustrySectorType.RETAIL_SALES]: $localize`Retail Sales`,
  [IndustrySectorType.LEISURE_ENTERTAINMENT]: $localize`Leisure / Entertainment`,
  [IndustrySectorType.BANKING_FINANCE]: $localize`Banking / Finance`,
  [IndustrySectorType.TRANSPORT]: $localize`Transport`,
  [IndustrySectorType.SERVICE]: $localize`Service`,
  [IndustrySectorType.MANUFACTURING]: $localize`Manufacturing`,
  [IndustrySectorType.FARMING_NATURAL_RESOURCES]: $localize`Farming / Natural Resources`,
  [IndustrySectorType.NATURAL_RESOURCES]: $localize`Natural Resources`,
  [IndustrySectorType.INFORMATION_TECHNOLOGY]: $localize`Information Technology`,
  [IndustrySectorType.OIL_GAS]: $localize`Oil Gas`,
  [IndustrySectorType.STUDENT]: $localize`Student`,
  [IndustrySectorType.FOREIGN]: $localize`Foreign`,
  [IndustrySectorType.RETIRED]: $localize`Retired`,
  [IndustrySectorType.UNEMPLOYED]: $localize`Unemployed`,
  [IndustrySectorType.VARIES]: $localize`Varies`,
  [IndustrySectorType.OTHER]: $localize`Other`,
};

export const TaskPriorityTypeRecord: Record<TaskPriority, string> = {
  [TaskPriority.HIGH]: $localize`High`,
  [TaskPriority.MEDIUM]: $localize`Medium`,
  [TaskPriority.LOW]: $localize`Low`,
};

export const RepaymentTypeRecord: Record<RepaymentType, string> = {
  [RepaymentType.PRINCIPAL_AND_INTEREST]: $localize`Principal And Interest`,
  [RepaymentType.INTEREST_ONLY]: $localize`Interest Only`,
  [RepaymentType.BALLOON_PAYMENT]: $localize`Balloon Payment`,
  [RepaymentType.UNKNOWN]: $localize`Unknown`,
};

export const RateTypeRecord: Record<RateType, string> = {
  [RateType.FIXED]: $localize`Fixed`,
  [RateType.VARIABLE]: $localize`Variable`,
  [RateType.ADJUSTABLE]: $localize`Adjustable`,
  [RateType.CAPPED_VARIABLE]: $localize`Capped Variable`,
  [RateType.BUYDOWN]: $localize`Buydown`,
};

export const TermTypeRecord: Record<TermType, string> = {
  [TermType.OPEN]: $localize`Open`,
  [TermType.CLOSED]: $localize`Closed`,
  [TermType.CONVERTIBLE]: $localize`Convertible`,
};

export const TermMonthsRecord: Record<TermMonths, string> = {
  [TermMonths.ONE_YEAR]: '12',
  [TermMonths.TWO_YEARS]: '24',
  [TermMonths.THREE_YEARS]: '36',
  [TermMonths.FOUR_YEARS]: '48',
  [TermMonths.FIVE_YEARS]: '60',
};

export const ConstructionTypeRecord: Record<ConstructionType, string> = {
  [ConstructionType.NEW]: $localize`New`,
  [ConstructionType.EXISTING]: $localize`Existing`,
};

export const OccupancyTypeRecord: Record<OccupancyType, string> = {
  [OccupancyType.OWNER_OCCUPIED]: $localize`Owner-Occupied`,
  [OccupancyType.OWNER_OCCUPIED_RENTAL]: $localize`Owner-Occupied & Rental`,
  [OccupancyType.RENTAL]: $localize`Rental`,
  [OccupancyType.SECOND_HOME]: $localize`Second Home`,
  [OccupancyType.LIVE_WITH_PARENT]: $localize`Live With Parent`,
  [OccupancyType.OTHER]: $localize`Other`,
};

export const DescriptionTypeRecord: Record<DescriptionType, string> = {
  [DescriptionType.PROPERTY]: $localize`Property`,
  [DescriptionType.RENTAL_PROPERTY]: $localize`Rental Property`,
  [DescriptionType.REAL_ESTATE]: $localize`Real Estate`,
  [DescriptionType.INVESTMENT]: $localize`Investment`,
  [DescriptionType.FURNITURES]: $localize`Furnitures`,
  [DescriptionType.JEWELRY]: $localize`Jewelry`,
};

export const SewageTypeRecord: Record<SewageType, string> = {
  [SewageType.MUNICIPAL]: $localize`Municipal`,
  [SewageType.SEPTIC_SYSTEM]: $localize`Septic System`,
  [SewageType.HOLDING_TANK]: $localize`Holding Tank`,
  [SewageType.UNKNOWN]: $localize`Unknown`,
};

export const WaterTypeRecord: Record<WaterType, string> = {
  [WaterType.MUNICIPAL]: $localize`Municipal`,
  [WaterType.CISTERN]: $localize`Cistern`,
  [WaterType.WELL]: $localize`Well`,
  [WaterType.NON]: $localize`Non`,
  [WaterType.PRIVATE]: $localize`Private`,
  [WaterType.OTHER]: $localize`Other`,
};

export const TenureTypeRecord: Record<TenureType, string> = {
  [TenureType.FREEHOLD]: $localize`FreeHold`,
  [TenureType.LEASEHOLD]: $localize`LeaseHold`,
  [TenureType.CONDO]: $localize`Condo`,
  [TenureType.OTHER]: $localize`Other`,
};

export const HeatingTypeRecord: Record<HeatingType, string> = {
  [HeatingType.ELECTRIC_BASEBOARD]: $localize`Electric Baseboard`,
  [HeatingType.FORCED_AIR_GAS_OIL_ELECTRIC]: $localize`Forced Air Gas/Oil/Electric`,
  [HeatingType.HOT_WATER_HEATING]: $localize`Hot Water Heating`,
  [HeatingType.GEOTHERMAL]: $localize`Geothermal`,
  [HeatingType.OIL]: $localize`Oil`,
  [HeatingType.OTHER]: $localize`Other`,
};

export const ClosedTypeRecord: Record<ClosedType, string> = {
  [ClosedType.YES]: $localize`Yes`,
  [ClosedType.NO]: $localize`No`,
};

export const TaxesPaidByTypeRecord: Record<TaxesPaidByType, string> = {
  [TaxesPaidByType.BORROWER]: $localize`Borrower`,
  [TaxesPaidByType.LENDER]: $localize`Lender`,
  [TaxesPaidByType.PAID_WITH_ANOTHER_MORTGAGE]: $localize`Paid with another Mortgage`,
};

export const FoundationTypeRecord: Record<FoundationType, string> = {
  [FoundationType.BASEMENT]: $localize`Basement`,
  [FoundationType.CRAWLSPACE]: $localize`Crawlspace`,
  [FoundationType.PIER]: $localize`Pier`,
  [FoundationType.SLAB_ON_GRADE]: $localize`Slab on Grade`,
  [FoundationType.WALKOUT_BASEMENT]: $localize`Walkout Basement`,
};

export const PropertyTypesRecord: Record<PropertyTypes, string> = {
  [PropertyTypes.PRIMARY_RESIDENCE]: $localize`Primary Residence`,
  [PropertyTypes.INVESTMENT_PROPERTY]: $localize`Investment property`,
  [PropertyTypes.MULTI_UNIT]: $localize`Multi-unit`,
  [PropertyTypes.SECOND_HOME]: $localize`Second Home`,
  [PropertyTypes.COTTAGE]: $localize`Cottage`,
  [PropertyTypes.MOBILE_MODULAR]: $localize`Mobile / Modular`,
};

export const PropertyTypeRecord: Record<PropertyType, string> = {
  [PropertyType.PRIMARY]: $localize`Subject`,
  [PropertyType.OTHER]: $localize`Other`,
  [PropertyType.EXTERNAL]: $localize`External`,
};

export const DwellingTypeRecord: Record<DwellingType, string> = {
  [DwellingType.DETACHED]: $localize`Detached`,
  [DwellingType.SEMI_DETACHED]: $localize`Semi-detached`,
  [DwellingType.DUPLEX_DETACHED]: $localize`Duplex - Detached`,
  [DwellingType.DUPLEX_SEMI_DETACHED]: $localize`Duplex - Semi-detached`,
  [DwellingType.DUPLEX]: $localize`Duplex`,
  [DwellingType.ROW_HOUSING]: $localize`Row Housing`,
  [DwellingType.APARTMENT_LOW_RISE]: $localize`Apartment Low Rise`,
  [DwellingType.APARTMENT_HIGH_RISE]: $localize`Apartment High Rise`,
  [DwellingType.APARTMENT]: $localize`Apartment`,
  [DwellingType.TOWNHOUSE]: $localize`Townhouse`,
  [DwellingType.STRIP]: $localize`Strip`,
  [DwellingType.HIGH_RISE]: $localize`High Rise`,
  [DwellingType.STACKED]: $localize`Stacked`,
  [DwellingType.MOBILE]: $localize`Mobile`,
  [DwellingType.TRIPLEX_DETACHED]: $localize`Triplex - Detached`,
  [DwellingType.TRIPLEX_SEMI_DETACHED]: $localize`Triplex - Semi-detached`,
  [DwellingType.TRIPLEX]: $localize`Triplex`,
  [DwellingType.FOURPLEX_DETACHED]: $localize`Fourplex - Detached`,
  [DwellingType.FOURPLEX_SEMI_DETACHED]: $localize`Fourplex - Semi-detached`,
  [DwellingType.FOURPLEX]: $localize`Fourplex`,
  [DwellingType.FIVEPLEX]: $localize`Fiveplex`,
  [DwellingType.SIXPLEX]: $localize`Sixplex`,
  [DwellingType.MODULAR_HOME_DETACHED]: $localize`Modular Home - Detached`,
  [DwellingType.MODULAR_HOME_SEMI_DETACHED]: $localize`Modular Home - Semi-detached`,
  [DwellingType.MODULAR_HOME]: $localize`Modular Home`,
  [DwellingType.COOP]: $localize`Co-op`,
  [DwellingType.SINGLE]: $localize`Single`,
  [DwellingType.CONDOMINIUM]: $localize`Condominium`,
  [DwellingType.OTHER]: $localize`Other`,
};

export const DwellingStyleRecord: Record<DwellingStyle, string> = {
  [DwellingStyle.ONE_STORY]: $localize`One Storey`,
  [DwellingStyle.TWO_STORY]: $localize`Two Storey`,
  [DwellingStyle.THREE_STORY]: $localize`Three Storey`,
  [DwellingStyle.BI_LEVEL]: $localize`Bi-Level`,
  [DwellingStyle.SPLIT_LEVEL]: $localize`Split Level`,
  [DwellingStyle.ONE_AND_ONE_HALF_STORY]: $localize`One and One Half Storey`,
  [DwellingStyle.OTHER]: $localize`Other`,
};

export const GarageTypeRecord: Record<GarageType, string> = {
  [GarageType.NONE]: $localize`None`,
  [GarageType.ATTACHED_GARAGE]: $localize`Attached`,
  [GarageType.BUILT_IN_GARAGE]: $localize`Built-in`,
  [GarageType.BASEMENT_GARAGE]: $localize`Basement`,
  [GarageType.DETACHED_GARAGE]: $localize`Detached`,
};

export const LocationTierTypeRecord: Record<LocationTierType, string> = {
  [LocationTierType.TIER_1]: $localize`Tier 1`,
  [LocationTierType.TIER_2]: $localize`Tier 2`,
  [LocationTierType.TIER_3]: $localize`Tier 3`,
};

export const GarageSizeRecord: Record<GarageSize, string> = {
  [GarageSize.NONE]: $localize`None`,
  [GarageSize.SINGLE]: $localize`Single`,
  [GarageSize.DOUBLE]: $localize`Double`,
  [GarageSize.TRIPLE]: $localize`Triple`,
};

export const RentalOffsetOptionsRecord: Record<RentalOffsetType, string> = {
  [RentalOffsetType.NONE]: $localize`None`,
  [RentalOffsetType.REDUCE_RENTAL_EXPENSES]: $localize`Reduce Rental exp., add bal to Gross Inc.`,
  [RentalOffsetType.ADD_PERCENTAGE_TO_GROSS_INCOME]: $localize`Add Percentage to Gross Income`,
};

export const MaritalTypeRecord: Record<MaritalType, string> = {
  [MaritalType.MARRIED]: $localize`Married`,
  [MaritalType.WIDOWED]: $localize`Widowed`,
  [MaritalType.SEPARATED]: $localize`Separated`,
  [MaritalType.DIVORCED]: $localize`Divorced`,
  [MaritalType.SINGLE]: $localize`Single`,
  [MaritalType.COMMON_LAW]: $localize`Common Law`,
  [MaritalType.ENGAGED]: $localize`Engaged`,
  [MaritalType.OTHER]: $localize`Other`,
};

export const PropertyInsuranceTypeRecord: Record<PropertyInsuranceType, string> = {
  [PropertyInsuranceType.FIRE]: $localize`Fire`,
  [PropertyInsuranceType.FLOOD]: $localize`Flood`,
  [PropertyInsuranceType.HOMEOWNER]: $localize`Homeowner`,
  [PropertyInsuranceType.RENTERS]: $localize`Renters`,
  [PropertyInsuranceType.EARTHQUAKE]: $localize`Earthquake`,
  [PropertyInsuranceType.COURSE_OF_CONSTRUCTION_AND_LIABILITY]: $localize`Course of Construction and Liability`,
  [PropertyInsuranceType.CONDO_CORPORATION_INSURANCE_BINDER]: $localize`Condo Corporation Insurance Binder`,
  [PropertyInsuranceType.BARELAND_LIABILITY]: $localize`Bareland Liability`,
  [PropertyInsuranceType.PROPERTY]: $localize`Property`,
  [PropertyInsuranceType.COMMERCIAL]: $localize`Commercial`,
  [PropertyInsuranceType.CONTENT]: $localize`Content`,
  [PropertyInsuranceType.COMPREHENSIVE_GENERAL_LIABILITY]: $localize`Comprehensive General Liability`,
  [PropertyInsuranceType.BOILER_EQUIPMENT]: $localize`Boiler & Equipment`,
  [PropertyInsuranceType.CONDO]: $localize`Condo`,
};

export const AppraisalTypeRecord: Record<AppraisalType, string> = {
  [AppraisalType.AS_IS]: $localize`As-is`,
  [AppraisalType.AS_RENOVATED]: $localize`As-Renovated`,
  [AppraisalType.AS_COMPLETE]: $localize`As-Complete`,
  [AppraisalType.LAND_VALUE]: $localize`Land Value`,
  [AppraisalType.THIRD_PARTY]: $localize`3rd Party Appraisal`,
};

export const AppraisalSourceRecord: Record<AppraisalSource, string> = {
  [AppraisalSource.MANUAL]: $localize`Manual`,
  [AppraisalSource.OPTA]: $localize`Opta`,
  [AppraisalSource.FCT]: $localize`FCT`,
};

export const AppraisalStatusRecord: Record<AppraisalStatus, string> = {
  [AppraisalStatus.DELIVERED]: $localize`Delivered`,
  [AppraisalStatus.IN_PROCESS]: $localize`In Process`,
  [AppraisalStatus.READY]: $localize`Ready`,
  [AppraisalStatus.CANCELLED]: $localize`Cancelled`,
};

export const CitizenshipStatusTypeRecord: Record<CitizenshipStatusType, string> = {
  [CitizenshipStatusType.CITIZEN]: $localize`Citizen`,
  [CitizenshipStatusType.FOREIGN_ALIEN]: $localize`Foreign Alien`,
  [CitizenshipStatusType.LANDED_IMMIGRANT]: $localize`Landed Immigrant`,
  [CitizenshipStatusType.NON_RESIDENT]: $localize`Non Resident`,
  [CitizenshipStatusType.RESIDENT]: $localize`Resident`,
  [CitizenshipStatusType.STUDENT_VISA]: $localize`Student Visa`,
  [CitizenshipStatusType.WORK_VISA]: $localize`Work Visa`,
};

export const CustomerTypeRecord: Record<CustomerType, string> = {
  [CustomerType.GUARANTOR]: $localize`Guarantor`,
  [CustomerType.COMPANY]: $localize`Company`,
  [CustomerType.CO_BORROWER]: $localize`Co-Borrower`,
  [CustomerType.CUSTOMER]: $localize`Borrower`,
  [CustomerType.THIRD_PARTY]: $localize`3rd Party`,
  [CustomerType.BROKER]: $localize`Agent`,
  [CustomerType.LAWYER]: $localize`Lawyer`,
  [CustomerType.SUBMITTING_AGENT]: $localize`Submitting Agent`,
  [CustomerType.SIGNING_OFFICER]: $localize`Signing Officer`,
  [CustomerType.DIRECTOR]: $localize`Director`,
  [CustomerType.BENEFICIAL_OWNER]: $localize`Beneficial Owner`,
  [CustomerType.BROKERAGE]: $localize`Brokerage`,
  [CustomerType.POA]: $localize`POA`,
};

export const LanguagePreferenceRecord: Record<LanguagePreference, string> = {
  [LanguagePreference.ENGLISH]: $localize`English`,
  [LanguagePreference.FRENCH]: $localize`French`,
};

export const LoanTypeRecord: Record<LoanType, string> = {
  [LoanType.MORTGAGE]: $localize`Mortgage`,
  [LoanType.SECURE_LINE_OF_CREDIT]: $localize`Secure Line of Credit`,
  [LoanType.SECURE_LINE_OF_CREDIT_FLEX]: $localize`Secure Line of Credit (Flex)`,
  [LoanType.LIEN]: $localize`Lien`,
  [LoanType.BRIDGE]: $localize`Bridge`,
};

export const PaymentFrequencyRecord: Record<PaymentFrequency, string> = {
  [PaymentFrequency.MONTHLY]: $localize`Monthly`,
  [PaymentFrequency.SEMI_MONTHLY]: $localize`Semi-Monthly`,
  [PaymentFrequency.BI_WEEKLY]: $localize`Biweekly`,
  [PaymentFrequency.WEEKLY]: $localize`Weekly`,
  [PaymentFrequency.ANNUALLY]: $localize`Annually`,
  [PaymentFrequency.DAILY]: $localize`Daily`,
  [PaymentFrequency.QUARTERLY]: $localize`Quarterly`,
  [PaymentFrequency.SEMI_ANNUALLY]: $localize`Semi-Annually`,
  [PaymentFrequency.ACCELERATED_BI_WEEKLY]: $localize`Accelerated Bi-Weekly`,
  [PaymentFrequency.ACCELERATED_WEEKLY]: $localize`Accelerated Weekly`,
};

export const ZoningTypesRecord: Record<ZoningTypes, string> = {
  [ZoningTypes.RESIDENTIAL]: $localize`Residential`,
  [ZoningTypes.COMMERCIAL]: $localize`Commercial`,
  [ZoningTypes.RESCOMMERCIAL]: $localize`Residential / Commercial`,
  [ZoningTypes.LAND]: $localize`Land`,
  [ZoningTypes.INDUSTRIAL]: $localize`Industrial`,
};

export const AdditionalPartyTypesRecord: Record<AdditionalPartyTypes, string> = {
  [AdditionalPartyTypes.CO_BORROWER]: $localize`Co-Borrower`,
  [AdditionalPartyTypes.GUARANTOR]: $localize`Guarantor`,
  [AdditionalPartyTypes.THIRD_PARTY]: $localize`3rd Party (e.g. broker, accountant, lawyer)`,
};

export const DownPaymentTypeRecord: Record<DownPaymentType, string> = {
  [DownPaymentType.SALE_OF_EXISTING_PROPERTY]: $localize`Sale of Existing Property`,
  [DownPaymentType.PERSONAL_CASH]: $localize`Personal Cash`,
  [DownPaymentType.RRSP]: $localize`RRSP`,
  [DownPaymentType.SELF_DIRECTED_RRSP]: $localize`Self-Directed RRSP`,
  [DownPaymentType.BORROWED_AGAINST_LIQUID_ASSETS]: $localize`Borrowed Against Liquid Assets`,
  [DownPaymentType.GIFT]: $localize`Gift`,
  [DownPaymentType.SWEAT_EQUITY]: $localize`Sweat Equity`,
  [DownPaymentType.EXISTING_EQUITY]: $localize`Existing Equity`,
  [DownPaymentType.SECONDARY_FINANCING]: $localize`Secondary Financing`,
  [DownPaymentType.GRANTS]: $localize`Grants`,
  [DownPaymentType.LOAN]: $localize`Loan`,
  [DownPaymentType.RENT]: $localize`Rent`,
  [DownPaymentType.OTHER]: $localize`Other`,
};

export const ApplicationWarningValues: Record<ApplicationWarningKeys, string> = {
  [ApplicationWarningKeys.NO_UNDERWRITER_AUTO_ASSIGNED_WARNING]: $localize`No underwriter was able to be automatically assigned to this deal!`,
  [ApplicationWarningKeys.LOAN_NUMBER_GENERATION_FAILED_WARNING]: $localize`The Loan Number failed to generate for the requested mortgage!`,
  [ApplicationWarningKeys.LOS_APPROVED_WARNING]: $localize`LOS Approval Failed!`,
  [ApplicationWarningKeys.LOS_PENDING_WARNING]: $localize`LOS Pending Failed!`,
  [ApplicationWarningKeys.LOS_DECLINED_WARNING]: $localize`LOS Decline Failed!`,
  [ApplicationWarningKeys.PRODUCT_CHANGE_WARNING]: $localize`Product has changed!`,
  [ApplicationWarningKeys.SERVICING_DUPLICATE_SUBMISSION_WARNING]: $localize`This application is a duplicate submission!`,
  [ApplicationWarningKeys.PROJECTED_BALANCE_FAILED_WARNING]: $localize`Projected Balance failed to generate!`,
};

export const ApplicationWarningHumanizedKey: Record<ApplicationWarningKeys, string> = {
  [ApplicationWarningKeys.NO_UNDERWRITER_AUTO_ASSIGNED_WARNING]: $localize`Failed Automatic Underwriter Assignment`,
  [ApplicationWarningKeys.LOAN_NUMBER_GENERATION_FAILED_WARNING]: $localize`Failed Loan Number Generation`,
  [ApplicationWarningKeys.LOS_APPROVED_WARNING]: $localize`Failed LOS Approval`,
  [ApplicationWarningKeys.LOS_PENDING_WARNING]: $localize`Failed LOS Pending`,
  [ApplicationWarningKeys.LOS_DECLINED_WARNING]: $localize`Failed LOS Decline`,
  [ApplicationWarningKeys.PRODUCT_CHANGE_WARNING]: $localize`Product has changed`,
  [ApplicationWarningKeys.SERVICING_DUPLICATE_SUBMISSION_WARNING]: $localize`This application is a duplicate submission`,
  [ApplicationWarningKeys.PROJECTED_BALANCE_FAILED_WARNING]: $localize`Failed Projected Balance Generation`,
};

export const WarningActionsHumanized: Record<WarningActions, string> = {
  [WarningActions.ADDED]: $localize`Warning`,
  [WarningActions.RESOLVED]: $localize`Resolved`,
};

export const ApplicationStatusTypeRecord: Record<ApplicationStatusType, string> = {
  [ApplicationStatusType.APPROVED]: $localize`Conditionally Approved`,
  [ApplicationStatusType.DECLINED]: $localize`Declined`,
  [ApplicationStatusType.PENDING]: $localize`Pending`,
  [ApplicationStatusType.CANCELLED]: $localize`Cancelled`,
  [ApplicationStatusType.RE_ADJUDICATION]: $localize`Re-Adjudication`,
};

export const InsuranceStatusRecord: Record<InsuranceStatus, string> = {
  [InsuranceStatus.APPROVED]: $localize`Approved`,
  [InsuranceStatus.CANCEL_CONFIRMATION]: $localize`Cancel confirmation`,
  [InsuranceStatus.DECLINED]: $localize`Declined`,
  [InsuranceStatus.ERROR]: $localize`Error`,
  [InsuranceStatus.RECEIVED]: $localize`Received`,
  [InsuranceStatus.REFERRED_TO_UNDERWRITER]: $localize`Referred to Underwriter`,
  [InsuranceStatus.SUBMITTED]: $localize`Submitted`,
  [InsuranceStatus.MANUAL_UNDER_WRITING_REQUIRED]: $localize`Manual Underwriting Required`,
  [InsuranceStatus.INSURABLE]: $localize`Insurable`,
  [InsuranceStatus.NON_INSURABLE]: $localize`Non-insurable`,
  [InsuranceStatus.FINALIZED]: $localize`Finalized`,
};

export const InsurerStatusUIRecord: Record<InsurerStatusUI, string> = {
  [InsurerStatusUI.APPROVED]: $localize`Insurer Approved`,
  [InsurerStatusUI.DECLINED]: $localize`Insurer Declined`,
  [InsurerStatusUI.PENDING]: $localize`Insurer Pending`,
  [InsurerStatusUI.ERROR]: $localize`Insurer Error`,
};

export const ConditionFieldRecord: Record<ConditionField, string> = {
  [ConditionField.Insurer]: $localize`Insurer`,
  [ConditionField.ApplicationPurpose]: $localize`Application Purpose`,
  [ConditionField.ApplicationSource]: $localize`Application Source`,
  [ConditionField.ZoningType]: $localize`Zoning Type`,
  [ConditionField.DwellingType]: $localize`Dwelling Type`,
  [ConditionField.Occupancy]: $localize`Occupancy`,
};

export const LimitTargetFieldRecord: Record<LimitTargetField, string> = {
  [LimitTargetField.LTV]: $localize`LTV`,
  [LimitTargetField.GDS]: $localize`GDS`,
  [LimitTargetField.TDS]: $localize`TDS`,
  [LimitTargetField.QGDS]: $localize`QGDS`,
  [LimitTargetField.QTDS]: $localize`QTDS`,
  [LimitTargetField.LoanAmount]: $localize`Loan Amount`,
  [LimitTargetField.PropertyType]: $localize`Property Type`,
  [LimitTargetField.Occupancy]: $localize`Occupancy`,
  [LimitTargetField.Insurer]: $localize`Insurer`,
  [LimitTargetField.Amortization]: $localize`Amortization`,
  [LimitTargetField.ApplicationPurpose]: $localize`Application Purpose`,
  [LimitTargetField.ApplicationSource]: $localize`Application Source`,
  [LimitTargetField.LoanType]: $localize`Loan Type`,
  [LimitTargetField.PurchasePrice]: $localize`Purchase Price`,
  [LimitTargetField.RateType]: $localize`Rate Type`,
  [LimitTargetField.Term]: $localize`Term`,
  [LimitTargetField.TermType]: $localize`Term Type`,
  [LimitTargetField.CreditScore]: $localize`Credit Score`,
  [LimitTargetField.FirmCode]: $localize`Firm Code`,
};

export const ProvinceRecord: Record<Province, string> = {
  [Province.Alberta]: 'Alberta',
  [Province.British_Columbia]: 'British Columbia',
  [Province.Manitoba]: 'Manitoba',
  [Province.New_Brunswick]: 'New Brunswick',
  [Province.Newfoundland_Labrador]: 'Newfoundland and Labrador',
  [Province.Northwest_Territories]: 'Northwest Territories',
  [Province.Nova_Scotia]: 'Nova Scotia',
  [Province.Nunavut]: 'Nunavut',
  [Province.Ontario]: 'Ontario',
  [Province.Prince_Edward_Island]: 'Prince Edward Island',
  [Province.Quebec]: 'Québec',
  [Province.Saskatchewan]: 'Saskatchewan',
  [Province.Yukon]: 'Yukon',
};

export const StateRecord: Record<State, string> = {
  [State.Alabama]: 'Alabama',
  [State.Alaska]: 'Alaska',
  [State.American_Samoa]: 'American Samoa',
  [State.Arizona]: 'Arizona',
  [State.Arkansas]: 'Arkansas',
  [State.California]: 'California',
  [State.Colorado]: 'Colorado',
  [State.Connecticut]: 'Connecticut',
  [State.Delaware]: 'Delaware',
  [State.District_of_Columbia]: 'District of Columbia',
  [State.Federated_States_of_Micronesia]: 'Federated States of Micronesia',
  [State.Florida]: 'Florida',
  [State.Georgia]: 'Georgia',
  [State.Guam]: 'Guam',
  [State.Hawaii]: 'Hawaii',
  [State.Idaho]: 'Idaho',
  [State.Illinois]: 'Illinois',
  [State.Indiana]: 'Indiana',
  [State.Iowa]: 'Iowa',
  [State.Kansas]: 'Kansas',
  [State.Kentucky]: 'Kentucky',
  [State.Louisiana]: 'Louisiana',
  [State.Maine]: 'Maine',
  [State.Marshall_Islands]: 'Marshall Islands',
  [State.Maryland]: 'Maryland',
  [State.Massachusetts]: 'Massachusetts',
  [State.Michigan]: 'Michigan',
  [State.Minnesota]: 'Minnesota',
  [State.Mississippi]: 'Mississippi',
  [State.Missouri]: 'Missouri',
  [State.Montana]: 'Montana',
  [State.Nebraska]: 'Nebraska',
  [State.Nevada]: 'Nevada',
  [State.New_Hampshire]: 'New Hampshire',
  [State.New_Jersey]: 'New Jersey',
  [State.New_Mexico]: 'New Mexico',
  [State.New_York]: 'New York',
  [State.North_Carolina]: 'North Carolina',
  [State.North_Dakota]: 'North Dakota',
  [State.Northern_Mariana_Islands]: 'Northern Mariana Islands',
  [State.Ohio]: 'Ohio',
  [State.Oklahoma]: 'Oklahoma',
  [State.Oregon]: 'Oregon',
  [State.Palau]: 'Palau',
  [State.Pennsylvania]: 'Pennsylvania',
  [State.Puerto_Rico]: 'Puerto Rico',
  [State.Rhode_Island]: 'Rhode Island',
  [State.South_Carolina]: 'South Carolina',
  [State.South_Dakota]: 'South Dakota',
  [State.Tennessee]: 'Tennessee',
  [State.Texas]: 'Texas',
  [State.Utah]: 'Utah',
  [State.Vermont]: 'Vermont',
  [State.Virgin_Islands]: 'Virgin Islands',
  [State.Virginia]: 'Virginia',
  [State.Washington]: 'Washington',
  [State.West_Virginia]: 'West Virginia',
  [State.Wisconsin]: 'Wisconsin',
  [State.Wyoming]: 'Wyoming',
  [State.Armed_Forces_Americas]: 'Armed Forces Americas (except Canada)',
  [State.Armed_Forces_Europe]: 'Armed Forces Europe, Africa, The Middle East and Canada',
  [State.Armed_Forces_Pacific]: 'Armed Forces Pacific',
};

export const CanadianProvinceCodeRecord: Record<CanadianProvinceCode, string> = {
  [CanadianProvinceCode.BC]: Province.British_Columbia,
  [CanadianProvinceCode.AB]: Province.Alberta,
  [CanadianProvinceCode.ON]: Province.Ontario,
  [CanadianProvinceCode.MB]: Province.Manitoba,
  [CanadianProvinceCode.SK]: Province.Saskatchewan,
  [CanadianProvinceCode.NB]: Province.New_Brunswick,
  [CanadianProvinceCode.NL]: Province.Newfoundland_Labrador,
  [CanadianProvinceCode.NS]: Province.Nova_Scotia,
  [CanadianProvinceCode.NT]: Province.Northwest_Territories,
  [CanadianProvinceCode.NU]: Province.Nunavut,
  [CanadianProvinceCode.PE]: Province.Prince_Edward_Island,
  [CanadianProvinceCode.QC]: Province.Quebec,
  [CanadianProvinceCode.YT]: Province.Yukon,
};

export const USStateCodeRecord: Record<USStateCode, string> = {
  [USStateCode.AA]: State.Armed_Forces_Americas,
  [USStateCode.AL]: State.Alabama,
  [USStateCode.AK]: State.Alaska,
  [USStateCode.AZ]: State.Arizona,
  [USStateCode.AR]: State.Arkansas,
  [USStateCode.CA]: State.California,
  [USStateCode.CO]: State.Colorado,
  [USStateCode.CT]: State.Connecticut,
  [USStateCode.DE]: State.Delaware,
  [USStateCode.DC]: State.District_of_Columbia,
  [USStateCode.FM]: State.Federated_States_of_Micronesia,
  [USStateCode.FL]: State.Florida,
  [USStateCode.GA]: State.Georgia,
  [USStateCode.GU]: State.Guam,
  [USStateCode.HI]: State.Hawaii,
  [USStateCode.ID]: State.Idaho,
  [USStateCode.IL]: State.Illinois,
  [USStateCode.IN]: State.Indiana,
  [USStateCode.IA]: State.Iowa,
  [USStateCode.KS]: State.Kansas,
  [USStateCode.KY]: State.Kentucky,
  [USStateCode.LA]: State.Louisiana,
  [USStateCode.ME]: State.Maine,
  [USStateCode.MH]: State.Marshall_Islands,
  [USStateCode.MD]: State.Maryland,
  [USStateCode.MA]: State.Massachusetts,
  [USStateCode.MI]: State.Michigan,
  [USStateCode.MN]: State.Minnesota,
  [USStateCode.MS]: State.Mississippi,
  [USStateCode.MO]: State.Missouri,
  [USStateCode.MT]: State.Montana,
  [USStateCode.NE]: State.Nebraska,
  [USStateCode.NV]: State.Nevada,
  [USStateCode.NH]: State.New_Hampshire,
  [USStateCode.NJ]: State.New_Jersey,
  [USStateCode.NM]: State.New_Mexico,
  [USStateCode.NY]: State.New_York,
  [USStateCode.NC]: State.North_Carolina,
  [USStateCode.ND]: State.North_Dakota,
  [USStateCode.MP]: State.Northern_Mariana_Islands,
  [USStateCode.OH]: State.Ohio,
  [USStateCode.OK]: State.Oklahoma,
  [USStateCode.OR]: State.Oregon,
  [USStateCode.PW]: State.Palau,
  [USStateCode.PA]: State.Pennsylvania,
  [USStateCode.PR]: State.Puerto_Rico,
  [USStateCode.RI]: State.Rhode_Island,
  [USStateCode.SC]: State.South_Carolina,
  [USStateCode.SD]: State.South_Dakota,
  [USStateCode.TN]: State.Tennessee,
  [USStateCode.TX]: State.Texas,
  [USStateCode.UT]: State.Utah,
  [USStateCode.VT]: State.Vermont,
  [USStateCode.VI]: State.Virgin_Islands,
  [USStateCode.VA]: State.Virginia,
  [USStateCode.WA]: State.Washington,
  [USStateCode.WV]: State.West_Virginia,
  [USStateCode.WI]: State.Wisconsin,
  [USStateCode.WY]: State.Wyoming,
};

export const ProductFeaturesRecord: Record<ProductFeatures, string> = {
  [ProductFeatures.isEdge]: $localize`Edge`,
  [ProductFeatures.isCombo]: $localize`Combo`,
  [ProductFeatures.isInsured]: $localize`Insurable`,
  [ProductFeatures.isLOC]: $localize`Line of Credit`,
};

export const StreetDirectionRecord: Record<StreetDirection, string> = {
  [StreetDirection.N]: $localize`N`,
  [StreetDirection.S]: $localize`S`,
  [StreetDirection.E]: $localize`E`,
  [StreetDirection.W]: $localize`W`,
  [StreetDirection.NE]: $localize`NE`,
  [StreetDirection.NW]: $localize`NW`,
  [StreetDirection.SE]: $localize`SE`,
  [StreetDirection.SW]: $localize`SW`,
};

export const StreetTypeRecord: Record<StreetType, string> = {
  [StreetType.ABBEY]: `Abbey`,
  [StreetType.ACRES]: `Acres`,
  [StreetType.ALLEE]: `Allée`,
  [StreetType.ALLEY]: `Alley`,
  [StreetType.ANNEX]: `Annex`,
  [StreetType.ARCADE]: `Arcade`,
  [StreetType.AUTOROUTE]: `Autoroute`,
  [StreetType.AVENUE]: `Avenue`,
  [StreetType.BAY]: `Bay`,
  [StreetType.BAYOU]: `Bayou`,
  [StreetType.BEACH]: `Beach`,
  [StreetType.BEND]: `Bend`,
  [StreetType.BLUFF]: `Bluff`,
  [StreetType.BLUFFS]: `Bluffs`,
  [StreetType.BOTTOM]: `Bottom`,
  [StreetType.BOULEVARD]: `Boulevard`,
  [StreetType.BRANCH]: `Branch`,
  [StreetType.BRIDGE]: `Bridge`,
  [StreetType.BROOK]: `Brook`,
  [StreetType.BROOKS]: `Brooks`,
  [StreetType.BURG]: `Burg`,
  [StreetType.BURGS]: `Burgs`,
  [StreetType.BY_PASS]: `By-Pass`,
  [StreetType.BYPASS]: `Bypass`,
  [StreetType.BYWAY]: `Byway`,
  [StreetType.CAMP]: `Camp`,
  [StreetType.CAMPUS]: `Campus`,
  [StreetType.CANYON]: `Canyon`,
  [StreetType.CAPE]: `Cape`,
  [StreetType.CARRE]: `Carré`,
  [StreetType.CARREFOUR]: `Carrefour`,
  [StreetType.CAUSEWAY]: `Causeway`,
  [StreetType.CENTER]: `Center`,
  [StreetType.CENTERS]: `Centers`,
  [StreetType.CENTRE]: `Centre`,
  [StreetType.CERCLE]: `Cercle`,
  [StreetType.CHASE]: `Chase`,
  [StreetType.CHEMIN]: `Chemin`,
  [StreetType.CIRCLE]: `Circle`,
  [StreetType.CIRCLES]: `Circles`,
  [StreetType.CIRCUIT]: `Circuit`,
  [StreetType.CLIFF]: `Cliff`,
  [StreetType.CLIFFS]: `Cliffs`,
  [StreetType.CLOSE]: `Close`,
  [StreetType.CLUB]: `Club`,
  [StreetType.COMMON]: `Common`,
  [StreetType.COMMONS]: `Commons`,
  [StreetType.CONCESSION]: `Concession`,
  [StreetType.CORNER]: `Corner`,
  [StreetType.CORNERS]: `Corners`,
  [StreetType.COTE]: `Côte`,
  [StreetType.COUR]: `Cour`,
  [StreetType.COURS]: `Cours`,
  [StreetType.COURSE]: `Course`,
  [StreetType.COURT]: `Court`,
  [StreetType.COURTS]: `Courts`,
  [StreetType.COVE]: `Cove`,
  [StreetType.COVES]: `Coves`,
  [StreetType.CREEK]: `Creek`,
  [StreetType.CRESCENT]: `Crescent`,
  [StreetType.CREST]: `Crest`,
  [StreetType.CROISSANT]: `Croissant`,
  [StreetType.CROSSING]: `Crossing`,
  [StreetType.CROSSROAD]: `Crossroad`,
  [StreetType.CROSSROADS]: `Crossroads`,
  [StreetType.CUL_DE_SAC]: `Cul-de-sac`,
  [StreetType.CURVE]: `Curve`,
  [StreetType.DALE]: `Dale`,
  [StreetType.DAM]: `Dam`,
  [StreetType.DELL]: `Dell`,
  [StreetType.DIVERSION]: `Diversion`,
  [StreetType.DIVIDE]: `Divide`,
  [StreetType.DOWNS]: `Downs`,
  [StreetType.DRIVE]: `Drive`,
  [StreetType.DRIVES]: `Drives`,
  [StreetType.ECHANGEUR]: `Échangeur`,
  [StreetType.END]: `End`,
  [StreetType.ESPLANADE]: `Esplanade`,
  [StreetType.ESTATE]: `Estate`,
  [StreetType.ESTATES]: `Estates`,
  [StreetType.EXPRESSWAY]: `Expressway`,
  [StreetType.EXTENSION]: `Extension`,
  [StreetType.EXTENSIONS]: `Extensions`,
  [StreetType.FALL]: `Fall`,
  [StreetType.FALLS]: `Falls`,
  [StreetType.FARM]: `Farm`,
  [StreetType.FERRY]: `Ferry`,
  [StreetType.FIELD]: `Field`,
  [StreetType.FIELDS]: `Fields`,
  [StreetType.FLAT]: `Flat`,
  [StreetType.FLATS]: `Flats`,
  [StreetType.FORD]: `Ford`,
  [StreetType.FORDS]: `Fords`,
  [StreetType.FOREST]: `Forest`,
  [StreetType.FORGE]: `Forge`,
  [StreetType.FORGES]: `Forges`,
  [StreetType.FORK]: `Fork`,
  [StreetType.FORKS]: `Forks`,
  [StreetType.FORT]: `Fort`,
  [StreetType.FREEWAY]: `Freeway`,
  [StreetType.FRONT]: `Front`,
  [StreetType.GARDEN]: `Garden`,
  [StreetType.GARDENS]: `Gardens`,
  [StreetType.GATE]: `Gate`,
  [StreetType.GATEWAY]: `Gateway`,
  [StreetType.GLADE]: `Glade`,
  [StreetType.GLEN]: `Glen`,
  [StreetType.GLENS]: `Glens`,
  [StreetType.GREEN]: `Green`,
  [StreetType.GREENS]: `Greens`,
  [StreetType.GROUNDS]: `Grounds`,
  [StreetType.GROVE]: `Grove`,
  [StreetType.GROVES]: `Groves`,
  [StreetType.HARBOR]: `Harbor`,
  [StreetType.HARBORS]: `Harbors`,
  [StreetType.HARBOUR]: `Harbour`,
  [StreetType.HAVEN]: `Haven`,
  [StreetType.HEATH]: `Heath`,
  [StreetType.HEIGHTS]: `Heights`,
  [StreetType.HIGHLANDS]: `Highlands`,
  [StreetType.HIGHWAY]: `Highway`,
  [StreetType.HILL]: `Hill`,
  [StreetType.HILLS]: `Hills`,
  [StreetType.HOLLOW]: `Hollow`,
  [StreetType.ILE]: `Île`,
  [StreetType.IMPASSE]: `Impasse`,
  [StreetType.INLET]: `Inlet`,
  [StreetType.ISLAND]: `Island`,
  [StreetType.ISLANDS]: `Islands`,
  [StreetType.ISLE]: `Isle`,
  [StreetType.JUNCTION]: `Junction`,
  [StreetType.JUNCTIONS]: `Junctions`,
  [StreetType.KEY]: `Key`,
  [StreetType.KEYS]: `Keys`,
  [StreetType.KNOLL]: `Knoll`,
  [StreetType.KNOLLS]: `Knolls`,
  [StreetType.LAKE]: `Lake`,
  [StreetType.LAKES]: `Lakes`,
  [StreetType.LAND]: `Land`,
  [StreetType.LANDING]: `Landing`,
  [StreetType.LANE]: `Lane`,
  [StreetType.LIGHT]: `Light`,
  [StreetType.LIGHTS]: `Lights`,
  [StreetType.LIMITS]: `Limits`,
  [StreetType.LINE]: `Line`,
  [StreetType.LINK]: `Link`,
  [StreetType.LOAF]: `Loaf`,
  [StreetType.LOCK]: `Lock`,
  [StreetType.LOCKS]: `Locks`,
  [StreetType.LODGE]: `Lodge`,
  [StreetType.LOOKOUT]: `Lookout`,
  [StreetType.LOOP]: `Loop`,
  [StreetType.MALL]: `Mall`,
  [StreetType.MANOR]: `Manor`,
  [StreetType.MANORS]: `Manors`,
  [StreetType.MAZE]: `Maze`,
  [StreetType.MEADOW]: `Meadow`,
  [StreetType.MEADOWS]: `Meadows`,
  [StreetType.MEWS]: `Mews`,
  [StreetType.MILL]: `Mill`,
  [StreetType.MILLS]: `Mills`,
  [StreetType.MISSION]: `Mission`,
  [StreetType.MONTEE]: `Montée`,
  [StreetType.MOOR]: `Moor`,
  [StreetType.MOTORWAY]: `Motorway`,
  [StreetType.MOUNT]: `Mount`,
  [StreetType.MOUNTAIN]: `Mountain`,
  [StreetType.MOUNTAINS]: `Mountains`,
  [StreetType.NECK]: `Neck`,
  [StreetType.ORCHARD]: `Orchard`,
  [StreetType.PARADE]: `Parade`,
  [StreetType.PARC]: `Parc`,
  [StreetType.PARK]: `Park`,
  [StreetType.PARKS]: `Parks`,
  [StreetType.PARKWAY]: `Parkway`,
  [StreetType.PARKWAYS]: `Parkways`,
  [StreetType.PASS]: `Pass`,
  [StreetType.PASSAGE]: `Passage`,
  [StreetType.PATH]: `Path`,
  [StreetType.PATHWAY]: `Pathway`,
  [StreetType.PIKE]: `Pike`,
  [StreetType.PINE]: `Pine`,
  [StreetType.PINES]: `Pines`,
  [StreetType.PLACE]: `Place`,
  [StreetType.PLAIN]: `Plain`,
  [StreetType.PLAINS]: `Plains`,
  [StreetType.PLATEAU]: `Plateau`,
  [StreetType.PLAZA]: `Plaza`,
  [StreetType.POINT]: `Point`,
  [StreetType.POINTE]: `Pointe`,
  [StreetType.POINTS]: `Points`,
  [StreetType.PORT]: `Port`,
  [StreetType.PORTS]: `Ports`,
  [StreetType.PRAIRIE]: `Prairie`,
  [StreetType.PRIVATE]: `Private`,
  [StreetType.PROMENADE]: `Promenade`,
  [StreetType.QUAI]: `Quai`,
  [StreetType.QUAY]: `Quay`,
  [StreetType.RADIAL]: `Radial`,
  [StreetType.RAMP]: `Ramp`,
  [StreetType.RANCH]: `Ranch`,
  [StreetType.RANG]: `Rang`,
  [StreetType.RANGE]: `Range`,
  [StreetType.RAPID]: `Rapid`,
  [StreetType.RAPIDS]: `Rapids`,
  [StreetType.REST]: `Rest`,
  [StreetType.RIDGE]: `Ridge`,
  [StreetType.RIDGES]: `Ridges`,
  [StreetType.RISE]: `Rise`,
  [StreetType.RIVER]: `River`,
  [StreetType.ROAD]: `Road`,
  [StreetType.ROADS]: `Roads`,
  [StreetType.ROND_POINT]: `Rond-point`,
  [StreetType.ROUTE]: `Route`,
  [StreetType.ROW]: `Row`,
  [StreetType.RUE]: `Rue`,
  [StreetType.RUELLE]: `Ruelle`,
  [StreetType.RUN]: `Run`,
  [StreetType.SENTIER]: `Sentier`,
  [StreetType.SHOAL]: `Shoal`,
  [StreetType.SHOALS]: `Shoals`,
  [StreetType.SHORE]: `Shore`,
  [StreetType.SHORES]: `Shores`,
  [StreetType.SKYWAY]: `Skyway`,
  [StreetType.SPRING]: `Spring`,
  [StreetType.SPRINGS]: `Springs`,
  [StreetType.SPUR]: `Spur`,
  [StreetType.SPURS]: `Spurs`,
  [StreetType.SQUARE]: `Square`,
  [StreetType.SQUARES]: `Squares`,
  [StreetType.STATION]: `Station`,
  [StreetType.STRAVENUE]: `Stravenue`,
  [StreetType.STREAM]: `Stream`,
  [StreetType.STREET]: `Street`,
  [StreetType.STREETS]: `Streets`,
  [StreetType.SUBDIVISION]: `Subdivision`,
  [StreetType.SUMMIT]: `Summit`,
  [StreetType.TERRACE]: `Terrace`,
  [StreetType.TERRASSE]: `Terrasse`,
  [StreetType.THICKET]: `Thicket`,
  [StreetType.THROUGHWAY]: `Throughway`,
  [StreetType.TOWERS]: `Towers`,
  [StreetType.TOWNLINE]: `Townline`,
  [StreetType.TRACE]: `Trace`,
  [StreetType.TRACK]: `Track`,
  [StreetType.TRAFFICWAY]: `Trafficway`,
  [StreetType.TRAIL]: `Trail`,
  [StreetType.TRAILER]: `Trailer`,
  [StreetType.TUNNEL]: `Tunnel`,
  [StreetType.TURNABOUT]: `Turnabout`,
  [StreetType.TURNPIKE]: `Turnpike`,
  [StreetType.UNDERPASS]: `Underpass`,
  [StreetType.UNION]: `Union`,
  [StreetType.UNIONS]: `Unions`,
  [StreetType.VALE]: `Vale`,
  [StreetType.VALLEY]: `Valley`,
  [StreetType.VALLEYS]: `Valleys`,
  [StreetType.VIA]: `Via`,
  [StreetType.VIADUCT]: `Viaduct`,
  [StreetType.VIEW]: `View`,
  [StreetType.VIEWS]: `Views`,
  [StreetType.VILLAGE]: `Village`,
  [StreetType.VILLAGES]: `Villages`,
  [StreetType.VILLAS]: `Villas`,
  [StreetType.VILLE]: `Ville`,
  [StreetType.VISTA]: `Vista`,
  [StreetType.VOIE]: `Voie`,
  [StreetType.WALK]: `Walk`,
  [StreetType.WALKS]: `Walks`,
  [StreetType.WALL]: `Wall`,
  [StreetType.WAY]: `Way`,
  [StreetType.WAYS]: `Ways`,
  [StreetType.WELL]: `Well`,
  [StreetType.WELLS]: `Wells`,
  [StreetType.WHARF]: `Wharf`,
  [StreetType.WOOD]: `Wood`,
  [StreetType.WYND]: `Wynd`,
};

export const ApplicationEntityRecord: Record<ApplicationEntity, string> = {
  [ApplicationEntity.APPLICATION]: $localize`:@@ApplicationEntity.APPLICATION:Application`,
  [ApplicationEntity.APPLICATION_TASK]: $localize`:@@ApplicationEntity.APPLICATION_TASK:Task`,
  [ApplicationEntity.EXISTING_MORTGAGE]: $localize`:@@ApplicationEntity.EXISTING_MORTGAGE:Existing Mortgage`,
  [ApplicationEntity.REQUESTED_MORTGAGE]: $localize`:@@ApplicationEntity.REQUESTED_MORTGAGE:Requested Mortgage`,
  [ApplicationEntity.FINANCIAL_ASSET]: $localize`:@@ApplicationEntity.FINANCIAL_ASSET:Financial Asset`,
  [ApplicationEntity.DOWN_PAYMENT]: $localize`:@@ApplicationEntity.DOWN_PAYMENT:Down Payment`,
  [ApplicationEntity.FEE]: $localize`:@@ApplicationEntity.FEE:Fee`,
  [ApplicationEntity.PROPERTY]: $localize`:@@ApplicationEntity.PROPERTY:Property`,
  [ApplicationEntity.PRIMARY]: $localize`:@@ApplicationEntity.PRIMARY:Primary`,
  [ApplicationEntity.EXTERNAL]: $localize`:@@ApplicationEntity.EXTERNAL:External`,
  [ApplicationEntity.OTHER]: $localize`:@@ApplicationEntity.OTHER:Other`,
  [ApplicationEntity.APPLICANT]: $localize`:@@ApplicationEntity.APPLICANT:Stakeholder`,
  [ApplicationEntity.APPLICATION_CONDITION]: $localize`:@@ApplicationEntity.APPLICATION_CONDITION:Condition`,
  [ApplicationEntity.APPLICATION_CONDITION_DOCUMENT_FILE]: $localize`:@@ApplicationEntity.APPLICATION_CONDITION_DOCUMENT_FILE:Document`,
  [ApplicationEntity.APPLICATION_NOTE]: $localize`:@@ApplicationEntity.APPLICATION_NOTE:Note`,
  [ApplicationEntity.APPLICATION_APPROVAL]: $localize`:@@ApplicationEntity.APPLICATION_APPROVAL:Approval`,
  [ApplicationEntity.APPLICATION_COMPUTED_DATA]: $localize`:@@ApplicationEntity.APPLICATION_COMPUTED_DATA:Ratios`,
  [ApplicationEntity.APPLICATION_STATUS]: $localize`:@@ApplicationEntity.APPLICATION_STATUS:Status`,
  [ApplicationEntity.APPLICATION_ASSIGNED_USER]: $localize`:@@ApplicationEntity.APPLICATION_ASSIGNED_USER:Team`,
  [ApplicationEntity.PROPERTY_OWNER]: $localize`:@@ApplicationEntity.PROPERTY_OWNER:Property Owner`,
  [ApplicationEntity.JOB]: $localize`:@@ApplicationEntity.JOB:Job`,
  [ApplicationEntity.OTHER_INCOME]: $localize`:@@ApplicationEntity.OTHER_INCOME:Other Income`,
  [ApplicationEntity.APPLICATION_INSURANCE_QUOTE]: $localize`:@@ApplicationEntity.APPLICATION_INSURANCE_QUOTE:Insurance Quote`,
  [ApplicationEntity.ACTIVE_TRADELINES]: $localize`:@@ApplicationEntity.ACTIVE_TRADELINES:Active Tradelines`,
  [ApplicationEntity.COLLECTIONS_JUDGEMENTS]: $localize`:@@ApplicationEntity.COLLECTIONS_JUDGEMENTS:Collections/Judgements`,
};

export const ApplicationLogActionRecord: Record<ApplicationLogAction, string> = {
  [ApplicationLogAction.ACTIVE_TRADELINES_CREATE]: $localize`:@@ApplicationLogs.ACTIVE_TRADELINES_CREATE:created`,
  [ApplicationLogAction.ACTIVE_TRADELINES_UPDATE]: $localize`:@@ApplicationLogs.ACTIVE_TRADELINES_UPDATE:updated`,
  [ApplicationLogAction.ACTIVE_TRADELINES_DELETE]: $localize`:@@ApplicationLogs.ACTIVE_TRADELINES_DELETE:deleted`,
  [ApplicationLogAction.ACTIVE_TRADELINES_ASSIGN_APPLICANT]: $localize`:@@ApplicationLogs.ACTIVE_TRADELINES_ASSIGN_APPLICANT:assign`,
  [ApplicationLogAction.ACTIVE_TRADELINES_UNASSIGN_APPLICANT]: $localize`:@@ApplicationLogs.ACTIVE_TRADELINES_UNASSIGN_APPLICANT:unassign`,
  [ApplicationLogAction.APPLICATION_CREATE]: $localize`:@@ApplicationLogs.APPLICATION_CREATE:created`,
  [ApplicationLogAction.APPLICATION_UPDATE]: $localize`:@@ApplicationLogs.APPLICATION_UPDATE:updated`,
  [ApplicationLogAction.APPLICATION_DELETE]: $localize`:@@ApplicationLogs.APPLICATION_DELETE:deleted`,
  [ApplicationLogAction.APPLICANT_CREATE]: $localize`:@@ApplicationLogs.APPLICANT_CREATE:created`,
  [ApplicationLogAction.APPLICANT_UPDATE]: $localize`:@@ApplicationLogs.APPLICANT_UPDATE:updated`,
  [ApplicationLogAction.APPLICANT_DELETE]: $localize`:@@ApplicationLogs.APPLICANT_DELETE:deleted`,
  [ApplicationLogAction.APPLICATION_UPDATE_DOCUMENT_DUE_DATE]: $localize`:@@ApplicationLogs.APPLICATION_UPDATE_DOCUMENT_DUE_DATE:updated docs due date`,
  [ApplicationLogAction.APPLICATION_UPDATE_LOCK_STATUS]: $localize`:@@ApplicationLogs.APPLICATION_UPDATE_LOCK_STATUS:updated lock status`,
  [ApplicationLogAction.COLLECTIONS_OR_JUDGEMENTS_CREATE]: $localize`:@@ApplicationLogs.COLLECTIONS_OR_JUDGEMENTS_CREATE:created`,
  [ApplicationLogAction.COLLECTIONS_OR_JUDGEMENTS_UPDATE]: $localize`:@@ApplicationLogs.COLLECTIONS_OR_JUDGEMENTS_UPDATE:updated`,
  [ApplicationLogAction.COLLECTIONS_OR_JUDGEMENTS_DELETE]: $localize`:@@ApplicationLogs.COLLECTIONS_OR_JUDGEMENTS_DELETE:deleted`,
  [ApplicationLogAction.COLLECTIONS_OR_JUDGEMENTS_ASSIGN_APPLICANT]: $localize`:@@ApplicationLogs.COLLECTIONS_OR_JUDGEMENTS_ASSIGN_APPLICANT:assign`,
  [ApplicationLogAction.COLLECTIONS_OR_JUDGEMENTS_UNASSIGN_APPLICANT]: $localize`:@@ApplicationLogs.COLLECTIONS_OR_JUDGEMENTS_UNASSIGN_APPLICANT:unassign`,
  [ApplicationLogAction.DOWN_PAYMENT_CREATE]: $localize`:@@ApplicationLogs.DOWN_PAYMENT_CREATE:created`,
  [ApplicationLogAction.DOWN_PAYMENT_UPDATE]: $localize`:@@ApplicationLogs.DOWN_PAYMENT_UPDATE:updated`,
  [ApplicationLogAction.DOWN_PAYMENT_DELETE]: $localize`:@@ApplicationLogs.DOWN_PAYMENT_DELETE:deleted`,
  [ApplicationLogAction.FINANCIAL_ASSET_CREATE]: $localize`:@@ApplicationLogs.FINANCIAL_ASSET_CREATE:created`,
  [ApplicationLogAction.FINANCIAL_ASSET_UPDATE]: $localize`:@@ApplicationLogs.FINANCIAL_ASSET_UPDATE:updated`,
  [ApplicationLogAction.FINANCIAL_ASSET_DELETE]: $localize`:@@ApplicationLogs.FINANCIAL_ASSET_DELETE:deleted`,
  [ApplicationLogAction.JOB_CREATE]: $localize`:@@ApplicationLogs.JOB_CREATE:created`,
  [ApplicationLogAction.JOB_UPDATE]: $localize`:@@ApplicationLogs.JOB_UPDATE:updated`,
  [ApplicationLogAction.JOB_DELETE]: $localize`:@@ApplicationLogs.JOB_DELETE:deleted`,
  [ApplicationLogAction.MORTGAGE_CREATE]: $localize`:@@ApplicationLogs.MORTGAGE_CREATE:created`,
  [ApplicationLogAction.MORTGAGE_UPDATE]: $localize`:@@ApplicationLogs.MORTGAGE_UPDATE:updated`,
  [ApplicationLogAction.MORTGAGE_DELETE]: $localize`:@@ApplicationLogs.MORTGAGE_DELETE:deleted`,
  [ApplicationLogAction.OTHER_INCOME_CREATE]: $localize`:@@ApplicationLogs.OTHER_INCOME_CREATE:created`,
  [ApplicationLogAction.OTHER_INCOME_UPDATE]: $localize`:@@ApplicationLogs.OTHER_INCOME_UPDATE:updated`,
  [ApplicationLogAction.OTHER_INCOME_DELETE]: $localize`:@@ApplicationLogs.OTHER_INCOME_DELETE:deleted`,
  [ApplicationLogAction.PROPERTY_CREATE]: $localize`:@@ApplicationLogs.PROPERTY_CREATE:created`,
  [ApplicationLogAction.PROPERTY_UPDATE]: $localize`:@@ApplicationLogs.PROPERTY_UPDATE:updated`,
  [ApplicationLogAction.PROPERTY_DELETE]: $localize`:@@ApplicationLogs.PROPERTY_DELETE:deleted`,
  [ApplicationLogAction.PROPERTY_OWNER_CREATE]: $localize`:@@ApplicationLogs.PROPERTY_OWNER_CREATE:created`,
  [ApplicationLogAction.PROPERTY_OWNER_UPDATE]: $localize`:@@ApplicationLogs.PROPERTY_OWNER_UPDATE:updated`,
  [ApplicationLogAction.PROPERTY_OWNER_DELETE]: $localize`:@@ApplicationLogs.PROPERTY_OWNER_DELETE:deleted`,
  [ApplicationLogAction.CREATED_DEFAULT]: $localize`:@@ApplicationLogs.CREATED_DEFAULT:created`,
  [ApplicationLogAction.UPDATED_DEFAULT]: $localize`:@@ApplicationLogs.UPDATED_DEFAULT:updated`,
  [ApplicationLogAction.DELETED_DEFAULT]: $localize`:@@ApplicationLogs.DELETED_DEFAULT:deleted`,
  [ApplicationLogAction.APPLICATION_ARCHIVED]: $localize`:@@ApplicationLogs.APPLICATION_ARCHIVED:updated archived status on`,
  [ApplicationLogAction.APPLICATION_CHANGE_STAGE]: $localize`:@@ApplicationLogs.APPLICATION_CHANGE_STAGE:updated stage on`,
  [ApplicationLogAction.APPLICATION_DECLINE]: $localize`:@@ApplicationLogs.APPLICATION_DECLINE:declined`,
  [ApplicationLogAction.APPLICATION_UPDATE_ASSIGNED_USERS]: $localize`:@@ApplicationLogs.APPLICATION_UPDATE_ASSIGNED_USERS:updated assigned users on`,
  [ApplicationLogAction.APPLICATION_CONDITIONALLY_APPROVE]: $localize`:@@ApplicationLogs.APPLICATION_CONDITIONALLY_APPROVE:conditionally approved application`,
  [ApplicationLogAction.APPLICATION_MARK_PENDING]: $localize`:@@ApplicationLogs.APPLICATION_MARK_PENDING:marked the application as pending`,
  [ApplicationLogAction.APPLICATION_MARK_COMMITTED]: $localize`:@@ApplicationLogs.APPLICATION_MARK_COMMITTED:marked the application as committed`,
  [ApplicationLogAction.APPLICATION_MARK_CREDIT_CARD_FULFILLED]: $localize`:@@ApplicationLogs.APPLICATION_MARK_CREDIT_CARD_FULFILLED:marked the application as credit card fulfilled`,
  [ApplicationLogAction.APPLICATION_MARK_AWAITING_DOCS]: $localize`:@@ApplicationLogs.APPLICATION_MARK_AWAITING_DOCS:marked the application as awaiting docs`,
  [ApplicationLogAction.TASK_CREATE]: $localize`:@@ApplicationLogs.TASK_CREATE:created`,
  [ApplicationLogAction.TASK_UPDATE]: $localize`:@@ApplicationLogs.TASK_UPDATE:updated`,
  [ApplicationLogAction.TASK_DELETE]: $localize`:@@ApplicationLogs.TASK_DELETE:deleted`,
  [ApplicationLogAction.TASK_BYPASS]: $localize`:@@ApplicationLogs.TASK_BYPASS:bypassed`,
  [ApplicationLogAction.TASK_UPDATE_COMPLETION]: $localize`:@@ApplicationLogs.TASK_UPDATE_COMPLETION:updated completion status on`,
  [ApplicationLogAction.FINANCIAL_ASSET_USE_AS_DOWN_PAYMENT]: $localize`:@@ApplicationLogs.FINANCIAL_ASSET_USE_AS_DOWN_PAYMENT:checked Financial Asset as`,
  [ApplicationLogAction.FINANCIAL_ASSET_DELETE_USE_AS_DOWN_PAYMENT]: $localize`:@@ApplicationLogs.FINANCIAL_ASSET_DELETE_USE_AS_DOWN_PAYMENT:unchecked Financial Asset as`,
  [ApplicationLogAction.CREDIT_REPORT_REQUEST_EQUIFAX]: $localize`:@@ApplicationLogs.CREDIT_REPORT_REQUEST_EQUIFAX:pulled Equifax report`,
  [ApplicationLogAction.CREDIT_REPORT_APPLY_CHANGES]: $localize`:@@ApplicationLogs.CREDIT_REPORT_APPLY_CHANGES:applied changes`,
  [ApplicationLogAction.CREDIT_REPORT_UPDATE_SCORE]: $localize`:@@ApplicationLogs.CREDIT_REPORT_UPDATE_SCORE:updated score`,
  [ApplicationLogAction.FEE_CREATE]: $localize`:@@ApplicationLogs.FEE_CREATE:created fee`,
  [ApplicationLogAction.FEE_UPDATE]: $localize`:@@ApplicationLogs.FEE_UPDATE:updated fee`,
  [ApplicationLogAction.FEE_DELETE]: $localize`:@@ApplicationLogs.FEE_DELETE:deleted fee`,
  [ApplicationLogAction.CONDITION_CREATE]: $localize`:@@ApplicationLogs.CONDITION_CREATE:created`,
  [ApplicationLogAction.CONDITION_UPDATE]: $localize`:@@ApplicationLogs.CONDITION_UPDATE:updated`,
  [ApplicationLogAction.CONDITION_DELETE]: $localize`:@@ApplicationLogs.CONDITION_DELETE:deleted`,
  [ApplicationLogAction.CONDITION_STATUS_CHANGE]: $localize`:@@ApplicationLogs.CONDITION_STATUS_CHANGE:updated status on`,
  [ApplicationLogAction.DOCUMENT_CREATE]: $localize`:@@ApplicationLogs.DOCUMENT_CREATE:uploaded document`,
  [ApplicationLogAction.DOCUMENT_UPDATE]: $localize`:@@ApplicationLogs.DOCUMENT_UPDATE:updated document`,
  [ApplicationLogAction.DOCUMENT_DELETE]: $localize`:@@ApplicationLogs.DOCUMENT_DELETE:deleted document`,
  [ApplicationLogAction.DOCUMENT_STATUS_CHANGE]: $localize`:@@ApplicationLogs.DOCUMENT_STATUS_CHANGE:updated status on`,
  [ApplicationLogAction.DOCUMENT_COMMENT_CREATE]: $localize`:@@ApplicationLogs.DOCUMENT_COMMENT_CREATE:commented on`,
  [ApplicationLogAction.NOTE_CREATE]: $localize`:@@ApplicationLogs.NOTE_CREATE:created`,
  [ApplicationLogAction.NOTE_UPDATE]: $localize`:@@ApplicationLogs.NOTE_UPDATE:updated`,
  [ApplicationLogAction.NOTE_DELETE]: $localize`:@@ApplicationLogs.NOTE_DELETE:deleted`,
  [ApplicationLogAction.APPROVAL_REQUESTED]: $localize`:@@ApplicationLogs.APPROVAL_REQUESTED:requested`,
  [ApplicationLogAction.APPROVAL_RE_REQUESTED]: $localize`:@@ApplicationLogs.APPROVAL_RE_REQUESTED:re-requested`,
  [ApplicationLogAction.APPROVAL_REVIEWED]: $localize`:@@ApplicationLogs.APPROVAL_REVIEWED:reviewed`,
  [ApplicationLogAction.APPROVAL_CANCEL]: $localize`:@@ApplicationLogs.APPROVAL_CANCEL:canceled`,
  [ApplicationLogAction.APPLICATION_SEND_TO_INTELLIFI]: $localize`:@@ApplicationLogs.APPLICATION_SEND_TO_INTELLIFI:sent the application to servicing (Intellifi)`,
  [ApplicationLogAction.APPLICATION_ASSIGNED_USER]: $localize`:@@ApplicationEntity.APPLICATION_ASSIGNED_USER:Team`,
  [ApplicationLogAction.PROPERTY_MARKET_VALUATION_REQUESTED]: $localize`:@@ApplicationLogs.PROPERTY_MARKET_VALUATION_REQUESTED:requested property valuation (AVM) for`,
  [ApplicationLogAction.PROPERTY_RESIDENTIAL_PREFILL_REQUESTED]: $localize`:@@ApplicationLogs.PROPERTY_RESIDENTIAL_PREFILL_REQUESTED:requested residential building prefill (iClarify) for`,
  [ApplicationLogAction.APPLICATION_INSURANCE_QUOTE_CMHC_CREATED]: $localize`:@@ApplicationLogs.APPLICATION_INSURANCE_QUOTE_CMHC_CREATED:added insurance request`,
  [ApplicationLogAction.APPLICATION_INSURANCE_QUOTE_CMHC_RESUBMISSION]: $localize`:@@ApplicationLogs.APPLICATION_INSURANCE_QUOTE_CMHC_RESUBMISSION:canceled insurance request`,
  [ApplicationLogAction.APPLICATION_INSURANCE_QUOTE_CMHC_REMOVED]: $localize`:@@ApplicationLogs.APPLICATION_INSURANCE_QUOTE_CMHC_REMOVED:resubmitted insurance request`,
  [ApplicationLogAction.APPLICATION_INSURANCE_QUOTE_CMHC_STATUS_UPDATE]: $localize`:@@ApplicationLogs.APPLICATION_INSURANCE_QUOTE_CMHC_STATUS_UPDATE:request status was updated`,
  [ApplicationLogAction.APPLICATION_INSURANCE_QUOTE_SAGEN_CREATED]: $localize`:@@ApplicationLogs.APPLICATION_INSURANCE_QUOTE_SAGEN_CREATED:added insurance request`,
  [ApplicationLogAction.APPLICATION_INSURANCE_QUOTE_SAGEN_RESUBMISSION]: $localize`:@@ApplicationLogs.APPLICATION_INSURANCE_QUOTE_SAGEN_RESUBMISSION:canceled insurance request`,
  [ApplicationLogAction.APPLICATION_INSURANCE_QUOTE_SAGEN_REMOVED]: $localize`:@@ApplicationLogs.APPLICATION_INSURANCE_QUOTE_SAGEN_REMOVED:resubmitted insurance request`,
  [ApplicationLogAction.APPLICATION_INSURANCE_QUOTE_SAGEN_STATUS_UPDATE]: $localize`:@@ApplicationLogs.APPLICATION_INSURANCE_QUOTE_SAGEN_STATUS_UPDATE:request status was updated`,
  [ApplicationLogAction.APPLICATION_INSURANCE_QUOTE_CG_CREATED]: $localize`:@@ApplicationLogs.APPLICATION_INSURANCE_QUOTE_CG_CREATED:added insurance request`,
  [ApplicationLogAction.APPLICATION_INSURANCE_QUOTE_CG_RESUBMISSION]: $localize`:@@ApplicationLogs.APPLICATION_INSURANCE_QUOTE_CG_RESUBMISSION:canceled insurance request`,
  [ApplicationLogAction.APPLICATION_INSURANCE_QUOTE_CG_REMOVED]: $localize`:@@ApplicationLogs.APPLICATION_INSURANCE_QUOTE_CG_REMOVED:resubmitted insurance request`,
  [ApplicationLogAction.APPLICATION_INSURANCE_QUOTE_CG_STATUS_UPDATE]: $localize`:@@ApplicationLogs.APPLICATION_INSURANCE_QUOTE_CG_STATUS_UPDATE:request status was updated`,
  [ApplicationLogAction.COMPUTED_DATA_UPDATE]: $localize`:@@ApplicationLogs.COMPUTED_DATA_UPDATE:PLACEHOLDER`,
  [ApplicationLogAction.NET_WORTH_UPDATE]: $localize`:@@ApplicationLogs.NET_WORTH_UPDATE:PLACEHOLDER`,
  [ApplicationLogAction.MORTGAGE_COMPUTED_DATA_UPDATE]: $localize`:@@ApplicationLogs.MORTGAGE_COMPUTED_DATA_UPDATE:PLACEHOLDER`,
  [ApplicationLogAction.APPRAISAL_CREATE]: $localize`:@@ApplicationLogs.APPRAISAL_CREATE:PLACEHOLDER`,
  [ApplicationLogAction.APPRAISAL_UPDATE]: $localize`:@@ApplicationLogs.APPRAISAL_UPDATE:PLACEHOLDER`,
  [ApplicationLogAction.APPRAISAL_DELETE]: $localize`:@@ApplicationLogs.APPRAISAL_DELETE:PLACEHOLDER`,
  [ApplicationLogAction.APPRAISAL_SET_AS_PROPERTY_VALUE]: $localize`:@@ApplicationLogs.APPRAISAL_SET_AS_PROPERTY_VALUE:PLACEHOLDER`,
  [ApplicationLogAction.DOCUMENT_LOG_ACTION]: $localize`:@@ApplicationLogs.DOCUMENT_LOG_ACTION:PLACEHOLDER`,
  [ApplicationLogAction.PROPERTY_INSURANCE_CREATE]: $localize`:@@ApplicationLogs.PROPERTY_INSURANCE_CREATE:PLACEHOLDER`,
  [ApplicationLogAction.PROPERTY_INSURANCE_UPDATE]: $localize`:@@ApplicationLogs.PROPERTY_INSURANCE_UPDATE:PLACEHOLDER`,
  [ApplicationLogAction.PROPERTY_INSURANCE_DELETE]: $localize`:@@ApplicationLogs.PROPERTY_INSURANCE_DELETE:PLACEHOLDER`,
  [ApplicationLogAction.ADDRESS_EXPANDED_CREATE]: $localize`:@@ApplicationLogs.ADDRESS_EXPANDED_CREATE:PLACEHOLDER`,
  [ApplicationLogAction.ADDRESS_EXPANDED_UPDATE]: $localize`:@@ApplicationLogs.ADDRESS_EXPANDED_UPDATE:PLACEHOLDER`,
  [ApplicationLogAction.ADDRESS_EXPANDED_DELETE]: $localize`:@@ApplicationLogs.ADDRESS_EXPANDED_DELETE:PLACEHOLDER`,
  [ApplicationLogAction.APPLICATION_BROKER_COMMISSION_UPSERT]: $localize`:@@ApplicationLogs.APPLICATION_BROKER_COMMISSION_UPSERT:PLACEHOLDER`,
  [ApplicationLogAction.FCT_MMS_CREATE_REQUEST]: $localize`:@@ApplicationLogs.FCT_MMS_CREATE_REQUEST:PLACEHOLDER`,
  [ApplicationLogAction.FCT_MMS_UPDATE_REQUEST]: $localize`:@@ApplicationLogs.FCT_MMS_UPDATE_REQUEST:PLACEHOLDER`,
};

export const AdvancedProductInUseErrorMessageRecord: Record<AdvancedProductInUseError, string> = {
  [AdvancedProductInUseError.MORTGAGES]: $localize`This product cannot be deleted as it is in use by at least one application!`,
  [AdvancedProductInUseError.DEFAULT_FEES]: $localize`This product cannot be deleted as it is in use by at least one default fee!`,
  [AdvancedProductInUseError.DEFAULT_TASKS]: $localize`This product cannot be deleted as it is in use by at least one default task!`,
  [AdvancedProductInUseError.CONDITIONS]: $localize`This product cannot be deleted as it is in use by at least one condition!`,
};

export const CreditTierInUseErrorMessageRecord: Record<CreditTierInUseError, string> = {
  [CreditTierInUseError.RATE_MATRIX]: $localize`This credit tier cannot be deleted as it is in use by at least one rate matrix!`,
  [CreditTierInUseError.DEFAULT_FEES]: $localize`This credit tier cannot be deleted as it is in use by at least one default fee!`,
};

// Note that specific names are not translated intentionally.
export const ApplicationSourceRecord: Record<ApplicationSource, string> = {
  [ApplicationSource.DOLPHIN]: `Dolphin`,
  [ApplicationSource.UPLOAD]: $localize`Upload`,
  [ApplicationSource.MANUAL]: $localize`Manual`,
  [ApplicationSource.COPY]: $localize`Copy`,
  [ApplicationSource.VELOCITY]: `Velocity`,
  [ApplicationSource.VELOCITY_MOR_WEB]: `Velocity (MorWeb)`,
  [ApplicationSource.VELOCITY_EXPERT]: 'Velocity (Expert)',
  [ApplicationSource.VELOCITY_SCARLETT]: 'Velocity (Scarlett)',
  [ApplicationSource.VELOCITY_CIBC_EXPERT]: 'Velocity (CIBC Expert)',
  [ApplicationSource.VELOCITY_LENDESK]: 'Velocity (Lendesk)',
  [ApplicationSource.VELOCITY_DOORR]: 'Velocity (Doorr)',
  [ApplicationSource.VELOCITY_BOSS]: 'Velocity (Boss)',
  [ApplicationSource.VELOCITY_FINMO]: 'Velocity (Finmo)',
  [ApplicationSource.FILOGIX]: `Filogix`,
  [ApplicationSource.LENDESK]: `Lendesk`,
  [ApplicationSource.MERGE]: $localize`Merge`,
  [ApplicationSource.EQ_MAPS_VELOCITY]: `EQ Maps Velocity`,
  [ApplicationSource.EQ_MAPS_FILOGIX]: `EQ Maps Velocity`,
  [ApplicationSource.EQ_MAPS_LENDESK]: `EQ Maps Velocity`,
  [ApplicationSource.EQ_MAPS_MBOSS]: `EQ Maps Mboss`,
};

export const ConditionOfApprovalStatusRecord: Record<ConditionOfApprovalStatus, string> = {
  [ConditionOfApprovalStatus.DRAFT]: $localize`Draft`,
  [ConditionOfApprovalStatus.INACTIVE]: $localize`Disabled`,
  [ConditionOfApprovalStatus.PUBLISHED]: $localize`Published`,
};

export const ApplicationRiskFlagSeverityRecord: Record<RiskFlagSeverity, string> = {
  [RiskFlagSeverity.FAIL]: $localize`Fail`,
  [RiskFlagSeverity.MANUAL_REVIEW]: $localize`Manual Review`,
};

export const ApplicationRiskFlagStatusRecord: Record<ApplicationRiskFlagStatus, string> = {
  [ApplicationRiskFlagStatus.NONE]: $localize`None`,
  [ApplicationRiskFlagStatus.RESOLVED]: $localize`Resolved`,
  [ApplicationRiskFlagStatus.ERROR]: $localize`Error`,
};

export const FundmoreResultTypeRecord: Record<FundmoreResultType, string> = {
  [FundmoreResultType.Fail]: $localize`Fail`,
  [FundmoreResultType.ManualReview]: $localize`Manual Review`,
  [FundmoreResultType.Pass]: $localize`Pass`,
  [FundmoreResultType.Unknown]: $localize`Unknown`,
};

export const FundmoreRecommendationTypeRecord: Record<FundmoreRecommendationType, string> = {
  [FundmoreRecommendationType.ChangeProduct]: $localize`Change Product`,
  [FundmoreRecommendationType.ChangeRequestedMortgageMaturityDate]: $localize`Change Requested Mortgage Maturity Date`,
  [FundmoreRecommendationType.DecreaseMortgageAmount]: $localize`Decrease Mortgage Amount`,
  [FundmoreRecommendationType.IncreaseDownPayment]: $localize`Increase Down Payment`,
  [FundmoreRecommendationType.RefinanceSecondMortgage]: $localize`Refinance Second Mortgage`,
  [FundmoreRecommendationType.SwitchToRefinance]: $localize`Switch To Refinance`,
  [FundmoreRecommendationType.UploadCreditReport]: $localize`Upload Credit Report`,
};

export const AssetTypeRecord: Record<AssetType, string> = {
  [AssetType.VEHICLE]: $localize`Vehicle`,
  [AssetType.RRSP]: $localize`RRSP`,
  [AssetType.STOCKS_BONDS_MUTUAL]: $localize`Stocks/Bonds/Mutual`,
  [AssetType.STOCKS]: $localize`Stocks`,
  [AssetType.MUTUAL_FUNDS]: $localize`Mutual Funds`,
  [AssetType.HOUSEHOLD_GOODS]: $localize`Household Goods`,
  [AssetType.CASH_SAVING]: $localize`Cash Saving`,
  [AssetType.CASH_CHEQUING]: $localize`Cash Chequing`,
  [AssetType.SAVINGS]: $localize`Savings`,
  [AssetType.DEPOSIT_ON_PURCHASE]: $localize`Deposit on Purchase`,
  [AssetType.GIC_TERM_DEPOSITS]: $localize`GIC Term Deposits`,
  [AssetType.LIFE_INSURANCE]: $localize`Life Insurance`,
  [AssetType.GIFT]: $localize`Gift`,
  [AssetType.SWEAT_EQUITY]: $localize`Sweat Equity`,
  [AssetType.PROPERTY]: $localize`Property`,
  [AssetType.RENTAL_PROPERTY]: $localize`Rental Property`,
  [AssetType.OTHER]: $localize`Other`,
};

export const ExcludeAssetFromCalculationReasonTypeRecord: Record<
  ExcludeAssetFromCalculationReasonType,
  string
> = {
  [ExcludeAssetFromCalculationReasonType.PAID_BY_OTHER_SOURCE]: $localize`Paid by Other Source`,
  [ExcludeAssetFromCalculationReasonType.FINAL_PAYMENT]: $localize`Final Payment`,
  [ExcludeAssetFromCalculationReasonType.DUPLICATE_ITEM]: $localize`Duplicate Item`,
  [ExcludeAssetFromCalculationReasonType.OMIT_FROM_GDS]: $localize`Omit from GDS`,
};

export const ExcludeLiabilityFromCalculationReasonTypeRecord: Record<
  ExcludeLiabilityFromCalculationReasonType,
  string
> = {
  [ExcludeLiabilityFromCalculationReasonType.PAID_BY_OTHER_SOURCE]: $localize`Paid by Other Source`,
  [ExcludeLiabilityFromCalculationReasonType.FINAL_PAYMENT]: $localize`Final Payment`,
  [ExcludeLiabilityFromCalculationReasonType.DUPLICATE_ITEM]: $localize`Duplicate Item`,
  [ExcludeLiabilityFromCalculationReasonType.OMIT_FROM_TDS]: $localize`Omit from TDS`,
};

export const OccupationTypeRecord: Record<OccupationType, string> = {
  [OccupationType.ADMINISTRATOR]: $localize`Administrator`,
  [OccupationType.CLERICAL]: $localize`Clerical`,
  [OccupationType.GOVERNMENT]: $localize`Government`,
  [OccupationType.LABOURER]: $localize`Labourer`,
  [OccupationType.MANAGER]: $localize`Manager`,
  [OccupationType.MEDICAL]: $localize`Medical`,
  [OccupationType.PROFESSIONAL]: $localize`Professional`,
  [OccupationType.RETIRED]: $localize`Retired`,
  [OccupationType.SALES]: $localize`Sales`,
  [OccupationType.SERVICE]: $localize`Service`,
  [OccupationType.SELF_EMPLOYED]: $localize`Self Employed`,
  [OccupationType.UNSPECIFIED]: $localize`Unspecified`,
  [OccupationType.OTHER]: $localize`Other`,
};

export const BrokerUserTypeRecord: Record<BrokerUserType, string> = {
  [BrokerUserType.UNUSED]: $localize`Unused`,
  [BrokerUserType.SUPPORT_USER]: $localize`Support User`,
  [BrokerUserType.REGIONAL_MANAGER]: $localize`Regional Manager`,
  [BrokerUserType.ADMINISTRATOR]: $localize`Administrator`,
  [BrokerUserType.ASSOCIATE]: $localize`Associate`,
  [BrokerUserType.BROKER_OWNER]: $localize`Broker(Owner)`,
  [BrokerUserType.FIRM_ADMIN]: $localize`Firm Admin`,
  [BrokerUserType.SYS_ADMIN]: $localize`Sys Admin`,
  [BrokerUserType.PASSIVE]: $localize`Passive`,
  [BrokerUserType.ASSISTANT]: $localize`Assistant`,
};

export const CompoundPeriodRecord: Record<CompoundPeriod, string> = {
  [CompoundPeriod.SEMI_MONTHLY]: $localize`Semimonthly`,
  [CompoundPeriod.SEMI_ANNUAL]: $localize`Semiannual`,
  [CompoundPeriod.MONTHLY]: $localize`Monthly`,
  [CompoundPeriod.QUARTERLY]: $localize`Quarterly`,
  [CompoundPeriod.ANNUALLY]: $localize`Annually`,
  [CompoundPeriod.BI_WEEKLY]: $localize`Biweekly`,
  [CompoundPeriod.DAILY]: $localize`Daily`,
  [CompoundPeriod.WEEKLY]: $localize`Weekly`,
  [CompoundPeriod.SIMPLE_INTEREST]: $localize`Simple Interest`,
};

export const GenderRecord: Record<Gender, string> = {
  [Gender.MALE]: $localize`Male`,
  [Gender.FEMALE]: $localize`Female`,
  [Gender.OTHER]: $localize`Other`,
};

export const SalutationRecord: Record<Salutation, string> = {
  [Salutation.MR]: $localize`Mr`,
  [Salutation.MRS]: $localize`Mrs`,
  [Salutation.MISS]: $localize`Miss`,
  [Salutation.MS]: $localize`Ms`,
  [Salutation.MADAM]: $localize`Madam`,
  [Salutation.DR]: $localize`Dr`,
  [Salutation.PROF]: $localize`Prof`,
  [Salutation.REV]: $localize`Rev`,
  [Salutation.SIR]: $localize`Sir`,
  [Salutation.JUDGE]: $localize`Judge`,
  [Salutation.OTHER]: $localize`Other`,
};

export const RelationshipToPrimaryApplicantRecord: Record<RelationshipToPrimaryApplicant, string> =
  {
    [RelationshipToPrimaryApplicant.CHILD]: $localize`Child`,
    [RelationshipToPrimaryApplicant.COMMON_LAW]: $localize`Common Law`,
    [RelationshipToPrimaryApplicant.GRANDCHILD]: $localize`Grandchild`,
    [RelationshipToPrimaryApplicant.GRANDPARENT]: $localize`Grandparent`,
    [RelationshipToPrimaryApplicant.PARENT]: $localize`Parent`,
    [RelationshipToPrimaryApplicant.RELATED]: $localize`Related`,
    [RelationshipToPrimaryApplicant.SIBLING]: $localize`Sibling`,
    [RelationshipToPrimaryApplicant.SPOUSE]: $localize`Spouse`,
    [RelationshipToPrimaryApplicant.OTHER]: $localize`Other`,
  };

export const FeeTypeRecord: Record<FeeType, string> = {
  [FeeType.COMMITMENT]: $localize`Commitment`,
  [FeeType.BROKER]: $localize`Broker`,
  [FeeType.LENDER]: $localize`Lender`,
  [FeeType.ADMINISTRATOR]: $localize`Administrator`,
  [FeeType.TITLE_INSURANCE]: $localize`Title Insurance`,
  [FeeType.APPRAISAL]: $localize`Appraisal`,
  [FeeType.LAND_TITLES]: $localize`Land Titles`,
  [FeeType.LEGAL]: $localize`Legal`,
  [FeeType.OTHER]: $localize`Other`,
};

export const FeePayerRecord: Record<FeePayer, string> = {
  [FeePayer.LENDER]: $localize`Lender`,
  [FeePayer.BORROWER]: $localize`Borrower`,
};

export const InsurerRecord: Record<InsurerType, string> = {
  [InsurerType.SAGEN]: 'Sagen',
  [InsurerType.CANADA_GUARANTY]: 'Canada Guaranty',
  [InsurerType.CMHC]: 'CMHC',
};

export const PaymentTypeRecord: Record<EmployedPaymentType | SelfEmployedPaymentType, string> = {
  [EmployedPaymentType.HOURLY_NON_GUARANTEED]: $localize`Hourly (non-guaranteed)`,
  [EmployedPaymentType.HOURLY_GUARANTEED]: $localize`Hourly (guaranteed)`,
  [EmployedPaymentType.SALARIED]: $localize`Salaried`,
  [EmployedPaymentType.COMMISION_ONLY]: $localize`Commission Only`,
  [SelfEmployedPaymentType.T4]: $localize`T4`,
  [SelfEmployedPaymentType.DIVIDEND]: $localize`Dividend`,
  [SelfEmployedPaymentType.BUSINESS_INCOME]: $localize`Business Income`,
  [SelfEmployedPaymentType.COMMISSION]: $localize`Commission`,
};

export const IncomeTypeRecord: Record<IncomeType, string> = {
  [IncomeType.EMPLOYED]: $localize`Employed`,
  [IncomeType.SELF_EMPLOYED]: $localize`Self Employed`,
  [IncomeType.OTHER]: $localize`Other`,
  [IncomeType.CANADA_CHILD_BENEFIT]: $localize`Canada Child Benefit`,
  [IncomeType.CANADA_PENSION_PLAN]: $localize`Canada Pension Plan`,
  [IncomeType.CHILD_SUPPORT]: $localize`Child Support`,
  [IncomeType.DISABILITY]: $localize`Disability`,
  [IncomeType.FOREIGN]: $localize`Foreign`,
  [IncomeType.INVESTMENT]: $localize`Investment`,
  [IncomeType.OLD_AGE_SECURITY]: $localize`Old Age Security`,
  [IncomeType.PENSION]: $localize`Pension`,
  [IncomeType.RENTAL]: $localize`Rental`,
  [IncomeType.SPOUSE_SUPPORT]: $localize`Spouse Support`,
  [IncomeType.SURVIVOR_BENEFIT_PENSION]: $localize`Survivor Benefit Pension`,
  [IncomeType.UNEMPLOYED]: $localize`Unemployed`,
  [IncomeType.BONUS]: $localize`Bonus`,
  [IncomeType.OVERTIME]: $localize`Overtime`,
  [IncomeType.COMMISSION]: $localize`Commission`,
  [IncomeType.OTHER_NON_EMPLOYED]: $localize`Other (Non-employed)`,
  [IncomeType.TRUST]: $localize`Trust`,
};

export const IncomePeriodRecord: Record<IncomePeriod, string> = {
  [IncomePeriod.BI_WEEKLY]: $localize`Bi-weekly`,
  [IncomePeriod.WEEKLY]: $localize`Weekly`,
  [IncomePeriod.SEMI_MONTHLY]: $localize`Semi-monthly`,
  [IncomePeriod.MONTHLY]: $localize`Monthly`,
  [IncomePeriod.YEARLY]: $localize`Annually`,
};

export const ApplicationConditionDocumentRejectReasonRecord: Record<
  ApplicationConditionDocumentRejectReasons,
  string
> = {
  [ApplicationConditionDocumentRejectReasons.INCORRECT_DOCUMENT]: $localize`Incorrect Document`,
  [ApplicationConditionDocumentRejectReasons.LOW_QUALITY]: $localize`Low Quality`,
  [ApplicationConditionDocumentRejectReasons.MISSING_PAGES]: $localize`Missing Pages`,
  [ApplicationConditionDocumentRejectReasons.TOO_OLD]: $localize`Too Old`,
  [ApplicationConditionDocumentRejectReasons.OTHER]: $localize`Other`,
};

export const ApplicationConditionDocumentStatusRecord: Record<
  ApplicationConditionDocumentStatus,
  string
> = {
  [ApplicationConditionDocumentStatus.ACCEPTED]: $localize`Accept`,
  [ApplicationConditionDocumentStatus.REJECTED]: $localize`Reject`,
};

export const PrivateDMDocumentRejectReasonRecord: Record<PrivateDMDocumentRejectReasons, string> = {
  [PrivateDMDocumentRejectReasons.INCORRECT_DOCUMENT]: $localize`Incorrect Document`,
  [PrivateDMDocumentRejectReasons.LOW_QUALITY]: $localize`Low Quality`,
  [PrivateDMDocumentRejectReasons.MISSING_PAGES]: $localize`Missing Pages`,
  [PrivateDMDocumentRejectReasons.TOO_OLD]: $localize`Too Old`,
  [PrivateDMDocumentRejectReasons.OTHER]: $localize`Other`,
};

export const PrivateDMDocumentStatusRecord: Record<PrivateDMDocumentStatus, string> = {
  [PrivateDMDocumentStatus.ACCEPTED]: $localize`Accept`,
  [PrivateDMDocumentStatus.REJECTED]: $localize`Reject`,
};

export const ApplicationApprovalActionRecord: Record<ApplicationApprovalAction, string> = {
  [ApplicationApprovalAction.APPROVE]: $localize`Approved`,
  [ApplicationApprovalAction.DECLINE]: $localize`Declined`,
  [ApplicationApprovalAction.REQUEST]: $localize`Requested`,
  [ApplicationApprovalAction.CANCEL]: $localize`Canceled`,
  [ApplicationApprovalAction.PENDING]: $localize`Approval reset to Pending`,
  [ApplicationApprovalAction.REQUEST_CHANGES]: $localize`Requested Changes`,
  [ApplicationApprovalAction.RECOMMEND_FOR_APPROVAL]: $localize`Recommend for Approval`,
};

export const PoliticallyExposedPersonTypeRecord: Record<PoliticallyExposedPersonType, string> = {
  [PoliticallyExposedPersonType.HIO]: $localize`Head of an International Organization (HIO)`,
  [PoliticallyExposedPersonType.NOT_A_PEP]: $localize`Not a PEP`,
  [PoliticallyExposedPersonType.PEDP_AND_HIO]: $localize`PEDP & HIO`,
  [PoliticallyExposedPersonType.PEFP_AND_HIO]: $localize`PEFP & HIO`,
  [PoliticallyExposedPersonType.PEDP]: $localize`Politically Exposed Domestic Person (PEDP)`,
  [PoliticallyExposedPersonType.PEFP]: $localize`Politically Exposed Foreign Person (PEFP)`,
};

export const FraudAMLRiskTypeRecord: Record<FraudAMLRiskType, string> = {
  [FraudAMLRiskType.DEMARKETED_CLIENT]: $localize`Demarketed Client (No New Business Permitted)`,
  [FraudAMLRiskType.HIGH_RISK_CLIENT]: $localize`High Risk Client`,
  [FraudAMLRiskType.NO_NEW_BUSINESS]: $localize`No New Business`,
  [FraudAMLRiskType.SATR_FILED]: $localize`SATR Filed`,
  [FraudAMLRiskType.STR_FILED]: $localize`STR Filed`,
};

export const ThirdPartyDeterminationTypeRecord: Record<ThirdPartyDeterminationType, string> = {
  [ThirdPartyDeterminationType.YES]: $localize`Yes`,
  [ThirdPartyDeterminationType.NO]: $localize`No`,
};

export const RequestNameScreeningTypeRecord: Record<RequestNameScreeningType, string> = {
  [RequestNameScreeningType.YES]: $localize`Yes`,
  [RequestNameScreeningType.NO]: $localize`No`,
};

export const AccountTypeRecord: Record<AccountType, string> = {
  [AccountType.CHEQUING]: $localize`Chequing`,
  [AccountType.SAVINGS]: $localize`Savings`,
  [AccountType.MAXI]: $localize`Maxi`,
  [AccountType.CHEQ]: $localize`Cheq`,
  [AccountType.ADVNTGE]: $localize`Advntge`,
  [AccountType.OADV]: $localize`Oadv`,
};

export const FinancialInstitutionTypeRecord: Record<FinancialInstitutionType, string> = {
  [FinancialInstitutionType.BANK_OF_MONTREAL]: 'Bank of Montreal',
  [FinancialInstitutionType.SCOTIABANK]: 'Scotiabank (The Bank of Nova Scotia)',
  [FinancialInstitutionType.ROYAL_BANK_OF_CANADA]: 'Royal Bank of Canada',
  [FinancialInstitutionType.THE_TORONTO_DOMINION_BANK]: 'The Toronto-Dominion Bank',
  [FinancialInstitutionType.NATIONAL_BANK_OF_CANADA]: 'National Bank of Canada',
  [FinancialInstitutionType.CANADIAN_IMPERIAL_BANK_OF_COMMERCE]:
    'Canadian Imperial Bank of Commerce',
  [FinancialInstitutionType.HSBC_BANK_CANADA]: 'HSBC Bank Canada',
  [FinancialInstitutionType.CANADIAN_WESTERN_BANK]: 'Canadian Western Bank',
  [FinancialInstitutionType.LAURENTIAN_BANK_OF_CANADA]: 'Laurentian Bank of Canada',
  [FinancialInstitutionType.BANK_OF_CANADA]: 'Bank of Canada',
  [FinancialInstitutionType.ALBERTA_TREASURY_BRANCHES]: 'Alberta Treasury Branches',
  [FinancialInstitutionType.ROYAL_BANK_OF_SCOTLAND_NV_CANADA_BRANCH]:
    'Royal Bank of Scotland N.V. (Canada Branch)',
  [FinancialInstitutionType.BANK_OF_AMERICA_NATIONAL_ASSOCIATION]:
    'Bank of America, National Association',
  [FinancialInstitutionType.THE_BANK_OF_NEW_YORK_MELLON]: 'The Bank of New York Mellon',
  [FinancialInstitutionType.BANK_OF_TOKYO_MITSUBISHI_UFJ_CANADA]:
    'Bank of Tokyo-Mitsubishi UFJ (Canada)',
  [FinancialInstitutionType.BNP_PARIBAS_CANADA]: 'BNP Paribas (Canada)',
  [FinancialInstitutionType.CITIBANK_CANADA]: 'Citibank Canada',
  [FinancialInstitutionType.DEUTSCHE_BANK_AG]: 'Deutsche Bank AG',
  [FinancialInstitutionType.MEGA_INTERNATIONAL_COMMERCIAL_BANK_CANADA]:
    'Mega International Commercial Bank (Canada)',
  [FinancialInstitutionType.JPMORGAN_CHASE_BANK_NATIONAL_ASSOCIATION]:
    'JPMorgan Chase Bank National Association',
  [FinancialInstitutionType.KOREA_EXCHANGE_BANK_OF_CANADA]: 'Korea Exchange Bank of Canada',
  [FinancialInstitutionType.MIZUHO_CORPORATE_BANK_LTD_CANADA_BRANCH]:
    'Mizuho Corporate Bank Ltd. Canada Branch',
  [FinancialInstitutionType.UBS_BANK_CANADA]: 'UBS Bank (Canada)',
  [FinancialInstitutionType.SOCIÉTÉ_GÉNÉRALE_CANADA_BRANCH]: 'Société Générale (Canada Branch)',
  [FinancialInstitutionType.STATE_BANK_OF_INDIA_CANADA_ALBERTA]:
    'State Bank of India (Canada) Alberta',
  [FinancialInstitutionType.SUMITOMO_MITSUI_BANKING_CORPORATION_OF_CANADA]:
    'Sumitomo Mitsui Banking Corporation of Canada',
  [FinancialInstitutionType.AMEX_BANK_OF_CANADA]: 'Amex Bank of Canada',
  [FinancialInstitutionType.INDUSTRIAL_AND_COMMERCIAL_BANK_OF_CHINA_CANADA]:
    'Industrial and Commercial Bank of China (Canada)',
  [FinancialInstitutionType.BANK_OF_CHINA_CANADA]: 'Bank of China (Canada)',
  [FinancialInstitutionType.CITIZENS_BANK_OF_CANADA]: 'Citizens Bank of Canada',
  [FinancialInstitutionType.FIRST_NATIONS_BANK_OF_CANADA]: 'First Nations Bank of Canada',
  [FinancialInstitutionType.BOFA_CANADA_BANK]: 'BofA Canada Bank',
  [FinancialInstitutionType.JP_MORGAN_BANK_CANADA]: 'J.P. Morgan Bank Canada',
  [FinancialInstitutionType.CTC_BANK_OF_CANADA]: 'CTC Bank of Canada',
  [FinancialInstitutionType.US_BANK_NATIONAL_ASSOCIATION]: 'U.S. Bank National Association',
  [FinancialInstitutionType.HABIB_CANADIAN_BANK]: 'Habib Canadian Bank',
  [FinancialInstitutionType.RABOBANK_NEDERLAND]: 'Rabobank Nederland',
  [FinancialInstitutionType.CAPITAL_ONE_BANK_CANADA_BRANCH]: 'Capital One Bank (Canada Branch)',
  [FinancialInstitutionType.PRESIDENTS_CHOICE_FINANCIAL]: "President's Choice Financial",
  [FinancialInstitutionType.STATE_STREET]: 'State Street',
  [FinancialInstitutionType.CITIBANK_NA]: 'Citibank N.A.',
  [FinancialInstitutionType.COMERICA_BANK]: 'Comerica Bank',
  [FinancialInstitutionType.FIRST_COMMERCIAL_BANK]: 'First Commercial Bank',
  [FinancialInstitutionType.HSBC_BANK_USA_NATIONAL_ASSOCIATION]:
    'HSBC Bank USA National Association',
  [FinancialInstitutionType.PACIFIC_WESTERN_BANK_OF_CANADA]: 'Pacific & Western Bank of Canada',
  [FinancialInstitutionType.UNITED_OVERSEAS_BANK_LIMITED]: 'United Overseas Bank Limited',
  [FinancialInstitutionType.MAPLE_BANK]: 'Maple Bank',
  [FinancialInstitutionType.CANADIAN_TIRE_BANK]: 'Canadian Tire Bank',
  [FinancialInstitutionType.UBS_AG_CANADA_BRANCH]: 'UBS AG Canada Branch',
  [FinancialInstitutionType.ICICI_BANK_CANADA]: 'ICICI Bank Canada',
  [FinancialInstitutionType.BANK_WEST]: 'Bank West',
  [FinancialInstitutionType.DUNDEE_BANK_OF_CANADA]: 'Dundee Bank of Canada',
  [FinancialInstitutionType.GENERAL_BANK_OF_CANADA]: 'General Bank of Canada',
  [FinancialInstitutionType.FIFTH_THIRD_BANK]: 'Fifth Third Bank',
  [FinancialInstitutionType.SOCIÉTÉ_GÉNÉRALE_CANADA_BRANCH_ONTARIO]:
    'Société Générale (Canada Branch) Ontario',
  [FinancialInstitutionType.BRIDGEWATER_BANK]: 'Bridgewater Bank',
  [FinancialInstitutionType.THE_NORTHERN_TRUST_COMPANY_CANADA_BRANCH]:
    'The Northern Trust Company Canada Branch',
  [FinancialInstitutionType.DIRECTCASH_BANK]: 'DirectCash Bank',
  [FinancialInstitutionType.JAMESON_BANK]: 'Jameson Bank',
  [FinancialInstitutionType.SHINHAN_BANK_CANADA]: 'Shinhan Bank Canada',
  [FinancialInstitutionType.M_T_BANK]: 'M&T Bank',
  [FinancialInstitutionType.HOMEQUITY_BANK]: 'HomEquity Bank',
  [FinancialInstitutionType.WALMART_CANADA_BANK]: 'Walmart Canada Bank',
  [FinancialInstitutionType.BARCLAYS_BANK_PLC_CANADA_BRANCH]: "Barclay's Bank PLC Canada Branch",
  [FinancialInstitutionType.MONCANA_BANK_OF_CANADA]: 'MonCana Bank of Canada',
  [FinancialInstitutionType.COMMUNITY_TRUST_COMPANY]: 'Community Trust Company',
  [FinancialInstitutionType.THE_CANADA_TRUST_COMPANY]: 'The Canada Trust Company',
  [FinancialInstitutionType.LAURENTIAN_TRUST_OF_CANADA_INC]: 'Laurentian Trust of Canada Inc.',
  [FinancialInstitutionType.EFFORT_TRUST_COMPANY]: 'Effort Trust Company',
  [FinancialInstitutionType.INVESTORS_GROUP_TRUST_CO_LTD]: 'Investors Group Trust Co. Ltd.',
  [FinancialInstitutionType.MANULIFE_BANK_OF_CANADA]: 'Manulife Bank of Canada',
  [FinancialInstitutionType.CIBC_TRUST_CORPORATION]: 'CIBC Trust Corporation',
  [FinancialInstitutionType.MONTREAL_TRUST_COMPANY_OF_CANADA]: 'Montreal Trust Company of Canada',
  [FinancialInstitutionType.SUN_LIFE_FINANCIAL_TRUST_INC]: 'Sun Life Financial Trust Inc.',
  [FinancialInstitutionType.PEACE_HILLS_TRUST_COMPANY]: 'Peace Hills Trust Company',
  [FinancialInstitutionType.ROYAL_TRUST_COMPANY]: 'Royal Trust Company',
  [FinancialInstitutionType.ROYAL_TRUST_CORPORATION_OF_CANADA]: 'Royal Trust Corporation of Canada',
  [FinancialInstitutionType.NATIONAL_TRUST_COMPANY]: 'National Trust Company',
  [FinancialInstitutionType.ROYAL_BANK_MORTGAGE_CORPORATION]: 'Royal Bank Mortgage Corporation',
  [FinancialInstitutionType.TD_MORTGAGE_CORPORATION]: 'TD Mortgage Corporation',
  [FinancialInstitutionType.TD_PACIFIC_MORTGAGE_CORPORATION]: 'TD Pacific Mortgage Corporation',
  [FinancialInstitutionType.HSBC_MORTGAGE_CORPORATION_CANADA]: 'HSBC Mortgage Corporation (Canada)',
  [FinancialInstitutionType.SCOTIA_MORTGAGE_CORPORATION]: 'Scotia Mortgage Corporation',
  [FinancialInstitutionType.CS_ALTERNA_BANK]: 'CS Alterna Bank',
  [FinancialInstitutionType.ING_BANK_OF_CANADA]: 'ING Bank of Canada',
  [FinancialInstitutionType.B2B_BANK_FORMERLY_B2B_TRUST]: 'B2B Bank (formerly B2B Trust)',
  [FinancialInstitutionType.RESMOR_TRUST_COMPANY]: 'ResMor Trust Company',
  [FinancialInstitutionType.PEOPLES_TRUST_COMPANY]: 'Peoples Trust Company',
  [FinancialInstitutionType.THE_EQUITABLE_TRUST_COMPANY]: 'The Equitable Trust Company',
  [FinancialInstitutionType.INDUSTRIAL_ALLIANCE_TRUST_INC]: 'Industrial Alliance Trust Inc.',
  [FinancialInstitutionType.MANULIFE_TRUST_COMPANY]: 'Manulife Trust Company',
  [FinancialInstitutionType.HOUSEHOLD_TRUST_COMPANY]: 'Household Trust Company',
  [FinancialInstitutionType.LATVIAN_CREDIT_UNION_LIMITED]: 'Latvian Credit Union Limited',
  [FinancialInstitutionType.COMMUNICATION_TECHNOLOGIES_CREDIT_UNION_LIMITED]:
    'Communication Technologies Credit Union Limited',
  [FinancialInstitutionType.ARNSTEIN_COMMUNITY_CREDIT_UNION_LIMITED]:
    'Arnstein Community Credit Union Limited',
  [FinancialInstitutionType.CENTRAL_1_CREDIT_UNION_BRITISH_COLUMBIA]:
    'Central 1 Credit Union British Columbia',
  [FinancialInstitutionType.ALL_TRANS_FINANCIAL_SERVICES_CREDIT_UNION_LIMITED]:
    'All Trans Financial Services Credit Union Limited',
  [FinancialInstitutionType.LA_CONFÉDÉRATION_DES_CAISSES_POPULAIRES_ET_DÉCONOMIE_DESJARDINS_DU_QUÉBEC]:
    "La Confédération des Caisses Populaires et D'Économie Desjardins du Québec",
  [FinancialInstitutionType.LA_FÉDÉRATION_DES_CAISSES_POPULAIRES_DU_MANITOBA_INC]:
    'La Fédération des caisses populaires du Manitoba Inc.',
  [FinancialInstitutionType.CENTRAL_1_CREDIT_UNION_ONTARIO]: 'Central 1 Credit Union Ontario',
  [FinancialInstitutionType.LA_FÉDÉRATION_DES_CAISSES_POPULAIRES_DE_LONTARIO_INC]:
    "La Fédération des Caisses Populaires de l'Ontario Inc.",
  [FinancialInstitutionType.AIRLINE_FINANCIAL_CREDIT_UNION_LIMITED]:
    'Airline Financial Credit Union Limited',
  [FinancialInstitutionType.MERIDIAN_CREDIT_UNION]: 'Meridian Credit Union',
  [FinancialInstitutionType.ATLANTIC_CENTRAL]: 'Atlantic Central',
  [FinancialInstitutionType.DUNDALK_DISTRICT_CREDIT_UNION_LIMITED]:
    'Dundalk District Credit Union Limited',
  [FinancialInstitutionType.ALTERNA_SAVINGS_AND_CREDIT_UNION]: 'Alterna Savings and Credit Union',
  [FinancialInstitutionType.GODERICH_COMMUNITY_CREDIT_UNION_LIMITED]:
    'Goderich Community Credit Union Limited',
  [FinancialInstitutionType.ONTARIO_CIVIL_SERVICE_CREDIT_UNION_LIMITED]:
    'Ontario Civil Service Credit Union Limited',
  [FinancialInstitutionType.CONCENTRA_FINANCIAL_SERVICES_ASSOCIATION]:
    'Concentra Financial Services Association',
  [FinancialInstitutionType.GOLDEN_HORSESHOE_CREDIT_UNION_LIMITED]:
    'Golden Horseshoe Credit Union Limited',
  [FinancialInstitutionType.LA_FÉDÉRATION_DES_CAISSES_POPULAIRES_ACADIENNES_LIMITÉE]:
    'La Fédération des Caisses Populaires Acadiennes Limitée',
  [FinancialInstitutionType.CREDIT_UNION_CENTRAL_OF_MANITOBA_LIMITED]:
    'Credit Union Central of Manitoba Limited',
  [FinancialInstitutionType.CREDIT_UNION_CENTRAL_OF_SASKATCHEWAN]:
    'Credit Union Central of Saskatchewan',
  [FinancialInstitutionType.ALLIANCE_DES_CAISSES_POPULAIRES_DE_LONTARIO_LIMITEE]:
    "Alliance des caisses populaires de l'Ontario Limitée",
  [FinancialInstitutionType.CREDIT_UNION_CENTRAL_ALBERTA_LIMITED]:
    'Credit Union Central Alberta Limited',
  [FinancialInstitutionType.MOTUS_BANK]: 'Motus Bank',
  [FinancialInstitutionType.COMMUNITY_FIRST_CREDIT_UNION]: 'Community First Credit Union',
  [FinancialInstitutionType.DESJARDINS_CREDIT_UNION]: 'Desjardins Credit Union',
  [FinancialInstitutionType.DUCA_FINANCIAL_SERVICES_CREDIT_UNION]:
    'DUCA Financial Services Credit Union',
  [FinancialInstitutionType.LIBRO_CREDIT_UNION]: 'Libro Credit Union',
  [FinancialInstitutionType.MBNA_CANADA_BANK]: 'MBNA Canada Bank',
  [FinancialInstitutionType.TANDIA_CREDIT_UNION]: 'Tandia Credit Union',
  [FinancialInstitutionType.WEALTH_SIMPLE]: 'Wealth Simple',
  [FinancialInstitutionType.OTHER]: $localize`Other`,
};

export const PaymentSourceRecord: Record<PaymentSource, string> = {
  [PaymentSource.EXTERNAL_ACCOUNT]: 'External Account',
  [PaymentSource.MERIDIAN_ACCOUNT]: 'Meridian Account',
};

export const DocumentManagementTypeRecord: Record<DocumentManagementType, string> = {
  [DocumentManagementType.EZIDOX]: 'DM',
  [DocumentManagementType.FUNDMORE]: 'Fundmore',
  [DocumentManagementType.NONE]: 'None',
};

export const OpenDocumentsStrategyRecord: Record<OpenDocumentsStrategy, string> = {
  [OpenDocumentsStrategy.NEW_TAB]: 'New Tab',
  [OpenDocumentsStrategy.NEW_WINDOW]: 'New Window',
};

export const OneTimeLinkGenerationStrategyRecord: Record<OneTimeLinkGenerationStrategy, string> = {
  [OneTimeLinkGenerationStrategy.CACHED]: 'Cached',
  [OneTimeLinkGenerationStrategy.ON_DEMAND]: 'On Demand',
};

export const ApplicationNoteStatusRecord: Record<ApplicationNoteStatus, string> = {
  [ApplicationNoteStatus.PUBLISHED]: $localize`Published`,
  [ApplicationNoteStatus.DRAFT]: $localize`Draft`,
};

export const ApplicationApprovalStatusRecord: Record<ApplicationApprovalStatus, string> = {
  [ApplicationApprovalStatus.PENDING]: $localize`Pending`,
  [ApplicationApprovalStatus.APPROVED]: $localize`Approved`,
  [ApplicationApprovalStatus.DECLINED]: $localize`Declined`,
  [ApplicationApprovalStatus.CHANGES_REQUESTED]: $localize`Changes Requested`,
  [ApplicationApprovalStatus.CANCELED]: $localize`Canceled`,
  [ApplicationApprovalStatus.RECOMMENDED_FOR_APPROVAL]: $localize`Recommend for Approval`,
};

export const AddressExpandedTypeRecord: Record<AddressExpandedType, string> = {
  [AddressExpandedType.CURRENT]: $localize`Current`,
  [AddressExpandedType.MAILING]: $localize`Mailing`,
  [AddressExpandedType.PREVIOUS]: $localize`Previous`,
};

export const ResidentialTypeRecord: Record<ResidentialType, string> = {
  [ResidentialType.RESIDENTIAL]: $localize`Residential`,
  [ResidentialType.BUSINESS]: $localize`Business`,
  [ResidentialType.TEMPORARY]: $localize`Temporary`,
  [ResidentialType.MAIL]: $localize`Mail`,
  [ResidentialType.OTHER]: $localize`Other`,
  [ResidentialType.RECREATION]: $localize`Recreation`,
  [ResidentialType.ALTERNATIVE]: $localize`Alternative`,
  [ResidentialType.PREVIOUS]: $localize`Previous`,
  [ResidentialType.FUNDING]: $localize`Funding`,
};

export const AgentAddressExpandedTypeRecord: Record<AgentAddressExpandedType, string> = {
  [AgentAddressExpandedType.ALTERNATIVE]: $localize`Alternative`,
  [AgentAddressExpandedType.BUSINESS]: $localize`Business`,
  [AgentAddressExpandedType.FUNDING]: $localize`Funding`,
  [AgentAddressExpandedType.RECREATION]: $localize`Recreation`,
  [AgentAddressExpandedType.RESIDENTIAL]: $localize`Residential`,
  [AgentAddressExpandedType.MAIL]: $localize`Mail`,
  [AgentAddressExpandedType.TEMPORARY]: $localize`Temporary`,
  [AgentAddressExpandedType.OTHER]: $localize`Other`,
};

export const ResidentialStatusTypeRecord: Record<ResidentialStatusType, string> = {
  [ResidentialStatusType.OWN]: $localize`Own`,
  [ResidentialStatusType.RENT]: $localize`Rent`,
  [ResidentialStatusType.LIVE_WITH_PARENTS]: $localize`Live with parents`,
  [ResidentialStatusType.SECONDARY]: $localize`Secondary`,
  [ResidentialStatusType.OTHER]: $localize`Other`,
};

export const InsurancePaidByTypeRecord: Record<InsurancePaidByType, string> = {
  [InsurancePaidByType.LENDER]: $localize`Lender`,
  [InsurancePaidByType.BORROWER]: $localize`Borrower`,
};

export const EQClosingServiceRecord: Record<EQClosingService, string> = {
  [EQClosingService.STANDARD]: $localize`Standard`,
  [EQClosingService.COLLATERAL_SWITCH]: $localize`Collateral Switch`,
  [EQClosingService.PLATINUM_REFINANCE]: $localize`Platinum Refinance`,
  [EQClosingService.PLATINUM_REM]: $localize`Platinum REM`,
  [EQClosingService.PLATINUM_SWITCH]: $localize`Platinum Switch`,
};

export const EQFraudAMLRiskRecord: Record<EQFraudAMLRiskType, string> = {
  [EQFraudAMLRiskType.HIGH_RISK]: $localize`High Risk`,
  [EQFraudAMLRiskType.HIGH_RISK_FOREIGN_PEP]: $localize`High Risk Foreign PEP`,
  [EQFraudAMLRiskType.HIGH_RISK_RE_CATEGORIZED]: $localize`High Risk Re-categorized`,
  [EQFraudAMLRiskType.STANDARD_RISK]: $localize`Standard Risk`,
  [EQFraudAMLRiskType.STANDARD_RISK_DOMESTIC_PEP]: $localize`Standard Risk Domestic PEP`,
  [EQFraudAMLRiskType.STANDARD_RISK_RE_CATEGORIZED]: $localize`Standard Risk Re-categorized`,
  [EQFraudAMLRiskType.THIRD_PARTY]: $localize`Third Party`,
  [EQFraudAMLRiskType.HIGH_RISK_WITH_STR]: $localize`High Risk with STR`,
  [EQFraudAMLRiskType.HIGH_RISK_PEP_WITH_STR]: $localize`High Risk PEP with STR`,
  [EQFraudAMLRiskType.DE_MARKETED_CLIENT]: $localize`De-marketed client`,
};

export const LendingTierRecord: Record<LendingTierType, string> = {
  [LendingTierType.TIER_1]: $localize`Tier 1`,
  [LendingTierType.TIER_2]: $localize`Tier 2`,
  [LendingTierType.TIER_3]: $localize`Tier 3`,
};

export const EzidoxApplicationStatusEnumRecord: Record<EzidoxApplicationStatusEnum, string> = {
  [EzidoxApplicationStatusEnum.DRAFT]: $localize`Draft`,
  [EzidoxApplicationStatusEnum.IN_PROGRESS]: $localize`In Progress`,
  [EzidoxApplicationStatusEnum.SUBMITTED]: $localize`Submitted`,
  [EzidoxApplicationStatusEnum.DECLINED]: $localize`Declined`,
  [EzidoxApplicationStatusEnum.GRANTED]: $localize`Granted`,
  [EzidoxApplicationStatusEnum.DELETED]: $localize`Deleted`,
  [EzidoxApplicationStatusEnum.ON_HOLD]: $localize`On hold`,
  [EzidoxApplicationStatusEnum.PURGED]: $localize`Purged`,
  [EzidoxApplicationStatusEnum.CUSTOM_DELETED]: $localize`Deleted`,
  [EzidoxApplicationStatusEnum.COMMITMENT]: $localize`Commitment`,
  [EzidoxApplicationStatusEnum.ARCHIVE]: $localize`Archived`,
  [EzidoxApplicationStatusEnum.FUNDING]: $localize`Funding`,
  [EzidoxApplicationStatusEnum.POST_FUNDING]: $localize`Post-Funding`,
  [EzidoxApplicationStatusEnum.CANCELLED]: $localize`Cancelled`,
  [EzidoxApplicationStatusEnum.INSTRUCTING]: $localize`Instructing`,
  [EzidoxApplicationStatusEnum.SIGNED_BACK]: $localize`Signed back`,
  [EzidoxApplicationStatusEnum.READY_FOR_INSTRUCTING]: $localize`Ready for instructing`,
  [EzidoxApplicationStatusEnum.READY_FOR_FUNDING]: $localize`Ready for funding`,
  [EzidoxApplicationStatusEnum.FUNDED]: $localize`Funded`,
  [EzidoxApplicationStatusEnum.CLOSED]: $localize`Closed`,
  [EzidoxApplicationStatusEnum.FULFILLMENT]: $localize`Fulfillment`,
  [EzidoxApplicationStatusEnum.FULFILLMENT_AND_FUNDING_REVIEW]: $localize`Fulfillment and Funding Review`,
};

export const EzidoxApplicationStatusEnumEQRecord: Record<EzidoxApplicationStatusEnum, string> = {
  [EzidoxApplicationStatusEnum.DRAFT]: $localize`Draft`,
  [EzidoxApplicationStatusEnum.IN_PROGRESS]: $localize`Underwriting`,
  [EzidoxApplicationStatusEnum.SUBMITTED]: $localize`Submitted`,
  [EzidoxApplicationStatusEnum.DECLINED]: $localize`Declined`,
  [EzidoxApplicationStatusEnum.GRANTED]: $localize`Granted`,
  [EzidoxApplicationStatusEnum.DELETED]: $localize`Deleted`,
  [EzidoxApplicationStatusEnum.ON_HOLD]: $localize`On hold`,
  [EzidoxApplicationStatusEnum.PURGED]: $localize`Purged`,
  [EzidoxApplicationStatusEnum.CUSTOM_DELETED]: $localize`Deleted`,
  [EzidoxApplicationStatusEnum.COMMITMENT]: $localize`Commitment`,
  [EzidoxApplicationStatusEnum.ARCHIVE]: $localize`Archived`,
  [EzidoxApplicationStatusEnum.FUNDING]: $localize`Funding`,
  [EzidoxApplicationStatusEnum.POST_FUNDING]: $localize`Post-Funding`,
  [EzidoxApplicationStatusEnum.CANCELLED]: $localize`Cancelled`,
  [EzidoxApplicationStatusEnum.INSTRUCTING]: $localize`Instructing`,
  [EzidoxApplicationStatusEnum.SIGNED_BACK]: $localize`Signed back`,
  [EzidoxApplicationStatusEnum.READY_FOR_INSTRUCTING]: $localize`Ready for instructing`,
  [EzidoxApplicationStatusEnum.READY_FOR_FUNDING]: $localize`Ready for funding`,
  [EzidoxApplicationStatusEnum.FUNDED]: $localize`Funded`,
  [EzidoxApplicationStatusEnum.CLOSED]: $localize`Closed`,
  [EzidoxApplicationStatusEnum.FULFILLMENT]: $localize`Fulfillment`,
  [EzidoxApplicationStatusEnum.FULFILLMENT_AND_FUNDING_REVIEW]: $localize`Fulfillment and Funding Review`,
};

export const EzidoxApplicationStatusEnumRecordOverride: Record<
  EzidoxStatusLocalizationOptions,
  Record<EzidoxApplicationStatusEnum, string>
> = {
  [EzidoxStatusLocalizationOptions.DEFAULT]: EzidoxApplicationStatusEnumRecord,
  [EzidoxStatusLocalizationOptions.EQ]: EzidoxApplicationStatusEnumEQRecord,
};

export const DeclineApplicationErrorMessageRecord: Record<DeclineApplicationError, string> = {
  [DeclineApplicationError.NOT_LODGED]: $localize`DM Application needs to be lodged before it can be declined!`,
};

export const CreditReportSourceRecord: Record<CreditReportSource, string> = {
  [CreditReportSource.EQUIFAX]: $localize`Equifax`,
  [CreditReportSource.POS]: $localize`POS`,
};

export const IncomeVerificationRecord: Record<IncomeVerificationType, string> = {
  [IncomeVerificationType.NOT_APPLICABLE]: $localize`Not Applicable`,
  [IncomeVerificationType.BUSINESS_FINANCIAL_STATEMENT]: $localize`Business Financial Statement`,
  [IncomeVerificationType.COMMISSION_STATEMENT]: $localize`Commission Statement`,
  [IncomeVerificationType.LEASE_AGREEMENTS]: $localize`Lease Agreements`,
  [IncomeVerificationType.LESS_THAN_12_MONTHS_BANK_STMT]: $localize`Less than 12 months bank stmt`,
  [IncomeVerificationType.EMPLOYMENT_LETTER_AND_PAYSTUB]: $localize`Employment Letter and Paystub`,
  [IncomeVerificationType.EMPLOYMENT_LETTER]: $localize`Employment Letter`,
  [IncomeVerificationType.MULTIPLE]: $localize`Multiple (see loan comment)`,
  [IncomeVerificationType.NOTICE_OF_ASSESSMENT]: $localize`Notice of Assessment`,
  [IncomeVerificationType.OTHER]: $localize`Other (see loan comments)`,
  [IncomeVerificationType.PAY_STUB]: $localize`Pay Stub`,
  [IncomeVerificationType.PENSION_STATEMENT]: $localize`Pension Statement`,
  [IncomeVerificationType.SELF_DECLARED_LETTER]: $localize`Self Declared Letter`,
  [IncomeVerificationType.T4]: $localize`T4`,
};

export const ProgramCodeTypeRecord: Record<ProgramCodeType, string> = {
  [ProgramCodeType.NEW_TO_CANADA]: $localize`New To Canada`,
  [ProgramCodeType.CASH_BACK]: $localize`Cash Back`,
  [ProgramCodeType.FRIENDS_AND_FAMILY]: $localize`Friends and Family`,
  [ProgramCodeType.RESTORATION_CREDIT]: $localize`Restoration Credit`,
  [ProgramCodeType.HORIZON]: $localize`Horizon`,
  [ProgramCodeType.HYBRID]: $localize`Hybrid`,
};

export const ResponsibleTypeRecord: Record<ResponsibleType, string> = {
  [ResponsibleType.BROKER]: $localize`Broker`,
  [ResponsibleType.LAWYER]: $localize`Lawyer`,
};

export const SourceOfBusinessRecord: Record<SourceOfBusinessType, string> = {
  [SourceOfBusinessType.BROKER]: $localize`Broker`,
  [SourceOfBusinessType.RETAIL]: $localize`Retail`,
  [SourceOfBusinessType.MORTGAGE_SPECIALIST]: $localize`Mortgage Specialist`,
  [SourceOfBusinessType.BRANCH]: $localize`Branch`,
  [SourceOfBusinessType.ON_LINE]: $localize`On-Line`,
  [SourceOfBusinessType.CALL_CENTER]: $localize`Call Center`,
  [SourceOfBusinessType.OTHER]: $localize`Other`,
};

export const PurposeCodeRecord: Record<PurposeCode, string> = {
  [PurposeCode.CONSOLIDATION_REFINANCE]: $localize`Consolidation/Refinance`,
  [PurposeCode.AUTO_USED_REPAIRS_INSURANCE]: $localize`Auto: Used/Repairs/Insurance`,
  [PurposeCode.NEW_AUTO]: $localize`New Auto`,
  [PurposeCode.RECREATIONAL_VEHICLES]: $localize`Recreational Vehicles (including Motor Homes)`,
  [PurposeCode.BOATS]: $localize`Boats (Pleasure Craft)`,
  [PurposeCode.VACATIONS]: $localize`Vacations`,
  [PurposeCode.FURNITURE]: $localize`Furniture`,
  [PurposeCode.HOME_REPAIRS_RENOVATIONS]: $localize`Home/Repairs/Renovations`,
  [PurposeCode.TAXES]: $localize`Taxes`,
  [PurposeCode.BRIDGE_FINANCING]: $localize`Bridge Financing`,
  [PurposeCode.INVESTMENT]: $localize`Investment`,
  [PurposeCode.CONSTRUCTION_MULTIPLE_DRAWS]: $localize`Construction multiple draws`,
  [PurposeCode.PURCHASE_HOUSING_RENTAL]: $localize`Purchase Housing (Rental)`,
  [PurposeCode.PURCHASE_HOUSING_OWNER_OCCUPIED]: $localize`Purchase Housing (Owner Occupied)`,
  [PurposeCode.PURCHASE_REAL_ESTATE_VACANT_LAND]: $localize`Purchase Real Estate (Vacant Land)`,
  [PurposeCode.PURCHASE_REAL_ESTATE_OTHER_VACATION]: $localize`Purchase Real Estate (Other/Vacation)`,
  [PurposeCode.MANUFACTURED_HOME_NEW]: $localize`Manufactured Home (New)`,
  [PurposeCode.MISCELLANEOUS]: $localize`Miscellaneous`,
  [PurposeCode.RRSP_PURCHASE]: $localize`RRSP Purchase`,
  [PurposeCode.LETTER_OF_CREDIT]: $localize`Letter of Credit`,
  [PurposeCode.MORTGAGE_ASSIGNMENTS]: $localize`Mortgage Assignments`,
  [PurposeCode.FIRST_HOME]: $localize`First H.O.M.E.`,
  [PurposeCode.CONDITIONAL_SALES_CONTRACT]: $localize`Conditional Sales Contract`,
  [PurposeCode.RESTRUCTURE]: $localize`Restructure (Existing Debt - Hardship Rewrite ONLY)`,
  [PurposeCode.EQUITY_DEAL]: $localize`Equity Deal`,
  [PurposeCode.MOBILE_HOME]: $localize`Mobile Home`,
  [PurposeCode.MERITLINE]: $localize`Meritline`,
  [PurposeCode.CREDITLINE]: $localize`Creditline`,
  [PurposeCode.FARM_PURCHASE]: $localize`Farm Purchase`,
  [PurposeCode.FARM_OPERATING]: $localize`Farm Operating`,
  [PurposeCode.FARM_EQUIPMENT]: $localize`Farm Equipment`,
  [PurposeCode.COMMERCIAL_PROPERTY]: $localize`Commercial Property`,
  [PurposeCode.COMMERCIAL_OPERATING]: $localize`Commercial Operating`,
  [PurposeCode.COMMERCIAL_EQUIPMENT]: $localize`Commercial Equipment`,
  [PurposeCode.OTHER_EQUIPMENT]: $localize`Other Equipment`,
  [PurposeCode.POSTAL_LETTER_OF_GUARANTEE]: $localize`Postal Letter of Guarantee`,
  [PurposeCode.MORTGAGE_ASSUMPTIONS]: $localize`Mortgage Assumptions`,
  [PurposeCode.VARIABLE_RATE_DEALER_SPECIAL]: $localize`Variable Rate - Dealer Special`,
  [PurposeCode.FIXED_RATE_AUTO]: $localize`Fixed Rate - Auto`,
  [PurposeCode.LOAN_SALE]: $localize`Loan Sale`,
  [PurposeCode.PURCHASE_BUILDER_HOME_UPON_COMPLETION]: $localize`Purchase builder home upon completion`,
  [PurposeCode.RESTORATION_CREDIT]: $localize`Restoration Credit`,
  [PurposeCode.NEW_VENTURES]: $localize`New Ventures`,
  [PurposeCode.SBIL]: $localize`SBIL`,
  [PurposeCode.LIFE_LEASE]: $localize`Life Lease`,
  [PurposeCode.LEASEHOLD]: $localize`Leasehold`,
  [PurposeCode.FARM_PLUS]: $localize`Farm Plus`,
  [PurposeCode.SYNDICATED_LOAN]: $localize`Syndicated Loan`,
  [PurposeCode.GRAPE_PLANTING]: $localize`Grape Planting`,
  [PurposeCode.CORPORATE_OFFICE_APPROVED]: $localize`Corporate Office Approved`,
  [PurposeCode.EXTENDED_AMORTIZATION]: $localize`Extended Amortization`,
  [PurposeCode.HYBRID_PROGRAM]: $localize`Hybrid Program`,
  [PurposeCode.MEZZANINE_FINANCING]: $localize`Mezzanine Financing`,
  [PurposeCode.BULK_LEASING]: $localize`Bulk Leasing`,
  [PurposeCode.GREEN_AUTO]: $localize`Green Auto`,
  [PurposeCode.GREEN_LOAN]: $localize`Green Loan`,
  [PurposeCode.SPECIAL_CREDIT_EXP_LOCS]: $localize`Special Credit-EXP LOCS`,
  [PurposeCode.NON_MANAGEMENT_STAFF]: $localize`Non-Management Staff`,
  [PurposeCode.MANAGEMENT_STAFF]: $localize`Management Staff`,
  [PurposeCode.NON_PERFORMING_LOAN_STATUS]: $localize`Non-performing loan status`,
  [PurposeCode.BANKRUPT_LOAN_STATUS]: $localize`Bankrupt Loan Status`,
  [PurposeCode.CASH_FLOW_SENIOR_TERM]: $localize`Cash Flow Senior Term`,
  [PurposeCode.HASCAP]: $localize`HASCAP`,
  [PurposeCode.HIGH_RATIO_OWNER_OCCUPIED_COMMERCIAL]: $localize`High Ratio Owner Occupied-Commercial`,
  [PurposeCode.FRANCHISING]: $localize`Franchising`,
  [PurposeCode.CEBA_LOAN]: $localize`CEBA Loan`,
  [PurposeCode.CEBA_CONVERSION]: $localize`CEBA Conversion`,
  [PurposeCode.BCAP_BDC_CO_LENDING]: $localize`BCAP BDC Co-Lending`,
  [PurposeCode.REFINANCED_CEBA_LOAN]: $localize`Refinanced CEBA Loan`,
  [PurposeCode.BDC_ACCELERATOR_GUARANTEE_PROGRAM]: $localize`BDC Accelerator Guarantee Program`,
  [PurposeCode.TRAILERS_SNOWMOBILES_ATVS]: $localize`Trailers/Snowmobiles/ATVs`,
  [PurposeCode.BUILD_A_PROPERTY]: $localize`Build a Property (Land is Already Owned)`,
  [PurposeCode.BUSINESS_PURPOSES]: $localize`Business Purposes`,
  [PurposeCode.CONSOLIDATION_OF_EXTERNAL_DEBT]: $localize`Consolidation of External Debt`,
  [PurposeCode.CONSOLIDATION_OF_INTERNAL_DEBT]: $localize`Consolidation of Internal Debt`,
  [PurposeCode.DOWN_PAYMENT_FOR_PROPERTY_PURCHASE]: $localize`Down Payment for Property Purchase`,
  [PurposeCode.FUTURE_USE]: $localize`Future Use`,
  [PurposeCode.MOTORCYCLE]: $localize`Motorcycle`,
  [PurposeCode.PROPERTY_PURCHASE_FROM_EXISTING_EQUITY]: $localize`Property Purchase (from Existing Equity in Other Real Estate)`,
  [PurposeCode.RECREATIONAL_HOME_OR_MOTORHOME]: $localize`Recreational Home or Motorhome`,
  [PurposeCode.SECONDARY_RESIDENCE]: $localize`Secondary Residence`,
  [PurposeCode.TUITION]: $localize`Tuition`,
  [PurposeCode.USED_VEHICLE]: $localize`Used Vehicle`,
};

export const MortgageClosingInstructionRecord: Record<MortgageClosingInstruction, string> = {
  [MortgageClosingInstruction.EXISTING_COLLATERAL_CHARGE]: $localize`Existing Collateral Charge`,
  [MortgageClosingInstruction.FCT]: $localize`FCT`,
  [MortgageClosingInstruction.SOLICITOR]: $localize`Solicitor`,
  [MortgageClosingInstruction.FCT_REQUEST]: $localize`FCT Request`,
};

export const MortgageStatusRecord: Record<MortgageStatus, string> = {
  [MortgageStatus.SOLICITOR_INSTRUCTED]: $localize`Solicitor Instructed`,
  [MortgageStatus.FCT_ORDERED]: $localize`FCT Ordered`,
  [MortgageStatus.FCT_RECEIVED]: $localize`FCT Received`,
  [MortgageStatus.APPLICATION_IN_PREPPING]: $localize`Application in Prepping`,
  [MortgageStatus.READY_FOR_CLOSING]: $localize`Ready for Closing`,
  [MortgageStatus.UPLOADED]: $localize`Uploaded`,
  [MortgageStatus.FUNDS_DISBURSED]: $localize`Funds Disbursed`,
  [MortgageStatus.WAITING_FOR_FINAL_REPORT]: $localize`Waiting for Final Report`,
  [MortgageStatus.FINAL_REPORT_ACCEPTED]: $localize`Final Report Accepted`,
};

export const MortgageStatusIconRecord: Record<MortgageStatus, string> = {
  [MortgageStatus.SOLICITOR_INSTRUCTED]: 'gavel',
  [MortgageStatus.FCT_ORDERED]: 'upload_file',
  [MortgageStatus.FCT_RECEIVED]: 'task',
  [MortgageStatus.APPLICATION_IN_PREPPING]: 'edit_document',
  [MortgageStatus.READY_FOR_CLOSING]: 'door_back',
  [MortgageStatus.UPLOADED]: 'publish',
  [MortgageStatus.FUNDS_DISBURSED]: 'paid',
  [MortgageStatus.WAITING_FOR_FINAL_REPORT]: 'pending_actions',
  [MortgageStatus.FINAL_REPORT_ACCEPTED]: 'done',
};

export const MortgageStatusOrderRecord: Record<MortgageStatus, number> = {
  [MortgageStatus.SOLICITOR_INSTRUCTED]: 1,
  [MortgageStatus.FCT_ORDERED]: 2,
  [MortgageStatus.FCT_RECEIVED]: 3,
  [MortgageStatus.APPLICATION_IN_PREPPING]: 4,
  [MortgageStatus.READY_FOR_CLOSING]: 5,
  [MortgageStatus.UPLOADED]: 6,
  [MortgageStatus.FUNDS_DISBURSED]: 7,
  [MortgageStatus.WAITING_FOR_FINAL_REPORT]: 8,
  [MortgageStatus.FINAL_REPORT_ACCEPTED]: 9,
};

export const MortgageStateRecord: Record<MortgageState, string> = {
  [MortgageState.ON_HOLD]: $localize`On Hold`,
  [MortgageState.CANCELLED]: $localize`Cancel`,
  [MortgageState.DECLINED]: $localize`Decline`,
};

export const MortgageStateIconRecord: Record<MortgageState, string> = {
  [MortgageState.ON_HOLD]: 'schedule',
  [MortgageState.CANCELLED]: 'cancel',
  [MortgageState.DECLINED]: 'error_outline',
};

export const TypeOfClosingInstructionRecord: Record<MortgageClosingInstruction, string> = {
  [MortgageClosingInstruction.EXISTING_COLLATERAL_CHARGE]: $localize`Existing Collateral Charge`,
  [MortgageClosingInstruction.FCT]: $localize`FCT`,
  [MortgageClosingInstruction.SOLICITOR]: $localize`Solicitor`,
  [MortgageClosingInstruction.FCT_REQUEST]: $localize`FCT Request`,
};

export const NoMortgageStatusOption = {
  key: 'NO_STATUS',
  value: $localize`No Status`,
};

export type NoMortgageStatusKey = typeof NoMortgageStatusOption.key;

export const MMSNotificationTypeRecord: Record<MMSNotificationType, string> = {
  [MMSNotificationType.DealStatus]: $localize`Deal Status`,
  [MMSNotificationType.Notes]: $localize`Notes`,
  [MMSNotificationType.TransactionStatus]: $localize`Transaction Status`,
  [MMSNotificationType.DealUpdates]: $localize`Deal Contents`,
  [MMSNotificationType.Milestones]: $localize`Milestones`,
  [MMSNotificationType.FCTLawyerDocuments]: $localize`Lawyer Documents`,
  [MMSNotificationType.Disbursement]: $localize`Disbursement`,
  [MMSNotificationType.ReleaseHold]: $localize`Release Hold`,
};

export const MMSDocumentUploadStatusRecord: Record<MMSDocumentUploadStatus, string> = {
  [MMSDocumentUploadStatus.FAILED]: $localize`Failed`,
  [MMSDocumentUploadStatus.SUCCESSFUL]: $localize`Successful`,
  [MMSDocumentUploadStatus.IN_PROGRESS]: $localize`In Progress`,
};

export const AgentSourceRecord: Record<AgentSource, string> = {
  [AgentSource.CONFERENCE]: $localize`Conference`,
  [AgentSource.EVENT]: $localize`Event`,
};

export const AgentStatusRecord: Record<AgentStatus, string> = {
  [AgentStatus.APPROVED]: $localize`Approved`,
  [AgentStatus.UNVERIFIED]: $localize`Unverified`,
  [AgentStatus.BLOCKED]: $localize`Blocked`,
};

export const NotificationTypesRecord: Record<NotificationTypes, string> = {
  [NotificationTypes.APPLICATION]: $localize`Application`,
  [NotificationTypes.DOCUMENT]: $localize`Document`,
  [NotificationTypes.APPROVAL]: $localize`Approval`,
  [NotificationTypes.CHAT]: $localize`Chat`,
  [NotificationTypes.INSURANCE]: $localize`Insurance`,
  [NotificationTypes.TASK]: $localize`Task`,
  [NotificationTypes.WARNING]: $localize`Warning`,
};
