import { IncrementCategory, MortgageIncrement } from '@fundmoreai/models';
import { inject, injectable } from 'tsyringe';
import { IncrementCalculationModel } from '../models/increments-calculation.model';

export interface IIncrementCalculator {
  getIncrementTotal(): IncrementTotal | undefined;
  getIncrementTotalByCategory(category: IncrementCategory): number;
  getStandardIncrementCap(): number;
}

interface IncrementTotal {
  category: IncrementCategory;
  total: number;
}

@injectable()
export class IncrementsCalculator implements IIncrementCalculator {
  readonly #DEFAULT_STANDARD_INCREMENT_CAP = 0.6;
  private increments: Pick<MortgageIncrement, 'amount' | 'category' | 'isChecked'>[];
  #standardIncrementCap: number;

  private computedIncrementTotal: number | undefined;
  private computedIncrementCategoryApplied: IncrementCategory | undefined;

  constructor(
    @inject(IncrementCalculationModel)
    incrementCalculationModel: IncrementCalculationModel,
  ) {
    this.increments = incrementCalculationModel.increments ?? [];
    this.#standardIncrementCap =
      incrementCalculationModel.maxStandardIncrement || this.#DEFAULT_STANDARD_INCREMENT_CAP;
  }

  private computeIncrementTotal(): IncrementTotal | undefined {
    const totals: { category: IncrementCategory; total: number }[] = [];
    for (const incrementCategory of Object.values(IncrementCategory)) {
      totals.push({
        category: incrementCategory,
        total: this.getIncrementTotalByCategory(incrementCategory),
      });
    }
    const maxIncrement = totals.reduce(
      (maxIncrement: IncrementTotal | undefined, incrementTotal) => {
        if (!maxIncrement) {
          return incrementTotal;
        }
        return maxIncrement.total >= incrementTotal.total ? maxIncrement : incrementTotal;
      },
      undefined,
    );
    if (!maxIncrement) {
      return undefined;
    }
    return maxIncrement;
  }

  public getIncrementTotal() {
    if (!this.computedIncrementTotal) {
      const incrementTotal = this.computeIncrementTotal();
      this.computedIncrementTotal = incrementTotal?.total;
      this.computedIncrementCategoryApplied = incrementTotal?.category;
    }
    if (!this.computedIncrementTotal || !this.computedIncrementCategoryApplied) {
      return undefined;
    }

    // MCU Specific requirement around always applying LTI increment if checked
    const ltiIncrement = this.getIncrementTotalByCategory(IncrementCategory.LOAN_TO_INCOME_RATIO);
    if (
      ltiIncrement &&
      this.computedIncrementCategoryApplied !== IncrementCategory.LOAN_TO_INCOME_RATIO
    ) {
      return {
        total: this.computedIncrementTotal + ltiIncrement,
        category: this.computedIncrementCategoryApplied,
      };
    }

    return { total: this.computedIncrementTotal, category: this.computedIncrementCategoryApplied };
  }

  public getIncrementTotalByCategory(category: IncrementCategory): number {
    const total = this.increments
      .filter((i) => i.isChecked && i.category === category)
      .reduce((sum, i) => sum + i.amount, 0);
    if (category === IncrementCategory.STANDARD_INCREMENT && total > this.#standardIncrementCap) {
      return this.#standardIncrementCap;
    }
    return total;
  }

  public getStandardIncrementCap() {
    return this.#standardIncrementCap;
  }
}
