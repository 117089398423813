import {
  fromLocalDateToShortISODate,
  fromShortISODateToMiddleLocalDate,
  toMiddleDate,
} from '@fundmoreai/helpers';
import { DatesProcessingType, Mortgage, PaymentFrequency } from '@fundmoreai/models';
import {
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  isLeapYear,
  setDate,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';

function getMiddleOfCurrentYear(startDate: Date): Date {
  return toMiddleDate(new Date(startDate.getFullYear(), 6, 2));
}

function getStartOfNextYear(startDate: Date): Date {
  return toMiddleDate(startOfYear(addYears(startDate, 1)));
}

function getMiddleOfLastYear(startDate: Date): Date {
  return toMiddleDate(new Date(subYears(startDate, 1).getFullYear(), 6, 2));
}

export function interestAdjustmentDate(
  mortgage: Partial<Mortgage> | undefined,
  tenantCode: DatesProcessingType | undefined,
) {
  if (!mortgage?.closingDate) {
    return null;
  }

  if (tenantCode === DatesProcessingType.Calvert) {
    return mortgage?.closingDate;
  }

  const paymentFrequency = mortgage.paymentFrequency;
  const closingDate = fromShortISODateToMiddleLocalDate(mortgage.closingDate);
  let interestAdjustmentDate: Date | null = null;

  switch (paymentFrequency) {
    case PaymentFrequency.SEMI_MONTHLY:
      interestAdjustmentDate =
        closingDate.getDate() === 1 || closingDate.getDate() === 15
          ? null
          : closingDate.getDate() < 15
          ? setDate(closingDate, 15)
          : toMiddleDate(startOfMonth(addMonths(closingDate, 1)));
      break;
    case PaymentFrequency.SEMI_ANNUALLY:
      {
        const isMonthBeforeJuly = closingDate.getMonth() < 6;
        const isBeforeJulySecond = closingDate.getMonth() === 6 && closingDate.getDate() < 2;
        const isBeforeMiddleOfYear = isMonthBeforeJuly || isBeforeJulySecond;
        interestAdjustmentDate = isBeforeMiddleOfYear
          ? getMiddleOfCurrentYear(closingDate)
          : getStartOfNextYear(closingDate);
      }
      break;
    case PaymentFrequency.MONTHLY:
    case PaymentFrequency.BI_WEEKLY:
    case PaymentFrequency.WEEKLY:
    case PaymentFrequency.ACCELERATED_BI_WEEKLY:
    case PaymentFrequency.ACCELERATED_WEEKLY:
    case PaymentFrequency.DAILY:
    case PaymentFrequency.ANNUALLY:
    case PaymentFrequency.QUARTERLY:
      interestAdjustmentDate = null;
      break;
    default:
      break;
  }
  return fromLocalDateToShortISODate(interestAdjustmentDate);
}

export function interestAdjustmentDateByFirstPaymentDate(mortgage: Partial<Mortgage> | undefined) {
  if (!mortgage?.closingDate || !mortgage?.firstRegularPaymentDate || !mortgage?.paymentFrequency) {
    return mortgage?.interestAdjustmentDate;
  }
  const paymentFrequency = mortgage.paymentFrequency;
  const closingDate = fromShortISODateToMiddleLocalDate(mortgage?.closingDate);
  const firstRegularPaymentDate = fromShortISODateToMiddleLocalDate(
    mortgage?.firstRegularPaymentDate,
  );
  let interestAdjustmentDate: Date | null = null;
  const diff = differenceInDaysBetweenDates(firstRegularPaymentDate, closingDate);
  const value = valueByPaymentFrequency(firstRegularPaymentDate, paymentFrequency);

  if (diff === null || value === null) {
    return mortgage?.interestAdjustmentDate;
  }

  const isDifferenceMoreThanPaymentFrequency = diff > value;

  switch (paymentFrequency) {
    case PaymentFrequency.MONTHLY:
      interestAdjustmentDate = isDifferenceMoreThanPaymentFrequency
        ? subMonths(firstRegularPaymentDate, 1)
        : null;
      break;
    case PaymentFrequency.SEMI_MONTHLY: {
      interestAdjustmentDate = isDifferenceMoreThanPaymentFrequency
        ? firstRegularPaymentDate.getDate() === 1
          ? setDate(subMonths(firstRegularPaymentDate, 1), 15)
          : firstRegularPaymentDate.getDate() === 15
          ? setDate(firstRegularPaymentDate, 1)
          : mortgage.interestAdjustmentDate
          ? new Date(mortgage.interestAdjustmentDate)
          : null
        : null;
      break;
    }
    case PaymentFrequency.BI_WEEKLY:
    case PaymentFrequency.ACCELERATED_BI_WEEKLY:
      interestAdjustmentDate = isDifferenceMoreThanPaymentFrequency
        ? subDays(firstRegularPaymentDate, 14)
        : null;
      break;
    case PaymentFrequency.WEEKLY:
    case PaymentFrequency.ACCELERATED_WEEKLY:
      interestAdjustmentDate = isDifferenceMoreThanPaymentFrequency
        ? subDays(firstRegularPaymentDate, 7)
        : null;
      break;
    case PaymentFrequency.DAILY:
      interestAdjustmentDate = isDifferenceMoreThanPaymentFrequency
        ? subDays(firstRegularPaymentDate, 1)
        : null;
      break;
    case PaymentFrequency.QUARTERLY:
      interestAdjustmentDate = isDifferenceMoreThanPaymentFrequency
        ? subMonths(firstRegularPaymentDate, 3)
        : null;
      break;
    case PaymentFrequency.ANNUALLY:
      interestAdjustmentDate = isDifferenceMoreThanPaymentFrequency
        ? subYears(firstRegularPaymentDate, 1)
        : null;
      break;
    case PaymentFrequency.SEMI_ANNUALLY:
      {
        const isMonthBeforeJuly = firstRegularPaymentDate.getMonth() < 6;
        const isBeforeJulySecond =
          firstRegularPaymentDate.getMonth() === 6 && closingDate.getDate() < 2;
        const isBeforeMiddleOfYear = isMonthBeforeJuly || isBeforeJulySecond;
        interestAdjustmentDate = isDifferenceMoreThanPaymentFrequency
          ? isBeforeMiddleOfYear
            ? getMiddleOfLastYear(firstRegularPaymentDate)
            : startOfYear(firstRegularPaymentDate)
          : null;
      }

      break;
    default:
      break;
  }

  return interestAdjustmentDate
    ? fromLocalDateToShortISODate(new Date(interestAdjustmentDate))
    : null;
}

export function servicingInterestAdjustmentDateByNextPaymentDate(
  mortgage: Partial<Mortgage> | undefined,
) {
  if (!mortgage?.closingDate || !mortgage?.nextPaymentDate || !mortgage?.paymentFrequency) {
    return mortgage?.interestAdjustmentDate;
  }
  const paymentFrequency = mortgage.paymentFrequency;
  const closingDate = fromShortISODateToMiddleLocalDate(mortgage?.closingDate);
  const nextPaymentDate = fromShortISODateToMiddleLocalDate(mortgage?.nextPaymentDate);
  let interestAdjustmentDate: Date | null = null;

  switch (paymentFrequency) {
    case PaymentFrequency.MONTHLY:
      interestAdjustmentDate = subMonths(nextPaymentDate, 1);
      break;
    case PaymentFrequency.SEMI_MONTHLY: {
      interestAdjustmentDate =
        nextPaymentDate.getDate() === 1
          ? setDate(subMonths(nextPaymentDate, 1), 15)
          : nextPaymentDate.getDate() === 15
          ? setDate(nextPaymentDate, 1)
          : mortgage?.interestAdjustmentAmount
          ? new Date(mortgage?.interestAdjustmentAmount)
          : null;
      break;
    }
    case PaymentFrequency.BI_WEEKLY:
    case PaymentFrequency.ACCELERATED_BI_WEEKLY:
      interestAdjustmentDate = subDays(nextPaymentDate, 14);
      break;
    case PaymentFrequency.WEEKLY:
    case PaymentFrequency.ACCELERATED_WEEKLY:
      interestAdjustmentDate = subDays(nextPaymentDate, 7);
      break;
    case PaymentFrequency.DAILY:
      interestAdjustmentDate = subDays(nextPaymentDate, 1);
      break;
    case PaymentFrequency.QUARTERLY:
      interestAdjustmentDate = subMonths(nextPaymentDate, 3);
      break;
    case PaymentFrequency.ANNUALLY:
      interestAdjustmentDate = subYears(nextPaymentDate, 1);
      break;
    case PaymentFrequency.SEMI_ANNUALLY:
      {
        const isMonthBeforeJuly = nextPaymentDate.getMonth() < 6;
        const isBeforeJulySecond = nextPaymentDate.getMonth() === 6 && closingDate.getDate() < 2;
        const isBeforeMiddleOfYear = isMonthBeforeJuly || isBeforeJulySecond;
        interestAdjustmentDate = isBeforeMiddleOfYear
          ? getMiddleOfLastYear(nextPaymentDate)
          : startOfYear(nextPaymentDate);
      }

      break;
    default:
      break;
  }

  return interestAdjustmentDate
    ? fromLocalDateToShortISODate(new Date(interestAdjustmentDate))
    : null;
}

export function differenceInDaysBetweenDates(
  firstRegularPaymentDate: Date | null | undefined,
  closingDate: Date | null | undefined,
) {
  if (!firstRegularPaymentDate || !closingDate) {
    return null;
  }
  const diff = Math.abs(firstRegularPaymentDate.getTime() - closingDate.getTime());
  const diffDays = Math.round(diff / (1000 * 3600 * 24));
  return diffDays;
}

/**
 * Subtracts one month from the given date while handling edge cases for month-end dates.
 *
 * Edge Cases:
 * - February 28 (or 29 in leap years) will return January 31.
 * - March 31 will return February 28 (or 29 in leap years).
 * - May 31 will return April 30.
 * - July 31 will return June 30.
 * - October 31 will return September 30.
 * - December 31 will return November 30.
 *
 * @param date - The input date from which one month will be subtracted.
 * @returns A new date object representing the date one month before the input date.
 */
export function subtractOneMonth(date: Date): Date {
  const subOneMonthDate = subMonths(date, 1);

  // Handle the edge case where the input date is the end of the month.
  if (endOfDay(date).getTime() === endOfMonth(date).getTime()) {
    return endOfMonth(subOneMonthDate);
  }

  return subOneMonthDate;
}

export function valueByPaymentFrequency(
  firstRegularPaymentDate: Date,
  paymentFrequency: PaymentFrequency,
) {
  if (!firstRegularPaymentDate) {
    return null;
  }
  const daysInYear = isLeapYear(firstRegularPaymentDate.getFullYear()) ? 366 : 365;

  const numberOfDaysBetweenDates = differenceInDaysBetweenDates(
    firstRegularPaymentDate,
    subMonths(firstRegularPaymentDate, 1),
  );

  if (numberOfDaysBetweenDates === null) {
    return null;
  }

  switch (paymentFrequency) {
    case PaymentFrequency.MONTHLY:
      return numberOfDaysBetweenDates;
    case PaymentFrequency.SEMI_MONTHLY:
      return Math.round(numberOfDaysBetweenDates / 2);
    case PaymentFrequency.BI_WEEKLY:
    case PaymentFrequency.ACCELERATED_BI_WEEKLY:
      return 14;
    case PaymentFrequency.WEEKLY:
    case PaymentFrequency.ACCELERATED_WEEKLY:
      return 7;
    case PaymentFrequency.DAILY:
      return 1;
    case PaymentFrequency.QUARTERLY:
      return Math.round(daysInYear / 4);
    case PaymentFrequency.ANNUALLY:
      return daysInYear;
    case PaymentFrequency.SEMI_ANNUALLY:
      return Math.round(daysInYear / 2);
    default:
      return null;
  }
}
