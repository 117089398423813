import {
  ComputePropertyValueOptions,
  CalculationAutomationSettings,
  Property,
} from '@fundmoreai/models';

interface PropertyValueInput {
  property: Property | undefined;
  calculationSettings?: CalculationAutomationSettings | null | undefined;
  filterByApplicantId?: string;
}

export function computePropertyValue({
  property,
  calculationSettings,
  filterByApplicantId,
}: PropertyValueInput): number {
  let propertyValue = 0;
  const computePropertyValueOption = calculationSettings?.ANY_MORTGAGE?.computePropertyValueOption;

  // apply property value calculation options
  switch (computePropertyValueOption) {
    case ComputePropertyValueOptions.MIN_BETWEEN_VALUE_AND_PURCHASE_PRICE: {
      propertyValue = calculateOnMinPropertyValueAndPurchasePrice(property);
      break;
    }
    case ComputePropertyValueOptions.PROPERTY_VALUE:
      propertyValue = property?.estimatedValue || 0;
      break;
    default:
      propertyValue = calculateOnMinPropertyValueAndPurchasePrice(property);
      break;
  }

  if (filterByApplicantId) {
    if (property?.ApplicantProperties.find((x) => x.applicantId === filterByApplicantId)) {
      propertyValue = propertyValue / property.ApplicantProperties.length;
    } else {
      propertyValue = 0;
    }
  }

  return +propertyValue;
}

function calculateOnMinPropertyValueAndPurchasePrice(property?: Property): number {
  let propertyValue = 0;
  const propertyValues = [property?.estimatedValue, property?.purchasePrice].filter(
    (x): x is number => !!x,
  );
  propertyValue = propertyValues.length != 0 ? Math.min(...propertyValues) : 0;

  return propertyValue;
}
