import {
  CreditBureauType,
  EquifaxMappedReportResponse,
  EquifaxReportWithErrorResponse,
  EquifaxResponseType,
} from './equifax';

export interface CreditReportReference {
  id: string;
  applicationId: string;
  type: CreditBureauType;
  metadata: CreditReportMetadata;
  reports: EquifaxMappedReportResponse | EquifaxReportWithErrorResponse;
  humanReadableReport: string;
  reportDate: string;
  source: CreditReportSource;
  ficoExternalSequenceNumber?: number | null;
  bniExternalSequenceNumber?: number | null;
}

export interface CreditReportMetadata {
  applicants: Partial<CreditReportApplicantInfo>[];
}
export interface CreditReportApplicantInfo {
  id: string;
  address: string;
  employer: string;
  lastName: string | null;
  firstName: string | null;
  occupation: string;
  dateOfBirth: string;
  subjectType: string;
  cellPhoneNumber: string;
}

export interface CreditReportReferenceReports {
  type?: EquifaxResponseType.CREDIT_REPORT;
  reports: Array<CreditReportReferenceReportsApplicant>;
}
interface CreditReportReferenceReportsApplicant {
  applicantId?: string;
  subjectType?: string;
  creditReport?: CreditReport;
  subjectFirstName?: string;
  subjectLastName?: string;
  dateOfRequest?: string;
  dateOfBirth?: string;
  hitCode?: string;
  hitCodeDescription?: string;
  hitStrengthIndicatorCode?: string;
  hitStrengthIndicatorDescription?: string;
  warnings?: Array<CreditReportWarnings>;
}

export enum CreditReportRequestHistoryKey {
  HIT_STRENGTH_INDICATOR_DESCRIPTION = 'hitStrengthIndicatorDescription',
  ERROR_DESCRIPTION = 'errorDescription',
  BNI_SCORE = 'bniScore',
}

interface CreditReport {
  summary: any;
  ficoScore: number;
  ficoScoreCode?: string;
  bniScore: number;
  addresses: any[];
  tradelines: any[];
  publicRecords: any[];
}

interface CreditReportWarnings {
  code: string;
  description: string;
}

export enum CreditReportSource {
  EQUIFAX = 'EQUIFAX',
  POS = 'POS',
}
