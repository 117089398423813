import {
  ComputePropertyValueOptions,
  LoanType,
  Mortgage,
  CalculationAutomationSettings,
} from '@fundmoreai/models';

export interface ComputeMortgageCalculationAutomationSettings {
  requestedMortgages: Pick<Mortgage, 'id' | 'loanType'>[];
  eqMapsEnabled: boolean;
  excludeExtraPaymentInTerm: boolean;
  computePropertyValueOption?: ComputePropertyValueOptions;
  hasError: boolean;
}

export function computeMortgageCalculationAutomationSettings(
  input: ComputeMortgageCalculationAutomationSettings,
): CalculationAutomationSettings | undefined {
  const {
    requestedMortgages,
    eqMapsEnabled,
    excludeExtraPaymentInTerm,
    computePropertyValueOption,
    hasError,
  } = input;

  if (requestedMortgages?.length === 0) {
    return undefined;
  }

  let calculationAutomationSettings: CalculationAutomationSettings = {};
  let requestedMortgage, helocRequestedMortgage;

  if (requestedMortgages.length === 1) {
    requestedMortgage = requestedMortgages[0];
  } else if (requestedMortgages.length === 2) {
    requestedMortgage = requestedMortgages.find((m) => m && m.loanType === LoanType.MORTGAGE);
    helocRequestedMortgage = requestedMortgages.find(
      (m) => m && m.loanType === LoanType.SECURE_LINE_OF_CREDIT,
    );
  }
  if (eqMapsEnabled) {
    if (requestedMortgage && requestedMortgage.id) {
      calculationAutomationSettings[requestedMortgage.id] = {
        computePropertyValueOption: undefined,
        isInterestAdjustmentAmountDisabled: true,
        isInterestAdjustmentDateDisabled: true,
        isMaturityDateDisabled: true,
        excludeExtraPaymentInTerm: undefined,
        isMonthlyPaymentDisabled: true,
        isPaymentAmountDisabled: true,
        setTDSToZero: false,
        setGDSToZero: false,
      };
    }

    if (helocRequestedMortgage && helocRequestedMortgage.id) {
      calculationAutomationSettings[helocRequestedMortgage.id] = {
        computePropertyValueOption: undefined,
        isInterestAdjustmentAmountDisabled: false,
        isInterestAdjustmentDateDisabled: false,
        isMaturityDateDisabled: false,
        excludeExtraPaymentInTerm: undefined,
        isMonthlyPaymentDisabled: false,
        isPaymentAmountDisabled: false,
        setTDSToZero: false,
        setGDSToZero: false,
      };
    }

    if (hasError) {
      calculationAutomationSettings.ANY_MORTGAGE = {
        computePropertyValueOption: undefined,
        isInterestAdjustmentAmountDisabled: undefined,
        isInterestAdjustmentDateDisabled: undefined,
        isMaturityDateDisabled: undefined,
        excludeExtraPaymentInTerm: undefined,
        isMonthlyPaymentDisabled: undefined,
        isPaymentAmountDisabled: undefined,
        setGDSToZero: true,
        setTDSToZero: true,
      };
    }
  } else {
    const excludeExtraPaymentInTermSetting = excludeExtraPaymentInTerm ? true : undefined;
    const computePropertyValueOptionSetting = computePropertyValueOption
      ? computePropertyValueOption
      : undefined;

    if (excludeExtraPaymentInTerm || computePropertyValueOption) {
      calculationAutomationSettings = {
        ANY_MORTGAGE: {
          computePropertyValueOption: computePropertyValueOptionSetting,
          isInterestAdjustmentAmountDisabled: undefined,
          isInterestAdjustmentDateDisabled: undefined,
          isMaturityDateDisabled: undefined,
          excludeExtraPaymentInTerm: excludeExtraPaymentInTermSetting,
          isMonthlyPaymentDisabled: undefined,
          isPaymentAmountDisabled: undefined,
          setGDSToZero: undefined,
          setTDSToZero: undefined,
        },
      };
    } else {
      calculationAutomationSettings = undefined;
    }
  }

  return calculationAutomationSettings;
}
