import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { EMPTY } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { LoadingEnd, LoadingStart } from '../core/loading.state';
import { Broker } from '../shared/model';
import { BrokerService } from './brokers.service';
import {
  BrokerTableKey,
  BrokerTableKeyRecord,
} from '../features/manager-portal/lawyer-broker-shared/lawyer-broker-details/model';
import { AgentAction } from '@fundmoreai/models';

export class UpdateBroker {
  static readonly type = '@brokers.UpdateBroker';
  constructor(public broker: Partial<Broker>) {}
}

export class DeleteBroker {
  static readonly type = '@brokers.DeleteBroker';
  constructor(public id: string) {}
}

export class CreateBroker {
  static readonly type = '@brokers.CreateBroker';
  constructor(public broker: Broker) {}
}

export class FetchBrokers {
  static readonly type = '@brokers.FetchBrokers';
  constructor(public skipIfExists: boolean = false) {}
}

export class BulkActionBroker {
  static readonly type = '@brokers.BulkActionBroker';
  constructor(public brokerIds: string[], public action: AgentAction) {}
}

export class DeleteBulkBroker {
  static readonly type = '@brokers.DeleteBulkBroker';
  constructor(public brokerIds: string[]) {}
}

export const MANAGE_BROKERS_DEFAULT_DISPLAY_COLUMNS = Object.values(BrokerTableKey).map(
  (value: BrokerTableKey, index) => {
    return {
      field: value,
      name: BrokerTableKeyRecord[value],
      isSelected: index < 5,
      isFrozen: value === BrokerTableKey.VERIFIED,
    };
  },
);

@State<Broker[]>({
  name: 'brokers',
  defaults: [],
})
@Injectable()
export class BrokersState {
  constructor(private brokersService: BrokerService) {}

  @Selector()
  static brokers(state: Broker[]) {
    return state ?? [];
  }

  static getById(id: string) {
    return createSelector([BrokersState.brokers], (brokers: Broker[]) => {
      return brokers.find((x) => x.id === id);
    });
  }

  @Selector()
  static verifiedBrokers(state: Broker[]) {
    return state.filter((broker) => broker.verified === true);
  }

  @Selector()
  static allFirmCodes(state: Broker[]): string[] {
    return Array.from(
      new Set(
        state
          .map((broker) => broker.firmCode)
          .filter((firmCode): firmCode is string => firmCode !== null && firmCode !== undefined),
      ),
    );
  }

  @Action(UpdateBroker)
  updateBroker(ctx: StateContext<Broker[]>, action: UpdateBroker) {
    if (!action.broker.id) {
      return;
    }

    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.brokersService
      .updateBroker(action.broker.id, { ...action.broker, id: undefined })
      .pipe(
        tap(() => {
          const update = updateItem<Broker>(
            (broker) => broker?.id === action.broker.id,
            patch(action.broker),
          );
          ctx.setState(update);
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(DeleteBroker)
  deleteBroker(ctx: StateContext<Broker[]>, action: DeleteBroker) {
    const state = ctx.getState();
    const prevId = action.id;
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.brokersService.deleteBroker(prevId).pipe(
      tap(() => ctx.setState([...state.filter((f) => f.id !== prevId)])),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(CreateBroker)
  createBroker(ctx: StateContext<Broker[]>, action: CreateBroker) {
    const state = ctx.getState();
    const prevId = action.broker.id;
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.brokersService.addBroker({ ...action.broker, id: undefined }).pipe(
      tap((broker) => {
        return ctx.setState([...state.filter((f) => f.id !== prevId), broker]);
      }),
      catchError((e) => {
        ctx.setState([...state.filter((f) => f.id !== prevId)]);
        throw e;
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(FetchBrokers)
  getBrokers(ctx: StateContext<Broker[]>, { skipIfExists }: FetchBrokers) {
    const brokers = ctx.getState();
    if (brokers?.length > 0 && skipIfExists) {
      return EMPTY;
    }

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.brokersService.getBrokers().pipe(
      tap((brokers) => {
        ctx.setState(
          brokers.sort((a, b) => {
            const brokerOne = a.firstName ?? '';
            const brokerTwo = b.firstName ?? '';
            return brokerTwo.localeCompare(brokerOne);
          }),
        );
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }
  @Action(BulkActionBroker)
  bulkActionBrokers(ctx: StateContext<Broker[]>, action: BulkActionBroker) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.brokersService.bulkActionBrokers(action.brokerIds, action.action).pipe(
      switchMap(() => {
        return ctx.dispatch(new FetchBrokers());
      }),
    );
  }

  @Action(DeleteBulkBroker)
  deleteBulkBrokers(ctx: StateContext<Broker[]>, action: DeleteBulkBroker) {
    return this.brokersService.deleteBulkBrokers(action.brokerIds).pipe(
      tap(() => {
        let brokers = ctx.getState().map((broker) => ({
          ...broker,
        }));

        for (const broker of brokers) {
          if (action.brokerIds.includes(broker.id)) {
            brokers = brokers.filter((b) => b.id !== broker.id);
          }
        }
        ctx.setState(brokers);
      }),
    );
  }
}
