import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { from, Observable, switchMap } from 'rxjs';
import { DebouncePeriods, STORE_ACTIONS_QUEUE_DELAY } from '../../features/shared/constants';

// Queues store actions and dispatches them sequentially.
// Use this service for avoiding race conditions.
@Injectable({
  providedIn: 'root',
})
export class StoreActionsQueue {
  private queue: (unknown | unknown[])[] = [];

  constructor(private store: Store) {}

  /**
   * Dispatches the action after the queue is empty.
   * @param actionOrActions actions to be dispatched
   * @returns observable that needs to be subscribed to
   */
  pushWithDelay(actionOrActions: unknown | unknown[]): Observable<unknown> {
    return from(this.isQueueEmpty()).pipe(switchMap(() => this.push(actionOrActions)));
  }

  /**
   * Adds the action to the queue and dispatches it when it's the first in the queue.
   * @param actionOrActions actions to be dispatched
   * @returns observable that needs to be subscribed to
   */
  push(actionOrActions: unknown | unknown[]): Observable<unknown> {
    this.queue.push(actionOrActions);

    const wrapper = new Observable<unknown>((observer) => {
      const intervalId = setInterval(() => {
        if (this.queue.length > 0) {
          const first = this.queue[0];

          if (first === actionOrActions) {
            clearInterval(intervalId);

            this.store.dispatch(actionOrActions).subscribe((...args) => {
              observer.next(...args);
              observer.complete();

              this.queue.shift();
            });
          }
        }
      }, STORE_ACTIONS_QUEUE_DELAY);
    });

    return wrapper;
  }

  private async isQueueEmpty(): Promise<void> {
    // wait 750ms for debounce (500ms long debounce + 250ms for safety)
    await new Promise((resolve) => setTimeout(resolve, DebouncePeriods.LONG + 250));
    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (this.queue.length === 0) {
          clearInterval(intervalId);
          resolve();
        }
      }, STORE_ACTIONS_QUEUE_DELAY * 2);
    });
  }
}
