import { Fee } from '@fundmoreai/models';

export function fixFeeAmount(
  fee: Pick<Fee, 'percent' | 'amount'>,
  loanAmount: number | null | undefined,
) {
  if (fee.percent === undefined || fee.percent === null) {
    return;
  }

  fee.amount = Math.round((((loanAmount ?? 0) * fee.percent) / 100) * 1000) / 1000;
}

export function fixFeePercent(
  fee: Pick<Fee, 'percent' | 'amount'>,
  loanAmount: number | null | undefined,
) {
  if (!loanAmount) {
    return;
  }
  fee.percent = Math.round(((fee.amount ?? 0) / loanAmount) * 100 * 1000) / 1000;
}

export function isFeeCalculationCorrect(
  fee: Pick<Fee, 'percent' | 'amount'>,
  loanAmount: number | null | undefined,
): boolean {
  if (loanAmount && fee.amount && fee.percent) {
    const newPercentage = Math.round((fee.amount / loanAmount) * 100 * 1000) / 1000;
    const areRoughlyEqual = Math.abs(newPercentage - fee.percent) <= 0.0001;
    return areRoughlyEqual;
  }

  return true;
}
