import { ApplicationBrokerCommission } from './brokerCommission';
import { ClosingInstruction } from './closingInstruction';
import {
  PaymentFrequency,
  CompoundPeriod,
  RepaymentType,
  PrepaymentType,
  MortgageType,
  LoanType,
  MortgagePosition,
  RateType,
  TermType,
  PayoffType,
  InsurancePaidByType,
  InsurancePremiumProgram,
  PayoffPaydownType,
  ConformingType,
  SecurityType,
} from './enums';
import {
  IncrementCategory,
  MortgageIncrement,
  MortgageDecrement,
  DiscretionStatus,
} from './increment-decrement';
import { InsurerType } from './insurance/insuranceQuote';
import { Property } from './property';
import { ApplicationAdvancedProduct } from './rateMatrix/applicationAdvancedProduct';
import { PrepaymentOptions } from './servicing';

export enum MortgageKey {
  AMORTIZATION = 'amortizationMonths',
  AMOUNT_TO_BE_ADVANCED = 'amountToBeAdvanced',
  BALLOON_AMOUNT = 'balloonAmount',
  BORROWER = 'borrower',
  CONFORMING = 'conforming',
  PAYOFF = 'payoff',
  PAYOFF_PAYDOWN = 'payoffPaydown',
  CLOSING_DATE = 'closingDate',
  COF_DATE = 'cofDate',
  FUNDING_DATE = 'fundingDate',
  COMPOUNDING = 'compounding',
  DEADLINE = 'deadlineToAcceptDate',
  FIRST_REGULAR_PAYMENT_DATE = 'firstRegularPaymentDate',
  INSURANCE_AMOUNT = 'insuranceAmount',
  INSURANCE_PREMIUM = 'insurancePremium',
  INSURANCE_PREMIUM_PROGRAM = 'insurancePremiumProgram',
  INSURANCE_ACCOUNT_NUMBER = 'insuranceAccountNum',
  INSURANCE_PREMIUM_SURCHARGE_APPLIED = 'insurancePremiumSurchargeApplied',
  INCLUDE_PREMIUM_IN_MORTGAGE = 'includePremiumInMortgage',
  INTEREST_ADJUSTMENT_DATE = 'interestAdjustmentDate',
  INSURER = 'insurer',
  PREPAYMENT_AMOUNT_IN_PAYMENTS = 'prepaymentAmountInPayments',
  LENDER = 'lender',
  HOLDER = 'holder',
  LOAN_NUMBER = 'loanNumber',
  LOAN_TYPE = 'loanType',
  MATURITY_DATE = 'maturityDate',
  MONTHLY_PAYMENT = 'monthlyPayment',
  MORTGAGE_BALANCE = 'mortgageBalance',
  MORTGAGE_TYPE = 'mortgageType',
  NET_RATE = 'netRate',
  BASE_RATE = 'baseRate',
  BUYDOWN = 'buyDownRate',
  DISCOUNT = 'discount',
  ORIGINAL_NET_RATE = 'originalNetRate',
  PAYMENT_FREQUENCY = 'paymentFrequency',
  PAYOUT_BALANCE = 'payoutBalance',
  APPLICATION_ADVANCED_PRODUCT = 'applicationAdvancedProduct',
  PURCHASE_VALUE = 'purchaseValue',
  PURPOSE = 'purpose',
  REPAYMENT_TYPE = 'repaymentType',
  PREPAYMENT_TYPE = 'prepaymentType',
  PREPAYMENT_PENALTY_PERIOD = 'prepaymentPenaltyPeriod',
  PREPAYMENT_AMOUNT = 'prepaymentAmount',
  PST = 'pst',
  RATE_TYPE = 'rateType',
  TERM = 'termMonths',
  TERM_TYPE = 'termType',
  TOTAL_MORTGAGE_AMOUNT = 'loanAmount',
  CASH_ADVANCE = 'cashAdvance',
  TOTAL_REGULAR_PAYMENT = 'totalRegularPayment',
  MORTGAGE_NUMBER = 'mortgageNum',
  APPLICANT_MORTGAGES = 'ApplicantMortgages',
  ORIGINAL_MI_PROVIDER = 'originalMIProvider',
  ORIGINAL_MI_NUMBER = 'originalMINumber',
  INSURANCE_PAID_BY = 'insurancePaidBy',
  REFI_BLENDED_AMORTIZATION = 'refiBlendedAmortization',
  PAYMENT_AMOUNT = 'paymentAmount',
  INTEREST_ADJUSTMENT_AMOUNT = 'interestAdjustmentAmount',
  PARTNER_LOAN_NAME = 'partnerLoanName',
  PARTNER_LOAN_PORTION = 'partnerLoanPortion',
  PARTNER_LOAN_EQ_PORTION = 'partnerLoanEQPortion',
  PARTNER_LOAN_YIELD = 'partnerLoanYield',
  MAX_PREPAYMENT_AMOUNT = 'maxPrepaymentAmount',
  PROGRAM_CODE = 'programCode',
  PROPERTY_ID = 'propertyId',
  CONSTRUCTION_COST = 'constructionCost',
  CONSTRUCTION_CASH = 'constructionCash',
  SECURITY_TYPE = 'securityType',
  SECURITY = 'security',
  BORROWING_LIMIT = 'borrowingLimit',
  MORTGAGE_PRODUCT_ID = 'mortgageProductId',
  RATE_HOLD_DATE = 'rateHoldDate',
  STATUS = 'status',
  STATE = 'state',
  STATE_CHANGE_COMMENT = 'stateChangeComment',
  STATE_CHANGE_DATE = 'stateChangeDate',
  INCREMENTS = 'increments',
  INCREMENT_TOTAL = 'incrementTotal',
  CUSTOM_INCREMENT_AMOUNT = 'customIncrementAmount',
  CUSTOM_INCREMENT_NOTE = 'customIncrementNote',
  PRODUCT_MEMBER_NUMBER = 'productMemberNumber',
  MARK_AS = 'markAs',
  BLENDED_RATE = 'blendedRate',
  NEXT_PAYMENT_DATE = 'nextPaymentDate',
  ORIGINAL_AMORTIZATION = 'originalAmortization',
  PROPERTY_TAX_AMOUNT = 'propertyTaxAmount',
  PREPAYMENT_OPTIONS = 'prepaymentOptions',
  PROJECTED_BALANCE = 'projectedBalance',
  NEW_MATURITY_DATE = 'newMaturityDate',
  TRANSFER_ADJUSTMENT = 'transferAdjustment',
  NEGATIVE_AMORTIZATION_RATE = 'negativeAmortizationRate',
  IS_LENDER_LIABILITY = 'isLenderLiability',
}

export interface ApplicantMortgage {
  applicantId: string;
  mortgageId: string;
}

export interface Mortgage {
  id: string;
  applicationId: string;
  expandedLoanNumber: ExpandedLoanNumber;
  amortizationMonths: number | null; // decimal(28, 6), for integer use Math.ceil(value)
  closingDate: string | null;
  cofDate: string | null;
  fundingDate: string | null;
  insuranceAmount: number | null;
  insurancePremium: number | null;
  insurancePremiumProgram: InsurancePremiumProgram | null;
  includePremiumInMortgage: boolean | null;
  insurer: InsurerType | null;
  lender: string | null;
  loanType: LoanType | null;
  amortizedMonthlyPayment?: number | null;
  amortizedMonthlyPaymentSnapshot?: AmortizedMonthlyPaymentSnapshot | null;
  mortgageBalance: number | null;
  mortgageType: MortgagePosition | null;
  netRate: number | null;
  baseRate: number | null;
  buyDownRate: number | null;
  discount: number | null;
  originalNetRate: number | null;
  paymentAmount: number | null; // mortgage payment value computed in the paymentFrequency
  payoutBalance: number | null;
  productName: string | null;
  propertyId: string | null;
  purchaseValue: number | null;
  purpose: string | null;
  rateType: RateType | null;
  prepaymentPenaltyPeriod: number | null;
  prepaymentAmount: number | null;
  prepaymentAmountInPayments: number | null | undefined;
  isPrepaymentAmountInPayments: boolean | null;
  maxPrepaymentAmount?: number | null;
  balloonAmount: number | null;
  termMonths: number | null;
  termType: TermType | null;
  loanAmount: number | null;
  cashAdvance: number | null;
  totalLoanAmount: number | null;
  totalMortgageAmount?: number | null; // added for backwards compatibility [DOCUMENTS]
  interestAdjustmentDate: string | null;
  firstRegularPaymentDate: string | null;
  maturityDate: string | null;
  deadlineToAcceptDate: string | null;
  pst: number | null;
  amountToBeAdvanced: number | null;
  createdAt: string;
  updatedAt: string;
  payoff: PayoffType;
  payoffPaydown: PayoffPaydownType;
  interestAdjustmentAmount: number | null;
  insuranceAccountNum: string | null;
  mortgageNum: string | null;
  ApplicantMortgages: ApplicantMortgage[];
  applicationAdvancedProduct?: ApplicationAdvancedProduct | null;
  brokerCommission?: ApplicationBrokerCommission | null;
  conforming?: ConformingType | null;
  constructionCash?: number | null;
  constructionCost?: number | null;
  // Computed FE field used in the documents context;
  // contains mortgage payment computed in all frequencies.
  payments?: { [key in PaymentFrequency]: number };
  originalMIProvider?: InsurerType | null;
  originalMINumber?: string | null;
  insurancePaidBy?: InsurancePaidByType | null;
  externalSequenceNumber?: number | null;
  partnerLoanName?: string | null;
  partnerLoanPortion?: number | null;
  partnerLoanEQPortion?: number | null;
  partnerLoanYield?: number | null;
  refiBlendedAmortization: boolean | null;
  totalRegularPayment: number | null;
  programCode?: string | null;
  securityType?: SecurityType | null;
  security?: Security[] | null;
  borrowingLimit?: number | null;
  mortgageProductId?: string | null;
  rateHoldDate?: string | null;
  status?: MortgageStatus[] | null;
  state?: MortgageState | null;
  stateChangeComment?: string | null;
  incrementCategoryApplied?: IncrementCategory | null;
  increments?: MortgageIncrement[] | null;
  customIncrementIsSet?: boolean | null;
  customIncrementAmount?: number | null;
  customIncrementNote?: string | null;
  decrements?: MortgageDecrement[] | null;
  decrementTotal?: number | null;
  incrementTotal?: number | null;
  productMemberNumber?: string | null;
  discretion?: number | null;
  discretionStatus?: DiscretionStatus | null;
  discretionUpdatedBy?: string | null;
  markAs?: RefinancedMortgageMarkAsType | null;
  ClosingInstructions?: ClosingInstruction[];
  nextPaymentDate?: string | null;
  originalAmortization?: number | null;
  propertyTaxAmount?: number | null;
  prepaymentOptions?: PrepaymentOptions | null;
  prepaymentPercentage?: number | null;
  projectedBalance?: number | null;
  pbForRenewalDate?: string | null;
  newMaturityDate?: Date | null;
  isBlendedRate?: boolean | null;
  savedNetRate?: number | null;
  insurancePremiumSurchargeApplied?: boolean | null;
  insurancePremiumSurcharge?: number | null;
  transferAdjustment?: number | null;
  contractualAmortizationMonths?: number | null;
  frequencyPayment?: number | null;
  rateMethod?: RateMethod | null;
  lastPaymentStatus?: PaymentStatus | null;
  useLiftPayment?: boolean | null;
  paymentAmountWithLift?: number | null;
  negativeAmortizationRate?: number | null;
  isLenderLiability?: boolean | null;
  Property: Pick<Property, 'id' | 'markAsSold' | 'type'> | null;
  customFields: { [key: string]: string } | null;
  type: MortgageType | null;
  compounding: CompoundPeriod | null;
  loanNumber: string | null;
  paymentFrequency: PaymentFrequency | null;
  repaymentType: RepaymentType | null;
  prepaymentType: PrepaymentType | null;
  stateChangeDate: string | null;
  monthlyPayment: number | null | undefined;
}

export interface ExpandedLoanNumber {
  prefix?: string | null;
  suffix?: string | null;
  number?: number | null;
}

export interface AmortizationSchedule {
  paymentDueDate: string;
  interest: number;
  principal: number;
  balance: number;
  payment: number;
}

export interface CommitmentRequestedMortgage {
  id: string;
  loanNumber: string | null;
  loanAmount: number | null;
  cashAdvance: number | null;
  totalMortgageAmount?: number | null; // added for backwards compatibility [DOCUMENTS]
  totalRegularPayment: number | null;
  purpose: string | null;
  mortgageType: string | null;
  netRate: number | null;
  baseRate: number | null;
  buyDownRate: number | null;
  discount: number | null;
  originalNetRate: number | null;
  rateType: string | null;
  compounding: string | null;
  paymentAmount: number | null;
  paymentFrequency: PaymentFrequency | null;
  repaymentType: string | null;
  termMonths: number | null;
  termType: string | null;
  monthlyPayment?: number | null;
  amountToAdvance: number | null;
  interestAdjustmentDate: string | null;
  firstRegularPaymentDate: string | null;
  maturityDate: string | null;
  dateOfAdvance: string | null | undefined;
  deadlineToAcceptDate: string | null;
  amortizationMonths: number | null;
  closingDate: string | null;
  fundingDate: string | null;
  prepaymentAmount: number | null;
  prepaymentPenaltyPeriod: number | null;
  isPrepaymentAmountInPayments: boolean | null;
  purchaseValue: number | null;
  useLiftPayment?: boolean | null;
  paymentAmountWithLift?: number | null;
}

export interface ConstructionMortgage {
  id: string;
  mortgageId: string;
  grossMortgageAmount: number;
  landAdvance: number;
  netLoanLessLandAdvance: number;
  landCost: number;
  buildingBudget: number;
  sqftOfProject: number;
  costPerSqft: number;
  builderName?: string;
}
export interface ConstructionDefault {
  id: string;
  constructionItem: string;
  percentage: number;
}
export interface ConstructionApplicationItem {
  id: string;
  constructionItem: string;
  percentage: number;
  budgetAmount: number;
  completedConstructionItemPercentage: number;
  amountAllowed: number;
  budgetPercentageUsed: number;
}

export interface DrawSchedule {
  id: string;
  tenantId: string;
  applicationId: string;
  drawDate: string;
  availableAdvanceAmount: number;
  hardCostCompletedToDate: number;
  estimatedInterestToBeDeducted: number;
  fundsHeldBack: number;
  daysFromPreviousAdvance: number;
  ctc: number;
  createdAt: string;
}

export interface AmortizedMonthlyPaymentSnapshot {
  repaymentType: RepaymentType;
  paymentFrequency: PaymentFrequency;
  compounding: CompoundPeriod;
  amortizationMonths: number | null | undefined;
  loanAmount: number | null | undefined;
  totalLoanAmount: number | null | undefined;
  netRate: number | null | undefined;
  termMonths: number | null | undefined;
}

export enum ProgramCodeType {
  NEW_TO_CANADA = 'NEW_TO_CANADA',
  CASH_BACK = 'CASH_BACK',
  FRIENDS_AND_FAMILY = 'FRIENDS_AND_FAMILY',
  RESTORATION_CREDIT = 'RESTORATION_CREDIT',
  HORIZON = 'HORIZON',
  HYBRID = 'HYBRID',
}

export enum Security {
  COLLATERAL_CHARGE = 'E',
  FIRST_MORTGAGE_RESIDENTIAL_OWNER_OCCUPIED = 'J',
  FIRST_MORTGAGE_RESIDENTIAL_NON_OWNER_OCCUPIED = 'K',
  SECOND_MORTGAGE_RESIDENTIAL = 'L',
  THIRD_MORTGAGE_RESIDENTIAL = 'M',
  PRIMA = 'Z',
  FULLY_SECURED_MCU_DEPOSIT_PRODUCTS = 'A',
  FULLY_SECURED_CANADA_SAVINGS_BONDS = 'B',
  OTHER_FI_STOCKS_BONDS_ETC = 'C',
  CHATTEL_MORTGAGE = 'D',
  WAGE_ASSIGNMENT_PROMISSORY_NOTE = 'H',
  LETTER_OF_DIRECTION = 'I',
  UNSECURED = 'Y',
}

export enum OperationFulfillmentMortgageStatus {
  SOLICITOR_INSTRUCTED = 'SOLICITOR_INSTRUCTED',
  FCT_ORDERED = 'FCT_ORDERED',
  FCT_RECEIVED = 'FCT_RECEIVED',
  APPLICATION_IN_PREPPING = 'APPLICATION_IN_PREPPING',
  READY_FOR_CLOSING = 'READY_FOR_CLOSING',
  UPLOADED = 'UPLOADED',
  FUNDS_DISBURSED = 'FUNDS_DISBURSED',
}

export enum FundedMortgageStatus {
  WAITING_FOR_FINAL_REPORT = 'WAITING_FOR_FINAL_REPORT',
  FINAL_REPORT_ACCEPTED = 'FINAL_REPORT_ACCEPTED',
}

export const MortgageStatus = { ...OperationFulfillmentMortgageStatus, ...FundedMortgageStatus };
export type MortgageStatus = OperationFulfillmentMortgageStatus | FundedMortgageStatus;

export enum MortgageState {
  ON_HOLD = 'ON_HOLD',
  CANCELLED = 'CANCELLED',
  DECLINED = 'DECLINED',
}

export enum RefinancedMortgageMarkAsType {
  REFINANCED = 'REFINANCED',
  SWITCHED_TRANSFERED = 'SWITCHED_TRANSFERED',
  PORTED = 'PORTED',
  REFINANCE_SWITCH_TRANSFER = 'REFINANCE_SWITCH_TRANSFER',
}

export enum RateMethod {
  FLAT = 'FLAT',
  SPLIT = 'SPLIT',
  STEP = 'STEP',
  STEPD = 'STEPD',
  SPLITD = 'SPLITD',
  PRIMSPLIT = 'PRIMSPLIT',
}

export enum PaymentStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  SENT = 'SENT',
  SKIPPED = 'SKIPPED',
  REVERSE = 'REVERSE',
  RECALLED = 'RECALLED',
  NSF = 'NSF',
  DAILY_LIMIT_EXCEEDED = 'DAILY_LIMIT_EXCEEDED',
  WEEKLY_LIMIT_EXCEEDED = 'WEEKLY_LIMIT_EXCEEDED',
  MONTHLY_LIMIT_EXCEEDED = 'MONTHLY_LIMIT_EXCEEDED',
}
