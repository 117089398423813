export enum EQClosingService {
  STANDARD = 'STANDARD',
  COLLATERAL_SWITCH = 'COLLATERAL_SWITCH',
  PLATINUM_REFINANCE = 'PLATINUM_REFINANCE',
  PLATINUM_REM = 'PLATINUM_REM',
  PLATINUM_SWITCH = 'PLATINUM_SWITCH',
}

export enum EQFraudAMLRiskType {
  HIGH_RISK = 'HIGH_RISK',
  HIGH_RISK_FOREIGN_PEP = 'HIGH_RISK_FOREIGN_PEP',
  HIGH_RISK_RE_CATEGORIZED = 'HIGH_RISK_RE_CATEGORIZED',
  STANDARD_RISK = 'STANDARD_RISK',
  STANDARD_RISK_DOMESTIC_PEP = 'STANDARD_RISK_DOMESTIC_PEP',
  STANDARD_RISK_RE_CATEGORIZED = 'STANDARD_RISK_RE_CATEGORIZED',
  THIRD_PARTY = 'THIRD_PARTY',
  HIGH_RISK_WITH_STR = 'HIGH_RISK_WITH_STR',
  HIGH_RISK_PEP_WITH_STR = 'HIGH_RISK_PEP_WITH_STR',
  DE_MARKETED_CLIENT = 'DE_MARKETED_CLIENT',
}

export enum LendingTierType {
  TIER_1 = 'TIER_1',
  TIER_2 = 'TIER_2',
  TIER_3 = 'TIER_3',
}

export enum EQIntegrationFields {
  CLOSING_SERVICE = 'closingService',
  ESTIMATED_TAX_HOLDBACK = 'estimatedTaxHoldback',
  LENDING_TIER = 'lendingTier',
  PARTNER_LOAN = 'partnerLoan',
  EQ_FRAUD_AML_RISK = 'eqFraudAMLRisk',
  RELATED_CIFS = 'relatedCIFs',
  INCOME_VERIFICATION = 'incomeVerification',
}

export enum EQPartnerLoanFields {
  PARTNER_LOAN_NAME = 'partnerLoanName',
  PARTNER_LOAN_PORTION = 'partnerLoanPortion',
  PARTNER_LOAN_EQ_PORTION = 'partnerLoanEQPortion',
  PARTNER_LOAN_YIELD = 'partnerLoanYield',
}
