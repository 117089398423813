export interface Limit {
  id: string;
  field: LimitTargetField;
  comparisons: Comparison[];
}

export interface Comparison {
  id: string;
  operator: Operator;
  value: number | string;
  conditions: LimitCondition[];
}

export interface LimitCondition {
  field: ConditionField;
  readableField?: string;
  key: string;
  value?: string;
}

export enum Operator {
  Equal = '=',
  LessThan = '<',
  GreaterThan = '>',
  Exists = 'exists',
}

export enum LogicalOperator {
  AND = 'AND',
  OR = 'OR',
}

export enum LimitTargetField {
  LTV = 'LTV',
  GDS = 'GDS',
  TDS = 'TDS',
  QGDS = 'QGDS',
  QTDS = 'QTDS',
  LoanAmount = 'LoanAmount',
  PropertyType = 'PropertyType',
  Occupancy = 'Occupancy',
  Insurer = 'Insurer',
  Amortization = 'Amortization',
  ApplicationPurpose = 'ApplicationPurpose',
  ApplicationSource = 'ApplicationSource',
  LoanType = 'LoanType',
  PurchasePrice = 'PurchasePrice',
  RateType = 'RateType',
  Term = 'Term',
  TermType = 'TermType',
  CreditScore = 'CreditScore',
  FirmCode = 'FirmCode',
}

export enum ConditionField {
  Insurer = 'Insurer',
  ApplicationPurpose = 'ApplicationPurpose',
  ApplicationSource = 'ApplicationSource',
  ZoningType = 'ZoningType',
  DwellingType = 'DwellingType',
  Occupancy = 'Occupancy',
}

export const ConditionFieldList = [
  ConditionField.ApplicationSource,
  ConditionField.ApplicationPurpose,
  ConditionField.DwellingType,
  ConditionField.Insurer,
  ConditionField.Occupancy,
  ConditionField.ZoningType,
];

export const ConditionFieldMapNotTranslated: Record<ConditionField, string> = {
  [ConditionField.Insurer]: `Insurer`,
  [ConditionField.ApplicationPurpose]: `Application Purpose`,
  [ConditionField.ApplicationSource]: `Application Source`,
  [ConditionField.ZoningType]: `Zoning Type`,
  [ConditionField.DwellingType]: `Dwelling Type`,
  [ConditionField.Occupancy]: `Occupancy`,
};

export const LimitTargetFieldMapNotTranslated: Record<LimitTargetField, string> = {
  [LimitTargetField.LTV]: `LTV`,
  [LimitTargetField.GDS]: `GDS`,
  [LimitTargetField.TDS]: `TDS`,
  [LimitTargetField.QGDS]: `QGDS`,
  [LimitTargetField.QTDS]: `QTDS`,
  [LimitTargetField.LoanAmount]: `Loan Amount`,
  [LimitTargetField.PropertyType]: `Property Type`,
  [LimitTargetField.Occupancy]: `Occupancy`,
  [LimitTargetField.Insurer]: `Insurer`,
  [LimitTargetField.Amortization]: `Amortization`,
  [LimitTargetField.ApplicationPurpose]: `Application Purpose`,
  [LimitTargetField.ApplicationSource]: `Application Source`,
  [LimitTargetField.LoanType]: `Loan Type`,
  [LimitTargetField.PurchasePrice]: `Purchase Price`,
  [LimitTargetField.RateType]: `Rate Type`,
  [LimitTargetField.Term]: `Term`,
  [LimitTargetField.TermType]: `Term Type`,
  [LimitTargetField.CreditScore]: `Credit Score`,
  [LimitTargetField.FirmCode]: `Firm Code`,
};
