/* eslint-disable max-len */
import {
  IncrementCategory,
  IncrementIncomeStatus,
  IncrementMortgageTerm,
  IncrementType,
} from '@fundmoreai/models';

export const IncrementCategoryRecord: Record<IncrementCategory, string> = {
  [IncrementCategory.STANDARD_INCREMENT]: $localize`:@@IncrementCategory.STANDARD_INCREMENT:Standard Increments`,
  [IncrementCategory.NON_INCOME_QUALIFYING]: $localize`:@@IncrementCategory.NON_INCOME_QUALIFYING:Non-Income Qualifying`,
  [IncrementCategory.HORIZON_CREDIT_PROGRAM]: $localize`:@@IncrementCategory.HORIZON_CREDIT_PROGRAM:Horizon Credit Program`,
  [IncrementCategory.BRIDGE_LOAN]: $localize`:@@IncrementCategory.BRIDGE_LOAN:Bridge`,
  [IncrementCategory.LOAN_TO_INCOME_RATIO]: $localize`:@@IncrementCategory.LOAN_TO_INCOME_RATIO:LTI`,
};

export const IncrementTypeRecord: Record<IncrementType, string> = {
  [IncrementType.AMORTIZATION_GREATER_THAN_25_YEARS]: $localize`:@@IncrementType.AMORTIZATION_GREATER_THAN_25_YEARS:Amortization is > 25 years`,
  [IncrementType.REFINANCE]: $localize`:@@IncrementType.REFINANCE:Refinance`,
  [IncrementType.SECOND_MORTGAGE]: $localize`:@@IncrementType.SECOND_MORTGAGE:Second Mortgage`,
  [IncrementType.NON_OWNER_OCCUPIED]: $localize`:@@IncrementType.NON_OWNER_OCCUPIED:Non-Owner Occupied`,
  [IncrementType.STRESS_TEST]: $localize`:@@IncrementType.STRESS_TEST:Failed a Stress Test`,
  [IncrementType.BEACON_550_TO_599]: $localize`:@@IncrementType.BEACON_550_TO_599:Beacon 550-599`,
  [IncrementType.BEACON_600_TO_679]: $localize`:@@IncrementType.BEACON_600_TO_679:Beacon 600-679`,
  [IncrementType.BEACON_640_TO_679]: $localize`:@@IncrementType.BEACON_640_TO_679:Beacon 640-679`,
  [IncrementType.BEACON_600_TO_639]: $localize`:@@IncrementType.BEACON_600_TO_639:Beacon 600-639`,
  [IncrementType.BEACON_680_TO_749]: $localize`:@@IncrementType.BEACON_680_TO_749:Beacon 680-749`,
  [IncrementType.BEACON_GREATER_THAN_EQUAL_TO_750]: $localize`:@@IncrementType.BEACON_GREATER_THAN_EQUAL_TO_750:Beacon >= 750`,
  [IncrementType.BEACON_LESS_THAN_EQUAL_TO_549]: $localize`:@@IncrementType.BEACON_LESS_THAN_EQUAL_TO_549:Beacon <= 549`,
  [IncrementType.LTI_GREATER_THAN_MAX]: $localize`:@@IncrementType.LTI_GREATER_THAN_MAX:LTI > Max`,

  // eq specific
  [IncrementType.FICO_SCORE_GREATER_THAN_EQUAL_TO_575]: $localize`:@@IncrementType.FICO_SCORE_GREATER_THAN_EQUAL_TO_575:Open Mortgage Fico >= 575`,
  [IncrementType.FICO_SCORE_LESS_THAN_575]: $localize`:@@IncrementType.FICO_SCORE_LESS_THAN_575:Open Mortgage Fico < 575`,
  [IncrementType.LTV_MORE_THAN_7_PERCENT]: $localize`:@@IncrementType.LTV_MORE_THAN_7_PERCENT:Co-Lending >7% LTV`,
  [IncrementType.LOAN_MORE_THAN_500000$]: $localize`:@@IncrementType.LOAN_MORE_THAN_500000$:Large Loans $500,000+`,
  [IncrementType.LOAN_MORE_THAN_750000$]: $localize`:@@IncrementType.LOAN_MORE_THAN_750000$:Large Loans $750,000+`,
  [IncrementType.LOAN_MORE_THAN_1000000$]: $localize`:@@IncrementType.LOAN_MORE_THAN_1000000$:Large Loans $1,000,000+`,
  [IncrementType.LOAN_MORE_THAN_2000000$]: $localize`:@@IncrementType.LOAN_MORE_THAN_2000000$:Large Loans $2,000,000+`,
  [IncrementType.PEARL_OR_AMBER_REFINANCE]: $localize`:@@IncrementType.PEARL_OR_AMBER_REFINANCE:Refinance & ETO Pearl or Amber Refinance`,
  [IncrementType.REFINANCE_WITH_ETO_LESS_THAN_75000$]: $localize`:@@IncrementType.REFINANCE_WITH_ETO_LESS_THAN_75000$:Refinance & ETO Refinance With ETO < $75000`,
  [IncrementType.LTV_MORE_THAN_75_EXCLUDED_CAPPED_FEES]: $localize`:@@IncrementType.LTV_MORE_THAN_75_EXCLUDED_CAPPED_FEES:Refinance & ETO LTV > 75% (excludes capped fees)`,
  [IncrementType.WITHIN_PAST_6_TO_12_MONTHS]: $localize`:@@IncrementType.WITHIN_PAST_6_TO_12_MONTHS:Bankruptcy/Consumer Proposal Within Past 6 - 12 Months`,
  [IncrementType.PROPERTIES_OWNED_GREATER_THAN_OR_EQUAL_TO_5]: $localize`:@@IncrementType.PROPERTIES_OWNED_GREATER_THAN_OR_EQUAL_TO_5:Rental Portfolios Properties Owned >= 5`,
  [IncrementType.EXCEEDS_65_PERCENT]: $localize`:@@IncrementType.EXCEEDS_65_PERCENT:Total Worth GDS/TDS Exceeds 65%`,
  [IncrementType.ANY_CLASSIFICATION]: $localize`:@@IncrementType.ANY_CLASSIFICATION:Non Conforming Loans Any Classification`,
  [IncrementType.NON_OWNER_OCCUPIED_ATLANTIC_TIER_1]: $localize`:@@IncrementType.NON_OWNER_OCCUPIED_ATLANTIC_TIER_1:Non-Owner Occupied Properties Atlantics Tier 1`,
  [IncrementType.NON_OWNER_OCCUPIED_ATLANTIC_TIER_2_AND_3]: $localize`:@@IncrementType.NON_OWNER_OCCUPIED_ATLANTIC_TIER_2_AND_3:Non-Owner Occupied Properties Atlantics Tier 2 & 3`,
  [IncrementType[
    'RATIO_3.5_TO_4.49_TIMES'
  ]]: $localize`:@@IncrementType.RATIO_3.5_TO_4.49_TIMES:LTI = 3.50 - 4.49 x`,
  [IncrementType[
    'RATIO_4.5_PLUS_TIMES'
  ]]: $localize`:@@IncrementType.RATIO_4.5_PLUS_TIMES:LTI = 4.5+ x`,

  [IncrementType.RMC_ALTERNATIVES_ACTIVE_CONSUMER_PROPOSAL_OR_BANKRUPTCY]: $localize`:@@IncrementType.RMC_ALTERNATIVES_ACTIVE_CONSUMER_PROPOSAL_OR_BANKRUPTCY:Alternative Active Consumer Proposal/Bankruptcy`,
  [IncrementType.RMC_ALTERNATIVES_GREATER_THAN_2_MILLION]: $localize`:@@IncrementType.RMC_ALTERNATIVES_GREATER_THAN_2_MILLION:Alternative >$2M`,
  [IncrementType.RMC_ALTERNATIVES_REFINANCE_GREATER_THAN_75_PERCENT]: $localize`:@@IncrementType.RMC_ALTERNATIVES_REFINANCE_GREATER_THAN_75_PERCENT:Alternative Refinance >75%`,
  [IncrementType.RMC_ALTERNATIVES_RENTALS]: $localize`:@@IncrementType.RMC_ALTERNATIVES_RENTALS:Alternative Rentals`,
  [IncrementType.RMC_ALTERNATIVES_TIER_3_LOCATIONS]: $localize`:@@IncrementType.RMC_ALTERNATIVES_TIER_3_LOCATIONS:Alternative Tier 3 location`,
};

export const IncrementTypeRecordEQ: Record<IncrementType, string> = {
  ...IncrementTypeRecord,
  [IncrementType.NON_OWNER_OCCUPIED]: $localize`:@@IncrementType.EQ_NON_OWNER_OCCUPIED:Non-Owner Occupied Properties`,
};

export const IncrementIncomeStatusRecord: Record<IncrementIncomeStatus, string> = {
  [IncrementIncomeStatus.QUALIFYING_INCOME]: $localize`:@@IncrementIncomeStatus.QUALIFYING_INCOME:Income Qualifying`,
  [IncrementIncomeStatus.NON_QUALIFYING_INCOME]: $localize`:@@IncrementIncomeStatus.NON_QUALIFYING_INCOME:Non-Income Qualifying`,
};

export const IncrementMortgageTermRecord: Record<IncrementMortgageTerm, string> = {
  [IncrementMortgageTerm.SIX_MONTHS]: $localize`:@@IncrementMortgageTerm.SIX_MONTHS:Term is 6 months`,
  [IncrementMortgageTerm.ONE_YEAR]: $localize`:@@IncrementMortgageTerm.ONE_YEAR:Term is 1 year`,
  [IncrementMortgageTerm.TWO_YEAR]: $localize`:@@IncrementMortgageTerm.TWO_YEAR:Term is 2 years`,
  [IncrementMortgageTerm.THREE_YEAR]: $localize`:@@IncrementMortgageTerm.THREE_YEAR:Term is 3 years`,
  [IncrementMortgageTerm.FOUR_YEAR]: $localize`:@@IncrementMortgageTerm.FOUR_YEAR:Term is 4 years`,
  [IncrementMortgageTerm.FIVE_YEAR]: $localize`:@@IncrementMortgageTerm.FIVE_YEAR:Term is 5 years`,
  [IncrementMortgageTerm.SEVEN_YEAR]: $localize`:@@IncrementMortgageTerm.SEVEN_YEAR:Term is 7 years`,
  [IncrementMortgageTerm.TEN_YEAR]: $localize`:@@IncrementMortgageTerm.TEN_YEAR:Term is 10 years`,
};

export enum IncrementTypeLocalizationOptions {
  EQ = 'EQ',
  DEFAULT = 'DEFAULT',
}
