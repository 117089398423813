import { DownPayment } from './downPayment';

export interface Flip {
  id: string;
  applicationId: string;
  analysisResult: FlipAnalysisModel;
  renovationBudget: number;
  interestCostByMonth: number;
  interestCostByDay: number;
  financingFeePercentage: number;
  financingFeeAmount: number;
  afterRepairValue: number;
  FlipCosts: FlipCost[];
  FlipRenovationSchedules: FlipRenovationSchedule[];
}

export interface FlipCost {
  id: string;
  flipId: string;
  type: string;
  paymentType: string;
  item: string;
  itemType: FlipDefaultsCostItemType;
  amount: number;
}

export interface FlipCostOperating extends FlipCost {
  projectTotal?: number;
  percentageOfTotal?: number;
}

export interface FlipRenovationSchedule {
  id: string;
  flipId: string;
  item: string;
  numberOfMonths: number;
}

export enum FlipType {
  PROFIT_FOCUSED = 'profitFocused',
  SALES_PRICE_FOCUSED = 'salesPriceFocused',
}

export enum FlipCostType {
  PURCHASE_COST = 'purchaseCost',
  SELLING_COST = 'sellingCost',
  OPERATING_COST = 'operatingCost',
}

export enum FlipPaymentType {
  ONE_TIME = 'oneTime',
  MONTHLY = 'monthly',
}

export enum FlipAmountType {
  CONSTANT = 'constant',
  COMPUTED = 'computed',
}

export enum FlipDefaultCostItems {
  REAL_ESTATE_AGENT_FEE = 'Real Estate Agent Fee',
  LEGAL_COST = 'Legal Cost',
  INSURANCE = 'Insurance',
  TITLE_INSURANCE_RPR = 'R.P.R./Title insurance',
  TITLE_INSURANCE = 'Title Insurance',
  TITLE_SEARCH = 'Title Search',
  APPRAISAL_FEE = 'Appraisal Fee',
  APPRAISAL_FEE_SECOND = '2nd Appraisal',
  INSPECTION_FEE = 'Inspection Fee',
  PROPERTY_TAX_ADJUSTMENT = 'Property Tax Adjustment',
  LAND_TRANSFER_TAX = 'Land Transfer Tax',
  MUNICIPAL_LAND_TRANSFER_TAX = 'Municipal Land Transfer Tax',
  ELECTRICAL = 'Electrical',
  GAS = 'Gas',
  WATER_SEWER = 'Water/ Sewer',
  ALARM_SYSTEM = 'Alarm System',
  LAWN_CARE_SNOW_REMOVAL = 'Lawn Care/Snow Removal',
  PROPERTY_TAXES = 'Property Taxes',
  CONDO_FEES = 'Condo Fees',
}

export enum FlipDefaultsCostItemType {
  REAL_ESTATE_AGENT_FEE = 'realEstateAgentFee',
  REAL_ESTATE_AGENT_FEE_ONTARIO = 'realEstateAgentFeeOntario',
  REAL_ESTATE_AGENT_FEE_ALBERTA = 'realEstateAgentFeeAlberta',
  LEGAL_COST = 'legalCost',
  INSURANCE = 'insurance',
  TITLE_INSURANCE_RPR = 'titleInsuranceRpr',
  TITLE_INSURANCE = 'titleInsurance',
  TITLE_SEARCH = 'titleSearch',
  APPRAISAL_FEE = 'appraisalFee',
  APPRAISAL_FEE_SECOND = 'appraisalFeeSecond',
  INSPECTION_FEE = 'inspectionFee',
  PROPERTY_TAX_ADJUSTMENT = 'propertyTaxAdjustment',
  LAND_TRANSFER_TAX = 'landTransferTax',
  LAND_TRANSFER_TAX_ONTARIO = 'landTransferTaxOntario',
  MUNICIPAL_LAND_TRANSFER_TAX = 'municipalLandTransferTax',
  ELECTRICAL = 'electrical',
  GAS = 'gas',
  WATER_SEWER = 'waterSewer',
  ALARM_SYSTEM = 'alarmSystem',
  LAWN_CARE_SNOW_REMOVAL = 'lawnCareSnowRemoval',
  PROPERTY_TAXES = 'propertyTaxes',
  CONDO_FEES = 'condoFees',
  OTHER = 'other',
}

export enum FlipAnalysisResultType {
  NORMAL = 'NORMAL',
  ONE_ADDITIONAL = 'ONE_ADDITIONAL',
  ONE_LESS = 'ONE_LESS',
}

export enum FlipAnalysisItem {
  PROFIT = 'Profit',
  TOTAL_OPERATING_COSTS = 'Total Operating Costs',
  TOTAL_FINANCING_COSTS = 'Total Financing Costs',
  TOTAL_COSTS = 'Total Costs',
  TOTAL_CASH_NEEDED = 'Total Cash Needed',
  ROI = 'ROI',
  ROI_ANNUALLY = 'ROI (annualy)',
  ROC_TO_ARV = 'Ratio of Costs to ARV',
  VARIABLE_MONTHLY_COSTS = 'Variable Monthly Costs',
  MAXIMUM_PURCHASED_PRICE = 'Maximum Purchase Price',
}

export enum FlipCalculationItem {
  ARV = 'After Repaired Value',
  PURCHASE_PRICE = 'Purchase Price',
  DESIRED_PROFIT = 'Desired Profit',
  OPERATING_COST = 'Monthly Operating Cost',
  TOTAL_MONTH = 'Total Month',
  INTEREST_COST_MONTH = 'Interest Costs (month)',
  FINANCING_FEES = 'Financing Fees',
  TOTAL_PURCHASE_COSTS = 'Total Purchase Costs',
  TOTAL_SELLING_COSTS = 'Total Selling Costs',
  RENOVATION_COSTS = 'Renovation Costs',
  DOWN_PAYMENT_TOTAL = 'Down Payment Total',
}

export interface FlipAnalysisResult {
  type: FlipAnalysisResultType;
  key: string;
  value: number;
  inputs: { [key: string]: string | number }[];
}

export enum FlipPropertyProvince {
  ALBERTA = 'alberta',
  ONTARIO = 'ontario',
}

export interface FlipAnalysisModel {
  type: FlipType;
  propertyProvince?: string;
  renovationScheduleTotalMonths: number;
  totalOperatingCost: number;
  totalSellingCost: number;
  totalPurchaseCost: number;
  interestCostByMonth: number;
  interestCostByDay: number;
  financingFeeAmount: number;
  renovationBudget: number;
  afterRepairValue: number;
  propertyPurchasePrice: number;
  desiredProfit: number;
  netRate: number | null;
  loanAmount: number;
  downPayments: DownPayment[];
  downPaymentsTotal: number;
  analysis: {
    operatingCost: FlipAnalysisResult[];
    financingCost: FlipAnalysisResult[];
    totalCost: FlipAnalysisResult[];
    totalCashNeeded: FlipAnalysisResult[];
    profit: FlipAnalysisResult[];
    maximumPurchasePrice: FlipAnalysisResult[];
    returnOnInvestment: FlipAnalysisResult[];
    returnOnInvestmentAnnually: FlipAnalysisResult[];
    ratioCostsToARV: FlipAnalysisResult[];
    variableMonthlyCost: FlipAnalysisResult[];
  };
}
