import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MortgageApplicationService } from '../../portal/mortgage-application.service';
import {
  ApplicationStage,
  GeneralAbstractPermissions,
  PipelineApplication,
} from '@fundmoreai/models';

@Injectable({
  providedIn: 'root',
})
export class PipelineService {
  constructor(
    private http: HttpClient,
    private mortgageApplicationService: MortgageApplicationService,
  ) {}

  getApplications(
    approvalRequested: boolean,
    archived: boolean,
    permissions: GeneralAbstractPermissions | undefined,
    isServicingEnabled: boolean | undefined,
  ): Observable<PipelineApplication[]> {
    let headers = new HttpHeaders();
    headers = headers.set('x-compression', 'true');

    let endpoint: string;

    if (isServicingEnabled) {
      endpoint = approvalRequested
        ? `${environment.fundmore_v2_api_url}/application/servicing/approval-requested?archived=${archived}`
        : `${environment.fundmore_v2_api_url}/application/servicing?archived=${archived}`;
    } else {
      endpoint = approvalRequested
        ? `${environment.fundmore_api_url}/applications/approvalRequested?archived=${archived}`
        : `${environment.api_url}/applications?archived=${archived}`;
    }

    return this.http.get<PipelineApplication[]>(endpoint, { headers }).pipe(
      map((applications) => {
        return this.mapApplication(
          applications,
          permissions,
          approvalRequested,
          isServicingEnabled,
        );
      }),
    );
  }

  getUnassignedDealsCount(isServicingEnabled: boolean | undefined) {
    return this.http.get<number>(
      isServicingEnabled
        ? `${environment.fundmore_v2_api_url}/application/servicing/unassigned-deals-count`
        : `${environment.fundmore_v2_api_url}/application/unassigned-deals-count`,
    );
  }

  getUnassignedDeals(
    permissions: GeneralAbstractPermissions | undefined,
    isServicingEnabled: boolean | undefined,
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('x-compression', 'true');

    return this.http
      .get<PipelineApplication[]>(
        isServicingEnabled
          ? `${environment.fundmore_v2_api_url}/application/servicing/unassigned-deals`
          : `${environment.fundmore_v2_api_url}/application/unassigned-deals`,
        { headers },
      )
      .pipe(
        map((applications) => {
          return this.mapApplication(applications, permissions, undefined, isServicingEnabled);
        }),
      );
  }

  getTeamApplications(
    team: string[],
    permissions: GeneralAbstractPermissions | undefined,
    isServicingEnabled: boolean | undefined,
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('x-compression', 'true');

    return this.http
      .get<PipelineApplication[]>(
        isServicingEnabled
          ? `${environment.fundmore_api_url}/application/servicing/team?team=${JSON.stringify(
              team,
            )}`
          : `${environment.fundmore_api_url}/applications/team?team=${JSON.stringify(team)}`,
        { headers },
      )
      .pipe(
        map((applications) => {
          return this.mapApplication(applications, permissions, undefined, isServicingEnabled);
        }),
      );
  }

  private mapApplication(
    applicationInput: PipelineApplication[],
    permissions: GeneralAbstractPermissions | undefined,
    approvalRequested = false,
    isServicingEnabled: boolean | undefined,
  ) {
    // TODO: add logic for assigned on
    const applications = applicationInput;

    const userHierarchyIds = permissions?.applicationList.hierarchyUserIds;
    applications.forEach((application) => {
      const canEdit =
        permissions?.applicationList.canEdit ||
        (permissions?.applicationList.canEditOwn &&
          userHierarchyIds?.includes(application.createdBy?.id ?? '')) ||
        (permissions?.applicationList.canEditAssigned &&
          (application.applicationAssignedUser ?? []).some(
            (assignedUser) =>
              assignedUser.userId && userHierarchyIds?.includes(assignedUser.userId),
          )) ||
        (permissions?.applicationList.canEditInNewStage &&
          application.currentStage === ApplicationStage.NEW_APPLICATION) ||
        (permissions?.applicationList.canEditInClosedStage &&
          (application.currentStage === ApplicationStage.FUNDED ||
            application.currentStage === ApplicationStage.DECLINED));

      application.canEdit = canEdit;
      application.primaryApplicantName =
        this.mortgageApplicationService.getPrimaryApplicantName(application);
      application.primaryApplicantCustomerType =
        this.mortgageApplicationService.getPrimaryApplicantCustomerType(application);
      application.otherApplicants = this.mortgageApplicationService.getOtherApplicants(application);
      application.approval = approvalRequested;
    });

    return applications;
  }
}
