import {
  FlipAnalysisModel,
  FlipAnalysisResult,
  FlipAnalysisResultType,
  DownPayment,
  FlipType,
} from '@fundmoreai/models';

import {
  buildOperatingCostAnalysisResult,
  buildFinancingCostAnalysisResult,
  buildTotalFlipCostAnalysisResult,
  buildTotalCashNeededAnalysisResult,
  buildProfitAnalysisResult,
  buildROIAnalysisResult,
  buildROIAnnuallyAnalysisResult,
  buildRatioCostToARVAnalysisResult,
  buildVariableMonthlyCostAnalysisResult,
  buildMaximumPurchasePriceAnalysis,
} from './flip-analysis-service';

import { computeMonthlyInterestCost } from './flip-general.calculator';

export class FlipAnalysisCalculator {
  renovationScheduleTotalMonths: number = 0;
  totalOperatingCost: number = 0;
  totalSellingCost: number = 0;
  totalPurchaseCost: number = 0;
  interestCostByMonth: number = 0;
  interestCostByDay: number = 0;
  financingFeeAmount: number = 0;
  renovationBudget: number = 0;
  afterRepairValue: number = 0;

  downPayments: DownPayment[] = [];
  downPaymentsTotal: number = 0;
  propertyPurchasePrice: number = 0;
  propertyProvince: string | undefined;
  desiredProfit: number = 0;
  netRate: number = 0;
  loanAmount: number = 0;

  oneAdditionalMonth: number = 0;
  oneLessMonth: number = 0;

  operatingCostAnalysis: FlipAnalysisResult[] = [];
  financingCostAnalysis: FlipAnalysisResult[] = [];
  totalCostAnalysis: FlipAnalysisResult[] = [];
  totalCashNeededAnalysis: FlipAnalysisResult[] = [];
  profitAnalysis: FlipAnalysisResult[] = [];
  maximumPurchasePriceAnalysis: FlipAnalysisResult[] = [];
  returnOnInvestmentAnalysis: FlipAnalysisResult[] = [];
  returnOnInvestmentAnalysisAnnually: FlipAnalysisResult[] = [];
  ratioCostsToARVAnalysis: FlipAnalysisResult[] = [];
  variableMonthlyCostAnalysis: FlipAnalysisResult[] = [];

  type: FlipType;

  provinceRelatedMonthlyInterestCost: number = 0;

  constructor(analysisModel?: Partial<FlipAnalysisModel>) {
    if (!analysisModel) {
      return;
    }
    this.type = analysisModel.type ?? FlipType.SALES_PRICE_FOCUSED;
    this.renovationScheduleTotalMonths = analysisModel.renovationScheduleTotalMonths ?? 0;

    this.oneAdditionalMonth = this.renovationScheduleTotalMonths + 1;
    this.oneLessMonth = this.renovationScheduleTotalMonths - 1;

    this.totalOperatingCost = analysisModel.totalOperatingCost ?? 0;
    this.totalPurchaseCost = analysisModel.totalPurchaseCost ?? 0;
    this.totalSellingCost = analysisModel.totalSellingCost ?? 0;
    this.interestCostByMonth = analysisModel.interestCostByMonth ?? 0;
    this.interestCostByDay = analysisModel.interestCostByDay ?? 0;
    this.financingFeeAmount = analysisModel.financingFeeAmount ?? 0;
    this.renovationBudget = analysisModel.renovationBudget ?? 0;
    this.afterRepairValue = analysisModel.afterRepairValue ?? 0;
    this.propertyPurchasePrice = analysisModel.propertyPurchasePrice ?? 0;
    this.propertyProvince = analysisModel.propertyProvince;
    this.desiredProfit = analysisModel.desiredProfit ?? 0;
    this.netRate = analysisModel.netRate ?? 0;
    this.loanAmount = analysisModel.loanAmount ?? 0;
    this.downPayments = analysisModel.downPayments ?? [];
    this.downPaymentsTotal = analysisModel.downPaymentsTotal ?? 0;

    this.provinceRelatedMonthlyInterestCost = this.interestCostByMonth;

    if (!analysisModel.analysis) {
      return;
    }

    this.operatingCostAnalysis = analysisModel.analysis.operatingCost;
    this.financingCostAnalysis = analysisModel.analysis.financingCost;
    this.totalCostAnalysis = analysisModel.analysis.totalCost;
    this.totalCashNeededAnalysis = analysisModel.analysis.totalCashNeeded;
    this.profitAnalysis = analysisModel.analysis.profit;
    this.maximumPurchasePriceAnalysis = analysisModel.analysis.maximumPurchasePrice;
    this.returnOnInvestmentAnalysis = analysisModel.analysis.returnOnInvestment;
    this.returnOnInvestmentAnalysisAnnually = analysisModel.analysis.returnOnInvestmentAnnually;
    this.ratioCostsToARVAnalysis = analysisModel.analysis.ratioCostsToARV;
    this.variableMonthlyCostAnalysis = analysisModel.analysis.variableMonthlyCost;
  }

  getModel(): FlipAnalysisModel {
    const model: FlipAnalysisModel = {
      type: this.type,
      totalOperatingCost: this.totalOperatingCost,
      totalSellingCost: this.totalSellingCost,
      totalPurchaseCost: this.totalPurchaseCost,
      renovationScheduleTotalMonths: this.renovationScheduleTotalMonths,
      interestCostByMonth: this.interestCostByMonth,
      interestCostByDay: this.interestCostByDay,
      financingFeeAmount: this.financingFeeAmount,
      renovationBudget: this.renovationBudget,
      afterRepairValue: this.afterRepairValue,
      propertyPurchasePrice: this.propertyPurchasePrice,
      propertyProvince: this.propertyProvince,
      desiredProfit: this.desiredProfit,
      netRate: this.netRate,
      loanAmount: this.loanAmount,
      downPayments: this.downPayments,
      downPaymentsTotal: this.downPaymentsTotal,
      analysis: {
        profit: this.profitAnalysis,
        maximumPurchasePrice: this.maximumPurchasePriceAnalysis,
        operatingCost: this.operatingCostAnalysis,
        financingCost: this.financingCostAnalysis,
        totalCost: this.totalCostAnalysis,
        totalCashNeeded: this.totalCashNeededAnalysis,
        returnOnInvestment: this.returnOnInvestmentAnalysis,
        returnOnInvestmentAnnually: this.returnOnInvestmentAnalysisAnnually,
        ratioCostsToARV: this.ratioCostsToARVAnalysis,
        variableMonthlyCost: this.variableMonthlyCostAnalysis,
      },
    };
    return model;
  }

  private analyseOperatingCost() {
    const analysis: FlipAnalysisResult[] = [];
    const normalAnalysis = buildOperatingCostAnalysisResult(
      FlipAnalysisResultType.NORMAL,
      this.renovationScheduleTotalMonths,
      this.totalOperatingCost,
    );
    const oneAdditionMonthAnalysis = buildOperatingCostAnalysisResult(
      FlipAnalysisResultType.ONE_ADDITIONAL,
      this.oneAdditionalMonth,
      this.totalOperatingCost,
    );
    analysis.push(normalAnalysis, oneAdditionMonthAnalysis);
    if (this.oneLessMonth > 0) {
      const oneLessMonthAnalysis = buildOperatingCostAnalysisResult(
        FlipAnalysisResultType.ONE_LESS,
        this.oneLessMonth,
        this.totalOperatingCost,
      );
      analysis.push(oneLessMonthAnalysis);
    }
    this.operatingCostAnalysis = analysis;
  }

  private analyseFinancingCost() {
    const analysis: FlipAnalysisResult[] = [];

    const normalAnalysis = buildFinancingCostAnalysisResult(
      FlipAnalysisResultType.NORMAL,
      this.renovationScheduleTotalMonths,
      this.provinceRelatedMonthlyInterestCost,
      this.financingFeeAmount,
    );

    const oneAdditionMonthAnalysis = buildFinancingCostAnalysisResult(
      FlipAnalysisResultType.ONE_ADDITIONAL,
      this.oneAdditionalMonth,
      this.interestCostByMonth,
      this.financingFeeAmount,
    );
    analysis.push(normalAnalysis, oneAdditionMonthAnalysis);

    if (this.oneLessMonth > 0) {
      const oneLessMonthAnalysis = buildFinancingCostAnalysisResult(
        FlipAnalysisResultType.ONE_LESS,
        this.oneLessMonth,
        this.interestCostByMonth,
        this.financingFeeAmount,
      );
      analysis.push(oneLessMonthAnalysis);
    }
    this.financingCostAnalysis = analysis;
  }

  private getAnalysisResultValues(costAnalysis: FlipAnalysisResult[]) {
    const normalCostAnalysis = costAnalysis.find(
      (analyseCost) => analyseCost.type === FlipAnalysisResultType.NORMAL,
    );
    const normalCostValue = normalCostAnalysis?.value ?? 0;

    const oneAdditionalMonthCostAnalysis = costAnalysis.find(
      (analyseCost) => analyseCost.type === FlipAnalysisResultType.ONE_ADDITIONAL,
    );
    const oneAdditionalMonthCostValue = oneAdditionalMonthCostAnalysis?.value ?? 0;

    const oneLessMonthCostAnalysis = costAnalysis.find(
      (analyseCost) => analyseCost.type === FlipAnalysisResultType.ONE_LESS,
    );
    const oneLessMonthCostValue = oneLessMonthCostAnalysis?.value ?? 0;
    return {
      normalCostValue,
      oneAdditionalMonthCostValue,
      oneLessMonthCostValue,
    };
  }

  private analyseTotalFlipCost() {
    const analysis: FlipAnalysisResult[] = [];

    const operatingCostAnalysisValues = this.getAnalysisResultValues(this.operatingCostAnalysis);
    const financingCostAnalysisValues = this.getAnalysisResultValues(this.financingCostAnalysis);
    const normalAnalysis = buildTotalFlipCostAnalysisResult(
      FlipAnalysisResultType.NORMAL,
      operatingCostAnalysisValues.normalCostValue,
      financingCostAnalysisValues.normalCostValue,
      this.totalPurchaseCost,
      this.totalSellingCost,
      this.renovationBudget,
    );

    const oneAdditionMonthAnalysis = buildTotalFlipCostAnalysisResult(
      FlipAnalysisResultType.ONE_ADDITIONAL,
      operatingCostAnalysisValues.oneAdditionalMonthCostValue,
      financingCostAnalysisValues.oneAdditionalMonthCostValue,
      this.totalPurchaseCost,
      this.totalSellingCost,
      this.renovationBudget,
    );
    analysis.push(normalAnalysis, oneAdditionMonthAnalysis);
    if (this.oneLessMonth > 0) {
      const oneLessMonthAnalysis = buildTotalFlipCostAnalysisResult(
        FlipAnalysisResultType.ONE_LESS,
        operatingCostAnalysisValues.oneLessMonthCostValue,
        financingCostAnalysisValues.oneLessMonthCostValue,
        this.totalPurchaseCost,
        this.totalSellingCost,
        this.renovationBudget,
      );
      analysis.push(oneLessMonthAnalysis);
    }
    this.totalCostAnalysis = analysis;
  }

  private analyseTotalCashNeeded() {
    const analysis: FlipAnalysisResult[] = [];
    const operatingCostAnalysisValues = this.getAnalysisResultValues(this.operatingCostAnalysis);
    const normalAnalysis = buildTotalCashNeededAnalysisResult(
      FlipAnalysisResultType.NORMAL,
      operatingCostAnalysisValues.normalCostValue,
      this.renovationScheduleTotalMonths,
      this.totalPurchaseCost,
      this.provinceRelatedMonthlyInterestCost,
      this.renovationBudget,
      this.downPaymentsTotal,
    );

    const oneAdditionMonthAnalysis = buildTotalCashNeededAnalysisResult(
      FlipAnalysisResultType.ONE_ADDITIONAL,
      operatingCostAnalysisValues.oneAdditionalMonthCostValue,
      this.oneAdditionalMonth,
      this.totalPurchaseCost,
      this.interestCostByMonth,
      this.renovationBudget,
    );
    analysis.push(normalAnalysis, oneAdditionMonthAnalysis);

    if (this.oneLessMonth > 0) {
      const oneLessMonthAnalysis = buildTotalCashNeededAnalysisResult(
        FlipAnalysisResultType.ONE_LESS,
        operatingCostAnalysisValues.oneAdditionalMonthCostValue,
        this.oneLessMonth,
        this.totalPurchaseCost,
        this.interestCostByMonth,
        this.renovationBudget,
      );
      analysis.push(oneLessMonthAnalysis);
    }
    this.totalCashNeededAnalysis = analysis;
  }

  private analyseProfit() {
    const analysis: FlipAnalysisResult[] = [];
    const totalCostAnalysisValues = this.getAnalysisResultValues(this.totalCostAnalysis);

    const normalAnalysis = buildProfitAnalysisResult(
      FlipAnalysisResultType.NORMAL,
      totalCostAnalysisValues.normalCostValue,
      this.propertyPurchasePrice,
      this.afterRepairValue,
    );

    const oneAdditionalMonthAnalysis = buildProfitAnalysisResult(
      FlipAnalysisResultType.ONE_ADDITIONAL,
      totalCostAnalysisValues.oneAdditionalMonthCostValue,
      this.propertyPurchasePrice,
      this.afterRepairValue,
    );

    analysis.push(normalAnalysis, oneAdditionalMonthAnalysis);
    if (this.oneLessMonth > 0) {
      const oneLessMonthAnalysis = buildProfitAnalysisResult(
        FlipAnalysisResultType.ONE_LESS,
        totalCostAnalysisValues.oneLessMonthCostValue,
        this.propertyPurchasePrice,
        this.afterRepairValue,
      );
      analysis.push(oneLessMonthAnalysis);
    }
    this.profitAnalysis = analysis;
  }

  private analyseReturnOnInvestment() {
    const analysis: FlipAnalysisResult[] = [];
    const profitAnalysisValues = this.getAnalysisResultValues(this.profitAnalysis);
    const totalCashNeededAnalysisValues = this.getAnalysisResultValues(
      this.totalCashNeededAnalysis,
    );
    let normalAnalysisProfit = this.desiredProfit;
    let oneAdditionalMonthAnalysisProfit = this.desiredProfit;
    let oneLessMonthAnalysisProfit = this.desiredProfit;
    if (FlipType.SALES_PRICE_FOCUSED === this.type) {
      normalAnalysisProfit = profitAnalysisValues.normalCostValue;
      oneAdditionalMonthAnalysisProfit = profitAnalysisValues.oneAdditionalMonthCostValue;
      oneLessMonthAnalysisProfit = profitAnalysisValues.oneLessMonthCostValue;
    }

    const normalAnalysis = buildROIAnalysisResult(
      FlipAnalysisResultType.NORMAL,
      normalAnalysisProfit,
      totalCashNeededAnalysisValues.normalCostValue,
    );

    const oneAdditionalMonthAnalysis = buildROIAnalysisResult(
      FlipAnalysisResultType.ONE_ADDITIONAL,
      oneAdditionalMonthAnalysisProfit,
      totalCashNeededAnalysisValues.oneAdditionalMonthCostValue,
    );
    analysis.push(normalAnalysis, oneAdditionalMonthAnalysis);
    if (this.oneLessMonth > 0) {
      const oneLessMonthAnalysis = buildROIAnalysisResult(
        FlipAnalysisResultType.ONE_LESS,
        oneLessMonthAnalysisProfit,
        totalCashNeededAnalysisValues.oneLessMonthCostValue,
      );
      analysis.push(oneLessMonthAnalysis);
    }
    this.returnOnInvestmentAnalysis = analysis;
  }

  private analyseReturnOnInvestmentAnnually() {
    const analysis: FlipAnalysisResult[] = [];
    const profitAnalysisValues = this.getAnalysisResultValues(this.profitAnalysis);
    const totalCashNeededAnalysisValues = this.getAnalysisResultValues(
      this.totalCashNeededAnalysis,
    );
    let normalAnalysisProfit = this.desiredProfit;
    let oneAdditionalMonthAnalysisProfit = this.desiredProfit;
    let oneLessMonthAnalysisProfit = this.desiredProfit;
    if (FlipType.SALES_PRICE_FOCUSED === this.type) {
      normalAnalysisProfit = profitAnalysisValues.normalCostValue;
      oneAdditionalMonthAnalysisProfit = profitAnalysisValues.oneAdditionalMonthCostValue;
      oneLessMonthAnalysisProfit = profitAnalysisValues.oneLessMonthCostValue;
    }
    const normalAnalysis = buildROIAnnuallyAnalysisResult(
      FlipAnalysisResultType.NORMAL,
      normalAnalysisProfit,
      totalCashNeededAnalysisValues.normalCostValue,
      this.renovationScheduleTotalMonths,
    );

    const oneAdditionalMonthAnalysis = buildROIAnnuallyAnalysisResult(
      FlipAnalysisResultType.ONE_ADDITIONAL,
      oneAdditionalMonthAnalysisProfit,
      totalCashNeededAnalysisValues.oneAdditionalMonthCostValue,
      this.oneAdditionalMonth,
    );
    analysis.push(normalAnalysis, oneAdditionalMonthAnalysis);
    if (this.oneLessMonth > 0) {
      const oneLessMonthAnalysis = buildROIAnnuallyAnalysisResult(
        FlipAnalysisResultType.ONE_LESS,
        oneLessMonthAnalysisProfit,
        totalCashNeededAnalysisValues.oneLessMonthCostValue,
        this.oneLessMonth,
      );
      analysis.push(oneLessMonthAnalysis);
    }
    this.returnOnInvestmentAnalysisAnnually = analysis;
  }

  private analyseRatioCostToARV() {
    const analysis: FlipAnalysisResult[] = [];
    const totalCostAnalysisValues = this.getAnalysisResultValues(this.totalCostAnalysis);

    const normalAnalysis = buildRatioCostToARVAnalysisResult(
      FlipAnalysisResultType.NORMAL,
      totalCostAnalysisValues.normalCostValue,
      this.afterRepairValue,
    );

    const oneAdditionalMonthAnalysis = buildRatioCostToARVAnalysisResult(
      FlipAnalysisResultType.ONE_ADDITIONAL,
      totalCostAnalysisValues.oneAdditionalMonthCostValue,
      this.afterRepairValue,
    );
    analysis.push(normalAnalysis, oneAdditionalMonthAnalysis);

    if (this.oneLessMonth > 0) {
      const oneLessMonthAnalysis = buildRatioCostToARVAnalysisResult(
        FlipAnalysisResultType.ONE_LESS,
        totalCostAnalysisValues.oneLessMonthCostValue,
        this.afterRepairValue,
      );
      analysis.push(oneLessMonthAnalysis);
    }
    this.ratioCostsToARVAnalysis = analysis;
  }

  private analyseVariableMonthlyCost() {
    const analysis: FlipAnalysisResult[] = [];

    const normalAnalysis = buildVariableMonthlyCostAnalysisResult(
      FlipAnalysisResultType.NORMAL,
      this.totalOperatingCost,
      this.interestCostByMonth,
    );

    const oneAdditionalMonthAnalysis = buildVariableMonthlyCostAnalysisResult(
      FlipAnalysisResultType.ONE_ADDITIONAL,
      this.totalOperatingCost,
      this.interestCostByMonth,
    );
    analysis.push(normalAnalysis, oneAdditionalMonthAnalysis);
    if (this.oneLessMonth > 0) {
      const oneLessMonthAnalysis = buildVariableMonthlyCostAnalysisResult(
        FlipAnalysisResultType.ONE_LESS,
        this.totalOperatingCost,
        this.interestCostByMonth,
      );
      analysis.push(oneLessMonthAnalysis);
    }
    this.variableMonthlyCostAnalysis = analysis;
  }

  private analyseMaximumPurchasePrice() {
    const analysis: FlipAnalysisResult[] = [];
    const totalCostAnalysis = this.getAnalysisResultValues(this.totalCostAnalysis);
    const normalAnalysis = buildMaximumPurchasePriceAnalysis(
      FlipAnalysisResultType.NORMAL,
      totalCostAnalysis.normalCostValue,
      this.desiredProfit,
      this.afterRepairValue,
    );
    const oneAdditionalMonthAnalysis = buildMaximumPurchasePriceAnalysis(
      FlipAnalysisResultType.ONE_ADDITIONAL,
      totalCostAnalysis.oneAdditionalMonthCostValue,
      this.desiredProfit,
      this.afterRepairValue,
    );
    analysis.push(normalAnalysis, oneAdditionalMonthAnalysis);
    if (this.oneLessMonth > 0) {
      const oneLessMonthAnalysis = buildMaximumPurchasePriceAnalysis(
        FlipAnalysisResultType.ONE_LESS,
        totalCostAnalysis.oneLessMonthCostValue,
        this.desiredProfit,
        this.afterRepairValue,
      );
      analysis.push(oneLessMonthAnalysis);
    }
    this.maximumPurchasePriceAnalysis = analysis;
  }

  public updateDownPayments(downPayments: DownPayment[], downPaymentsTotal?: number) {
    this.downPayments = downPayments;
    if (downPaymentsTotal !== 0 && !downPaymentsTotal) {
      return;
    }
    this.downPaymentsTotal = downPaymentsTotal;
    this.analyseTotalCashNeeded();
    this.analyseReturnOnInvestment();
    this.analyseReturnOnInvestmentAnnually();
  }

  static analyseFlip(analysisModel: Partial<FlipAnalysisModel>) {
    const calculator = new FlipAnalysisCalculator(analysisModel);
    calculator.analyseOperatingCost();
    calculator.analyseFinancingCost();
    calculator.analyseTotalFlipCost();
    calculator.analyseTotalCashNeeded();
    calculator.analyseProfit();
    calculator.analyseReturnOnInvestment();
    calculator.analyseReturnOnInvestmentAnnually();
    calculator.analyseRatioCostToARV();
    calculator.analyseVariableMonthlyCost();
    calculator.analyseMaximumPurchasePrice();
    return calculator.getModel();
  }
}
