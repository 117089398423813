import {
  CalculationItemType,
  CalculationResult,
  LoanType,
  LoanTypeRecordForCalculation,
  Mortgage,
  MortgageType,
  Property,
  PropertyType,
} from '@fundmoreai/models';
import { FundmoreCalculator } from '..';
import {
  ComputeCappedFeesInput,
  ComputeCostToCarryPropertyInput,
  ComputeTotalIncomeWithRentalOffsetPropertyInput,
} from '../fundmore.calculator';

/*
  LTI calculation (Loan to Income Ratio) is calculated as follows:
  LTI = Total Loan Amount / Total Income with Rental
  Total Loan Amount = Sum of all requested loan amounts
  Total Income with Rental = Total Income without property rental + Rental Income

  In our application, we have two scenarios:

  enableBlanket 'false' - LTI is calculated per security
    Total Loan Amount = Sum of all total loan amounts for the security
      For existing mortgages, total loan amount is considered as mortgage balance
      For requested mortgages, total loan amount is taken, with fallback to loan amount (LOC's don't have a loan amount)

  enableBlanket 'true' - LTI is calculated for all securities
    Total Loan Amount = Sum of all requested loan amounts for all securities
      Computed same as above, but for all the requested and existing mortgages on the application.
      For existing mortgages, only those that are collateralized are considered. (linked property is a security)
*/

export type MortgageForLTICalculation = Pick<
  Mortgage,
  | 'propertyId'
  | 'monthlyPayment'
  | 'totalLoanAmount'
  | 'type'
  | 'payoffPaydown'
  | 'loanType'
  | 'loanAmount'
  | 'mortgageBalance'
  | 'insuranceAmount'
  | 'includePremiumInMortgage'
  | 'transferAdjustment'
>;

export type PropertyForLTICalculation = ComputeCostToCarryPropertyInput &
  ComputeTotalIncomeWithRentalOffsetPropertyInput &
  Pick<Property, 'id' | 'type' | 'propertyAddress' | 'isCollateralized'>;

export interface ComputeLTIBySecurityInput {
  requestedMortgages: MortgageForLTICalculation[];
  existingMortgages: MortgageForLTICalculation[];
  properties: PropertyForLTICalculation[];
  totalIncomeWithoutPropertyRental: number;
  fees: ComputeCappedFeesInput[];
  capFeesMaxPercentage: number | undefined;
  isBlanket: boolean;
}

export interface TotalLoanAmountBreakdown {
  value: number;
  breakdown: {
    requestedMortgages: {
      value: number | null;
      breakdown: {
        loanAmount: number | null;
        loanType: LoanType | null;
        insuranceAmount: number | null;
      }[];
    };
    existingMortgages: {
      value: number | null;
      breakdown: {
        mortgageBalance: number | null;
      }[];
    };
  };
}

export class LTICalculator {
  private input: ComputeLTIBySecurityInput;

  constructor() {}

  public computeLTIBySecurity(input: ComputeLTIBySecurityInput): Record<string, CalculationResult> {
    const {
      requestedMortgages,
      existingMortgages,
      properties,
      totalIncomeWithoutPropertyRental,
      isBlanket,
    } = input;
    this.input = input;
    const result: { [key: string]: CalculationResult } = {};

    const primaryProperty = properties?.find((p) => p.type === PropertyType.PRIMARY);
    const otherProperties = properties?.filter((p) => p.id !== primaryProperty?.id);

    if (!primaryProperty) {
      return result;
    }

    const securities = properties.filter(
      (p) => p.isCollateralized || p.type === PropertyType.PRIMARY,
    );

    const linkedSecurities = requestedMortgages
      .concat(existingMortgages)
      .reduce((acc, mortgage) => {
        if (mortgage.propertyId && securities.some((s) => s.id === mortgage.propertyId)) {
          acc[mortgage.propertyId] = acc[mortgage.propertyId] || [];
          acc[mortgage.propertyId].push(mortgage);
        }
        return acc;
      }, {} as Record<string, MortgageForLTICalculation[]>);

    const primaryPropertyRequestedMortgages = linkedSecurities[primaryProperty.id]?.filter(
      (mortgage) => mortgage.type === MortgageType.REQUESTED,
    );

    const primaryPropertyCostToCarry = FundmoreCalculator.computeCostToCarryForTDS(
      primaryProperty,
      primaryPropertyRequestedMortgages?.reduce((acc, m) => acc + (m.monthlyPayment || 0), 0),
    );

    const totalIncomeWithRental =
      FundmoreCalculator.computeTotalMonthlyIncomeWithPropertyRentalIncome(
        totalIncomeWithoutPropertyRental,
        primaryPropertyCostToCarry,
        primaryProperty,
        otherProperties,
        existingMortgages,
      );

    if (isBlanket) {
      result[primaryProperty.id] = this.computeLTIForBlanket(
        requestedMortgages,
        existingMortgages.filter((m) => securities.some((s) => s.id === m.propertyId)),
        totalIncomeWithRental,
        primaryProperty.propertyAddress || null,
      );

      return result;
    }

    Object.entries(linkedSecurities).forEach(([propertyId, mortgages]) => {
      const property = properties.find((p) => p.id === propertyId);

      result[propertyId] = this.computeLTIForNonBlanket(
        mortgages,
        totalIncomeWithRental,
        property?.propertyAddress || null,
      );
    });

    return result;
  }

  private computeLTIForBlanket(
    requestedMortgages: MortgageForLTICalculation[],
    existingMortgages: MortgageForLTICalculation[],
    totalIncomeWithAllRental: number,
    propertyAddress: string | null,
  ): CalculationResult {
    // LTI is calculated for all securities (loan amount for all mortgages)

    const requestedMortgagesTotalLoanAmount = {
      value: requestedMortgages.reduce(
        (acc, m) => acc + this.getRequestedMortgageTotalLoanAmount(m),
        0,
      ),
      breakdown: requestedMortgages.map((m) => ({
        loanAmount: m.loanAmount,
        loanType: m.loanType,
        insuranceAmount: m.includePremiumInMortgage ? m.insuranceAmount : null,
      })),
    };
    const existingMortgagesTotalLoanAmount = {
      value: FundmoreCalculator.computeExistingMortgageAmount(existingMortgages),
      breakdown: existingMortgages.map((m) => ({
        mortgageBalance: FundmoreCalculator.computeExistingMortgageAmount([m]),
      })),
    };
    const totalLoanAmountForAllSecurities = {
      value: requestedMortgagesTotalLoanAmount.value + existingMortgagesTotalLoanAmount.value,
      breakdown: {
        requestedMortgages: requestedMortgagesTotalLoanAmount,
        existingMortgages: existingMortgagesTotalLoanAmount,
      },
    };

    return this.computeLTI(
      totalIncomeWithAllRental,
      totalLoanAmountForAllSecurities,
      propertyAddress,
    );
  }

  private computeLTIForNonBlanket(
    propertyMortgages: MortgageForLTICalculation[],
    totalIncomeWithAllRental: number,
    propertyAddress: string | null,
  ): CalculationResult {
    // LTI is calculated per security (loan amount for the security)
    const requestedMortgages = propertyMortgages.filter((m) => m.type === MortgageType.REQUESTED);
    const existingMortgages = propertyMortgages.filter((m) => m.type === MortgageType.EXISTING);

    const requestedMortgagesTotalLoanAmount = {
      value: requestedMortgages.reduce(
        (acc, m) => acc + this.getRequestedMortgageTotalLoanAmount(m),
        0,
      ),
      breakdown: requestedMortgages.map((m) => ({
        loanAmount: m.loanAmount,
        loanType: m.loanType,
        insuranceAmount: m.includePremiumInMortgage ? m.insuranceAmount : null,
      })),
    };

    const existingMortgagesTotalLoanAmount = {
      value: FundmoreCalculator.computeExistingMortgageAmount(existingMortgages),
      breakdown: existingMortgages.map((m) => ({
        mortgageBalance: FundmoreCalculator.computeExistingMortgageAmount([m]),
      })),
    };

    const totalLoanAmountForSecurity: TotalLoanAmountBreakdown = {
      value: requestedMortgagesTotalLoanAmount.value + existingMortgagesTotalLoanAmount.value,
      breakdown: {
        requestedMortgages: requestedMortgagesTotalLoanAmount,
        existingMortgages: existingMortgagesTotalLoanAmount,
      },
    };

    return this.computeLTI(totalIncomeWithAllRental, totalLoanAmountForSecurity, propertyAddress);
  }

  private computeLTI(
    totalIncomeWithRental: number,
    totalLoanAmountBreakdown: TotalLoanAmountBreakdown,
    propertyAddress: string | null,
  ): CalculationResult {
    let lti = 0;

    const {
      value: totalLoanAmount,
      breakdown: { requestedMortgages, existingMortgages },
    } = totalLoanAmountBreakdown;

    const requestedProductsAmount = requestedMortgages.value;
    const numberOfRequestedProducts = requestedMortgages.breakdown.length;
    const numberOfExistingProducts = existingMortgages.breakdown.length;

    const cappedFees = FundmoreCalculator.computeCappedFees(
      this.input.fees,
      this.input.capFeesMaxPercentage,
    );

    if (totalIncomeWithRental && totalLoanAmount) {
      lti = Math.round((totalLoanAmount / (totalIncomeWithRental * 12)) * 100) / 100;
    }

    const data: CalculationResult = {
      formula: '(Total Loan Amount / Total Income with Rental)',
      items: [
        {
          text: 'Property Address',
          value: propertyAddress || 'Unknown',
          type: CalculationItemType.TEXT,
        },
        {
          text: 'Total Loan Amount',
          value: totalLoanAmount?.toString(),
          type: CalculationItemType.CURRENCY,
          subitems: [
            {
              ...(numberOfRequestedProducts > 1 && {
                value: requestedProductsAmount?.toString(),
                type: CalculationItemType.CURRENCY,
              }),
              text: 'Requested Products',
              subitems: [
                ...requestedMortgages.breakdown.map((m) => ({
                  text: m.loanType ? LoanTypeRecordForCalculation[m.loanType] : 'Requested Product',
                  value: m.loanAmount?.toString(),
                  type: CalculationItemType.CURRENCY,
                  subitems: [
                    ...(m.insuranceAmount && m.loanType === LoanType.MORTGAGE
                      ? [
                          {
                            text: 'Insurance Premiums',
                            value: m.insuranceAmount?.toString(),
                            type: CalculationItemType.CURRENCY,
                          },
                        ]
                      : []),
                  ],
                })),
                ...(cappedFees > 0 &&
                requestedMortgages.breakdown.some((m) => m.loanType === LoanType.MORTGAGE)
                  ? [
                      {
                        text: 'Capped Fees',
                        value: cappedFees?.toString(),
                        type: CalculationItemType.CURRENCY,
                      },
                    ]
                  : []),
              ],
            },
            ...(numberOfExistingProducts > 0
              ? [
                  {
                    ...(numberOfExistingProducts > 1 && {
                      value: existingMortgages.value?.toString(),
                      type: CalculationItemType.CURRENCY,
                    }),
                    text: 'Existing Products',
                    subitems: existingMortgages.breakdown.map((m) => ({
                      text: 'Existing Mortgage',
                      value: m.mortgageBalance?.toString(),
                      type: CalculationItemType.CURRENCY,
                    })),
                  },
                ]
              : []),
          ],
        },
        {
          text: 'Total Income with Rental',
          value: totalIncomeWithRental?.toString(),
          type: CalculationItemType.CURRENCY,
        },
      ],
      result: lti,
    };
    return data;
  }

  private getRequestedMortgageTotalLoanAmount(
    mortgage: Pick<MortgageForLTICalculation, 'loanType' | 'loanAmount' | 'totalLoanAmount'>,
  ): number {
    switch (mortgage.loanType) {
      case LoanType.MORTGAGE:
      case LoanType.BRIDGE:
      case LoanType.LIEN:
        return mortgage.totalLoanAmount || 0;
      case LoanType.SECURE_LINE_OF_CREDIT:
      case LoanType.SECURE_LINE_OF_CREDIT_FLEX:
        return mortgage.loanAmount || 0;

      default:
        return 0;
    }
  }
}
