import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '@ngxs/store';
import { ChannelConstants, PubNubEvent, PubSubConfig } from './model';
import {
  AddApplicationEvent,
  AddChatEvent,
  AddUserEvent,
  LoadPersistedMessages,
  PresenceEventOnApplication,
  RefreshPubNubConfig,
} from './pubnub.state.actions';
import { EMPTY, Observable, of, switchMap } from 'rxjs';
import Pubnub, { FetchMessagesResponse, PresenceEvent, StatusEvent } from 'pubnub';
@Injectable({
  providedIn: 'root',
})
export class PubNubService {
  readonly logging = false;
  private pubnub: Pubnub | undefined;
  constructor(private http: HttpClient, private store: Store) {}

  public setup(userId: string): Observable<unknown> {
    if (!environment.pubSub.subscribeKey) {
      // avoid setup if no pubsub key
      return EMPTY;
    }

    return this.http.get<PubSubConfig>(`${environment.api_url}/propagation/pubsub/configs`).pipe(
      switchMap((pubSubConf) => {
        if (!userId) {
          throw new Error('PubNub setup failed: No user signed in.');
        }

        this.pubnub = new Pubnub({
          publishKey: environment.pubSub.publishKey,
          subscribeKey: environment.pubSub.subscribeKey,
          logVerbosity: this.logging,
          userId: userId,
          keepAlive: true,
          restore: true,
          authKey: pubSubConf.authKey,
        });

        this.pubnub.addListener({
          // status: (status: { error: object; statusCode: number }) => {
          status: (status: StatusEvent) => {
            if (this.logging) {
              console.log(status);
            }

            if (
              status.statusCode === 403 ||
              status.statusCode === 500 ||
              status.category === 'PNAccessDeniedCategory' ||
              status.category === 'PNTimeoutCategory' ||
              status.category === 'PNNetworkIssuesCategory'
            ) {
              if (this.logging) {
                console.log('Refreshing pubnub auth key');
              }
              this.store.dispatch(new RefreshPubNubConfig());
            }
          },
          message: (message: PubNubEvent) =>
            this.store.dispatch(this.generateMessageEvent(message)),
          presence: (presenceEvent: PresenceEvent) =>
            this.store.dispatch(
              new PresenceEventOnApplication(
                presenceEvent.channel,
                presenceEvent.uuid,
                presenceEvent.action,
                presenceEvent.state,
              ),
            ),
        });

        return of('pubnub setup complete');
      }),
    );
  }

  private generateMessageEvent(message: PubNubEvent) {
    if (message.channel.includes(ChannelConstants.APPLICATION)) {
      return new AddApplicationEvent(message);
    }
    if (message.channel.includes(ChannelConstants.CHAT)) {
      return new AddChatEvent(message);
    }
    if (message.channel.includes(ChannelConstants.USER)) {
      return new AddUserEvent(message);
    }
    return undefined;
  }

  public refresh(): Observable<unknown> {
    if (!environment.pubSub.subscribeKey) {
      // avoid setup if no pubsub key
      return EMPTY;
    }
    return this.http.get<PubSubConfig>(`${environment.api_url}/propagation/pubsub/configs`).pipe(
      switchMap((pubSubConf) => {
        this.pubnub?.setAuthKey(pubSubConf.authKey);

        return of('pubnub refresh complete');
      }),
    );
  }

  public destroyAll(): void {
    if (!environment.pubSub.subscribeKey) {
      // avoid setup if no pubsub key
      return;
    }
    return this.pubnub?.unsubscribeAll();
  }

  public destroy(channels: string[]): void {
    if (!environment.pubSub.subscribeKey) {
      // avoid setup if no pubsub key
      return;
    }
    return this.pubnub?.unsubscribe({ channels });
  }

  public subscribe(channels: string[], presence = false): void {
    if (!environment.pubSub.subscribeKey) {
      // avoid setup if no pubsub key
      return;
    }
    const params: Pubnub.SubscribeParameters = {
      channels,
      withPresence: presence,
    };
    return this.pubnub?.subscribe(params);
  }

  public fetchMessages(channel: string, count: number = 25) {
    if (!environment.pubSub.subscribeKey || !this.pubnub) {
      // avoid setup if no pubsub key
      return;
    }
    const params: Pubnub.FetchMessagesParameters = {
      channels: [channel],
      // default/max is 25 messages for multiple channels (up to 500)
      count,
      includeMessageActions: true,
    };
    this.pubnub.fetchMessages(params, (status, response: FetchMessagesResponse) => {
      if (status.error) {
        console.warn(status);
        return;
      }

      const mapMessages = response.channels[channel]
        ?.map((message) => this.generateMessageEvent(message))
        .filter(
          (event): event is AddApplicationEvent | AddChatEvent | AddUserEvent =>
            event !== undefined,
        );
      if (!mapMessages || mapMessages.length === 0) {
        return;
      }
      this.store.dispatch(new LoadPersistedMessages(mapMessages));
    });
  }

  public deleteMessages({
    channel,
    start,
    end,
  }: {
    channel: string;
    start: string | undefined;
    end: string | undefined;
  }) {
    if (!environment.pubSub.subscribeKey || !this.pubnub) {
      // avoid setup if no pubsub key
      return;
    }

    const params: Pubnub.DeleteMessagesParameters = {
      channel: channel,
      start,
      end,
    };
    this.pubnub.deleteMessages(params, (status) => {
      if (status.error) {
        console.warn(status);
        return;
      }
    });
  }

  public addMessageAction({
    channel,
    timetoken,
    action,
  }: {
    channel: string;
    timetoken: string;
    action: string;
  }) {
    if (!environment.pubSub.subscribeKey || !this.pubnub) {
      // avoid setup if no pubsub key
      return;
    }

    const params: Pubnub.AddMessageActionParameters = {
      channel,
      messageTimetoken: timetoken,
      action: {
        type: action,
        value: new Date().toISOString(),
      },
    };
    this.pubnub.addMessageAction(params, (status) => {
      if (status.error) {
        console.warn(status);
        return;
      }
    });
  }

  public presence(channel: string) {
    if (!environment.pubSub.subscribeKey || !this.pubnub) {
      // avoid setup if no pubsub key
      return;
    }
    return this.pubnub.hereNow({
      channels: [channel],
      includeUUIDs: true,
      // TODO: active tab events
      includeState: false,
    });
  }
}
