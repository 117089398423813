import { LanguagePreference } from './applicant';
import { CanadianProvinceCode } from './enums';
import { PropertyTypes } from './property';

export interface UserPreference extends OutOfOfficeDetails {
  id?: string;
  userId?: string;
  location?: CanadianProvinceCode[] | string[] | null;
  language?: LanguagePreference | string | null;
  propertyType?: PropertyTypes[] | string[] | null;
  productType?: string[] | null;
  broker?: string[] | null;
  loanAmount?: number | null;
  maxCapacity?: number | null;
  canBeAssigned?: boolean;
  financingDate?: string | null;
  unlimitedLoanAmount?: boolean;
  unlimitedCapacity?: boolean;
  directManager?: string;
  maxMortgageSecured?: number | null;
  unlimitedMortgageSecured?: boolean;
  maxNonMortgageSecured?: number | null;
  unlimitedNonMortgageSecured?: boolean;
  maxBridgeSecured?: number | null;
  unlimitedBridgeSecured?: boolean;
  maxTotalConnection?: number | null;
  unlimitedTotalConnection?: boolean;
  maxDiscretion?: number | null;
  unlimitedDiscretion?: boolean;
  notificationFilters?: NotificationFilters | null;
}

export interface NotificationFilters {
  stages?: string[] | null;
  notificationTypes?: string[] | null;
  showOnlyUnread?: boolean | null;
}

export interface OutOfOfficeDetails {
  userId?: string;
  outOfOffice?: boolean;
  dateRangeVacation?: DateRangeVacation | null;
  delegate?: string | null;
}

export interface DateRangeVacation {
  start?: string;
  end?: string;
}
