import { DownPaymentKey } from './downPayment';
import { FeeKey } from './fee';
import { JobKey } from './job';
import { PropertyOwnerKey } from './propertyOwner';
import { FinancialLiabilityKey } from './liability';
import { PropertyAppraisalKey } from './propertyAppraisal';
import { PropertyInsuranceKey } from './propertyInsurance';
import { ConditionKey } from './conditionOfApproval';
import { ConstructionKey } from './constructionMortgage';
import { PropertyKey } from './property';
import { ApplicantKey } from './applicant';
import { MortgageKey } from './mortgage';
import { ApplicationKey, ApplicationStage, ServicingApplicationPurpose } from './application';
import { FinancialAssetKey } from './asset';
import {
  APPLICATION_TYPE_OPTIONS,
  DWELLING_STYLE_OPTIONS,
  DWELLING_TYPE_OPTIONS,
  FINANCIAL_ASSET_OPTIONS,
  FOUNDATION_TYPE_OPTIONS,
  GARAGE_SIZE_OPTIONS,
  GARAGE_TYPE_OPTIONS,
  HEATING_TYPE_OPTIONS,
  LOCATION_TIER_OPTIONS,
  MORTGAGE_CLASSIFICATION_OPTIONS,
  OCCUPANCY_OPTIONS,
  APPLICATION_PURPOSE_OPTIONS,
  SEWAGE_INFO_OPTIONS,
  DOWN_PAYMENT_SOURCE_OPTIONS,
  TENURE_OPTIONS,
  WATER_TYPE_OPTIONS,
  ZONING_TYPE_OPTIONS,
  CITIZENSHIP_STATUS_OPTIONS,
  INDUSTRY_SECTOR_OPTIONS,
  EMPLOYMENT_TYPE_OPTIONS,
  OCCUPATION_TYPE_OPTIONS,
  PROPERTY_TYPE_OPTIONS,
  LOAN_TYPE_OPTIONS,
  INSURER_OPTIONS,
  COMPOUNDING_OPTIONS,
  MORTGAGE_TYPE_OPTIONS,
  RATE_TYPE_OPTIONS,
  PAYMENT_FREQUENCY_OPTIONS,
  TERM_OPTIONS,
  REPAYMENT_TYPE_OPTIONS,
  TERM_TYPE_OPTIONS,
  PREPAYMENT_TYPE_OPTIONS,
  PAID_BY_OPTIONS,
  FEE_TYPE_OPTIONS,
  PROPERTY_INSURANCE_TYPE_OPTIONS,
  APPRAISAL_TYPE_OPTIONS,
  APPRAISAL_STATUS_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
  JOB_TYPE_OPTIONS,
  MARTIAL_STATUS_OPTIONS,
  LIABILITY_TYPE_OPTIONS,
  CUSTOMER_TYPE_OPTIONS,
  SALUTATION_OPTIONS,
  GENDER_OPTIONS,
  RELATIONSHIP_TO_PRIMARY_APPLICANT_OPTIONS,
  TAXES_PAID_BY,
  PAYOFF_OPTIONS,
  CONSTRUCTION_TYPE_OPTIONS,
  INCOME_PAYMENT_FREQUENCY_OPTIONS,
  LIABILITY_SOURCE_OPTIONS,
  RENTAL_OFFSET_OPTIONS,
  POLITICALLY_EXPOSED_PERSON_OPTIONS,
  THIRD_PARTY_DETERMINATION_OPTIONS,
  FRAUD_AML_RISK_OPTIONS,
  REQUEST_NAME_SCREENING_OPTIONS,
  ACCOUNT_OPTIONS,
  FINANCIAL_INSTITUTION_OPTIONS,
  TAX_FIRST_PAYMENT_TYPE_OPTIONS,
  COUNTRY_OPTIONS,
  STREET_TYPE_OPTIONS,
  STREET_DIRECTION_OPTIONS,
  ADDRESS_EXPANDED_TYPE_OPTIONS,
  RESIDENTIAL_TYPE_OPTIONS,
  RESIDENTIAL_STATUS_TYPE_OPTIONS,
  INSURANCE_PAID_BY_OPTIONS,
  ORIGINAL_MI_PROVIDER_OPTIONS,
  APPRAISAL_SOURCE_OPTIONS,
  PAYOFF_PAYDOWN_OPTIONS,
  EXCLUDE_ASSET_FROM_CALCULATION_REASON_TYPE_OPTIONS,
  FCT_INSURANCE_STATUS_OPTIONS,
  CLOSING_SERVICE_OPTIONS,
  LENDING_TIER_OPTIONS,
  EQ_FRAUD_AML_RISK_OPTIONS,
  INCOME_VERIFICATION_OPTIONS,
  RESPONSIBLE_OPTIONS,
  SOURCE_OF_BUSINESS_OPTIONS,
  EXCLUDE_LIABILITY_FROM_CALCULATION_REASON_TYPE_OPTIONS,
  CONFORMING_OPTIONS,
  CONFIDENCE_SCORE_OPTIONS,
  PROGRAM_CODE_OPTIONS,
  PURPOSE_CODE_OPTIONS,
  PAYMENT_SOURCE_OPTIONS,
  SECURITY_TYPE_OPTIONS,
  SECURITY_OPTIONS,
  TYPE_OF_CLOSING_INSTRUCTION_OPTIONS,
  SERVICING_TYPE_OPTIONS,
  PREPAYMENT_OPTIONS,
  AGENT_ADDRESS_EXPANDED_TYPE_OPTIONS,
} from './fieldMetadata.enum';
import { AddressExpandedKey } from './addressExpanded';
import { ApplicationBrokerCommissionKey } from './brokerCommission';
import { ClosingInstructionKey } from './closingInstruction';
import { AgentAddressExpandedKey } from './agentAddressExpanded';

export interface FieldMetadata {
  id?: string;
  tableName: string;
  tableDisplayName?: string;
  fieldName: string;
  fieldDisplayName?: string;
  defaultValue?: string;
  type: string;
  options?: FieldOption;
  description?: string;
  requiredForStageTransition?: string;
  isStageRequired?: boolean;
  isCustom?: boolean;
  allowOptionsEditing?: boolean;
  locked: boolean;
  lockedForStageTransition?: ApplicationStage[];
  createdAt?: Date;
  deletedAt?: Date;
  updatedAt?: Date;
  tenantId?: string;
}

export interface FieldName {
  [key: string]: FieldMetadata;
}

export interface FormattedFieldMetadata {
  [key: string]: FieldName;
}

export interface FieldOption {
  [key: string]: FieldOptionDetails | string;
}

export interface FieldOptionDetails {
  value: string;
  disabled?: boolean;
  isDefault?: boolean;
  deletedAt?: Date;
  updatedAt?: Date;
}

export enum SupportedTypes {
  TEXT = 'Text',
  NUMBER = 'Number',
  CURRENCY = 'Currency',
  PERCENT = 'Percentage',
  SINGLESELECT = 'Single-select',
  SINGLESELECT_NUMERIC = 'Single-select (Numeric)',
  MULTISELECT = 'Multi-select',
  DATE = 'Date',
  BOOLEAN = 'Boolean',
  DISABLED = 'Disabled',
  TEXT_AUTO_COMPLETE = 'Text Auto Complete',
}

export enum EntitySupportsNewFields {
  MORTGAGE = 'Mortgage',
  APPLICATION = 'Application',
  APPLICANT = 'Applicant',
  PROPERTY = 'Property',
}

export enum SupportedCustomEntity {
  FEE = 'Fee',
  DOWN_PAYMENT = 'DownPayment',
  JOB = 'Job',
  PROPERTY_OWNER = 'PropertyOwner',
  PROPERTY_APPRAISAL = 'PropertyAppraisal',
  PROPERTY_INSURANCE = 'PropertyInsurance',
  FINANCIAL_LIABILITY = 'FinancialLiability',
  FINANCIAL_ASSET = 'FinancialAsset',
  CONDITION_OF_APPROVAL = 'ConditionOfApproval',
  CONSTRUCTION_MORTGAGE = 'ConstructionMortgage',
  PROPERTY = 'Property',
  APPLICANT = 'Applicant',
  APPLICATION = 'Application',
  MORTGAGE = 'Mortgage',
  ADDRESS_EXPANDED = 'AddressExpanded',
  AGENT_ADDRESS_EXPANDED = 'AgentAddressExpanded',
  BROKER_COMMISSION = 'ApplicationBrokerCommission',
  CLOSING_INSTRUCTION = 'ClosingInstruction',
}

export enum FieldMetadataKey {
  TABLE_DISPLAY_NAME = 'tableDisplayName',
  FIELD_DISPLAY_NAME = 'fieldDisplayName',
}

export type SupportedCustomColumn =
  | DownPaymentKey
  | FeeKey
  | JobKey
  | PropertyOwnerKey
  | PropertyAppraisalKey
  | PropertyInsuranceKey
  | FinancialLiabilityKey
  | FinancialAssetKey
  | ConditionKey
  | ConstructionKey
  | PropertyKey
  | MortgageKey
  | ApplicantKey
  | ApplicationKey
  | AddressExpandedKey
  | AgentAddressExpandedKey
  | ApplicationBrokerCommissionKey
  | ClosingInstructionKey;

export const KeySingleSelects: {
  [key: string]: { [key: string]: boolean };
} = {
  [SupportedCustomEntity.APPLICATION]: {
    [ApplicationKey.PURPOSE]: true,
    [ApplicationKey.TYPE]: true,
    [ApplicationKey.MORTGAGE_CLASSIFICATION]: true,
    [ApplicationKey.SOURCE_OF_BUSINESS]: true,
    [ApplicationKey.PURPOSE_CODE]: true,
  },
  [SupportedCustomEntity.PROPERTY]: {
    [PropertyKey.ZONING_TYPE]: true,
  },
  [SupportedCustomEntity.MORTGAGE]: {
    [MortgageKey.PAYMENT_FREQUENCY]: true,
  },
  [SupportedCustomEntity.APPLICANT]: {
    [ApplicantKey.CITIZENSHIP_STATUS]: true,
  },
};

export const DisableDefaultFields: {
  [key: string]: { [key: string]: boolean };
} = {
  [SupportedCustomEntity.APPLICATION]: {
    [ApplicationKey.MORTGAGE_CLASSIFICATION]: true,
  },
  [SupportedCustomEntity.PROPERTY_INSURANCE]: {
    [PropertyInsuranceKey.AMOUNT]: true,
    [PropertyInsuranceKey.PERCENT]: true,
    [PropertyInsuranceKey.TYPE]: true,
  },
  [SupportedCustomEntity.PROPERTY_OWNER]: {
    [PropertyOwnerKey.SPOUSAL_CONSENT]: true,
    [PropertyOwnerKey.FIRST_NAME]: true,
    [PropertyOwnerKey.LAST_NAME]: true,
    [PropertyOwnerKey.EMAIL]: true,
    [PropertyOwnerKey.PHONE_NUMBER]: true,
  },
  [SupportedCustomEntity.JOB]: {
    [JobKey.TYPE]: true,
    [JobKey.PAYMENT_TYPE]: true,
    [JobKey.INCOME_PAYMENT_FREQUENCY]: true,
  },
  [SupportedCustomEntity.FINANCIAL_LIABILITY]: {
    [FinancialLiabilityKey.SOURCE]: true,
  },
};

export const customFieldsByServicingPurpose: Record<ServicingApplicationPurpose, string[]> = {
  [ServicingApplicationPurpose.PAYMENT_CHANGE]: [
    MortgageKey.NEXT_PAYMENT_DATE,
    MortgageKey.PAYMENT_FREQUENCY,
    MortgageKey.PAYMENT_AMOUNT,
    MortgageKey.AMORTIZATION,
    MortgageKey.INTEREST_ADJUSTMENT_DATE,
  ],
  [ServicingApplicationPurpose.SERVICING_RENEWAL]: [
    MortgageKey.RATE_HOLD_DATE,
    MortgageKey.PAYMENT_FREQUENCY,
    MortgageKey.TERM_TYPE,
    MortgageKey.TERM,
    MortgageKey.AMORTIZATION,
    MortgageKey.PAYMENT_AMOUNT,
    MortgageKey.PROPERTY_TAX_AMOUNT,
    MortgageKey.PREPAYMENT_OPTIONS,
    MortgageKey.NEXT_PAYMENT_DATE,
    MortgageKey.INTEREST_ADJUSTMENT_DATE,
  ],
  [ServicingApplicationPurpose.COST_OF_BORROWING]: [],
};

export const defaultFieldMetadata: Partial<FieldMetadata>[] = [
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.APPLICATION_ADVANCED_PRODUCT,
    fieldDisplayName: 'Product',
    type: SupportedTypes.DISABLED,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.TOTAL_MORTGAGE_AMOUNT,
    fieldDisplayName: 'Loan Amount',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.LOAN_TYPE,
    fieldDisplayName: 'Loan Type',
    type: SupportedTypes.SINGLESELECT,
    options: LOAN_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.LOAN_NUMBER,
    fieldDisplayName: 'Loan #',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PREPAYMENT_AMOUNT_IN_PAYMENTS,
    fieldDisplayName: 'Prepayment Amount in Payments',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.AMOUNT_TO_BE_ADVANCED,
    fieldDisplayName: 'Amount To Be Advanced',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.BORROWING_LIMIT,
    fieldDisplayName: 'Borrowing Limit',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.DEADLINE,
    fieldDisplayName: 'Deadline',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.BALLOON_AMOUNT,
    fieldDisplayName: 'Balloon Amount',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.CONFORMING,
    fieldDisplayName: 'Conforming',
    options: CONFORMING_OPTIONS,
    type: SupportedTypes.SINGLESELECT,
  },

  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PROGRAM_CODE,
    fieldDisplayName: 'Program Code',
    type: SupportedTypes.SINGLESELECT,
    options: PROGRAM_CODE_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.FIRST_REGULAR_PAYMENT_DATE,
    fieldDisplayName: 'First Regular Payment Date',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.INTEREST_ADJUSTMENT_DATE,
    fieldDisplayName: 'Interest Adjustment Date',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.INSURER,
    fieldDisplayName: 'Insurer',
    type: SupportedTypes.SINGLESELECT,
    options: INSURER_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.LENDER,
    fieldDisplayName: 'Lender',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PURPOSE,
    fieldDisplayName: 'Purpose',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.COMPOUNDING,
    fieldDisplayName: 'Compounding',
    type: SupportedTypes.SINGLESELECT,
    options: COMPOUNDING_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.MORTGAGE_TYPE,
    fieldDisplayName: 'Mortgage Type',
    type: SupportedTypes.SINGLESELECT,
    options: MORTGAGE_TYPE_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.RATE_TYPE,
    fieldDisplayName: 'Rate Type',
    type: SupportedTypes.SINGLESELECT,
    options: RATE_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.CLOSING_DATE,
    fieldDisplayName: 'Closing Date',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.COF_DATE,
    fieldDisplayName: 'Condition of Financing Date',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.FUNDING_DATE,
    fieldDisplayName: 'Funding Date',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.MATURITY_DATE,
    fieldDisplayName: 'Maturity Date',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PAYMENT_FREQUENCY,
    fieldDisplayName: 'Payment Frequency',
    type: SupportedTypes.SINGLESELECT,
    options: PAYMENT_FREQUENCY_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PAYOUT_BALANCE,
    fieldDisplayName: 'Pay Out Balance',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PURCHASE_VALUE,
    fieldDisplayName: 'Purchase Value',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.MORTGAGE_BALANCE,
    fieldDisplayName: 'Mortgage Balance',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.INSURANCE_PREMIUM,
    fieldDisplayName: 'Insurance Premium (%)',
    type: SupportedTypes.PERCENT,
  },

  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.INSURANCE_PREMIUM_PROGRAM,
    fieldDisplayName: 'Insurance Premium Program',
    type: SupportedTypes.PERCENT,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.INSURANCE_AMOUNT,
    fieldDisplayName: 'Insurance Amount ($)',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.INSURANCE_ACCOUNT_NUMBER,
    fieldDisplayName: 'Insurance Account Number',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.MONTHLY_PAYMENT,
    fieldDisplayName: 'Monthly Payment',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.NET_RATE,
    fieldDisplayName: 'Net Rate',
    type: SupportedTypes.PERCENT,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.BASE_RATE,
    fieldDisplayName: 'Base Rate',
    type: SupportedTypes.PERCENT,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.BUYDOWN,
    fieldDisplayName: 'Buydown',
    type: SupportedTypes.PERCENT,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.DISCOUNT,
    fieldDisplayName: 'Discount',
    type: SupportedTypes.PERCENT,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.ORIGINAL_NET_RATE,
    fieldDisplayName: 'Original Net Rate',
    type: SupportedTypes.PERCENT,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.TERM,
    fieldDisplayName: 'Term',
    type: SupportedTypes.SINGLESELECT_NUMERIC,
    options: TERM_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.AMORTIZATION,
    fieldDisplayName: 'Amortization',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.REPAYMENT_TYPE,
    fieldDisplayName: 'Repayment Type',
    type: SupportedTypes.SINGLESELECT,
    options: REPAYMENT_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.TERM_TYPE,
    fieldDisplayName: 'Term Type',
    type: SupportedTypes.SINGLESELECT,
    options: TERM_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PAYOFF,
    fieldDisplayName: 'Pay From',
    type: SupportedTypes.SINGLESELECT,
    options: PAYOFF_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PAYOFF_PAYDOWN,
    fieldDisplayName: 'Payoff & Paydown',
    type: SupportedTypes.SINGLESELECT,
    options: PAYOFF_PAYDOWN_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PREPAYMENT_TYPE,
    fieldDisplayName: 'Holdback/Prepayment',
    type: SupportedTypes.SINGLESELECT,
    options: PREPAYMENT_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PREPAYMENT_PENALTY_PERIOD,
    fieldDisplayName: 'Prepayment Penalty Period',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.MAX_PREPAYMENT_AMOUNT,
    fieldDisplayName: 'Max Prepayment Amount',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PREPAYMENT_AMOUNT,
    fieldDisplayName: 'Prepayment Amount',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PST,
    fieldDisplayName: 'PST',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.MORTGAGE_NUMBER,
    fieldDisplayName: 'Mortgage Number',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.ORIGINAL_MI_PROVIDER,
    fieldDisplayName: 'Original MI Provider',
    type: SupportedTypes.SINGLESELECT,
    options: ORIGINAL_MI_PROVIDER_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.ORIGINAL_MI_NUMBER,
    fieldDisplayName: 'Original MI Number',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.INSURANCE_PAID_BY,
    fieldDisplayName: 'Insurance Paid By',
    type: SupportedTypes.SINGLESELECT,
    options: INSURANCE_PAID_BY_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PARTNER_LOAN_NAME,
    fieldDisplayName: 'Partner Loan Name',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PARTNER_LOAN_PORTION,
    fieldDisplayName: 'Partner Loan Portion',
    type: SupportedTypes.CURRENCY,
  },

  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PARTNER_LOAN_EQ_PORTION,
    fieldDisplayName: 'Partner Loan EQ Portion',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PARTNER_LOAN_YIELD,
    fieldDisplayName: 'Partner Loan Yield',
    type: SupportedTypes.PERCENT,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.CONSTRUCTION_CASH,
    fieldDisplayName: 'Construction Cash',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.CONSTRUCTION_COST,
    fieldDisplayName: 'Construction Cost',
    type: SupportedTypes.NUMBER,
  },

  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.SECURITY_TYPE,
    fieldDisplayName: 'Security Type',
    type: SupportedTypes.SINGLESELECT,
    options: SECURITY_TYPE_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.SECURITY,
    fieldDisplayName: 'Security',
    type: SupportedTypes.MULTISELECT,
    options: SECURITY_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.MORTGAGE_PRODUCT_ID,
    fieldDisplayName: 'Product ID',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.RATE_HOLD_DATE,
    fieldDisplayName: 'Rate Hold',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.INSURANCE_PREMIUM_SURCHARGE_APPLIED,
    fieldDisplayName: 'Add Premium Surcharge',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.BLENDED_RATE,
    fieldDisplayName: 'Blended Rate',
    type: SupportedTypes.PERCENT,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.NEGATIVE_AMORTIZATION_RATE,
    fieldDisplayName: 'Negative Amortization Rate',
    type: SupportedTypes.PERCENT,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.NAME,
    fieldDisplayName: 'Name',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.APPLICATION_NOTES,
    fieldDisplayName: 'Application Notes',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.APPLICATION_DATE,
    fieldDisplayName: 'Application Date',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.DOWN_PAYMENT_TOTAL,
    fieldDisplayName: 'Down Payment Total',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.DOCS_DUE_DATE,
    fieldDisplayName: 'Docs Due Date',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.DECLINE_COMMENT,
    fieldDisplayName: 'Decline Comment',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.PURPOSE,
    fieldDisplayName: 'Purpose',
    type: SupportedTypes.SINGLESELECT,
    options: APPLICATION_PURPOSE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.MORTGAGE_CLASSIFICATION,
    fieldDisplayName: 'Mortgage Classification',
    type: SupportedTypes.SINGLESELECT,
    options: MORTGAGE_CLASSIFICATION_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.TYPE,
    fieldDisplayName: 'Application Type',
    type: SupportedTypes.SINGLESELECT,
    options: APPLICATION_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.IS_COMBO,
    fieldDisplayName: 'Application Is Combo',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.CLOSING_SERVICE,
    fieldDisplayName: 'Closing Service',
    type: SupportedTypes.SINGLESELECT,
    options: CLOSING_SERVICE_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.LENDING_TIER,
    fieldDisplayName: 'Lending Tier',
    type: SupportedTypes.SINGLESELECT,
    options: LENDING_TIER_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.COMMITMENT_ISSUE_DATE,
    fieldDisplayName: 'Commitment Issue Date',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.COMMITMENT_EXPIRY_DATE,
    fieldDisplayName: 'Commitment Expiry Date',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.QUICK_CLOSE_FLAG,
    fieldDisplayName: 'Quick Close',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.RATE_HOLD_EXPIRY_DATE,
    fieldDisplayName: 'Rate Hold Expiry Date',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.SOURCE_OF_BUSINESS,
    fieldDisplayName: 'Source of Business',
    type: SupportedTypes.SINGLESELECT,
    options: SOURCE_OF_BUSINESS_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.PURPOSE_CODE,
    fieldDisplayName: 'Purpose Code',
    type: SupportedTypes.SINGLESELECT,
    options: PURPOSE_CODE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.TOTAL_BUSINESS_CONNECTION,
    fieldDisplayName: 'Total Business Connection',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.APPLICATION_LOAN_NUMBER,
    fieldDisplayName: 'Loan Number',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.HAS_OTHER_SECURITY_CONDITIONS,
    fieldDisplayName: 'Assignment of Rent / Caveats',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.OTHER_SECURITY_CONDITIONS,
    fieldDisplayName: 'Other Security Conditions',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.SHORT_LEGAL_DESCRIPTION,
    fieldDisplayName: 'Short Legal Description',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.LEGAL_DESCRIPTION,
    fieldDisplayName: 'Legal Description',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.PROPERTY_ADDRESS,
    fieldDisplayName: 'Property Address',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.PID,
    fieldDisplayName: 'PID',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.PURCHASE_DATE,
    fieldDisplayName: 'Purchase Date',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.PURCHASE_PRICE,
    fieldDisplayName: 'Purchase Price',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.PROPERTY_TYPE,
    fieldDisplayName: 'Property Type',
    type: SupportedTypes.SINGLESELECT,
    options: PROPERTY_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.DWELLING_STYLE,
    fieldDisplayName: 'Dwelling Style',
    type: SupportedTypes.SINGLESELECT,
    options: DWELLING_STYLE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.DWELLING_TYPE,
    fieldDisplayName: 'Dwelling Type',
    type: SupportedTypes.SINGLESELECT,
    options: DWELLING_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.FOUNDATION_TYPE,
    fieldDisplayName: 'Foundation Type',
    type: SupportedTypes.SINGLESELECT,
    options: FOUNDATION_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.INCLUDE_IN_CLTV,
    fieldDisplayName: 'Include in CLTV',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.GARAGE_SIZE,
    fieldDisplayName: 'Garage Number of Cars',
    type: SupportedTypes.SINGLESELECT,
    options: GARAGE_SIZE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.GARAGE_TYPE,
    fieldDisplayName: 'Garage Type',
    type: SupportedTypes.SINGLESELECT,
    options: GARAGE_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.NO_OF_UNITS,
    fieldDisplayName: 'Number of Units',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.LOT_SIZE,
    fieldDisplayName: 'Lot Size',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.LOT,
    fieldDisplayName: 'Lot',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.PLAN,
    fieldDisplayName: 'Plan',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.SEWAGE_INFO,
    fieldDisplayName: 'Sewage Type',
    type: SupportedTypes.SINGLESELECT,
    options: SEWAGE_INFO_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.TAXATION_YEAR,
    fieldDisplayName: 'Taxation Year',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.TAXES_PAID_BY,
    fieldDisplayName: 'Taxes Paid By',
    type: SupportedTypes.SINGLESELECT,
    options: TAXES_PAID_BY,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.WATER_TYPE,
    fieldDisplayName: 'Water Type',
    type: SupportedTypes.SINGLESELECT,
    options: WATER_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.YEAR_BUILT,
    fieldDisplayName: 'Year Built',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.CONSTRUCTION_TYPE,
    fieldDisplayName: 'Construction Type',
    type: SupportedTypes.SINGLESELECT,
    options: CONSTRUCTION_TYPE_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.TENURE,
    fieldDisplayName: 'Tenure',
    type: SupportedTypes.SINGLESELECT,
    options: TENURE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.VALUE_OF_IMPROVEMENTS,
    fieldDisplayName: 'Value of Improvements',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.LIVING_SPACE,
    fieldDisplayName: 'Living Space',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.ENVIRONMENTAL_HAZARD,
    fieldDisplayName: 'Environmental Hazards',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.ANNUAL_TAXES,
    fieldDisplayName: 'Annual Taxes',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.HEATING_COST,
    fieldDisplayName: 'Heating Cost',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.HEATING_TYPE,
    fieldDisplayName: 'Heating Type',
    type: SupportedTypes.SINGLESELECT,
    options: HEATING_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.IMPROVEMENTS,
    fieldDisplayName: 'Improvements',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.INCLUDE_EXPENSES_IN_TDS,
    fieldDisplayName: 'Include expenses in TDS',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.CONDO_FEES,
    fieldDisplayName: 'Condo Fees',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.CONDO_FEES_INCLUDE_HEATING,
    fieldDisplayName: 'Condo fees include heating',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.CONDO_FEES_PERCENT_IN_CALCULATION,
    fieldDisplayName: '% in calculations',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.OCCUPANCY,
    fieldDisplayName: 'Occupancy',
    type: SupportedTypes.SINGLESELECT,
    options: OCCUPANCY_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.PROPERTY_HOLDER,
    fieldDisplayName: 'Property Holder',
    type: SupportedTypes.MULTISELECT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.RPE_MONTHLY_RENTAL_INCOME,
    fieldDisplayName: 'Monthly Rental Income',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.RPE_RENTAL_OFFSET_OPTION,
    fieldDisplayName: 'Rental Inclusion/Offset Option',
    type: SupportedTypes.SINGLESELECT,
    options: RENTAL_OFFSET_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.RPE_OFFSET,
    fieldDisplayName: 'Offset',
    type: SupportedTypes.PERCENT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.ESTIMATED_VALUE,
    fieldDisplayName: 'Estimated Value',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.IS_MLS_LISTING,
    fieldDisplayName: 'Is Mls Listing',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.ZONING_TYPE,
    fieldDisplayName: 'Zoning Type',
    type: SupportedTypes.SINGLESELECT,
    options: ZONING_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.RPE_HYDRO,
    fieldDisplayName: 'Hydro',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.RPE_INSURANCE,
    fieldDisplayName: 'Insurance',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.RPE_REPAIRS,
    fieldDisplayName: 'Repairs',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.RPE_MANAGEMENT_EXPENSES,
    fieldDisplayName: 'Mgmt Expenses',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.RPE_INTEREST_CHARGES,
    fieldDisplayName: 'Intereset Charges',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.RPE_GENERAL_EXPENSES,
    fieldDisplayName: 'General Expenses',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.BLOCK,
    fieldDisplayName: 'Block',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.CONCESSION_TOWNSHIP,
    fieldDisplayName: 'Concession/Township',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.APPRAISED_DATE,
    fieldDisplayName: 'Appraised Date',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.PROPERTY_VALUE,
    fieldDisplayName: 'Property Value',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.LOCATION_TIER,
    fieldDisplayName: 'Location Tier',
    type: SupportedTypes.SINGLESELECT,
    options: LOCATION_TIER_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.TAX_INSTALLMENT,
    fieldDisplayName: 'Tax Installment',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.TAX_ROLL_NUMBER,
    fieldDisplayName: 'Tax Roll Number',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.TAX_FIRST_PAYMENT_TYPE,
    fieldDisplayName: 'Tax First Payment Type',
    type: SupportedTypes.SINGLESELECT,
    allowOptionsEditing: true,
    options: TAX_FIRST_PAYMENT_TYPE_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.SECTION,
    fieldDisplayName: 'Section',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.PROPERTY_NUMBER,
    fieldDisplayName: 'Property Number',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.ORIGINAL_PURCHASE_PRICE,
    fieldDisplayName: 'Original Purchase Price',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.MARK_AS_SOLD,
    fieldDisplayName: 'Mark as Sold',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.ESTIMATED_TAX_HOLDBACK,
    fieldDisplayName: 'Estimated Tax Holdback',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY,
    tableDisplayName: 'Properties',
    fieldName: PropertyKey.BUILDER_NAME,
    fieldDisplayName: 'Builder Name',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.FEE,
    tableDisplayName: 'Fees',
    fieldName: FeeKey.TYPE,
    fieldDisplayName: 'Type',
    type: SupportedTypes.SINGLESELECT,
    options: FEE_TYPE_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.FEE,
    tableDisplayName: 'Fees',
    fieldName: FeeKey.PAID_BY,
    fieldDisplayName: 'Paid By',
    type: SupportedTypes.SINGLESELECT,
    options: PAID_BY_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.FEE,
    tableDisplayName: 'Fees',
    fieldName: FeeKey.PERCENT,
    fieldDisplayName: 'Percentage',
    type: SupportedTypes.PERCENT,
  },
  {
    tableName: SupportedCustomEntity.FEE,
    tableDisplayName: 'Fees',
    fieldName: FeeKey.AMOUNT,
    fieldDisplayName: 'Amount',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.FEE,
    tableDisplayName: 'Fees',
    fieldName: FeeKey.SUBTRACT_FROM_PRINCIPAL,
    fieldDisplayName: 'Deduct/Cap',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.FEE,
    tableDisplayName: 'Fees',
    fieldName: FeeKey.INCLUDE_IN_APR,
    fieldDisplayName: 'APR',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.FEE,
    tableDisplayName: 'Fees',
    fieldName: FeeKey.CAP_FEES_PERCENTAGE,
    fieldDisplayName: 'Cap Fees Percentage',
    type: SupportedTypes.PERCENT,
  },
  {
    tableName: SupportedCustomEntity.FEE,
    tableDisplayName: 'Fees',
    fieldName: FeeKey.DESCRIPTION,
    fieldDisplayName: 'Description',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.DOWN_PAYMENT,
    tableDisplayName: 'Down Payments',
    fieldName: DownPaymentKey.SOURCE,
    fieldDisplayName: 'Source',
    type: SupportedTypes.SINGLESELECT,
    options: DOWN_PAYMENT_SOURCE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.DOWN_PAYMENT,
    tableDisplayName: 'Down Payments',
    fieldName: DownPaymentKey.DESCRIPTION,
    fieldDisplayName: 'Description',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.DOWN_PAYMENT,
    tableDisplayName: 'Down Payments',
    fieldName: DownPaymentKey.AMOUNT,
    fieldDisplayName: 'Amount',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_INSURANCE,
    tableDisplayName: 'Insurances',
    fieldName: PropertyInsuranceKey.AMOUNT,
    fieldDisplayName: 'Amount',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_INSURANCE,
    tableDisplayName: 'Insurances',
    fieldName: PropertyInsuranceKey.PERCENT,
    fieldDisplayName: 'Percentage',
    type: SupportedTypes.PERCENT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_INSURANCE,
    tableDisplayName: 'Insurances',
    fieldName: PropertyInsuranceKey.TYPE,
    fieldDisplayName: 'Type',
    type: SupportedTypes.SINGLESELECT,
    options: PROPERTY_INSURANCE_TYPE_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_APPRAISAL,
    tableDisplayName: 'Appraisals',
    fieldName: PropertyAppraisalKey.APPRAISAL_TYPE,
    fieldDisplayName: 'Type',
    type: SupportedTypes.SINGLESELECT,
    options: APPRAISAL_TYPE_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_APPRAISAL,
    tableDisplayName: 'Appraisals',
    fieldName: PropertyAppraisalKey.SOURCE,
    fieldDisplayName: 'Source',
    type: SupportedTypes.SINGLESELECT,
    options: APPRAISAL_SOURCE_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_APPRAISAL,
    tableDisplayName: 'Appraisals',
    fieldName: PropertyAppraisalKey.STATUS,
    fieldDisplayName: 'Status',
    type: SupportedTypes.SINGLESELECT,
    options: APPRAISAL_STATUS_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_APPRAISAL,
    tableDisplayName: 'Appraisals',
    fieldName: PropertyAppraisalKey.APPRAISAL_DATE,
    fieldDisplayName: 'Date Appraised',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_APPRAISAL,
    tableDisplayName: 'Appraisals',
    fieldName: PropertyAppraisalKey.APPRAISER,
    fieldDisplayName: 'Name',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_APPRAISAL,
    tableDisplayName: 'Appraisals',
    fieldName: PropertyAppraisalKey.APPRAISER_COMPANY,
    fieldDisplayName: 'Company',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_APPRAISAL,
    tableDisplayName: 'Appraisals',
    fieldName: PropertyAppraisalKey.APPRAISED_VALUE,
    fieldDisplayName: 'Value',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_APPRAISAL,
    tableDisplayName: 'Appraisals',
    fieldName: PropertyAppraisalKey.APPRAISAL_NOTES,
    fieldDisplayName: 'Notes',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_APPRAISAL,
    tableDisplayName: 'Appraisals',
    fieldName: PropertyAppraisalKey.VALUATION_TIME,
    fieldDisplayName: 'Valuation Time',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_APPRAISAL,
    tableDisplayName: 'Appraisals',
    fieldName: PropertyAppraisalKey.CONFIDENCE_SCORE,
    fieldDisplayName: 'Confidence Score',
    type: SupportedTypes.SINGLESELECT,
    options: CONFIDENCE_SCORE_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_APPRAISAL,
    tableDisplayName: 'Appraisals',
    fieldName: PropertyAppraisalKey.FCT_INSURANCE_STATUS,
    fieldDisplayName: 'Eligibility Status (FCT)',
    type: SupportedTypes.SINGLESELECT,
    options: FCT_INSURANCE_STATUS_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.IS_CURRENT,
    fieldDisplayName: 'Current Income',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.UNABLE_TO_VERIFY,
    fieldDisplayName: 'Unable to Verify',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.PROBATION,
    fieldDisplayName: 'Probation',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.EMPLOYER_NAME,
    fieldDisplayName: 'Employer Name',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.EMPLOYER_ADDRESS,
    fieldDisplayName: 'Employer Address',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.EMPLOYER_EMAIL_ADDRESS,
    fieldDisplayName: 'Employer Email Address',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.INCOME_PAYMENT_FREQUENCY,
    fieldDisplayName: 'Income Payment Frequency',
    type: SupportedTypes.SINGLESELECT,
    options: INCOME_PAYMENT_FREQUENCY_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.TIME_AT_JOB_MONTHS,
    fieldDisplayName: 'Time At Job (Months)',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.OCCUPATION,
    fieldDisplayName: 'Occupation',
    type: SupportedTypes.SINGLESELECT,
    options: OCCUPATION_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.JOB_TITLE,
    fieldDisplayName: 'Job Title',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.EMPLOYMENT_START_DATE,
    fieldDisplayName: 'Start Date',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.EMPLOYMENT_END_DATE,
    fieldDisplayName: 'End Date',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.EMPLOYMENT_TYPE,
    fieldDisplayName: 'Employment Type',
    type: SupportedTypes.SINGLESELECT,
    options: EMPLOYMENT_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.INDUSTRY_SECTOR,
    fieldDisplayName: 'Industry Sector',
    type: SupportedTypes.SINGLESELECT,
    options: INDUSTRY_SECTOR_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.ANNUAL_INCOME,
    fieldDisplayName: 'Annual Income',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.BONUS_OVERTIME_COMMISSIONS,
    fieldDisplayName: 'Bonus / Overtime / Commissions',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.BUSINESS_TYPE,
    fieldDisplayName: 'Business Type',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.DESCRIPTION,
    fieldDisplayName: 'Description',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.PAYMENT_TYPE,
    fieldDisplayName: 'Payment Type',
    type: SupportedTypes.SINGLESELECT,
    options: PAYMENT_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.TIME_AT_INDUSTRY_MONTHS,
    fieldDisplayName: 'Time at Industry (Months)',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.TYPE,
    fieldDisplayName: 'Type',
    type: SupportedTypes.SINGLESELECT,
    options: JOB_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.JOB,
    tableDisplayName: 'Employment',
    fieldName: JobKey.PHONE_NUMBER,
    fieldDisplayName: 'Phone Number',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_OWNER,
    tableDisplayName: 'Owners',
    fieldName: PropertyOwnerKey.TITLE_NAME,
    fieldDisplayName: 'Name On Title',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_OWNER,
    tableDisplayName: 'Owners',
    fieldName: PropertyOwnerKey.SPOUSE_NAME,
    fieldDisplayName: 'Spouse Name',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_OWNER,
    tableDisplayName: 'Owners',
    fieldName: PropertyOwnerKey.SPOUSAL_CONSENT,
    fieldDisplayName: 'Spousal Consent Required',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_OWNER,
    tableDisplayName: 'Owners',
    fieldName: PropertyOwnerKey.IS_COMPANY,
    fieldDisplayName: 'Is Company',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_OWNER,
    tableDisplayName: 'Owners',
    fieldName: PropertyOwnerKey.FIRST_NAME,
    fieldDisplayName: 'First Name',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_OWNER,
    tableDisplayName: 'Owners',
    fieldName: PropertyOwnerKey.LAST_NAME,
    fieldDisplayName: 'Last Name',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_OWNER,
    tableDisplayName: 'Owners',
    fieldName: PropertyOwnerKey.EMAIL,
    fieldDisplayName: 'Email',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_OWNER,
    tableDisplayName: 'Owners',
    fieldName: PropertyOwnerKey.PHONE_NUMBER,
    fieldDisplayName: 'Phone Number',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_OWNER,
    tableDisplayName: 'Owners',
    fieldName: PropertyOwnerKey.ADDRESS,
    fieldDisplayName: 'Address For Service Per Title',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.PROPERTY_OWNER,
    tableDisplayName: 'Owners',
    fieldName: PropertyOwnerKey.MARTIAL_STATUS,
    fieldDisplayName: 'Marital Status',
    type: SupportedTypes.SINGLESELECT,
    options: MARTIAL_STATUS_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_LIABILITY,
    tableDisplayName: 'Financial Liability',
    fieldName: FinancialLiabilityKey.PAYOFF_PAYDOWN,
    fieldDisplayName: 'Payoff & Paydown',
    type: SupportedTypes.SINGLESELECT,
    options: PAYOFF_PAYDOWN_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_LIABILITY,
    tableDisplayName: 'Financial Liability',
    fieldName: FinancialLiabilityKey.PAYOFF,
    fieldDisplayName: 'Pay From',
    type: SupportedTypes.SINGLESELECT,
    options: PAYOFF_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_LIABILITY,
    tableDisplayName: 'Financial Liability',
    fieldName: FinancialLiabilityKey.DESCRIPTION,
    fieldDisplayName: 'Description',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_LIABILITY,
    tableDisplayName: 'Financial Liability',
    fieldName: FinancialLiabilityKey.SOURCE,
    fieldDisplayName: 'Source',
    type: SupportedTypes.SINGLESELECT,
    options: LIABILITY_SOURCE_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_LIABILITY,
    tableDisplayName: 'Financial Liability',
    fieldName: FinancialLiabilityKey.CURRENT_TYPE,
    fieldDisplayName: 'Status',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_LIABILITY,
    tableDisplayName: 'Financial Liability',
    fieldName: FinancialLiabilityKey.LIABILITY,
    fieldDisplayName: 'Type',
    type: SupportedTypes.SINGLESELECT,
    options: LIABILITY_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_LIABILITY,
    tableDisplayName: 'Financial Liability',
    fieldName: FinancialLiabilityKey.VALUE,
    fieldDisplayName: 'Limit',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_LIABILITY,
    tableDisplayName: 'Financial Liability',
    fieldName: FinancialLiabilityKey.BALANCE,
    fieldDisplayName: 'Balance',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_LIABILITY,
    tableDisplayName: 'Financial Liability',
    fieldName: FinancialLiabilityKey.MONTHLY_PAYMENT,
    fieldDisplayName: 'Payment',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_LIABILITY,
    tableDisplayName: 'Financial Liability',
    fieldName: FinancialLiabilityKey.APPLICANT_ID,
    fieldDisplayName: 'Credit Holder',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_LIABILITY,
    tableDisplayName: 'Financial Liability',
    fieldName: FinancialLiabilityKey.EXCLUDE_FROM_CALCULATION,
    fieldDisplayName: 'Exclude from Calculation',
    type: SupportedTypes.SINGLESELECT,
    options: EXCLUDE_LIABILITY_FROM_CALCULATION_REASON_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_LIABILITY,
    tableDisplayName: 'Financial Liability',
    fieldName: FinancialLiabilityKey.SECURITY_TYPE,
    fieldDisplayName: 'Security Type',
    type: SupportedTypes.SINGLESELECT,
    options: SECURITY_TYPE_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_LIABILITY,
    tableDisplayName: 'Financial Liability',
    fieldName: FinancialLiabilityKey.FINANCIAL_INSTITUTION,
    fieldDisplayName: 'Financial Institution',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_LIABILITY,
    tableDisplayName: 'Financial Liability',
    fieldName: FinancialLiabilityKey.PAYMENT_FREQUENCY,
    fieldDisplayName: 'Payment Frequency',
    type: SupportedTypes.SINGLESELECT,
    options: PAYMENT_FREQUENCY_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_ASSET,
    tableDisplayName: 'Financial Asset',
    fieldName: FinancialAssetKey.ASSET,
    fieldDisplayName: 'Type',
    type: SupportedTypes.SINGLESELECT,
    options: FINANCIAL_ASSET_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_ASSET,
    tableDisplayName: 'Financial Asset',
    fieldName: FinancialAssetKey.EXCLUDE_FROM_CALCULATION,
    fieldDisplayName: 'Exclude from Calculation',
    type: SupportedTypes.SINGLESELECT,
    options: EXCLUDE_ASSET_FROM_CALCULATION_REASON_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_ASSET,
    tableDisplayName: 'Financial Asset',
    fieldName: FinancialAssetKey.DESCRIPTION,
    fieldDisplayName: 'Description',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_ASSET,
    tableDisplayName: 'Financial Asset',
    fieldName: FinancialAssetKey.VALUE,
    fieldDisplayName: 'Value',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_ASSET,
    tableDisplayName: 'Financial Asset',
    fieldName: FinancialAssetKey.USE_AS_DOWN_PAYMENT,
    fieldDisplayName: 'Use As Down Payment',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.FINANCIAL_ASSET,
    tableDisplayName: 'Financial Asset',
    fieldName: FinancialAssetKey.PURCHASE_PRICE,
    fieldDisplayName: 'Purchase Price',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.CONDITION_OF_APPROVAL,
    tableDisplayName: 'Conditions',
    fieldName: ConditionKey.NAME,
    fieldDisplayName: 'Name',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.CONDITION_OF_APPROVAL,
    tableDisplayName: 'Conditions',
    fieldName: ConditionKey.STATUS,
    fieldDisplayName: 'Status',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.CONDITION_OF_APPROVAL,
    tableDisplayName: 'Conditions',
    fieldName: ConditionKey.TEXT,
    fieldDisplayName: 'Text',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.CONDITION_OF_APPROVAL,
    tableDisplayName: 'Conditions',
    fieldName: ConditionKey.TEXT_FRENCH,
    fieldDisplayName: 'Text (French)',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.CONDITION_OF_APPROVAL,
    tableDisplayName: 'Conditions',
    fieldName: ConditionKey.PRODUCTS,
    fieldDisplayName: 'Products',
    type: SupportedTypes.SINGLESELECT,
  },
  {
    tableName: SupportedCustomEntity.CONDITION_OF_APPROVAL,
    tableDisplayName: 'Conditions',
    fieldName: ConditionKey.DOCUMENT,
    fieldDisplayName: 'Document',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.CONDITION_OF_APPROVAL,
    tableDisplayName: 'Conditions',
    fieldName: ConditionKey.DM_DOCUMENT,
    fieldDisplayName: 'Linked Document',
    type: SupportedTypes.SINGLESELECT,
  },
  {
    tableName: SupportedCustomEntity.CONDITION_OF_APPROVAL,
    tableDisplayName: 'Conditions',
    fieldName: ConditionKey.IS_DEFAULT,
    fieldDisplayName: 'Default',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.CONDITION_OF_APPROVAL,
    tableDisplayName: 'Conditions',
    fieldName: ConditionKey.REQUIRED_STAGE,
    fieldDisplayName: 'Required Stage',
    type: SupportedTypes.SINGLESELECT,
  },
  {
    tableName: SupportedCustomEntity.CONDITION_OF_APPROVAL,
    tableDisplayName: 'Conditions',
    fieldName: ConditionKey.RESPONSIBLE,
    fieldDisplayName: 'Responsible',
    type: SupportedTypes.SINGLESELECT,
    options: RESPONSIBLE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.CONDITION_OF_APPROVAL,
    tableDisplayName: 'Conditions',
    fieldName: ConditionKey.APPLICATION_PURPOSE,
    fieldDisplayName: 'Application Purpose',
    type: SupportedTypes.SINGLESELECT,
  },
  {
    tableName: SupportedCustomEntity.CONDITION_OF_APPROVAL,
    tableDisplayName: 'Conditions',
    fieldName: ConditionKey.CONDITION_TYPE,
    fieldDisplayName: 'Condition Type',
    type: SupportedTypes.SINGLESELECT,
  },
  {
    tableName: SupportedCustomEntity.CONDITION_OF_APPROVAL,
    tableDisplayName: 'Conditions',
    fieldName: ConditionKey.INDEX,
    fieldDisplayName: 'Index',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.CONSTRUCTION_MORTGAGE,
    tableDisplayName: 'Construction Mortgage',
    fieldName: ConstructionKey.LAND_ADVANCE,
    fieldDisplayName: 'Land Advance (if applicable)',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.CONSTRUCTION_MORTGAGE,
    tableDisplayName: 'Construction Mortgage',
    fieldName: ConstructionKey.SQFT_PROJECT,
    fieldDisplayName: 'Sq/ft of Project',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.CONSTRUCTION_MORTGAGE,
    tableDisplayName: 'Construction Mortgage',
    fieldName: ConstructionKey.LAND_COST,
    fieldDisplayName: 'Land Cost',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.CONSTRUCTION_MORTGAGE,
    tableDisplayName: 'Construction Mortgage',
    fieldName: ConstructionKey.BUILDING_BUDGET,
    fieldDisplayName: 'Building Budget',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.CONSTRUCTION_MORTGAGE,
    tableDisplayName: 'Construction Mortgage',
    fieldName: ConstructionKey.GROSS_MORTGAGE_AMOUNT,
    fieldDisplayName: 'Amount To Be Advanced',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.CONSTRUCTION_MORTGAGE,
    tableDisplayName: 'Construction Mortgage',
    fieldName: ConstructionKey.NET_LOAN_LESS_ADVANCE,
    fieldDisplayName: 'Net Loan less Land Advance',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.CONSTRUCTION_MORTGAGE,
    tableDisplayName: 'Construction Mortgage',
    fieldName: ConstructionKey.COST_PER_SQFT,
    fieldDisplayName: 'Cost Sq/ft',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.CONSTRUCTION_MORTGAGE,
    tableDisplayName: 'Construction Mortgage',
    fieldName: ConstructionKey.BUILDER_NAME,
    fieldDisplayName: 'Builder Name',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.CUSTOMER_TYPE,
    fieldDisplayName: 'Customer Type',
    type: SupportedTypes.SINGLESELECT,
    options: CUSTOMER_TYPE_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.CITIZENSHIP_STATUS,
    fieldDisplayName: 'Citizenship Status',
    type: SupportedTypes.SINGLESELECT,
    options: CITIZENSHIP_STATUS_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.SALUTATION,
    fieldDisplayName: 'Salutation',
    type: SupportedTypes.SINGLESELECT,
    options: SALUTATION_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.TIME_AT_RESIDENCE_MONTHS,
    fieldDisplayName: 'Time at Residence Months',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.SURNAME,
    fieldDisplayName: 'Last Name',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.NAME,
    fieldDisplayName: 'First Name',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.MIDDLE_NAME,
    fieldDisplayName: 'Middle Name',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.GENDER,
    fieldDisplayName: 'Gender',
    type: SupportedTypes.SINGLESELECT,
    options: GENDER_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.POB,
    fieldDisplayName: 'Place Of Birth',
    type: SupportedTypes.SINGLESELECT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.NATIONALITY,
    fieldDisplayName: 'Nationality',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.REGISTERED_COUNTRY,
    fieldDisplayName: 'Registered Country',
    type: SupportedTypes.SINGLESELECT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.COR,
    fieldDisplayName: 'Country Of Residence',
    type: SupportedTypes.SINGLESELECT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.RESIDENTIAL_ADDRESS,
    fieldDisplayName: 'Residential Address',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.EMAIL,
    fieldDisplayName: 'Email',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.CELL_PHONE,
    fieldDisplayName: 'Cell Phone',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.HOME_PHONE,
    fieldDisplayName: 'Home Phone',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.DOB,
    fieldDisplayName: 'Date of Birth',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.RESIDENTIAL_ADDRESS,
    fieldDisplayName: 'Residential Address',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.MARITAL_STATUS,
    fieldDisplayName: 'Marital Status',
    type: SupportedTypes.SINGLESELECT,
    options: MARTIAL_STATUS_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.RELATIONSHIP_TO_PRIMARY_APPLICANT,
    fieldDisplayName: 'Relationship to Primary Applicant',
    type: SupportedTypes.SINGLESELECT,
    options: RELATIONSHIP_TO_PRIMARY_APPLICANT_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.DEPENDENTS,
    fieldDisplayName: 'Dependents',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.BROKERAGE,
    fieldDisplayName: 'Brokerage',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.IS_FIRST_TIME_HOME_BUYER,
    fieldDisplayName: 'First Time Home Buyer',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.FAX_NO,
    fieldDisplayName: 'Fax Number',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.WORK_PHONE,
    fieldDisplayName: 'Work Phone',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.COMPANY,
    fieldDisplayName: 'Company',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.COMPANY_ID,
    fieldDisplayName: 'Company ID',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.BUSINESS_NO,
    fieldDisplayName: 'Business Number',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.INCORPORATION_NO,
    fieldDisplayName: 'Incorporation Number',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.SIN,
    fieldDisplayName: 'SIN',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.NETWORTH,
    fieldDisplayName: 'Net Worth',
    type: SupportedTypes.CURRENCY,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.REFERENCE,
    fieldDisplayName: 'Reference',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.CONSENT_RECEIVED,
    fieldDisplayName: 'Consent Received',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.NEW_TO_CANADA,
    fieldDisplayName: 'New to Canada',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.DOCUMENT_REQUEST_NOTIFICATIONS,
    fieldDisplayName: 'Document Request Notifications',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.FICO_SCORE_OVERRIDE,
    fieldDisplayName: 'FICO Score Override',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.POLITICALLY_EXPOSED_PERSON_STATUS,
    fieldDisplayName: 'Politically Exposed Person',
    type: SupportedTypes.SINGLESELECT,
    options: POLITICALLY_EXPOSED_PERSON_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.FRAUD_AML_RISK,
    fieldDisplayName: 'Fraud/AML Risk',
    type: SupportedTypes.MULTISELECT,
    options: FRAUD_AML_RISK_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.EQ_FRAUD_AML_RISK,
    fieldDisplayName: 'Fraud/AML Risk',
    type: SupportedTypes.SINGLESELECT,
    options: EQ_FRAUD_AML_RISK_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.THIRD_PARTY_DETERMINATION,
    fieldDisplayName: 'Third Party Determination',
    type: SupportedTypes.SINGLESELECT,
    options: THIRD_PARTY_DETERMINATION_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.CLIENT_RISK_SCORE,
    fieldDisplayName: 'Client Risk Score',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.FINANCIAL_INSTITUTION,
    fieldDisplayName: 'Financial Institution',
    type: SupportedTypes.SINGLESELECT,
    options: FINANCIAL_INSTITUTION_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.FINANCIAL_INSTITUTION_NAME,
    fieldDisplayName: 'Financial Institution Name',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.FINANCIAL_INSTITUTION_NUMBER,
    fieldDisplayName: 'Financial Institution Number',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.ACCOUNT_NUMBER,
    fieldDisplayName: 'Account Number',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.TRANSIT_NUMBER,
    fieldDisplayName: 'Transit Number',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.ACCOUNT_TYPE,
    fieldDisplayName: 'Account Type',
    type: SupportedTypes.SINGLESELECT,
    options: ACCOUNT_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.PAYMENT_SOURCE,
    fieldDisplayName: 'Payment Source',
    type: SupportedTypes.SINGLESELECT,
    options: PAYMENT_SOURCE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.MEMBER_BANKING_NUMBER,
    fieldDisplayName: 'Member Number',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.SUB_NUMBER,
    fieldDisplayName: 'Sub Number',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.REQUEST_NAME_SCREENING,
    fieldDisplayName: 'Request Name Screening',
    type: SupportedTypes.SINGLESELECT,
    options: REQUEST_NAME_SCREENING_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.CLIENT_IDENTIFIED_IN_NAME_SCREENING,
    fieldDisplayName: 'Client Identified In Name Screening',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.CLIENT_ID,
    fieldDisplayName: 'Client Id',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.CLIENT_SCREENING_COMPLETED,
    fieldDisplayName: 'Client Screening Completed',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.CLIENT_SCREENING_COMPLETED_DATE,
    fieldDisplayName: 'Client Screening Completed Date',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.CLIENT_LAST_SCREENING_DATE,
    fieldDisplayName: 'Client Last Screening Date',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.RELATED_CIFS,
    fieldDisplayName: 'Related CIFs',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.INCOME_VERIFICATION,
    fieldDisplayName: 'Income Verification',
    type: SupportedTypes.SINGLESELECT,
    options: INCOME_VERIFICATION_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.DURATION_IN_CANADA,
    fieldDisplayName: 'Duration In Canada',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.PERMANENT_RESIDENCE_FLAG,
    fieldDisplayName: 'Permanent Residence',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.CONNECTED_PARTY,
    fieldDisplayName: 'Connected Party',
    type: SupportedTypes.BOOLEAN,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.BNI_SCORE,
    fieldDisplayName: 'BNI',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.APPLICANT,
    tableDisplayName: 'Stakeholder',
    fieldName: ApplicantKey.CREDIT_SCORE,
    fieldDisplayName: 'FICO',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.ADDRESS,
    fieldDisplayName: 'Address',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.CITY,
    fieldDisplayName: 'City',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.COUNTRY,
    fieldDisplayName: 'Country',
    type: SupportedTypes.SINGLESELECT,
    options: COUNTRY_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.LAT,
    fieldDisplayName: 'Latitude',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.LONG,
    fieldDisplayName: 'Longitude',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.STREET_NUMBER,
    fieldDisplayName: 'Street Number',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.STREET_NUMBER_SUFFIX,
    fieldDisplayName: 'Street No. Suffix',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.STREET_NAME,
    fieldDisplayName: 'Street Name',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.STREET_TYPE,
    fieldDisplayName: 'Street Type',
    type: SupportedTypes.SINGLESELECT,
    options: STREET_TYPE_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.STREET_DIRECTION,
    fieldDisplayName: 'Street Direction',
    type: SupportedTypes.SINGLESELECT,
    options: STREET_DIRECTION_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.UNIT,
    fieldDisplayName: 'Unit',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.POSTAL_CODE,
    fieldDisplayName: 'Postal Code',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.TYPE,
    fieldDisplayName: 'Type',
    type: SupportedTypes.SINGLESELECT,
    options: ADDRESS_EXPANDED_TYPE_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.RESIDENTIAL_TYPE,
    fieldDisplayName: 'Residential Type',
    type: SupportedTypes.SINGLESELECT,
    options: RESIDENTIAL_TYPE_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.RESIDENTIAL_STATUS_TYPE,
    fieldDisplayName: 'Residential Status Type',
    type: SupportedTypes.SINGLESELECT,
    options: RESIDENTIAL_STATUS_TYPE_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.TIME_AT_RESIDENCE_MONTHS,
    fieldDisplayName: 'Time At Residence (months)',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.RENT,
    fieldDisplayName: 'Rent',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.INCLUDE_RENT_IN_CALCULATIONS,
    fieldDisplayName: 'Include rent in calculations',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.ADDRESS_EXPANDED,
    tableDisplayName: 'Address Expanded',
    fieldName: AddressExpandedKey.UNIT_TYPE,
    fieldDisplayName: 'Unit Type',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.AGENT_ADDRESS_EXPANDED,
    tableDisplayName: 'Agent Address Expanded',
    fieldName: AgentAddressExpandedKey.ADDRESS,
    fieldDisplayName: 'Address',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.AGENT_ADDRESS_EXPANDED,
    tableDisplayName: 'Agent Address Expanded',
    fieldName: AgentAddressExpandedKey.CITY,
    fieldDisplayName: 'City',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.AGENT_ADDRESS_EXPANDED,
    tableDisplayName: 'Agent Address Expanded',
    fieldName: AgentAddressExpandedKey.COUNTRY,
    fieldDisplayName: 'Country',
    type: SupportedTypes.SINGLESELECT,
    options: COUNTRY_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.AGENT_ADDRESS_EXPANDED,
    tableDisplayName: 'Agent Address Expanded',
    fieldName: AgentAddressExpandedKey.LAT,
    fieldDisplayName: 'Latitude',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.AGENT_ADDRESS_EXPANDED,
    tableDisplayName: 'Agent Address Expanded',
    fieldName: AgentAddressExpandedKey.LONG,
    fieldDisplayName: 'Longitude',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.AGENT_ADDRESS_EXPANDED,
    tableDisplayName: 'Agent Address Expanded',
    fieldName: AgentAddressExpandedKey.STREET_NUMBER,
    fieldDisplayName: 'Street Number',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.AGENT_ADDRESS_EXPANDED,
    tableDisplayName: 'Agent Address Expanded',
    fieldName: AgentAddressExpandedKey.STREET_NUMBER_SUFFIX,
    fieldDisplayName: 'Street No. Suffix',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.AGENT_ADDRESS_EXPANDED,
    tableDisplayName: 'Agent Address Expanded',
    fieldName: AgentAddressExpandedKey.STREET_NAME,
    fieldDisplayName: 'Street Name',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.AGENT_ADDRESS_EXPANDED,
    tableDisplayName: 'Agent Address Expanded',
    fieldName: AgentAddressExpandedKey.STREET_TYPE,
    fieldDisplayName: 'Street Type',
    type: SupportedTypes.SINGLESELECT,
    options: STREET_TYPE_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.AGENT_ADDRESS_EXPANDED,
    tableDisplayName: 'Agent Address Expanded',
    fieldName: AgentAddressExpandedKey.STREET_DIRECTION,
    fieldDisplayName: 'Street Direction',
    type: SupportedTypes.SINGLESELECT,
    options: STREET_DIRECTION_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.AGENT_ADDRESS_EXPANDED,
    tableDisplayName: 'Agent Address Expanded',
    fieldName: AgentAddressExpandedKey.UNIT,
    fieldDisplayName: 'Unit',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.AGENT_ADDRESS_EXPANDED,
    tableDisplayName: 'Agent Address Expanded',
    fieldName: AgentAddressExpandedKey.POSTAL_CODE,
    fieldDisplayName: 'Postal Code',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.AGENT_ADDRESS_EXPANDED,
    tableDisplayName: 'Agent Address Expanded',
    fieldName: AgentAddressExpandedKey.TYPE,
    fieldDisplayName: 'Type',
    type: SupportedTypes.SINGLESELECT,
    options: AGENT_ADDRESS_EXPANDED_TYPE_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.AGENT_ADDRESS_EXPANDED,
    tableDisplayName: 'Agent Address Expanded',
    fieldName: AgentAddressExpandedKey.IS_CURRENT,
    fieldDisplayName: 'Current',
    type: SupportedTypes.BOOLEAN,
    allowOptionsEditing: false,
  },

  {
    tableName: SupportedCustomEntity.AGENT_ADDRESS_EXPANDED,
    tableDisplayName: 'Agent Address Expanded',
    fieldName: AgentAddressExpandedKey.UNIT_TYPE,
    fieldDisplayName: 'Unit Type',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.BROKER_COMMISSION,
    tableDisplayName: 'Broker Commission',
    fieldName: ApplicationBrokerCommissionKey.NEW_FUNDS,
    fieldDisplayName: 'New Funds',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.BROKER_COMMISSION,
    tableDisplayName: 'Broker Commission',
    fieldName: ApplicationBrokerCommissionKey.BROKER_REDUCTION_FEE,
    fieldDisplayName: 'Broker Reduction Fee',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.BROKER_COMMISSION,
    tableDisplayName: 'Broker Commission',
    fieldName: ApplicationBrokerCommissionKey.FINAL_COMMISSION,
    fieldDisplayName: 'Final Commission',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.BROKER_COMMISSION,
    tableDisplayName: 'Broker Commission',
    fieldName: ApplicationBrokerCommissionKey.LESS_BUYDOWN,
    fieldDisplayName: 'Less Buydown',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.BROKER_COMMISSION,
    tableDisplayName: 'Broker Commission',
    fieldName: ApplicationBrokerCommissionKey.STANDARD_COMMISSION,
    fieldDisplayName: 'Standard Commission',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.BROKER_COMMISSION,
    tableDisplayName: 'Broker Commission',
    fieldName: ApplicationBrokerCommissionKey.TOTAL_FUNDS,
    fieldDisplayName: 'Total Funds',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Applications',
    fieldName: ApplicationKey.FUND_ID,
    fieldDisplayName: 'Fund',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.CLOSING_INSTRUCTION,
    tableDisplayName: 'Closing Instruction',
    fieldName: ClosingInstructionKey.TYPE_OF_CLOSING_INSTRUCTION,
    fieldDisplayName: 'Type of Closing',
    type: SupportedTypes.SINGLESELECT,
    options: TYPE_OF_CLOSING_INSTRUCTION_OPTIONS,
    allowOptionsEditing: false,
  },
  {
    tableName: SupportedCustomEntity.CLOSING_INSTRUCTION,
    tableDisplayName: 'Closing Instruction',
    fieldName: ClosingInstructionKey.REGISTRATION_NUMBER,
    fieldDisplayName: 'Registration Number',
    type: SupportedTypes.TEXT,
  },
  {
    tableName: SupportedCustomEntity.CLOSING_INSTRUCTION,
    tableDisplayName: 'Closing Instruction',
    fieldName: ClosingInstructionKey.COLLATERAL_AMOUNT,
    fieldDisplayName: 'Collateral Amount',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Application',
    fieldName: ApplicationKey.EFFECTIVE_DATE,
    fieldDisplayName: 'Effective Date',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.APPLICATION,
    tableDisplayName: 'Application',
    fieldName: ApplicationKey.SERVICING_TYPE,
    fieldDisplayName: 'Servicing Type',
    type: SupportedTypes.SINGLESELECT,
    options: SERVICING_TYPE_OPTIONS,
    allowOptionsEditing: true,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.NEXT_PAYMENT_DATE,
    fieldDisplayName: 'Next Payment Date',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PAYMENT_AMOUNT,
    fieldDisplayName: 'Payment Amount',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.ORIGINAL_AMORTIZATION,
    fieldDisplayName: 'Original Amortization',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PROPERTY_TAX_AMOUNT,
    fieldDisplayName: 'Property Tax Amount',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PREPAYMENT_OPTIONS,
    fieldDisplayName: 'Prepayment Options',
    type: SupportedTypes.SINGLESELECT,
    options: PREPAYMENT_OPTIONS,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.PROJECTED_BALANCE,
    fieldDisplayName: 'Projected Balance',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.NEW_MATURITY_DATE,
    fieldDisplayName: 'New Maturity Date',
    type: SupportedTypes.DATE,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.TRANSFER_ADJUSTMENT,
    fieldDisplayName: 'Transfer Adjustment',
    type: SupportedTypes.NUMBER,
  },
  {
    tableName: SupportedCustomEntity.MORTGAGE,
    tableDisplayName: 'Mortgages',
    fieldName: MortgageKey.IS_LENDER_LIABILITY,
    fieldDisplayName: 'Is Lender Liability',
    type: SupportedTypes.BOOLEAN,
  },
];
