import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import {
  DocumentPackageServiceType,
  EditableTenantSettings,
  FeatureConfig,
  ScoreProcessingType,
} from '../../environments/environment.base';
import { ResetState, stageString } from './';
import {
  DocumentManagementType,
  OpenDocumentsStrategy,
  OneTimeLinkGenerationStrategy,
  ApplicationStage,
  UserAccount,
  DefaultLTI,
} from './model';
import { AppFeaturesService } from './services/app-features.service';
import * as Sentry from '@sentry/angular-ivy';
import { RouterSelectors } from '../router-state/router.selectors';

export class UpdateTenantSetting {
  static readonly type = '@appFeatureState.UpdateTenantSetting';
  constructor(public key: string, public value: string) {}
}

export class FetchAndSetTenantSettings {
  static readonly type = '@appFeatureState.FetchAndSetTenantSettings';
  constructor(public currentUserAccount: UserAccount) {}
}

export class FeatureEnableGoogleMaps {
  static readonly type = '@appFeaturesState.FeatureEnableGoogleMaps';
  constructor(public enable: boolean) {}
}

export class TenantSettingsInitialized {
  static readonly type = '@appFeaturesState.TenantFeaturesInitialized';
}

export interface AppFeaturesStateModel {
  features: FeatureConfig;
  googleMapsEnabled?: boolean;
}

@State<AppFeaturesStateModel>({
  name: 'appFeatures',
})
@Injectable()
export class AppFeaturesState {
  constructor(public appFeatureService: AppFeaturesService) {}

  @Selector() static createCollectorAccount(state: AppFeaturesStateModel) {
    return state.features?.createCollectorAccount ?? true;
  }

  @Selector() static isDemoMode(state: AppFeaturesStateModel) {
    return state.features?.demo ?? false;
  }

  @Selector() static isFundmoreDMEnabled(state: AppFeaturesStateModel) {
    return state.features?.fundmoreDMEnabled ?? false;
  }

  @Selector() static isAutomaticAssignmentEnabled(state: AppFeaturesStateModel) {
    return state.features?.enableAutomaticAssignment ?? false;
  }

  @Selector() static isAdvancedAutomaticAssignmentEnabled(state: AppFeaturesStateModel) {
    return state.features?.enableAdvancedAutomaticAssignment ?? false;
  }

  @Selector() static isProductDefaultValueOverrideEnabled(state: AppFeaturesStateModel) {
    return state.features?.enableProductDefaultValueOverride ?? false;
  }

  @Selector() static isDelegateRemovalEnabled(state: AppFeaturesStateModel) {
    return state.features?.delegateRemovalEnabled ?? false;
  }

  @Selector() static isIqDocumentRequestsEnabled(state: AppFeaturesStateModel) {
    return state.features?.iqDocumentRequestsEnabled ?? false;
  }

  @Selector() static automaticArchivingSettings(state: AppFeaturesStateModel) {
    return state.features?.automaticArchivingSettings ?? null;
  }

  @Selector() static isExpandedLendingLimitsEnabled(state: AppFeaturesStateModel) {
    return state.features?.enableExpandedLendingLimits ?? false;
  }

  @Selector() static isAutomaticCompoundingEnabled(state: AppFeaturesStateModel) {
    return state.features?.automaticCompoundingEnabled ? true : false;
  }

  @Selector() static automaticCompoundingMappings(state: AppFeaturesStateModel) {
    return state.features?.automaticCompoundingEnabled;
  }

  @Selector() static disabledPropertyDetailsTabs(state: AppFeaturesStateModel) {
    return state.features?.disabledPropertyDetailsTabs ?? undefined;
  }

  @Selector() static enableCloneDeal(state: AppFeaturesStateModel) {
    return state.features?.enableCloneDeal ?? false;
  }

  @Selector() static isOOOReplacementAutomationEnabled(state: AppFeaturesStateModel) {
    return state.features?.enableOOOReplacementAutomation ?? false;
  }

  @Selector() static isFraudIqEnabled(state: AppFeaturesStateModel) {
    return state.features?.enableFraudIq ?? false;
  }

  @Selector() static isAVAEnabled(state: AppFeaturesStateModel) {
    return state.features?.isAVAEnabled ?? false;
  }

  @Selector() static isBlockConditionalApprovalBasedOnDLAEnabled(state: AppFeaturesStateModel) {
    return state.features?.blockConditionalApprovalBasedOnDLA ?? false;
  }

  @Selector() static isBlockConditionalApprovalWhenUnresolvedApprovals(
    state: AppFeaturesStateModel,
  ) {
    return state.features?.blockConditionalApprovalWhenUnresolvedApprovals ?? false;
  }

  @Selector() static isBlockConditionalApprovalWhenUnresolvedRiskFlags(
    state: AppFeaturesStateModel,
  ) {
    return state.features?.blockConditionalApprovalWhenUnresolvedRiskFlags ?? false;
  }

  @Selector() static isBlockSendToServicingWhenUnresolvedApprovals(state: AppFeaturesStateModel) {
    return state.features?.blockSendToServicingWhenUnresolvedApprovals ?? false;
  }

  @Selector() static isFnfEnabled(state: AppFeaturesStateModel) {
    return state.features?.fnfEnabled ?? false;
  }

  @Selector() static isFctEnabled(state: AppFeaturesStateModel) {
    return state.features?.fctEnabled ?? false;
  }

  @Selector() static isAssigneeDLALimitEnabled(state: AppFeaturesStateModel) {
    return state.features?.assigneeDLALimitEnabled ?? false;
  }

  @Selector() static isAutomaticLoanAmountCalculationDisabled(state: AppFeaturesStateModel) {
    return state.features?.automaticLoanAmountCalculationDisabled ?? false;
  }

  @Selector() static aiFeatures(state: AppFeaturesStateModel) {
    return state.features?.aiFeatures;
  }

  @Selector() static commitmentExpiryDateOffset(state: AppFeaturesStateModel) {
    return state.features?.commitmentExpiryDateOffset;
  }

  @Selector() static isAdditionalSecuritiesDisabled(state: AppFeaturesStateModel) {
    return state.features?.additionalSecuritiesDisabled ?? false;
  }

  @Selector() static iadByFpdEnabled(state: AppFeaturesStateModel) {
    return state.features?.iadByFpdEnabled ?? false;
  }

  @Selector() static ltiSettings(state: AppFeaturesStateModel) {
    return { ...DefaultLTI, ...state.features?.ltiSettings };
  }

  /**
   * Defaults to FUNDMORE
   */
  @Selector() static documentManagementType(state: AppFeaturesStateModel) {
    return state.features?.documentManagementType ?? DocumentManagementType.FUNDMORE;
  }

  /**
   * Defaults to NEW_TAB
   */
  @Selector() static dmOpenDocumentsStrategy(state: AppFeaturesStateModel) {
    return state.features?.dmOpenDocumentsStrategy ?? OpenDocumentsStrategy.NEW_TAB;
  }

  /**
   * Defaults to CACHED
   */
  @Selector() static dmOneTimeLinkGenerationStrategy(state: AppFeaturesStateModel) {
    return state.features?.dmOneTimeLinkGenerationStrategy ?? OneTimeLinkGenerationStrategy.CACHED;
  }

  @Selector() static isNotReady() {
    return !environment.production;
  }

  @Selector() static processingType(state: AppFeaturesStateModel) {
    return state.features?.scoreProcessingType;
  }

  @Selector() static googleMapsEnabled(state: AppFeaturesStateModel) {
    return state.googleMapsEnabled ?? false;
  }

  @Selector() static googleMapsEmbedded(state: AppFeaturesStateModel) {
    return state.features?.googleMapsEmbedded ?? true;
  }

  @Selector() static pdfOptions(state: AppFeaturesStateModel) {
    return state.features?.pdfOptions;
  }

  @Selector() static stageString(state: AppFeaturesStateModel) {
    return state.features?.stageString;
  }

  @Selector() static stageConfig(state: AppFeaturesStateModel) {
    return state.features?.stageConfig;
  }

  @Selector() static finalStages(state: AppFeaturesStateModel) {
    return state.features?.finalStages;
  }

  @Selector() static widgetViewPermissions(state: AppFeaturesStateModel) {
    return state.features?.widgetViewPermissions;
  }

  @Selector()
  static isServicingEnabled(state: AppFeaturesStateModel) {
    return state.features?.isServicingEnabled;
  }

  @Selector([AppFeaturesState, RouterSelectors.servicing])
  static tenantStages(
    state: AppFeaturesStateModel,
    isServicingEnabled: boolean | undefined,
  ): ApplicationStage[] | undefined {
    return isServicingEnabled
      ? state.features?.tenantStagesServicing
      : state.features?.tenantStages;
  }

  @Selector([AppFeaturesState])
  static underwritingAndServicingTenantStages(
    state: AppFeaturesStateModel,
  ): ApplicationStage[] | undefined {
    if (!state.features) {
      return;
    }

    return [
      ...(state.features.tenantStagesServicing || []),
      ...(state.features.tenantStages || []),
    ];
  }
  @Selector([AppFeaturesState])
  static tenantStagesServicing(state: AppFeaturesStateModel): ApplicationStage[] | undefined {
    if (!state.features) {
      return;
    }

    return state.features.tenantStagesServicing;
  }

  @Selector([AppFeaturesState.tenantStages, AppFeaturesState.stageString])
  static visibleTenantStagesWithLabels(
    tenantStages: ApplicationStage[] | undefined,
    appFeaturesStageString: Record<string, string> | undefined,
  ): [string, string][] | undefined {
    const allApplicationStages = appFeaturesStageString || stageString;

    return tenantStages
      ?.filter((stage) => AppFeaturesState.isStageVisible(stage))
      .map((stage) => [stage, allApplicationStages?.[stage]]);
  }

  @Selector() static eqMapsEnabled(state: AppFeaturesStateModel) {
    return state.features?.eqMapsEnabled;
  }

  @Selector() static fctIntegrationEnabled(state: AppFeaturesStateModel) {
    return state.features?.fctIntegrationEnabled;
  }

  @Selector() static optionalFields(state: AppFeaturesStateModel) {
    return state.features?.optionalFields;
  }

  @Selector() static partnerLoanOptions(state: AppFeaturesStateModel) {
    return state.features?.partnerLoanOptions;
  }

  @Selector() static restrictCBLiabilityEditing(state: AppFeaturesStateModel) {
    return state.features?.restrictCBLiabilityEditing;
  }

  @Selector() static applyCreditReportsOnIngestion(state: AppFeaturesStateModel) {
    return state.features?.applyCreditReportsOnIngestion;
  }

  @Selector() static dolphinServicingEnabled(state: AppFeaturesStateModel) {
    return state.features?.dolphinServicing;
  }

  @Selector() static dolphinRead(state: AppFeaturesStateModel) {
    return state.features?.dolphinRead;
  }

  @Selector() static intellifiServicingEnabled(state: AppFeaturesStateModel) {
    return state.features?.intellifiServicing;
  }

  @Selector() static isConstructionModuleEnabled(state: AppFeaturesStateModel) {
    return state.features?.enableConstructionModule;
  }

  @Selector() static isFundmoreScoreEnabled(state: AppFeaturesStateModel) {
    return state.features?.enableFundmoreScore;
  }

  @Selector() static is5CsEnabledEnabled(state: AppFeaturesStateModel) {
    return state.features?.enable5Cs;
  }

  @Selector() static flipModuleEnabled(state: AppFeaturesStateModel) {
    return state.features?.flipModuleEnabled;
  }

  @Selector() static isEquifaxEnabled(state: AppFeaturesStateModel) {
    return state.features?.equifaxEnabled ?? false;
  }

  @Selector() static features(state: AppFeaturesStateModel) {
    return state.features;
  }

  @Selector() static hasCustomScore(state: AppFeaturesStateModel) {
    return state.features?.hasCustomScore;
  }

  @Selector() static reportingModule(state: AppFeaturesStateModel) {
    return state.features?.reportingModule;
  }

  @Selector() static documentPackageService(state: AppFeaturesStateModel) {
    return state.features?.documentPackageService;
  }

  @Selector() static processingDates(state: AppFeaturesStateModel) {
    return state.features?.datesProcessingType;
  }

  @Selector() static isClientIdGenerationEnabled(state: AppFeaturesStateModel) {
    return state.features?.clientIdGenerationEnabled ?? false;
  }

  @Selector() static timezone(state: AppFeaturesStateModel) {
    return state.features?.timezone;
  }

  @Selector() static isBlockApplyingProductsWhenWarningsEnabled(state: AppFeaturesStateModel) {
    return state.features?.blockApplyingProductsWhenWarnings ?? false;
  }

  @Selector() static checkProductApplyOnStageTransitionEnabled(state: AppFeaturesStateModel) {
    return state.features?.checkProductApplyOnStageTransitionEnabled ?? false;
  }

  @Selector() static insuranceAutomationEnabled(state: AppFeaturesStateModel) {
    return state.features?.insuranceAutomationEnabled;
  }

  @Selector() static helpRedirectLink(state: AppFeaturesStateModel) {
    return state.features?.helpRedirectLink || 'https://help.fundmore.ai';
  }

  @Selector() static isMemberIdIntegrationEnabled(state: AppFeaturesStateModel) {
    return state.features?.memberIdIntegrationEnabled ?? false;
  }

  @Selector() static isDecisionEngineIntegrationEnabled(state: AppFeaturesStateModel) {
    return state.features?.decisionEngineIntegrationEnabled ?? false;
  }

  @Selector() static isBlocklistScanEnabled(state: AppFeaturesStateModel) {
    return state.features?.enableBlocklistScan ?? false;
  }

  @Selector() static locCalculatorParameters(state: AppFeaturesStateModel) {
    return state.features?.locCalculatorParameters ?? {};
  }

  @Selector() static incrementsParameters(state: AppFeaturesStateModel) {
    return state.features?.incrementsParameters ?? { maxStandardIncrements: 0.6 };
  }

  @Selector() static otherRateConfigurations(state: AppFeaturesStateModel) {
    if (
      !state.features?.otherRateConfigurations ||
      !Object.keys(state.features.otherRateConfigurations)?.length
    ) {
      return { liftForVariableRate: 1 };
    }
    return state.features?.otherRateConfigurations;
  }

  @Selector() static paymentFrequencyByRateType(state: AppFeaturesStateModel) {
    return state.features?.paymentFrequencyByRateType;
  }

  @Selector() static declineApplicationWhenBrokerBlockedOnIngest(state: AppFeaturesStateModel) {
    return state.features?.declineApplicationWhenBrokerBlockedOnIngest;
  }

  @Selector() static purposeCodeByApplicationPurpose(state: AppFeaturesStateModel) {
    return state.features?.purposeCodeByApplicationPurpose;
  }

  @Selector() static decisionEngineSubmitStageBasedBehaviour(state: AppFeaturesStateModel) {
    return state.features?.decisionEngineSubmitStageBasedBehaviour;
  }

  @Selector() static isBlanketEnabled(state: AppFeaturesStateModel) {
    return state.features?.enableBlanket ?? false;
  }

  @Selector() static isPrimaryResidenceEnabled(state: AppFeaturesStateModel) {
    return state.features?.isPrimaryResidenceEnabled ?? false;
  }

  @Selector() static isProductStatusEnabled(state: AppFeaturesStateModel) {
    return state.features?.enableProductStatus ?? false;
  }

  @Selector() static blockApplicationActionsStage(state: AppFeaturesStateModel) {
    return state.features?.blockApplicationActionsStage;
  }

  @Selector() static incrementsEnabled(state: AppFeaturesStateModel) {
    return state.features?.incrementsEnabled;
  }

  @Selector() static incrementsAutomationEnabled(state: AppFeaturesStateModel) {
    return state.features?.incrementsAutomationEnabled;
  }

  @Selector() static decrementsEnabled(state: AppFeaturesStateModel) {
    return state.features?.decrementsEnabled;
  }

  @Selector() static discretionEnabled(state: AppFeaturesStateModel) {
    return state.features?.discretionEnabled;
  }

  @Selector() static signPaymentChangeFormTaskAutomationEnabled(state: AppFeaturesStateModel) {
    return state.features?.signPaymentChangeFormTaskAutomationEnabled;
  }

  @Selector() static reissueCOBTaskAutomationEnabled(state: AppFeaturesStateModel) {
    return state.features?.reissueCOBTaskAutomationEnabled;
  }

  @Selector() static blockMovingWithoutApproveStartingWithStage(state: AppFeaturesStateModel) {
    return state.features?.blockMovingWithoutApproveStartingWithStage;
  }

  @Selector() static excludeExtraPaymentInTerm(state: AppFeaturesStateModel) {
    return state.features?.excludeExtraPaymentInTerm;
  }

  @Selector() static preventManualAgentCreation(state: AppFeaturesStateModel) {
    return state.features?.preventManualAgentCreation ?? false;
  }

  @Selector() static fctMmsIntegrationEnabled(state: AppFeaturesStateModel) {
    return state.features?.fctMmsIntegrationEnabled ?? false;
  }

  @Selector() static isBlendedRateOnMCUEnabled(state: AppFeaturesStateModel) {
    return state.features?.isBlendedRateOnMCUEnabled ?? false;
  }

  @Selector() static takeFullFirstPayment(state: AppFeaturesStateModel) {
    return state.features?.takeFullFirstPayment ?? false;
  }

  @Selector() static getApplicationV2Enabled(state: AppFeaturesStateModel) {
    return state.features?.getApplicationV2Enabled ?? false;
  }

  @Selector() static overrideRateType(state: AppFeaturesStateModel) {
    return state.features?.overrideRateType;
  }

  @Selector() static forceDateRecalculationParamsOnIngest(state: AppFeaturesStateModel) {
    return state.features?.forceDateRecalculationParamsOnIngest;
  }

  static isStageVisible(stage: ApplicationStage | string) {
    return createSelector(
      [AppFeaturesState.tenantStages],
      (tenantStages: ApplicationStage[] | undefined) => {
        if (tenantStages) {
          return tenantStages.some((a) => a === stage);
        }

        // if tenant specific state collection missing, show all states
        return true;
      },
    );
  }

  static isUnderwritingAndServicingStageVisible(stage: ApplicationStage | string) {
    return createSelector(
      [AppFeaturesState.underwritingAndServicingTenantStages],
      (tenantStages: ApplicationStage[] | undefined) => {
        if (tenantStages) {
          return tenantStages.some((a) => a === stage);
        }

        // if tenant specific state collection missing, show all states
        return true;
      },
    );
  }

  @Action(ResetState)
  resetState(ctx: StateContext<AppFeaturesStateModel>) {
    ctx.setState({
      features: {
        scoreProcessingType: ScoreProcessingType.Lender,
        documentPackageService: DocumentPackageServiceType.Default,
      } satisfies FeatureConfig,
    });
  }

  @Action(FeatureEnableGoogleMaps)
  enableGoogleMaps(ctx: StateContext<AppFeaturesStateModel>, action: FeatureEnableGoogleMaps) {
    ctx.patchState({ googleMapsEnabled: action.enable });
  }

  @Action(UpdateTenantSetting) public updateTenantSetting(
    ctx: StateContext<AppFeaturesStateModel>,
    action: UpdateTenantSetting,
  ) {
    const { key, value } = action;

    if (!Object.values<string>(EditableTenantSettings).includes(key)) {
      return;
    }

    return this.appFeatureService.updateTenantSetting(key, value).pipe(
      tap(() => {
        const state = ctx.getState();
        const stringBoolean = value === 'true' || value === 'false';
        let keyValue = stringBoolean ? value === 'true' : value;

        if (jsonSettings.includes(key as EditableTenantSettings)) {
          try {
            keyValue = value ? JSON.parse(value) : value;
          } catch (e) {
            console.error(`Error parsing ${key}`, e);
          }
        }

        ctx.patchState({
          features: { ...state.features, [key]: keyValue },
        });
      }),
    );
  }

  @Action(FetchAndSetTenantSettings) public fetchAndSetTenantSettings(
    ctx: StateContext<AppFeaturesStateModel>,
    { currentUserAccount }: FetchAndSetTenantSettings,
  ) {
    const envConfig = this.appFeatureService.getFeatures(currentUserAccount);
    return this.appFeatureService.getTenant().pipe(
      tap(([tenant]) => {
        const { features } = ctx.getState();

        Sentry.setContext('tenant', {
          code: tenant?.code,
          name: tenant?.name,
        });

        const tenantSettings = this.appFeatureService.getFeaturesFromTenantSettings(
          currentUserAccount,
          tenant.settings,
        );

        const updatedFeatures: FeatureConfig = {
          ...envConfig,
          ...features,
          ...tenantSettings,
        };
        ctx.patchState({ features: updatedFeatures });
        ctx.dispatch(new TenantSettingsInitialized());
      }),
    );
  }
}

const jsonSettings = [
  EditableTenantSettings.automaticArchivingSettings,
  EditableTenantSettings.locCalculatorParameters,
  EditableTenantSettings.incrementsParameters,
  EditableTenantSettings.otherRateConfigurations,
  EditableTenantSettings.ltiSettings,
];
