import { IndustrySectorType, OccupationType } from './applicant';
import { IncomePeriod } from './enums';
import { IncomeType } from './income';
import { CustomEQOccupationType } from './job';

export interface ApplicantOtherIncome {
  applicantId: string;
  otherIncomeId?: string;
}

export interface ApplicationOtherIncome {
  id: string;
  isCurrent?: boolean | null;
  unableToVerify?: boolean | null;
  applicationId: string;
  startDate: string | null;
  endDate: string | null;
  createdAt?: string;
  updatedAt?: string;
  occupation?: OccupationType | CustomEQOccupationType | undefined | null;
  ApplicantOtherIncomes: ApplicantOtherIncome[];
  externalSequenceNumber?: number | null;
  timeAtIndustryMonths?: number | null;
  timeAtJobMonths?: number | null;
  industrySector?: IndustrySectorType | null;
  type: IncomeType | null;
  description: string | null;
  period: IncomePeriod | null;
  amount: number | null;
}

export enum OtherIncomeKey {
  TYPE = 'type',
  PERIOD = 'period',
  DESCRIPTION = 'description',
  AMOUNT = 'amount',
}
