/* eslint-disable max-len */
import { Action, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';
import { Injectable, NgZone } from '@angular/core';
import {
  EventMessageType,
  PubNubEvent,
  ApplicationUserEvent,
  EzidoxEventType,
  NotificationBody,
} from './model';
import { AddNotification, FundmoreNotification, ResetState } from '../shared';
import { PubNubService } from './pubnub.service';
import { AuthState } from '../auth/auth.state';
import {
  getChatChannel,
  getUserChannel,
  getApplicationChannel,
  getApplicationPresenceChannel,
} from './channelHelpers';
import { MortgageApplicationState } from '../portal/mortgage-application.state';
import { PipelineState } from '../features/pipeline';
import {
  EventAddMessage,
  EventDeleteMessage,
  EventEditMessage,
} from '../features/application/application-chat/application-chat.actions';
import { MortgageInsuranceApplicationQuotesGet } from '../features/application/boxes/mortgage-insurance/mortgage-insurance.actions';
import { SetApplicationWarnings } from '../portal/states/application.state';
import {
  FetchApplicationConditionDocumentComments,
  FetchApplicationConditionDocuments,
} from '../portal/states/application-condition-documents.state.actions';
import {
  ApplicationStatusTypeRecord,
  ApplicationWarningHumanizedKey,
  DeclineApplicationErrorMessageRecord,
  EzidoxApplicationStatusEnumRecord,
  MMSNotificationTypeRecord,
  PriorityTypeRecord,
  WarningActionsHumanized,
} from '../shared/enum-records';
import { FetchApplicationApprovals } from '../features/application/approvals/application-approval.actions';
import {
  ApplicationApprovalAction,
  ApplicationConditionDocumentStatus,
  ApplicationCreateProductsState,
  ApplicationCreditCardFulfillState,
  ApplicationStage,
  DecisionEngineStatus,
  DeclineApplicationError,
  DocumentManagementType,
  EzidoxApplicationStatusEnumMap,
  InsuranceStatus,
  MMSMessageCounters,
  PipelineApplication,
  RateChangeNotificationRateType,
  RateChangeNotificationTrend,
} from '@fundmoreai/models';
import {
  FetchApplications,
  FetchUnassignedDealsCount,
  UpdatePendingApprovalsList,
} from '../features/pipeline/pipeline.actions';
import { FetchNotes } from '../features/application/notes/notes.actions';
import {
  CloseDocumentRequestDialog,
  RefreshApplication,
  RefreshApplicationRestrictedStatus,
  RefreshApplicationRestrictedStatusAndCreditOffers,
  RefreshDecisionEngine,
} from '../portal/mortgage-application.actions';
import { GetTasks } from '../features/tasks/tasks.actions';
import { FetchHistoryLogs } from '../features/application/history-logs/history-logs.actions';
import { SectionOpenedState } from '../core/section-open.state';
import { Subject, catchError, debounceTime, delay, of, retry, switchMap, tap, timer } from 'rxjs';
import { FetchStatus } from '../features/application/widgets/conditions/dm-document-status/dm-documents-status.state';
import { AppFeaturesState } from '../shared/app-features.state';
import { EzidoxRequestStatusReset, EzidoxUrlReset } from '../portal/states/ezidox.state.actions';
import {
  FetchPrivateDMDocumentComments,
  FetchPrivateDMDocuments,
} from '../portal/states/actions/private-dm-documents.action';
import { ErrorDetailsDialogComponent } from '../features/application/sidebar/dialogs/error-details-dialog/error-details-dialog.component';
import { EqClearLoanDecisionErrors, FetchEqMessages } from '../portal/eq-core.state.actions';
import { AddNotifications } from '../shared/state.model';
import { RoleState } from '../features/shared/user';
import {
  FetchAppraisalData,
  PropertyAppraisalState,
} from '../portal/property-appraisals/property-appraisals.state';
import { AuthStateModel } from '../auth/auth.actions';
import { ChatMessage } from '../features/chat/chat-message.model';
import { RiskFlagSeverityRecord } from '../features/manager-portal/risk-flag-templates/risk-flag-template.enum';
import { FetchApplicationRiskFlags } from '../features/application/application-risk-flags/application-risk-flags.actions';
import { FetchDocumentRequests } from '../features/application/widgets/conditions/document-request.actions';
import { FetchRequestedDocumentNotes } from '../features/application/widgets/conditions/requested-documents-notes/requested-documents-note.actions';
import { FetchApplicantsCreditCardOffers } from '../portal/applicants.actions';
import { FetchFinancialLiabilities } from '../features/application/widgets/credit/financial-liability.actions';
import { MCUCreateProductsPostback, MCUCreditCardFulfillPostback } from '../portal/mcu.state';
import { SetMMSNotificationCounters } from '../portal/closing-instruction/fct-mms/fct-mms.state';
import { FetchClosingInstructions } from '../portal/closing-instruction/closing-instruction.state';
import {
  GetMMSDocuments,
  SetMMSDocumentUploadStatus,
} from '../features/application/widgets/loan-details/closing-instruction-mms/fct-mms-documents/fct-mms-documents.action';
import { GetAvaChat } from '../features/application/ava/ava.state';
import { ActivatedRoute } from '@angular/router';
import {
  AddApplicationEvent,
  HandleApplicationUserEvent,
  AddChatEvent,
  HandleChatEvent,
  AddUserEvent,
  SubscribeApplication,
  SubscribeChannels,
  PresenceOnChannel,
  PresenceOnApplication,
  PresenceEventOnApplication,
  UnsubscribeApplication,
  SubscribeUser,
  FetchPersistedMessages,
  LoadPersistedMessages,
  DeletePersistedMessages,
  AddMessageAction,
  SubscribeChat,
  UnsubscribeChat,
  UnsubscribeAll,
  SetupPubNub,
  RefreshPubNubConfig,
} from './pubnub.state.actions';

export interface PubNubModel {
  isPubNubConfigured: boolean;
  retryCount?: number;
  applicationEvents: PubNubEvent[];
  chatEvents: PubNubEvent[];
  userEvents: PubNubEvent[];
  applicationChannels: Set<string>;
  presenceChannels: Set<string>;
  chatChannels: Set<string>;
  userChannels: Set<string>;
  persistedMessagesLoaded: boolean;
  presence: { [key: string]: string[] };
}

const pubNubDefaults: PubNubModel = {
  isPubNubConfigured: false,
  retryCount: 0,
  applicationEvents: [],
  chatEvents: [],
  userEvents: [],
  applicationChannels: new Set(),
  presenceChannels: new Set(),
  chatChannels: new Set(),
  userChannels: new Set(),
  persistedMessagesLoaded: false,
  presence: {},
};

@State<PubNubModel>({
  name: 'pubnub',
  defaults: pubNubDefaults,
})
@Injectable()
export class PubNubState {
  private fetchPipelineApplication = new Subject<boolean>();
  constructor(
    private pubNubService: PubNubService,
    private store: Store,
    private zone: NgZone,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
  ) {
    this.fetchPipelineApplication
      .asObservable()
      .pipe(debounceTime(3000))
      .subscribe(() => {
        this.store.dispatch(new FetchApplications());
      });
  }

  @Selector() static applicationEvents(state: PubNubModel) {
    return state.applicationEvents;
  }

  @Selector() static chatEvents(state: PubNubModel) {
    return state.chatEvents;
  }

  @Selector() static userEvents(state: PubNubModel) {
    return state.userEvents;
  }

  static applicationPresence(applicationId: string) {
    return createSelector(
      [PubNubState, AuthState],
      (state: PubNubModel, authState: AuthStateModel) => {
        const tenantCode = authState.currentUserAccount?.tenant?.code;

        if (!tenantCode || !applicationId) {
          return;
        }

        const channel = getApplicationPresenceChannel(tenantCode, applicationId);
        // remove current user from presence
        return state.presence[channel]?.filter((u) => u !== authState.currentUserAccount?.user?.id);
      },
    );
  }

  @Action(AddApplicationEvent)
  applicationEvent(ctx: StateContext<PubNubModel>, action: AddApplicationEvent) {
    const state = ctx.getState();

    if (this.eventIsDuplicate(action.event, ctx)) {
      return;
    }

    const handleApplicationUserEvent: ApplicationUserEvent = {
      event: action.event,
      applications: this.store.selectSnapshot(PipelineState.applications),
      currentApplicationId: this.store.selectSnapshot(MortgageApplicationState.application)?.id,
      callback: (id: string) => {
        this.handleEventForMessageType(action.event, id);
      },
    };
    ctx.dispatch(new HandleApplicationUserEvent(handleApplicationUserEvent));
    ctx.patchState({ applicationEvents: [...state.applicationEvents, action.event] });
  }

  @Action(AddChatEvent)
  chatEvent(ctx: StateContext<PubNubModel>, action: AddChatEvent) {
    const state = ctx.getState();
    ctx.dispatch(new HandleChatEvent(action.event));
    ctx.patchState({ chatEvents: [...state.applicationEvents, action.event] });
  }

  @Action(AddUserEvent)
  userEvent(ctx: StateContext<PubNubModel>, action: AddUserEvent) {
    const state = ctx.getState();

    if (this.eventIsDuplicate(action.event, ctx)) {
      return;
    }

    const handleApplicationUserEvent = {
      event: action.event,
      applications: this.store.selectSnapshot(PipelineState.applications),
      currentApplicationId: this.store.selectSnapshot(MortgageApplicationState.application)?.id,
      callback: (id: string) => {
        this.handleEventForMessageType(action.event, id);
      },
    };
    ctx.dispatch(new HandleApplicationUserEvent(handleApplicationUserEvent));

    ctx.patchState({ userEvents: [...state.applicationEvents, action.event] });
  }

  @Action(SubscribeApplication) subscribeApplication(
    ctx: StateContext<PubNubModel>,
    { applicationId }: SubscribeApplication,
  ) {
    const currentUser = this.store.selectSnapshot(AuthState.currentUser);
    const tenantCode = currentUser?.tenant?.code;

    if (!tenantCode || !applicationId) {
      return;
    }

    const channel = getApplicationChannel(tenantCode, applicationId);
    const state = ctx.getState();

    ctx.patchState({ applicationChannels: state.applicationChannels.add(channel) });

    ctx.dispatch(new SubscribeChannels([channel]));
  }

  @Action(PresenceOnChannel) async presenceOnChannel(
    ctx: StateContext<PubNubModel>,
    { channel }: PresenceOnChannel,
  ) {
    return of(null).pipe(
      switchMap(async () => {
        const state = ctx.getState();
        if (!state.isPubNubConfigured) {
          throw 'PubNub is not yet configured.';
        }
        const hereNow = await this.pubNubService.presence(channel);
        return hereNow;
      }),
      retry({
        count: 10,
        delay: (error, attempt) => {
          console.warn(
            `Presence on channel ${channel} failed. Retrying... attempt: ${attempt}, ${error}`,
          );
          return timer((attempt + 2) * 1000);
        },
      }),
      catchError(() => {
        return of(null);
      }),
      tap((hereNow) => {
        if (!hereNow) {
          return;
        }
        const state = ctx.getState();
        ctx.patchState({
          presence: {
            ...state.presence,
            [channel]: [...new Set(hereNow.channels[channel]?.occupants?.map((o) => o.uuid))],
          },
        });
      }),
    );
  }

  @Action(PresenceOnApplication) async presenceOnApplication(
    ctx: StateContext<PubNubModel>,
    { applicationId }: PresenceOnApplication,
  ) {
    const currentUser = this.store.selectSnapshot(AuthState.currentUser);
    const tenantCode = currentUser?.tenant?.code;

    if (!tenantCode || !applicationId) {
      return;
    }

    const channel = getApplicationPresenceChannel(tenantCode, applicationId);
    const state = ctx.getState();
    ctx.patchState({ presenceChannels: state.presenceChannels.add(channel) });

    ctx.dispatch([new SubscribeChannels([channel], true), new PresenceOnChannel(channel)]);
  }

  @Action(PresenceEventOnApplication) async presenceEventsOnApplication(
    ctx: StateContext<PubNubModel>,
    { channel, userId, action }: PresenceEventOnApplication,
  ) {
    const state = ctx.getState();
    switch (action) {
      case 'join': {
        ctx.patchState({
          presence: {
            ...state.presence,
            [channel]: [...new Set([...(state.presence[channel] ?? []), userId])],
          },
        });
        break;
      }
      case 'leave': {
        ctx.patchState({
          presence: {
            ...state.presence,
            [channel]: state.presence[channel]?.filter((u) => u !== userId),
          },
        });
        break;
      }
    }
  }

  @Action(UnsubscribeApplication) unsubscribeApplication(
    ctx: StateContext<PubNubModel>,
    { applicationId }: UnsubscribeApplication,
  ) {
    const state = ctx.getState();
    const currentUser = this.store.selectSnapshot(AuthState.currentUser);
    const tenantCode = currentUser?.tenant?.code;

    if (!tenantCode || !applicationId) {
      return;
    }

    const channel = getApplicationChannel(tenantCode, applicationId);
    const channelPresence = getApplicationPresenceChannel(tenantCode, applicationId);
    const applicationChannels = new Set(state.applicationChannels);
    const presenceChannels = new Set(state.applicationChannels);

    applicationChannels.delete(channel);
    presenceChannels.delete(channelPresence);

    ctx.patchState({ applicationChannels, presenceChannels });

    this.pubNubService.destroy([channel, channelPresence]);
  }

  @Action(SubscribeUser) subscribeUser(ctx: StateContext<PubNubModel>) {
    const currentUser = this.store.selectSnapshot(AuthState.currentUser);

    const tenantCode = currentUser?.tenant?.code;
    const userId = currentUser?.user?.id;

    if (!tenantCode || !userId) {
      return;
    }

    const channel = getUserChannel(tenantCode, userId);
    const state = ctx.getState();

    ctx.patchState({ userChannels: state.userChannels.add(channel) });

    ctx.dispatch(new SubscribeChannels([channel]));
  }

  @Action(FetchPersistedMessages) fetchPersistedMessages(ctx: StateContext<PubNubModel>) {
    const state = ctx.getState();
    // make sure we will load persisted messages only once, and always after the user is logged in
    // and pipeline applications loaded for title and description
    if (state.persistedMessagesLoaded) {
      return;
    }
    const currentUser = this.store.selectSnapshot(AuthState.currentUser);

    const tenantCode = currentUser?.tenant?.code;
    const userId = currentUser?.user?.id;

    if (!tenantCode || !userId) {
      return;
    }
    ctx.patchState({ persistedMessagesLoaded: true });

    const channel = getUserChannel(tenantCode, userId);

    this.pubNubService.fetchMessages(channel, 100);
  }

  @Action(LoadPersistedMessages) loadPersistedMessages(
    ctx: StateContext<PubNubModel>,
    action: LoadPersistedMessages,
  ) {
    const currentUserId = this.store.selectSnapshot(AuthState.currentUser)?.user?.id;
    const notifications: FundmoreNotification[] = [];
    action.events.forEach((persistedEvent) => {
      const documentBody = persistedEvent.event.message.body;
      const eventType = persistedEvent.event.message.type;
      const readActions = persistedEvent.event.actions?.read;
      const unreadActions = persistedEvent.event.actions?.unread;

      const { message, title, visibleNotification } = this.generateApplicationUserEventMessage(
        eventType,
        documentBody,
        currentUserId,
      );
      if (!visibleNotification) {
        return;
      }
      const handleApplicationUserEvent = {
        event: persistedEvent.event,
        applications: this.store.selectSnapshot(PipelineState.applications),
        currentApplicationId: undefined,
        callback: () => {},
      };
      const notification = this.generateFundmoreNotification(
        handleApplicationUserEvent,
        message,
        title,
      );
      if (notification) {
        const readActionsKeys = readActions ? Object.keys(readActions) : undefined;
        const readAt =
          readActionsKeys && readActionsKeys.length > 0
            ? // get the latest action
              new Date(readActionsKeys[readActionsKeys.length - 1])
            : undefined;

        const unreadActionsKeys = unreadActions ? Object.keys(unreadActions) : undefined;
        const unreadAt =
          unreadActionsKeys && unreadActionsKeys.length > 0
            ? // get the latest action
              new Date(unreadActionsKeys[unreadActionsKeys.length - 1])
            : undefined;

        notification.readAt = readAt;
        if (unreadAt && (!readAt || unreadAt > readAt)) {
          notification.readAt = undefined;
        }
        notification.seen = !!notification.readAt;
        notifications.push(notification);
      }
    });
    this.store.dispatch(new AddNotifications(notifications));
  }

  @Action(DeletePersistedMessages) deletePersistedMessages(
    ctx: StateContext<PubNubModel>,
    action: DeletePersistedMessages,
  ) {
    const currentUser = this.store.selectSnapshot(AuthState.currentUser);

    const tenantCode = currentUser?.tenant?.code;
    const userId = currentUser?.user?.id;

    if (!tenantCode || !userId) {
      return;
    }
    const channel = getUserChannel(tenantCode, userId);

    this.pubNubService.deleteMessages({
      channel,
      end: action.timetoken ? `${action.timetoken}` : undefined,
      start: action.timetoken ? `${+action.timetoken - 1}` : undefined,
    });
  }

  @Action(AddMessageAction) addPersistedMessage(
    ctx: StateContext<PubNubModel>,
    action: AddMessageAction,
  ) {
    const currentUser = this.store.selectSnapshot(AuthState.currentUser);

    const tenantCode = currentUser?.tenant?.code;
    const userId = currentUser?.user?.id;

    if (!tenantCode || !userId) {
      return;
    }
    const channel = getUserChannel(tenantCode, userId);

    this.pubNubService.addMessageAction({
      channel,
      timetoken: action.timetoken,
      action: action.action,
    });
  }

  @Action(SubscribeChat) subscribeChat(ctx: StateContext<PubNubModel>, action: SubscribeChat) {
    const currentUser = this.store.selectSnapshot(AuthState.currentUser);

    const tenantCode = currentUser?.tenant?.code;
    const appId = action.applicationId;

    if (!tenantCode || !appId) {
      return;
    }

    const channel = getChatChannel(tenantCode, appId);
    const state = ctx.getState();

    ctx.patchState({ chatChannels: state.chatChannels.add(channel) });

    ctx.dispatch(new SubscribeChannels([channel]));
  }

  @Action(UnsubscribeChat) unsubscribeChat(ctx: StateContext<PubNubModel>) {
    const state = ctx.getState();

    const chatChannels = Array.from(state.chatChannels);

    if (chatChannels.length === 0) {
      return;
    }

    ctx.patchState({ chatChannels: new Set() });

    this.pubNubService.destroy(chatChannels);
  }

  @Action(UnsubscribeAll) unsubscribeAll(ctx: StateContext<PubNubModel>) {
    this.pubNubService.destroyAll();
    ctx.patchState({
      chatChannels: new Set(),
      userChannels: new Set(),
      applicationChannels: new Set(),
      presenceChannels: new Set(),
    });
  }

  @Action(SetupPubNub) setupPubNub(ctx: StateContext<PubNubModel>, action: SetupPubNub) {
    return this.pubNubService.setup(action.userId).pipe(
      tap(() => {
        const state = ctx.getState();
        ctx.patchState({ isPubNubConfigured: true });
        ctx.dispatch([
          new SubscribeChannels([
            ...state.chatChannels,
            ...state.applicationChannels,
            ...state.userChannels,
          ]),
          new SubscribeChannels([...state.presenceChannels], true),
          new FetchPersistedMessages(),
        ]);
      }),
    );
  }

  @Action(RefreshPubNubConfig, { cancelUncompleted: true }) refreshPubNubAuth(
    ctx: StateContext<PubNubModel>,
  ) {
    let retryCount = ctx.getState().retryCount ?? 0;
    if (retryCount > 4) {
      retryCount = 0;
    }
    ctx.patchState({ isPubNubConfigured: false, retryCount: retryCount + 1 });

    return this.pubNubService
      .refresh()
      .pipe(
        // prevent endless loops on bad access token
        delay(2000 + 3000 * retryCount),
        tap(() => {
          const state = ctx.getState();
          ctx.patchState({ isPubNubConfigured: true });
          ctx.dispatch([
            new SubscribeChannels([
              ...state.chatChannels,
              ...state.applicationChannels,
              ...state.userChannels,
            ]),
            new SubscribeChannels([...state.presenceChannels], true),
          ]);
        }),
      )
      .subscribe();
  }

  @Action(ResetState) reset(ctx: StateContext<PubNubModel>) {
    ctx.dispatch(new UnsubscribeAll());
    ctx.setState(pubNubDefaults);
  }

  @Action(HandleChatEvent) handleChatEvent(
    ctx: StateContext<PubNubState>,
    action: HandleChatEvent,
  ) {
    const currentApplicationId = this.store.selectSnapshot(
      MortgageApplicationState.application,
    )?.id;
    const currentUserId = this.store.selectSnapshot(AuthState.currentUser)?.user?.id;
    if (
      !action.event ||
      !currentUserId ||
      !currentApplicationId ||
      !(action.event.message.body.applicationId === currentApplicationId)
    ) {
      return;
    }

    if (action.event.message.type === EventMessageType.CHAT_MESSAGE) {
      this.store.dispatch(new EventAddMessage(action.event.message.body as ChatMessage));
    }

    if (action.event.message.type === EventMessageType.CHAT_EDIT) {
      this.store.dispatch(new EventEditMessage(action.event.message.body as ChatMessage));
    }

    if (action.event.message.type === EventMessageType.CHAT_DELETE) {
      this.store.dispatch(new EventDeleteMessage(action.event.message.body as ChatMessage));
    }
  }

  @Action(HandleApplicationUserEvent) handle(
    ctx: StateContext<PubNubModel>,
    action: HandleApplicationUserEvent,
  ) {
    const currentUserId = this.store.selectSnapshot(AuthState.currentUser)?.user?.id;
    const documentBody = action.applicationUserEvent.event.message.body;
    const eventType = action.applicationUserEvent.event.message.type;
    const applicationId = documentBody.id;
    if (!applicationId) {
      return;
    }

    const { message, title, visibleNotification, handleCallback } =
      this.generateApplicationUserEventMessage(eventType, documentBody, currentUserId);

    if (visibleNotification) {
      const notification = this.generateFundmoreNotification(
        action.applicationUserEvent,
        message,
        title,
      );
      notification && this.store.dispatch(new AddNotification(notification));
    }

    if (!handleCallback) {
      return;
    }
    if (applicationId && applicationId === action.applicationUserEvent.currentApplicationId) {
      action.applicationUserEvent.callback(action.applicationUserEvent.currentApplicationId);
    } else {
      action.applicationUserEvent.callback();
    }
  }

  private userIsUnassigned(applicationId?: string): boolean {
    if (this.store.selectSnapshot(SectionOpenedState.isOpen('pipeline'))) {
      return false;
    }

    if (!applicationId) {
      return false;
    }

    const application = this.store.selectSnapshot(MortgageApplicationState.application);

    if (!(application?.id === applicationId)) {
      return false;
    }

    const currentUser = this.store.selectSnapshot(AuthState.currentUser);

    if (!currentUser?.user?.id) {
      return false;
    }

    return !application.ApplicationAssignedUsers?.some((a) => a.userId === currentUser.user.id);
  }

  private generateApplicationUserEventMessage(
    eventType: EventMessageType,
    documentBody: NotificationBody,
    currentUserId: string,
  ) {
    let message: string | undefined = undefined;
    let visibleNotification = true;
    let handleCallback = true;
    let title: string | undefined;

    let applicationName: string | undefined = documentBody.applicationName;
    if (!applicationName) {
      const application: PipelineApplication | undefined = this.store
        .selectSnapshot((state) => state)
        ?.pipeline?.applications?.find((a: PipelineApplication) => a.id === documentBody.id);
      applicationName = application?.primaryApplicantName;
    }

    applicationName = `<b>${applicationName}</b>`;

    switch (eventType) {
      case EventMessageType.CONDITION_DOCUMENT_UPLOADED: {
        title = $localize`Documents Uploaded`;

        message = $localize`Documents have been uploaded on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;

        visibleNotification = documentBody.userId !== currentUserId;
        handleCallback = documentBody.userId !== currentUserId;
        break;
      }
      case EventMessageType.CONDITION_DOCUMENT_REVIEWED: {
        message = documentBody.document_name + $localize` has been `;

        switch (documentBody.status) {
          case ApplicationConditionDocumentStatus.ACCEPTED:
            title = $localize`Document Accepted`;

            message += $localize` accepted on `;

            break;
          case ApplicationConditionDocumentStatus.REJECTED:
            title = $localize`Document Rejected`;

            message += $localize` rejected on `;

            break;
          default:
            title = $localize`Document Reviewed`;

            message += $localize` reviewed on `;
        }

        message += (applicationName ?? $localize`an`) + $localize` application.`;

        visibleNotification = documentBody.userId !== currentUserId;
        handleCallback = documentBody.userId !== currentUserId;
        break;
      }
      case EventMessageType.APPLICATION_CONDITION_DOCUMENT_COMMENT_ADDED: {
        title = $localize`New Comment on Document`;

        message = `<b>${documentBody.userName}</b>` + $localize` added a new comment to document`;
        message += documentBody.document_name + $localize` on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;

        // add notification if the current user is not the one who added the comment
        visibleNotification = documentBody.userId !== currentUserId;
        // handle callback if the current user is not the one who added the comment
        handleCallback = documentBody.userId !== currentUserId;
        break;
      }
      case EventMessageType.APPROVAL_REQUESTED: {
        title = $localize`Approval Requested`;

        message = `<b>${documentBody.applicationApproval.actorFullName}</b>` + ' ';
        message += $localize`requested your approval`;
        if (!documentBody.applicationApproval.actorSameAsRequester) {
          message += ' ' + $localize`on behalf of` + ' ';
          message += `<b>${documentBody.applicationApproval.requesterFullName}</b>`;
        }
        message += ': ';
        message += documentBody.applicationApproval.approvalTypeName
          ? documentBody.applicationApproval.approvalTypeName
          : '';
        message += $localize` on ` + (applicationName ?? $localize`an`) + $localize` application.`;
        break;
      }
      case EventMessageType.APPROVAL_REQUEST_CANCELED: {
        title = $localize`Request Canceled`;

        message = `<b>${documentBody.applicationApproval.actorFullName}</b>` + ' ';
        message += $localize`canceled a request for your approval`;
        if (!documentBody.applicationApproval.actorSameAsRequester) {
          message += ' ' + $localize`on behalf of` + ' ';
          message += `<b>${documentBody.applicationApproval.requesterFullName}</b>`;
        }
        message += ': ';
        message += documentBody.applicationApproval.approvalTypeName
          ? documentBody.applicationApproval.approvalTypeName
          : '';
        message += $localize` on ` + (applicationName ?? $localize`an`) + $localize` application.`;
        break;
      }

      case EventMessageType.APPROVAL_RECOMMENDED_FOR_APPROVAL: {
        title = $localize`Request recommended for approval`;

        message = `<b>${documentBody.applicationApproval.actorFullName}</b>` + ' ';
        message += $localize`recommended your request for approval`;
        if (!documentBody.applicationApproval.actorSameAsRequester) {
          message += ' ' + $localize`on behalf of` + ' ';
          message += `<b>${documentBody.applicationApproval.requesterFullName}</b>`;
        }
        message += ': ';
        message += documentBody.applicationApproval.approvalTypeName
          ? documentBody.applicationApproval.approvalTypeName
          : '';
        message += $localize` on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;
        break;
      }

      case EventMessageType.APPROVAL_REQUEST_REVIEWED: {
        message = `<b>${documentBody.applicationApproval.actorFullName}<b>` + ' ';

        switch (documentBody.applicationApproval.action) {
          case ApplicationApprovalAction.APPROVE:
            title = $localize`Request Approved`;

            message += $localize`approved` + ' ';
            break;
          case ApplicationApprovalAction.DECLINE:
            title = $localize`Request Declined`;

            message += $localize`declined` + ' ';
            break;
          case ApplicationApprovalAction.REQUEST_CHANGES:
            title = $localize`Changes Requested `;

            message += $localize`requested changes on` + ' ';
            break;
        }

        message += $localize`your request for approval`;
        if (!documentBody.applicationApproval.actorSameAsReviewer) {
          message += ' ' + $localize`on behalf of` + ' ';
          message += `<b>${documentBody.applicationApproval.approverFullName}</b>`;
        }
        message += ': ';
        message += documentBody.applicationApproval.approvalTypeName
          ? documentBody.applicationApproval.approvalTypeName
          : '';
        message += $localize` on ` + (applicationName ?? $localize`an`) + $localize` application.`;

        break;
      }
      case EventMessageType.BULK_TASKS_ASSIGNED: {
        title = $localize`Tasks Assigned`;

        message = $localize`Tasks`;
        message += ` "${documentBody.taskNames.join(', ')}" `;
        message += $localize`have been assigned to you on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;
        visibleNotification = true;
        handleCallback = true;
        break;
      }
      case EventMessageType.TASK_ASSIGNED: {
        title = $localize`Task Assigned`;

        message = $localize`A new task`;
        message += ` "${documentBody.taskName}" `;
        message += $localize`has been assigned to you on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;
        visibleNotification = true;
        handleCallback = true;
        break;
      }
      case EventMessageType.TASK_CREATED: {
        visibleNotification = false;
        handleCallback = documentBody.userId !== currentUserId;
        break;
      }
      case EventMessageType.TASK_UPDATED: {
        title = $localize`Task Updated`;

        message = $localize`Your assigned task`;
        message += ` "${documentBody.taskName}" `;
        message += $localize`has been updated on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;
        visibleNotification = true;
        handleCallback = true;
        break;
      }
      case EventMessageType.TASK_DELETED: {
        title = $localize`Task Deleted`;

        message = $localize`The task`;
        message += ` "${documentBody.taskName}" `;
        message += $localize`has been deleted on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;
        visibleNotification = documentBody.userId !== currentUserId;
        handleCallback = documentBody.userId !== currentUserId;
        break;
      }
      case EventMessageType.TASK_MARK_AS_COMPLETED: {
        title = $localize`Task Completed`;

        message = $localize`The task`;
        message += ` "${documentBody.taskName}" `;
        message += $localize`has been marked as completed on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;
        visibleNotification = documentBody.userId !== currentUserId;
        handleCallback = documentBody.userId !== currentUserId;
        break;
      }
      case EventMessageType.TASK_MARK_AS_NOT_COMPLETED: {
        visibleNotification = false;
        handleCallback = documentBody.userId !== currentUserId;
        break;
      }
      case EventMessageType.DEFAULT_TASKS_CREATED: {
        visibleNotification = false;
        handleCallback = true;
        break;
      }
      case EventMessageType.EQB_LOAN_DECISION_FAILURE: {
        title = $localize`EQB Loan Decision Failed`;

        message = $localize`Loan decision failed while trying to send to P+ on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;

        break;
      }
      case EventMessageType.EQB_LOAN_DECISION_SUCCESS: {
        title = $localize`EQB Loan Decision Successful`;

        message = $localize`Loan decision successful on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;

        break;
      }
      case EventMessageType.EQB_LOAN_UPDATE_FAILURE_SYNC: {
        title = $localize`EQB Loan Update Failed`;

        message = $localize`Loan update failed while trying to send to P+ on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;

        break;
      }
      case EventMessageType.EQB_LOAN_UPDATE_FAILURE_ASYNC: {
        title = $localize`EQB Loan Update Failed`;

        message = $localize`Loan update failed while trying to send to P+ on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;
        break;
      }
      case EventMessageType.EQB_LOAN_UPDATE_SUCCESS_SYNC: {
        title = $localize`EQB Loan Update Successful`;

        message = $localize`Loan update successful on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;
        break;
      }
      case EventMessageType.EQB_LOAN_UPDATE_SUCCESS_ASYNC: {
        title = $localize`EQB Loan Update Successful`;

        message = $localize`Loan update successful on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;
        break;
      }
      // Ezidox
      case EventMessageType.EZIDOX_APPLICANT_DELETED: {
        title = $localize`Document Request Removed`;
        message = $localize`Document request for` + ' ';
        message += documentBody.stakeholderNames.join(', ');
        message += ' ' + $localize`has been deleted on` + ' ';
        message += (applicationName ?? $localize`an`) + ' ' + $localize`application` + '.';

        visibleNotification = true;
        handleCallback = true;
        break;
      }
      case EventMessageType.EZIDOX_APPLICANT_DELETE_FAILED: {
        title = $localize`Failed: Remove Document Request`;
        message = $localize`There was an error while removing the document request for` + ' ';
        message += documentBody.stakeholderNames.join(', ');
        message += ' ' + $localize`on` + ' ';
        message += (applicationName ?? $localize`an`) + ' ' + $localize`application` + '.';

        visibleNotification = true;
        handleCallback = true;
        break;
      }
      case EventMessageType.EZIDOX_APPLICANT_UPDATED: {
        title = $localize`Document Request Updated`;
        message = $localize`Document request for` + ' ';
        message += documentBody.stakeholderNames.join(', ');
        message += ' ' + $localize`has been updated on` + ' ';
        message += (applicationName ?? $localize`an`) + ' ' + $localize`application` + '.';

        visibleNotification = true;
        handleCallback = true;
        break;
      }
      case EventMessageType.EZIDOX_APPLICANT_UPDATE_FAILED: {
        title = $localize`Failed: Update Document Request`;
        message = $localize`There was an error while updating the document request for` + ' ';
        message += documentBody.stakeholderNames.join(', ');
        message += ' ' + $localize`on` + ' ';
        message += (applicationName ?? $localize`an`) + ' ' + $localize`application`;
        message += documentBody.message ? ': ' + documentBody.message : +'.';

        visibleNotification = true;
        handleCallback = true;
        break;
      }
      case EventMessageType.EZIDOX_APPLICATION_CREATED: {
        title = $localize`Document Request Created`;
        message = $localize`Document request for` + ' ';
        message += documentBody.stakeholderNames.join(', ');
        message += ' ' + $localize`has been created on` + ' ';
        message += (applicationName ?? $localize`an`) + ' ' + $localize`application` + '.';
        if (documentBody.closingDateSetFromDocsDueDate) {
          message += $localize` The closing date was set 7 days from the documents due date.`;
        }

        visibleNotification = true;
        handleCallback = true;
        break;
      }
      case EventMessageType.EZIDOX_APPLICATION_CREATE_FAILED: {
        title = $localize`Failed: Create Document Request`;
        message = $localize`There was an error while creating the document request on` + ' ';
        message += (applicationName ?? $localize`an`) + ' ' + $localize`application `;

        if (documentBody.error) {
          message += ': ' + documentBody.error;
        }

        visibleNotification = true;
        handleCallback = true;
        break;
      }
      case EventMessageType.EZIDOX_APPLICATION_DECLINE_FAILED: {
        title = $localize`Failed: Decline Document Request`;

        message = $localize`Application failed to decline in DM on `;
        (applicationName ?? $localize`an`) + ' ' + $localize`application` + '.';

        break;
      }
      case EventMessageType.EZIDOX_APPLICATION_UPDATED: {
        if (documentBody.stakeholderNames && documentBody.stakeholderNames.length) {
          title = $localize`Document Request Created`;
          message = $localize`Document request for` + ' ';
          message += documentBody.stakeholderNames.join(', ');
          message += ' ' + $localize`has been created on` + ' ';
          message += (applicationName ?? $localize`an`) + ' ' + $localize`application` + '.';
        } else {
          title = $localize`Document request updated`;
          message = $localize`Document request has been updated on` + ' ';
          message += (applicationName ?? $localize`an`) + ' ' + $localize`application` + '.';
        }

        visibleNotification = true;
        handleCallback = true;
        break;
      }
      case EventMessageType.EZIDOX_APPLICATION_DATES_UPDATED: {
        title = $localize`Document request updated`;
        message = $localize`Document request dates have been updated on` + ' ';
        message += (applicationName ?? $localize`an`) + ' ' + $localize`application` + '.';
        if (documentBody.closingDateSetFromDocsDueDate) {
          message += $localize` The closing date was set 7 days from the documents due date.`;
        }

        visibleNotification = true;
        handleCallback = true;
        break;
      }
      case EventMessageType.EZIDOX_APPLICATION_UPDATE_FAILED: {
        title = $localize`Failed: Create Document Request`;

        message = $localize`There was an error while updating the document request on` + ' ';
        message += (applicationName ?? $localize`an`) + ' ' + $localize`application` + '.';

        visibleNotification = true;
        handleCallback = true;
        break;
      }
      case EventMessageType.EZIDOX_APPLICATION_NOTE: {
        switch (documentBody.ezidoxHookEvent) {
          case EzidoxEventType.APPLICATION_NOTE_CREATED:
            title = $localize`Note Created`;

            message = $localize`A note was added in FundMore DM on `;
            break;
          case EzidoxEventType.APPLICATION_NOTE_REMOVED:
            title = $localize`Note Removed`;

            message = $localize`A note was removed in FundMore DM on `;
            break;
          case EzidoxEventType.APPLICATION_NOTE_UPDATED:
            title = $localize`Note Updated`;

            message = $localize`A note was updated in FundMore DM on `;
            break;
        }
        message += (applicationName ?? $localize`an`) + ' ' + $localize`application` + '.';
        break;
      }
      case EventMessageType.EZIDOX_APPLICATION_STATUS: {
        title = $localize`FundMore DM Status Update`;

        const statusKey = EzidoxApplicationStatusEnumMap[documentBody.ezidoxStatusId];
        const status = statusKey
          ? EzidoxApplicationStatusEnumRecord[statusKey]
          : documentBody.ezidoxStatusString;
        message = $localize`Status was updated in FundMore DM to: ` + status + $localize` on `;
        message += (applicationName ?? $localize`an`) + ' ' + $localize`application` + '.';
        break;
      }
      case EventMessageType.EZIDOX_ATTACHMENT_UPLOADED: {
        title = $localize`Document Attached`;
        if (documentBody.document_name) {
          message = $localize`Document` + ' ' + documentBody.document_name + ' ';
        } else {
          message = $localize`A document` + ' ';
        }
        message += $localize`has been attached on` + ' ';
        message += (applicationName ?? $localize`an`) + ' ' + $localize`application` + '.';

        visibleNotification = true;
        handleCallback = true;
        break;
      }
      case EventMessageType.EZIDOX_DOCUMENT_REVIEWED: {
        message = documentBody.document_name + ' ';
        switch (documentBody.ezidoxHookEvent) {
          case EzidoxEventType.DOCUMENT_ACCEPTED:
            title = $localize`Document Accepted`;
            message += $localize`has been accepted` + ' ';
            break;
          case EzidoxEventType.DOCUMENT_IRRELEVANT:
            title = $localize`Document Not Relevant`;
            message += $localize`has been marked as irrelevant` + ' ';
            break;
          case EzidoxEventType.DOCUMENT_REJECTED:
            title = $localize`Document Rejected`;
            message += $localize`has been rejected` + ' ';
            break;
          case EzidoxEventType.DOCUMENT_COMMENT:
            title = $localize`New Comment on Document`;
            message = `<b>${documentBody.authorName}</b>` + ' ';
            message += $localize`left a new comment to document` + ' ';
            message += documentBody.document_name + ' ';
            break;
        }
        message += $localize`on` + ' ';
        message += (applicationName ?? $localize`an`) + ' ' + $localize`application` + '.';
        break;
      }
      case EventMessageType.EZIDOX_DOCUMENT_UPLOADED: {
        if (documentBody.multiple) {
          title = $localize`Documents Uploaded`;

          message = $localize`New documents have been uploaded on` + ' ';
        } else {
          title = $localize`Document Uploaded`;

          message = $localize`A new document has been uploaded on` + ' ';
        }

        message += (applicationName ?? $localize`an`) + ' ' + $localize`application` + '.';

        visibleNotification = documentBody.userId === currentUserId;
        handleCallback = documentBody.userId === currentUserId;
        break;
      }
      case EventMessageType.EZIDOX_DOCUMENT_UPLOADED_WEBHOOK: {
        title = $localize`Document Uploaded`;

        message = $localize`A new` + ' ';
        message += documentBody.document_name + ' ';
        message += $localize`document has been uploaded on` + ' ';

        message += (applicationName ?? $localize`an`) + ' ' + $localize`application` + '.';
        visibleNotification = true;
        handleCallback = true;
        break;
      }
      case EventMessageType.EZIDOX_SHARE_FAILED: {
        title = $localize`Failed: Share Document Request`;
        message = $localize`There was an error while sharing the document request on` + ' ';
        message += (applicationName ?? $localize`an`) + ' ' + $localize`application` + '.';

        visibleNotification = true;
        handleCallback = true;
        break;
      }
      case EventMessageType.APPLICATION_WARNINGS_UPDATED: {
        title = $localize`Warnings Changed`;

        message = WarningActionsHumanized[documentBody.warningAction];
        message += `: ` + ApplicationWarningHumanizedKey[documentBody.updatedWarning];
        message += $localize` on `;
        message += (applicationName ?? $localize`an`) + ' ' + $localize`application` + '.';

        break;
      }
      // events with no notification but with callbacks
      case EventMessageType.DOCUMENT_UPLOADED:
      case EventMessageType.APPLICATION_CREATED: {
        visibleNotification = false;
        handleCallback = true;
        break;
      }
      case EventMessageType.INSURANCE_QUOTE_UPDATED: {
        title = documentBody.insuranceType ?? $localize`Mortgage Insurance`;
        title += $localize` Quote Updated`;

        message = documentBody.insuranceType ?? $localize`Mortgage insurance`;
        message += $localize` quote #` + documentBody.insuranceQuoteNumber;
        message += $localize` has been updated on `;
        message += (applicationName ?? $localize`an`) + ' ' + $localize`application` + '.';

        break;
      }
      case EventMessageType.APPLICATION_UPDATE_PUSHED: {
        message = documentBody.message;
        // show UI notification if we have a message on the payload
        visibleNotification = !!message;
        handleCallback = true;
        break;
      }
      case EventMessageType.APPLICATION_STAGE_CHANGED: {
        title = $localize`Application Stage Change`;

        message = applicationName ?? '';
        message += $localize` application has been moved from `;
        message += documentBody.previousStage + $localize` to ` + documentBody.currentStage;

        visibleNotification = true;
        handleCallback = true;

        break;
      }
      case EventMessageType.APPLICATION_CREATE_MEMBERS: {
        if (documentBody.error) {
          title = $localize`Create Memberships Failed`;
          message = 'Create memberships postback failed with error: ' + documentBody.error;
          visibleNotification = true;
          break;
        }

        title = $localize`Memberships Created`;
        message = $localize`Memberships have been created and `;
        message +=
          documentBody.jointMemberships === true
            ? $localize`multiple joint memberships have been found on  `
            : documentBody.jointMemberships === false
            ? $localize`one joint membership has been found on  `
            : $localize`no joint membership has been found on  `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;
        visibleNotification = true;
        handleCallback = true;

        break;
      }
      case EventMessageType.APPLICATION_RETRIEVE_JOINT_MEMBERSHIPS: {
        title = $localize`Joint Memberships Retrieved`;

        message = $localize`Joint memberships have been retrieved and `;
        message +=
          documentBody.jointMemberships === true
            ? $localize`multiple joint memberships have been found on  `
            : documentBody.jointMemberships === false
            ? $localize`one joint membership has been found on  `
            : $localize`no joint membership has been found on  `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;
        visibleNotification = true;
        handleCallback = true;

        break;
      }
      case EventMessageType.APPLICATION_PRIORITY_UPDATED: {
        title = $localize`Application Priority Changed`;
        const onApplication = applicationName ?? $localize`an`;
        const priority = documentBody.priority
          ? PriorityTypeRecord[documentBody.priority]
          : $localize`None`;
        message = $localize`Priority has been updated to ${priority} on ${onApplication} application.`;

        visibleNotification = true;
        handleCallback = true;

        break;
      }
      case EventMessageType.CHAT_MENTION: {
        title = $localize`Mentioned in Chat`;

        message = `<b>${documentBody.authorName}</b>`;
        message += $localize` has mentioned you in the chat on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;

        visibleNotification = !!message;
        break;
      }
      case EventMessageType.APPLICATION_ASSIGNED: {
        title = $localize`Application Assigned`;

        message = $localize`You have been assigned as `;
        message += `${this.store.selectSnapshot(RoleState.role(documentBody.roleId))?.name ?? ''} `;
        message += $localize`on ` + (applicationName ?? $localize`an`) + $localize` application.`;

        visibleNotification = true;
        handleCallback = true;

        break;
      }
      case EventMessageType.DELEGATE_SET: {
        title = $localize`Delegate Assignment`;

        message = $localize`You have been assigned as delegate for `;
        message += `<b>${documentBody.userName}</b>`;

        visibleNotification = true;

        break;
      }
      case EventMessageType.USER_DETAILS_CHANGED: {
        title = $localize`User Details Updated`;
        message = $localize`<b>${documentBody.userName}</b> has edited your user details.`;
        visibleNotification = !!message;
        break;
      }
      case EventMessageType.FCT_APPLICATION_UPDATED: {
        title = $localize`FCT Appraisals`;
        message = $localize`FCT Appraisal request (FCT Reference ID ${documentBody.fctReferenceId}) has been updated.`;
        visibleNotification = !!message;
        break;
      }
      case EventMessageType.FCT_NOTES_UPDATED: {
        title = $localize`FCT Appraisals`;
        message = $localize`FCT Appraisal request notes (FCT Reference ID ${documentBody.fctReferenceId}) have been updated.`;
        visibleNotification = !!message;
        break;
      }
      case EventMessageType.FCT_APPLICATION_STATUS_UPDATED: {
        visibleNotification = false;
        break;
      }
      case EventMessageType.APPLICATION_STATUS_INTEGRATION_CHANGED: {
        title =
          $localize`Application ` + documentBody.applicationStatus
            ? ApplicationStatusTypeRecord[documentBody.applicationStatus]
            : $localize`Status Changed`;

        message = applicationName ?? $localize`An`;
        message += $localize` application`;
        message += documentBody.applicationStatus
          ? ` has been marked as ${ApplicationStatusTypeRecord[
              documentBody.applicationStatus
            ].toLowerCase()}.`
          : $localize` had its status changed.`;

        break;
      }
      case EventMessageType.APPLICATION_DELETED: {
        title = $localize`Application Deleted`;
        message = applicationName ?? $localize`An`;
        message += $localize` application has been deleted`;
        break;
      }
      case EventMessageType.APPLICATION_ARCHIVED: {
        title = $localize`Application Archived`;
        message = applicationName ?? $localize`An`;
        message += $localize` application has been archived`;
        break;
      }
      case EventMessageType.APPLICATION_UNARCHIVED: {
        title = $localize`Application Unarchived`;
        message = applicationName ?? $localize`An`;
        message += $localize` application has been unarchived`;
        break;
      }
      case EventMessageType.APPLICATION_LOCKED: {
        title = $localize`Application Locked`;
        message = applicationName ?? $localize`An`;
        message += $localize` application has been locked`;
        break;
      }
      case EventMessageType.APPLICATION_UNLOCKED: {
        title = $localize`Application Unlocked`;
        message = applicationName ?? $localize`An`;
        message += $localize` application has been unlocked`;
        break;
      }
      case EventMessageType.APPLICATION_DUPLICATE: {
        title = $localize`Duplicate Found`;
        message = $localize`Similar application `;
        message += applicationName ? `${applicationName} ` : '';
        message += $localize`to the one you have assigned `;
        message += documentBody.originalApplicationName
          ? `${documentBody.originalApplicationName} `
          : '';
        message += $localize`has been created`;
        break;
      }

      // Application Risk Flags
      case EventMessageType.APPLICATION_RISK_FLAG_CREATED: {
        if (!documentBody.applicationRiskFlag) {
          visibleNotification = false;
          handleCallback = false;
          break;
        }

        title = $localize`Application Risk Flag Created`;
        message = `Name: ${documentBody.applicationRiskFlag.type}, ${$localize`Severity:`} ${
          RiskFlagSeverityRecord[documentBody.applicationRiskFlag.severity]
        }`;

        visibleNotification = documentBody.userId !== currentUserId;
        handleCallback = documentBody.userId !== currentUserId;
        break;
      }
      case EventMessageType.APPLICATION_RISK_FLAG_DELETED: {
        if (!documentBody.applicationRiskFlag) {
          visibleNotification = false;
          handleCallback = false;
          break;
        }
        title = $localize`Application Risk Flag Deleted`;
        message = `Name: ${documentBody.applicationRiskFlag.type}, ${$localize`Severity:`} ${
          RiskFlagSeverityRecord[documentBody.applicationRiskFlag.severity]
        }`;

        visibleNotification = documentBody.userId !== currentUserId;
        handleCallback = documentBody.userId !== currentUserId;
        break;
      }
      case EventMessageType.APPLICATION_RISK_FLAG_MESSAGE: {
        if (!documentBody.applicationRiskFlag) {
          visibleNotification = false;
          handleCallback = false;
          break;
        }
        title = $localize`New message on Application Risk Flag`;
        message = `Name: ${documentBody.applicationRiskFlag.type}, ${$localize`Severity:`} ${
          RiskFlagSeverityRecord[documentBody.applicationRiskFlag.severity]
        }`;

        visibleNotification = documentBody.userId !== currentUserId;
        handleCallback = documentBody.userId !== currentUserId;
        break;
      }
      case EventMessageType.APPLICATION_RISK_FLAG_MESSAGE_DELETED: {
        if (!documentBody.applicationRiskFlag) {
          visibleNotification = false;
          handleCallback = false;
          break;
        }
        title = $localize`Message deleted on Application Risk Flag`;
        message = `Name: ${documentBody.applicationRiskFlag.type}, ${$localize`Severity:`} ${
          RiskFlagSeverityRecord[documentBody.applicationRiskFlag.severity]
        }`;

        visibleNotification = documentBody.userId !== currentUserId;
        handleCallback = documentBody.userId !== currentUserId;
        break;
      }
      case EventMessageType.APPLICATION_RISK_FLAG_RESOLVED: {
        if (!documentBody.applicationRiskFlag) {
          visibleNotification = false;
          handleCallback = false;
          break;
        }
        title = $localize`Application Risk Flag Resolved`;
        message = `Name: ${documentBody.applicationRiskFlag.type}, ${$localize`Severity:`} ${
          RiskFlagSeverityRecord[documentBody.applicationRiskFlag.severity]
        }`;

        visibleNotification = documentBody.userId !== currentUserId;
        handleCallback = documentBody.userId !== currentUserId;
        break;
      }

      case EventMessageType.APPLICANT_CONNECTED_PARTY_CHECK_FINISHED:
      case EventMessageType.IDS_BLOCKLIST_SCAN_COMPLETED: {
        visibleNotification = false;
        handleCallback = true;
        break;
      }

      //Applicant Member Options
      case EventMessageType.APPLICANT_MEMBER_OPTIONS_REFRESH: {
        if (!documentBody.applicantMemberOptions) {
          visibleNotification = false;
          break;
        }
        title = $localize`Applicant Member Options Refreshed`;
        message = `${$localize`New existing member options available for`} ${
          documentBody.applicantMemberOptions.name
        } ${documentBody.applicantMemberOptions.surname}`;

        visibleNotification = documentBody.userId !== currentUserId;
        handleCallback = documentBody.userId !== currentUserId;
        break;
      }

      case EventMessageType.APPLICANT_MEMBER_TYPE_UPDATED: {
        visibleNotification = false;
        handleCallback = true;
        break;
      }

      case EventMessageType.APPLICANT_MARKED_AS_MEMBER: {
        visibleNotification = false;
        handleCallback = documentBody.userId !== currentUserId;
        break;
      }

      case EventMessageType.APPLICANT_MEMBER_ACCOUNTS_UPDATED: {
        visibleNotification = false;
        handleCallback = documentBody.userId !== currentUserId;
        break;
      }

      // Decision Engine
      case EventMessageType.APPLICATION_DECISION_ENGINE_POLLING_EXECUTED: {
        switch (documentBody.applicationDecisionEngine?.status) {
          case DecisionEngineStatus.APPROVED:
            title = $localize`Provenir approved application`;
            message = `${$localize`Provenir approved`} <b>${
              documentBody.applicationName
            }</b> ${$localize`application`}`;
            break;
          case DecisionEngineStatus.DECLINED:
            title = $localize`Provenir declined application`;
            message = `${$localize`Provenir declined`} <b>${
              documentBody.applicationName
            }</b> ${$localize`application`}`;
            break;
          case DecisionEngineStatus.MANUAL_REVIEW:
          case DecisionEngineStatus.ERROR:
            title = $localize`Provenir recommended manual review`;
            message = `${$localize`Provenir recommended manual review on`} <b>${
              documentBody.applicationName
            }</b> ${$localize`application`}`;
            break;
        }

        visibleNotification = documentBody.userId !== currentUserId;
        break;
      }

      // Document Requests
      case EventMessageType.DOCUMENT_REQUEST_CREATED: {
        title = $localize`Document Request Created`;

        message = $localize`A new document request has been created on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;

        visibleNotification = true;
        handleCallback = true;
        break;
      }

      case EventMessageType.DOCUMENT_SIGNATURE_REQUEST_CREATED: {
        title = $localize`Signature Request Created`;

        message = $localize`A new signature request has been created on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;

        visibleNotification = true;
        handleCallback = true;
        break;
      }

      case EventMessageType.DOCUMENT_SIGNATURE_REQUEST_UPDATED: {
        visibleNotification = false;
        handleCallback = documentBody.userId !== currentUserId;
        break;
      }

      case EventMessageType.DOCUMENT_REQUEST_DELETED: {
        title = $localize`Document Request(s) Deleted`;

        message = $localize`One or more document requests have been deleted on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;

        visibleNotification = true;
        handleCallback = true;
        break;
      }

      case EventMessageType.DOCUMENT_REQUEST_NOTE_CREATED: {
        title = $localize`Document Request Note Created`;

        message = $localize`A new document request note has been created on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;

        visibleNotification = true;
        handleCallback = true;

        break;
      }

      case EventMessageType.PRODUCT_RATE_CHANGED: {
        title = $localize`Product Changed`;

        const rateChange = documentBody.rateChange;
        message = $localize`Product ${
          rateChange?.productName ? '<b>' + rateChange.productName + '</b> ' : ''
        }has been changed on ${applicationName} application.\n`;

        if (rateChange && rateChange.trend !== RateChangeNotificationTrend.INCONCLUSIVE) {
          if (rateChange.rateType === RateChangeNotificationRateType.FIXED) {
            message += $localize`Fixed rate`;
          } else if (rateChange.rateType === RateChangeNotificationRateType.VARIABLE) {
            message += $localize`Variable rate`;
          }

          if (rateChange.trend === RateChangeNotificationTrend.UP) {
            message += $localize` increased: <b>${rateChange.oldRate}%</b> → <b>${rateChange.newRate}%</b>`;
          } else if (rateChange.trend === RateChangeNotificationTrend.DOWN) {
            message += $localize` decreased: <b>${rateChange.oldRate}%</b> → <b>${rateChange.newRate}%</b>`;
          } else if (rateChange.trend === RateChangeNotificationTrend.CREATED) {
            message += $localize` was set at <b>${rateChange.newRate}%</b>`;
            if (
              rateChange.newModifier &&
              rateChange.modifierTrend === RateChangeNotificationTrend.CREATED
            ) {
              message += $localize` with a modifier of <b>${rateChange.newModifier}%</b>`;
            }
          } else if (rateChange.trend === RateChangeNotificationTrend.SAME) {
            message += $localize` remained the same. `;
            if (rateChange.modifierTrend === RateChangeNotificationTrend.UP) {
              message += $localize`Modifier increased: <b>${rateChange.oldModifier}%</b> → <b>${rateChange.newModifier}%</b>`;
            } else if (rateChange.modifierTrend === RateChangeNotificationTrend.DOWN) {
              message += $localize`Modifier decreased: <b>${rateChange.oldModifier}%</b> → <b>${rateChange.newModifier}%</b>`;
            } else if (rateChange.modifierTrend === RateChangeNotificationTrend.CREATED) {
              message += $localize`Modifier set at <b>${rateChange.newModifier}%</b>`;
            }
          }

          if (rateChange.terms) {
            message += $localize` for term(s) <b>${rateChange.terms}</b> (months).`;
          }
        }

        break;
      }

      case EventMessageType.APPLICATION_CREATE_PRODUCTS: {
        title = $localize`Product Upload`;

        const createProductsState = documentBody.createProductsResponse?.createProductsState;

        if (createProductsState === ApplicationCreateProductsState.SUCCESS) {
          message = $localize`Products have been successfully uploaded for `;
          message += (applicationName ?? $localize`an`) + $localize` application.`;
        }

        if (createProductsState === ApplicationCreateProductsState.FAILED) {
          message = $localize`Products upload failed for `;
          message += (applicationName ?? $localize`an`) + $localize` application.`;
        }

        if (createProductsState === ApplicationCreateProductsState.PENDING) {
          message = $localize`Products upload is pending for `;
          message += (applicationName ?? $localize`an`) + $localize` application.`;
        }

        break;
      }

      case EventMessageType.APPLICATION_CREDIT_CARD_FULFILL: {
        title = $localize`Credit Card Fulfillment`;

        const creditCardFulfillState =
          documentBody.creditCardFulfillResponse?.creditCardFulfillState;

        if (creditCardFulfillState === ApplicationCreditCardFulfillState.SUCCESS) {
          message = $localize`Credit cards have been fulfilled for `;
          message += (applicationName ?? $localize`an`) + $localize` application.`;
        }

        if (creditCardFulfillState === ApplicationCreditCardFulfillState.FAILED) {
          message = $localize`Credit cards fulfillment failed for `;
          message += (applicationName ?? $localize`an`) + $localize` application.`;
        }

        if (creditCardFulfillState === ApplicationCreditCardFulfillState.PENDING) {
          message = $localize`Credit cards fulfillment is pending for `;
          message += (applicationName ?? $localize`an`) + $localize` application.`;
        }

        break;
      }

      case EventMessageType.RATE_HOLD_EXPIRED: {
        title = $localize`Rate Hold Expired`;

        message = $localize`Rate hold has expired on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;

        break;
      }

      case EventMessageType.APPLICATION_NOTE_LIST_REFRESH: {
        visibleNotification = false;
        handleCallback = documentBody.userId !== currentUserId;
        break;
      }

      case EventMessageType.MMS_REQUEST_UPDATED: {
        title = $localize`MMS Request Updated`;

        const mmsNotification = documentBody.mmsNotification;
        if (mmsNotification) {
          message = applicationName ? `Application ${applicationName}, ` : '';
          message += mmsNotification.fctURN ? `FCT URN: ${mmsNotification.fctURN} ` : '';

          if (mmsNotification.successful?.length > 0) {
            message += `\n${mmsNotification.successful
              .map((n) => MMSNotificationTypeRecord[n])
              .join(', ')} ${
              mmsNotification.successful?.length > 1 ? $localize`have` : $localize`has`
            } been updated.`;
          }

          if (mmsNotification.failed?.length > 0) {
            message += `\n${mmsNotification.failed
              .map((n) => MMSNotificationTypeRecord[n])
              .join(', ')} ${
              mmsNotification.failed?.length > 1 ? $localize`have` : $localize`has`
            } failed to update.`;
          }
        }

        break;
      }

      case EventMessageType.MMS_DOCUMENTS_UPLOADED: {
        const { mmsDocumentUploadFailed } = documentBody?.mmsDocumentUploadNotification ?? {};
        if (mmsDocumentUploadFailed && mmsDocumentUploadFailed.length > 0) {
          title = $localize`MMS Request Updated`;
          message = applicationName ? `Application ${applicationName}, ` : '';
          message += `Failed to upload ${mmsDocumentUploadFailed
            .map((d) => d.documentName)
            .join(', ')}.`;
        } else {
          visibleNotification = false;
        }

        break;
      }

      case EventMessageType.NEW_AVA_MESSAGE: {
        title = $localize`New AVA Message`;

        message = $localize`There is a new message from AVA on `;
        message += (applicationName ?? $localize`an`) + $localize` application.`;

        const activeApplication = this.store.selectSnapshot(MortgageApplicationState.application);

        visibleNotification = documentBody.id !== activeApplication.id;
        handleCallback = true;

        break;
      }

      case EventMessageType.APPLICATION_INCREMENTS_UPDATED: {
        visibleNotification = false;
        handleCallback = true;

        break;
      }

      default: {
        // ignore notifications that are not explicity handled
        console.warn('Unhandled notification received!', eventType);

        handleCallback = false;
        visibleNotification = false;

        break;
      }
    }

    // un assigned users only get callbacks, no toasts. Only short circuit if
    // we are sure user is unassigned
    if (this.userIsUnassigned(documentBody.applicationId ?? documentBody.id)) {
      return { handleCallback, visibleNotification: false };
    }

    return { message, title, visibleNotification, handleCallback };
  }

  @Action(SubscribeChannels) subscribe(ctx: StateContext<PubNubModel>, action: SubscribeChannels) {
    const state = ctx.getState();
    if (!state.isPubNubConfigured) {
      console.warn(
        $localize`Subscription failed. PubNub is not yet configured. The failed subscription will be retried when PubNub finishes configuring`,
      );
      return;
    }
    return this.pubNubService.subscribe(action.channels, action.presence);
  }

  handleEventForMessageType(event: PubNubEvent, applicationId: string) {
    const documentBody = event.message.body;
    switch (event.message.type) {
      case EventMessageType.EZIDOX_APPLICATION_DATES_UPDATED:
        if (!applicationId) {
          return;
        }
        break;
      case EventMessageType.EZIDOX_APPLICATION_CREATED:
      case EventMessageType.EZIDOX_APPLICATION_UPDATED:
        if (!applicationId) {
          return;
        }
        this.store.dispatch([
          new EzidoxUrlReset(),
          new EzidoxRequestStatusReset(),
          new RefreshApplication(applicationId),
        ]);
        break;

      case EventMessageType.EZIDOX_APPLICATION_DECLINE_FAILED:
        if (!applicationId) {
          return;
        }

        if (event.message.body.message === DeclineApplicationError.NOT_LODGED) {
          this.zone.run(() => {
            this.dialog.open(ErrorDetailsDialogComponent, {
              data: {
                message: DeclineApplicationErrorMessageRecord[DeclineApplicationError.NOT_LODGED],
              },
              width: '400px',
              height: '300px',
            });
          });
        }
        break;

      case EventMessageType.EZIDOX_APPLICATION_CREATE_FAILED:
      case EventMessageType.EZIDOX_APPLICATION_UPDATE_FAILED:
        if (!applicationId) {
          return;
        }
        this.store.dispatch([
          new CloseDocumentRequestDialog(),
          new EzidoxRequestStatusReset(),
          new RefreshApplication(applicationId),
        ]);
        break;

      case EventMessageType.APPLICANT_CONNECTED_PARTY_CHECK_FINISHED:
      case EventMessageType.IDS_BLOCKLIST_SCAN_COMPLETED:
      case EventMessageType.EZIDOX_APPLICATION_STATUS:
      case EventMessageType.EZIDOX_APPLICATION_NOTE:
      case EventMessageType.EZIDOX_APPLICANT_UPDATED:
      case EventMessageType.EZIDOX_APPLICANT_UPDATE_FAILED:
      case EventMessageType.EZIDOX_ATTACHMENT_UPLOADED:
      case EventMessageType.APPLICATION_UPDATE_PUSHED:
        if (!applicationId) {
          return;
        }
        this.store.dispatch(new RefreshApplication(applicationId));
        break;
      case EventMessageType.EZIDOX_DOCUMENT_UPLOADED_WEBHOOK:
      case EventMessageType.EZIDOX_DOCUMENT_UPLOADED:
      case EventMessageType.EZIDOX_DOCUMENT_REVIEWED:
      case EventMessageType.DOCUMENT_UPLOADED: {
        if (!applicationId) {
          return;
        }

        const documentManagementType = this.store.selectSnapshot(
          AppFeaturesState.documentManagementType,
        );

        if (documentManagementType === DocumentManagementType.EZIDOX) {
          this.store.dispatch(new FetchStatus(applicationId));
        }
        break;
      }
      case EventMessageType.INSURANCE_QUOTE_UPDATED:
        if (!applicationId) {
          return;
        }

        if (documentBody.insuranceStatus === InsuranceStatus.APPROVED) {
          this.store.dispatch(new RefreshApplication(applicationId));
        } else {
          this.store.dispatch(new MortgageInsuranceApplicationQuotesGet(applicationId));
        }

        break;

      case EventMessageType.APPLICATION_WARNINGS_UPDATED: {
        const { warnings } = event.message.body;
        if (!warnings) {
          return;
        }
        if (!applicationId) {
          return;
        }
        this.store.dispatch(new SetApplicationWarnings(applicationId, warnings));
        break;
      }

      case EventMessageType.EQB_LOAN_DECISION_SUCCESS:
        if (!applicationId) {
          return;
        }

        this.store.dispatch(new EqClearLoanDecisionErrors());
        break;
      case EventMessageType.EQB_LOAN_DECISION_FAILURE:
        if (!applicationId) {
          return;
        }

        this.store.dispatch(new FetchEqMessages(applicationId));

        break;
      case EventMessageType.EQB_LOAN_UPDATE_FAILURE_ASYNC: {
        if (!applicationId) {
          return;
        }
        this.store.dispatch(new FetchEqMessages(applicationId));

        break;
      }
      case EventMessageType.EQB_LOAN_UPDATE_SUCCESS_ASYNC: {
        if (!applicationId) {
          return;
        }
        this.store.dispatch(new FetchEqMessages(applicationId));
        this.store.dispatch(new RefreshApplication(applicationId));
        break;
      }
      case EventMessageType.APPLICATION_CONDITION_DOCUMENT_COMMENT_ADDED: {
        if (!event.message.body.applicationConditionDocumentFileId) {
          return;
        }

        const ezidoxEnabled =
          this.store.selectSnapshot(AppFeaturesState.documentManagementType) ===
          DocumentManagementType.EZIDOX;

        ezidoxEnabled
          ? this.store.dispatch(
              new FetchPrivateDMDocumentComments(
                event.message.body.applicationConditionDocumentFileId,
              ),
            )
          : this.store.dispatch(
              new FetchApplicationConditionDocumentComments(
                event.message.body.applicationConditionDocumentFileId,
              ),
            );
        break;
      }

      case EventMessageType.CONDITION_DOCUMENT_REVIEWED:
      case EventMessageType.CONDITION_DOCUMENT_UPLOADED: {
        if (!applicationId) {
          return;
        }

        const ezidoxEnabled =
          this.store.selectSnapshot(AppFeaturesState.documentManagementType) ===
          DocumentManagementType.EZIDOX;

        ezidoxEnabled
          ? this.store.dispatch(new FetchPrivateDMDocuments(applicationId))
          : this.store.dispatch(new FetchApplicationConditionDocuments(applicationId));
        break;
      }
      case EventMessageType.APPROVAL_REQUESTED:
      case EventMessageType.APPROVAL_REQUEST_CANCELED:
        if (!applicationId) {
          // TODO: this should be handled similar with fetch applications debounce
          this.store.dispatch(new UpdatePendingApprovalsList());
          return;
        }

        this.store.dispatch(new FetchApplicationApprovals(applicationId));
        break;

      case EventMessageType.APPROVAL_RECOMMENDED_FOR_APPROVAL:
        if (!applicationId) {
          this.store.dispatch(new UpdatePendingApprovalsList());
          return;
        }

        this.store.dispatch(new FetchApplicationApprovals(applicationId));
        break;

      case EventMessageType.APPROVAL_REQUEST_REVIEWED:
        if (!applicationId) {
          return;
        }

        if (
          event.message.body.applicationApproval.action === ApplicationApprovalAction.APPROVE ||
          event.message.body.applicationApproval.action === ApplicationApprovalAction.DECLINE
        ) {
          this.store.dispatch(new FetchNotes(applicationId));
        }

        this.store.dispatch(new FetchApplicationApprovals(applicationId));
        break;
      case EventMessageType.APPLICATION_ASSIGNED:
        this.store.dispatch(new FetchApplications());

        break;
      case EventMessageType.BULK_TASKS_ASSIGNED:
      case EventMessageType.TASK_ASSIGNED:
      case EventMessageType.TASK_CREATED:
      case EventMessageType.TASK_UPDATED:
      case EventMessageType.TASK_DELETED:
      case EventMessageType.TASK_MARK_AS_COMPLETED:
      case EventMessageType.TASK_MARK_AS_NOT_COMPLETED:
      case EventMessageType.DEFAULT_TASKS_CREATED: {
        if (!applicationId) {
          return;
        }
        const application = this.store.selectSnapshot(MortgageApplicationState.application);

        if (application?.id === applicationId) {
          this.store.dispatch(new GetTasks(applicationId));
        }
        break;
      }

      case EventMessageType.APPLICATION_PRIORITY_UPDATED:
      case EventMessageType.APPLICATION_STAGE_CHANGED:
      case EventMessageType.APPLICATION_LOCKED:
      case EventMessageType.APPLICATION_UNLOCKED:
      case EventMessageType.APPLICATION_ARCHIVED:
      case EventMessageType.APPLICATION_UNARCHIVED: {
        if (this.store.selectSnapshot(SectionOpenedState.isOpen('pipeline'))) {
          this.fetchPipelineApplication.next(true);
        }
        if (!applicationId) {
          return;
        }
        this.store.dispatch(new RefreshApplication(applicationId));
        break;
      }

      case EventMessageType.APPLICATION_DUPLICATE:
      case EventMessageType.APPLICATION_DELETED:
      case EventMessageType.APPLICATION_CREATED: {
        if (this.store.selectSnapshot(SectionOpenedState.isOpen('pipeline'))) {
          this.fetchPipelineApplication.next(true);

          this.store.dispatch(new FetchUnassignedDealsCount());
        }
        break;
      }
      case EventMessageType.FCT_APPLICATION_UPDATED:
      case EventMessageType.FCT_APPLICATION_STATUS_UPDATED:
      case EventMessageType.FCT_NOTES_UPDATED: {
        const fctReferenceID = event.message.body.fctReferenceId;

        if (!fctReferenceID) {
          return;
        }

        const appraisal = this.store
          .selectSnapshot(PropertyAppraisalState.appraisals)
          .find((a) => a.FCTAppraisal?.fctReferenceId === fctReferenceID);

        if (appraisal?.id) {
          this.store.dispatch(new FetchAppraisalData(appraisal.id, true));
        }
        break;
      }

      case EventMessageType.DOCUMENT_SIGNATURE_REQUEST_CREATED:
      case EventMessageType.DOCUMENT_SIGNATURE_REQUEST_UPDATED:
      case EventMessageType.DOCUMENT_REQUEST_CREATED:
      case EventMessageType.DOCUMENT_REQUEST_DELETED: {
        const application = this.store.selectSnapshot(MortgageApplicationState.application);

        if (applicationId && application?.id === applicationId) {
          this.store.dispatch(new FetchDocumentRequests(applicationId));
        }
        break;
      }

      case EventMessageType.DOCUMENT_REQUEST_NOTE_CREATED: {
        const application = this.store.selectSnapshot(MortgageApplicationState.application);

        if (applicationId && application?.id === applicationId) {
          this.store.dispatch(new FetchRequestedDocumentNotes(applicationId));
        }
        break;
      }

      case EventMessageType.APPLICATION_RISK_FLAG_CREATED:
      case EventMessageType.APPLICATION_RISK_FLAG_DELETED:
      case EventMessageType.APPLICATION_RISK_FLAG_RESOLVED:
      case EventMessageType.APPLICATION_RISK_FLAG_MESSAGE:
      case EventMessageType.APPLICATION_RISK_FLAG_MESSAGE_DELETED: {
        const application = this.store.selectSnapshot(MortgageApplicationState.application);

        if (applicationId && application?.id === applicationId) {
          this.store.dispatch(new FetchApplicationRiskFlags(applicationId));
        }
        break;
      }

      case EventMessageType.APPLICANT_MARKED_AS_MEMBER:
      case EventMessageType.APPLICANT_MEMBER_OPTIONS_REFRESH: {
        const application = this.store.selectSnapshot(MortgageApplicationState.application);

        if (applicationId && application?.id === applicationId) {
          this.store.dispatch(new RefreshApplicationRestrictedStatusAndCreditOffers(applicationId));
        }
        break;
      }

      case EventMessageType.APPLICANT_MEMBER_TYPE_UPDATED: {
        const application = this.store.selectSnapshot(MortgageApplicationState.application);

        if (applicationId && application?.id === applicationId) {
          this.store.dispatch(new RefreshApplicationRestrictedStatus(applicationId));
        }
        break;
      }

      case EventMessageType.APPLICANT_MEMBER_ACCOUNTS_UPDATED: {
        const application = this.store.selectSnapshot(MortgageApplicationState.application);

        if (applicationId && application?.id === applicationId) {
          this.store.dispatch(new FetchFinancialLiabilities(applicationId));
        }
        break;
      }

      case EventMessageType.APPLICATION_DECISION_ENGINE_POLLING_EXECUTED: {
        const application = this.store.selectSnapshot(MortgageApplicationState.application);

        if (applicationId && application?.id === applicationId) {
          this.store.dispatch(new RefreshDecisionEngine(applicationId));
          this.store.dispatch(new FetchApplicantsCreditCardOffers(applicationId));
        }
        break;
      }
      case EventMessageType.APPLICATION_INCREMENTS_UPDATED:
      case EventMessageType.APPLICATION_CREATE_MEMBERS:
      case EventMessageType.APPLICATION_RETRIEVE_JOINT_MEMBERSHIPS: {
        const application = this.store.selectSnapshot(MortgageApplicationState.application);

        if (applicationId && application?.id === applicationId) {
          this.store.dispatch(new RefreshApplication(applicationId));
        }
        break;
      }

      case EventMessageType.APPLICATION_NOTE_LIST_REFRESH: {
        const application = this.store.selectSnapshot(MortgageApplicationState.application);

        if (applicationId && application?.id === applicationId) {
          this.store.dispatch(new FetchNotes(applicationId));
        }
        break;
      }

      case EventMessageType.NEW_AVA_MESSAGE: {
        const application = this.store.selectSnapshot(MortgageApplicationState.application);

        if (applicationId && application?.id === applicationId) {
          this.store.dispatch(new GetAvaChat(documentBody.avaChatId));
        }

        break;
      }

      case EventMessageType.APPLICATION_CREATE_PRODUCTS: {
        if (!applicationId) {
          return;
        }
        this.store.dispatch(new MCUCreateProductsPostback(documentBody.createProductsResponse));
        break;
      }

      case EventMessageType.APPLICATION_CREDIT_CARD_FULFILL: {
        if (!applicationId) {
          return;
        }
        this.store.dispatch(
          new MCUCreditCardFulfillPostback(documentBody.creditCardFulfillResponse),
        );
        break;
      }

      case EventMessageType.MMS_REQUEST_UPDATED: {
        if (!applicationId) {
          return;
        }
        const mmsNotification = documentBody.mmsNotification;
        if (mmsNotification?.counters?.fctURN) {
          const counters = new MMSMessageCounters(mmsNotification.counters);
          if (counters.hasNewMessages()) {
            this.store.dispatch(new SetMMSNotificationCounters(counters));
          }
        }

        this.store.dispatch(new FetchClosingInstructions(applicationId));
        break;
      }

      case EventMessageType.MMS_DOCUMENTS_UPLOADED: {
        if (!applicationId) {
          return;
        }
        this.store.dispatch(new SetMMSDocumentUploadStatus(false));
        this.store.dispatch(new GetMMSDocuments(applicationId));
        break;
      }
    }

    if (applicationId) {
      this.store.dispatch(new FetchHistoryLogs(undefined, applicationId));
    }
  }

  private generateFundmoreNotification(
    applicationUserEvent: ApplicationUserEvent,
    message?: string,
    title?: string,
  ) {
    const documentBody = applicationUserEvent.event.message.body;
    const documentEventType = applicationUserEvent.event.message.type;
    if (!documentBody.id) {
      return;
    }
    const applicationId = documentBody.id;

    let customIcon;
    let customPath;

    if (
      documentEventType === EventMessageType.APPROVAL_REQUESTED ||
      documentEventType === EventMessageType.APPROVAL_REQUEST_CANCELED ||
      documentEventType === EventMessageType.APPROVAL_REQUEST_REVIEWED ||
      documentEventType === EventMessageType.APPROVAL_RECOMMENDED_FOR_APPROVAL
    ) {
      customPath = ['approvals'];
      customIcon = 'approval';
    } else if (
      documentEventType === EventMessageType.TASK_ASSIGNED ||
      documentEventType === EventMessageType.TASK_UPDATED ||
      documentEventType === EventMessageType.TASK_DELETED ||
      documentEventType === EventMessageType.TASK_MARK_AS_COMPLETED
    ) {
      customPath = ['tasks'];
      customIcon = 'fact_check';
    } else if (
      documentEventType === EventMessageType.CONDITION_DOCUMENT_UPLOADED ||
      documentEventType === EventMessageType.CONDITION_DOCUMENT_REVIEWED ||
      documentEventType === EventMessageType.APPLICATION_CONDITION_DOCUMENT_COMMENT_ADDED ||
      documentEventType === EventMessageType.DOCUMENT_REQUEST_CREATED ||
      documentEventType === EventMessageType.DOCUMENT_SIGNATURE_REQUEST_CREATED ||
      documentEventType === EventMessageType.DOCUMENT_SIGNATURE_REQUEST_UPDATED ||
      documentEventType === EventMessageType.DOCUMENT_REQUEST_DELETED ||
      documentEventType === EventMessageType.DOCUMENT_REQUEST_NOTE_CREATED
    ) {
      customIcon = 'description';
    } else if (documentEventType === EventMessageType.CHAT_MENTION) {
      customIcon = 'chat';
    } else if (documentEventType === EventMessageType.APPLICATION_WARNINGS_UPDATED) {
      customIcon = 'warning';
    } else if (documentEventType === EventMessageType.INSURANCE_QUOTE_UPDATED) {
      customIcon = 'extension';
    } else if (documentEventType === EventMessageType.APPLICATION_RISK_FLAG_CREATED) {
      customIcon = 'tour';
    } else if (documentEventType === EventMessageType.NEW_AVA_MESSAGE) {
      customIcon = 'live_help';
    } else {
      // default to application icon, should be no notifications without icons
      customIcon = 'dashboard';
    }

    const data: FundmoreNotification = {
      message: `${message}`,
      title: title ?? $localize`Notification`,
      applicationId: applicationId,
      applicationIsServicing: documentBody.applicationIsServicing,
      applicationStage: documentBody.applicationStage as ApplicationStage,
      applicationName: documentBody.applicationName,
      applicationMortgages: documentBody.applicationMortgages,
      applicationIsLatest: documentBody.applicationIsLatest,
      applicationCreatedAt: documentBody.applicationCreatedAt,
      customIcon,
      customPath,
      channel: applicationUserEvent.event.channel,
      addedAt: applicationUserEvent.event.timetoken
        ? new Date(+applicationUserEvent.event.timetoken.toString().slice(0, -4))
        : new Date(),
      timetoken: applicationUserEvent.event.timetoken,
      eventType: documentEventType,
    };

    return data;
  }

  private eventIsDuplicate(event: PubNubEvent, ctx: StateContext<PubNubModel>) {
    const state = ctx.getState();

    const previousEvents = [...state.applicationEvents, ...state.userEvents];

    return previousEvents.some(
      (p) => p.message.body.pubNubMessageId === event.message.body.pubNubMessageId,
    );
  }
}
