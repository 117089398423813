/* eslint-disable max-len */
import {
  AmortizedMonthlyPaymentSnapshot,
  assertUnreachable,
  CalculationItemType,
  CalculationResult,
  CompoundPeriod,
  LoanType,
  LOCCalculatorParametersModel,
  Mortgage,
  CalculationAutomationSettings,
  PaymentFrequency,
  PrepaymentType,
  PropertyType,
  RepaymentType,
} from '@fundmoreai/models';
import { FundmoreCalculator } from '..';
import { extractCompoundingPeriod } from '../fundmore.calculator';

export function paymentCalculationDetails(
  paymentValue: number | undefined,
  requestedMortgage: Mortgage | undefined,
  totalLoanAmount?: number,
  calculationAutomationSettings?: CalculationAutomationSettings,
  eqLoanUpdateErrors?: string[],
): CalculationResult {
  if (
    !requestedMortgage?.id ||
    (calculationAutomationSettings &&
      calculationAutomationSettings[requestedMortgage.id]?.isPaymentAmountDisabled &&
      (eqLoanUpdateErrors?.length ?? 0) > 0)
  ) {
    return {
      formula: 'Payment amount is 0 because there are errors on update to P+',
      items: [],
      result: 0,
    };
  }

  const paymentFrequency =
    (requestedMortgage && requestedMortgage.paymentFrequency) || PaymentFrequency.MONTHLY;
  const repaymentType =
    (requestedMortgage && requestedMortgage.repaymentType) || RepaymentType.PRINCIPAL_AND_INTEREST;
  const amortization = requestedMortgage?.amortizationMonths ?? 0;
  const interestRate = requestedMortgage?.netRate ?? 0;
  const compounding = extractCompoundingPeriod(requestedMortgage);
  const payment = getPayment(paymentValue, requestedMortgage);

  const formula = getFormula(
    paymentFrequency,
    repaymentType,
    amortization,
    interestRate,
    totalLoanAmount,
    requestedMortgage.loanType,
  );

  let adjustedInterestRate = 0.0;

  if (interestRate) {
    if (repaymentType === RepaymentType.INTEREST_ONLY || !amortization) {
      adjustedInterestRate = interestRate / 12;
    } else {
      if (compounding) {
        adjustedInterestRate = Math.pow(1 + interestRate / 100 / compounding, compounding / 12);
      }
    }
  }

  const isLOC =
    requestedMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
    requestedMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX;

  const data: CalculationResult = {
    formula: formula,
    items: [
      {
        text: isLOC ? 'Total Cash Advance' : 'Total Loan Amount',
        value: totalLoanAmount?.toString(),
        type: CalculationItemType.CURRENCY,
      },
      {
        text: 'Adjusted Interest Rate',
        value: adjustedInterestRate?.toString(),
        type: CalculationItemType.PERCENT,
      },
      {
        text: 'Payment Frequency',
        value: requestedMortgage.paymentFrequency ? paymentFrequency : undefined,
        type: CalculationItemType.TEXT,
        info: requestedMortgage.paymentFrequency ? undefined : 'Formula Default: Monthly',
      },
    ],
    result: payment ?? 0,
  };

  if (repaymentType !== RepaymentType.INTEREST_ONLY) {
    data.items.push({
      text: 'Amortization',
      value: amortization?.toFixed(2)?.toString(),
      type: CalculationItemType.DURATION_MONTHS,
    });
  }

  return data;
}

function getFormula(
  paymentFrequency: PaymentFrequency,
  repaymentType: RepaymentType,
  amortization: number,
  interestRate: number,
  totalLoanAmount: number | undefined,
  loanType: LoanType | null,
): string {
  let formula: string = '';
  const isLOC =
    loanType === LoanType.SECURE_LINE_OF_CREDIT || loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX;

  const formulaFirstVariableName = isLOC ? 'Total Cash Advance' : 'Total Loan Amount';

  if (
    (repaymentType === RepaymentType.INTEREST_ONLY || !amortization) &&
    interestRate &&
    totalLoanAmount
  ) {
    formula = `${formulaFirstVariableName} * Monthly Rate`;
  } else {
    switch (paymentFrequency) {
      case PaymentFrequency.MONTHLY:
        formula = `(${formulaFirstVariableName} x (Adjusted Interest Rate - 1)) / (1 - Adjusted Interest Rate ^ -(Amortization * 12))`;
        break;
      case PaymentFrequency.SEMI_MONTHLY:
        formula = `((${formulaFirstVariableName} x (Adjusted Interest Rate - 1)) / (1 - Adjusted Interest Rate ^ -(Amortization * 24)) `;
        break;
      case PaymentFrequency.BI_WEEKLY:
        formula = `((${formulaFirstVariableName} x (Adjusted Interest Rate - 1)) / (1 - Adjusted Interest Rate ^ -(Amortization * 26)) `;
        break;
      case PaymentFrequency.WEEKLY:
        formula = `((${formulaFirstVariableName} x (Adjusted Interest Rate - 1)) / (1 - Adjusted Interest Rate ^ -(Amortization * 52)) `;
        break;
      case PaymentFrequency.ANNUALLY:
        formula = `((${formulaFirstVariableName} x (Adjusted Interest Rate - 1)) / (1 - Adjusted Interest Rate ^ -(Amortization)) `;
        break;
      case PaymentFrequency.DAILY:
        formula = `((${formulaFirstVariableName} x (Adjusted Interest Rate - 1)) / (1 - Adjusted Interest Rate ^ -(Amortization * 365)) `;
        break;
      case PaymentFrequency.QUARTERLY:
        formula = `((${formulaFirstVariableName} x (Adjusted Interest Rate - 1)) / (1 - Adjusted Interest Rate ^ -(Amortization * 4)) `;
        break;
      case PaymentFrequency.SEMI_ANNUALLY:
        formula = `((${formulaFirstVariableName} x (Adjusted Interest Rate - 1)) / (1 - Adjusted Interest Rate ^ -(Amortization * 2)) `;
        break;
      case PaymentFrequency.ACCELERATED_BI_WEEKLY:
        formula = `((${formulaFirstVariableName} x (Adjusted Interest Rate - 1)) / (1 - Adjusted Interest Rate ^ -(Amortization * 12)) * 13) / 26`;
        break;
      case PaymentFrequency.ACCELERATED_WEEKLY:
        formula = `((${formulaFirstVariableName} x (Adjusted Interest Rate - 1)) / (1 - Adjusted Interest Rate ^ -(Amortization * 12)) * 13) / 52`;
        break;
      default:
        assertUnreachable(paymentFrequency, `Unhandled PaymentFrequency`);
        break;
    }
  }
  return formula;
}

export function getPayment(
  paymentValue: number | undefined,
  requestedMortgage: Partial<Mortgage> | undefined,
): number | undefined {
  if (!paymentValue) {
    return undefined;
  }

  const paymentFrequency =
    (requestedMortgage && requestedMortgage.paymentFrequency) || PaymentFrequency.MONTHLY;
  const repaymentType =
    (requestedMortgage && requestedMortgage.repaymentType) || RepaymentType.PRINCIPAL_AND_INTEREST;
  const prepaymentType =
    (requestedMortgage && requestedMortgage.prepaymentType) || PrepaymentType.SPREAD_EVENLY;
  const prepaymentAmount = (requestedMortgage && requestedMortgage.prepaymentAmount) || 0.0;
  const prepaymentAmountInPayments =
    (requestedMortgage && requestedMortgage.prepaymentAmountInPayments) || null;
  const isPrepaymentAmountInPayments =
    (requestedMortgage && requestedMortgage.isPrepaymentAmountInPayments) || false;
  const balloonAmount = (requestedMortgage && requestedMortgage.balloonAmount) || 0.0;
  const prepaymentAmountByFrequency = FundmoreCalculator.prepaymentAmountByFrequency(
    paymentValue,
    paymentFrequency,
    prepaymentAmount,
    prepaymentAmountInPayments ?? undefined,
    isPrepaymentAmountInPayments,
  );

  let payment = paymentValue;

  if (repaymentType === RepaymentType.BALLOON_PAYMENT) {
    const monthlyAmountToBalloon = balloonAmount / 12;

    payment -= monthlyAmountToBalloon;
  } else {
    if (prepaymentType === PrepaymentType.SPREAD_EVENLY) {
      const monthlyAmountToSpread = prepaymentAmountByFrequency / 12;

      payment -= monthlyAmountToSpread;
    }
  }

  payment = paymentByFrequency(payment, paymentFrequency);

  return payment;
}

export function paymentByFrequency(payment: number, paymentFrequency: PaymentFrequency) {
  let paymentByFrequency = payment ?? 0;
  switch (paymentFrequency) {
    case PaymentFrequency.ACCELERATED_BI_WEEKLY:
      paymentByFrequency = (payment * 13) / 26;
      break;
    case PaymentFrequency.ACCELERATED_WEEKLY:
      paymentByFrequency = (payment * 13) / 52;
      break;
    case PaymentFrequency.ANNUALLY:
    case PaymentFrequency.BI_WEEKLY:
    case PaymentFrequency.DAILY:
    case PaymentFrequency.MONTHLY:
    case PaymentFrequency.QUARTERLY:
    case PaymentFrequency.SEMI_ANNUALLY:
    case PaymentFrequency.SEMI_MONTHLY:
    case PaymentFrequency.WEEKLY:
      // included in compounding
      paymentByFrequency = payment;
      break;
    default:
      assertUnreachable(paymentFrequency, 'Unhandled PaymentFrequency');
      paymentByFrequency = payment;
      break;
  }

  return Math.round(paymentByFrequency * 100) / 100;
}

export function computeExistingLOCMortgagePayments(
  mortgage: Pick<
    Mortgage,
    'id' | 'loanAmount' | 'monthlyPayment' | 'mortgageBalance' | 'paymentFrequency'
  >,
  propertyType: PropertyType | undefined,
  locCalculatorParameters?: LOCCalculatorParametersModel,
) {
  const isPrimary = propertyType === PropertyType.PRIMARY;
  const amount = isPrimary ? mortgage.loanAmount : mortgage.mortgageBalance;
  const netRate = locCalculatorParameters?.rate;
  const amortizationMonths = locCalculatorParameters?.amortizationMonths;

  let monthlyPayment = FundmoreCalculator.computeMortgageMonthlyPayment(
    { ...mortgage, amortizationMonths },
    amount,
    netRate,
  );

  if (monthlyPayment) {
    monthlyPayment = Math.round(monthlyPayment * 100) / 100;
  }

  return {
    monthlyPayment,
  };
}
