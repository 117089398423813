import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { LoadingEnd, LoadingStart } from '../../core/loading.state';
import { AddressExpandedService } from '../services/address-expanded.service';
import { AddressExpanded, PropertyAddressDetails } from '@fundmoreai/models';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ApplicationResetState } from 'src/app/shared/state.model';
import { GoogleService } from '../services/google.service';
import {
  AddOrUpdateApplicantAddressesExpanded,
  RemoveApplicantAddressesExpanded,
} from '../applicants.actions';
import { of } from 'rxjs';
import { AddOrUpdateEmployerAddressesExpanded } from '../../features/application/widgets/income/income.state.actions';

export class SetAddressesExpanded {
  static readonly type = '@AddressExpanded.setAddressesExpanded';
  constructor(public addressesExpanded: AddressExpanded[]) {}
}

export class PushAddressesExpanded {
  static readonly type = '@AddressExpanded.pushAddressesExpanded';
  constructor(public addressesExpanded: AddressExpanded[]) {}
}

export class CreateAddressExpanded {
  static readonly type = '@AddressExpanded.createAddressExpanded';
  constructor(public newAddressExpanded: Partial<AddressExpanded>) {}
}
export class UpsertAddressExpanded {
  static readonly type = '@AddressExpanded.upsertAddressExpanded';
  constructor(
    public addressExpandedId: string | undefined,
    public changes: Partial<AddressExpanded>,
  ) {}
}

export class UpsertAddressExpandedInput {
  static readonly type = '@AddressExpanded.upsertAddressExpandedInput';
  constructor(
    public addressExpandedId: string | undefined,
    public address: string | null,
    public applicantId?: string,
    public propertyId?: string,
    public jobId?: string,
    public applicationId?: string,
  ) {}
}
export class DeleteAddressExpanded {
  static readonly type = '@AddressExpanded.deleteAddressExpanded';
  constructor(public addressExpandedId: string) {}
}

export class ClearAddressExpanded {
  static readonly type = '@AddressExpanded.clearAddressExpanded';

  constructor(public addressExpandedId: string) {}
}

export class ClearPropertyAddressExpanded {
  static readonly type = '@AddressExpanded.clearPropertyAddressExpanded';

  constructor(public propertyId: string) {}
}

export class ClearApplicantAddressExpanded {
  static readonly type = '@AddressExpanded.clearApplicantAddressExpanded';

  constructor(public applicantId: string) {}
}

export class FetchTempAddress {
  static readonly type = '@AddressExpanded.fetchTempAddress';

  constructor(public tempId: string, public address: string) {}
}

export class UpsertInputAddress {
  static readonly type = '@AddressExpanded.upsertInputAddress';

  constructor(public tempId: string, public address: string) {}
}

export class ClearTempAddress {
  static readonly type = '@AddressExpanded.clearTempAddress';

  constructor(public tempId: string) {}
}

export class UpdateTempAddress {
  static readonly type = '@AddressExpanded.updateTempAddress';

  constructor(public tempId: string, public addressExpanded: AddressExpanded) {}
}

export class UseSubjectPropertyAddress {
  static readonly type = '@AddressExpanded.useSubjectPropertyAddress';

  constructor(public propertyId: string, public addressExpandedId: string) {}
}

interface AddressExpandedStateModel {
  addressesExpanded: AddressExpanded[];
  tempAddress: {
    [key: string]: AddressExpanded;
  };
}
const defaults = {
  addressesExpanded: [],
  tempAddress: {},
};
@State<AddressExpandedStateModel>({
  name: 'AddressExpanded',
  defaults: { ...defaults },
})
@Injectable()
export class AddressExpandedState {
  constructor(
    private addressExpandedService: AddressExpandedService,
    private googleService: GoogleService,
    private store: Store,
  ) {}

  @Selector()
  static addressesExpanded(state: AddressExpandedStateModel) {
    return state.addressesExpanded ?? [];
  }

  @Selector()
  static applicantAddressesExpanded(state: AddressExpandedStateModel) {
    return state.addressesExpanded?.filter((x) => x.applicantId !== null) ?? [];
  }

  @Selector()
  static propertyAddressesExpanded(state: AddressExpandedStateModel) {
    return state.addressesExpanded?.filter((x) => x.propertyId !== null) ?? [];
  }

  static tempAddress(tempId: string) {
    return createSelector(
      [AddressExpandedState],
      (state: AddressExpandedStateModel): AddressExpanded | undefined => {
        return state.tempAddress[tempId];
      },
    );
  }

  static getAddressesExpandedByApplicantId(applicantId: string) {
    return createSelector(
      [AddressExpandedState],
      (state: AddressExpandedStateModel): AddressExpanded[] => {
        return state.addressesExpanded?.filter((x) => x.applicantId === applicantId) ?? [];
      },
    );
  }

  @Action(ApplicationResetState)
  resetAppState(ctx: StateContext<AddressExpandedStateModel>) {
    ctx.setState({ ...defaults });
  }

  @Action(SetAddressesExpanded)
  setAddressesExpanded(
    ctx: StateContext<AddressExpandedStateModel>,
    { addressesExpanded }: SetAddressesExpanded,
  ): void {
    ctx.patchState({ addressesExpanded });
  }

  @Action(PushAddressesExpanded)
  pushAddressesExpanded(
    ctx: StateContext<AddressExpandedStateModel>,
    { addressesExpanded }: PushAddressesExpanded,
  ): void {
    ctx.setState(
      patch<AddressExpandedStateModel>({
        addressesExpanded: append<AddressExpanded>(addressesExpanded),
      }),
    );
  }

  @Action(CreateAddressExpanded) createAddressExpanded(
    ctx: StateContext<AddressExpandedStateModel>,
    action: CreateAddressExpanded,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const { newAddressExpanded } = action;

    return this.addressExpandedService.createAddressExpanded(newAddressExpanded).pipe(
      tap((addressExpanded: AddressExpanded) => {
        ctx.setState(
          patch<AddressExpandedStateModel>({
            addressesExpanded: append<AddressExpanded>([addressExpanded]),
          }),
        );
      }),
      switchMap((addressExpanded: AddressExpanded) => {
        if (addressExpanded.applicantId) {
          return ctx
            .dispatch(
              new AddOrUpdateApplicantAddressesExpanded(
                addressExpanded.applicantId,
                addressExpanded,
              ),
            )
            .pipe(map(() => addressExpanded));
        }
        if (addressExpanded.jobId) {
          return ctx
            .dispatch(
              new AddOrUpdateEmployerAddressesExpanded(addressExpanded.jobId, addressExpanded),
            )
            .pipe(map(() => addressExpanded));
        }

        return of(addressExpanded);
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpsertAddressExpanded)
  upsertAddressExpanded(
    ctx: StateContext<AddressExpandedStateModel>,
    action: UpsertAddressExpanded,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const { addressExpandedId, changes } = action;

    if (!addressExpandedId) {
      return this.addressExpandedService.createAddressExpanded(changes).pipe(
        tap((addressExpanded: AddressExpanded) => {
          ctx.setState(
            patch<AddressExpandedStateModel>({
              addressesExpanded: append<AddressExpanded>([addressExpanded]),
            }),
          );
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
    }

    return this.addressExpandedService.updateAddressExpanded(addressExpandedId, changes).pipe(
      tap((addressExpanded: AddressExpanded) => {
        ctx.setState(
          patch<AddressExpandedStateModel>({
            addressesExpanded: updateItem<AddressExpanded>(
              (addressExpanded) => addressExpanded?.id === addressExpandedId,
              addressExpanded,
            ),
          }),
        );
      }),
      switchMap((addressExpanded: AddressExpanded) => {
        if (addressExpanded.applicantId) {
          return ctx
            .dispatch(
              new AddOrUpdateApplicantAddressesExpanded(
                addressExpanded.applicantId,
                addressExpanded,
              ),
            )
            .pipe(map(() => addressExpanded));
        }
        if (addressExpanded.jobId) {
          return ctx
            .dispatch(
              new AddOrUpdateEmployerAddressesExpanded(addressExpanded.jobId, addressExpanded),
            )
            .pipe(map(() => addressExpanded));
        }

        return of(addressExpanded);
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpsertAddressExpandedInput)
  upsertAddressExpandedInput(
    ctx: StateContext<AddressExpandedStateModel>,
    action: UpsertAddressExpandedInput,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const { addressExpandedId, address } = action;
    return this.googleService.getAddressExpanded(address)?.pipe(
      switchMap((propertyAddressDetails: PropertyAddressDetails) => {
        const addressExpanded =
          AddressExpandedState.mapPropertyAddressToAddressExpanded(propertyAddressDetails);
        addressExpanded.applicantId = action.applicantId;
        addressExpanded.propertyId = action.propertyId;
        addressExpanded.jobId = action.jobId;
        addressExpanded.applicationId = action.applicationId;
        addressExpanded.address = address;

        if (!addressExpandedId) {
          return this.addressExpandedService.createAddressExpanded(addressExpanded).pipe(
            tap((addressExpanded: AddressExpanded) => {
              ctx.setState(
                patch<AddressExpandedStateModel>({
                  addressesExpanded: append<AddressExpanded>([addressExpanded]),
                }),
              );
            }),
            switchMap((addressExpanded: AddressExpanded) => {
              if (addressExpanded.applicantId) {
                return ctx
                  .dispatch(
                    new AddOrUpdateApplicantAddressesExpanded(
                      addressExpanded.applicantId,
                      addressExpanded,
                    ),
                  )
                  .pipe(map(() => addressExpanded));
              }
              if (addressExpanded.jobId) {
                return ctx
                  .dispatch(
                    new AddOrUpdateEmployerAddressesExpanded(
                      addressExpanded.jobId,
                      addressExpanded,
                    ),
                  )
                  .pipe(map(() => addressExpanded));
              }

              return of(addressExpanded);
            }),
            finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
          );
        }

        return this.addressExpandedService
          .updateAddressExpanded(addressExpandedId, addressExpanded)
          .pipe(
            tap((addressExpanded: AddressExpanded) => {
              ctx.setState(
                patch<AddressExpandedStateModel>({
                  addressesExpanded: updateItem<AddressExpanded>(
                    (addressExpanded) => addressExpanded?.id === addressExpandedId,
                    addressExpanded,
                  ),
                }),
              );
            }),
            switchMap((addressExpanded: AddressExpanded) => {
              if (addressExpanded.applicantId) {
                return ctx
                  .dispatch(
                    new AddOrUpdateApplicantAddressesExpanded(
                      addressExpanded.applicantId,
                      addressExpanded,
                    ),
                  )
                  .pipe(map(() => addressExpanded));
              }
              if (addressExpanded.jobId) {
                return ctx
                  .dispatch(
                    new AddOrUpdateEmployerAddressesExpanded(
                      addressExpanded.jobId,
                      addressExpanded,
                    ),
                  )
                  .pipe(map(() => addressExpanded));
              }

              return of(addressExpanded);
            }),
            finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
          );
      }),
    );
  }

  @Action(DeleteAddressExpanded) deleteAddressExpanded(
    ctx: StateContext<AddressExpandedStateModel>,
    action: DeleteAddressExpanded,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const { addressExpandedId } = action;

    return this.addressExpandedService.deleteAddressExpanded(addressExpandedId).pipe(
      switchMap(() => {
        const addressExpanded = ctx
          .getState()
          .addressesExpanded.find((addressExpanded) => addressExpanded?.id === addressExpandedId);

        if (!addressExpanded) {
          return of(null);
        }

        if (addressExpanded.applicantId) {
          return ctx.dispatch(
            new RemoveApplicantAddressesExpanded(addressExpanded.applicantId, addressExpandedId),
          );
        }

        return of(null);
      }),
      tap(() => {
        ctx.setState(
          patch<AddressExpandedStateModel>({
            addressesExpanded: removeItem<AddressExpanded>(
              (addressExpanded) => addressExpanded?.id === addressExpandedId,
            ),
          }),
        );
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(ClearAddressExpanded)
  clearAddressExpanded(ctx: StateContext<AddressExpandedStateModel>, action: ClearAddressExpanded) {
    let currentAddress = ctx
      .getState()
      .addressesExpanded.find(
        (addressExpanded) => addressExpanded?.id === action.addressExpandedId,
      );

    if (currentAddress) {
      currentAddress = {
        ...currentAddress,
        address: null,
        streetDirection: null,
        streetName: null,
        streetNumber: null,
        streetNumberSuffix: null,
        streetType: null,
        unit: null,
        unitType: null,
        postalCode: null,
        city: null,
        country: null,
        province: null,
        lat: null,
        long: null,
      };

      ctx.setState(
        patch<AddressExpandedStateModel>({
          addressesExpanded: updateItem<AddressExpanded>(
            (addressExpanded) => addressExpanded?.id === action.addressExpandedId,
            currentAddress,
          ),
        }),
      );
    }
  }

  @Action(ClearPropertyAddressExpanded)
  clearPropertyAddressExpanded(
    ctx: StateContext<AddressExpandedStateModel>,
    action: ClearPropertyAddressExpanded,
  ) {
    ctx.setState(
      patch<AddressExpandedStateModel>({
        addressesExpanded: removeItem<AddressExpanded>(
          (addressExpanded) => addressExpanded?.propertyId === action.propertyId,
        ),
      }),
    );
  }

  @Action(ClearApplicantAddressExpanded)
  clearApplicantAddressExpanded(
    ctx: StateContext<AddressExpandedStateModel>,
    action: ClearApplicantAddressExpanded,
  ) {
    ctx.setState(
      patch<AddressExpandedStateModel>({
        addressesExpanded: removeItem<AddressExpanded>(
          (addressExpanded) => addressExpanded?.applicantId === action.applicantId,
        ),
      }),
    );
  }

  @Action(ClearTempAddress)
  clearTempAddress(ctx: StateContext<AddressExpandedStateModel>, action: ClearTempAddress) {
    const state = ctx.getState();
    const tempAddress = { ...state.tempAddress };

    delete tempAddress[action.tempId];

    ctx.patchState({
      tempAddress,
    });
  }

  @Action(FetchTempAddress)
  fetchTempAddress(ctx: StateContext<AddressExpandedStateModel>, action: FetchTempAddress) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.googleService.getAddressExpanded(action.address).pipe(
      tap((propertyAddressDetails: PropertyAddressDetails) => {
        const addressExpanded = AddressExpandedState.mapPropertyAddressToAddressExpanded(
          propertyAddressDetails,
        ) as AddressExpanded;

        const state = ctx.getState();
        ctx.patchState({
          tempAddress: {
            ...state.tempAddress,
            [action.tempId]: addressExpanded,
          },
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateTempAddress)
  updateTempAddress(ctx: StateContext<AddressExpandedStateModel>, action: UpdateTempAddress) {
    const state = ctx.getState();

    ctx.patchState({
      tempAddress: {
        ...state.tempAddress,
        [action.tempId]: { ...action.addressExpanded },
      },
    });
  }

  @Action(UseSubjectPropertyAddress)
  useSubjectPropertyAddress(
    ctx: StateContext<AddressExpandedStateModel>,
    action: UseSubjectPropertyAddress,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.addressExpandedService.getPropertyAddress(action.propertyId).pipe(
      map((propertyAddressDetails: PropertyAddressDetails) => {
        const addressExpanded =
          AddressExpandedState.mapPropertyAddressToAddressExpanded(propertyAddressDetails);
        this.store.dispatch(new UpsertAddressExpanded(action.addressExpandedId, addressExpanded));
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  static mapPropertyAddressToAddressExpanded(
    propertyAddressDetails: PropertyAddressDetails,
  ): Partial<AddressExpanded> {
    const addressExpanded: Partial<AddressExpanded> = {
      address: propertyAddressDetails.formattedAddress,
      country: propertyAddressDetails.country,
      city: propertyAddressDetails.city,
      province: propertyAddressDetails.province,
      streetDirection: propertyAddressDetails.streetDirection,
      streetName: propertyAddressDetails.streetName,
      streetNumber: propertyAddressDetails.streetNumber,
      streetType: propertyAddressDetails.streetType,
      unit: propertyAddressDetails.unit,
      postalCode: propertyAddressDetails.postalCode,
      lat: propertyAddressDetails.lat,
      long: propertyAddressDetails.lng,
    };

    return addressExpanded;
  }
}
