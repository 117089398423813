import { PaymentFrequency } from '@fundmoreai/models';
import { paymentFrequencyMap } from './mortgage-payment.calculator';

export function computeNegativeAmortizationRate(
  loanAmount: number | null,
  paymentAmount: number | null,
  paymentFrequency: PaymentFrequency | null,
): number {
  const annualPayment =
    paymentAmount != null && paymentFrequency != null
      ? paymentAmount * (paymentFrequencyMap.get(paymentFrequency) as number)
      : 0;
  const negativeAmortizationRate = loanAmount != null ? (annualPayment / loanAmount) * 100 : 0;

  return negativeAmortizationRate;
}
