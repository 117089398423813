import { DefaultFeeKey } from '@fundmoreai/models';

export const DefaultFeeKeyRecord: Record<DefaultFeeKey, string> = {
  [DefaultFeeKey.TYPE]: $localize`:@@CustomFieldsFallback.DEFAULT_FEE.TYPE:Type`,
  [DefaultFeeKey.PAID_BY]: $localize`:@@CustomFieldsFallback.DEFAULT_FEE.PAID_BY:Paid by`,
  [DefaultFeeKey.DESCRIPTION]: $localize`:@@CustomFieldsFallback.DEFAULT_FEE.DESCRIPTION:Description`,
  [DefaultFeeKey.PRODUCTS]: $localize`:@@CustomFieldsFallback.DEFAULT_FEE.PRODUCTS:Products`,
  [DefaultFeeKey.CREDIT_TIERS]: $localize`:@@CustomFieldsFallback.DEFAULT_FEE.CREDIT_TIERS:Credit Tiers`,
  [DefaultFeeKey.APPLICATION_PURPOSE]: $localize`:@@CustomFieldsFallback.DEFAULT_FEE.APPLICATION_PURPOSE:Application Purposes`,
  [DefaultFeeKey.TERM_TYPES]: $localize`:@@CustomFieldsFallback.DEFAULT_FEE.TERM_TYPES:Term Types`,
  [DefaultFeeKey.PERCENT]: $localize`:@@CustomFieldsFallback.DEFAULT_FEE.PERCENT:Percent`,
  [DefaultFeeKey.AMOUNT]: $localize`:@@CustomFieldsFallback.DEFAULT_FEE.AMOUNT:Amount`,
  [DefaultFeeKey.SUBTRACT_FROM_PRINCIPAL]: $localize`:@@CustomFieldsFallback.DEFAULT_FEE.SUBTRACT_FROM_PRINCIPAL:Subtract from principal`,
  [DefaultFeeKey.CAP_FEES_PERCENTAGE]: $localize`:@@CustomFieldsFallback.DEFAULT_FEE.CAP_FEES_PERCENTAGE:Cap Fees Percentage`,
  [DefaultFeeKey.INCLUDE_IN_APR]: $localize`:@@CustomFieldsFallback.DEFAULT_FEE.INCLUDE_IN_APR:Include in APR`,
};
