import { PubNubEvent, ApplicationUserEvent } from './model';

export const DELAY_TIME_MS = 2000;
export class AddApplicationEvent {
  static readonly type = '@pubNub.addApplicationEvent';
  constructor(public event: PubNubEvent) {}
}

export class AddChatEvent {
  static readonly type = '@pubNub.addChatEvent';
  constructor(public event: PubNubEvent) {}
}

export class AddUserEvent {
  static readonly type = '@pubNub.addUserEvent';
  constructor(public event: PubNubEvent) {}
}

export class HandleApplicationUserEvent {
  static readonly type = '@pubNub.handleApplicationUserEvent';
  constructor(public applicationUserEvent: ApplicationUserEvent) {}
}

export class HandleChatEvent {
  static readonly type = '@pubNub.handleChatEvent';
  constructor(public event: PubNubEvent) {}
}

export class SubscribeUser {
  static readonly type = '@pubNub.subscribeUser';
}

export class SubscribeChat {
  static readonly type = '@pubNub.subscribeChat';
  constructor(public applicationId: string) {}
}

export class SubscribeApplication {
  static readonly type = '@pubNub.subscribeApplication';
  constructor(public applicationId: string) {}
}

export class UnsubscribeApplication {
  static readonly type = '@pubNub.UnsubscribeApplication';
  constructor(public applicationId: string) {}
}

export class PresenceOnChannel {
  static readonly type = '@pubNub.PresenceOnChannel';
  constructor(public channel: string) {}
}

export class PresenceOnApplication {
  static readonly type = '@pubNub.PresenceOnApplication';
  constructor(public applicationId: string) {}
}

export class PresenceEventOnApplication {
  static readonly type = '@pubNub.PresenceEventOnApplication';
  constructor(
    public channel: string,
    public userId: string,
    public action: 'leave' | 'join' | 'state-change' | 'timeout',
    public state?: string,
  ) {}
}

export class UnsubscribeUser {
  static readonly type = '@pubNub.unsubscribeUser';
}

export class UnsubscribeChat {
  static readonly type = '@pubNub.unsubscribeChat';
}

export class UnsubscribeAll {
  static readonly type = '@pubNub.unsubscribeAll';
}

export class SetupPubNub {
  static readonly type = '@pubNub.setup';
  constructor(public userId: string) {}
}

export class RefreshPubNubConfig {
  static readonly type = '@pubNub.refreshPubNubConfig';
}

export class SubscribeChannels {
  static readonly type = '@pubNub.subscribeChannels';
  constructor(public channels: string[], public presence: boolean = false) {}
}

export class FetchPersistedMessages {
  static readonly type = '@pubNub.FetchPersistedMessages';
}

export class DeletePersistedMessages {
  static readonly type = '@pubNub.DeletePersistedMessages';
  constructor(public timetoken: string | number | undefined) {}
}

export class AddMessageAction {
  static readonly type = '@pubNub.AddMessageAction';
  constructor(public timetoken: string, public action: 'read' | 'unread') {}
}

export class LoadPersistedMessages {
  static readonly type = '@pubNub.LoadPersistedMessages';
  constructor(public events: (AddApplicationEvent | AddChatEvent | AddUserEvent)[]) {}
}
