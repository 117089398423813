import { TermType } from '../enums';
import { Rate } from './rate';

export interface RateMatrixTerm {
  id: string;
  rateMatrixId: string;
  fixedRateId: string | null;
  variableRateId: string | null;
  variableRateModifier?: number;
  duration?: TermDuration;
  termType?: TermType | null;
  FixedRate?: Rate;
  VariableRate?: Rate;
  isActive?: boolean;
  rangeStartDate?: string | null;
  rangeEndDate?: string | null;
  isSpecialRate?: boolean;
}

export interface TermDuration {
  durations: (number | null)[];
}
