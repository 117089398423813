import { Mortgage, Property, PropertyViewModel } from '@fundmoreai/models';
export function getTotalOfSecurities(
  primaryProperty: Property | undefined,
  primaryExistingMortgages: Mortgage[],
  primaryAppraisedValue: number | undefined,
  securities: PropertyViewModel[],
) {
  const primaryPriorBalance = primaryExistingMortgages.reduce(
    (a, b) => a + (b.mortgageBalance ?? 0),
    0,
  );

  const securitiesPriorBalance = securities.reduce((a, b) => a + (b.mortgages.balance ?? 0), 0);

  const totalPriorBalance = primaryPriorBalance + securitiesPriorBalance;

  const securitiesAppraisedValue = securities.reduce((a, b) => a + (b.estimatedValue ?? 0), 0);

  const totalAppraisedValue =
    securitiesAppraisedValue +
    (primaryAppraisedValue ? primaryAppraisedValue : primaryProperty?.estimatedValue ?? 0);

  return { totalPriorBalance, totalAppraisedValue };
}
