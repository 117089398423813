import { User } from './user';

export interface FraudIqEvaluation {
  id: string;
  tenantId: string;
  applicationId: string;
  riskFlagId?: string;
  request: object;
  response: object;
  requestedById?: string;
  requestedByUser?: User;
  highestScore?: number | null;
  highestPrecisionScore?: number | null;
  errorMessage?: string | null;
  fraudStatus?: string | null;
  analyzedApplicants?: string | null;
  createdAt: Date;
  updatedAt?: Date | null;
}

export enum FraudIqEvaluationKey {
  ID = 'id',
  ERROR_MESSAGE = 'errorMessage',
  HIGHEST_SCORE = 'highestScore',
  HIGHEST_PRECISION_SCORE = 'highestPrecisionScore',
  FRAUD_STATUS = 'fraudStatus',
  ANALYZED_APPLICANTS = 'analyzedApplicants',
}
