import { Applicant, ContactType } from './applicant';
import { FinancialAsset } from './asset';
import { FinancialLiability } from './liability';
import { PaymentStatus, RateMethod, RefinancedMortgageMarkAsType, Security } from './mortgage';
import { ApplicationOtherIncome } from './otherIncome';
import { Property, PropertyAddressDetails } from './property';
import { CreditReportReference } from './creditReport';
import {
  AgentSource,
  ApplicationType,
  CompoundPeriod,
  InsurancePaidByType,
  LoanType,
  MortgageClassificationType,
  MortgagePosition,
  MortgageType,
  PaymentFrequency,
  RateType,
  RepaymentType,
  SecurityType,
  SourceOfBusinessType,
} from './enums';
import { ApplicationMetadata, ApplicationSource, PurposeCode } from './application';
import { Fee } from './fee';
import { DownPayment } from './downPayment';
import { EQClosingService, LendingTierType } from './eqMaps';
import { AddressExpanded } from './addressExpanded';
import { ApplicationNoteMetadata, ApplicationNoteStatus } from './applicationNote';
import { MortgageDecrement, MortgageIncrement } from './increment-decrement';
import { PrepaymentOptions } from './servicing';
import { PropertyAppraisal } from './propertyAppraisal';

export interface PropertyAppraisalCreate
  extends Omit<
    Partial<PropertyAppraisal>,
    'id' | 'applicationId' | 'createdAt' | 'updatedAt' | 'propertyId'
  > {}

export interface PropertyCreate
  extends Omit<Property, 'propertyAddressExpanded' | 'PropertyAppraisals'> {
  docsDueDate: string;
  propertyAddressExpanded: Partial<PropertyAddressDetails>;
  PropertyAppraisals: PropertyAppraisalCreate[];
}

export interface ApplicantData extends Omit<Applicant, 'ApplicantAddressesExpanded'> {
  docsDueDate: string;
  ApplicantOtherIncomes?: ApplicationOtherIncome[];
  ApplicantFinancialLiabilities?: Partial<FinancialLiability>[];
  ApplicantProperties?: Partial<ApplicantProperty>[];
  ApplicantFinancialAssets?: FinancialAsset[];
  ApplicantCreditReports?: CreditReportReference[];
  ApplicantAddressesExpanded: Partial<AddressExpanded>[];
}

export interface BrokerApplicantData extends ApplicantData {
  firmCode: string;
  preferredContactMethod: ContactType;
  userType: string;
  licenseRegistrationNumber: string;
  profileStatus: string | null;
  externalId: { [key in ApplicationSource]?: string };
  source?: AgentSource;
  notes?: string | null;
}

export interface DownPaymentData extends DownPayment {
  tableData: unknown;
}

export interface FinancialAssetData extends FinancialAsset {
  tableData: unknown;
}

export interface FinancialLiabilityData extends FinancialLiability {
  tableData: unknown;
}

export interface OtherIncomeData extends ApplicationOtherIncome {
  tableData: unknown;
}

export interface ApplicantProperty {
  propertyId: string;
  mappedProperty: Partial<PropertyCreate>;
}

export interface CreateApplicationData {
  name?: string;
  broker?: string;
  brokerNotes?: string;
  brokerNotesDate?: string;
  applicationNotes?: ApplicationNoteCreate[];
  code?: string;
  applicationDate?: string | number;
  purpose?: string;
  mortgageClassification?: MortgageClassificationType;
  filogixDealId?: string;
  externalDealId?: string;
  source?: ApplicationSource;
  originalData?: any;
  fundId?: string;
  dealType?: ApplicationType;
  closingService?: EQClosingService;
  lendingTier?: LendingTierType;
  clonedApplicationId?: string;
  metadata?: ApplicationMetadata;
  lineOfBusinessId?: string;
  lineOfBusiness?: any;
  advancedProductId?: string;
  externalApplicationId?: string;
  externalApplicationGUID?: string;
  isCombo?: boolean;
  commitmentIssueDate?: string;
  commitmentExpiryDate?: string;
  quickCloseFlag?: boolean;
  sourceOfBusiness?: SourceOfBusinessType;
  purposeCode?: PurposeCode;
  applicationLoanNumber?: string;
  isServicing?: boolean;
  effectiveDate?: string;
  productSub?: number;
  paymentSub?: string;
  productNumber?: number;
  primaryMemberNumber?: number;
  mcuOriginalAppId?: string;
  submissionApplicationId?: string;
  mcuProductId?: string;

  financialAssets?: Partial<FinancialAssetData>[];
  otherIncomes?: Partial<OtherIncomeData>[];
  liabilities?: Partial<FinancialLiabilityData>[];
  servicingMortgage?: Partial<MortgageCreate>;
  requestedMortgage?: Partial<MortgageCreate>;
  helocRequestedMortgage?: Partial<MortgageCreate>;
  existingMortgage?: Partial<MortgageCreate>[];
  refinanceMortgage?: Partial<MortgageCreate>[];
  properties?: Partial<PropertyCreate>[];
  downPayments?: Partial<DownPaymentData>[];
  primaryApplicant?: Partial<ApplicantData>;
  otherApplicants?: Partial<ApplicantData>[];
  brokerApplicant?: Partial<BrokerApplicantData>;
  brokerApplicants?: Partial<BrokerApplicantData>[];
  contactsEmails?: {
    email: string;
    externalEmailSequenceNumber?: number;
  }[];
  lenderSubmission?: LenderSubmissionCreate;
  Fees?: Partial<Fee>[];
  calculationAutomationSettings?: CalculationAutomationSettings;
  applicationSourceForOther?: string | null;
}

export type CalculationAutomationSettings =
  | Record<string, CalculationAutomationSettingsModel>
  | null
  | undefined;

export enum CalculationAutomationSettingsKeys {
  computePropertyValueOption = 'computePropertyValueOption',
  isPaymentAmountDisabled = 'isPaymentAmountDisabled',
  isMonthlyPaymentDisabled = 'isMonthlyPaymentDisabled',
  isInterestAdjustmentDateDisabled = 'isInterestAdjustmentDateDisabled',
  isInterestAdjustmentAmountDisabled = 'isInterestAdjustmentAmountDisabled',
  isMaturityDateDisabled = 'isMaturityDateDisabled',
  excludeExtraPaymentInTerm = 'excludeExtraPaymentInTerm',
  setTDSToZero = 'setTDSToZero',
  setGDSToZero = 'setGDSToZero',
}

export enum ComputePropertyValueOptions {
  PROPERTY_VALUE = 'PROPERTY_VALUE',
  MIN_BETWEEN_VALUE_AND_PURCHASE_PRICE = 'MIN_BETWEEN_VALUE_AND_PURCHASE_PRICE',
}

export class CalculationAutomationSettingsModel {
  [CalculationAutomationSettingsKeys.computePropertyValueOption]:
    | ComputePropertyValueOptions
    | null
    | undefined;
  [CalculationAutomationSettingsKeys.isPaymentAmountDisabled]: boolean | null | undefined;
  [CalculationAutomationSettingsKeys.isMonthlyPaymentDisabled]: boolean | null | undefined;
  [CalculationAutomationSettingsKeys.isInterestAdjustmentDateDisabled]: boolean | null | undefined;
  [CalculationAutomationSettingsKeys.isInterestAdjustmentAmountDisabled]:
    | boolean
    | null
    | undefined;
  [CalculationAutomationSettingsKeys.isMaturityDateDisabled]: boolean | null | undefined;
  [CalculationAutomationSettingsKeys.excludeExtraPaymentInTerm]: boolean | null | undefined;
  [CalculationAutomationSettingsKeys.setTDSToZero]: boolean | null | undefined;
  [CalculationAutomationSettingsKeys.setGDSToZero]: boolean | null | undefined;
}

export interface LenderSubmissionCreate {
  routingSender?: string | null;
  routingReceiver?: string | null;
  language?: string | null;
  submitRequestTime?: string | null;
  lenderProfile?: {
    linkId?: string | null;
    registrationName?: string | null;
    lenderCode?: string | null;
    lenderName?: string | null;
    useLegacyUserId?: string | null;
  } | null;
}

export interface MortgageCreate {
  id: string;
  applicationId: string;
  propertyId: string | null;
  lender?: string | null;
  productName?: string | null;
  loanType?: LoanType | null;
  purpose?: string | null;
  closingDate: string | null | undefined;
  fundingDate?: string | null;
  mortgageType: MortgagePosition | null;
  paymentFrequency?: PaymentFrequency | null;
  purchaseValue: number | null;
  insuranceAmount: number | null;
  insurancePremium: number | null;
  loanAmount: number | null;
  monthlyPayment: number | null;
  netRate: number | null;
  originalNetRate: number | null;
  termMonths?: number | null;
  amortizationMonths?: number | null;
  repaymentType?: RepaymentType | null;
  type: MortgageType | null;
  rateType?: RateType | null;
  termType?: string | null;
  maturityDate?: string | null;
  mortgageBalance: number | null;
  compounding: CompoundPeriod | null;
  externalLoanNumber?: string | null;
  loanNumber?: string | null;
  deadlineToAcceptDate?: string | null;
  interestAdjustmentDate: string | null | undefined;
  interestAdjustmentAmount: number | null;
  borrowingLimit?: number | null;
  firstRegularPaymentDate?: string | null;
  insurer?: string | null;
  insuranceAccountNum?: string | null;
  mortgageNum: string | null | undefined;
  includePremiumInMortgage: boolean | null;
  markAs: RefinancedMortgageMarkAsType | null;
  totalLoanAmount: number | null;
  paymentAmount: number | null;
  baseRate: number | null;
  buyDownRate: number | null;
  discount: number | null;
  cashAdvance: number | null;
  totalRegularPayment: number | null;
  payoff?: string;
  payoffPaydown?: string;
  originalMINumber?: string | null;
  originalMIProvider?: string | null;
  insurancePaidBy?: InsurancePaidByType | null;
  externalSequenceNumber?: number | null;
  partnerLoanName?: string | null;
  partnerLoanPortion?: number | null;
  partnerLoanEQPortion?: number | null;
  refiBlendedAmortization: boolean | null;
  cofDate: string | null;
  securityType: SecurityType | null;
  security: Security[] | null;
  rateHoldDate: string | null;
  nextPaymentDate?: string | null;
  originalAmortization?: number | null;
  propertyTaxAmount?: number | null;
  prepaymentOptions?: PrepaymentOptions | null;
  prepaymentAmount?: number | null;
  maxPrepaymentAmount?: number | null;
  prepaymentPercentage?: number | null;
  newMaturityDate?: Date | null;
  isBlendedRate?: boolean | null;
  contractualAmortizationMonths?: number | null;
  frequencyPayment?: number | null;
  rateMethod?: RateMethod | null;
  lastPaymentStatus?: PaymentStatus | null;
  productMemberNumber?: string | null;
  increments?: MortgageIncrement[] | null;
  customIncrementAmount?: number | null;
  customIncrementIsSet?: boolean | null;
  customIncrementNote?: string | null;
  incrementTotal?: number | null;
  decrements?: MortgageDecrement[] | null;
  decrementTotal?: number | null;
  insurancePremiumSurcharge?: number | null;
  insurancePremiumSurchargeApplied?: boolean | null;
}

export interface ApplicationNoteCreate {
  applicationId?: string;
  status: ApplicationNoteStatus;
  authorNames?: string[];
  authorRoles?: string[];
  editableById?: string;
  content: string;
  lastModifiedAt?: Date | string;
  metadata?: ApplicationNoteMetadata;
}
