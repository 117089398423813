import { Mortgage, Fee, LoanType } from '@fundmoreai/models';

export function computeAmountToAdvance(
  requestedMortgage: Partial<Mortgage> | undefined,
  fees: Fee[],
  pst: number | null,
  prepaymentAmount: number | null | undefined,
  multipleRequestedMortgages = false,
): number | null {
  if (!requestedMortgage?.loanAmount) {
    return null;
  }

  let amountToAdvance = requestedMortgage.loanAmount;

  if (!multipleRequestedMortgages || requestedMortgage.loanType === LoanType.MORTGAGE) {
    amountToAdvance -= computeFeesToSubtract(fees);
  }

  if (requestedMortgage.includePremiumInMortgage && pst) {
    amountToAdvance -= pst;
  }

  if (prepaymentAmount) {
    amountToAdvance -= prepaymentAmount;
  }

  if (requestedMortgage.transferAdjustment) {
    amountToAdvance -= requestedMortgage.transferAdjustment;
  }

  return Math.round((amountToAdvance ?? 0) * 100) / 100;
}

export function computeFeesToSubtract(fees: Fee[]): number {
  // Deducted Fees
  let feesToSubtract = 0.0;

  for (const fee of fees) {
    if (
      fee.capFeesPercentage !== null &&
      fee.capFeesPercentage < 100 &&
      fee.subtractFromPrincipal === false
    ) {
      feesToSubtract += ((fee.amount ?? 0) * (100 - fee.capFeesPercentage)) / 100;
    }

    if (fee.subtractFromPrincipal) {
      feesToSubtract += fee.amount ?? 0;
    }
  }

  return feesToSubtract;
}
