import {
  Fee,
  Mortgage,
  MortgageType,
  Property,
  PropertyType,
  CalculationResult,
  Application,
  CalculationItemType,
  CalculationAutomationSettings,
} from '@fundmoreai/models';
import { computeExistingMortgageAmount } from './existing-mortgage-amount.calculator';
import { computeLoanAmountForLTV } from './loan-amount-for-ltv.calculator';
import { computePropertyValue } from './property-value.calculator';
import { computeLTV } from './ltv.calculator';
import { FundmoreCalculator } from '..';

export function computeCltv(
  requestedMortgages: Partial<Mortgage>[],
  existingMortgages: Partial<Mortgage>[],
  properties: Property[],
  fees: Fee[],
  capFeesMaxPercentage?: number,
  calculationAutomationSettings?: CalculationAutomationSettings,
) {
  // securities and primary property
  const filteredProperties = properties.filter(
    (property) =>
      property.type === PropertyType.PRIMARY ||
      (property.isCollateralized && property.includeInCLTV),
  );
  const filteredPropertiesId = filteredProperties.map((property) => property.id);

  // filtered properties existingMortgages
  const filteredExistingMortgages = existingMortgages.filter(
    (mortgage) =>
      mortgage.type !== MortgageType.REQUESTED &&
      mortgage.propertyId &&
      filteredPropertiesId.includes(mortgage.propertyId),
  );
  const totalExistingMortgageAmount = computeExistingMortgageAmount(filteredExistingMortgages);

  const loanAmountTotal = computeLoanAmountForLTV(requestedMortgages, fees, capFeesMaxPercentage);

  const totalPropertyValue = filteredProperties.reduce(
    (sum, property) =>
      sum +
      computePropertyValue({
        property,
        calculationSettings: calculationAutomationSettings,
      }),
    0.0,
  );

  const cltv = computeLTV(
    totalPropertyValue,
    totalExistingMortgageAmount,
    loanAmountTotal,
    calculationAutomationSettings,
  ).result;

  const data: CalculationResult = {
    formula: '(Loan Amount + Total Existing Mortgage Amount) / Total Properties Value',
    items: [
      {
        text: 'Loan Amount',
        value: loanAmountTotal?.toString(),
        type: CalculationItemType.CURRENCY,
      },
      {
        text: 'Total Existing Mortgage Amount',
        value: totalExistingMortgageAmount?.toString(),
        type: CalculationItemType.CURRENCY,
      },
      {
        text: 'Total Properties Value',
        value: totalPropertyValue?.toString(),
        type: CalculationItemType.CURRENCY,
      },
    ],
    result: cltv,
  };
  return data;
}

export function computeCltvFromApplication(
  application: Application,
  calculationAutomationSettings: CalculationAutomationSettings,
): CalculationResult {
  const requestedMortgages = application.Mortgages.filter((x) => x.type === MortgageType.REQUESTED);
  const existingMortgages = application.Mortgages.filter((x) => x.type === MortgageType.EXISTING);
  const properties = application.Properties;
  const fees = application.Fees;
  const capFeesMaxPercentage =
    FundmoreCalculator.computeCapFeesMaxPercentageFromApplication(application);

  const cltv = computeCltv(
    requestedMortgages,
    existingMortgages,
    properties,
    fees,
    capFeesMaxPercentage,
    calculationAutomationSettings,
  );

  return cltv;
}
