import { Fee, Mortgage, Property, PropertyType } from '@fundmoreai/models';
import { FundmoreCalculator } from '..';

export function computeTotalLoanAmountForAllRequestedMortgages(
  requestedMortgages: Pick<
    Mortgage,
    'loanAmount' | 'includePremiumInMortgage' | 'insuranceAmount' | 'loanType'
  >[],
  existingMortgages: Partial<Mortgage>[],
  fees: Fee[],
  properties: Property[],
): number | undefined {
  const borrowerProperties = properties.filter(
    (property) => property.type !== PropertyType.EXTERNAL,
  );

  const borrowerPropertiesId = borrowerProperties.map((property) => property.id);

  const borrowerExistingMortgages = existingMortgages.filter(
    (m) => m.propertyId && borrowerPropertiesId.includes(m.propertyId),
  );

  const existingMortgageAmount =
    FundmoreCalculator.computeExistingMortgageAmount(borrowerExistingMortgages);

  let totalLoanAmount = 0;
  requestedMortgages.forEach((requestedMortgage) => {
    totalLoanAmount +=
      FundmoreCalculator.computeTotalLoanAmountWithoutCapFees(
        requestedMortgage,
        requestedMortgage.insuranceAmount,
      ) ?? 0;
  });

  const primaryProperty = properties.find((p) => p?.type === PropertyType.PRIMARY);
  const capFeesMaxPercentage =
    FundmoreCalculator.computeCapFeesMaxPercentage(
      fees,
      totalLoanAmount,
      existingMortgageAmount,
      primaryProperty?.estimatedValue,
    ) ?? 0;

  let totalLoanAmountForAllRequestedMortgages = 0;
  requestedMortgages.forEach((requestedMortgage) => {
    totalLoanAmountForAllRequestedMortgages +=
      FundmoreCalculator.computeTotalLoanAmount(
        requestedMortgage,
        fees,
        requestedMortgage.insuranceAmount,
        capFeesMaxPercentage,
      ) ?? 0;
  });

  return Math.round(totalLoanAmountForAllRequestedMortgages * 100) / 100;
}
