import { EqLoanUpdateType } from '@fundmoreai/models';

export interface EqLoanUpdatePayload {
  applicationId: string;
  type: EqLoanUpdateType;
}
export class EqLoanUpdate {
  static readonly type = '@EqCoreState.eqLoanUpdate';
  constructor(public payload: EqLoanUpdatePayload) {}
}

export class FirstRegularPaymentDateManuallyChanged {
  static readonly type = '@EqCoreState.firstRegularPaymentDateManuallyChanged';
  constructor(public applicationId: string) {}
}

export class FetchEqMessages {
  static readonly type = '@EqCoreState.fetchEqMessages';

  constructor(public applicationId: string) {}
}

export class EqClearLoanUpdateErrors {
  static readonly type = '@EqCoreState.eqClearLoanUpdateErrors';
}

export class EqClearLoanDecisionErrors {
  static readonly type = '@EqCoreState.clearLoanDecisionErrors';
}

export class EqLoanDecisionRetry {
  static readonly type = '@EqCoreState.eqLoanDecisionRetry';
  constructor(public applicationId?: string) {}
}
