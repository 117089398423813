import { State, Action, StateContext, Selector, Store, createSelector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';
import { ApplicantsService } from './applicants.service';
import {
  Applicant,
  CustomerType,
  Job,
  AddressExpandedType,
  AddressExpanded,
  ApplicantExistingMemberOption,
} from '@fundmoreai/models';
import { IsThirdPartyPipe } from '../shared/isThirdParty.pipe';
import { ReapplyMortgageAdvancedProducts } from './advanced-products/advanced-product.state';
import {
  AddApplicant,
  PatchBulkApplicantsCreditScore,
  UpdateApplicantJob,
  DeleteApplicantJob,
  SetSelectedApplicantId,
  UpdateApplicant,
  DeleteApplicant,
  SetApplicants,
  AddOrUpdateApplicantAddressesExpanded,
  RemoveApplicantAddressesExpanded,
  SwapApplicantType,
  GenerateNewClientId,
  UpdateApplicantClientId,
  UpdateApplicantCredit,
  MarkAsMemberForStakeholder,
  RefreshExistingMemberOptions,
  StartClientScreening,
  UpdateApplicantMemberType,
  FetchApplicantsCreditCardOffers,
  UpdateApplicantCreditCardOffer,
  UpdateApplicantCreditCardOfferDetails,
  SwitchApplicantJob,
  CreateMembershipIds,
  RetrieveJointMembershipIds,
} from './applicants.actions';
import { LoadingEnd, LoadingStart } from '../core/loading.state';
import { insertItem, patch, updateItem, removeItem } from '@ngxs/store/operators';
import { ApplicationResetState } from '../shared/state.model';
import { SetApplicationMemberFlags } from './mortgage-application.actions';
import { PushAddressesExpanded } from './states/address-expanded.state';

export interface ApplicantStateModel {
  applicants?: {
    [key: string]: Applicant;
  };
  selectedApplicantId: string | undefined;
}

const defaults = {
  applicants: undefined,
  selectedApplicantId: undefined,
};
@State<ApplicantStateModel>({
  name: 'applicants',
  defaults: { ...defaults },
})
@Injectable()
export class ApplicantsState {
  @Selector()
  private static applicants(state: ApplicantStateModel): Applicant[] | undefined {
    return state.applicants
      ? Object.values(state.applicants)
          .map((applicant: Applicant) => ({
            ...applicant,
            currentResidence: applicant.ApplicantAddressesExpanded?.find(
              (address) => address.type === AddressExpandedType.CURRENT,
            ),
          }))
          .sort(
            (a: Applicant, b: Applicant) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
          )
      : undefined;
  }

  static applicant(applicantId: string) {
    return createSelector(
      [ApplicantsState],
      (state: ApplicantStateModel): Applicant | undefined => {
        return state.applicants?.[applicantId];
      },
    );
  }

  @Selector([ApplicantsState.applicants])
  static applicantsList(applicants: Applicant[] | undefined): Applicant[] | undefined {
    return applicants;
  }

  @Selector([ApplicantsState.applicants])
  static applicantsListExcludingThirdParty(
    applicants: Applicant[] | undefined,
  ): Applicant[] | undefined {
    return applicants?.filter(
      (applicant) => !IsThirdPartyPipe.IsThirdParty(applicant.customerType),
    );
  }

  static applicantExistingMemberOptions(applicantId: string) {
    return createSelector(
      [ApplicantsState.applicants],
      (applicants: Applicant[] | undefined): ApplicantExistingMemberOption[] | null | undefined => {
        return applicants?.find((x) => x.id === applicantId)?.existingMemberOptions;
      },
    );
  }

  @Selector([ApplicantsState.applicants])
  static broker(applicants: Applicant[] | undefined): Applicant | undefined {
    return applicants?.find((x) => x.customerType === CustomerType.BROKER);
  }

  @Selector([ApplicantsState.applicants])
  static brokers(applicants: Applicant[] | undefined): Applicant[] | undefined {
    return applicants
      ?.filter((a) => a.customerType === CustomerType.BROKER)
      .sort((a, b) => {
        const nameA = a.name ?? '' + a.surname ?? '';
        const nameB = b.name ?? '' + b.surname ?? '';

        return nameA.localeCompare(nameB);
      });
  }

  @Selector([ApplicantsState.applicants])
  static buyersStakeholders(applicants: Applicant[] | undefined): Applicant[] | undefined {
    return applicants?.filter(
      (x) =>
        x.customerType &&
        [
          CustomerType.CUSTOMER,
          CustomerType.CO_BORROWER,
          CustomerType.GUARANTOR,
          CustomerType.DIRECTOR,
        ].includes(x.customerType),
    );
  }

  @Selector([ApplicantsState.buyersStakeholders]) static primaryApplicantsLowestCreditScore(
    applicants: Applicant[] | undefined,
  ): number | null {
    const validApplicantCreditScores = applicants
      ?.map((a) => a.crScore)
      ?.filter((cs): cs is number => !!cs);

    if (!validApplicantCreditScores || validApplicantCreditScores.length === 0) {
      return null;
    }

    return Math.min(...validApplicantCreditScores);
  }

  static creditCardOfferDetailsAmountToRequestLimit(applicantId: string) {
    return createSelector(
      [ApplicantsState.buyersStakeholders],
      (applicants: Applicant[] | undefined) => {
        const selectedCreditCardOfferDetails = applicants
          ?.find((x) => x.id === applicantId)
          ?.ApplicantCreditCardOffer?.ApplicantCreditCardOfferDetails?.find((x) => x.selected);

        if (!selectedCreditCardOfferDetails) {
          return;
        }

        const amountToRequestSum =
          applicants?.reduce((sum, applicant) => {
            if (applicant.id === applicantId) {
              return sum;
            }

            sum +=
              applicant.ApplicantCreditCardOffer?.ApplicantCreditCardOfferDetails?.find(
                (x) => x.selected && x.cardName === selectedCreditCardOfferDetails.cardName,
              )?.amountToRequest || 0;

            return sum;
          }, 0) || 0;

        return selectedCreditCardOfferDetails.approvedLoanAmount - amountToRequestSum;
      },
    );
  }

  @Selector([ApplicantsState.applicants])
  static lawyers(applicants: Applicant[] | undefined): Applicant[] | undefined {
    return applicants?.filter((x) => x.customerType === CustomerType.LAWYER);
  }

  @Selector([ApplicantsState.applicantsListExcludingThirdParty, ApplicantsState.primaryApplicant])
  static otherApplicants(
    applicants: Applicant[] | undefined,
    primaryApplicant: Applicant | undefined,
  ): Applicant[] | undefined {
    return applicants?.filter((applicant) => applicant.id !== primaryApplicant?.id);
  }

  @Selector([ApplicantsState.applicants])
  static primaryApplicant(applicants: Applicant[] | undefined): Applicant | undefined {
    return applicants?.find((x) => x.isPrimary);
  }

  @Selector([ApplicantsState.primaryApplicant])
  static primaryApplicantDocumentRequestInProgress(applicant: Applicant | undefined): boolean {
    return (applicant?.createDocumentRequest ?? false) && applicant?.ezidoxStakeholderId == null;
  }

  @Selector([ApplicantsState, ApplicantsState.applicants])
  static selectedApplicant(
    state: ApplicantStateModel,
    applicants: Applicant[] | undefined,
  ): Applicant | undefined {
    return applicants?.find((x) => x.id === state.selectedApplicantId);
  }

  @Selector([ApplicantsState.applicantsList])
  static applicantsValuesForProductReapplyTriggers(applicants: Applicant[] | undefined) {
    return applicants?.map((applicant) => ({
      ficoScoreOverride: applicant.ficoScoreOverride,
      crScore: applicant.crScore,
      crScoreCode: applicant.crScoreCode,
      ApplicantAddressesExpanded: applicant.ApplicantAddressesExpanded?.map((applicantAddress) => ({
        includeRentInCalculations: applicantAddress.includeRentInCalculations,
        rent: applicantAddress.rent,
        residentialStatusType: applicantAddress.residentialStatusType,
        type: applicantAddress.type,
      })),
    }));
  }

  @Selector([ApplicantsState.applicants])
  static newProspectStakeholdersFlag(applicants: Applicant[] | undefined): boolean {
    return (
      (
        applicants?.filter(
          (x) =>
            x.customerType &&
            [
              CustomerType.CUSTOMER,
              CustomerType.CO_BORROWER,
              CustomerType.GUARANTOR,
              CustomerType.DIRECTOR,
            ].includes(x.customerType) &&
            !x.memberNumber,
        ) ?? []
      ).length > 0
    );
  }

  @Selector([ApplicantsState.applicants])
  static connectedPartyError(applicants: Applicant[] | undefined): boolean {
    return (applicants ?? []).some((x) => x.connectedPartyCheckError);
  }

  constructor(private applicantsService: ApplicantsService, private store: Store) {}

  @Action(AddApplicant)
  addApplicant(
    ctx: StateContext<ApplicantStateModel>,
    { applicationId, newApplicant }: AddApplicant,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    const reapplyProduct =
      (newApplicant.customerType === CustomerType.CUSTOMER ||
        newApplicant.customerType === CustomerType.CO_BORROWER ||
        newApplicant.customerType === CustomerType.GUARANTOR) &&
      newApplicant.ficoScoreOverride;

    return this.applicantsService.postApplicant(applicationId, newApplicant).pipe(
      tap((applicant) => {
        const state = ctx.getState();

        if (!applicant.ApplicantAddressesExpanded) {
          applicant.ApplicantAddressesExpanded = [];
        }
        if (!applicant.Jobs) {
          applicant.Jobs = [];
        }

        // If ficoScoreOverride will be set for this applicant,
        // reset ficoScoreOverride for all the rest of them in the state
        // Resetting ficoScoreOverride for all other applicants in the db
        // is handled in the API logic as well.
        const applicants = { ...state.applicants };

        ctx.patchState({
          applicants: {
            ...Object.keys(applicants).reduce((acc, applicantId) => {
              return {
                ...acc,
                [applicantId]: {
                  ...applicants[applicantId],
                  ficoScoreOverride: applicant.ficoScoreOverride
                    ? false
                    : applicants[applicantId].ficoScoreOverride,
                },
              };
            }, {}),
            [applicant.id]: applicant,
          },
          selectedApplicantId: applicant.id,
        });
      }),
      tap((applicant: Applicant) =>
        this.store.dispatch(new PushAddressesExpanded(applicant.ApplicantAddressesExpanded)),
      ),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));

        if (reapplyProduct) {
          this.store.dispatch(new ReapplyMortgageAdvancedProducts(applicationId, true, true));
        }
      }),
    );
  }

  @Action(ApplicationResetState)
  reset(ctx: StateContext<ApplicantStateModel>) {
    ctx.setState({ ...defaults });
  }

  @Action(DeleteApplicant)
  deleteApplicant(ctx: StateContext<ApplicantStateModel>, action: DeleteApplicant) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicantsService.deleteApplicant(action.applicant.id).pipe(
      tap(() => {
        const state = ctx.getState();
        const applicants = { ...state.applicants };

        delete applicants[action.applicant.id];

        ctx.patchState({
          applicants,
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(DeleteApplicantJob)
  deleteApplicantJob(ctx: StateContext<ApplicantStateModel>, action: DeleteApplicantJob) {
    const state = ctx.getState();
    const applicant = state.applicants?.[action.applicantId];

    if (!applicant) {
      return;
    }

    ctx.patchState({
      applicants: {
        ...state.applicants,
        [action.applicantId]: {
          ...applicant,
          Jobs: [...applicant.Jobs].filter((x) => x.id !== action.jobId),
        },
      },
    });
  }

  @Action(PatchBulkApplicantsCreditScore)
  patchBulkApplicantsCreditScore(
    ctx: StateContext<ApplicantStateModel>,
    action: PatchBulkApplicantsCreditScore,
  ) {
    const state = ctx.getState();
    const applicants = { ...state.applicants };

    action.applicants.forEach((applicant) => {
      const updatedApplicant = {
        ...applicants[applicant.id],
        crScore: applicant.crScore,
        crScoreCode: applicant.crScoreCode,
        bniScore: applicant.bniScore,
        crDescription: applicant.crDescription,
      };

      applicants[applicant.id] = updatedApplicant;
    });

    ctx.patchState({ applicants });
  }

  @Action(SetApplicants)
  setApplicants(ctx: StateContext<ApplicantStateModel>, action: SetApplicants) {
    const applicantsEntities = action.applicants?.reduce(
      (entities: { [key: string]: Applicant }, applicant) => {
        entities[applicant.id] = applicant;

        return entities;
      },
      {},
    );

    ctx.patchState({ applicants: applicantsEntities });
  }

  @Action(SetSelectedApplicantId)
  setSelectedApplicantId(ctx: StateContext<ApplicantStateModel>, action: SetSelectedApplicantId) {
    ctx.patchState({
      selectedApplicantId: action.applicantId,
    });
  }

  @Action(UpdateApplicant)
  updateApplicant(ctx: StateContext<ApplicantStateModel>, action: UpdateApplicant) {
    const state = ctx.getState();

    if (!action.applicant.id) {
      return;
    }

    ctx.dispatch(new LoadingStart(this.constructor.name));

    const prevApplicant = state.applicants?.[action.applicant.id];
    const applicantToUpdate: Partial<Applicant> = {
      ...action.applicant,
      applicationId: prevApplicant?.applicationId,
      ezidoxStakeholderId: prevApplicant?.ezidoxStakeholderId,
    };
    const willFicoScoreOverrideBeSet =
      applicantToUpdate.ficoScoreOverride && !prevApplicant?.ficoScoreOverride;

    return this.applicantsService.patchApplicant(action.applicant.id, applicantToUpdate).pipe(
      tap((applicant) => {
        // If ficoScoreOverride will be set for this applicant,
        // reset ficoScoreOverride for all the rest of them in the state
        // Resetting ficoScoreOverride for all other applicants in the db
        // is handled in the API logic as well.
        const applicants: Applicant[] = Object.values(state.applicants || {});

        ctx.patchState({
          applicants: {
            ...applicants.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.id]: {
                  ...curr,
                  ficoScoreOverride: willFicoScoreOverrideBeSet ? false : curr.ficoScoreOverride,
                },
              };
            }, {}),
            [applicant.id]: {
              ...applicant,
              Jobs: ctx.getState().applicants?.[applicant.id]?.Jobs || [],
            },
          },
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(UpdateApplicantCredit)
  updateApplicantCredit(ctx: StateContext<ApplicantStateModel>, action: UpdateApplicant) {
    const state = ctx.getState();

    if (!action.applicant.id) {
      return;
    }

    ctx.dispatch(new LoadingStart(this.constructor.name));

    const prevApplicant = state.applicants?.[action.applicant.id];
    const applicantToUpdate: Partial<Applicant> = {
      ...action.applicant,
      applicationId: prevApplicant?.applicationId,
      ezidoxStakeholderId: prevApplicant?.ezidoxStakeholderId,
    };

    return this.applicantsService.patchApplicantCredit(action.applicant.id, applicantToUpdate).pipe(
      tap((applicant) => {
        ctx.patchState({
          applicants: {
            ...(state.applicants || {}),
            [applicant.id]: {
              ...applicant,
              Jobs: ctx.getState().applicants?.[applicant.id]?.Jobs || [],
            },
          },
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(UpdateApplicantJob)
  updateApplicantJob(ctx: StateContext<ApplicantStateModel>, action: UpdateApplicantJob) {
    const state = ctx.getState();
    const applicant = state.applicants?.[action.applicantId];

    if (!applicant) {
      return;
    }

    const jobs: Job[] = [...(applicant.Jobs ?? [])];
    const indexToUpdate = jobs?.findIndex((x) => x.id === action.job.id);

    jobs[indexToUpdate] = action.job;

    ctx.patchState({
      applicants: {
        ...state.applicants,
        [action.applicantId]: {
          ...applicant,
          Jobs: jobs,
        },
      },
    });
  }

  @Action(SwitchApplicantJob)
  switchApplicantJob(ctx: StateContext<ApplicantStateModel>, action: SwitchApplicantJob) {
    const state = ctx.getState();
    const applicant = state.applicants?.[action.applicantId];

    if (!applicant) {
      return;
    }

    const jobs: Job[] = [...(applicant.Jobs ?? [])];

    jobs.push(action.job);

    ctx.patchState({
      applicants: {
        ...state.applicants,
        [action.applicantId]: {
          ...applicant,
          Jobs: jobs,
        },
      },
    });

    ctx.dispatch(new DeleteApplicantJob(action.previousApplicantId, action.job.id));
  }

  @Action(UpdateApplicantMemberType)
  updateApplicantMemberType(
    ctx: StateContext<ApplicantStateModel>,
    action: UpdateApplicantMemberType,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicantsService
      .patchApplicantMemberType(
        action.applicantId,
        action.restrictedPartyMember,
        action.staffMember,
        action.isStudent,
      )
      .pipe(
        tap(() => {
          const state = ctx.getState();
          const applicant = state.applicants?.[action.applicantId];

          if (!applicant) {
            return;
          }

          ctx.patchState({
            applicants: {
              ...state.applicants,
              [action.applicantId]: {
                ...applicant,
                restrictedPartyMember: action.restrictedPartyMember,
                staffMember: action.staffMember,
                isStudent: action.isStudent,
              },
            },
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(AddOrUpdateApplicantAddressesExpanded)
  addOrUpdateApplicantAddressesExpanded(
    ctx: StateContext<ApplicantStateModel>,
    action: AddOrUpdateApplicantAddressesExpanded,
  ) {
    const applicant = ctx.getState().applicants?.[action.applicantId];

    if (!applicant) {
      return;
    }

    const applicantAddressExpanded = applicant.ApplicantAddressesExpanded.find(
      (aae) => aae.id === action.addressExpanded.id,
    );

    if (applicantAddressExpanded) {
      // update
      ctx.setState(
        patch<ApplicantStateModel>({
          applicants: patch({
            [action.applicantId]: patch<Applicant>({
              ApplicantAddressesExpanded: updateItem<AddressExpanded>(
                (ae) => ae?.id === action.addressExpanded.id,
                patch(action.addressExpanded),
              ),
            }),
          }),
        }),
      );
    } else {
      // add
      ctx.setState(
        patch<ApplicantStateModel>({
          applicants: patch({
            [action.applicantId]: patch<Applicant>({
              ApplicantAddressesExpanded: insertItem<AddressExpanded>(action.addressExpanded),
            }),
          }),
        }),
      );
    }
  }

  @Action(RemoveApplicantAddressesExpanded)
  removeApplicantAddressesExpanded(
    ctx: StateContext<ApplicantStateModel>,
    action: RemoveApplicantAddressesExpanded,
  ) {
    const applicant = ctx.getState().applicants?.[action.applicantId];

    if (!applicant) {
      return;
    }

    const applicantAddressExpanded = applicant.ApplicantAddressesExpanded.find(
      (aae) => aae.id === action.addressExpandedId,
    );

    if (!applicantAddressExpanded) {
      return;
    }

    ctx.setState(
      patch<ApplicantStateModel>({
        applicants: patch({
          [action.applicantId]: patch<Applicant>({
            ApplicantAddressesExpanded: removeItem<AddressExpanded>(
              (ae) => ae?.id === action.addressExpandedId,
            ),
          }),
        }),
      }),
    );
  }

  @Action(SwapApplicantType) swapApplicantType(
    ctx: StateContext<ApplicantStateModel>,
    { applicantId, newType, replacementApplicantId }: SwapApplicantType,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicantsService
      .swapApplicantType(applicantId, newType, replacementApplicantId)
      .pipe(
        tap(({ updatedApplicant, updatedReplacementApplicant }) => {
          const state = ctx.getState();

          const applicant = state.applicants?.[applicantId];

          const updatedState = {
            applicants: {
              ...state.applicants,
              [applicantId]: {
                ...applicant,
                ...updatedApplicant,
              },
            },
          };

          if (!applicant) {
            return;
          }

          if (replacementApplicantId) {
            const replacementApplicant = state.applicants?.[replacementApplicantId];

            if (replacementApplicant) {
              updatedState.applicants[replacementApplicantId] = {
                ...replacementApplicant,
                ...updatedReplacementApplicant,
              };
            }
          }

          ctx.patchState(updatedState);
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(GenerateNewClientId) generateNewClientId(
    ctx: StateContext<ApplicantStateModel>,
    { applicantId }: GenerateNewClientId,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicantsService.generateNewClientId(applicantId).pipe(
      tap(({ clientId, expandedClientId }) => {
        const state = ctx.getState();
        const applicant = state.applicants?.[applicantId];

        if (!applicant) {
          return;
        }

        ctx.patchState({
          applicants: {
            ...state.applicants,
            [applicantId]: {
              ...applicant,
              clientId,
              expandedClientId,
              existingMemberOptions: null,
              memberNumber: null,
              restrictedPartyMember: false,
              staffMember: false,
              isStudent: false,
            },
          },
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateApplicantClientId) updateApplicantClientId(
    ctx: StateContext<ApplicantStateModel>,
    { applicantId, clientId }: UpdateApplicantClientId,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicantsService.updateClientId(applicantId, clientId).pipe(
      tap((updatedApplicant) => {
        const state = ctx.getState();
        const applicant = state.applicants?.[applicantId];

        if (!applicant) {
          return;
        }

        ctx.patchState({
          applicants: {
            ...state.applicants,
            [applicantId]: {
              ...applicant,
              ...updatedApplicant,
            },
          },
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(MarkAsMemberForStakeholder)
  markAsMemberForStakeholder(
    ctx: StateContext<ApplicantStateModel>,
    action: MarkAsMemberForStakeholder,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicantsService
      .markAsMember(action.applicantId, action.existingMemberOptionId, action.memberNumber)
      .pipe(
        tap((result) => {
          const state = ctx.getState();
          const applicant = state.applicants?.[action.applicantId];
          const { Application, ...updatedApplicant } = result;

          if (!applicant) {
            return;
          }

          ctx.patchState({
            applicants: {
              ...state.applicants,
              [action.applicantId]: {
                ...applicant,
                ...updatedApplicant,
              },
            },
          });

          ctx.dispatch(
            new SetApplicationMemberFlags(
              Application.restrictedPartyMember,
              Application.staffMember,
              Application.connectedParty,
            ),
          );
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(RefreshExistingMemberOptions)
  refreshExistingMemberOptions(
    ctx: StateContext<ApplicantStateModel>,
    action: RefreshExistingMemberOptions,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    let applicant = ctx.getState().applicants?.[action.applicantId];

    if (!applicant) {
      return;
    }

    return this.applicantsService.refreshExistingMemberOptions(applicant).pipe(
      tap((response: Applicant) => {
        const state = ctx.getState();

        applicant = state.applicants?.[action.applicantId];

        if (!applicant) {
          return;
        }

        applicant = {
          ...applicant,
          existingMemberOptions: response.existingMemberOptions,
          existingMemberOptionPoolingResults: {
            existingMemberOptionResponseStatus:
              response.existingMemberOptionPoolingResults?.existingMemberOptionResponseStatus,
            existingMemberOptionCompletedDate:
              response.existingMemberOptionPoolingResults?.existingMemberOptionCompletedDate,
          },
        };

        ctx.patchState({
          applicants: {
            ...state.applicants,
            [action.applicantId]: applicant,
          },
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(StartClientScreening)
  startClientScreening(ctx: StateContext<ApplicantStateModel>, action: StartClientScreening) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicantsService
      .startClientScreening(action.applicantId, action.applicationId)
      .pipe(finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))));
  }

  @Action(FetchApplicantsCreditCardOffers)
  fetchApplicantsCreditCardOffers(
    ctx: StateContext<ApplicantStateModel>,
    action: FetchApplicantsCreditCardOffers,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicantsService.getApplicantsCreditCardOffers(action.applicationId).pipe(
      tap((applicantsCreditCardOffers) => {
        const state = ctx.getState();
        const applicants = { ...state.applicants };

        Object.keys(applicants)?.forEach((applicantId) => {
          applicants[applicantId] = {
            ...applicants[applicantId],
            ApplicantCreditCardOffer: applicantsCreditCardOffers?.find(
              (creditCardOffer) => creditCardOffer.applicantId === applicantId,
            ),
          };
        });

        ctx.patchState({
          applicants,
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateApplicantCreditCardOffer)
  updateApplicantCreditCardOffer(
    ctx: StateContext<ApplicantStateModel>,
    action: UpdateApplicantCreditCardOffer,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicantsService
      .updateApplicantCreditCardOffer(action.applicantId, action.applicantCreditCardOffer)
      .pipe(
        tap((applicantCreditCardOffer) => {
          const state = ctx.getState();
          const applicant = state.applicants?.[action.applicantId];

          if (!applicant) {
            return;
          }

          ctx.patchState({
            applicants: {
              ...state.applicants,
              [action.applicantId]: {
                ...applicant,
                ApplicantCreditCardOffer: applicantCreditCardOffer,
              },
            },
          });
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(UpdateApplicantCreditCardOfferDetails)
  updateApplicantCreditCardOfferDetails(
    ctx: StateContext<ApplicantStateModel>,
    action: UpdateApplicantCreditCardOfferDetails,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicantsService
      .updateApplicantCreditCardOfferDetails(
        action.applicantId,
        action.applicantCreditCardOfferDetails,
      )
      .pipe(
        tap((applicantCreditCardOffer) => {
          const state = ctx.getState();
          const applicant = state.applicants?.[action.applicantId];

          if (!applicant) {
            return;
          }

          ctx.patchState({
            applicants: {
              ...state.applicants,
              [action.applicantId]: {
                ...applicant,
                ApplicantCreditCardOffer: applicantCreditCardOffer,
              },
            },
          });
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(CreateMembershipIds)
  createMembershipIds(ctx: StateContext<ApplicantStateModel>, action: CreateMembershipIds) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicantsService.createMembershipIds(action.applicationId).pipe(
      tap((updatedApplicants) => {
        if (updatedApplicants && updatedApplicants.length > 0) {
          const state = ctx.getState();
          const applicants = { ...state.applicants };

          updatedApplicants.forEach((applicant) => {
            if (applicant.id && applicant.memberNumber) {
              applicants[applicant.id] = {
                ...applicants[applicant.id],
                memberNumber: applicant.memberNumber,
              };
            }
          });

          ctx.patchState({
            applicants,
          });
        }
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(RetrieveJointMembershipIds)
  retrieveJointMembershipIds(
    ctx: StateContext<ApplicantStateModel>,
    action: RetrieveJointMembershipIds,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicantsService
      .retrieveJointMembershipIds(action.applicationId)
      .pipe(finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))));
  }
}
