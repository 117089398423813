import { injectable } from 'tsyringe';

export interface IRemainingMortgageTermCalculator {
  getRemainingMortgageTerm(maturityDate: string | Date): number;
}

@injectable()
export class RemainingMortgageTermCalculator implements IRemainingMortgageTermCalculator {
  getRemainingMortgageTerm(
    maturityDate: string | Date,
    startDate: string | Date = new Date(),
  ): number {
    const start = new Date(startDate);
    const end = new Date(maturityDate);

    const diff = end.getTime() - start.getTime();
    const diffInDays = diff / (1000 * 60 * 60 * 24); // milliseconds to days
    const monthsDifference = Math.round((diffInDays / 365) * 12);

    return monthsDifference;
  }
}
