import { AddressExpandedType, ResidentialStatusType, ResidentialType } from './addressExpanded';
import { AgentAddressExpandedType } from './agentAddressExpanded';
import {
  AccountType,
  CitizenshipStatusType,
  CustomerType,
  EmployedPaymentType,
  EmploymentType,
  FinancialInstitutionType,
  FraudAMLRiskType,
  Gender,
  IncomeVerificationType,
  IndustrySectorType,
  MaritalType,
  OccupationType,
  PaymentSource,
  PoliticallyExposedPersonType,
  RelationshipToPrimaryApplicant,
  RequestNameScreeningType,
  Salutation,
  SelfEmployedPaymentType,
  ThirdPartyDeterminationType,
} from './applicant';
import { ApplicationPurposeType, PurposeCode } from './application';
import {
  ApplicationType,
  AssetType,
  CompoundPeriod,
  ConformingType,
  ConstructionType,
  Country,
  DownPaymentType,
  DwellingStyle,
  DwellingType,
  ExcludeAssetFromCalculationReasonType,
  ExcludeLiabilityFromCalculationReasonType,
  FeePayer,
  FeeType,
  FinancialLiabilitySource,
  FoundationType,
  GarageType,
  HeatingType,
  IncomePeriod,
  InsurancePaidByType,
  LiabilityType,
  LoanType,
  LocationTierType,
  MortgageClassificationType,
  MortgageClosingInstruction,
  MortgagePosition,
  OccupancyType,
  PaymentFrequency,
  PayoffPaydownType,
  PayoffType,
  PrepaymentType,
  RateType,
  RentalOffsetType,
  RepaymentType,
  ResponsibleType,
  SecurityType,
  SourceOfBusinessType,
  StreetDirection,
  StreetType,
  TaxesPaidByType,
  TenureType,
  TermMonths,
  TermType,
  WaterType,
} from './enums';
import { EQClosingService, EQFraudAMLRiskType, LendingTierType } from './eqMaps';
import { FCTInsuranceStatus } from './fct';
import { FieldOptionDetails } from './fieldMetadata';
import { IncomeType } from './income';
import { InsurerType } from './insurance/insuranceQuote';
import { ProgramCodeType, Security } from './mortgage';
import {
  GarageSize,
  PropertyTypes,
  SewageType,
  TaxFirstPaymentType,
  ZoningTypes,
} from './property';
import {
  AppraisalSource,
  AppraisalType,
  AppraisalStatus,
  AppraisalConfidenceScore,
} from './propertyAppraisal';
import { PropertyInsuranceType } from './propertyInsurance';
import { PrepaymentOptions, ServicingType } from './servicing';

// ApplicantKey.CUSTOMER_TYPE
export const CUSTOMER_TYPE_OPTIONS: Record<CustomerType, FieldOptionDetails> = {
  CO_BORROWER: {
    isDefault: true,
    value: 'Co-Borrower',
  },
  COMPANY: {
    isDefault: true,
    value: 'Company',
  },
  CUSTOMER: {
    isDefault: true,
    value: 'Customer',
  },
  GUARANTOR: {
    isDefault: true,
    value: 'Guarantor',
  },
  THIRD_PARTY: {
    isDefault: true,
    value: '3rd Party',
  },
  BROKER: {
    isDefault: true,
    value: 'Broker',
  },
  LAWYER: {
    isDefault: true,
    value: 'Lawyer',
  },
  SUBMITTING_AGENT: {
    isDefault: true,
    value: 'Submitting Agent',
  },
  SIGNING_OFFICER: {
    isDefault: true,
    value: 'Signing Officer',
  },
  DIRECTOR: {
    isDefault: true,
    value: 'Director',
  },
  BENEFICIAL_OWNER: {
    isDefault: true,
    value: 'Beneficial Owner',
  },
  BROKERAGE: {
    isDefault: true,
    value: 'Brokerage',
  },
  POA: {
    isDefault: true,
    value: 'POA',
  },
};

// ApplicantKey.SALUTATION
export const SALUTATION_OPTIONS: Record<Salutation, FieldOptionDetails> = {
  MR: {
    isDefault: true,
    value: 'Mr',
  },
  MRS: {
    isDefault: true,
    value: 'Mrs',
  },
  MISS: {
    isDefault: true,
    value: 'Miss',
  },
  MS: {
    isDefault: true,
    value: 'Ms',
  },
  MADAM: {
    isDefault: true,
    value: 'Madam',
  },
  DR: {
    isDefault: true,
    value: 'Dr',
  },
  JUDGE: {
    isDefault: true,
    value: 'Judge',
  },
  PROF: {
    isDefault: true,
    value: 'Prof',
  },
  REV: {
    isDefault: true,
    value: 'Rev',
  },
  SIR: {
    isDefault: true,
    value: 'Sir',
  },
  OTHER: {
    isDefault: true,
    value: 'Other',
  },
};

// ApplicantKey.CITIZENSHIP_STATUS
export const CITIZENSHIP_STATUS_OPTIONS: Record<CitizenshipStatusType, FieldOptionDetails> = {
  CITIZEN: {
    isDefault: true,
    value: 'Citizen',
  },
  FOREIGN_ALIEN: {
    isDefault: true,
    value: 'Foreign Alien',
  },
  LANDED_IMMIGRANT: {
    isDefault: true,
    value: 'Landed Immigrant',
  },
  NON_RESIDENT: {
    isDefault: true,
    value: 'Non Resident',
  },
  RESIDENT: {
    isDefault: true,
    value: 'Resident',
  },
  STUDENT_VISA: {
    isDefault: true,
    value: 'Student Visa',
  },
  WORK_VISA: {
    isDefault: true,
    value: 'Work Visa',
  },
};

// ApplicantKey.GENDER
export const GENDER_OPTIONS: Record<Gender, FieldOptionDetails> = {
  MALE: {
    isDefault: true,
    value: 'Male',
  },
  FEMALE: {
    isDefault: true,
    value: 'Female',
  },
  OTHER: {
    isDefault: true,
    value: 'Other',
  },
};

// ApplicantKey.RELATIONSHIP_TO_PRIMARY_APPLICANT
export const RELATIONSHIP_TO_PRIMARY_APPLICANT_OPTIONS: Record<
  RelationshipToPrimaryApplicant,
  FieldOptionDetails
> = {
  CHILD: {
    isDefault: true,
    value: 'Child',
  },
  COMMON_LAW: {
    isDefault: true,
    value: 'CommonLaw',
  },
  GRANDCHILD: {
    isDefault: true,
    value: 'Grandchild',
  },
  GRANDPARENT: {
    isDefault: true,
    value: 'Grandparent',
  },
  PARENT: {
    isDefault: true,
    value: 'Parent',
  },
  RELATED: {
    isDefault: true,
    value: 'Related',
  },
  SIBLING: {
    isDefault: true,
    value: 'Sibling',
  },
  SPOUSE: {
    isDefault: true,
    value: 'Spouse',
  },
  OTHER: {
    isDefault: true,
    value: 'Other',
  },
};

// ApplicantKey.POLITICALLY_EXPOSED_PERSON_STATUS
export const POLITICALLY_EXPOSED_PERSON_OPTIONS: Record<
  PoliticallyExposedPersonType,
  FieldOptionDetails
> = {
  HIO: {
    isDefault: true,
    value: 'Head of an International Organization (HIO)',
  },
  NOT_A_PEP: {
    isDefault: true,
    value: 'Not a PEP',
  },
  PEDP_AND_HIO: {
    isDefault: true,
    value: 'PEDP & HIO',
  },
  PEFP_AND_HIO: {
    isDefault: true,
    value: 'PEFP & HIO',
  },
  PEDP: {
    isDefault: true,
    value: 'Politically Exposed Domestic Person (PEDP)',
  },
  PEFP: {
    isDefault: true,
    value: 'Politically Exposed Foreign Person (PEFP)',
  },
};

// ApplicantKey.FRAUD_AML_RISK
export const FRAUD_AML_RISK_OPTIONS: Record<FraudAMLRiskType, FieldOptionDetails> = {
  DEMARKETED_CLIENT: {
    isDefault: true,
    value: 'Demarketed Client (No New Business Permitted)',
  },
  HIGH_RISK_CLIENT: {
    isDefault: true,
    value: 'High Risk Client',
  },
  NO_NEW_BUSINESS: {
    isDefault: true,
    value: 'No New Business',
  },
  SATR_FIELD: {
    isDefault: true,
    value: 'SATR Filed',
  },
  STR_FIELD: {
    isDefault: true,
    value: 'STR Filed',
  },
};

// ApplicantKey.EQ_FRAUD_AML_RISK
export const EQ_FRAUD_AML_RISK_OPTIONS: Record<EQFraudAMLRiskType, FieldOptionDetails> = {
  [EQFraudAMLRiskType.HIGH_RISK]: {
    isDefault: true,
    value: 'High Risk',
  },
  [EQFraudAMLRiskType.HIGH_RISK_FOREIGN_PEP]: {
    isDefault: true,
    value: 'High Risk Foreign PEP',
  },
  [EQFraudAMLRiskType.HIGH_RISK_RE_CATEGORIZED]: {
    isDefault: true,
    value: 'High Risk Re-Categorized',
  },
  [EQFraudAMLRiskType.STANDARD_RISK]: {
    isDefault: true,
    value: 'Standard Risk',
  },
  [EQFraudAMLRiskType.STANDARD_RISK_DOMESTIC_PEP]: {
    isDefault: true,
    value: 'Standard Risk Domestic PEP',
  },
  [EQFraudAMLRiskType.STANDARD_RISK_RE_CATEGORIZED]: {
    isDefault: true,
    value: 'Standard Risk Re-Categorized',
  },
  [EQFraudAMLRiskType.THIRD_PARTY]: {
    isDefault: true,
    value: 'Third Party',
  },
  [EQFraudAMLRiskType.HIGH_RISK_WITH_STR]: {
    isDefault: true,
    value: 'High Risk with STR',
  },
  [EQFraudAMLRiskType.HIGH_RISK_PEP_WITH_STR]: {
    isDefault: true,
    value: 'High Risk PEP with STR',
  },
  [EQFraudAMLRiskType.DE_MARKETED_CLIENT]: {
    isDefault: true,
    value: 'De-Marketed Client',
  },
};

// ApplicantKey.THIRD_PARTY_DETERMINATION
export const THIRD_PARTY_DETERMINATION_OPTIONS: Record<
  ThirdPartyDeterminationType,
  FieldOptionDetails
> = {
  YES: {
    isDefault: true,
    value: 'Yes',
  },
  NO: {
    isDefault: true,
    value: 'No',
  },
};

// ApplicantKey.REQUEST_NAME_SCREENING
export const REQUEST_NAME_SCREENING_OPTIONS: Record<RequestNameScreeningType, FieldOptionDetails> =
  {
    [RequestNameScreeningType.YES]: {
      isDefault: true,
      value: 'Yes',
    },
    [RequestNameScreeningType.NO]: {
      isDefault: true,
      value: 'No',
    },
  };

// ApplicantKey.ACCOUNT_TYPE
export const ACCOUNT_OPTIONS: Record<AccountType, FieldOptionDetails> = {
  CHEQUING: {
    isDefault: true,
    value: 'Chequing',
  },
  SAVINGS: {
    isDefault: true,
    value: 'Savings',
  },
  [AccountType.MAXI]: { isDefault: false, value: 'Maxi' },
  [AccountType.CHEQ]: { isDefault: false, value: 'Cheq' },
  [AccountType.ADVNTGE]: { isDefault: false, value: 'Advntge' },
  [AccountType.OADV]: { isDefault: false, value: 'Oadv' },
};

// ApplicantKey.PAYMENT_SOURCE
export const PAYMENT_SOURCE_OPTIONS: Record<PaymentSource, FieldOptionDetails> = {
  EXTERNAL_ACCOUNT: {
    isDefault: true,
    value: 'External Account',
  },
  MERIDIAN_ACCOUNT: { isDefault: true, value: 'Meridian Account' },
};

// ApplicantKey.ACCOUNT_TYPE
export const FINANCIAL_INSTITUTION_OPTIONS: Record<FinancialInstitutionType, FieldOptionDetails> = {
  [FinancialInstitutionType.BANK_OF_MONTREAL]: {
    isDefault: true,
    value: 'Bank of Montreal',
  },
  [FinancialInstitutionType.SCOTIABANK]: {
    isDefault: true,
    value: 'Scotiabank (The Bank of Nova Scotia)',
  },
  [FinancialInstitutionType.ROYAL_BANK_OF_CANADA]: {
    isDefault: true,
    value: 'Royal Bank of Canada',
  },
  [FinancialInstitutionType.THE_TORONTO_DOMINION_BANK]: {
    isDefault: true,
    value: 'The Toronto-Dominion Bank',
  },
  [FinancialInstitutionType.NATIONAL_BANK_OF_CANADA]: {
    isDefault: true,
    value: 'National Bank of Canada',
  },
  [FinancialInstitutionType.CANADIAN_IMPERIAL_BANK_OF_COMMERCE]: {
    isDefault: true,
    value: 'Canadian Imperial Bank of Commerce',
  },
  [FinancialInstitutionType.HSBC_BANK_CANADA]: {
    isDefault: true,
    value: 'HSBC Bank Canada',
  },
  [FinancialInstitutionType.CANADIAN_WESTERN_BANK]: {
    isDefault: true,
    value: 'Canadian Western Bank',
  },
  [FinancialInstitutionType.LAURENTIAN_BANK_OF_CANADA]: {
    isDefault: true,
    value: 'Laurentian Bank of Canada',
  },
  [FinancialInstitutionType.BANK_OF_CANADA]: {
    isDefault: true,
    value: 'Bank of Canada',
  },
  [FinancialInstitutionType.ALBERTA_TREASURY_BRANCHES]: {
    isDefault: true,
    value: 'Alberta Treasury Branches',
  },
  [FinancialInstitutionType.ROYAL_BANK_OF_SCOTLAND_NV_CANADA_BRANCH]: {
    isDefault: true,
    value: 'Royal Bank of Scotland N.V. (Canada Branch)',
  },
  [FinancialInstitutionType.BANK_OF_AMERICA_NATIONAL_ASSOCIATION]: {
    isDefault: true,
    value: 'Bank of America, National Association',
  },
  [FinancialInstitutionType.THE_BANK_OF_NEW_YORK_MELLON]: {
    isDefault: true,
    value: 'The Bank of New York Mellon',
  },
  [FinancialInstitutionType.BANK_OF_TOKYO_MITSUBISHI_UFJ_CANADA]: {
    isDefault: true,
    value: 'Bank of Tokyo-Mitsubishi UFJ (Canada)',
  },
  [FinancialInstitutionType.BNP_PARIBAS_CANADA]: {
    isDefault: true,
    value: 'BNP Paribas (Canada)',
  },
  [FinancialInstitutionType.CITIBANK_CANADA]: {
    isDefault: true,
    value: 'Citibank Canada',
  },
  [FinancialInstitutionType.DEUTSCHE_BANK_AG]: {
    isDefault: true,
    value: 'Deutsche Bank AG',
  },
  [FinancialInstitutionType.MEGA_INTERNATIONAL_COMMERCIAL_BANK_CANADA]: {
    isDefault: true,
    value: 'Mega International Commercial Bank (Canada)',
  },
  [FinancialInstitutionType.JPMORGAN_CHASE_BANK_NATIONAL_ASSOCIATION]: {
    isDefault: true,
    value: 'JPMorgan Chase Bank National Association',
  },
  [FinancialInstitutionType.KOREA_EXCHANGE_BANK_OF_CANADA]: {
    isDefault: true,
    value: 'Korea Exchange Bank of Canada',
  },
  [FinancialInstitutionType.MIZUHO_CORPORATE_BANK_LTD_CANADA_BRANCH]: {
    isDefault: true,
    value: 'Mizuho Corporate Bank Ltd. Canada Branch',
  },
  [FinancialInstitutionType.UBS_BANK_CANADA]: {
    isDefault: true,
    value: 'UBS Bank (Canada)',
  },
  [FinancialInstitutionType.SOCIÉTÉ_GÉNÉRALE_CANADA_BRANCH]: {
    isDefault: true,
    value: 'Société Générale (Canada Branch)',
  },
  [FinancialInstitutionType.STATE_BANK_OF_INDIA_CANADA_ALBERTA]: {
    isDefault: true,
    value: 'State Bank of India (Canada) Alberta',
  },
  [FinancialInstitutionType.SUMITOMO_MITSUI_BANKING_CORPORATION_OF_CANADA]: {
    isDefault: true,
    value: 'Sumitomo Mitsui Banking Corporation of Canada',
  },
  [FinancialInstitutionType.AMEX_BANK_OF_CANADA]: {
    isDefault: true,
    value: 'Amex Bank of Canada',
  },
  [FinancialInstitutionType.INDUSTRIAL_AND_COMMERCIAL_BANK_OF_CHINA_CANADA]: {
    isDefault: true,
    value: 'Industrial and Commercial Bank of China (Canada)',
  },
  [FinancialInstitutionType.BANK_OF_CHINA_CANADA]: {
    isDefault: true,
    value: 'Bank of China (Canada)',
  },
  [FinancialInstitutionType.CITIZENS_BANK_OF_CANADA]: {
    isDefault: true,
    value: 'Citizens Bank of Canada',
  },
  [FinancialInstitutionType.FIRST_NATIONS_BANK_OF_CANADA]: {
    isDefault: true,
    value: 'First Nations Bank of Canada',
  },
  [FinancialInstitutionType.BOFA_CANADA_BANK]: {
    isDefault: true,
    value: 'BofA Canada Bank',
  },
  [FinancialInstitutionType.JP_MORGAN_BANK_CANADA]: {
    isDefault: true,
    value: 'J.P. Morgan Bank Canada',
  },
  [FinancialInstitutionType.CTC_BANK_OF_CANADA]: {
    isDefault: true,
    value: 'CTC Bank of Canada',
  },
  [FinancialInstitutionType.US_BANK_NATIONAL_ASSOCIATION]: {
    isDefault: true,
    value: 'U.S. Bank National Association',
  },
  [FinancialInstitutionType.HABIB_CANADIAN_BANK]: {
    isDefault: true,
    value: 'Habib Canadian Bank',
  },
  [FinancialInstitutionType.RABOBANK_NEDERLAND]: {
    isDefault: true,
    value: 'Rabobank Nederland',
  },
  [FinancialInstitutionType.CAPITAL_ONE_BANK_CANADA_BRANCH]: {
    isDefault: true,
    value: 'Capital One Bank (Canada Branch)',
  },
  [FinancialInstitutionType.PRESIDENTS_CHOICE_FINANCIAL]: {
    isDefault: true,
    value: "President's Choice Financial",
  },
  [FinancialInstitutionType.STATE_STREET]: {
    isDefault: true,
    value: 'State Street',
  },
  [FinancialInstitutionType.CITIBANK_NA]: {
    isDefault: true,
    value: 'Citibank N.A.',
  },
  [FinancialInstitutionType.COMERICA_BANK]: {
    isDefault: true,
    value: 'Comerica Bank',
  },
  [FinancialInstitutionType.FIRST_COMMERCIAL_BANK]: {
    isDefault: true,
    value: 'First Commercial Bank',
  },
  [FinancialInstitutionType.HSBC_BANK_USA_NATIONAL_ASSOCIATION]: {
    isDefault: true,
    value: 'HSBC Bank USA National Association',
  },
  [FinancialInstitutionType.PACIFIC_WESTERN_BANK_OF_CANADA]: {
    isDefault: true,
    value: 'Pacific & Western Bank of Canada',
  },
  [FinancialInstitutionType.UNITED_OVERSEAS_BANK_LIMITED]: {
    isDefault: true,
    value: 'United Overseas Bank Limited',
  },
  [FinancialInstitutionType.MAPLE_BANK]: {
    isDefault: true,
    value: 'Maple Bank',
  },
  [FinancialInstitutionType.CANADIAN_TIRE_BANK]: {
    isDefault: true,
    value: 'Canadian Tire Bank',
  },
  [FinancialInstitutionType.UBS_AG_CANADA_BRANCH]: {
    isDefault: true,
    value: 'UBS AG Canada Branch',
  },
  [FinancialInstitutionType.ICICI_BANK_CANADA]: {
    isDefault: true,
    value: 'ICICI Bank Canada',
  },
  [FinancialInstitutionType.BANK_WEST]: {
    isDefault: true,
    value: 'Bank West',
  },
  [FinancialInstitutionType.DUNDEE_BANK_OF_CANADA]: {
    isDefault: true,
    value: 'Dundee Bank of Canada',
  },
  [FinancialInstitutionType.GENERAL_BANK_OF_CANADA]: {
    isDefault: true,
    value: 'General Bank of Canada',
  },
  [FinancialInstitutionType.FIFTH_THIRD_BANK]: {
    isDefault: true,
    value: 'Fifth Third Bank',
  },
  [FinancialInstitutionType.SOCIÉTÉ_GÉNÉRALE_CANADA_BRANCH_ONTARIO]: {
    isDefault: true,
    value: 'Société Générale (Canada Branch) Ontario',
  },
  [FinancialInstitutionType.BRIDGEWATER_BANK]: {
    isDefault: true,
    value: 'Bridgewater Bank',
  },
  [FinancialInstitutionType.THE_NORTHERN_TRUST_COMPANY_CANADA_BRANCH]: {
    isDefault: true,
    value: 'The Northern Trust Company Canada Branch',
  },
  [FinancialInstitutionType.DIRECTCASH_BANK]: {
    isDefault: true,
    value: 'DirectCash Bank',
  },
  [FinancialInstitutionType.JAMESON_BANK]: {
    isDefault: true,
    value: 'Jameson Bank',
  },
  [FinancialInstitutionType.SHINHAN_BANK_CANADA]: {
    isDefault: true,
    value: 'Shinhan Bank Canada',
  },
  [FinancialInstitutionType.M_T_BANK]: {
    isDefault: true,
    value: 'M&T Bank',
  },
  [FinancialInstitutionType.HOMEQUITY_BANK]: {
    isDefault: true,
    value: 'HomEquity Bank',
  },
  [FinancialInstitutionType.WALMART_CANADA_BANK]: {
    isDefault: true,
    value: 'Walmart Canada Bank',
  },
  [FinancialInstitutionType.BARCLAYS_BANK_PLC_CANADA_BRANCH]: {
    isDefault: true,
    value: "Barclay's Bank PLC Canada Branch",
  },
  [FinancialInstitutionType.MONCANA_BANK_OF_CANADA]: {
    isDefault: true,
    value: 'MonCana Bank of Canada',
  },
  [FinancialInstitutionType.COMMUNITY_TRUST_COMPANY]: {
    isDefault: true,
    value: 'Community Trust Company',
  },
  [FinancialInstitutionType.THE_CANADA_TRUST_COMPANY]: {
    isDefault: true,
    value: 'The Canada Trust Company',
  },
  [FinancialInstitutionType.LAURENTIAN_TRUST_OF_CANADA_INC]: {
    isDefault: true,
    value: 'Laurentian Trust of Canada Inc.',
  },
  [FinancialInstitutionType.EFFORT_TRUST_COMPANY]: {
    isDefault: true,
    value: 'Effort Trust Company',
  },
  [FinancialInstitutionType.INVESTORS_GROUP_TRUST_CO_LTD]: {
    isDefault: true,
    value: 'Investors Group Trust Co. Ltd.',
  },
  [FinancialInstitutionType.MANULIFE_BANK_OF_CANADA]: {
    isDefault: true,
    value: 'Manulife Bank of Canada',
  },
  [FinancialInstitutionType.CIBC_TRUST_CORPORATION]: {
    isDefault: true,
    value: 'CIBC Trust Corporation',
  },
  [FinancialInstitutionType.MONTREAL_TRUST_COMPANY_OF_CANADA]: {
    isDefault: true,
    value: 'Montreal Trust Company of Canada',
  },
  [FinancialInstitutionType.SUN_LIFE_FINANCIAL_TRUST_INC]: {
    isDefault: true,
    value: 'Sun Life Financial Trust Inc.',
  },
  [FinancialInstitutionType.PEACE_HILLS_TRUST_COMPANY]: {
    isDefault: true,
    value: 'Peace Hills Trust Company',
  },
  [FinancialInstitutionType.ROYAL_TRUST_COMPANY]: {
    isDefault: true,
    value: 'Royal Trust Company',
  },
  [FinancialInstitutionType.ROYAL_TRUST_CORPORATION_OF_CANADA]: {
    isDefault: true,
    value: 'Royal Trust Corporation of Canada',
  },
  [FinancialInstitutionType.NATIONAL_TRUST_COMPANY]: {
    isDefault: true,
    value: 'National Trust Company',
  },
  [FinancialInstitutionType.ROYAL_BANK_MORTGAGE_CORPORATION]: {
    isDefault: true,
    value: 'Royal Bank Mortgage Corporation',
  },
  [FinancialInstitutionType.TD_MORTGAGE_CORPORATION]: {
    isDefault: true,
    value: 'TD Mortgage Corporation',
  },
  [FinancialInstitutionType.TD_PACIFIC_MORTGAGE_CORPORATION]: {
    isDefault: true,
    value: 'TD Pacific Mortgage Corporation',
  },
  [FinancialInstitutionType.HSBC_MORTGAGE_CORPORATION_CANADA]: {
    isDefault: true,
    value: 'HSBC Mortgage Corporation (Canada)',
  },
  [FinancialInstitutionType.SCOTIA_MORTGAGE_CORPORATION]: {
    isDefault: true,
    value: 'Scotia Mortgage Corporation',
  },
  [FinancialInstitutionType.CS_ALTERNA_BANK]: {
    isDefault: true,
    value: 'CS Alterna Bank',
  },
  [FinancialInstitutionType.ING_BANK_OF_CANADA]: {
    isDefault: true,
    value: 'ING Bank of Canada',
  },
  [FinancialInstitutionType.B2B_BANK_FORMERLY_B2B_TRUST]: {
    isDefault: true,
    value: 'B2B Bank (formerly B2B Trust)',
  },
  [FinancialInstitutionType.RESMOR_TRUST_COMPANY]: {
    isDefault: true,
    value: 'ResMor Trust Company',
  },
  [FinancialInstitutionType.PEOPLES_TRUST_COMPANY]: {
    isDefault: true,
    value: 'Peoples Trust Company',
  },
  [FinancialInstitutionType.THE_EQUITABLE_TRUST_COMPANY]: {
    isDefault: true,
    value: 'The Equitable Trust Company',
  },
  [FinancialInstitutionType.INDUSTRIAL_ALLIANCE_TRUST_INC]: {
    isDefault: true,
    value: 'Industrial Alliance Trust Inc.',
  },
  [FinancialInstitutionType.MANULIFE_TRUST_COMPANY]: {
    isDefault: true,
    value: 'Manulife Trust Company',
  },
  [FinancialInstitutionType.HOUSEHOLD_TRUST_COMPANY]: {
    isDefault: true,
    value: 'Household Trust Company',
  },
  [FinancialInstitutionType.LATVIAN_CREDIT_UNION_LIMITED]: {
    isDefault: true,
    value: 'Latvian Credit Union Limited',
  },
  [FinancialInstitutionType.COMMUNICATION_TECHNOLOGIES_CREDIT_UNION_LIMITED]: {
    isDefault: true,
    value: 'Communication Technologies Credit Union Limited',
  },
  [FinancialInstitutionType.ARNSTEIN_COMMUNITY_CREDIT_UNION_LIMITED]: {
    isDefault: true,
    value: 'Arnstein Community Credit Union Limited',
  },
  [FinancialInstitutionType.CENTRAL_1_CREDIT_UNION_BRITISH_COLUMBIA]: {
    isDefault: true,
    value: 'Central 1 Credit Union British Columbia',
  },
  [FinancialInstitutionType.ALL_TRANS_FINANCIAL_SERVICES_CREDIT_UNION_LIMITED]: {
    isDefault: true,
    value: 'All Trans Financial Services Credit Union Limited',
  },
  [FinancialInstitutionType.LA_CONFÉDÉRATION_DES_CAISSES_POPULAIRES_ET_DÉCONOMIE_DESJARDINS_DU_QUÉBEC]:
    {
      isDefault: true,
      value: "La Confédération des Caisses Populaires et D'Économie Desjardins du Québec",
    },
  [FinancialInstitutionType.LA_FÉDÉRATION_DES_CAISSES_POPULAIRES_DU_MANITOBA_INC]: {
    isDefault: true,
    value: 'La Fédération des caisses populaires du Manitoba Inc.',
  },
  [FinancialInstitutionType.CENTRAL_1_CREDIT_UNION_ONTARIO]: {
    isDefault: true,
    value: 'Central 1 Credit Union Ontario',
  },
  [FinancialInstitutionType.LA_FÉDÉRATION_DES_CAISSES_POPULAIRES_DE_LONTARIO_INC]: {
    isDefault: true,
    value: "La Fédération des Caisses Populaires de l'Ontario Inc.",
  },
  [FinancialInstitutionType.AIRLINE_FINANCIAL_CREDIT_UNION_LIMITED]: {
    isDefault: true,
    value: 'Airline Financial Credit Union Limited',
  },
  [FinancialInstitutionType.MERIDIAN_CREDIT_UNION]: {
    isDefault: true,
    value: 'Meridian Credit Union',
  },
  [FinancialInstitutionType.ATLANTIC_CENTRAL]: {
    isDefault: true,
    value: 'Atlantic Central',
  },
  [FinancialInstitutionType.DUNDALK_DISTRICT_CREDIT_UNION_LIMITED]: {
    isDefault: true,
    value: 'Dundalk District Credit Union Limited',
  },
  [FinancialInstitutionType.ALTERNA_SAVINGS_AND_CREDIT_UNION]: {
    isDefault: true,
    value: 'Alterna Savings and Credit Union',
  },
  [FinancialInstitutionType.GODERICH_COMMUNITY_CREDIT_UNION_LIMITED]: {
    isDefault: true,
    value: 'Goderich Community Credit Union Limited',
  },
  [FinancialInstitutionType.ONTARIO_CIVIL_SERVICE_CREDIT_UNION_LIMITED]: {
    isDefault: true,
    value: 'Ontario Civil Service Credit Union Limited',
  },
  [FinancialInstitutionType.CONCENTRA_FINANCIAL_SERVICES_ASSOCIATION]: {
    isDefault: true,
    value: 'Concentra Financial Services Association',
  },
  [FinancialInstitutionType.GOLDEN_HORSESHOE_CREDIT_UNION_LIMITED]: {
    isDefault: true,
    value: 'Golden Horseshoe Credit Union Limited',
  },
  [FinancialInstitutionType.LA_FÉDÉRATION_DES_CAISSES_POPULAIRES_ACADIENNES_LIMITÉE]: {
    isDefault: true,
    value: 'La Fédération des Caisses Populaires Acadiennes Limitée',
  },
  [FinancialInstitutionType.CREDIT_UNION_CENTRAL_OF_MANITOBA_LIMITED]: {
    isDefault: true,
    value: 'Credit Union Central of Manitoba Limited',
  },
  [FinancialInstitutionType.CREDIT_UNION_CENTRAL_OF_SASKATCHEWAN]: {
    isDefault: true,
    value: 'Credit Union Central of Saskatchewan',
  },
  [FinancialInstitutionType.ALLIANCE_DES_CAISSES_POPULAIRES_DE_LONTARIO_LIMITEE]: {
    isDefault: true,
    value: "Alliance des caisses populaires de l'Ontario Limitée",
  },
  [FinancialInstitutionType.CREDIT_UNION_CENTRAL_ALBERTA_LIMITED]: {
    isDefault: true,
    value: 'Credit Union Central Alberta Limited',
  },
  [FinancialInstitutionType.MOTUS_BANK]: { isDefault: true, value: 'Motus Bank' },
  [FinancialInstitutionType.COMMUNITY_FIRST_CREDIT_UNION]: {
    isDefault: true,
    value: 'Community First Credit Union',
  },
  [FinancialInstitutionType.DESJARDINS_CREDIT_UNION]: {
    isDefault: true,
    value: 'Desjardins Credit Union',
  },
  [FinancialInstitutionType.DUCA_FINANCIAL_SERVICES_CREDIT_UNION]: {
    isDefault: true,
    value: 'DUCA Financial Services Credit Union',
  },
  [FinancialInstitutionType.LIBRO_CREDIT_UNION]: { isDefault: true, value: 'Libro Credit Union' },
  [FinancialInstitutionType.MBNA_CANADA_BANK]: { isDefault: true, value: 'MBNA Canada Bank' },
  [FinancialInstitutionType.TANDIA_CREDIT_UNION]: { isDefault: true, value: 'Tandia Credit Union' },
  [FinancialInstitutionType.WEALTH_SIMPLE]: { isDefault: true, value: 'Wealth Simple' },
  [FinancialInstitutionType.OTHER]: { isDefault: true, value: 'Other' },
};

// ApplicantKey.INCOME_VERIFICATION
export const INCOME_VERIFICATION_OPTIONS: Record<IncomeVerificationType, FieldOptionDetails> = {
  [IncomeVerificationType.NOT_APPLICABLE]: {
    isDefault: true,
    value: 'Not Applicable',
  },
  [IncomeVerificationType.BUSINESS_FINANCIAL_STATEMENT]: {
    isDefault: true,
    value: 'Business Financial Statement',
  },
  [IncomeVerificationType.COMMISSION_STATEMENT]: {
    isDefault: true,
    value: 'Commission Statement',
  },
  [IncomeVerificationType.LEASE_AGREEMENTS]: {
    isDefault: true,
    value: 'Lease Agreements',
  },
  [IncomeVerificationType.LESS_THAN_12_MONTHS_BANK_STMT]: {
    isDefault: true,
    value: 'Less than 12 months bank stmt',
  },
  [IncomeVerificationType.EMPLOYMENT_LETTER_AND_PAYSTUB]: {
    isDefault: true,
    value: 'Employment Letter and Paystub',
  },
  [IncomeVerificationType.EMPLOYMENT_LETTER]: {
    isDefault: true,
    value: 'Employment Letter',
  },
  [IncomeVerificationType.MULTIPLE]: {
    isDefault: true,
    value: 'Multiple (see loan comment)',
  },
  [IncomeVerificationType.NOTICE_OF_ASSESSMENT]: {
    isDefault: true,
    value: 'Notice of Assessment',
  },
  [IncomeVerificationType.OTHER]: {
    isDefault: true,
    value: 'Other (see loan comments)',
  },
  [IncomeVerificationType.PAY_STUB]: {
    isDefault: true,
    value: 'Pay Stub',
  },
  [IncomeVerificationType.PENSION_STATEMENT]: {
    isDefault: true,
    value: 'Pension Statement',
  },
  [IncomeVerificationType.SELF_DECLARED_LETTER]: {
    isDefault: true,
    value: 'Self Declared Letter',
  },
  [IncomeVerificationType.T4]: {
    isDefault: true,
    value: 'T4',
  },
};

// ApplicationKey.PURPOSE
export const APPLICATION_PURPOSE_OPTIONS: Record<ApplicationPurposeType, FieldOptionDetails> = {
  BRIDGE_FINANCING: {
    isDefault: true,
    value: 'Bridge Financing',
  },
  CONSTRUCTION: {
    isDefault: true,
    value: 'Construction',
  },
  DEFICIENCY_SALE: {
    isDefault: true,
    value: 'Deficiency Sale',
  },
  ETO: {
    isDefault: true,
    value: 'ETO',
  },
  PURCHASE: {
    isDefault: true,
    value: 'Purchase',
  },
  REFINANCE: {
    isDefault: true,
    value: 'Refinance',
  },
  RENEWAL: {
    isDefault: true,
    value: 'Renewal',
  },
  SWITCH_TRANSFER: {
    isDefault: true,
    value: 'Switch/Transfer',
  },
  OTHER: {
    isDefault: true,
    value: 'Other',
  },
  WORKOUT: {
    isDefault: true,
    value: 'Workout',
  },
  PAYMENT_CHANGE: {
    isDefault: true,
    value: 'Payment Change',
  },
  SERVICING_RENEWAL: {
    isDefault: true,
    value: 'Servicing Renewal',
  },
  COST_OF_BORROWING: {
    isDefault: true,
    value: 'Cost of Borrowing',
  },
};

// ApplicationKey.MORTGAGE_CLASSIFICATION
export const MORTGAGE_CLASSIFICATION_OPTIONS: Record<
  MortgageClassificationType,
  FieldOptionDetails
> = {
  ASSUMPTION: {
    isDefault: true,
    value: 'Assumption',
  },
  BRIDGE_FINANCING_WITH_PURCHASE: {
    isDefault: true,
    value: 'Bridge Financing with Purchase',
  },
  BRIDGE_FINANCING_WITHOUT_PURCHASE: {
    isDefault: true,
    value: 'Bridge Financing without Purchase',
  },
  CONSTRUCTION: {
    isDefault: true,
    value: 'Construction',
  },
  CONSTRUCTION_EXISTING_PROPERTY: {
    isDefault: true,
    value: 'Construction - Existing Property',
  },
  CONSTRUCTION_NEW_BUILD: {
    isDefault: true,
    value: 'Construction - New Build',
  },
  CONSTRUCTION_TAKEOVER: {
    isDefault: true,
    value: 'Construction - Takeover',
  },
  DEFICIENCY_SALE: {
    isDefault: true,
    value: 'Deficiency Sale',
  },
  ETO: {
    isDefault: true,
    value: 'ETO',
  },
  FLIP: {
    isDefault: true,
    value: 'Flip',
  },
  HELOC: {
    isDefault: true,
    value: 'HELOC',
  },
  PORT: {
    isDefault: true,
    value: 'Port',
  },
  PURCHASE: {
    isDefault: true,
    value: 'Purchase',
  },
  PURCHASE_PLUS_IMPROVEMENT: {
    isDefault: true,
    value: 'Purchase plus Improvements',
  },
  NEW_BUILD: {
    isDefault: true,
    value: 'New Build',
  },
  PRIVATE: {
    isDefault: true,
    value: 'Private',
  },
  RENT_TO_OWN: {
    isDefault: true,
    value: 'Rent to Own',
  },
  PURCHASE_WITH_HELOC: {
    isDefault: true,
    value: 'Purchase with HELOC',
  },
  REFINANCE: {
    isDefault: true,
    value: 'Refinance',
  },
  RENEWAL: {
    isDefault: true,
    value: 'Renewal',
  },
  REVERSE_MORTGAGE: {
    isDefault: true,
    value: 'Reverse Mortgage',
  },
  SWITCH_TRANSFER_COLLATERAL: {
    isDefault: true,
    value: 'Switch Transfer Collateral',
  },
  SWITCH_TRANSFER_STANDARD: {
    isDefault: true,
    value: 'Switch Transfer Standard',
  },
  OTHER: {
    isDefault: true,
    value: 'Other',
  },
  WORKOUT: {
    isDefault: true,
    value: 'Workout',
  },
};

// ApplicationKey.TYPE
export const APPLICATION_TYPE_OPTIONS: Record<ApplicationType, FieldOptionDetails> = {
  APPROVAL: {
    isDefault: true,
    value: 'Approval',
  },
  PREAPPROVAL: {
    isDefault: true,
    value: 'Pre-approval',
  },
};

// PropertyKey.DWELLING_STYLE
export const DWELLING_STYLE_OPTIONS: Record<DwellingStyle, FieldOptionDetails> = {
  ONE_STORY: {
    isDefault: true,
    value: 'One Storey',
  },
  TWO_STORY: {
    isDefault: true,
    value: 'Two Storey',
  },
  THREE_STORY: {
    isDefault: true,
    value: 'Three Storey',
  },
  BI_LEVEL: {
    isDefault: true,
    value: 'Bi-Level',
  },
  SPLIT_LEVEL: {
    isDefault: true,
    value: 'Split Level',
  },
  ONE_AND_ONE_HALF_STORY: {
    isDefault: true,
    value: 'One and One Half Storey',
  },
  OTHER: {
    isDefault: true,
    value: 'Other',
  },
};

// PropertyKey.CONSTRUCTION_TYPE
export const CONSTRUCTION_TYPE_OPTIONS: Record<ConstructionType, FieldOptionDetails> = {
  NEW: {
    isDefault: true,
    value: 'New',
  },
  EXISTING: {
    isDefault: true,
    value: 'Existing',
  },
};

// PropertyKey.PROPERTY_TYPE
export const PROPERTY_TYPE_OPTIONS: Record<PropertyTypes, FieldOptionDetails> = {
  PRIMARY_RESIDENCE: {
    isDefault: true,
    value: 'Primary Residence',
  },
  INVESTMENT_PROPERTY: {
    isDefault: true,
    value: 'Investment property',
  },
  MULTI_UNIT: {
    isDefault: true,
    value: 'Multi-unit',
  },
  SECOND_HOME: {
    isDefault: true,
    value: 'Second Home',
  },
  COTTAGE: {
    isDefault: true,
    value: 'Cottage',
  },
  MOBILE_MODULAR: {
    isDefault: true,
    value: 'Mobile / Modular',
  },
};

// PropertyKey.DWELLING_TYPE
export const DWELLING_TYPE_OPTIONS: Record<DwellingType, FieldOptionDetails> = {
  DETACHED: {
    isDefault: true,
    value: 'Detached',
  },
  SEMI_DETACHED: {
    isDefault: true,
    value: 'Semi-detached',
  },
  DUPLEX_DETACHED: {
    isDefault: true,
    value: 'Duplex - Detached',
  },
  DUPLEX_SEMI_DETACHED: {
    isDefault: true,
    value: 'Duplex - Semi-detached',
  },
  DUPLEX: {
    isDefault: true,
    value: 'Duplex',
  },
  ROW_HOUSING: {
    isDefault: true,
    value: 'Row Housing',
  },
  APARTMENT_LOW_RISE: {
    isDefault: true,
    value: 'Apartment Low Rise',
  },
  APARTMENT_HIGH_RISE: {
    isDefault: true,
    value: 'Apartment High Rise',
  },
  APARTMENT: {
    isDefault: true,
    value: 'Apartment',
  },
  TOWNHOUSE: {
    isDefault: true,
    value: 'Townhouse',
  },
  STRIP: {
    isDefault: true,
    value: 'Strip',
  },
  HIGH_RISE: {
    isDefault: true,
    value: 'High Rise',
  },
  STACKED: {
    isDefault: true,
    value: 'Stacked',
  },
  MOBILE: {
    isDefault: true,
    value: 'Mobile',
  },
  TRIPLEX_DETACHED: {
    isDefault: true,
    value: 'Triplex - Detached',
  },
  TRIPLEX_SEMI_DETACHED: {
    isDefault: true,
    value: 'Triplex - Semi-detached',
  },
  TRIPLEX: {
    isDefault: true,
    value: 'Triplex',
  },
  FOURPLEX_DETACHED: {
    isDefault: true,
    value: 'Fourplex - Detached',
  },
  FOURPLEX_SEMI_DETACHED: {
    isDefault: true,
    value: 'Fourplex - Semi-detached',
  },
  FOURPLEX: {
    isDefault: true,
    value: 'Fourplex',
  },
  FIVEPLEX: {
    isDefault: true,
    value: 'Fiveplex',
  },
  SIXPLEX: {
    isDefault: true,
    value: 'Sixplex',
  },
  MODULAR_HOME_DETACHED: {
    isDefault: true,
    value: 'Modular Home - Detached',
  },
  MODULAR_HOME_SEMI_DETACHED: {
    isDefault: true,
    value: 'Modular Home - Semi-detached',
  },
  MODULAR_HOME: {
    isDefault: true,
    value: 'Modular Home',
  },
  COOP: {
    isDefault: true,
    value: 'Co-op',
  },
  SINGLE: {
    isDefault: true,
    value: 'Single',
  },
  CONDOMINIUM: {
    isDefault: true,
    value: 'Condominium',
  },
  OTHER: {
    isDefault: true,
    value: 'Other',
  },
};

// PropertyKey.FOUNDATION_TYPE
export const FOUNDATION_TYPE_OPTIONS: Record<FoundationType, FieldOptionDetails> = {
  BASEMENT: {
    isDefault: true,
    value: 'Basement',
  },
  CRAWLSPACE: {
    isDefault: true,
    value: 'CrawlSpace',
  },
  PIER: {
    isDefault: true,
    value: 'Pier',
  },
  SLAB_ON_GRADE: {
    isDefault: true,
    value: 'Slab on Grade',
  },
  WALKOUT_BASEMENT: {
    isDefault: true,
    value: 'Walkout Basement',
  },
};

// PropertyKey.GARAGE_SIZE
export const GARAGE_SIZE_OPTIONS: Record<GarageSize, FieldOptionDetails> = {
  NONE: {
    isDefault: true,
    value: 'None',
  },
  SINGLE: {
    isDefault: true,
    value: 'Single',
  },
  DOUBLE: {
    isDefault: true,
    value: 'Double',
  },
  TRIPLE: {
    isDefault: true,
    value: 'Triple',
  },
};

// PropertyKey.GARAGE_TYPE
export const GARAGE_TYPE_OPTIONS: Record<GarageType, FieldOptionDetails> = {
  NONE: {
    isDefault: true,
    value: 'None',
  },
  ATTACHED_GARAGE: {
    isDefault: true,
    value: 'Attached',
  },
  BUILT_IN_GARAGE: {
    isDefault: true,
    value: 'Built-in',
  },
  BASEMENT_GARAGE: {
    isDefault: true,
    value: 'Basement',
  },
  DETACHED_GARAGE: {
    isDefault: true,
    value: 'Detached',
  },
};

// PropertyKey.SEWAGE_INFO
export const SEWAGE_INFO_OPTIONS: Record<SewageType, FieldOptionDetails> = {
  MUNICIPAL: {
    isDefault: true,
    value: 'Municipal',
  },
  SEPTIC_SYSTEM: {
    isDefault: true,
    value: 'Septic System',
  },
  HOLDING_TANK: {
    isDefault: true,
    value: 'Holding Tank',
  },
  UNKNOWN: {
    isDefault: true,
    value: 'Unknown',
  },
};

// PropertyKey.WATER_TYPE
export const WATER_TYPE_OPTIONS: Record<WaterType, FieldOptionDetails> = {
  MUNICIPAL: {
    isDefault: true,
    value: 'Municipal',
  },
  CISTERN: {
    isDefault: true,
    value: 'Cistern',
  },
  WELL: {
    isDefault: true,
    value: 'Well',
  },
  NON: {
    isDefault: true,
    value: 'Non',
  },
  PRIVATE: {
    isDefault: true,
    value: 'Private',
  },
  OTHER: {
    isDefault: true,
    value: 'Other',
  },
};

// PropertyKey.TENURE
export const TENURE_OPTIONS: Record<TenureType, FieldOptionDetails> = {
  FREEHOLD: {
    isDefault: true,
    value: 'FreeHold',
  },
  LEASEHOLD: {
    isDefault: true,
    value: 'LeaseHold',
  },
  CONDO: {
    isDefault: true,
    value: 'Condo',
  },
  OTHER: {
    isDefault: true,
    value: 'Other',
  },
};

// PropertyKey.HEATING_TYPE
export const HEATING_TYPE_OPTIONS: Record<HeatingType, FieldOptionDetails> = {
  ELECTRIC_BASEBOARD: {
    isDefault: true,
    value: 'Electric Baseboard',
  },
  FORCED_AIR_GAS_OIL_ELECTRIC: {
    isDefault: true,
    value: 'Forced Air Gas/Oil/Electric',
  },
  HOT_WATER_HEATING: {
    isDefault: true,
    value: 'Hot Water Heating',
  },
  GEOTHERMAL: {
    isDefault: true,
    value: 'Geothermal',
  },
  OIL: {
    isDefault: true,
    value: 'Oil',
  },
  OTHER: {
    isDefault: true,
    value: 'Other',
  },
};

// PropertyKey.OCCUPANCY
export const OCCUPANCY_OPTIONS: Record<OccupancyType, FieldOptionDetails> = {
  OWNER_OCCUPIED: {
    isDefault: true,
    value: 'Owner-Occupied',
  },
  OWNER_OCCUPIED_RENTAL: {
    isDefault: true,
    value: 'Owner-Occupied & Rental',
  },
  RENTAL: {
    isDefault: true,
    value: 'Rental',
  },
  SECOND_HOME: {
    isDefault: true,
    value: 'Second Home',
  },
  LIVE_WITH_PARENT: {
    isDefault: true,
    value: 'Live With Parent',
  },
  OTHER: {
    isDefault: true,
    value: 'Other',
  },
};

// PropertyKey.ZONING_TYPE
export const ZONING_TYPE_OPTIONS: Record<ZoningTypes, FieldOptionDetails> = {
  COMMERCIAL: {
    isDefault: true,
    value: 'Commercial',
  },
  INDUSTRIAL: {
    isDefault: true,
    value: 'Industrial',
  },
  LAND: {
    isDefault: true,
    value: 'Land',
  },
  RESIDENTIAL: {
    isDefault: true,
    value: 'Residential',
  },
  RESCOMMERCIAL: {
    isDefault: true,
    value: 'Residential / Commercial',
  },
};

// PropertyKey.LOCATION_TIER
export const LOCATION_TIER_OPTIONS: Record<LocationTierType, FieldOptionDetails> = {
  TIER_1: {
    isDefault: true,
    value: 'Tier 1',
  },
  TIER_2: {
    isDefault: true,
    value: 'Tier 2',
  },
  TIER_3: {
    isDefault: true,
    value: 'Tier 3',
  },
};

// PropertyKey.TAXES_PAID_BY
export const TAXES_PAID_BY: Record<TaxesPaidByType, FieldOptionDetails> = {
  BORROWER: {
    isDefault: true,
    value: 'Borrower',
  },
  LENDER: {
    isDefault: true,
    value: 'Lender',
  },
  PAID_WITH_ANOTHER_MORTGAGE: {
    isDefault: true,
    value: 'Paid with another mortgage',
  },
};

// PropertyKey.TAX_FIRST_PAYMENT_TYPE
export const TAX_FIRST_PAYMENT_TYPE_OPTIONS: Record<TaxFirstPaymentType, FieldOptionDetails> = {
  FINAL: {
    isDefault: true,
    value: 'Final',
  },
  INTERIM: {
    isDefault: true,
    value: 'Interim',
  },
  SUPPLEMENTARY: {
    isDefault: true,
    value: 'Supplementary',
  },
};

// PropertyKey.RPE_RENTAL_OFFSET_OPTION
export const RENTAL_OFFSET_OPTIONS: Record<RentalOffsetType, FieldOptionDetails> = {
  NONE: {
    isDefault: true,
    value: 'None',
  },
  REDUCE_RENTAL_EXPENSES: {
    isDefault: true,
    value: 'Reduce Rental exp., add bal to Gross Inc.',
  },
  ADD_PERCENTAGE_TO_GROSS_INCOME: {
    isDefault: true,
    value: 'Add Percentage to Gross Income',
  },
};

// FinancialAssetKey.ASSET
export const FINANCIAL_ASSET_OPTIONS: Record<AssetType, FieldOptionDetails> = {
  VEHICLE: {
    isDefault: true,
    value: 'Vehicle',
  },
  RRSP: {
    isDefault: true,
    value: 'RRSP',
  },
  STOCKS_BONDS_MUTUAL: {
    isDefault: true,
    value: 'Stocks/Bonds/Mutual',
  },
  STOCKS: {
    isDefault: true,
    value: 'Stocks',
  },
  MUTUAL_FUNDS: {
    isDefault: true,
    value: 'Mutual Funds',
  },
  HOUSEHOLD_GOODS: {
    isDefault: true,
    value: 'Household Goods',
  },
  CASH_SAVING: {
    isDefault: true,
    value: 'Cash Saving',
  },
  CASH_CHEQUING: {
    isDefault: true,
    value: 'Cash Chequing',
  },
  SAVINGS: {
    isDefault: true,
    value: 'Savings',
  },
  DEPOSIT_ON_PURCHASE: {
    isDefault: true,
    value: 'Deposit on Purchase',
  },
  GIC_TERM_DEPOSITS: {
    isDefault: true,
    value: 'GIC Term Deposits',
  },
  LIFE_INSURANCE: {
    isDefault: true,
    value: 'Life Insurance',
  },
  GIFT: {
    isDefault: true,
    value: 'Gift',
  },
  SWEAT_EQUITY: {
    isDefault: true,
    value: 'Sweat Equity',
  },
  PROPERTY: {
    isDefault: true,
    value: 'Property',
  },
  RENTAL_PROPERTY: {
    isDefault: true,
    value: 'Rental Property',
  },
  OTHER: {
    isDefault: true,
    value: 'Other',
  },
};

// FinancialAssetKey.EXCLUDE_ASSET_FROM_CALCULATION
export const EXCLUDE_ASSET_FROM_CALCULATION_REASON_TYPE_OPTIONS: Record<
  ExcludeAssetFromCalculationReasonType,
  FieldOptionDetails
> = {
  PAID_BY_OTHER_SOURCE: {
    isDefault: true,
    value: 'Paid by Other Source',
  },
  FINAL_PAYMENT: {
    isDefault: true,
    value: 'Final Payment',
  },
  DUPLICATE_ITEM: {
    isDefault: true,
    value: 'Duplicate Item',
  },
  OMIT_FROM_GDS: {
    isDefault: true,
    value: 'Omit from GDS',
  },
};

// FinancialLiabilityKey.LIABILITY
export const LIABILITY_TYPE_OPTIONS: Record<LiabilityType, FieldOptionDetails> = {
  CREDIT: {
    isDefault: true,
    value: 'Credit',
  },
  PERSONAL_LOAN: {
    isDefault: true,
    value: 'Personal Loan',
  },
  AUTO_LOAN: {
    isDefault: true,
    value: 'Auto Loan',
  },
  ALIMONY: {
    isDefault: true,
    value: 'Alimony',
  },
  CHILD_SUPPORT: {
    isDefault: true,
    value: 'Child Support',
  },
  STUDENT_LOAN: {
    isDefault: true,
    value: 'Student Loan',
  },
  WAGE_GARNISHMENT: {
    isDefault: true,
    value: 'Wage Garnishment',
  },
  UNSECURED_LINE_OF_CREDIT: {
    isDefault: true,
    value: 'Unsecured Line of Credit',
  },
  INCOME_TAX: {
    isDefault: true,
    value: 'Income Tax',
  },
  SECURED_LINE_OF_CREDIT: {
    isDefault: true,
    value: 'Secured Line of Credit',
  },
  LEASE: {
    isDefault: true,
    value: 'Lease',
  },
  AUTO_LEASE: {
    isDefault: true,
    value: 'Auto Lease',
  },
  MORTGAGE: {
    isDefault: true,
    value: 'Mortgage',
  },
  JUDGMENT: {
    isDefault: true,
    value: 'Judgment',
  },
  OVERDUE_TAXES: {
    isDefault: true,
    value: 'Overdue Taxes',
  },
  CREDIT_CARD: {
    isDefault: true,
    value: 'Credit Card',
  },
  RENT: {
    isDefault: true,
    value: 'Rent',
  },
  PROPERTY_FIRE_INSURANCE: {
    isDefault: true,
    value: 'Property Fire Insurance',
  },
  PROPERTY_REPAIRS: {
    isDefault: true,
    value: 'Property Repairs',
  },
  PROPERTY_HYDRO: {
    isDefault: true,
    value: 'Property Hydro',
  },
  PROPERTY_MANAGEMENT: {
    isDefault: true,
    value: 'Property Management',
  },
  PROPERTY_GENERAL: {
    isDefault: true,
    value: 'Property General',
  },
  PROPERTY_INTEREST: {
    isDefault: true,
    value: 'Property Interest',
  },
  OTHER: {
    isDefault: true,
    value: 'Other',
  },
};

// FinancialLiabilityKey.PAYOFF_PAYDOWN
export const PAYOFF_PAYDOWN_OPTIONS: Record<PayoffPaydownType, FieldOptionDetails> = {
  PAYOFF: {
    isDefault: true,
    value: 'Payoff',
  },
  PAYDOWN: {
    isDefault: true,
    value: 'Paydown',
  },
  PAYDOWN_INCREASE_LIMIT: {
    isDefault: true,
    value: 'Paydown & Increase Limit',
  },
  PAYDOWN_REDUCE_LIMIT: {
    isDefault: true,
    value: 'Paydown & Reduce Limit',
  },
  NONE: {
    isDefault: true,
    value: 'None',
  },
};

// FinancialLiabilityKey.PAYOFF
export const PAYOFF_OPTIONS: Record<PayoffType, FieldOptionDetails> = {
  PRIOR_TO_ADVANCE: {
    isDefault: true,
    value: 'Prior to advance',
  },
  FROM_PROCEEDS: {
    isDefault: true,
    value: 'From proceeds',
  },
  NONE: {
    isDefault: true,
    value: 'None',
  },
};

// FinancialLiabilityKey.Source
export const LIABILITY_SOURCE_OPTIONS: Record<FinancialLiabilitySource, FieldOptionDetails> = {
  POS_BROKER: {
    isDefault: true,
    value: 'Pos broker',
  },
  POS_CREDIT_BUREAU: {
    isDefault: true,
    value: 'Pos Credit Bureau',
  },
  CREDIT_BUREAU: {
    isDefault: true,
    value: 'Credit Bureau',
  },
  EQUIFAX: {
    isDefault: true,
    value: 'Equifax',
  },
  MANUAL: {
    isDefault: true,
    value: 'Manual',
  },
  MORTGAGE_APPLICATION: {
    isDefault: true,
    value: 'Mortgage Application',
  },
  IDS_BANKING: {
    isDefault: true,
    value: 'IDS Banking',
  },
};

export const RESPONSIBLE_OPTIONS: Record<ResponsibleType, FieldOptionDetails> = {
  BROKER: {
    isDefault: true,
    value: 'Broker',
  },
  LAWYER: {
    isDefault: true,
    value: 'Lawyer',
  },
};

// FinancialLiabilityKey.EXCLUDE_LIABILITY_FROM_CALCULATION
export const EXCLUDE_LIABILITY_FROM_CALCULATION_REASON_TYPE_OPTIONS: Record<
  ExcludeLiabilityFromCalculationReasonType,
  FieldOptionDetails
> = {
  PAID_BY_OTHER_SOURCE: {
    isDefault: true,
    value: 'Paid by Other Source',
  },
  FINAL_PAYMENT: {
    isDefault: true,
    value: 'Final Payment',
  },
  DUPLICATE_ITEM: {
    isDefault: true,
    value: 'Duplicate Item',
  },
  OMIT_FROM_TDS: {
    isDefault: true,
    value: 'Omit from TDS',
  },
};

// DownPaymentKey.SOURCE
export const DOWN_PAYMENT_SOURCE_OPTIONS: Record<DownPaymentType, FieldOptionDetails> = {
  SALE_OF_EXISTING_PROPERTY: {
    isDefault: true,
    value: 'Sale of Existing Property',
  },
  PERSONAL_CASH: {
    isDefault: true,
    value: 'Personal Cash',
  },
  RRSP: {
    isDefault: true,
    value: 'RRSP',
  },
  SELF_DIRECTED_RRSP: {
    isDefault: true,
    value: 'Self-Directed RRSP',
  },
  BORROWED_AGAINST_LIQUID_ASSETS: {
    isDefault: true,
    value: 'Borrowed Against Liquid Assets',
  },
  GIFT: {
    isDefault: true,
    value: 'Gift',
  },
  SWEAT_EQUITY: {
    isDefault: true,
    value: 'Sweat Equity',
  },
  EXISTING_EQUITY: {
    isDefault: true,
    value: 'Existing Equity',
  },
  SECONDARY_FINANCING: {
    isDefault: true,
    value: 'Secondary Financing',
  },
  GRANTS: {
    isDefault: true,
    value: 'Grants',
  },
  LOAN: {
    isDefault: true,
    value: 'Loan',
  },
  RENT: {
    isDefault: true,
    value: 'Rent',
  },
  OTHER: {
    isDefault: true,
    value: 'Other',
  },
};

// JobKey.INDUSTRY_SECTOR
export const INDUSTRY_SECTOR_OPTIONS: Record<IndustrySectorType, FieldOptionDetails> = {
  [IndustrySectorType.CONSTRUCTION]: {
    isDefault: true,
    value: 'Construction',
  },
  [IndustrySectorType.GOVERNMENT]: {
    isDefault: true,
    value: 'Government',
  },
  [IndustrySectorType.HEALTH]: {
    isDefault: true,
    value: 'Health',
  },
  [IndustrySectorType.EDUCATION]: {
    isDefault: true,
    value: 'Education',
  },
  [IndustrySectorType.FARMING]: {
    isDefault: true,
    value: 'Farming',
  },
  [IndustrySectorType.HI_TECH]: {
    isDefault: true,
    value: 'Hi-Tech',
  },
  [IndustrySectorType.RETAIL_SALES]: {
    isDefault: true,
    value: 'Retail Sales',
  },
  [IndustrySectorType.LEISURE_ENTERTAINMENT]: {
    isDefault: true,
    value: 'Leisure / Entertainment',
  },
  [IndustrySectorType.BANKING_FINANCE]: {
    isDefault: true,
    value: 'Banking / Finance',
  },
  [IndustrySectorType.TRANSPORT]: {
    isDefault: true,
    value: 'Transport',
  },
  [IndustrySectorType.SERVICE]: {
    isDefault: true,
    value: 'Services',
  },
  [IndustrySectorType.MANUFACTURING]: {
    isDefault: true,
    value: 'Manufacturing',
  },
  [IndustrySectorType.FARMING_NATURAL_RESOURCES]: {
    isDefault: true,
    value: 'Farming / Natural Resources',
  },
  [IndustrySectorType.NATURAL_RESOURCES]: {
    isDefault: true,
    value: 'Natural Resources',
  },
  [IndustrySectorType.INFORMATION_TECHNOLOGY]: {
    isDefault: true,
    value: 'Information Technology',
  },
  [IndustrySectorType.OIL_GAS]: {
    isDefault: true,
    value: 'Oil Gas',
  },
  [IndustrySectorType.STUDENT]: {
    isDefault: true,
    value: 'Student',
  },
  [IndustrySectorType.FOREIGN]: {
    isDefault: true,
    value: 'Foreign',
  },
  [IndustrySectorType.RETIRED]: {
    isDefault: true,
    value: 'Retired',
  },
  [IndustrySectorType.UNEMPLOYED]: {
    isDefault: true,
    value: 'Unemployed',
  },
  [IndustrySectorType.VARIES]: {
    isDefault: true,
    value: 'Varies',
  },
  [IndustrySectorType.OTHER]: {
    isDefault: true,
    value: 'Other',
  },
};

// JobKey.EMPLOYMENT_TYPE
export const EMPLOYMENT_TYPE_OPTIONS: Record<EmploymentType, FieldOptionDetails> = {
  FULL_TIME: {
    isDefault: true,
    value: 'Full Time',
  },
  CONTRACT: {
    isDefault: true,
    value: 'Contract',
  },
  PART_TIME: {
    isDefault: true,
    value: 'Part Time',
  },
  SEASONAL: {
    isDefault: true,
    value: 'Seasonal Full/Part Time',
  },
  SELF_EMPLOYED: {
    isDefault: true,
    value: 'Self Employed',
  },
};

// JobKey.OCCUPATION
export const OCCUPATION_TYPE_OPTIONS: Record<OccupationType, FieldOptionDetails> = {
  ADMINISTRATOR: {
    isDefault: true,
    value: 'Administrator',
  },
  CLERICAL: {
    isDefault: true,
    value: 'Clerical',
  },
  GOVERNMENT: {
    isDefault: true,
    value: 'Government',
  },
  LABOURER: {
    isDefault: true,
    value: 'Labourer',
  },
  MANAGER: {
    isDefault: true,
    value: 'Manager',
  },
  MEDICAL: {
    isDefault: true,
    value: 'Medical',
  },
  PROFESSIONAL: {
    isDefault: true,
    value: 'Professional',
  },
  RETIRED: {
    isDefault: true,
    value: 'Retired',
  },
  SALES: {
    isDefault: true,
    value: 'Sales',
  },
  SELF_EMPLOYED: {
    isDefault: true,
    value: 'Self Employed',
  },
  SERVICE: {
    isDefault: true,
    value: 'Service',
  },
  UNSPECIFIED: {
    isDefault: true,
    value: 'Unspecified',
  },
  OTHER: {
    isDefault: true,
    value: 'Other',
  },
};

// JobKey.PAYMENT_TYPE
// DO NOT ALLOW EDITING
// The options are only here so you can see them, not edit.
// For editing, refactor is needed.
export const PAYMENT_TYPE_OPTIONS: Record<
  EmployedPaymentType | SelfEmployedPaymentType,
  FieldOptionDetails
> = {
  HOURLY_NON_GUARANTEED: {
    isDefault: true,
    value: 'Hourly (non-guaranteed)',
  },
  HOURLY_GUARANTEED: {
    isDefault: true,
    value: 'Hourly (guaranteed)',
  },
  SALARIED: {
    isDefault: true,
    value: 'Salaried',
  },
  COMMISION_ONLY: {
    isDefault: true,
    value: 'Commission Only',
  },
  T4: {
    isDefault: true,
    value: 'T4',
  },
  DIVIDEND: {
    isDefault: true,
    value: 'Dividend',
  },
  BUSINESS_INCOME: {
    isDefault: true,
    value: 'Business Income',
  },
  COMMISSION: {
    isDefault: true,
    value: 'Commission',
  },
};

// JobKey.TYPE
//its a combination of Job - type and OtherIncome - type and its in this way for compatibility
export const JOB_TYPE_OPTIONS: Record<IncomeType, FieldOptionDetails> = {
  EMPLOYED: {
    isDefault: true,
    value: 'Employed',
  },
  SELF_EMPLOYED: {
    isDefault: true,
    value: 'Self Employed',
  },
  OTHER: {
    isDefault: true,
    value: 'Other',
  },
  CANADA_CHILD_BENEFIT: {
    isDefault: true,
    value: 'Canada Child Benefit',
  },
  CANADA_PENSION_PLAN: {
    isDefault: true,
    value: 'Canada Pension Plan',
  },
  CHILD_SUPPORT: {
    isDefault: true,
    value: 'Child Support',
  },
  DISABILITY: {
    isDefault: true,
    value: 'Disability',
  },
  FOREIGN: {
    isDefault: true,
    value: 'Foreign',
  },
  INVESTMENT: {
    isDefault: true,
    value: 'Investment',
  },
  OLD_AGE_SECURITY: {
    isDefault: true,
    value: 'Old Age Security',
  },
  PENSION: {
    isDefault: true,
    value: 'Pension',
  },
  RENTAL: {
    isDefault: true,
    value: 'Rental',
  },
  SPOUSE_SUPPORT: {
    isDefault: true,
    value: 'Spouse Support',
  },
  SURVIVOR_BENEFIT_PENSION: {
    isDefault: true,
    value: 'Survivor Benefit Pension',
  },
  UNEMPLOYED: {
    isDefault: true,
    value: 'Unemployed',
  },
  BONUS: {
    isDefault: true,
    value: 'Bonus',
  },
  OVERTIME: {
    isDefault: true,
    value: 'Overtime',
  },
  COMMISSION: {
    isDefault: true,
    value: 'Commission',
  },
  OTHER_NON_EMPLOYED: {
    isDefault: true,
    value: 'Other (Non-employed)',
  },
  TRUST: {
    isDefault: true,
    value: 'Trust',
  },
};

// MortgageKey.LOAN_TYPE
export const LOAN_TYPE_OPTIONS: Record<LoanType, FieldOptionDetails> = {
  LIEN: {
    isDefault: true,
    value: 'Lien',
  },
  MORTGAGE: {
    isDefault: true,
    value: 'Mortgage',
  },
  SECURE_LINE_OF_CREDIT: {
    isDefault: true,
    value: 'Secure Line of Credit',
  },
  SECURE_LINE_OF_CREDIT_FLEX: {
    isDefault: true,
    value: 'Secure Line of Credit (Flex)',
  },
  BRIDGE: {
    isDefault: true,
    value: 'Bridge',
  },
};

// MortgageKey.INSURER
export const INSURER_OPTIONS: Record<InsurerType, FieldOptionDetails> = {
  CMHC: {
    isDefault: true,
    value: 'CMHC',
  },
  CANADA_GUARANTY: {
    isDefault: true,
    value: 'Canada Guaranty',
  },
  SAGEN: {
    isDefault: true,
    value: 'Sagen',
  },
};

// MortgageKey.COMPOUNDING
export const COMPOUNDING_OPTIONS: Record<CompoundPeriod, FieldOptionDetails> = {
  MONTHLY: {
    isDefault: true,
    value: 'Monthly',
  },
  SEMI_ANNUAL: {
    isDefault: true,
    value: 'Semiannual',
  },
  SEMI_MONTHLY: {
    isDefault: true,
    value: 'Semimonthly',
  },
  QUARTERLY: {
    isDefault: true,
    value: 'Quarterly',
  },
  ANNUALLY: {
    isDefault: true,
    value: 'Annually',
  },
  BI_WEEKLY: {
    isDefault: true,
    value: 'Biweekly',
  },
  DAILY: {
    isDefault: true,
    value: 'Daily',
  },
  WEEKLY: {
    isDefault: true,
    value: 'Weekly',
  },
  SIMPLE_INTEREST: {
    isDefault: true,
    value: 'Simple Interest',
  },
};

// MortgageKey.CONFORMING
export const CONFORMING_OPTIONS: Record<ConformingType, FieldOptionDetails> = {
  CONFORMING: {
    isDefault: true,
    value: 'Conforming',
  },
  NONCONFORMING: {
    isDefault: true,
    value: 'Non-Conforming',
  },
};

// MortgageKey.PROGRAM_CODE
export const PROGRAM_CODE_OPTIONS: Record<ProgramCodeType, FieldOptionDetails> = {
  [ProgramCodeType.NEW_TO_CANADA]: {
    isDefault: true,
    value: 'New To Canada',
  },
  [ProgramCodeType.CASH_BACK]: {
    isDefault: true,
    value: 'Cash Back',
  },
  [ProgramCodeType.FRIENDS_AND_FAMILY]: {
    isDefault: true,
    value: 'Friends and Family',
  },
  [ProgramCodeType.RESTORATION_CREDIT]: {
    isDefault: true,
    value: 'Restoration Credit',
  },
  [ProgramCodeType.HORIZON]: {
    isDefault: true,
    value: 'Horizon',
  },
  [ProgramCodeType.HYBRID]: {
    isDefault: true,
    value: 'Hybrid',
  },
};

// MortgageKey.MORTGAGE_TYPE
export const MORTGAGE_TYPE_OPTIONS: Record<MortgagePosition, FieldOptionDetails> = {
  FIRST: {
    isDefault: true,
    value: 'First',
  },
  SECOND: {
    isDefault: true,
    value: 'Second',
  },
  THIRD: {
    isDefault: true,
    value: 'Third',
  },
  UNKNOWN: {
    isDefault: true,
    value: 'Unknown',
  },
};

// MortgageKey.RATE_TYPE
export const RATE_TYPE_OPTIONS: Record<RateType, FieldOptionDetails> = {
  FIXED: {
    isDefault: true,
    value: 'Fixed',
  },
  ADJUSTABLE: {
    isDefault: true,
    value: 'Adjustable',
  },
  CAPPED_VARIABLE: {
    isDefault: true,
    value: 'Capped Variable',
  },
  VARIABLE: {
    isDefault: true,
    value: 'Variable',
  },
  BUYDOWN: {
    isDefault: true,
    value: 'Buydown',
  },
};

// MortgageKey.PAYMENT_FREQUENCY
export const PAYMENT_FREQUENCY_OPTIONS: Record<PaymentFrequency, FieldOptionDetails> = {
  ACCELERATED_BI_WEEKLY: {
    isDefault: true,
    value: 'Accelerated Biweekly',
  },
  ACCELERATED_WEEKLY: {
    isDefault: true,
    value: 'Accelerated Weekly',
  },
  BI_WEEKLY: {
    isDefault: true,
    value: 'Biweekly',
  },
  MONTHLY: {
    isDefault: true,
    value: 'Monthly',
  },
  SEMI_MONTHLY: {
    isDefault: true,
    value: 'Semimonthly',
  },
  SEMI_ANNUALLY: {
    isDefault: true,
    value: 'Semiannually',
  },
  QUARTERLY: {
    isDefault: true,
    value: 'Quarterly',
  },
  WEEKLY: {
    isDefault: true,
    value: 'Weekly',
  },
  ANNUALLY: {
    isDefault: true,
    value: 'Annually',
  },
  DAILY: {
    isDefault: true,
    value: 'Daily',
  },
};

// MortgageKey.TERM
export const TERM_OPTIONS: Record<TermMonths, FieldOptionDetails> = {
  ONE_YEAR: {
    isDefault: true,
    value: '12',
  },
  TWO_YEARS: {
    isDefault: true,
    value: '24',
  },
  THREE_YEARS: {
    isDefault: true,
    value: '36',
  },
  FOUR_YEARS: {
    isDefault: true,
    value: '48',
  },
  FIVE_YEARS: {
    isDefault: true,
    value: '60',
  },
};

// MortgageKey.REPAYMENT_TYPE
export const REPAYMENT_TYPE_OPTIONS: Record<RepaymentType, FieldOptionDetails> = {
  BALLOON_PAYMENT: {
    isDefault: true,
    value: 'Balloon Payment',
  },
  INTEREST_ONLY: {
    isDefault: true,
    value: 'Interest Only',
  },
  PRINCIPAL_AND_INTEREST: {
    isDefault: true,
    value: 'Principal And Interest',
  },
  UNKNOWN: {
    isDefault: true,
    value: 'Unknown',
  },
};

// MortgageKey.TERM_TYPE
export const TERM_TYPE_OPTIONS: Record<TermType, FieldOptionDetails> = {
  OPEN: {
    isDefault: true,
    value: 'Open',
  },
  CLOSED: {
    isDefault: true,
    value: 'Closed',
  },
  CONVERTIBLE: {
    isDefault: true,
    value: 'Convertible',
  },
};

// MortgageKey.PREPAYMENT_TYPE
export const PREPAYMENT_TYPE_OPTIONS: Record<PrepaymentType, FieldOptionDetails> = {
  APPLY_UPFRONT: {
    isDefault: true,
    value: 'Apply Upfront',
  },
  SPREAD_EVENLY: {
    isDefault: true,
    value: 'Spread Evenly',
  },
};

// FeeKey.TYPE
export const FEE_TYPE_OPTIONS: Record<FeeType, FieldOptionDetails> = {
  COMMITMENT: {
    isDefault: true,
    value: 'Commitment',
  },
  BROKER: {
    isDefault: true,
    value: 'Broker',
  },
  LENDER: {
    isDefault: true,
    value: 'Lender',
  },
  ADMINISTRATOR: {
    isDefault: true,
    value: 'Administrator',
  },
  TITLE_INSURANCE: {
    isDefault: true,
    value: 'Title Insurance',
  },
  APPRAISAL: {
    isDefault: true,
    value: 'Appraisal',
  },
  LAND_TITLES: {
    isDefault: true,
    value: 'Land Titles',
  },
  LEGAL: {
    isDefault: true,
    value: 'Legal',
  },
  OTHER: {
    isDefault: true,
    value: 'Other',
  },
};

// FeeKey.PAID_BY
export const PAID_BY_OPTIONS: Record<FeePayer, FieldOptionDetails> = {
  BORROWER: {
    isDefault: true,
    value: 'Borrower',
  },
  LENDER: {
    isDefault: true,
    value: 'Lender',
  },
};

// PropertyInsuranceKey.TYPE
export const PROPERTY_INSURANCE_TYPE_OPTIONS: Record<PropertyInsuranceType, FieldOptionDetails> = {
  BARELAND_LIABILITY: {
    isDefault: true,
    value: 'Bareland Liability',
  },
  CONDO_CORPORATION_INSURANCE_BINDER: {
    isDefault: true,
    value: 'Condo Corporation Insurance Binder',
  },
  COURSE_OF_CONSTRUCTION_AND_LIABILITY: {
    isDefault: true,
    value: 'Course of Construction and Liability',
  },
  EARTHQUAKE: {
    isDefault: true,
    value: 'Earthquake',
  },
  FIRE: {
    isDefault: true,
    value: 'Fire',
  },
  FLOOD: {
    isDefault: true,
    value: 'Flood',
  },
  HOMEOWNER: {
    isDefault: true,
    value: 'Homeowners',
  },
  RENTERS: {
    isDefault: true,
    value: 'Renters',
  },
  PROPERTY: {
    isDefault: true,
    value: 'Property',
  },
  COMMERCIAL: {
    isDefault: true,
    value: 'Commercial',
  },
  CONTENT: {
    isDefault: true,
    value: 'Content',
  },
  COMPREHENSIVE_GENERAL_LIABILITY: {
    isDefault: true,
    value: 'Comprehensive General Liability',
  },
  BOILER_EQUIPMENT: {
    isDefault: true,
    value: 'Boiler & Equipment',
  },
  CONDO: {
    isDefault: true,
    value: 'Condo',
  },
};

// PropertyAppraisalKey.APPRAISAL_TYPE
export const APPRAISAL_TYPE_OPTIONS: Record<AppraisalType, FieldOptionDetails> = {
  AS_IS: {
    isDefault: true,
    value: 'As-Is',
  },
  AS_RENOVATED: {
    isDefault: true,
    value: 'As-Renovated',
  },
  AS_COMPLETE: {
    isDefault: true,
    value: 'As-Complete',
  },
  LAND_VALUE: {
    isDefault: true,
    value: 'Land Value',
  },
  THIRD_PARTY: {
    isDefault: true,
    value: '3rd Party Appraisal',
  },
};

// PropertyAppraisalKey.SOURCE
export const APPRAISAL_SOURCE_OPTIONS: Record<AppraisalSource, FieldOptionDetails> = {
  MANUAL: {
    isDefault: true,
    value: 'Manual',
  },
  OPTA: {
    isDefault: true,
    value: 'Opta',
  },
  FCT: {
    isDefault: true,
    value: 'FCT',
  },
};

// PropertyAppraisalKey.STATUS
export const APPRAISAL_STATUS_OPTIONS: Record<AppraisalStatus, FieldOptionDetails> = {
  IN_PROCESS: {
    isDefault: true,
    value: 'In Process',
  },
  READY: {
    isDefault: true,
    value: 'Ready',
  },
  DELIVERED: {
    isDefault: true,
    value: 'Delivered',
  },
  CANCELLED: {
    isDefault: true,
    value: 'Cancelled',
  },
};

// PropertyAppraisalKey.CONFIDENCE_SCORE
export const CONFIDENCE_SCORE_OPTIONS: Record<AppraisalConfidenceScore, FieldOptionDetails> = {
  HIGH: {
    isDefault: true,
    value: 'High',
  },
  MEDIUM: {
    isDefault: true,
    value: 'Medium',
  },
  LOW: {
    isDefault: true,
    value: 'Low',
  },
};

// PropertyAppraisalKey.FCT_INSURANCE_STATUS
export const FCT_INSURANCE_STATUS_OPTIONS: Record<FCTInsuranceStatus, FieldOptionDetails> = {
  [FCTInsuranceStatus.ELIGIBLE_FOR_INSURANCE]: {
    isDefault: true,
    value: 'Eligible for Insurance',
  },
  [FCTInsuranceStatus.INELIGIBLE_FOR_INSURANCE]: {
    isDefault: true,
    value: 'Ineligible for Insurance',
  },
  [FCTInsuranceStatus.NO_REPORT_AVAILABLE]: {
    isDefault: true,
    value: 'No Report Available',
  },
  [FCTInsuranceStatus.ORDER_UPDATE_REQUIRED]: {
    isDefault: true,
    value: 'Order Update Required',
  },
  [FCTInsuranceStatus.PENDING]: {
    isDefault: true,
    value: 'Pending',
  },
};

// PropertyOwnerKey.MARTIAL_STATUS
export const MARTIAL_STATUS_OPTIONS: Record<MaritalType, FieldOptionDetails> = {
  MARRIED: {
    isDefault: true,
    value: 'Married',
  },
  WIDOWED: {
    isDefault: true,
    value: 'Widowed',
  },
  SEPARATED: {
    isDefault: true,
    value: 'Separated',
  },
  DIVORCED: {
    isDefault: true,
    value: 'Divorced',
  },
  SINGLE: {
    isDefault: true,
    value: 'Single',
  },
  COMMON_LAW: {
    isDefault: true,
    value: 'Common Law',
  },
  ENGAGED: {
    isDefault: true,
    value: 'Engaged',
  },
  OTHER: {
    isDefault: true,
    value: 'Other',
  },
};

// JobKey.INCOME_PAYMENT_FREQUENCY
export const INCOME_PAYMENT_FREQUENCY_OPTIONS: Record<IncomePeriod, FieldOptionDetails> = {
  BI_WEEKLY: {
    isDefault: true,
    value: 'Bi-weekly',
  },
  WEEKLY: {
    isDefault: true,
    value: 'Weekly',
  },
  SEMI_MONTHLY: {
    isDefault: true,
    value: 'Semi-monthly',
  },
  MONTHLY: {
    isDefault: true,
    value: 'Monthly',
  },
  YEARLY: {
    isDefault: true,
    value: 'Annually',
  },
};

// AddressExpandedKey.COUNTRY
export const COUNTRY_OPTIONS: Record<Country, FieldOptionDetails> = {
  [Country.CANADA]: {
    isDefault: true,
    value: 'Canada',
  },
  [Country.UNITED_STATES]: {
    isDefault: true,
    value: 'United States',
  },
};

// AddressExpandedKey.STREET_TYPE
export const STREET_TYPE_OPTIONS: Record<StreetType, FieldOptionDetails> = {
  [StreetType.ABBEY]: {
    isDefault: true,
    value: 'Abbey',
  },
  [StreetType.ACRES]: {
    isDefault: true,
    value: 'Acres',
  },
  [StreetType.ALLEE]: {
    isDefault: true,
    value: 'Allée',
  },
  [StreetType.ALLEY]: {
    isDefault: true,
    value: 'Alley',
  },
  [StreetType.ANNEX]: {
    isDefault: true,
    value: 'Annex',
  },
  [StreetType.ARCADE]: {
    isDefault: true,
    value: 'Arcade',
  },
  [StreetType.AUTOROUTE]: {
    isDefault: true,
    value: 'Autoroute',
  },
  [StreetType.AVENUE]: {
    isDefault: true,
    value: 'Avenue',
  },
  [StreetType.BAY]: {
    isDefault: true,
    value: 'Bay',
  },
  [StreetType.BAYOU]: {
    isDefault: true,
    value: 'Bayou',
  },
  [StreetType.BEACH]: {
    isDefault: true,
    value: 'Beach',
  },
  [StreetType.BEND]: {
    isDefault: true,
    value: 'Bend',
  },
  [StreetType.BLUFF]: {
    isDefault: true,
    value: 'Bluff',
  },
  [StreetType.BLUFFS]: {
    isDefault: true,
    value: 'Bluffs',
  },
  [StreetType.BOTTOM]: {
    isDefault: true,
    value: 'Bottom',
  },
  [StreetType.BOULEVARD]: {
    isDefault: true,
    value: 'Boulevard',
  },
  [StreetType.BRANCH]: {
    isDefault: true,
    value: 'Branch',
  },
  [StreetType.BRIDGE]: {
    isDefault: true,
    value: 'Bridge',
  },
  [StreetType.BROOK]: {
    isDefault: true,
    value: 'Brook',
  },
  [StreetType.BROOKS]: {
    isDefault: true,
    value: 'Brooks',
  },
  [StreetType.BURG]: {
    isDefault: true,
    value: 'Burg',
  },
  [StreetType.BURGS]: {
    isDefault: true,
    value: 'Burgs',
  },
  [StreetType.BY_PASS]: {
    isDefault: true,
    value: 'By-Pass',
  },
  [StreetType.BYPASS]: {
    isDefault: true,
    value: 'Bypass',
  },
  [StreetType.BYWAY]: {
    isDefault: true,
    value: 'Byway',
  },
  [StreetType.CAMP]: {
    isDefault: true,
    value: 'Camp',
  },
  [StreetType.CAMPUS]: {
    isDefault: true,
    value: 'Campus',
  },
  [StreetType.CANYON]: {
    isDefault: true,
    value: 'Canyon',
  },
  [StreetType.CAPE]: {
    isDefault: true,
    value: 'Cape',
  },
  [StreetType.CARRE]: {
    isDefault: true,
    value: 'Carré',
  },
  [StreetType.CARREFOUR]: {
    isDefault: true,
    value: 'Carrefour',
  },
  [StreetType.CAUSEWAY]: {
    isDefault: true,
    value: 'Causeway',
  },
  [StreetType.CENTER]: {
    isDefault: true,
    value: 'Center',
  },
  [StreetType.CENTERS]: {
    isDefault: true,
    value: 'Centers',
  },
  [StreetType.CENTRE]: {
    isDefault: true,
    value: 'Centre',
  },
  [StreetType.CERCLE]: {
    isDefault: true,
    value: 'Cercle',
  },
  [StreetType.CHASE]: {
    isDefault: true,
    value: 'Chase',
  },
  [StreetType.CHEMIN]: {
    isDefault: true,
    value: 'Chemin',
  },
  [StreetType.CIRCLE]: {
    isDefault: true,
    value: 'Circle',
  },
  [StreetType.CIRCLES]: {
    isDefault: true,
    value: 'Circles',
  },
  [StreetType.CIRCUIT]: {
    isDefault: true,
    value: 'Circuit',
  },
  [StreetType.CLIFF]: {
    isDefault: true,
    value: 'Cliff',
  },
  [StreetType.CLIFFS]: {
    isDefault: true,
    value: 'Cliffs',
  },
  [StreetType.CLOSE]: {
    isDefault: true,
    value: 'Close',
  },
  [StreetType.CLUB]: {
    isDefault: true,
    value: 'Club',
  },
  [StreetType.COMMON]: {
    isDefault: true,
    value: 'Common',
  },
  [StreetType.COMMONS]: {
    isDefault: true,
    value: 'Commons',
  },
  [StreetType.CONCESSION]: {
    isDefault: true,
    value: 'Concession',
  },
  [StreetType.CORNER]: {
    isDefault: true,
    value: 'Corner',
  },
  [StreetType.CORNERS]: {
    isDefault: true,
    value: 'Corners',
  },
  [StreetType.COTE]: {
    isDefault: true,
    value: 'Côte',
  },
  [StreetType.COUR]: {
    isDefault: true,
    value: 'Cour',
  },
  [StreetType.COURS]: {
    isDefault: true,
    value: 'Cours',
  },
  [StreetType.COURSE]: {
    isDefault: true,
    value: 'Course',
  },
  [StreetType.COURT]: {
    isDefault: true,
    value: 'Court',
  },
  [StreetType.COURTS]: {
    isDefault: true,
    value: 'Courts',
  },
  [StreetType.COVE]: {
    isDefault: true,
    value: 'Cove',
  },
  [StreetType.COVES]: {
    isDefault: true,
    value: 'Coves',
  },
  [StreetType.CREEK]: {
    isDefault: true,
    value: 'Creek',
  },
  [StreetType.CRESCENT]: {
    isDefault: true,
    value: 'Crescent',
  },
  [StreetType.CREST]: {
    isDefault: true,
    value: 'Crest',
  },
  [StreetType.CROISSANT]: {
    isDefault: true,
    value: 'Croissant',
  },
  [StreetType.CROSSING]: {
    isDefault: true,
    value: 'Crossing',
  },
  [StreetType.CROSSROAD]: {
    isDefault: true,
    value: 'Crossroad',
  },
  [StreetType.CROSSROADS]: {
    isDefault: true,
    value: 'Crossroads',
  },
  [StreetType.CUL_DE_SAC]: {
    isDefault: true,
    value: 'Cul-de-sac',
  },
  [StreetType.CURVE]: {
    isDefault: true,
    value: 'Curve',
  },
  [StreetType.DALE]: {
    isDefault: true,
    value: 'Dale',
  },
  [StreetType.DAM]: {
    isDefault: true,
    value: 'Dam',
  },
  [StreetType.DELL]: {
    isDefault: true,
    value: 'Dell',
  },
  [StreetType.DIVERSION]: {
    isDefault: true,
    value: 'Diversion',
  },
  [StreetType.DIVIDE]: {
    isDefault: true,
    value: 'Divide',
  },
  [StreetType.DOWNS]: {
    isDefault: true,
    value: 'Downs',
  },
  [StreetType.DRIVE]: {
    isDefault: true,
    value: 'Drive',
  },
  [StreetType.DRIVES]: {
    isDefault: true,
    value: 'Drives',
  },
  [StreetType.ECHANGEUR]: {
    isDefault: true,
    value: 'Échangeur',
  },
  [StreetType.END]: {
    isDefault: true,
    value: 'End',
  },
  [StreetType.ESPLANADE]: {
    isDefault: true,
    value: 'Esplanade',
  },
  [StreetType.ESTATE]: {
    isDefault: true,
    value: 'Estate',
  },
  [StreetType.ESTATES]: {
    isDefault: true,
    value: 'Estates',
  },
  [StreetType.EXPRESSWAY]: {
    isDefault: true,
    value: 'Expressway',
  },
  [StreetType.EXTENSION]: {
    isDefault: true,
    value: 'Extension',
  },
  [StreetType.EXTENSIONS]: {
    isDefault: true,
    value: 'Extensions',
  },
  [StreetType.FALL]: {
    isDefault: true,
    value: 'Fall',
  },
  [StreetType.FALLS]: {
    isDefault: true,
    value: 'Falls',
  },
  [StreetType.FARM]: {
    isDefault: true,
    value: 'Farm',
  },
  [StreetType.FERRY]: {
    isDefault: true,
    value: 'Ferry',
  },
  [StreetType.FIELD]: {
    isDefault: true,
    value: 'Field',
  },
  [StreetType.FIELDS]: {
    isDefault: true,
    value: 'Fields',
  },
  [StreetType.FLAT]: {
    isDefault: true,
    value: 'Flat',
  },
  [StreetType.FLATS]: {
    isDefault: true,
    value: 'Flats',
  },
  [StreetType.FORD]: {
    isDefault: true,
    value: 'Ford',
  },
  [StreetType.FORDS]: {
    isDefault: true,
    value: 'Fords',
  },
  [StreetType.FOREST]: {
    isDefault: true,
    value: 'Forest',
  },
  [StreetType.FORGE]: {
    isDefault: true,
    value: 'Forge',
  },
  [StreetType.FORGES]: {
    isDefault: true,
    value: 'Forges',
  },
  [StreetType.FORK]: {
    isDefault: true,
    value: 'Fork',
  },
  [StreetType.FORKS]: {
    isDefault: true,
    value: 'Forks',
  },
  [StreetType.FORT]: {
    isDefault: true,
    value: 'Fort',
  },
  [StreetType.FREEWAY]: {
    isDefault: true,
    value: 'Freeway',
  },
  [StreetType.FRONT]: {
    isDefault: true,
    value: 'Front',
  },
  [StreetType.GARDEN]: {
    isDefault: true,
    value: 'Garden',
  },
  [StreetType.GARDENS]: {
    isDefault: true,
    value: 'Gardens',
  },
  [StreetType.GATE]: {
    isDefault: true,
    value: 'Gate',
  },
  [StreetType.GATEWAY]: {
    isDefault: true,
    value: 'Gateway',
  },
  [StreetType.GLADE]: {
    isDefault: true,
    value: 'Glade',
  },
  [StreetType.GLEN]: {
    isDefault: true,
    value: 'Glen',
  },
  [StreetType.GLENS]: {
    isDefault: true,
    value: 'Glens',
  },
  [StreetType.GREEN]: {
    isDefault: true,
    value: 'Green',
  },
  [StreetType.GREENS]: {
    isDefault: true,
    value: 'Greens',
  },
  [StreetType.GROUNDS]: {
    isDefault: true,
    value: 'Grounds',
  },
  [StreetType.GROVE]: {
    isDefault: true,
    value: 'Grove',
  },
  [StreetType.GROVES]: {
    isDefault: true,
    value: 'Groves',
  },
  [StreetType.HARBOR]: {
    isDefault: true,
    value: 'Harbor',
  },
  [StreetType.HARBORS]: {
    isDefault: true,
    value: 'Harbors',
  },
  [StreetType.HARBOUR]: {
    isDefault: true,
    value: 'Harbour',
  },
  [StreetType.HAVEN]: {
    isDefault: true,
    value: 'Haven',
  },
  [StreetType.HEATH]: {
    isDefault: true,
    value: 'Heath',
  },
  [StreetType.HEIGHTS]: {
    isDefault: true,
    value: 'Heights',
  },
  [StreetType.HIGHLANDS]: {
    isDefault: true,
    value: 'Highlands',
  },
  [StreetType.HIGHWAY]: {
    isDefault: true,
    value: 'Highway',
  },
  [StreetType.HILL]: {
    isDefault: true,
    value: 'Hill',
  },
  [StreetType.HILLS]: {
    isDefault: true,
    value: 'Hills',
  },
  [StreetType.HOLLOW]: {
    isDefault: true,
    value: 'Hollow',
  },
  [StreetType.ILE]: {
    isDefault: true,
    value: 'Île',
  },
  [StreetType.IMPASSE]: {
    isDefault: true,
    value: 'Impasse',
  },
  [StreetType.INLET]: {
    isDefault: true,
    value: 'Inlet',
  },
  [StreetType.ISLAND]: {
    isDefault: true,
    value: 'Island',
  },
  [StreetType.ISLANDS]: {
    isDefault: true,
    value: 'Islands',
  },
  [StreetType.ISLE]: {
    isDefault: true,
    value: 'Isle',
  },
  [StreetType.JUNCTION]: {
    isDefault: true,
    value: 'Junction',
  },
  [StreetType.JUNCTIONS]: {
    isDefault: true,
    value: 'Junctions',
  },
  [StreetType.KEY]: {
    isDefault: true,
    value: 'Key',
  },
  [StreetType.KEYS]: {
    isDefault: true,
    value: 'Keys',
  },
  [StreetType.KNOLL]: {
    isDefault: true,
    value: 'Knoll',
  },
  [StreetType.KNOLLS]: {
    isDefault: true,
    value: 'Knolls',
  },
  [StreetType.LAKE]: {
    isDefault: true,
    value: 'Lake',
  },
  [StreetType.LAKES]: {
    isDefault: true,
    value: 'Lakes',
  },
  [StreetType.LAND]: {
    isDefault: true,
    value: 'Land',
  },
  [StreetType.LANDING]: {
    isDefault: true,
    value: 'Landing',
  },
  [StreetType.LANE]: {
    isDefault: true,
    value: 'Lane',
  },
  [StreetType.LIGHT]: {
    isDefault: true,
    value: 'Light',
  },
  [StreetType.LIGHTS]: {
    isDefault: true,
    value: 'Lights',
  },
  [StreetType.LIMITS]: {
    isDefault: true,
    value: 'Limits',
  },
  [StreetType.LINE]: {
    isDefault: true,
    value: 'Line',
  },
  [StreetType.LINK]: {
    isDefault: true,
    value: 'Link',
  },
  [StreetType.LOAF]: {
    isDefault: true,
    value: 'Loaf',
  },
  [StreetType.LOCK]: {
    isDefault: true,
    value: 'Lock',
  },
  [StreetType.LOCKS]: {
    isDefault: true,
    value: 'Locks',
  },
  [StreetType.LODGE]: {
    isDefault: true,
    value: 'Lodge',
  },
  [StreetType.LOOKOUT]: {
    isDefault: true,
    value: 'Lookout',
  },
  [StreetType.LOOP]: {
    isDefault: true,
    value: 'Loop',
  },
  [StreetType.MALL]: {
    isDefault: true,
    value: 'Mall',
  },
  [StreetType.MANOR]: {
    isDefault: true,
    value: 'Manor',
  },
  [StreetType.MANORS]: {
    isDefault: true,
    value: 'Manors',
  },
  [StreetType.MAZE]: {
    isDefault: true,
    value: 'Maze',
  },
  [StreetType.MEADOW]: {
    isDefault: true,
    value: 'Meadow',
  },
  [StreetType.MEADOWS]: {
    isDefault: true,
    value: 'Meadows',
  },
  [StreetType.MEWS]: {
    isDefault: true,
    value: 'Mews',
  },
  [StreetType.MILL]: {
    isDefault: true,
    value: 'Mill',
  },
  [StreetType.MILLS]: {
    isDefault: true,
    value: 'Mills',
  },
  [StreetType.MISSION]: {
    isDefault: true,
    value: 'Mission',
  },
  [StreetType.MONTEE]: {
    isDefault: true,
    value: 'Montée',
  },
  [StreetType.MOOR]: {
    isDefault: true,
    value: 'Moor',
  },
  [StreetType.MOTORWAY]: {
    isDefault: true,
    value: 'Motorway',
  },
  [StreetType.MOUNT]: {
    isDefault: true,
    value: 'Mount',
  },
  [StreetType.MOUNTAIN]: {
    isDefault: true,
    value: 'Mountain',
  },
  [StreetType.MOUNTAINS]: {
    isDefault: true,
    value: 'Mountains',
  },
  [StreetType.NECK]: {
    isDefault: true,
    value: 'Neck',
  },
  [StreetType.ORCHARD]: {
    isDefault: true,
    value: 'Orchard',
  },
  [StreetType.PARADE]: {
    isDefault: true,
    value: 'Parade',
  },
  [StreetType.PARC]: {
    isDefault: true,
    value: 'Parc',
  },
  [StreetType.PARK]: {
    isDefault: true,
    value: 'Park',
  },
  [StreetType.PARKS]: {
    isDefault: true,
    value: 'Parks',
  },
  [StreetType.PARKWAY]: {
    isDefault: true,
    value: 'Parkway',
  },
  [StreetType.PARKWAYS]: {
    isDefault: true,
    value: 'Parkways',
  },
  [StreetType.PASS]: {
    isDefault: true,
    value: 'Pass',
  },
  [StreetType.PASSAGE]: {
    isDefault: true,
    value: 'Passage',
  },
  [StreetType.PATH]: {
    isDefault: true,
    value: 'Path',
  },
  [StreetType.PATHWAY]: {
    isDefault: true,
    value: 'Pathway',
  },
  [StreetType.PIKE]: {
    isDefault: true,
    value: 'Pike',
  },
  [StreetType.PINE]: {
    isDefault: true,
    value: 'Pine',
  },
  [StreetType.PINES]: {
    isDefault: true,
    value: 'Pines',
  },
  [StreetType.PLACE]: {
    isDefault: true,
    value: 'Place',
  },
  [StreetType.PLAIN]: {
    isDefault: true,
    value: 'Plain',
  },
  [StreetType.PLAINS]: {
    isDefault: true,
    value: 'Plains',
  },
  [StreetType.PLATEAU]: {
    isDefault: true,
    value: 'Plateau',
  },
  [StreetType.PLAZA]: {
    isDefault: true,
    value: 'Plaza',
  },
  [StreetType.POINT]: {
    isDefault: true,
    value: 'Point',
  },
  [StreetType.POINTE]: {
    isDefault: true,
    value: 'Pointe',
  },
  [StreetType.POINTS]: {
    isDefault: true,
    value: 'Points',
  },
  [StreetType.PORT]: {
    isDefault: true,
    value: 'Port',
  },
  [StreetType.PORTS]: {
    isDefault: true,
    value: 'Ports',
  },
  [StreetType.PRAIRIE]: {
    isDefault: true,
    value: 'Prairie',
  },
  [StreetType.PRIVATE]: {
    isDefault: true,
    value: 'Private',
  },
  [StreetType.PROMENADE]: {
    isDefault: true,
    value: 'Promenade',
  },
  [StreetType.QUAI]: {
    isDefault: true,
    value: 'Quai',
  },
  [StreetType.QUAY]: {
    isDefault: true,
    value: 'Quay',
  },
  [StreetType.RADIAL]: {
    isDefault: true,
    value: 'Radial',
  },
  [StreetType.RAMP]: {
    isDefault: true,
    value: 'Ramp',
  },
  [StreetType.RANCH]: {
    isDefault: true,
    value: 'Ranch',
  },
  [StreetType.RANG]: {
    isDefault: true,
    value: 'Rang',
  },
  [StreetType.RANGE]: {
    isDefault: true,
    value: 'Range',
  },
  [StreetType.RAPID]: {
    isDefault: true,
    value: 'Rapid',
  },
  [StreetType.RAPIDS]: {
    isDefault: true,
    value: 'Rapids',
  },
  [StreetType.REST]: {
    isDefault: true,
    value: 'Rest',
  },
  [StreetType.RIDGE]: {
    isDefault: true,
    value: 'Ridge',
  },
  [StreetType.RIDGES]: {
    isDefault: true,
    value: 'Ridges',
  },
  [StreetType.RISE]: {
    isDefault: true,
    value: 'Rise',
  },
  [StreetType.RIVER]: {
    isDefault: true,
    value: 'River',
  },
  [StreetType.ROAD]: {
    isDefault: true,
    value: 'Road',
  },
  [StreetType.ROADS]: {
    isDefault: true,
    value: 'Roads',
  },
  [StreetType.ROND_POINT]: {
    isDefault: true,
    value: 'Rond-point',
  },
  [StreetType.ROUTE]: {
    isDefault: true,
    value: 'Route',
  },
  [StreetType.ROW]: {
    isDefault: true,
    value: 'Row',
  },
  [StreetType.RUE]: {
    isDefault: true,
    value: 'Rue',
  },
  [StreetType.RUELLE]: {
    isDefault: true,
    value: 'Ruelle',
  },
  [StreetType.RUN]: {
    isDefault: true,
    value: 'Run',
  },
  [StreetType.SENTIER]: {
    isDefault: true,
    value: 'Sentier',
  },
  [StreetType.SHOAL]: {
    isDefault: true,
    value: 'Shoal',
  },
  [StreetType.SHOALS]: {
    isDefault: true,
    value: 'Shoals',
  },
  [StreetType.SHORE]: {
    isDefault: true,
    value: 'Shore',
  },
  [StreetType.SHORES]: {
    isDefault: true,
    value: 'Shores',
  },
  [StreetType.SKYWAY]: {
    isDefault: true,
    value: 'Skyway',
  },
  [StreetType.SPRING]: {
    isDefault: true,
    value: 'Spring',
  },
  [StreetType.SPRINGS]: {
    isDefault: true,
    value: 'Springs',
  },
  [StreetType.SPUR]: {
    isDefault: true,
    value: 'Spur',
  },
  [StreetType.SPURS]: {
    isDefault: true,
    value: 'Spurs',
  },
  [StreetType.SQUARE]: {
    isDefault: true,
    value: 'Square',
  },
  [StreetType.SQUARES]: {
    isDefault: true,
    value: 'Squares',
  },
  [StreetType.STATION]: {
    isDefault: true,
    value: 'Station',
  },
  [StreetType.STRAVENUE]: {
    isDefault: true,
    value: 'Stravenue',
  },
  [StreetType.STREAM]: {
    isDefault: true,
    value: 'Stream',
  },
  [StreetType.STREET]: {
    isDefault: true,
    value: 'Street',
  },
  [StreetType.STREETS]: {
    isDefault: true,
    value: 'Streets',
  },
  [StreetType.SUBDIVISION]: {
    isDefault: true,
    value: 'Subdivision',
  },
  [StreetType.SUMMIT]: {
    isDefault: true,
    value: 'Summit',
  },
  [StreetType.TERRACE]: {
    isDefault: true,
    value: 'Terrace',
  },
  [StreetType.TERRASSE]: {
    isDefault: true,
    value: 'Terrasse',
  },
  [StreetType.THICKET]: {
    isDefault: true,
    value: 'Thicket',
  },
  [StreetType.THROUGHWAY]: {
    isDefault: true,
    value: 'Throughway',
  },
  [StreetType.TOWERS]: {
    isDefault: true,
    value: 'Towers',
  },
  [StreetType.TOWNLINE]: {
    isDefault: true,
    value: 'Townline',
  },
  [StreetType.TRACE]: {
    isDefault: true,
    value: 'Trace',
  },
  [StreetType.TRACK]: {
    isDefault: true,
    value: 'Track',
  },
  [StreetType.TRAFFICWAY]: {
    isDefault: true,
    value: 'Trafficway',
  },
  [StreetType.TRAIL]: {
    isDefault: true,
    value: 'Trail',
  },
  [StreetType.TRAILER]: {
    isDefault: true,
    value: 'Trailer',
  },
  [StreetType.TUNNEL]: {
    isDefault: true,
    value: 'Tunnel',
  },
  [StreetType.TURNABOUT]: {
    isDefault: true,
    value: 'Turnabout',
  },
  [StreetType.TURNPIKE]: {
    isDefault: true,
    value: 'Turnpike',
  },
  [StreetType.UNDERPASS]: {
    isDefault: true,
    value: 'Underpass',
  },
  [StreetType.UNION]: {
    isDefault: true,
    value: 'Union',
  },
  [StreetType.UNIONS]: {
    isDefault: true,
    value: 'Unions',
  },
  [StreetType.VALE]: {
    isDefault: true,
    value: 'Vale',
  },
  [StreetType.VALLEY]: {
    isDefault: true,
    value: 'Valley',
  },
  [StreetType.VALLEYS]: {
    isDefault: true,
    value: 'Valleys',
  },
  [StreetType.VIA]: {
    isDefault: true,
    value: 'Via',
  },
  [StreetType.VIADUCT]: {
    isDefault: true,
    value: 'Viaduct',
  },
  [StreetType.VIEW]: {
    isDefault: true,
    value: 'View',
  },
  [StreetType.VIEWS]: {
    isDefault: true,
    value: 'Views',
  },
  [StreetType.VILLAGE]: {
    isDefault: true,
    value: 'Village',
  },
  [StreetType.VILLAGES]: {
    isDefault: true,
    value: 'Villages',
  },
  [StreetType.VILLAS]: {
    isDefault: true,
    value: 'Villas',
  },
  [StreetType.VILLE]: {
    isDefault: true,
    value: 'Ville',
  },
  [StreetType.VISTA]: {
    isDefault: true,
    value: 'Vista',
  },
  [StreetType.VOIE]: {
    isDefault: true,
    value: 'Voie',
  },
  [StreetType.WALK]: {
    isDefault: true,
    value: 'Walk',
  },
  [StreetType.WALKS]: {
    isDefault: true,
    value: 'Walks',
  },
  [StreetType.WALL]: {
    isDefault: true,
    value: 'Wall',
  },
  [StreetType.WAY]: {
    isDefault: true,
    value: 'Way',
  },
  [StreetType.WAYS]: {
    isDefault: true,
    value: 'Ways',
  },
  [StreetType.WELL]: {
    isDefault: true,
    value: 'Well',
  },
  [StreetType.WELLS]: {
    isDefault: true,
    value: 'Wells',
  },
  [StreetType.WHARF]: {
    isDefault: true,
    value: 'Wharf',
  },
  [StreetType.WOOD]: {
    isDefault: true,
    value: 'Wood',
  },
  [StreetType.WYND]: {
    isDefault: true,
    value: 'Wynd',
  },
};

// AddressExpandedKey.STREET_DIRECTION
export const STREET_DIRECTION_OPTIONS: Record<StreetDirection, FieldOptionDetails> = {
  [StreetDirection.N]: {
    isDefault: true,
    value: 'N',
  },
  [StreetDirection.S]: {
    isDefault: true,
    value: 'S',
  },
  [StreetDirection.E]: {
    isDefault: true,
    value: 'E',
  },
  [StreetDirection.W]: {
    isDefault: true,
    value: 'W',
  },
  [StreetDirection.NE]: {
    isDefault: true,
    value: 'NE',
  },
  [StreetDirection.NW]: {
    isDefault: true,
    value: 'NW',
  },
  [StreetDirection.SE]: {
    isDefault: true,
    value: 'SE',
  },
  [StreetDirection.SW]: {
    isDefault: true,
    value: 'SW',
  },
};

// AddressExpandedKey.ADDRESS_EXPANDED_TYPE
export const ADDRESS_EXPANDED_TYPE_OPTIONS: Record<AddressExpandedType, FieldOptionDetails> = {
  [AddressExpandedType.CURRENT]: {
    isDefault: true,
    value: 'Current',
  },
  [AddressExpandedType.MAILING]: {
    isDefault: true,
    value: 'Mailing',
  },
  [AddressExpandedType.PREVIOUS]: {
    isDefault: true,
    value: 'Previous',
  },
};

// AddressExpandedKey.RESIDENTIAL_TYPE
export const RESIDENTIAL_TYPE_OPTIONS: Record<ResidentialType, FieldOptionDetails> = {
  [ResidentialType.RESIDENTIAL]: {
    isDefault: true,
    value: 'Residential',
  },
  [ResidentialType.BUSINESS]: {
    isDefault: true,
    value: 'Business',
  },
  [ResidentialType.TEMPORARY]: {
    isDefault: true,
    value: 'Temporary',
  },
  [ResidentialType.MAIL]: {
    isDefault: true,
    value: 'Mail',
  },
  [ResidentialType.OTHER]: {
    isDefault: true,
    value: 'Other',
  },
  [ResidentialType.RECREATION]: {
    isDefault: true,
    value: 'Recreation',
  },
  [ResidentialType.ALTERNATIVE]: {
    isDefault: true,
    value: 'Alternative',
  },
  [ResidentialType.PREVIOUS]: {
    isDefault: true,
    value: 'Previous',
  },
  [ResidentialType.FUNDING]: {
    isDefault: true,
    value: 'Funding',
  },
};
// AddressExpandedKey.RESIDENTIAL_STATUS_TYPE
export const RESIDENTIAL_STATUS_TYPE_OPTIONS: Record<ResidentialStatusType, FieldOptionDetails> = {
  [ResidentialStatusType.OWN]: {
    isDefault: true,
    value: 'Own',
  },
  [ResidentialStatusType.RENT]: {
    isDefault: true,
    value: 'Rent',
  },
  [ResidentialStatusType.LIVE_WITH_PARENTS]: {
    isDefault: true,
    value: 'Live With Parents',
  },
  [ResidentialStatusType.OTHER]: {
    isDefault: true,
    value: 'Other',
  },
  [ResidentialStatusType.SECONDARY]: {
    isDefault: true,
    value: 'Secondary',
  },
};

// AgentAddressKey.AGENT_ADDRESS_EXPANDED_TYPE
export const AGENT_ADDRESS_EXPANDED_TYPE_OPTIONS: Record<
  AgentAddressExpandedType,
  FieldOptionDetails
> = {
  [AgentAddressExpandedType.ALTERNATIVE]: {
    isDefault: true,
    value: 'Alternative',
  },
  [AgentAddressExpandedType.BUSINESS]: {
    isDefault: true,
    value: 'Business',
  },
  [AgentAddressExpandedType.FUNDING]: {
    isDefault: true,
    value: 'Funding',
  },
  [AgentAddressExpandedType.RECREATION]: {
    isDefault: true,
    value: 'Recreation',
  },
  [AgentAddressExpandedType.RESIDENTIAL]: {
    isDefault: true,
    value: 'Residential',
  },
  [AgentAddressExpandedType.MAIL]: {
    isDefault: true,
    value: 'Mail',
  },
  [AgentAddressExpandedType.TEMPORARY]: {
    isDefault: true,
    value: 'Temporary',
  },
  [AgentAddressExpandedType.OTHER]: {
    isDefault: true,
    value: 'Other',
  },
};

// MortgageKey.INSURANCE_PAID_BY
export const INSURANCE_PAID_BY_OPTIONS: Record<InsurancePaidByType, FieldOptionDetails> = {
  [InsurancePaidByType.BORROWER]: {
    isDefault: true,
    value: 'Borrower',
  },
  [InsurancePaidByType.LENDER]: {
    isDefault: true,
    value: 'Lender',
  },
};

// MortgageKey.ORIGINAL_MI_PROVIDER
export const ORIGINAL_MI_PROVIDER_OPTIONS: Record<InsurerType, FieldOptionDetails> = {
  [InsurerType.CANADA_GUARANTY]: {
    isDefault: true,
    value: 'Canada Guaranty',
  },
  [InsurerType.SAGEN]: {
    isDefault: true,
    value: 'Sagen',
  },
  [InsurerType.CMHC]: {
    isDefault: true,
    value: 'CMHC',
  },
};

// ApplicationKey.CLOSING_SERVICE
export const CLOSING_SERVICE_OPTIONS: Record<EQClosingService, FieldOptionDetails> = {
  [EQClosingService.STANDARD]: {
    isDefault: true,
    value: 'Standard',
  },
  [EQClosingService.COLLATERAL_SWITCH]: {
    isDefault: true,
    value: 'Collateral Switch',
  },
  [EQClosingService.PLATINUM_REFINANCE]: {
    isDefault: true,
    value: 'Platinum Refinance',
  },
  [EQClosingService.PLATINUM_REM]: {
    isDefault: true,
    value: 'Platinum REM',
  },
  [EQClosingService.PLATINUM_SWITCH]: {
    isDefault: true,
    value: 'Platinum Switch',
  },
};

// ApplicationKey.LENDING_TIER
export const LENDING_TIER_OPTIONS: Record<LendingTierType, FieldOptionDetails> = {
  [LendingTierType.TIER_1]: {
    isDefault: true,
    value: 'Tier 1',
  },
  [LendingTierType.TIER_2]: {
    isDefault: true,
    value: 'Tier 2',
  },
  [LendingTierType.TIER_3]: {
    isDefault: true,
    value: 'Tier 3',
  },
};

// ApplicationKey.SOURCE_OF_BUSINESS
export const SOURCE_OF_BUSINESS_OPTIONS: Record<SourceOfBusinessType, FieldOptionDetails> = {
  [SourceOfBusinessType.BROKER]: {
    isDefault: true,
    value: 'Broker',
  },
  [SourceOfBusinessType.OTHER]: {
    isDefault: true,
    value: 'Other',
  },
  [SourceOfBusinessType.RETAIL]: {
    isDefault: true,
    value: 'Retails',
  },
  [SourceOfBusinessType.MORTGAGE_SPECIALIST]: {
    isDefault: true,
    value: 'Mortgage Specialist',
  },
  [SourceOfBusinessType.BRANCH]: {
    isDefault: true,
    value: 'Branch',
  },
  [SourceOfBusinessType.ON_LINE]: {
    isDefault: true,
    value: 'On-line',
  },
  [SourceOfBusinessType.CALL_CENTER]: {
    isDefault: true,
    value: 'Call Center',
  },
};

//ApplicationKey.PURPOSE_CODE
export const PURPOSE_CODE_OPTIONS: Record<PurposeCode, FieldOptionDetails> = {
  [PurposeCode.CONSOLIDATION_REFINANCE]: {
    isDefault: true,
    value: 'Consolidation/Refinance',
  },
  [PurposeCode.AUTO_USED_REPAIRS_INSURANCE]: {
    isDefault: true,
    value: 'Auto: Used/Repairs/Insurance',
  },
  [PurposeCode.NEW_AUTO]: {
    isDefault: true,
    value: 'New Auto',
  },
  [PurposeCode.RECREATIONAL_VEHICLES]: {
    isDefault: true,
    value: 'Recreational Vehicles (including Motor Homes)',
  },
  [PurposeCode.BOATS]: {
    isDefault: true,
    value: 'Boats (Pleasure Craft)',
  },
  [PurposeCode.VACATIONS]: {
    isDefault: true,
    value: 'Vacations',
  },
  [PurposeCode.FURNITURE]: {
    isDefault: true,
    value: 'Furniture',
  },
  [PurposeCode.HOME_REPAIRS_RENOVATIONS]: {
    isDefault: true,
    value: 'Home/Repairs/Renovations',
  },
  [PurposeCode.TAXES]: {
    isDefault: true,
    value: 'Taxes',
  },
  [PurposeCode.BRIDGE_FINANCING]: {
    isDefault: true,
    value: 'Bridge Financing',
  },
  [PurposeCode.INVESTMENT]: {
    isDefault: true,
    value: 'Investment',
  },
  [PurposeCode.CONSTRUCTION_MULTIPLE_DRAWS]: {
    isDefault: true,
    value: 'Construction multiple draws',
  },
  [PurposeCode.PURCHASE_HOUSING_RENTAL]: {
    isDefault: true,
    value: 'Purchase Housing (Rental)',
  },
  [PurposeCode.PURCHASE_HOUSING_OWNER_OCCUPIED]: {
    isDefault: true,
    value: 'Purchase Housing (Owner Occupied)',
  },
  [PurposeCode.PURCHASE_REAL_ESTATE_VACANT_LAND]: {
    isDefault: true,
    value: 'Purchase Real Estate (Vacant Land)',
  },
  [PurposeCode.PURCHASE_REAL_ESTATE_OTHER_VACATION]: {
    isDefault: true,
    value: 'Purchase Real Estate (Other/Vacation)',
  },
  [PurposeCode.MANUFACTURED_HOME_NEW]: {
    isDefault: true,
    value: 'Manufactured Home (New)',
  },
  [PurposeCode.MISCELLANEOUS]: {
    isDefault: true,
    value: 'Miscellaneous',
  },
  [PurposeCode.RRSP_PURCHASE]: {
    isDefault: true,
    value: 'RRSP Purchase',
  },
  [PurposeCode.LETTER_OF_CREDIT]: {
    isDefault: true,
    value: 'Letter of Credit',
  },
  [PurposeCode.MORTGAGE_ASSIGNMENTS]: {
    isDefault: true,
    value: 'Mortgage Assignments',
  },
  [PurposeCode.FIRST_HOME]: {
    isDefault: true,
    value: 'First H.O.M.E.',
  },
  [PurposeCode.CONDITIONAL_SALES_CONTRACT]: {
    isDefault: true,
    value: 'Conditional Sales Contract',
  },
  [PurposeCode.RESTRUCTURE]: {
    isDefault: true,
    value: 'Restructure (Existing Debt - Hardship Rewrite ONLY)',
  },
  [PurposeCode.EQUITY_DEAL]: {
    isDefault: true,
    value: 'Equity Deal',
  },
  [PurposeCode.MOBILE_HOME]: {
    isDefault: true,
    value: 'Mobile Home',
  },
  [PurposeCode.MERITLINE]: {
    isDefault: true,
    value: 'Meritline',
  },
  [PurposeCode.CREDITLINE]: {
    isDefault: true,
    value: 'Creditline',
  },
  [PurposeCode.FARM_PURCHASE]: {
    isDefault: true,
    value: 'Farm Purchase',
  },
  [PurposeCode.FARM_OPERATING]: {
    isDefault: true,
    value: 'Farm Operating',
  },
  [PurposeCode.FARM_EQUIPMENT]: {
    isDefault: true,
    value: 'Farm Equipment',
  },
  [PurposeCode.COMMERCIAL_PROPERTY]: {
    isDefault: true,
    value: 'Commercial Property',
  },
  [PurposeCode.COMMERCIAL_OPERATING]: {
    isDefault: true,
    value: 'Commercial Operating',
  },
  [PurposeCode.COMMERCIAL_EQUIPMENT]: {
    isDefault: true,
    value: 'Commercial Equipment',
  },
  [PurposeCode.OTHER_EQUIPMENT]: {
    isDefault: true,
    value: 'Other Equipment',
  },
  [PurposeCode.POSTAL_LETTER_OF_GUARANTEE]: {
    isDefault: true,
    value: 'Postal Letter of Guarantee',
  },
  [PurposeCode.MORTGAGE_ASSUMPTIONS]: {
    isDefault: true,
    value: 'Mortgage Assumptions',
  },
  [PurposeCode.VARIABLE_RATE_DEALER_SPECIAL]: {
    isDefault: true,
    value: 'Variable Rate - Dealer Special',
  },
  [PurposeCode.FIXED_RATE_AUTO]: {
    isDefault: true,
    value: 'Fixed Rate - Auto',
  },
  [PurposeCode.LOAN_SALE]: {
    isDefault: true,
    value: 'Loan Sale',
  },
  [PurposeCode.PURCHASE_BUILDER_HOME_UPON_COMPLETION]: {
    isDefault: true,
    value: 'Purchase builder home upon completion',
  },
  [PurposeCode.RESTORATION_CREDIT]: {
    isDefault: true,
    value: 'Restoration Credit',
  },
  [PurposeCode.NEW_VENTURES]: {
    isDefault: true,
    value: 'New Ventures',
  },
  [PurposeCode.SBIL]: {
    isDefault: true,
    value: 'SBIL',
  },
  [PurposeCode.LIFE_LEASE]: {
    isDefault: true,
    value: 'Life Lease',
  },
  [PurposeCode.LEASEHOLD]: {
    isDefault: true,
    value: 'Leasehold',
  },
  [PurposeCode.FARM_PLUS]: {
    isDefault: true,
    value: 'Farm Plus',
  },
  [PurposeCode.SYNDICATED_LOAN]: {
    isDefault: true,
    value: 'Syndicated Loan',
  },
  [PurposeCode.GRAPE_PLANTING]: {
    isDefault: true,
    value: 'Grape Planting',
  },
  [PurposeCode.CORPORATE_OFFICE_APPROVED]: {
    isDefault: true,
    value: 'Corporate Office Approved',
  },
  [PurposeCode.EXTENDED_AMORTIZATION]: {
    isDefault: true,
    value: 'Extended Amortization',
  },
  [PurposeCode.HYBRID_PROGRAM]: {
    isDefault: true,
    value: 'Hybrid Program',
  },
  [PurposeCode.MEZZANINE_FINANCING]: {
    isDefault: true,
    value: 'Mezzanine Financing',
  },
  [PurposeCode.BULK_LEASING]: {
    isDefault: true,
    value: 'Bulk Leasing',
  },
  [PurposeCode.GREEN_AUTO]: {
    isDefault: true,
    value: 'Green Auto',
  },
  [PurposeCode.GREEN_LOAN]: {
    isDefault: true,
    value: 'Green Loan',
  },
  [PurposeCode.SPECIAL_CREDIT_EXP_LOCS]: {
    isDefault: true,
    value: 'Special Credit-EXP LOCS',
  },
  [PurposeCode.NON_MANAGEMENT_STAFF]: {
    isDefault: true,
    value: 'Non-Management Staff',
  },
  [PurposeCode.MANAGEMENT_STAFF]: {
    isDefault: true,
    value: 'Management Staff',
  },
  [PurposeCode.NON_PERFORMING_LOAN_STATUS]: {
    isDefault: true,
    value: 'Non-performing loan status',
  },
  [PurposeCode.BANKRUPT_LOAN_STATUS]: {
    isDefault: true,
    value: 'Bankrupt Loan Status',
  },
  [PurposeCode.CASH_FLOW_SENIOR_TERM]: {
    isDefault: true,
    value: 'Cash Flow Senior Term',
  },
  [PurposeCode.HASCAP]: {
    isDefault: true,
    value: 'HASCAP',
  },
  [PurposeCode.HIGH_RATIO_OWNER_OCCUPIED_COMMERCIAL]: {
    isDefault: true,
    value: 'High Ratio Owner Occupied-Commercial',
  },
  [PurposeCode.FRANCHISING]: {
    isDefault: true,
    value: 'Franchising',
  },
  [PurposeCode.CEBA_LOAN]: {
    isDefault: true,
    value: 'CEBA Loan',
  },
  [PurposeCode.CEBA_CONVERSION]: {
    isDefault: true,
    value: 'CEBA Conversion',
  },
  [PurposeCode.BCAP_BDC_CO_LENDING]: {
    isDefault: true,
    value: 'BCAP BDC Co-Lending',
  },
  [PurposeCode.REFINANCED_CEBA_LOAN]: {
    isDefault: true,
    value: 'Refinanced CEBA Loan',
  },
  [PurposeCode.BDC_ACCELERATOR_GUARANTEE_PROGRAM]: {
    isDefault: true,
    value: 'BDC Accelerator Guarantee Program',
  },
  [PurposeCode.TRAILERS_SNOWMOBILES_ATVS]: {
    isDefault: true,
    value: 'Trailers/Snowmobiles/ATVs',
  },
  [PurposeCode.BUILD_A_PROPERTY]: {
    isDefault: true,
    value: 'Build a Property (Land is Already Owned)',
  },
  [PurposeCode.BUSINESS_PURPOSES]: {
    isDefault: true,
    value: 'Business Purposes',
  },
  [PurposeCode.CONSOLIDATION_OF_EXTERNAL_DEBT]: {
    isDefault: true,
    value: 'Consolidation of External Debt',
  },
  [PurposeCode.CONSOLIDATION_OF_INTERNAL_DEBT]: {
    isDefault: true,
    value: 'Consolidation of Internal Debt',
  },
  [PurposeCode.DOWN_PAYMENT_FOR_PROPERTY_PURCHASE]: {
    isDefault: true,
    value: 'Down Payment for Property Purchase',
  },
  [PurposeCode.FUTURE_USE]: {
    isDefault: true,
    value: 'Future Use',
  },
  [PurposeCode.MOTORCYCLE]: {
    isDefault: true,
    value: 'Motorcycle',
  },
  [PurposeCode.PROPERTY_PURCHASE_FROM_EXISTING_EQUITY]: {
    isDefault: true,
    value: 'Property Purchase (from Existing Equity in Other Real Estate)',
  },
  [PurposeCode.RECREATIONAL_HOME_OR_MOTORHOME]: {
    isDefault: true,
    value: 'Recreational Home or Motorhome',
  },
  [PurposeCode.SECONDARY_RESIDENCE]: {
    isDefault: true,
    value: 'Secondary Residence',
  },
  [PurposeCode.TUITION]: {
    isDefault: true,
    value: 'Tuition',
  },
  [PurposeCode.USED_VEHICLE]: {
    isDefault: true,
    value: 'Used Vehicle',
  },
};

export const SECURITY_TYPE_OPTIONS: Record<SecurityType, FieldOptionDetails> = {
  [SecurityType.MORTGAGE_SECURED]: {
    isDefault: true,
    value: 'Mortgage Secured',
  },
  [SecurityType.SECURED]: {
    isDefault: true,
    value: 'Secured',
  },
  [SecurityType.UNSECURED]: {
    isDefault: true,
    value: 'Unsecured',
  },
  [SecurityType.CASH_SECURED]: {
    isDefault: true,
    value: 'Cash Secured',
  },
};

export const SECURITY_OPTIONS: Record<Security, FieldOptionDetails> = {
  [Security.COLLATERAL_CHARGE]: {
    isDefault: true,
    value: `${Security.COLLATERAL_CHARGE}: Collateral Charge`,
  },
  [Security.FIRST_MORTGAGE_RESIDENTIAL_OWNER_OCCUPIED]: {
    isDefault: true,
    value: `${Security.FIRST_MORTGAGE_RESIDENTIAL_OWNER_OCCUPIED}: 1st Mtg Residential Owner Occupied`,
  },
  [Security.FIRST_MORTGAGE_RESIDENTIAL_NON_OWNER_OCCUPIED]: {
    isDefault: true,
    value: `${Security.FIRST_MORTGAGE_RESIDENTIAL_NON_OWNER_OCCUPIED}: 1st Mtg Residential Non-Owner Occupied`,
  },
  [Security.SECOND_MORTGAGE_RESIDENTIAL]: {
    isDefault: true,
    value: `${Security.SECOND_MORTGAGE_RESIDENTIAL}: 2nd Mtg Residential`,
  },
  [Security.THIRD_MORTGAGE_RESIDENTIAL]: {
    isDefault: true,
    value: `${Security.THIRD_MORTGAGE_RESIDENTIAL}: 3rd Mtg Residential`,
  },
  [Security.PRIMA]: {
    isDefault: true,
    value: `${Security.PRIMA}: PRIMA`,
  },
  [Security.FULLY_SECURED_MCU_DEPOSIT_PRODUCTS]: {
    isDefault: true,
    value: `${Security.FULLY_SECURED_MCU_DEPOSIT_PRODUCTS}: Fully Secured MCU Deposit Products`,
  },
  [Security.FULLY_SECURED_CANADA_SAVINGS_BONDS]: {
    isDefault: true,
    value: `${Security.FULLY_SECURED_CANADA_SAVINGS_BONDS}: Fully Secured Canada Savings Bonds`,
  },
  [Security.OTHER_FI_STOCKS_BONDS_ETC]: {
    isDefault: true,
    value: `${Security.OTHER_FI_STOCKS_BONDS_ETC}: Other FI Stocks/Bonds etc.`,
  },
  [Security.CHATTEL_MORTGAGE]: {
    isDefault: true,
    value: `${Security.CHATTEL_MORTGAGE}: Chattel Mortgage`,
  },
  [Security.WAGE_ASSIGNMENT_PROMISSORY_NOTE]: {
    isDefault: true,
    value: `${Security.WAGE_ASSIGNMENT_PROMISSORY_NOTE}: Wage Assignment/Promissory Note`,
  },
  [Security.LETTER_OF_DIRECTION]: {
    isDefault: true,
    value: `${Security.LETTER_OF_DIRECTION}: Letter of Direction`,
  },
  [Security.UNSECURED]: {
    isDefault: true,
    value: `${Security.UNSECURED}: Unsecured`,
  },
};

export const TYPE_OF_CLOSING_INSTRUCTION_OPTIONS: Record<
  MortgageClosingInstruction,
  FieldOptionDetails
> = {
  [MortgageClosingInstruction.EXISTING_COLLATERAL_CHARGE]: {
    isDefault: true,
    value: 'Existing Collateral Charge',
  },
  [MortgageClosingInstruction.FCT]: {
    isDefault: true,
    value: 'FCT',
  },
  [MortgageClosingInstruction.SOLICITOR]: {
    isDefault: true,
    value: 'Solicitor',
  },
  [MortgageClosingInstruction.FCT_REQUEST]: {
    isDefault: true,
    value: 'FCT Request',
  },
};

export const SERVICING_TYPE_OPTIONS: Record<ServicingType, FieldOptionDetails> = {
  [ServicingType.CHANGE_PAYMENT_DATE]: {
    isDefault: true,
    value: 'Change Payment Date',
  },
  [ServicingType.CHANGE_PAYMENT_FREQUENCY]: {
    isDefault: true,
    value: 'Change Frequency',
  },
  [ServicingType.COST_OF_BORROWING_UPDATE]: {
    isDefault: true,
    value: 'Cost of Borrowing Update',
  },
  [ServicingType.DECREASE_PAYMENT]: {
    isDefault: true,
    value: 'Decrease Payment',
  },
  [ServicingType.INCREASE_PAYMENT]: {
    isDefault: true,
    value: 'Increase Payment',
  },
  [ServicingType.RENEWAL_EARLY]: {
    isDefault: true,
    value: 'Early',
  },
  [ServicingType.RENEWAL_ON_MATURITY]: {
    isDefault: true,
    value: 'On Maturity',
  },
};

export const PREPAYMENT_OPTIONS: Record<PrepaymentOptions, FieldOptionDetails> = {
  [PrepaymentOptions.CL]: {
    isDefault: true,
    value: 'CL',
  },
  [PrepaymentOptions.CV]: {
    isDefault: true,
    value: 'CV',
  },
  [PrepaymentOptions.OP]: {
    isDefault: true,
    value: 'OP',
  },
  [PrepaymentOptions.SIX_MONTHS]: {
    isDefault: true,
    value: 'Six Months',
  },
  [PrepaymentOptions.THREE_MONTHS]: {
    isDefault: true,
    value: 'Three Months',
  },
};
