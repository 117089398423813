import {
  Action,
  Actions,
  Selector,
  State,
  StateContext,
  Store,
  ofActionDispatched,
  createSelector,
} from '@ngxs/store';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { PropertiesState } from './properties.state';
import { FinancialLiabilityState } from '../features/application/widgets/credit/financial-liability.state';
import { FundmoreCalculator } from '@fundmoreai/calculator';
import { StandardRatesState } from './standard-rates.state';
import {
  ApplicationServicingData,
  CalculationResult,
  ComputedRatioRateType,
  DatesProcessingType,
  Fee,
  LoanType,
  Mortgage,
  CalculationAutomationSettings,
  MortgageType,
  OtherRateConfigurations,
  PaymentFrequency,
  Property,
  PropertyType,
  ServicingApplicationPurpose,
  Summary,
} from '@fundmoreai/models';
import { ApplicationResetState } from '../shared/state.model';
import { FeesState } from './fees.state';
import { MortgagesV2State } from 'src/app/portal/mortgages-v2/mortgages-v2.state';
import { IncomeState } from '../features/application/widgets/income/income.state';
import { StandardRatesModel } from '../shared/model';
import { ComputeSummaryAllRequestedMortgages } from './summary.actions';
import { AppFeaturesState } from '../shared/app-features.state';
import { MortgageApplicationState } from './mortgage-application.state';
import { AuthState } from '../auth/auth.state';
import { formatISO } from 'date-fns';

@State<Summary>({
  name: 'summary',
})
@Injectable()
export class SummaryState {
  constructor(private store: Store, private actions$: Actions) {}

  static summaryByRequestedMortgage(requestedMortgageId: string) {
    return createSelector(
      [
        SummaryState.ltiForPrimaryProperty,
        MortgagesV2State.ltvByRequestedMortgage(requestedMortgageId),
        SummaryState.gdsAndTdsByRequestedMortgage(requestedMortgageId),
        SummaryState.cltvByRequestedMortgage(requestedMortgageId),
      ],
      (lti, ltv, { gds, qgds, tds, qtds, bgds, btds, qualifyingRate, bocRate }, cLtv) => {
        return {
          lti,
          ltv,
          gds,
          tds,
          qgds,
          qtds,
          bgds,
          btds,
          cLtv,
          qualifyingRate,
          bocRate: bocRate ?? 0,
        };
      },
    );
  }

  @Selector([
    FeesState.feesList,
    MortgagesV2State.capFeesMaxPercentage,
    MortgagesV2State.requestedMortgages,
    PropertiesState.primaryProperty,
    MortgagesV2State.primaryPropertyExistingMortgageAmount,
    MortgagesV2State.primaryPropertyExistingMortgagePayment,
    MortgagesV2State.borrowerExistingMortgages,
    IncomeState.totalMonthlyIncomeWithoutPropertyRental,
    PropertiesState.otherProperties,
    FinancialLiabilityState.debt,
    StandardRatesState.standardRates,
    MortgagesV2State.calculationAutomationSettings,
    PropertiesState.propertiesListWithOwners,
    MortgagesV2State.existingMortgages,
    AppFeaturesState.isBlanketEnabled,
    PropertiesState.propertiesList,
    MortgagesV2State.existingMortgages,
    MortgagesV2State.totalLoanAmountForAllRequestedMortgages,
    AppFeaturesState.processingDates,
    AuthState.tenantCode,
    AppFeaturesState.iadByFpdEnabled,
    AppFeaturesState.takeFullFirstPayment,
    MortgagesV2State.servicingCurrentMortgage,
    MortgagesV2State.servicingNewMortgage,
    MortgageApplicationState.applicationServicingData,
    MortgageApplicationState.applicationServicingPurpose,
    MortgageApplicationState.servicingMortgageRemainingTerm,
  ])
  static individualSummaryAllRequestedMortgages(
    fees: Fee[],
    capFeesMaxPercentage: number,
    requestedMortgages: Mortgage[],
    primaryProperty: Property | undefined,
    primaryPropertyExistingMortgageAmount: number,
    primaryPropertyExistingMortgagePayment: number,
    borrowerExistingMortgages: Mortgage[],
    totalIncomeWithoutPropertyRental: number,
    otherProperties: Property[] | undefined,
    debt: number,
    boc: StandardRatesModel,
    calculationAutomationSettings: CalculationAutomationSettings,
    properties: Property[],
    existingMortgages: Mortgage[],
    isBlanketEnabled: boolean,
    allProperties: Property[],
    allExistingMortgages: Mortgage[],
    totalLoanAmountForAllRequestedMortgages: number,
    processingDates: DatesProcessingType | undefined,
    tenantCode: string,
    iadByFpdEnabled: boolean,
    takeFullFirstPayment: boolean,
    servicingCurrentMortgage: Mortgage,
    servicingNewMortgage: Mortgage,
    applicationServicingData: ApplicationServicingData | null | undefined,
    applicationServicingPurpose: ServicingApplicationPurpose | undefined,
    servicingMortgageRemainingTerm: number | undefined,
  ): (Summary & { mortgageId?: string })[] {
    const mortgagesComputedData =
      requestedMortgages.map((requestedMortgage) => {
        const totalLoanAmount = MortgagesV2State.loanAmountForLTVByMortgage(requestedMortgage.id)(
          requestedMortgage,
          fees,
          capFeesMaxPercentage,
        );
        const ltv = MortgagesV2State.ltvByRequestedMortgage(requestedMortgage.id)(
          MortgagesV2State.linkedPropertyValueForLtv(requestedMortgage.id)(
            requestedMortgage,
            allProperties,
            primaryProperty,
            isBlanketEnabled,
            calculationAutomationSettings,
          ),
          MortgagesV2State.linkedPropertyExistingMortgageAmount(requestedMortgage.id)(
            requestedMortgages,
            primaryPropertyExistingMortgageAmount,
            isBlanketEnabled,
          ),
          MortgagesV2State.loanAmountForLTVByMortgage(requestedMortgage.id)(
            requestedMortgage,
            fees,
            capFeesMaxPercentage,
          ),
          calculationAutomationSettings,
        );

        const { gds, qgds, tds, qtds, bgds, btds, qualifyingRate, bocRate } =
          SummaryState.gdsAndTdsByRequestedMortgage(requestedMortgage.id)(
            requestedMortgage,
            totalLoanAmount,
            primaryPropertyExistingMortgagePayment,
            borrowerExistingMortgages,
            totalIncomeWithoutPropertyRental,
            primaryProperty,
            otherProperties,
            debt,
            boc,
            ltv,
            calculationAutomationSettings,
            isBlanketEnabled,
            allProperties,
            allExistingMortgages,
          );
        const cLtv = SummaryState.cltvByRequestedMortgage(requestedMortgage.id)(
          properties,
          requestedMortgage,
          existingMortgages,
          capFeesMaxPercentage,
          calculationAutomationSettings,
          fees,
        );

        const ltvBySecurity = SummaryState.ltvBySecurity(
          requestedMortgages,
          existingMortgages,
          properties,
          fees,
          capFeesMaxPercentage,
          isBlanketEnabled,
          calculationAutomationSettings,
        );

        const ltiBySecurity = SummaryState.ltiBySecurity(
          requestedMortgages,
          existingMortgages,
          properties,
          totalIncomeWithoutPropertyRental,
          fees,
          capFeesMaxPercentage,
          isBlanketEnabled,
        );

        const lti = SummaryState.ltiForPrimaryProperty(primaryProperty, ltiBySecurity);

        const apr = MortgagesV2State.aprForMortgage(requestedMortgage.id)(
          requestedMortgages,
          totalLoanAmountForAllRequestedMortgages,
          fees,
          processingDates,
          tenantCode,
          calculationAutomationSettings,
          iadByFpdEnabled,
          takeFullFirstPayment,
        );

        return {
          mortgageId: requestedMortgage.id,
          lti,
          ltv,
          gds,
          tds,
          qgds,
          qtds,
          bgds,
          btds,
          cLtv,
          qualifyingRate,
          bocRate: bocRate ?? 0,
          ltvBySecurity,
          ltiBySecurity,
          apr,
        };
      }) ?? [];

    if (servicingNewMortgage) {
      let apr: number | undefined;

      if (applicationServicingPurpose === ServicingApplicationPurpose.SERVICING_RENEWAL) {
        apr = MortgagesV2State.servicingApr(
          {
            ...servicingNewMortgage,
            totalLoanAmount:
              servicingNewMortgage.projectedBalance || servicingNewMortgage.mortgageBalance || null,
            firstRegularPaymentDate: servicingNewMortgage.nextPaymentDate || null,
          },
          [],
          processingDates,
          tenantCode,
          calculationAutomationSettings,
          iadByFpdEnabled,
          takeFullFirstPayment,
        );
      } else {
        const closingDate =
          applicationServicingPurpose === ServicingApplicationPurpose.COST_OF_BORROWING
            ? formatISO(new Date(), { representation: 'date' })
            : applicationServicingData?.effectiveDate || null;
        const paymentFrequency =
          applicationServicingPurpose === ServicingApplicationPurpose.COST_OF_BORROWING
            ? servicingCurrentMortgage.paymentFrequency
            : servicingNewMortgage.paymentFrequency;
        const paymentAmount =
          applicationServicingPurpose === ServicingApplicationPurpose.COST_OF_BORROWING
            ? servicingCurrentMortgage.paymentAmount
            : servicingNewMortgage.paymentAmount;
        const termMonths = servicingMortgageRemainingTerm || null;
        const firstRegularPaymentDate =
          (applicationServicingPurpose === ServicingApplicationPurpose.COST_OF_BORROWING
            ? servicingCurrentMortgage.nextPaymentDate
            : servicingNewMortgage.nextPaymentDate) || null;

        const mortgage: Mortgage = {
          ...servicingCurrentMortgage,
          paymentFrequency,
          paymentAmount,
          termMonths,
          closingDate,
          firstRegularPaymentDate,
          totalLoanAmount: servicingCurrentMortgage.mortgageBalance,
        };

        apr = MortgagesV2State.servicingApr(
          mortgage,
          [],
          processingDates,
          tenantCode,
          calculationAutomationSettings,
          iadByFpdEnabled,
          takeFullFirstPayment,
        );
      }

      mortgagesComputedData.push({
        mortgageId: servicingNewMortgage.id,
        lti: this.EMPTY_CALCULATION_RESULT,
        ltv: this.EMPTY_CALCULATION_RESULT,
        gds: this.EMPTY_CALCULATION_RESULT,
        tds: this.EMPTY_CALCULATION_RESULT,
        qgds: this.EMPTY_CALCULATION_RESULT,
        qtds: this.EMPTY_CALCULATION_RESULT,
        bgds: this.EMPTY_CALCULATION_RESULT,
        btds: this.EMPTY_CALCULATION_RESULT,
        cLtv: this.EMPTY_CALCULATION_RESULT,
        apr,
        qualifyingRate: 0,
        bocRate: 0,
        ltvBySecurity: {},
        ltiBySecurity: {},
      });
    }

    return mortgagesComputedData;
  }

  @Selector()
  static summaryAllRequestedMortgages(state: Summary) {
    return state;
  }

  @Selector([
    SummaryState.ltvAllRequestedMortgages,
    SummaryState.gdsAndTdsAllRequestedMortgages,
    SummaryState.cltvAllRequestedMortgages,
    SummaryState.ltvBySecurity,
    SummaryState.ltiBySecurity,
    SummaryState.ltiForPrimaryProperty,
  ])
  static summaryAllRequestedMortgagesForDocumentContext(
    ltv: CalculationResult,
    {
      gds,
      qgds,
      tds,
      qtds,
      bgds,
      btds,
      qualifyingRate,
      bocRate,
    }: {
      gds: CalculationResult;
      qgds: CalculationResult;
      tds: CalculationResult;
      qtds: CalculationResult;
      bgds: CalculationResult;
      btds: CalculationResult;
      qualifyingRate: number;
      bocRate: number;
    },
    cLtv: CalculationResult,
    ltvBySecurity: { [key: string]: CalculationResult } | undefined,
    ltiBySecurity: { [key: string]: CalculationResult } | undefined,
    lti: CalculationResult,
  ): Summary {
    return {
      lti,
      ltv,
      gds,
      tds,
      qgds,
      qtds,
      bgds,
      btds,
      cLtv,
      qualifyingRate,
      bocRate,
      ltvBySecurity,
      ltiBySecurity,
    };
  }

  static costToCarryForTDSByRequestedMortgage(requestedMortgage: Mortgage) {
    return createSelector(
      [
        PropertiesState.linkedProperty(requestedMortgage.propertyId),
        MortgagesV2State.requestedMortgage(requestedMortgage.id),
        MortgageApplicationState.isServicingApplication,
        MortgagesV2State.totalLoanAmount(requestedMortgage.id),
        AppFeaturesState.otherRateConfigurations,
      ],
      (
        linkedProperty: Property | undefined,
        requestedMortgage: Mortgage | undefined,
        isServicing: boolean | undefined,
        totalLoanAmount: number,
        { liftForVariableRate }: OtherRateConfigurations,
      ) => {
        const paymentForCostToCarry =
          isServicing && requestedMortgage?.type === MortgageType.REQUESTED
            ? requestedMortgage?.frequencyPayment
            : getMonthlyPaymentForRequestedMortgage(
                requestedMortgage,
                totalLoanAmount,
                liftForVariableRate,
              );
        return FundmoreCalculator.computeCostToCarryForTDS(
          linkedProperty,
          paymentForCostToCarry ?? undefined,
        );
      },
    );
  }

  @Selector([
    MortgagesV2State.linkedPropertyValueAllRequestedMortgagesForLtv,
    MortgagesV2State.linkedPropertyExistingMortgageAmountAllRequestedMortgages,
    MortgagesV2State.loanAmountForLTVAllRequestedMortgages,
    MortgagesV2State.calculationAutomationSettings,
  ])
  static ltvAllRequestedMortgages(
    linkedPropertyValueAllRequestedMortgagesForLtv: number,
    linkedPropertyExistingMortgageAmountAllRequestedMortgages: number,
    loanAmountForLTVTotal: number | undefined,
    calculationAutomationSettings: CalculationAutomationSettings,
  ) {
    const ltv = FundmoreCalculator.computeLTV(
      linkedPropertyValueAllRequestedMortgagesForLtv,
      linkedPropertyExistingMortgageAmountAllRequestedMortgages,
      loanAmountForLTVTotal,
      calculationAutomationSettings,
    );
    return ltv;
  }

  private static readonly EMPTY_CALCULATION_RESULT: CalculationResult = {
    formula: '',
    items: [],
    result: 0,
  };

  static gdsAndTdsByRequestedMortgage(requestedMortgageId: string) {
    return createSelector(
      [
        MortgagesV2State.requestedMortgage(requestedMortgageId),
        MortgagesV2State.totalLoanAmount(requestedMortgageId),
        MortgagesV2State.primaryPropertyExistingMortgagePayment,
        MortgagesV2State.borrowerExistingMortgages,
        IncomeState.totalMonthlyIncomeWithoutPropertyRental,
        PropertiesState.primaryProperty,
        PropertiesState.otherProperties,
        FinancialLiabilityState.debt,
        StandardRatesState.standardRates,
        MortgagesV2State.ltvByRequestedMortgage(requestedMortgageId),
        MortgagesV2State.calculationAutomationSettings,
        AppFeaturesState.isBlanketEnabled,
        PropertiesState.propertiesList,
        MortgagesV2State.existingMortgages,
      ],
      (
        requestedMortgage: Mortgage | undefined,
        totalLoanAmount: number | undefined,
        existingMortgagePaymentsOnPrimaryProperty: number,
        existingMortgages: Mortgage[],
        totalIncomeWithoutPropertyRental: number,
        primaryProperty: Property | undefined,
        otherProperties: Property[] | undefined,
        debt: number,
        boc: StandardRatesModel,
        _ltvByRequestedMortgage: CalculationResult,
        calculationAutomationSettings: CalculationAutomationSettings,
        isBlanketEnabled: boolean,
        allProperties: Property[],
        allExistingMortgages: Mortgage[],
      ) => {
        if (!requestedMortgage) {
          return {
            gds: SummaryState.EMPTY_CALCULATION_RESULT,
            qgds: SummaryState.EMPTY_CALCULATION_RESULT,
            tds: SummaryState.EMPTY_CALCULATION_RESULT,
            qtds: SummaryState.EMPTY_CALCULATION_RESULT,
            bgds: SummaryState.EMPTY_CALCULATION_RESULT,
            btds: SummaryState.EMPTY_CALCULATION_RESULT,
            qualifyingRate: 0,
            bocRate: 0,
          };
        }

        const netRate = requestedMortgage.netRate;
        const qualifyingRate = FundmoreCalculator.computeQualifyingRate(
          netRate ?? null,
          boc?.bocConventionalMortgage5Year,
        );
        const benchmarkRate = boc?.bocConventionalMortgage5Year;
        let monthlyPayment;
        const isLOC =
          requestedMortgage.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
          requestedMortgage.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX;

        if (isLOC) {
          monthlyPayment = requestedMortgage.amortizedMonthlyPayment;
        } else {
          monthlyPayment =
            !calculationAutomationSettings ||
            !calculationAutomationSettings[requestedMortgageId]?.isMonthlyPaymentDisabled
              ? FundmoreCalculator.computeMortgageMonthlyPayment(
                  requestedMortgage,
                  totalLoanAmount,
                  netRate,
                )
              : requestedMortgage?.monthlyPayment;
        }

        const requestedMortgageForCalculation = isLOC
          ? {
              ...requestedMortgage,
              ...requestedMortgage.amortizedMonthlyPaymentSnapshot,
            }
          : requestedMortgage;

        const { interest, principal } = FundmoreCalculator.computePrincipalAndInterest({
          ...requestedMortgageForCalculation,
          paymentFrequency: PaymentFrequency.MONTHLY,
          paymentAmount: monthlyPayment || null,
        });

        const securitiesIdsForBlanket = allProperties
          ?.filter((x) => x.type === PropertyType.PRIMARY || x.isCollateralized)
          .map((x) => x.id);

        // Use existing mortgages for the property linked to the requested mortgage if NOT blanket,
        // otherwise use existing mortgages for all securities
        const existingMortgagesForPropertyLinkedToRequestedMortgage = allExistingMortgages.filter(
          (x) =>
            (!isBlanketEnabled && x.propertyId === requestedMortgage.propertyId) ||
            (isBlanketEnabled && x.propertyId && securitiesIdsForBlanket?.includes(x.propertyId)),
        );
        const existingMortgagesForPropertyLinkedToRequestedMortgagePayments =
          FundmoreCalculator.computeExistingMortgagePayments(
            existingMortgagesForPropertyLinkedToRequestedMortgage,
          );

        // Use the linked property if NOT blanket, otherwise use the primary property
        const requestedMortgageProperty = allProperties?.find(
          (x) =>
            (!isBlanketEnabled && x.id === requestedMortgage.propertyId) ||
            (isBlanketEnabled && x.type === PropertyType.PRIMARY),
        );

        const gds = FundmoreCalculator.computeGDS(
          monthlyPayment,
          requestedMortgageProperty,
          otherProperties,
          existingMortgagesForPropertyLinkedToRequestedMortgage,
          existingMortgagesForPropertyLinkedToRequestedMortgagePayments,
          totalIncomeWithoutPropertyRental,
          principal,
          interest,
          calculationAutomationSettings,
          ComputedRatioRateType.NET,
          isLOC,
        );

        const tds = FundmoreCalculator.computeTDS(
          monthlyPayment,
          primaryProperty,
          otherProperties,
          existingMortgages,
          existingMortgagePaymentsOnPrimaryProperty,
          totalIncomeWithoutPropertyRental,
          debt,
          principal,
          interest,
          calculationAutomationSettings,
          ComputedRatioRateType.NET,
          isLOC,
        );

        const qualifyingMortgageMonthlyPayment = FundmoreCalculator.computeMortgageMonthlyPayment(
          requestedMortgageForCalculation,
          totalLoanAmount,
          qualifyingRate,
        );

        const { interest: qualifyingInterest, principal: qualifyingPrincipal } =
          FundmoreCalculator.computePrincipalAndInterest({
            ...requestedMortgageForCalculation,
            paymentFrequency: PaymentFrequency.MONTHLY,
            paymentAmount: qualifyingMortgageMonthlyPayment || null,
            netRate: qualifyingRate,
          });

        const qgds = FundmoreCalculator.computeGDS(
          qualifyingMortgageMonthlyPayment,
          requestedMortgageProperty,
          otherProperties,
          existingMortgagesForPropertyLinkedToRequestedMortgage,
          existingMortgagesForPropertyLinkedToRequestedMortgagePayments,
          totalIncomeWithoutPropertyRental,
          qualifyingPrincipal,
          qualifyingInterest,
          null,
          ComputedRatioRateType.QUALIFYING,
          isLOC,
        );

        const qtds = FundmoreCalculator.computeTDS(
          qualifyingMortgageMonthlyPayment,
          primaryProperty,
          otherProperties,
          existingMortgages,
          existingMortgagePaymentsOnPrimaryProperty,
          totalIncomeWithoutPropertyRental,
          debt,
          qualifyingPrincipal,
          qualifyingInterest,
          null,
          ComputedRatioRateType.QUALIFYING,
          isLOC,
        );
        const benchmarkMortgageMonthlyPayment = FundmoreCalculator.computeMortgageMonthlyPayment(
          requestedMortgage,
          totalLoanAmount,
          benchmarkRate,
        );

        const { interest: benchmarkInterest, principal: benchmarkPrincipal } =
          FundmoreCalculator.computePrincipalAndInterest({
            ...requestedMortgageForCalculation,
            paymentFrequency: PaymentFrequency.MONTHLY,
            paymentAmount: benchmarkMortgageMonthlyPayment || null,
            netRate: benchmarkRate ?? 0,
          });

        const bgds = FundmoreCalculator.computeGDS(
          benchmarkMortgageMonthlyPayment,
          requestedMortgageProperty,
          otherProperties,
          existingMortgagesForPropertyLinkedToRequestedMortgage,
          existingMortgagesForPropertyLinkedToRequestedMortgagePayments,
          totalIncomeWithoutPropertyRental,
          benchmarkPrincipal,
          benchmarkInterest,
          null,
          ComputedRatioRateType.BENCHMARK,
          isLOC,
        );

        const btds = FundmoreCalculator.computeTDS(
          benchmarkMortgageMonthlyPayment,
          primaryProperty,
          otherProperties,
          existingMortgages,
          existingMortgagePaymentsOnPrimaryProperty,
          totalIncomeWithoutPropertyRental,
          debt,
          benchmarkPrincipal,
          benchmarkInterest,
          null,
          ComputedRatioRateType.BENCHMARK,
          isLOC,
        );

        return { gds, qgds, tds, qtds, bgds, btds, qualifyingRate, bocRate: benchmarkRate };
      },
    );
  }

  @Selector([
    MortgagesV2State.activeRequestedMortgagesForCalculations,
    MortgagesV2State.primaryPropertyExistingMortgagePayment,
    MortgagesV2State.borrowerExistingMortgages,
    IncomeState.totalMonthlyIncomeWithoutPropertyRental,
    PropertiesState.primaryProperty,
    PropertiesState.otherProperties,
    FinancialLiabilityState.debt,
    StandardRatesState.standardRates,
    SummaryState.ltvAllRequestedMortgages,
    MortgagesV2State.calculationAutomationSettings,
  ])
  static gdsAndTdsAllRequestedMortgages(
    requestedMortgages: Mortgage[],
    existingMortgagePaymentsOnPrimaryProperty: number,
    existingMortgages: Mortgage[],
    totalIncomeWithoutPropertyRental: number,
    primaryProperty: Property | undefined,
    otherProperties: Property[] | undefined,
    debt: number,
    boc: StandardRatesModel,
    _ltvAllRequestedMortgages: CalculationResult,
    calculationAutomationSettings: CalculationAutomationSettings,
  ) {
    let monthlyPaymentTotal = 0,
      qualifyingMortgageMonthlyPaymentTotal = 0,
      benchmarkMortgageMonthlyPaymentTotal = 0;
    let qualifyingRateTotal = 0;
    let principalTotal = 0,
      qualifyingPrincipalTotal = 0,
      benchmarkPrincipalTotal = 0;
    let interestTotal = 0,
      qualifyingInterestTotal = 0,
      benchmarkInterestTotal = 0;
    const benchmarkRate = boc?.bocConventionalMortgage5Year || 0;

    requestedMortgages.forEach((requestedMortgage) => {
      const netRate = requestedMortgage?.netRate;
      const qualifyingRate = FundmoreCalculator.computeQualifyingRate(
        netRate,
        boc?.bocConventionalMortgage5Year,
      );
      const isLOC =
        requestedMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
        requestedMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX;

      const monthlyPayment =
        (isLOC
          ? requestedMortgage?.amortizedMonthlyPayment
          : !calculationAutomationSettings ||
            !calculationAutomationSettings[requestedMortgage.id]?.isMonthlyPaymentDisabled
          ? FundmoreCalculator.computeMortgageMonthlyPayment(
              requestedMortgage,
              requestedMortgage.totalLoanAmount ?? 0,
              netRate,
            )
          : requestedMortgage?.monthlyPayment) ?? 0;

      monthlyPaymentTotal += monthlyPayment;
      qualifyingRateTotal += qualifyingRate;

      const requestedMortgageForCalculation = isLOC
        ? {
            ...requestedMortgage,
            ...requestedMortgage.amortizedMonthlyPaymentSnapshot,
          }
        : requestedMortgage;

      const qualifyingMortgageMonthlyPayment =
        FundmoreCalculator.computeMortgageMonthlyPayment(
          requestedMortgageForCalculation,
          requestedMortgageForCalculation.totalLoanAmount ?? 0,
          qualifyingRate,
        ) || 0;

      qualifyingMortgageMonthlyPaymentTotal += qualifyingMortgageMonthlyPayment;

      const benchmarkMortgageMonthlyPayment =
        FundmoreCalculator.computeMortgageMonthlyPayment(
          requestedMortgageForCalculation,
          requestedMortgageForCalculation.totalLoanAmount ?? 0,
          benchmarkRate,
        ) || 0;

      benchmarkMortgageMonthlyPaymentTotal += benchmarkMortgageMonthlyPayment;

      const { interest, principal } = FundmoreCalculator.computePrincipalAndInterest({
        ...requestedMortgageForCalculation,
        paymentFrequency: PaymentFrequency.MONTHLY,
        paymentAmount: monthlyPayment || null,
      });
      principalTotal += principal;
      interestTotal += interest;

      const { interest: qualifyingInterest, principal: qualifyingPrincipal } =
        FundmoreCalculator.computePrincipalAndInterest({
          ...requestedMortgageForCalculation,
          paymentFrequency: PaymentFrequency.MONTHLY,
          paymentAmount: qualifyingMortgageMonthlyPayment || null,
          netRate: qualifyingRate,
        });
      qualifyingPrincipalTotal += qualifyingPrincipal;
      qualifyingInterestTotal += qualifyingInterest;

      const { interest: benchmarkInterest, principal: benchmarkPrincipal } =
        FundmoreCalculator.computePrincipalAndInterest({
          ...requestedMortgageForCalculation,
          paymentFrequency: PaymentFrequency.MONTHLY,
          paymentAmount: benchmarkMortgageMonthlyPayment || null,
          netRate: benchmarkRate,
        });
      benchmarkPrincipalTotal += benchmarkPrincipal;
      benchmarkInterestTotal += benchmarkInterest;
    });

    const allReqMortgagesAreLOC = requestedMortgages.every(
      (rm) =>
        rm.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
        rm.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX,
    );

    const gds = FundmoreCalculator.computeGDS(
      monthlyPaymentTotal,
      primaryProperty,
      otherProperties,
      existingMortgages,
      existingMortgagePaymentsOnPrimaryProperty,
      totalIncomeWithoutPropertyRental,
      principalTotal,
      interestTotal,
      calculationAutomationSettings,
      undefined,
      allReqMortgagesAreLOC,
    );

    const tds = FundmoreCalculator.computeTDS(
      monthlyPaymentTotal,
      primaryProperty,
      otherProperties,
      existingMortgages,
      existingMortgagePaymentsOnPrimaryProperty,
      totalIncomeWithoutPropertyRental,
      debt,
      principalTotal,
      interestTotal,
      calculationAutomationSettings,
      undefined,
      allReqMortgagesAreLOC,
    );

    const qgds = FundmoreCalculator.computeGDS(
      qualifyingMortgageMonthlyPaymentTotal,
      primaryProperty,
      otherProperties,
      existingMortgages,
      existingMortgagePaymentsOnPrimaryProperty,
      totalIncomeWithoutPropertyRental,
      qualifyingPrincipalTotal,
      qualifyingInterestTotal,
      null,
      ComputedRatioRateType.QUALIFYING,
      allReqMortgagesAreLOC,
    );

    const qtds = FundmoreCalculator.computeTDS(
      qualifyingMortgageMonthlyPaymentTotal,
      primaryProperty,
      otherProperties,
      existingMortgages,
      existingMortgagePaymentsOnPrimaryProperty,
      totalIncomeWithoutPropertyRental,
      debt,
      qualifyingPrincipalTotal,
      qualifyingInterestTotal,
      null,
      ComputedRatioRateType.QUALIFYING,
      allReqMortgagesAreLOC,
    );

    const bgds = FundmoreCalculator.computeGDS(
      benchmarkMortgageMonthlyPaymentTotal,
      primaryProperty,
      otherProperties,
      existingMortgages,
      existingMortgagePaymentsOnPrimaryProperty,
      totalIncomeWithoutPropertyRental,
      benchmarkPrincipalTotal,
      benchmarkInterestTotal,
      null,
      ComputedRatioRateType.BENCHMARK,
      allReqMortgagesAreLOC,
    );

    const btds = FundmoreCalculator.computeTDS(
      benchmarkMortgageMonthlyPaymentTotal,
      primaryProperty,
      otherProperties,
      existingMortgages,
      existingMortgagePaymentsOnPrimaryProperty,
      totalIncomeWithoutPropertyRental,
      debt,
      benchmarkPrincipalTotal,
      benchmarkInterestTotal,
      null,
      ComputedRatioRateType.BENCHMARK,
      allReqMortgagesAreLOC,
    );

    return {
      gds,
      qgds,
      tds,
      qtds,
      bgds,
      btds,
      qualifyingRate: qualifyingRateTotal,
      bocRate: benchmarkRate,
    };
  }

  static cltvByRequestedMortgage(requestedMortgageId: string) {
    return createSelector(
      [
        PropertiesState.propertiesListWithOwners,
        MortgagesV2State.requestedMortgage(requestedMortgageId),
        MortgagesV2State.existingMortgages,
        MortgagesV2State.capFeesMaxPercentage,
        MortgagesV2State.calculationAutomationSettings,
        FeesState.feesList,
      ],
      (
        properties: Property[],
        requestedMortgage: Mortgage,
        existingMortgages: Mortgage[],
        capFeesMaxPercentage: number,
        calculationAutomationSettings: CalculationAutomationSettings,
        fees: Fee[] | undefined,
      ) => {
        const cltv = FundmoreCalculator.computeCltv(
          [requestedMortgage],
          existingMortgages,
          properties ?? [],
          fees || [],
          capFeesMaxPercentage ?? 0.0,
          calculationAutomationSettings,
        );
        return cltv;
      },
    );
  }

  @Selector([
    PropertiesState.propertiesListWithOwners,
    MortgagesV2State.activeRequestedMortgages,
    MortgagesV2State.existingMortgages,
    MortgagesV2State.capFeesMaxPercentage,
    MortgagesV2State.calculationAutomationSettings,
    FeesState.feesList,
  ])
  static cltvAllRequestedMortgages(
    properties: Property[],
    requestedMortgages: Mortgage[],
    existingMortgages: Mortgage[],
    capFeesMaxPercentage: number,
    calculationAutomationSettings: CalculationAutomationSettings,
    fees: Fee[] | undefined,
  ) {
    const cltv = FundmoreCalculator.computeCltv(
      requestedMortgages,
      existingMortgages,
      properties ?? [],
      fees || [],
      capFeesMaxPercentage ?? 0.0,
      calculationAutomationSettings,
    );
    return cltv;
  }

  static qualifyingRateByRequestedMortgage(requestedMortgageId: string) {
    return createSelector(
      [MortgagesV2State.requestedMortgage(requestedMortgageId), StandardRatesState.standardRates],
      (requestedMortgage: Mortgage | undefined, boc: StandardRatesModel) => {
        const qualifyingRate = FundmoreCalculator.computeQualifyingRate(
          requestedMortgage?.netRate,
          boc?.bocConventionalMortgage5Year,
        );

        return qualifyingRate;
      },
    );
  }

  @Selector([MortgagesV2State.activeRequestedMortgages, StandardRatesState.standardRates])
  static qualifyingRateAllRequestedMortgages(
    requestedMortgages: Mortgage[],
    boc: StandardRatesModel,
  ) {
    const netRateTotal = requestedMortgages?.reduce((sum, x) => sum + (x.netRate ?? 0), 0);

    const qualifyingRate = FundmoreCalculator.computeQualifyingRate(
      netRateTotal,
      boc?.bocConventionalMortgage5Year,
    );

    return qualifyingRate;
  }

  @Selector([
    MortgagesV2State.requestedMortgages,
    MortgagesV2State.existingMortgages,
    PropertiesState.propertiesList,
    FeesState.feesList,
    MortgagesV2State.capFeesMaxPercentage,
    AppFeaturesState.isBlanketEnabled,
    MortgagesV2State.calculationAutomationSettings,
  ])
  static ltvBySecurity(
    requestedMortgages: Mortgage[],
    existingMortgages: Mortgage[],
    properties: Property[] | undefined,
    fees: Fee[] | undefined,
    capFeesMaxPercentage: number,
    isBlanketEnabled: boolean,
    calculationAutomationSettings: CalculationAutomationSettings,
  ) {
    if (isBlanketEnabled) {
      return {};
    }

    const linkedSecurities = requestedMortgages
      .filter((m) => m.loanType !== LoanType.BRIDGE)
      .reduce((acc, mortgage) => {
        if (mortgage.propertyId) {
          acc[mortgage.propertyId] = acc[mortgage.propertyId] || [];
          acc[mortgage.propertyId].push(mortgage);
        }
        return acc;
      }, {} as Record<string, Mortgage[]>);

    const result: { [key: string]: CalculationResult } = {};

    Object.entries(linkedSecurities).forEach(([propertyId, mortgages]) => {
      const linkedProperty = properties?.find((x) => x.id === propertyId);

      const linkedPropertyValue = FundmoreCalculator.computePropertyValue({
        property: linkedProperty,
        calculationSettings: calculationAutomationSettings,
      });

      const linkedPropertyExistingMortgages = existingMortgages.filter(
        (m) => propertyId === m.propertyId,
      );

      const linkedPropertyExistingMortgageAmount = FundmoreCalculator.computeExistingMortgageAmount(
        linkedPropertyExistingMortgages,
      );

      const loanAmountForLTV = FundmoreCalculator.computeLoanAmountForLTV(
        mortgages,
        fees || [],
        capFeesMaxPercentage,
      );

      result[propertyId] = FundmoreCalculator.computeLTV(
        linkedPropertyValue,
        linkedPropertyExistingMortgageAmount,
        loanAmountForLTV,
        calculationAutomationSettings,
      );
    });

    return result;
  }

  @Selector([
    MortgagesV2State.activeRequestedMortgagesForCalculations,
    MortgagesV2State.existingMortgages,
    PropertiesState.propertiesList,
    IncomeState.totalMonthlyIncomeWithoutPropertyRental,
    FeesState.feesList,
    MortgagesV2State.capFeesMaxPercentage,
    AppFeaturesState.isBlanketEnabled,
  ])
  static ltiBySecurity(
    requestedMortgages: Mortgage[],
    existingMortgages: Mortgage[],
    properties: Property[] | undefined,
    totalIncomeWithoutPropertyRental: number,
    fees: Fee[] | undefined,
    capFeesMaxPercentage: number | undefined,
    isBlanket: boolean,
  ) {
    if (!properties) {
      return {};
    }
    return new FundmoreCalculator.LTICalculator().computeLTIBySecurity({
      requestedMortgages,
      existingMortgages,
      properties,
      totalIncomeWithoutPropertyRental,
      fees: fees || [],
      capFeesMaxPercentage,
      isBlanket,
    });
  }

  @Selector([PropertiesState.primaryProperty, SummaryState.ltiBySecurity])
  static ltiForPrimaryProperty(
    primaryProperty?: Property,
    ltiBySecurity?: { [key: string]: CalculationResult },
  ) {
    if (!ltiBySecurity || !primaryProperty) {
      return this.EMPTY_CALCULATION_RESULT;
    }

    return ltiBySecurity[primaryProperty.id];
  }

  @Action(ComputeSummaryAllRequestedMortgages, { cancelUncompleted: true })
  computeSummaryAllRequestedMortgages(ctx: StateContext<Summary>) {
    return combineLatest([
      this.store.select(SummaryState.ltvAllRequestedMortgages),
      this.store.select(SummaryState.gdsAndTdsAllRequestedMortgages),
      this.store.select(SummaryState.cltvAllRequestedMortgages),
      this.store.select(SummaryState.ltvBySecurity),
      this.store.select(SummaryState.ltiBySecurity),
      this.store.select(SummaryState.ltiForPrimaryProperty),
    ]).pipe(
      debounceTime(100),
      map(
        ([
          ltv,
          { gds, qgds, tds, qtds, bgds, btds, qualifyingRate, bocRate },
          cLtv,
          ltvBySecurity,
          ltiBySecurity,
          lti,
        ]) => {
          ctx.patchState({
            lti,
            ltv,
            gds,
            tds,
            qgds,
            qtds,
            bgds,
            btds,
            cLtv,
            qualifyingRate,
            bocRate,
            ltvBySecurity,
            ltiBySecurity,
          });
        },
      ),
      takeUntil(this.actions$.pipe(ofActionDispatched(ApplicationResetState))),
    );
  }

  @Action(ApplicationResetState)
  resetState(ctx: StateContext<Summary>) {
    ctx.patchState({});
  }
}

const getMonthlyPaymentForRequestedMortgage = (
  requestedMortgage: Mortgage | undefined,
  totalLoanAmount: number,
  liftForVariableRate: number | undefined,
) => {
  if (!requestedMortgage || !liftForVariableRate) {
    return;
  }
  if (!requestedMortgage.useLiftPayment) {
    return requestedMortgage.monthlyPayment;
  }
  const requestedMortgageForCalculation: Mortgage = {
    ...requestedMortgage,
    netRate:
      requestedMortgage.netRate !== null ? requestedMortgage.netRate + liftForVariableRate : null,
  };
  return FundmoreCalculator.computeMortgageMonthlyPayment(
    requestedMortgageForCalculation,
    totalLoanAmount,
    requestedMortgageForCalculation.netRate,
  );
};
