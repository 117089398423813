import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Application } from '../../../../shared';
import { ApplicantJobsService } from 'src/app/portal/applicant-jobs.service';
import {
  ApplicationOtherIncome,
  EmployedPaymentType,
  IncomePeriod,
  Job,
  Property,
  PropertyType,
  SelfEmployedPaymentType,
  IncomeType,
  AddressExpanded,
} from '@fundmoreai/models';
import {
  EmployedAndSelfEmployedIncome,
  EmployedIncome,
  Income,
  OtherIncome,
  SelfEmployedIncome,
} from './income.model';
import { finalize, map, of, switchMap, tap } from 'rxjs';
import { OtherIncomesService } from 'src/app/portal/other-incomes.service';
import { FundmoreCalculator } from '@fundmoreai/calculator';
import { ReapplyMortgageAdvancedProducts } from 'src/app/portal/advanced-products/advanced-product.state';
import { MortgagesV2State } from 'src/app/portal/mortgages-v2/mortgages-v2.state';
import {
  DeleteApplicantJob,
  SwitchApplicantJob,
  UpdateApplicantJob,
} from '../../../../portal/applicants.actions';
import { ApplicationResetState } from '../../../../shared/state.model';
import { LoadingStart, LoadingEnd } from '../../../../core/loading.state';
import { patch } from '@ngxs/store/operators';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AddEmployedIncome,
  AddOtherIncome,
  AddSelfEmployedIncome,
  DeleteEmployedOrSelfEmployedIncome,
  DeleteOtherIncome,
  SetIncomeList,
  UpdateEmployedIncome,
  UpdateIncomeFrequency,
  UpdateIncomePropertiesList,
  UpdateSelfEmployedIncome,
  UpdateOtherIncome,
  ChangeIncomeTypeToOtherIncome,
  ChangeIncomeTypeToEmploymentOrSelf,
  AddOrUpdateEmployerAddressesExpanded,
} from './income.state.actions';

export interface IncomeStateModel {
  incomeFrequency: IncomePeriod;
  incomeList: {
    [key: string]: Income;
  };
  isLoaded: boolean;
}

export function isPropertyRentalIncome(income: Partial<Income>): OtherIncome | undefined {
  const otherIncome = income as OtherIncome;

  return otherIncome.type === IncomeType.RENTAL && otherIncome.disabled ? otherIncome : undefined;
}

const defaults = {
  incomeFrequency: IncomePeriod.MONTHLY,
  incomeList: {},
  isLoaded: false,
};
@State<IncomeStateModel>({
  name: 'income',
  defaults: { ...defaults },
})
@Injectable()
export class IncomeState {
  static NAME = 'income';

  static income(incomeId: string) {
    return createSelector([IncomeState], (state: IncomeStateModel): Income | undefined => {
      return state.incomeList[incomeId];
    });
  }

  @Selector()
  static incomesList(state: IncomeStateModel) {
    return Object.values(state.incomeList).sort(
      (a, b) =>
        (b.startDate ? new Date(b.startDate).getTime() : 0) -
        (a.startDate ? new Date(a.startDate).getTime() : 0),
    );
  }

  @Selector()
  static isLoaded(state: IncomeStateModel) {
    return state.isLoaded;
  }

  @Selector()
  static totalIncome(state: IncomeStateModel) {
    const incomeList = Object.values(state.incomeList);
    const incomes: FundmoreCalculator.ComputeTotalIncomeInput[] = incomeList
      .filter((x) => x.isCurrent && !x.unableToVerify)
      .map((x) => ({
        amount: x.amount,
        period: x.incomePaymentFrequency,
        toggled: false,
      }));

    return FundmoreCalculator.computeTotalIncome(incomes, state.incomeFrequency);
  }

  @Selector()
  static totalMonthlyIncomeWithoutPropertyRental(state: IncomeStateModel) {
    const incomeList = Object.values(state.incomeList);
    const incomes: FundmoreCalculator.ComputeTotalIncomeInput[] = incomeList
      .filter((x) => x.isCurrent && !x.unableToVerify && !isPropertyRentalIncome(x))
      .map((x) => ({
        amount: x.amount,
        period: x.incomePaymentFrequency,
        toggled: false,
      }));

    return FundmoreCalculator.computeTotalIncome(incomes, IncomePeriod.MONTHLY);
  }

  @Selector([IncomeState.incomesList])
  static incomesValuesForProductReapplyTriggers(incomes: Income[]) {
    return incomes?.map((income) => ({
      amount: income.amount,
      incomePaymentFrequency: income.incomePaymentFrequency,
      isCurrent: income.isCurrent,
      unableToVerify: income.unableToVerify,
    }));
  }

  constructor(
    private applicantJobsService: ApplicantJobsService,
    private otherIncomesService: OtherIncomesService,
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  @Action(ApplicationResetState)
  resetState(ctx: StateContext<IncomeStateModel>) {
    ctx.setState({ ...defaults });
  }

  @Action(AddEmployedIncome)
  addEmployedIncome(ctx: StateContext<IncomeStateModel>, action: AddEmployedIncome) {
    const { job } = action;

    if (!job || !job.applicantId || !action.applicationId) {
      return;
    }

    const applicantId = job.applicantId;

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicantJobsService
      .postJob(action.applicationId, applicantId, [{ ...job, id: undefined }])
      .pipe(
        map((jobs: Job[]) => jobs[0]),
        tap((job) => {
          const state = ctx.getState();

          ctx.patchState({
            incomeList: {
              ...state.incomeList,
              [job.id]: this.mapJobToIncome(applicantId, job),
            },
          });
          ctx.dispatch(new UpdateApplicantJob(applicantId, job));
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(AddOtherIncome)
  addOtherIncome(ctx: StateContext<IncomeStateModel>, action: AddOtherIncome) {
    const { otherIncome } = action;
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.otherIncomesService.postOtherIncomes(action.applicationId, [otherIncome]).pipe(
      map((otherIncomes: ApplicationOtherIncome[]) => otherIncomes[0]),
      tap((otherIncome: ApplicationOtherIncome) => {
        const state = ctx.getState();

        ctx.patchState({
          incomeList: {
            ...state.incomeList,
            [otherIncome.id]: this.mapOtherIncomeToIncome(otherIncome),
          },
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(AddSelfEmployedIncome)
  addSelfEmployedIncome(ctx: StateContext<IncomeStateModel>, action: AddSelfEmployedIncome) {
    const { job } = action;

    if (!job || !job.applicantId || !action.applicationId) {
      return;
    }

    const applicantId = job.applicantId;

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicantJobsService.postJob(action.applicationId, applicantId, [job]).pipe(
      map((jobs: Job[]) => jobs[0]),
      tap((job: Job) => {
        const state = ctx.getState();

        ctx.patchState({
          incomeList: {
            ...state.incomeList,
            [job.id]: this.mapJobToIncome(applicantId, job),
          },
        });
        ctx.dispatch(new UpdateApplicantJob(applicantId, job));
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(DeleteEmployedOrSelfEmployedIncome)
  deleteEmployedOrSelfEmployedIncome(
    ctx: StateContext<IncomeStateModel>,
    action: DeleteEmployedOrSelfEmployedIncome,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicantJobsService
      .deleteJob(action.applicationId, action.income.stakeholderId, action.income.id)
      .pipe(
        tap(() => {
          const state = ctx.getState();
          const incomeList = { ...state.incomeList };

          delete incomeList[action.income.id];

          ctx.patchState({
            incomeList,
          });
          ctx.dispatch(new DeleteApplicantJob(action.income.stakeholderId, action.income.id));
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(DeleteOtherIncome)
  deleteOtherIncome(ctx: StateContext<IncomeStateModel>, action: DeleteOtherIncome) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.otherIncomesService.deleteOtherIncome(action.applicationId, action.income.id).pipe(
      tap(() => {
        const state = ctx.getState();
        const incomeList = { ...state.incomeList };

        delete incomeList[action.income.id];

        ctx.patchState({
          incomeList,
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
        // New Income Widget
        ctx.dispatch(new ReapplyMortgageAdvancedProducts(action.applicationId, true, true));
      }),
    );
  }

  @Action(SetIncomeList)
  setIncomeList(ctx: StateContext<IncomeStateModel>, action: SetIncomeList) {
    const incomes: Income[] = [];
    const { applicants, OtherIncomes, Properties } = action.application;

    applicants.forEach((applicant) => {
      const { Jobs } = applicant;

      if (!Jobs) {
        return;
      }

      Jobs.forEach((job) => {
        incomes.push(this.mapJobToIncome(applicant.id, job));
      });
    });

    OtherIncomes.forEach((otherIncome) => {
      incomes.push(this.mapOtherIncomeToIncome(otherIncome));
    });

    Properties.forEach((property: Property) => {
      const income = this.mapPropertyToIncome(property);

      if (income) {
        incomes.push(income);
      }
    });

    const incomeEntities = incomes.reduce((entities: { [key: string]: Income }, income) => {
      entities[income.id] = income;

      return entities;
    }, {});

    ctx.patchState({ incomeList: incomeEntities, isLoaded: true });
  }

  @Action(UpdateEmployedIncome)
  updateEmployedIncome(ctx: StateContext<IncomeStateModel>, action: UpdateEmployedIncome) {
    let state = ctx.getState();

    const previousIncome = state.incomeList[action.income.id] as EmployedIncome;

    const { income } = action;
    const job: Job = {
      annualIncome: FundmoreCalculator.getYearlyIncomeAmount(
        income.amount,
        income.incomePaymentFrequency,
      ),
      applicantId: income.stakeholderId,
      bonusOvertimeCommissions: income.bonusOvertimeCommissions,
      description: income.description,
      employerName: income.employerName,
      employerEmailAddress: income.employerEmailAddress,
      employmentType: income.employmentType,
      endDate: income.endDate,
      id: income.id,
      incomePaymentFrequency: income.incomePaymentFrequency,
      industrySector: income.industrySector,
      jobTitle: income.jobTitle,
      paymentType: income.employedPaymentType,
      occupation: income.occupation,
      startDate: income.startDate,
      timeAtIndustryMonths: income.timeAtIndustryMonths,
      timeAtJobMonths: income.timeAtJobMonths,
      isCurrent: income.isCurrent ?? false,
      unableToVerify: income.unableToVerify ?? false,
      probation: income.probation ?? false,
      type: income.type,
      phoneNumber: income.phoneNumber,
      EmployerAddressExpanded: income.EmployerAddressExpanded,
    };

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicantJobsService
      .patchJob(action.applicationId, income.stakeholderId, income.id, {
        ...job,
        EmployerAddressExpanded: undefined,
      })
      .pipe(
        tap(() => {
          state = ctx.getState();

          ctx.patchState({
            incomeList: {
              ...state.incomeList,
              [income.id]: this.mapJobToIncome(income.stakeholderId, job),
            },
          });

          if (previousIncome.stakeholderId !== action.income.stakeholderId) {
            ctx.dispatch(
              new SwitchApplicantJob(income.stakeholderId, previousIncome.stakeholderId, job),
            );
          } else {
            ctx.dispatch(new UpdateApplicantJob(income.stakeholderId, job));
          }
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(UpdateIncomeFrequency)
  updateIncomeFrequency(ctx: StateContext<IncomeStateModel>, action: UpdateIncomeFrequency) {
    ctx.patchState({
      incomeFrequency: action.incomeFrequency,
    });
  }

  @Action(UpdateIncomePropertiesList)
  updateIncomePropertiesList(
    ctx: StateContext<IncomeStateModel>,
    action: UpdateIncomePropertiesList,
  ) {
    const state = ctx.getState();

    const incomeList = { ...state.incomeList };

    const propertyRentalIncomes = Object.values(incomeList).filter(
      (x) => !!isPropertyRentalIncome(x),
    );

    // remove property rental incomes
    propertyRentalIncomes.forEach((income) => delete incomeList[income.id]);

    const newPropertyRentalIncomesEntities = action.properties
      .map((property: Property) => this.mapPropertyToIncome(property))
      .reduce((entities: { [key: string]: OtherIncome }, income) => {
        if (!income) {
          return entities;
        }

        entities[income.id] = income;

        return entities;
      }, {});

    ctx.patchState({
      incomeList: { ...incomeList, ...newPropertyRentalIncomesEntities },
    });
  }

  @Action(UpdateSelfEmployedIncome)
  updateSelfEmployedIncome(ctx: StateContext<IncomeStateModel>, action: UpdateSelfEmployedIncome) {
    let state = ctx.getState();

    const previousIncome = state.incomeList[action.income.id] as SelfEmployedIncome;

    const { income } = action;
    const job: Job = {
      annualIncome: FundmoreCalculator.getYearlyIncomeAmount(
        income.amount,
        income.incomePaymentFrequency,
      ),
      applicantId: income.stakeholderId,
      businessType: income.businessType,
      description: income.description,
      employerName: income.employerName,
      employerEmailAddress: income.employerEmailAddress,
      employmentType: income.employmentType,
      endDate: income.endDate,
      id: income.id,
      incomePaymentFrequency: income.incomePaymentFrequency,
      industrySector: income.industrySector,
      jobTitle: income.jobTitle,
      occupation: income.occupation,
      paymentType: income.selfEmployedPaymentType,
      startDate: income.startDate,
      timeAtIndustryMonths: income.timeAtIndustryMonths,
      timeAtJobMonths: income.timeAtJobMonths,
      isCurrent: income.isCurrent ?? false,
      unableToVerify: income.unableToVerify ?? false,
      probation: income.probation ?? false,
      type: income.type,
      phoneNumber: income.phoneNumber,
      EmployerAddressExpanded: income.EmployerAddressExpanded,
    };

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicantJobsService
      .patchJob(action.applicationId, income.stakeholderId, income.id, {
        ...job,
        EmployerAddressExpanded: undefined,
      })
      .pipe(
        tap(() => {
          state = ctx.getState();

          ctx.patchState({
            incomeList: {
              ...state.incomeList,
              [income.id]: this.mapJobToIncome(income.stakeholderId, job),
            },
          });

          if (previousIncome.stakeholderId !== action.income.stakeholderId) {
            ctx.dispatch(
              new SwitchApplicantJob(income.stakeholderId, previousIncome.stakeholderId, job),
            );
          } else {
            ctx.dispatch(new UpdateApplicantJob(income.stakeholderId, job));
          }
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(UpdateOtherIncome)
  updateOtherIncome(ctx: StateContext<IncomeStateModel>, action: UpdateOtherIncome) {
    const { income } = action;
    const otherIncome: ApplicationOtherIncome = {
      amount: income.amount,
      applicationId: action.applicationId,
      description: income.description,
      id: income.id,
      isCurrent: income.isCurrent,
      unableToVerify: income.unableToVerify,
      period: income.incomePaymentFrequency,
      type: income.type,
      occupation: income.occupation,
      industrySector: income.industrySector,
      timeAtIndustryMonths: income.timeAtIndustryMonths,
      timeAtJobMonths: income.timeAtJobMonths,
      ApplicantOtherIncomes:
        income.stakeholderIds?.map((stakeholderId) => ({
          applicantId: stakeholderId,
          otherIncomeId: income.id,
        })) || [],
      startDate: income.startDate,
      endDate: income.endDate,
    };

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.otherIncomesService
      .patchOtherIncome(action.applicationId, income.id, otherIncome)
      .pipe(
        tap(() => {
          const state = ctx.getState();

          ctx.patchState({
            incomeList: {
              ...state.incomeList,
              [income.id]: this.mapOtherIncomeToIncome(otherIncome),
            },
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(ChangeIncomeTypeToOtherIncome)
  changeIncomeTypeToOtherIncome(
    ctx: StateContext<IncomeStateModel>,
    action: ChangeIncomeTypeToOtherIncome,
  ) {
    const { income } = action;
    const otherIncome: Partial<ApplicationOtherIncome> = {
      amount: income.amount,
      applicationId: action.applicationId,
      description: income.description,
      isCurrent: income.isCurrent ?? false,
      unableToVerify: income.unableToVerify ?? false,
      period: income.incomePaymentFrequency,
      type: income.type,
      occupation: income.occupation,
      ApplicantOtherIncomes: action.applicationId ? [{ applicantId: income.stakeholderId }] : [],
      startDate: income.startDate ?? null,
      endDate: income.endDate ?? null,
    };

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.otherIncomesService.postOtherIncomes(action.applicationId, [otherIncome]).pipe(
      tap((otherIncomes: ApplicationOtherIncome[]) => {
        const state = ctx.getState();

        ctx.patchState({
          incomeList: {
            ...state.incomeList,
            [otherIncomes[0].id]: this.mapOtherIncomeToIncome(otherIncomes[0]),
          },
        });
      }),
      switchMap((otherIncomes: ApplicationOtherIncome[]) => {
        return this.applicantJobsService
          .deleteJob(action.applicationId, action.income.stakeholderId, action.income.id)
          .pipe(
            switchMap(() => {
              const state = ctx.getState();
              const incomeList = { ...state.incomeList };

              delete incomeList[action.income.id];

              ctx.patchState({
                incomeList,
              });

              return ctx.dispatch(
                new DeleteApplicantJob(action.income.stakeholderId, action.income.id),
              );
            }),
            map(() => {
              return otherIncomes;
            }),
          );
      }),
      tap((otherIncomes) => {
        const otherIncome = otherIncomes?.[0];
        if (otherIncome && action.openSidebar) {
          this.router.navigate([], {
            queryParams: { sidenav: 'income', incomeId: otherIncome.id },
            relativeTo: this.activatedRoute,
          });
        }
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
        ctx.dispatch(new ReapplyMortgageAdvancedProducts(action.applicationId, true, true));
      }),
    );
  }

  @Action(ChangeIncomeTypeToEmploymentOrSelf)
  changeIncomeTypeToEmploymentOrSelf(
    ctx: StateContext<IncomeStateModel>,
    action: ChangeIncomeTypeToEmploymentOrSelf,
  ) {
    const state = ctx.getState();
    const prevIncomeType = state.incomeList[action.income.id].type;
    const prevIncomeTypeIsOther = ![
      IncomeType.EMPLOYED,
      IncomeType.OTHER,
      IncomeType.SELF_EMPLOYED,
    ].includes(prevIncomeType);

    if (prevIncomeTypeIsOther) {
      // switch to employment income types from other
      const income = action.income as OtherIncome;
      const stakeholderId = income.stakeholderIds?.[0] ?? undefined;

      if (!stakeholderId) {
        return of();
      }

      const job: Partial<Job> = {
        annualIncome: FundmoreCalculator.getYearlyIncomeAmount(
          income.amount,
          income.incomePaymentFrequency,
        ),
        applicantId: stakeholderId,
        description: income.description,
        endDate: income.endDate,
        incomePaymentFrequency: income.incomePaymentFrequency,
        occupation: income.occupation,
        startDate: income.startDate,
        isCurrent: income.isCurrent ?? false,
        unableToVerify: income.unableToVerify ?? false,
        type: income.type,
      };

      ctx.dispatch(new LoadingStart(this.constructor.name));

      return this.applicantJobsService.postJob(action.applicationId, stakeholderId, [job]).pipe(
        map((jobs: Job[]) => jobs[0]),
        tap((job: Job) => {
          const state = ctx.getState();

          ctx.patchState({
            incomeList: {
              ...state.incomeList,
              [job.id]: this.mapJobToIncome(stakeholderId, job),
            },
          });
          ctx.dispatch(new UpdateApplicantJob(stakeholderId, job));
        }),
        switchMap((job: Job) => {
          return this.otherIncomesService
            .deleteOtherIncome(action.applicationId, action.income.id)
            .pipe(
              tap(() => {
                const state = ctx.getState();
                const incomeList = { ...state.incomeList };

                delete incomeList[action.income.id];

                ctx.patchState({
                  incomeList,
                });
              }),
              map(() => job),
            );
        }),
        tap((job) => {
          if (job && action.openSidebar) {
            this.router.navigate([], {
              queryParams: { sidenav: 'income', incomeId: job.id },
              relativeTo: this.activatedRoute,
            });
          }
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
    } else {
      // switch between self employed and employed/other income types
      const income = action.income as EmployedAndSelfEmployedIncome;
      const switchBetweenEmployedAndEmployedOther =
        (prevIncomeType === IncomeType.EMPLOYED && income.type === IncomeType.OTHER) ||
        (prevIncomeType === IncomeType.OTHER && income.type === IncomeType.EMPLOYED);

      ctx.dispatch(new LoadingStart(this.constructor.name));

      const job: Job = {
        annualIncome: FundmoreCalculator.getYearlyIncomeAmount(
          income.amount,
          income.incomePaymentFrequency,
        ),
        applicantId: income.stakeholderId,
        applicationId: action.applicationId,
        bonusOvertimeCommissions: switchBetweenEmployedAndEmployedOther
          ? (income as EmployedIncome).bonusOvertimeCommissions
          : undefined,
        description: income.description,
        employerName: income.employerName,
        employerEmailAddress: income.employerEmailAddress,
        employmentType: income.employmentType,
        endDate: income.endDate,
        id: income.id,
        incomePaymentFrequency: income.incomePaymentFrequency,
        jobTitle: income.jobTitle,
        occupation: income.occupation,
        paymentType: switchBetweenEmployedAndEmployedOther
          ? (income as EmployedIncome).employedPaymentType
          : undefined,
        startDate: income.startDate,
        timeAtIndustryMonths: income.timeAtIndustryMonths,
        timeAtJobMonths: income.timeAtJobMonths,
        type: income.type,
        industrySector: income.industrySector,
        isCurrent: income.isCurrent ?? false,
        unableToVerify: income.unableToVerify ?? false,
        probation: income.probation ?? false,
        phoneNumber: income.phoneNumber,
      };

      return this.applicantJobsService
        .patchJob(action.applicationId, income.stakeholderId, job.id, job)
        .pipe(
          tap(() => {
            const state = ctx.getState();

            ctx.patchState({
              incomeList: {
                ...state.incomeList,
                [job.id]: this.mapJobToIncome(income.stakeholderId, job),
              },
            });
            ctx.dispatch(new UpdateApplicantJob(income.stakeholderId, job));
          }),
          finalize(() => {
            ctx.dispatch(new LoadingEnd(this.constructor.name));
          }),
        );
    }
  }

  private mapJobToIncome(applicantId: string, job: Job): EmployedIncome | SelfEmployedIncome {
    if (job.type === IncomeType.SELF_EMPLOYED) {
      const income: SelfEmployedIncome = {
        amount: FundmoreCalculator.getIncomeAmountFromAnnualIncome(
          job.annualIncome,
          job.incomePaymentFrequency,
        ),
        businessType: job.businessType,
        endDate: job.endDate,
        id: job.id,
        isCurrent: job.isCurrent,
        unableToVerify: job.unableToVerify,
        probation: job.probation ?? false,
        stakeholderId: applicantId,
        type: IncomeType.SELF_EMPLOYED,
        description: job.description,
        EmployerAddressExpanded: job.EmployerAddressExpanded,
        employerEmailAddress: job.employerEmailAddress,
        employerName: job.employerName, // business name
        employmentType: job.employmentType, // job type
        incomePaymentFrequency: job.incomePaymentFrequency,
        industrySector: job.industrySector,
        jobTitle: job.jobTitle,
        selfEmployedPaymentType: job.paymentType as SelfEmployedPaymentType | undefined,
        startDate: job.startDate,
        timeAtIndustryMonths: job.timeAtIndustryMonths,
        phoneNumber: job.phoneNumber,
        timeAtJobMonths: job.timeAtJobMonths,
        occupation: job.occupation,
      };

      return income;
    } else {
      const income: EmployedIncome = {
        amount: FundmoreCalculator.getIncomeAmountFromAnnualIncome(
          job.annualIncome,
          job.incomePaymentFrequency,
        ),
        bonusOvertimeCommissions: job.bonusOvertimeCommissions,
        endDate: job.endDate,
        id: job.id,
        isCurrent: job.isCurrent,
        unableToVerify: job.unableToVerify,
        probation: job.probation ?? false,
        type: job.type ?? IncomeType.EMPLOYED,
        description: job.description,
        EmployerAddressExpanded: job.EmployerAddressExpanded,
        employerEmailAddress: job.employerEmailAddress,
        employerName: job.employerName, // business name
        employedPaymentType: job.paymentType as EmployedPaymentType | undefined,
        employmentType: job.employmentType, // job type
        incomePaymentFrequency: job.incomePaymentFrequency,
        industrySector: job.industrySector,
        jobTitle: job.jobTitle,
        occupation: job.occupation,
        stakeholderId: applicantId,
        startDate: job.startDate,
        timeAtIndustryMonths: job.timeAtIndustryMonths,
        phoneNumber: job.phoneNumber,
        timeAtJobMonths: job.timeAtJobMonths,
      };

      return income;
    }
  }

  private mapOtherIncomeToIncome(otherIncome: ApplicationOtherIncome): OtherIncome {
    const income: OtherIncome = {
      amount: otherIncome.amount,
      description: otherIncome.description,
      disabled: false,
      endDate: otherIncome.endDate,
      id: otherIncome.id,
      incomePaymentFrequency: otherIncome.period,
      isCurrent: otherIncome.isCurrent,
      unableToVerify: otherIncome.unableToVerify,
      occupation: otherIncome.occupation,
      stakeholderIds: otherIncome.ApplicantOtherIncomes?.map((x) => x.applicantId),
      startDate: otherIncome.startDate,
      type: otherIncome.type as IncomeType,
      timeAtIndustryMonths: otherIncome.timeAtIndustryMonths,
      timeAtJobMonths: otherIncome.timeAtJobMonths,
      industrySector: otherIncome.industrySector,
    };

    return income;
  }

  private mapPropertyToIncome(property: Property): OtherIncome | undefined {
    let propertyRentalIncome: number;

    if (property.type === PropertyType.PRIMARY) {
      const requestedMortgages = this.store.selectSnapshot(MortgagesV2State.requestedMortgages);
      const primaryPropertyMortgagePaymentsTotal = requestedMortgages.reduce(
        (sum, x) => sum + (x.monthlyPayment || 0),
        0,
      );
      const primaryPropertyCostToCarry = FundmoreCalculator.computeCostToCarryForTDS(
        property,
        primaryPropertyMortgagePaymentsTotal,
      );

      propertyRentalIncome = FundmoreCalculator.getPrimaryPropertyRentalMonthlyIncome(
        property,
        property.Mortgages,
        primaryPropertyCostToCarry,
      );
    } else {
      propertyRentalIncome = FundmoreCalculator.getOtherPropertyRentalMonthlyIncome(
        property,
        property.Mortgages,
      );
    }

    if (propertyRentalIncome && !property.markAsSold) {
      const income: OtherIncome = {
        amount: propertyRentalIncome,
        description: '',
        disabled: true,
        endDate: null,
        id: property.id,
        incomePaymentFrequency: IncomePeriod.MONTHLY,
        isCurrent: true,
        unableToVerify: false,
        property,
        stakeholderIds: property.ApplicantProperties?.map((x) => x.applicantId),
        startDate: null,
        type: IncomeType.RENTAL,
      };

      return income;
    }

    return;
  }

  @Action(AddOrUpdateEmployerAddressesExpanded)
  addOrUpdateEmployerAddressesExpanded(
    ctx: StateContext<IncomeStateModel>,
    action: AddOrUpdateEmployerAddressesExpanded,
  ) {
    const job = ctx.getState().incomeList[action.jobId];

    if (!job) {
      return;
    }

    ctx.setState(
      patch<IncomeStateModel>({
        incomeList: patch({
          [action.jobId]: patch<Income>({
            EmployerAddressExpanded: action.addressExpanded,
          }),
        }),
      }),
    );
  }
}
