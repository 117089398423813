import { Mortgage, PayoffType } from '@fundmoreai/models';

export function computePayoutMortgageBalance(existingMortgages: Mortgage[]): number {
  let payoutMortgageBalance = 0;

  for (const existingMortgage of existingMortgages) {
    if (
      existingMortgage?.mortgageBalance != null &&
      existingMortgage?.mortgageBalance >= 0 &&
      (existingMortgage.payoff === PayoffType.PRIOR_TO_ADVANCE ||
        existingMortgage.payoff === PayoffType.FROM_PROCEEDS)
    ) {
      const mortgageBalance = existingMortgage.mortgageBalance;

      payoutMortgageBalance += mortgageBalance;
    }
  }
  return payoutMortgageBalance;
}
