import {
  ApplicationBrokerCommission,
  Fee,
  FundmoreRecommendationModel,
  IncrementCategory,
  Mortgage,
  MortgageDecrement,
  MortgageIncrement,
  MortgageState,
  MortgageStatus,
  PaymentFrequency,
  Province,
  RateType,
  State,
} from '../../shared/model';
import { UpdateApplicationLoanNumberMortgageResponse } from '../mortgage-application.service';

export class ApplyRecommendation {
  static readonly type = '@mortgagesV2State.applyRecommendation';
  constructor(public recommendation: FundmoreRecommendationModel, public applicationId: string) {}
}

export class CreateMortgage {
  static readonly type = '@mortgagesV2State.createMortgage';
  constructor(public mortgage: Partial<Mortgage>, public applicationId: string) {}
}

export class DeleteMortgage {
  static readonly type = '@mortgagesV2State.deleteMortgage';
  constructor(public applicationId: string, public mortgageId: string, public comment?: string) {}
}

export class SetMortgages {
  static readonly type = '@mortgagesV2State.setMortgages';
  constructor(public mortgages: Mortgage[]) {}
}

export class UpdateMortgage {
  static readonly type = '@mortgagesV2State.updateMortgage';
  constructor(
    public applicationId: string,
    public mortgageId: string,
    public mortgage: Partial<Mortgage>,
    public hasValueChanged?: { [key: string]: boolean },
  ) {}
}

export class UpdateRequestedMortgagePaymentIfNeeded {
  static readonly type = '@mortgagesV2State.updateMortgagePaymentIfNeeded';

  constructor(public mortgageId: string) {}
}

export class UpdateExistingLOCMortgagePayments {
  static readonly type = '@mortgagesV2State.UpdateExistingLOCMortgagePayments';
  constructor(public mortgageId: string) {}
}

export class UpdateMortgageTotalLoanAmountIfNeeded {
  static readonly type = '@mortgagesV2State.updateMortgageTotalLoanAmountIfNeeded';

  constructor(
    public requestedMortgages: Mortgage[],
    public fees: Fee[],
    public capFeesMaxPercentage: number | undefined,
  ) {}
}

export class UpdateRequestedMortgage {
  static readonly type = '@mortgagesV2State.updateRequestedMortgage';
  constructor(public applicationId: string, public mortgage: Partial<Mortgage>) {}
}

export class PatchMortgage {
  static readonly type = '@mortgagesV2State.patchMortgage';
  constructor(
    public applicationId: string,
    public mortgageId: string,
    public mortgage: Partial<Mortgage>,
  ) {}
}

export class UpdateMortgageBaseRate {
  static readonly type = '@mortgagesV2State.updateMortgageBaseRate';
  constructor(
    public applicationId: string,
    public mortgageId: string,
    public baseRate: number | null | undefined,
  ) {}
}

export class UpdateMortgageBuyDownRate {
  static readonly type = '@mortgagesV2State.updateMortgageBuyDownRate';
  constructor(
    public applicationId: string,
    public mortgageId: string,
    public buyDownRate: number | null | undefined,
  ) {}
}

export class UpdateMortgageDiscount {
  static readonly type = '@mortgagesV2State.updateMortgageDiscount';
  constructor(
    public applicationId: string,
    public mortgageId: string,
    public discount: number | null | undefined,
  ) {}
}

export class RecomputeLoanAmountOnRequestedMortgage {
  static readonly type = '@mortgagesV2State.recomputeLoanAmountOnRequestedMortgage';
}

export class UpdatePSTOnRequestedMortgageIfNeeded {
  static readonly type = '@mortgagesV2State.updatePSTOnRequestedMortgageIfNeeded';
  constructor(public mortgageId: string, public province: Province | State | null | undefined) {}
}

export class ClosingDateChanged {
  static readonly type = '@mortgagesV2State.closingDateChanged';
  constructor(public mortgageId: string, public closingDate: string | null) {}
}

export class IADDateChanged {
  static readonly type = '@mortgagesV2State.iadDateChanged';
  constructor(public mortgageId: string, public interestAdjustmentDate: string | null) {}
}

export class FirstRegularPaymentDateChanged {
  static readonly type = '@mortgagesV2State.firstRegularPaymentDateChanged';
  constructor(public mortgageId: string, public firstRegularPaymentDate: string | null) {}
}

export class NextPaymentDateChanged {
  static readonly type = '@mortgagesV2State.nextPaymentDateChanged';
  constructor(public mortgageId: string) {}
}

export class PaymentFrequencyChanged {
  static readonly type = '@mortgagesV2State.paymentFrequencyChanged';
  constructor(public mortgageId: string, public paymentFrequency: PaymentFrequency | null) {}
}

export class TermChanged {
  static readonly type = '@mortgagesV2State.termChanged';
  constructor(public mortgageId: string, public termMonths: number | null) {}
}

export class RateTypeChanged {
  static readonly type = '@mortgagesV2State.rateTypeChanged';
  constructor(public mortgageId: string, public rateType: RateType | null) {}
}

export class RateTypeChangedForPaymentFrequency {
  static readonly type = '@mortgagesV2State.rateTypeChangedForPaymentFrequency';
  constructor(public mortgageId: string, public rateType: RateType | null) {}
}

export class UpdateMortgagePrepaymentAmountIfNeeded {
  static readonly type = '@mortgagesV2State.UpdateMortgagePrepaymentAmountIfNeeded';
  constructor(
    public mortgage: Pick<
      Mortgage,
      | 'applicationId'
      | 'id'
      | 'monthlyPayment'
      | 'prepaymentAmount'
      | 'isPrepaymentAmountInPayments'
      | 'prepaymentAmountInPayments'
    >,
  ) {}
}

export class UpdateMortgagePropertyId {
  static readonly type = '@mortgagesV2State.updateMortgagePropertyId';
  constructor(public mortgageId: string, public propertyId: string | null) {}
}

export class UpdateMortgagesLoanNumberLocal {
  static readonly type = '@mortgagesV2State.updateMortgagesLoanNumberLocal';
  constructor(public mortgages: UpdateApplicationLoanNumberMortgageResponse[]) {}
}

export class UpdateMortgageStatus {
  static readonly type = '@mortgagesV2State.updateMortgageStatus';
  constructor(
    public applicationId: string,
    public mortgageId: string,
    public status: MortgageStatus[],
  ) {}
}

export class UpdateMortgageState {
  static readonly type = '@mortgagesV2State.updateMortgageState';
  constructor(
    public applicationId: string,
    public mortgageId: string,
    public state: MortgageState | null,
    public comment: string | null,
  ) {}
}

export class UpdateMortgageIncrement {
  static readonly type = '@mortgagesV2State.updateMortgageIncrement';
  constructor(
    public mortgageId: string,
    public mortgage: {
      increments: MortgageIncrement[];
    },
  ) {}
}

export class UpsertMortgageCustomIncrement {
  static readonly type = '@mortgagesV2State.upsertMortgageCustomIncrement';
  constructor(
    public mortgageId: string,
    public payload: {
      customIncrementAmount: number;
      customIncrementNote: string;
    },
  ) {}
}

export class UpdateMortgageDecrement {
  static readonly type = '@mortgagesV2State.updateMortgageDecrement';
  constructor(
    public mortgageId: string,
    public payload: {
      decrements: MortgageDecrement[] | null;
    },
  ) {}
}

export class PatchMortgageBrokerCommission {
  static readonly type = '@mortgagesV2State.patchMortgageBrokerCommission';
  constructor(public mortgageId: string, public brokerCommission: ApplicationBrokerCommission) {}
}

export class UpdateMortgageDiscretion {
  static readonly type = '@mortgagesV2State.updateMortgageDiscretion';
  constructor(public mortgageId: string, public discretion: number | null) {}
}

export class AddUploadedStatusToRequestedMortgages {
  static readonly type = '@mortgagesV2State.addUploadedStatusToRequestedMortgages';
  constructor() {}
}

export class RefreshProjectedBalance {
  static readonly type = '@mortgagesV2State.refreshProjectedBalance';
  constructor(public applicationId: string) {}
}

export class SetProjectedBalance {
  static readonly type = '@mortgagesV2State.setProjectedBalance';
  constructor(public projectedBalance: number | null) {}
}

export class ClearPropertyLinksAndDropLinkedExistingMortgages {
  static readonly type = '@mortgagesV2State.clearPropertyLinksAndDropLinkedExistingMortgages';
  constructor(public propertyId: string) {}
}

export class CalculateServicingNewMaturityDate {
  static readonly type = '@mortgagesV2State.CalculateServicingNewMaturityDate';
}

export class CalculateOriginationBlendedRateForAllRequestedMortgages {
  static readonly type =
    '@mortgagesV2State.CalculateOriginationBlendedRateForAllRequestedMortgages';
}

export class CalculateOriginationBlendedRateForRequestedMortgage {
  static readonly type = '@mortgagesV2State.CalculateOriginationBlendedRateForMortgage';
  constructor(public mortgageId: string) {}
}

export class CalculateServicingBlendedRateForServicingMortgage {
  static readonly type = '@mortgagesV2State.CalculateServicingBlendedRateForServicingMortgage';
  constructor(public mortgageId: string) {}
}
