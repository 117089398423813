import { ApplicationSource } from './application';
import { AgentContactType, AgentContact } from './enums';

export interface Lawyer {
  id: string;
  tenantId: string;
  firstName: string;
  lastName: string;
  emailAddress?: string | null;
  cellPhoneNumber?: string | null;
  workPhoneNumber?: string | null;
  homePhoneNumber?: string | null;
  faxNumber?: string | null;
  firmCode?: string | null;
  source?: string | null;
  notes?: string | null;
  riskScore?: number | null;
  preferredContactMethod?: AgentContactType | null;
  externalSrc?: string | null;
  externalRef?: string | null;
  approved?: boolean | null;
  verified?: boolean | null;
  verifiedBy?: string | null;
  company: string | null;
  city: string | null;
  province: string | null;
  postalCode: string | null;
  phoneNumber: string | null;
  street: string | null;
}
