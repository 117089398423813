import { Injectable } from '@angular/core';
import { FundmoreCalculator } from '@fundmoreai/calculator';
import { Mortgage, CalculationAutomationSettings, MortgageType } from '@fundmoreai/models';
import { MortgagesServices } from 'src/app/portal/mortgages.services';

@Injectable({ providedIn: 'root' })
export class AmortizationScheduleService {
  constructor(private mortgagesServices: MortgagesServices) {}

  getAmortizationSchedule(
    mortgage: Mortgage | undefined,
    amountToAdvance: number | null,
    calculationAutomationSettings: CalculationAutomationSettings,
    iadByFpdEnabled: boolean,
    takeFullFirstPayment: boolean,
  ) {
    const requestedMortgage =
      mortgage?.type === MortgageType.SERVICING
        ? mortgage
        : this.mortgagesServices.toCommitmentMortgage(
            mortgage,
            amountToAdvance,
            calculationAutomationSettings,
            iadByFpdEnabled,
          );

    return FundmoreCalculator.generateAmortizationSchedule(requestedMortgage, takeFullFirstPayment);
  }
}
