import { InsurancePremiumProgram, Insurer, InsurerType, Mortgage } from '@fundmoreai/models';
import { InsuranceQuote } from '../shared/model';

export class GetInsurancePremiumPlans {
  static readonly type = '@insurances.getInsurancePremiumPlans';
}

export class GetInsurers {
  static readonly type = '@insurances.getInsurers';
}

export class GetMIQuote {
  static readonly type = '@insurances.getMIQuote';
  constructor(public mortgageId: string, public insurerId: string) {}
}

export class ClearInsurance {
  static readonly type = '@insurances.clearInsurance';

  constructor(public id: string) {}
}

export class SetInsurer {
  static readonly type = '@insurances.setInsurer';
  constructor(public id: string, public insurer: InsurerType | null) {}
}

export class SetInsuranceAmount {
  static readonly type = '@insurances.setInsuranceAmount';
  constructor(public id: string, public insuranceAmount: number | null) {}
}

export class SetInsurancePremium {
  static readonly type = '@insurances.setInsurancePremium';
  constructor(public id: string, public insurancePremium: number | null) {}
}

export class SetInsurancePremiumSurchargeApplied {
  static readonly type = '@insurances.setInsurancePremiumSurchargeApplied';
  constructor(public id: string, public surchargeApplied: boolean, public loanAmount: number) {}
}

export class SetInsurancePremiumByLTV {
  static readonly type = '@insurances.setInsurancePremiumByLTV';
  constructor(
    public requestedMortgage: Mortgage | undefined,
    public ltv: number,
    public canApplyInsurancePremiumSurcharge: boolean,
  ) {}
}

export class SetInsurancePremiumAndAmount {
  static readonly type = '@insurances.setInsurancePremiumAndAmount';
  constructor(
    public id: string,
    public insurancePremium: number | null,
    public insuranceAmount: number | null,
    public appliedInsuranceSurcharge: boolean,
    public insurancePremiumSurcharge: number,
  ) {}
}

export class SetInsurancePremiumProgram {
  static readonly type = '@insurances.setInsurancePremiumProgram';
  constructor(public id: string, public insurancePremiumProgram: InsurancePremiumProgram | null) {}
}

export class SetStandardInsurancePremiumProgram {
  static readonly type = '@insurances.setStandardInsurancePremiumProgram';
  constructor(public id: string, public insurerType: InsurerType | null) {}
}

export class RecalculateInsurancePremium {
  static readonly type = '@insurances.recalculateInsurancePremium';
  constructor(
    public requestedMortgage: Mortgage | undefined,
    public insurancePremiumProgram: InsurancePremiumProgram | null,
    public ltv: number,
    public canApplyInsurancePremiumSurcharge: boolean,
  ) {}
}

export class SetInsuranceQuote {
  static readonly type = '@insurances.setInsuranceQuote';
  constructor(public id: string, public insuranceQuote: InsuranceQuote) {}
}

export class SetInsurers {
  static readonly type = '@insurances.setInsurers';
  constructor(public insurers: Insurer[]) {}
}

export class InitializeInsurances {
  static readonly type = '@insurances.initializeInsurers';
  constructor(public mortgages: Mortgage[]) {}
}
