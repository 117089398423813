import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from '../../../environments/environment';
import {
  BlockApplicationActionsUntilStage,
  FeatureConfig,
} from '../../../environments/environment.base';
import {
  ApplicationStage,
  ComputePropertyValueOptions,
  DocumentManagementType,
  Tenant,
  TenantSettingsConfig,
  UserAccount,
} from '../model';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AppFeaturesService {
  constructor(private http: HttpClient) {}

  getFeatures(currentUserAccount: UserAccount) {
    const envConfig = this.getFeaturesFromEnv(currentUserAccount);

    return { ...envConfig };
  }

  getFeaturesFromEnv(currentUserAccount: UserAccount) {
    if (!currentUserAccount) {
      return {} as FeatureConfig;
    }
    const tenantName = currentUserAccount?.tenant?.name;
    if (!tenantName) {
      throw new Error('Tenant is not defined');
    }
    const config = environment.features.config;

    // eslint-disable-next-line no-prototype-builtins
    if (config.hasOwnProperty(tenantName)) {
      return config[tenantName];
    }

    for (const tenant in config) {
      if (tenantName.startsWith(tenant)) {
        return config[tenant];
      }
    }

    return config.default;
  }

  getFeaturesFromTenantSettings(
    currentUserAccount: UserAccount,
    tenantSettings?: TenantSettingsConfig,
  ) {
    if (!tenantSettings) {
      return {} as FeatureConfig;
    }

    const featureConfig: Partial<FeatureConfig> = {
      additionalSecuritiesDisabled: tenantSettings.additionalSecuritiesDisabled === 'true',
      aiFeatures: parseJSONObject(tenantSettings.aiFeatures),
      applyCreditReportsOnIngestion: tenantSettings.applyCreditReportsOnIngestion === 'true',
      assigneeDLALimitEnabled: tenantSettings.assigneeDLALimitEnabled === 'true',
      automaticLoanAmountCalculationDisabled:
        tenantSettings.automaticLoanAmountCalculationDisabled === 'true',
      blockApplyingProductsWhenWarnings:
        tenantSettings.blockApplyingProductsWhenWarnings === 'true',
      blockConditionalApprovalBasedOnDLA:
        tenantSettings.blockConditionalApprovalBasedOnDLA === 'true',
      blockConditionalApprovalWhenUnresolvedApprovals:
        tenantSettings.blockConditionalApprovalWhenUnresolvedApprovals === 'true',
      blockConditionalApprovalWhenUnresolvedRiskFlags:
        tenantSettings.blockConditionalApprovalWhenUnresolvedRiskFlags === 'true',
      blockSendToServicingWhenUnresolvedApprovals:
        tenantSettings.blockSendToServicingWhenUnresolvedApprovals === 'true',
      cgEnabled: tenantSettings.cgEnabled === 'true',
      checkProductApplyOnStageTransitionEnabled:
        tenantSettings.checkProductApplyOnStageTransitionEnabled === 'true',
      clientIdGenerationEnabled: tenantSettings.clientIdGenerationEnabled === 'true',
      cmhcEnabled: tenantSettings.cmhcEnabled === 'true',
      commitmentExpiryDateOffset: tenantSettings.commitmentExpiryDateOffset,
      computePropertyValueOption:
        tenantSettings.computePropertyValueOption &&
        tenantSettings.computePropertyValueOption in ComputePropertyValueOptions
          ? ComputePropertyValueOptions[
              tenantSettings.computePropertyValueOption as keyof typeof ComputePropertyValueOptions
            ]
          : undefined,
      createCollectorAccount: tenantSettings.createCollectorAccount === 'true',
      decisionEngineIntegrationEnabled: tenantSettings.decisionEngineIntegrationEnabled === 'true',
      delegateRemovalEnabled: tenantSettings.delegateRemovalEnabled === 'true',
      demo: tenantSettings.demo === 'true',
      disabledPropertyDetailsTabs: tenantSettings.disabledPropertyDetailsTabs
        ? tenantSettings.disabledPropertyDetailsTabs.split(',').map((item) => item.trim())
        : undefined,
      dmOneTimeLinkGenerationStrategy: tenantSettings.dmOneTimeLinkGenerationStrategy,
      dmOpenDocumentsStrategy: tenantSettings.dmOpenDocumentsStrategy,
      documentManagementType: tenantSettings.documentManagementType,
      dolphinRead: tenantSettings.dolphinRead,
      dolphinServicing: tenantSettings.dolphinServicing,
      enable5Cs: tenantSettings.enable5Cs !== 'false',
      enableAdvancedAutomaticAssignment:
        tenantSettings.enableAdvancedAutomaticAssignment === 'true',
      enableExpandedLendingLimits: tenantSettings.enableExpandedLendingLimits === 'true',
      enableAutomaticAssignment: tenantSettings.enableAutomaticAssignment === 'true',
      enableBlanket: tenantSettings.enableBlanket === 'true',
      enableBlocklistScan: tenantSettings.enableBlocklistScan === 'true',
      enableCloneDeal: tenantSettings.enableCloneDeal === 'true',
      enableConstructionModule: tenantSettings.enableConstructionModule === 'true',
      enableFraudIq: tenantSettings.enableFraudIq === 'true',
      enableFundmoreScore: tenantSettings.enableFundmoreScore !== 'false',
      enableOOOReplacementAutomation: tenantSettings.enableOOOReplacementAutomation === 'true',
      eqMapsEnabled: tenantSettings.eqMapsEnabled === 'true',
      equifaxEnabled: tenantSettings.equifaxEnabled === 'true',
      fctEnabled: tenantSettings.fctEnabled === 'true',
      fctIntegrationEnabled: tenantSettings.fctIntegrationEnabled === 'true',
      flipModuleEnabled: tenantSettings.flipModuleEnabled === 'true',
      fnfEnabled: tenantSettings.fnfEnabled === 'true',
      fundmoreDMEnabled:
        !!currentUserAccount.user.ezidoxCollectorId &&
        tenantSettings.documentManagementType === DocumentManagementType.EZIDOX,
      hasCustomScore: tenantSettings.hasCustomScore === 'true',
      helpRedirectLink: tenantSettings.helpRedirectLink,
      iadByFpdEnabled: tenantSettings.iadByFpdEnabled === 'true',
      insuranceAutomationEnabled: tenantSettings.insuranceAutomationEnabled === 'true',
      intellifiServicing: tenantSettings.intellifiServicing === 'true',
      iqDocumentRequestsEnabled: tenantSettings.iqDocumentRequestsEnabled === 'true',
      memberIdIntegrationEnabled: tenantSettings.memberIdIntegrationEnabled === 'true',
      optionalFields: parseJSONArray(tenantSettings.optionalFields),
      widgetViewPermissions: parseJSONArray(tenantSettings.widgetViewPermissions),
      partnerLoanOptions: parseJSONObject(tenantSettings.partnerLoanOptions),
      ltiSettings: parseJSONObject(tenantSettings.ltiSettings),
      reportingModule: tenantSettings.reportingModule === 'true',
      restrictCBLiabilityEditing: parseJSONObject(tenantSettings.restrictCBLiabilityEditing),
      sagenEnabled: tenantSettings.sagenEnabled === 'true',
      timezone: tenantSettings.timezone,
      enableProductStatus: tenantSettings.enableProductStatus === 'true',
      blockApplicationActionsStage: parseJSONObject(
        tenantSettings.blockApplicationActionsStage,
      ) as BlockApplicationActionsUntilStage,
      isPrimaryResidenceEnabled: tenantSettings.isPrimaryResidenceEnabled === 'true',
      incrementsEnabled: tenantSettings.incrementsEnabled === 'true',
      incrementsAutomationEnabled: tenantSettings.incrementsAutomationEnabled === 'true',
      decrementsEnabled: tenantSettings.decrementsEnabled === 'true',
      enableProductDefaultValueOverride:
        tenantSettings.enableProductDefaultValueOverride === 'true',
      rateChangeNotificationsEnabled: tenantSettings.rateChangeNotificationsEnabled === 'true',
      enableClamavFileScanning: tenantSettings.enableClamavFileScanning === 'true',
      isAVAEnabled: tenantSettings.isAVAEnabled === 'true',
      discretionEnabled: tenantSettings.discretionEnabled === 'true',
      signPaymentChangeFormTaskAutomationEnabled:
        tenantSettings.signPaymentChangeFormTaskAutomationEnabled === 'true',
      reissueCOBTaskAutomationEnabled: tenantSettings.reissueCOBTaskAutomationEnabled === 'true',
      blockMovingWithoutApproveStartingWithStage:
        tenantSettings.blockMovingWithoutApproveStartingWithStage as ApplicationStage,
      excludeExtraPaymentInTerm: tenantSettings.excludeExtraPaymentInTerm === 'true',
      incrementsParameters: parseJSONObject(tenantSettings.incrementsParameters),
      isServicingEnabled: tenantSettings.isServicingEnabled === 'true',
      preventManualAgentCreation: tenantSettings.preventManualAgentCreation === 'true',
      fctMmsIntegrationEnabled: tenantSettings.fctMmsIntegrationEnabled === 'true',
      isBlendedRateOnMCUEnabled: tenantSettings.isBlendedRateOnMCUEnabled === 'true',
      // enabled when missing, intended to be used to enable the old streetview component
      googleMapsEmbedded: tenantSettings.googleMapsEmbedded !== 'false',
      takeFullFirstPayment: tenantSettings.takeFullFirstPayment === 'true',
      otherRateConfigurations: parseJSONObject(tenantSettings.otherRateConfigurations),
      getApplicationV2Enabled: tenantSettings.getApplicationV2Enabled === 'true',
      reapplyDefaultFeesEnabled: tenantSettings.reapplyDefaultFeesEnabled === 'true',
    };

    featureConfig.mortgageInsuranceEnabled =
      featureConfig.sagenEnabled || featureConfig.cmhcEnabled || featureConfig.cgEnabled;

    if (tenantSettings.automaticCompoundingEnabled) {
      try {
        featureConfig.automaticCompoundingEnabled = JSON.parse(
          tenantSettings.automaticCompoundingEnabled,
        );
      } catch (e) {
        console.error('Error parsing automaticCompoundingEnabled', e);
      }
    }

    if (tenantSettings.automaticArchivingSettings) {
      try {
        featureConfig.automaticArchivingSettings = JSON.parse(
          tenantSettings.automaticArchivingSettings,
        );
      } catch (e) {
        console.error('Error parsing automaticArchivingSettings', e);
      }
    }

    if (tenantSettings.decisionEngineSubmitStageBasedBehaviour) {
      try {
        featureConfig.decisionEngineSubmitStageBasedBehaviour = JSON.parse(
          tenantSettings.decisionEngineSubmitStageBasedBehaviour,
        );
      } catch (e) {
        console.error('Error parsing decisionEngineSubmitStageBasedBehaviour', e);
      }
    }

    if (tenantSettings.locCalculatorParameters) {
      try {
        featureConfig.locCalculatorParameters = JSON.parse(tenantSettings.locCalculatorParameters);
      } catch (e) {
        console.error('Error parsing locCalculatorParameters', e);
      }
    }

    if (tenantSettings.paymentFrequencyByRateType) {
      try {
        featureConfig.paymentFrequencyByRateType = JSON.parse(
          tenantSettings.paymentFrequencyByRateType,
        );
      } catch (e) {
        console.error('Error parsing paymentFrequencyByRateType', e);
      }
    }

    if (tenantSettings.purposeCodeByApplicationPurpose) {
      try {
        featureConfig.purposeCodeByApplicationPurpose = JSON.parse(
          tenantSettings.purposeCodeByApplicationPurpose,
        );
      } catch (e) {
        console.error('Error parsing purposeCodeByApplicationPurpose', e);
      }
    }

    if (tenantSettings.tenantStages) {
      try {
        featureConfig.tenantStages = JSON.parse(tenantSettings.tenantStages);
      } catch (e) {
        console.error('Error parsing tenantStages', e);
      }
    }

    if (tenantSettings.tenantStagesServicing) {
      try {
        featureConfig.tenantStagesServicing = JSON.parse(tenantSettings.tenantStagesServicing);
      } catch (e) {
        console.error('Error parsing tenantStagesServicing', e);
      }
    }

    if (tenantSettings.overrideRateType) {
      try {
        featureConfig.overrideRateType = JSON.parse(tenantSettings.overrideRateType);
      } catch (e) {
        console.error('Error parsing overrideRateType', e);
      }
    }

    if (tenantSettings.forceDateRecalculationParamsOnIngest) {
      try {
        featureConfig.forceDateRecalculationParamsOnIngest = JSON.parse(
          tenantSettings.forceDateRecalculationParamsOnIngest,
        );
      } catch (e) {
        console.error('Error parsing forceDateRecalculationParamsOnIngest', e);
      }
    }
    return featureConfig;
  }

  updateTenantSetting(key: string, value: string) {
    return this.http.patch<void>(`${environment.api_url}/tenant/settings`, {
      key,
      value,
    });
  }

  getTenant() {
    return this.http.get<[Tenant]>(`${environment.api_url}/tenant`);
  }
}

export function parseJSONObject(str?: string) {
  try {
    return str ? JSON.parse(str) : {};
  } catch (e) {
    console.error('Error parsing JSON object', e);
    return {};
  }
}

export function parseJSONArray(str?: string) {
  try {
    return str ? JSON.parse(str) : [];
  } catch (e) {
    console.error('Error parsing JSON array', e);
    return [];
  }
}
