import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap, finalize, switchMap } from 'rxjs/operators';
import { InsurancesService } from './insurances.services';
import {
  InsurancePremium,
  InsurancePremiumProgram,
  InsuranceQuote,
  Insurer,
  InsurerType,
  MortgageType,
} from '../shared/model';
import { LoadingEnd, LoadingStart } from '../core/loading.state';
import { FundmoreCalculator } from '@fundmoreai/calculator';
import { EMPTY, of } from 'rxjs';
import {
  GetInsurancePremiumPlans,
  GetInsurers,
  RecalculateInsurancePremium,
  SetInsurancePremiumAndAmount,
  SetInsurancePremiumProgram,
  SetStandardInsurancePremiumProgram,
  ClearInsurance,
  SetInsurer,
  SetInsurancePremium,
  SetInsurancePremiumByLTV,
  SetInsuranceAmount,
  SetInsurancePremiumSurchargeApplied,
  SetInsuranceQuote,
  SetInsurers,
  GetMIQuote,
  InitializeInsurances,
} from './insurances.state.actions';

interface InsuranceDetails {
  insurer: InsurerType | null;
  insurancePremiumProgram: InsurancePremiumProgram | null;
  insurancePremium: number | null;
  insuranceAmount: number | null;
  insuranceQuote: InsuranceQuote | null;
  insurancePremiumSurchargeApplied?: boolean | null;
  insurancePremiumSurchargeAmount?: number | null;
}

export interface InsuranceStateModel {
  insurers: Insurer[];
  insurancesByMortgage: Record<string, InsuranceDetails>;
  insurancePremiumPlans: InsurancePremium[];
}
const defaults = {
  insurers: [],
  insurancesByMortgage: {},
  insurancePremiumPlans: [],
};

@State<InsuranceStateModel>({
  name: 'insurances',
  defaults: {
    ...defaults,
  },
})
@Injectable()
export class InsuranceState {
  constructor(private insurancesServices: InsurancesService, private store: Store) {}

  static insurer(id: string) {
    return createSelector([InsuranceState], (state: InsuranceStateModel): InsurerType | null => {
      return state.insurancesByMortgage?.[id]?.insurer;
    });
  }

  static insurancePremium(id: string) {
    return createSelector([InsuranceState], (state: InsuranceStateModel): number | null => {
      return state.insurancesByMortgage?.[id]?.insurancePremium;
    });
  }

  static insurancePremiumProgram(id: string) {
    return createSelector(
      [InsuranceState],
      (state: InsuranceStateModel): InsurancePremiumProgram | null => {
        return state.insurancesByMortgage?.[id]?.insurancePremiumProgram;
      },
    );
  }

  static insuranceAmount(id: string) {
    return createSelector([InsuranceState], (state): number | null => {
      return state.insurancesByMortgage?.[id]?.insuranceAmount ?? null;
    });
  }

  static insurance(id: string) {
    return createSelector([InsuranceState], (state): InsuranceDetails | null => {
      return state.insurancesByMortgage?.[id];
    });
  }
  static insuranceQuote(id: string) {
    return createSelector([InsuranceState], (state): InsuranceQuote | null => {
      return state.insurancesByMortgage?.[id]?.insuranceQuote ?? null;
    });
  }

  @Selector() static insurers(state: InsuranceStateModel) {
    return state.insurers;
  }

  @Selector() static insurancePremiumPlans(state: InsuranceStateModel) {
    return state.insurancePremiumPlans;
  }

  @Selector() static insurancePremiumSurchargeAmount() {
    return 0.2;
  }

  @Action(GetInsurancePremiumPlans) getInsurancePremiumPlans(
    ctx: StateContext<InsuranceStateModel>,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.insurancesServices.getInsurancePremiumPlans().pipe(
      tap((insurancePremiumPlans) => {
        ctx.patchState({ insurancePremiumPlans });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(GetInsurers) getInsurers(ctx: StateContext<InsuranceStateModel>) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.insurancesServices.getInsurers().pipe(
      tap((insurers) => {
        ctx.patchState({ insurers });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  getInsurerName(insurerType: InsurerType | null) {
    switch (insurerType) {
      case InsurerType.CANADA_GUARANTY:
        return 'Canada Guaranty';

      case InsurerType.SAGEN:
        return 'Sagen';

      case InsurerType.CMHC:
        return 'CMHC';

      default:
        return null;
    }
  }

  @Action(RecalculateInsurancePremium) recalculateInsurancePremium(
    ctx: StateContext<InsuranceStateModel>,
    action: RecalculateInsurancePremium,
  ) {
    const state = ctx.getState();

    if (!action.requestedMortgage) {
      return EMPTY;
    }

    const insurer = this.store.selectSnapshot(InsuranceState.insurer(action.requestedMortgage.id));
    const insurerName = this.getInsurerName(insurer);
    const insurancePremiumPlan = state.insurancePremiumPlans?.find(
      (p: InsurancePremium) =>
        p.insurer?.name === insurerName &&
        p.programCode === action.insurancePremiumProgram &&
        p.ltvRatioStart <= action.ltv &&
        p.ltvRatioEnd >= action.ltv,
    );

    const applyInsurancePremiumSurcharge =
      action.canApplyInsurancePremiumSurcharge &&
      action.requestedMortgage.insurancePremiumSurchargeApplied
        ? true
        : false;
    const surcharge = applyInsurancePremiumSurcharge
      ? this.store.selectSnapshot(InsuranceState.insurancePremiumSurchargeAmount)
      : 0;
    if (insurancePremiumPlan) {
      const premiumRateWithSurcharge = insurancePremiumPlan.rate + surcharge;
      const insuranceAmount = FundmoreCalculator.calculateInsuranceAmount(
        premiumRateWithSurcharge,
        action.requestedMortgage.loanAmount,
      );
      return ctx.dispatch(
        new SetInsurancePremiumAndAmount(
          action.requestedMortgage.id,
          premiumRateWithSurcharge,
          insuranceAmount,
          applyInsurancePremiumSurcharge,
          surcharge,
        ),
      );
    } else {
      // can happen if there is no program selected or LTV does not match
      const insuranceAmount = FundmoreCalculator.calculateInsuranceAmount(
        action.requestedMortgage.insurancePremium,
        action.requestedMortgage.loanAmount,
      );

      return ctx.dispatch(
        new SetInsurancePremiumAndAmount(
          action.requestedMortgage.id,
          action.requestedMortgage.insurancePremium,
          insuranceAmount,
          applyInsurancePremiumSurcharge,
          surcharge,
        ),
      );
    }
  }

  @Action(SetInsurancePremiumProgram) setInsurancePremiumProgram(
    ctx: StateContext<InsuranceStateModel>,
    action: SetInsurancePremiumProgram,
  ) {
    const state = ctx.getState();

    const updatedInsurances = {
      ...state.insurancesByMortgage,
      [action.id]: {
        ...(state.insurancesByMortgage?.[action.id] ?? {}),
        insurancePremiumProgram: action.insurancePremiumProgram,
      },
    };

    ctx.patchState({
      insurancesByMortgage: updatedInsurances,
    });
  }

  @Action(SetStandardInsurancePremiumProgram) setStandardInsurancePremiumProgram(
    ctx: StateContext<InsuranceStateModel>,
    action: SetStandardInsurancePremiumProgram,
  ) {
    let program: InsurancePremiumProgram | null = null;
    switch (action.insurerType) {
      case InsurerType.SAGEN:
        program = InsurancePremiumProgram.SAGEN_STANDARD_PREMIUMS;
        break;
      case InsurerType.CANADA_GUARANTY:
        program = InsurancePremiumProgram.CG_STANDARD_PREMIUMS;
        break;
      case InsurerType.CMHC:
        program = InsurancePremiumProgram.CMHC_STANDARD_PREMIUMS;
        break;
    }

    if (program) {
      return ctx.dispatch(new SetInsurancePremiumProgram(action.id, program));
    }

    return of(null);
  }

  @Action(ClearInsurance)
  clearInsurance(ctx: StateContext<InsuranceStateModel>, action: ClearInsurance) {
    const state = ctx.getState();

    ctx.patchState({
      insurancesByMortgage: {
        ...state.insurancesByMortgage,
        [action.id]: {
          ...(state.insurancesByMortgage?.[action.id] ?? {}),
          insurer: null,
          insurancePremiumProgram: null,
          insurancePremium: null,
          insuranceAmount: null,
          insuranceQuote: null,
          insurancePremiumSurchargeAmount: null,
          insurancePremiumSurchargeApplied: null,
        },
      },
    });
  }

  @Action(SetInsurer)
  setInsurer(ctx: StateContext<InsuranceStateModel>, action: SetInsurer) {
    const state = ctx.getState();

    const updatedInsurances = {
      ...state.insurancesByMortgage,
      [action.id]: {
        ...(state.insurancesByMortgage?.[action.id] ?? {}),
        insurer: action.insurer,
      },
    };

    ctx.patchState({
      insurancesByMortgage: updatedInsurances,
    });

    return ctx.dispatch(new SetStandardInsurancePremiumProgram(action.id, action.insurer));
  }

  @Action(SetInsurancePremium) setInsurancePremium(
    ctx: StateContext<InsuranceStateModel>,
    action: SetInsurancePremium,
  ) {
    const state = ctx.getState();

    const updatedInsurances = {
      ...state.insurancesByMortgage,
      [action.id]: {
        ...(state.insurancesByMortgage?.[action.id] ?? {}),
        insurancePremium: action.insurancePremium,
        insurancePremiumSurchargeApplied: false,
        insurancePremiumSurchargeAmount: null,
      },
    };

    ctx.patchState({
      insurancesByMortgage: updatedInsurances,
    });
  }

  @Action(SetInsurancePremiumByLTV) setInsurancePremiumByLTV(
    ctx: StateContext<InsuranceStateModel>,
    action: SetInsurancePremiumByLTV,
  ) {
    if (!action.requestedMortgage) {
      return EMPTY;
    }
    const insuranceProgram = this.store.selectSnapshot(
      InsuranceState.insurancePremiumProgram(action.requestedMortgage.id),
    );

    return ctx.dispatch(
      new RecalculateInsurancePremium(
        action.requestedMortgage,
        insuranceProgram,
        action.ltv,
        action.canApplyInsurancePremiumSurcharge,
      ),
    );
  }

  @Action(SetInsuranceAmount) setInsuranceAmount(
    ctx: StateContext<InsuranceStateModel>,
    action: SetInsuranceAmount,
  ) {
    const state = ctx.getState();

    const updatedInsurances = {
      ...state.insurancesByMortgage,
      [action.id]: {
        ...(state.insurancesByMortgage?.[action.id] ?? {}),
        insuranceAmount: action.insuranceAmount,
        insurancePremiumSurchargeApplied: false,
        insurancePremiumSurchargeAmount: null,
      },
    };

    ctx.patchState({
      insurancesByMortgage: updatedInsurances,
    });
  }

  @Action(SetInsurancePremiumSurchargeApplied) setInsurancePremiumSurchargeApplied(
    ctx: StateContext<InsuranceStateModel>,
    action: SetInsurancePremiumSurchargeApplied,
  ) {
    const insurancePremiumSurchargeAmount = this.store.selectSnapshot(
      InsuranceState.insurancePremiumSurchargeAmount,
    );
    const state = ctx.getState();
    const insurance = state.insurancesByMortgage[action.id];
    const computedInsurancePremium = action.surchargeApplied
      ? (insurance.insurancePremium ?? 0) + insurancePremiumSurchargeAmount
      : (insurance.insurancePremium ?? 0) - insurancePremiumSurchargeAmount;

    const insuranceAmount = FundmoreCalculator.calculateInsuranceAmount(
      computedInsurancePremium,
      action.loanAmount,
    );

    ctx.dispatch(
      new SetInsurancePremiumAndAmount(
        action.id,
        computedInsurancePremium,
        insuranceAmount,
        action.surchargeApplied,
        action.surchargeApplied ? insurancePremiumSurchargeAmount : 0,
      ),
    );
  }

  @Action(SetInsurancePremiumAndAmount) setInsurancePremiumAndAmount(
    ctx: StateContext<InsuranceStateModel>,
    action: SetInsurancePremiumAndAmount,
  ) {
    const state = ctx.getState();

    const updatedInsurances = {
      ...state.insurancesByMortgage,
      [action.id]: {
        ...(state.insurancesByMortgage?.[action.id] ?? {}),
        insurancePremium: action.insurancePremium,
        insuranceAmount: action.insuranceAmount,
        insurancePremiumSurchargeApplied: action.appliedInsuranceSurcharge,
        insurancePremiumSurchargeAmount: action.insurancePremiumSurcharge,
      },
    };

    ctx.patchState({
      insurancesByMortgage: updatedInsurances,
    });
  }

  @Action(SetInsuranceQuote) setInsuranceQuote(
    ctx: StateContext<InsuranceStateModel>,
    action: SetInsuranceQuote,
  ) {
    const state = ctx.getState();

    const updatedInsurances = {
      ...state.insurancesByMortgage,
      [action.id]: {
        ...(state.insurancesByMortgage?.[action.id] ?? {}),
        insuranceQuote: action.insuranceQuote,
      },
    };

    ctx.patchState({
      insurancesByMortgage: updatedInsurances,
    });
  }

  @Action(SetInsurers) setInsurers(ctx: StateContext<InsuranceStateModel>, action: SetInsurers) {
    ctx.patchState({ insurers: action.insurers });
  }

  @Action(GetMIQuote) getMIQuote(ctx: StateContext<InsuranceStateModel>, action: GetMIQuote) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.insurancesServices.getMIQuote(action.insurerId).pipe(
      switchMap((quote) => {
        return ctx.dispatch(new SetInsuranceQuote(action.mortgageId, quote));
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(InitializeInsurances) initInsurances(
    ctx: StateContext<InsuranceStateModel>,
    action: InitializeInsurances,
  ) {
    const requestedMortgages = action.mortgages.filter((m) => m.type === MortgageType.REQUESTED);
    const insurancesByMortgage: Record<string, InsuranceDetails> = {};

    for (const requestedMortgage of requestedMortgages) {
      insurancesByMortgage[requestedMortgage.id] = {
        insurer: requestedMortgage.insurer,
        insurancePremiumProgram: requestedMortgage.insurancePremiumProgram,
        insuranceAmount: requestedMortgage.insuranceAmount,
        insurancePremium: requestedMortgage.insurancePremium,
        insuranceQuote: null,
        insurancePremiumSurchargeAmount: null,
        insurancePremiumSurchargeApplied: null,
      };
    }

    ctx.patchState({
      insurancesByMortgage,
    });
  }

  // TODO: Properly refactor this state separate general information and application information
  // @Action(ApplicationResetState) reset(ctx: StateContext<InsuranceStateModel>) {
  //   ctx.setState({ ...defaults });
  // }
}
