import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, tap } from 'rxjs/operators';
import { DefaultFeeKeyRecord } from './model';
import { FeesService } from './default-fees.service';
import { compareAsc } from 'date-fns';
import { LoadingStart, LoadingEnd } from '../../../core/loading.state';
import { DefaultFee, DefaultFeeKey } from '@fundmoreai/models';

export class UpdateDefaultFee {
  static readonly type = '@defaultFees.updateDefaultFee';
  constructor(public defaultFee: DefaultFee) {}
}

export class DeleteDefaultFee {
  static readonly type = '@defaultFees.deleteDefaultFee';
  constructor(public id: string) {}
}

export class CreateDefaultFee {
  static readonly type = '@defaultFees.createDefaultFee';
  constructor(public defaultFee: DefaultFee) {}
}

export class FetchDefaultFees {
  static readonly type = '@defaultFees.fetchDefaultFees';
}

export const DEFAULT_FEES_DEFAULT_DISPLAY_COLUMNS = Object.values(DefaultFeeKey).map(
  (value: DefaultFeeKey) => {
    return {
      field: value,
      name: DefaultFeeKeyRecord[value],
      isSelected: [
        DefaultFeeKey.TYPE,
        DefaultFeeKey.PAID_BY,
        DefaultFeeKey.PERCENT,
        DefaultFeeKey.AMOUNT,
      ].includes(value),
      isFrozen: false,
    };
  },
);

@State<DefaultFee[]>({
  name: 'defaultFees',
  defaults: [],
})
@Injectable()
export class DefaultFeesState {
  constructor(private feesService: FeesService) {}

  @Selector()
  static fees(state: DefaultFee[]) {
    return [...(state ?? [])].sort((a, b) =>
      compareAsc(new Date(a.createdAt ?? ''), new Date(b.createdAt ?? '')),
    );
  }

  @Action(UpdateDefaultFee)
  updateFee(ctx: StateContext<DefaultFee[]>, action: UpdateDefaultFee) {
    const state = ctx.getState();
    const prevId = action.defaultFee.id;
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.feesService
      .updateDefaultFee(action.defaultFee.id, { ...action.defaultFee, id: undefined })
      .pipe(
        tap((updatedDefaultFee) =>
          ctx.setState([...state.filter((f) => f.id !== prevId), updatedDefaultFee]),
        ),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(DeleteDefaultFee)
  deleteFee(ctx: StateContext<DefaultFee[]>, action: DeleteDefaultFee) {
    const state = ctx.getState();
    const prevId = action.id;
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.feesService.deleteDefaultFee(prevId).pipe(
      tap(() => ctx.setState([...state.filter((f) => f.id !== prevId)])),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(CreateDefaultFee)
  private createFee(ctx: StateContext<DefaultFee[]>, action: CreateDefaultFee) {
    const state = ctx.getState();
    const prevId = action.defaultFee.id;
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.feesService.addDefaultFee({ ...action.defaultFee, id: undefined }).pipe(
      tap((fee) => {
        return ctx.setState([...state.filter((f) => f.id !== prevId), fee]);
      }),
      catchError((e) => {
        ctx.setState([...state.filter((f) => f.id !== prevId)]);
        throw e;
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(FetchDefaultFees)
  getFees(ctx: StateContext<DefaultFee[]>) {
    ctx.setState([]);

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.feesService.getDefaultFees().pipe(
      tap((fees) => {
        ctx.setState(
          fees.sort((a, b) => {
            const typeOne = a.type ?? '';
            const typeTwo = b.type ?? '';
            return typeTwo.localeCompare(typeOne);
          }),
        );
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }
}
