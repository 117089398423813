import {
  CalculationItemType,
  CalculationResult,
  ComputePropertyValueOptions,
  Fee,
  Mortgage,
  CalculationAutomationSettings,
  Property,
} from '@fundmoreai/models';
import { computeExistingMortgageAmount } from './existing-mortgage-amount.calculator';
import { computeLoanAmountForLTV } from './loan-amount-for-ltv.calculator';
import { computePropertyValue } from './property-value.calculator';

export function computeLTV(
  propertyValue: number | undefined,
  existingMortgageAmount: number,
  loanAmount: number | undefined,
  calculationSettings?: CalculationAutomationSettings | null | undefined,
): CalculationResult {
  let ltvValue = 0;
  const computePropertyValueOption = calculationSettings?.ANY_MORTGAGE?.computePropertyValueOption;

  if (propertyValue && loanAmount) {
    ltvValue = ((loanAmount + existingMortgageAmount) / propertyValue) * 100;
  }

  // build ltv formula based on property value options
  let ltvFormula = '';
  let propertyValueText = '';
  switch (computePropertyValueOption) {
    case ComputePropertyValueOptions.PROPERTY_VALUE:
      ltvFormula = '(Total Loan Amount + Existing Mortgage Amount) / Property Value * 100';
      propertyValueText = 'Property Value';
      break;
    case ComputePropertyValueOptions.MIN_BETWEEN_VALUE_AND_PURCHASE_PRICE:
      ltvFormula =
        '(Total Loan Amount + Existing Mortgage Amount) / MIN(Property Value, Purchase Price) * 100';
      propertyValueText = 'MIN(Property Value, Purchase Price)';
      break;
    default:
      ltvFormula =
        '(Total Loan Amount + Existing Mortgage Amount) / MIN(Property Value, Purchase Price) * 100';
      propertyValueText = 'MIN(Property Value, Purchase Price)';
      break;
  }

  const data: CalculationResult = {
    formula: ltvFormula,
    items: [
      {
        text: 'Total Loan Amount',
        value: loanAmount?.toString(),
        type: CalculationItemType.CURRENCY,
      },
      {
        text: 'Existing Mortgage Amount',
        value: existingMortgageAmount?.toString(),
        type: CalculationItemType.CURRENCY,
      },
      {
        text: propertyValueText,
        value: propertyValue?.toString(),
        type: CalculationItemType.CURRENCY,
      },
    ],
    result: ltvValue,
  };
  return data;
}

export function computeLTVFromObjects(
  property: Property,
  existingMortgages: Mortgage[],
  requestedMortgages: Mortgage[],
  fees: Fee[],
  capFeesMaxPercentage: number | undefined,
  calculationAutomationSettings: CalculationAutomationSettings,
): CalculationResult {
  const propertyValue = computePropertyValue({
    property,
    calculationSettings: calculationAutomationSettings,
  });

  const existingMortgageAmount = computeExistingMortgageAmount(
    existingMortgages?.filter((x) => x.propertyId === property.id),
  );

  const loanAmount = computeLoanAmountForLTV(requestedMortgages, fees, capFeesMaxPercentage);

  const ltv = computeLTV(
    propertyValue,
    existingMortgageAmount,
    loanAmount,
    calculationAutomationSettings,
  );

  return ltv;
}
