import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageData, MessageInfoComponent } from '../portal/message-info.component';
import { AddNotification, FundmoreNotification } from '../shared';
import { patch, updateItem } from '@ngxs/store/operators';
import {
  ReadNotification,
  MuteNotifications,
  UnreadOnlyNotifications,
  ClearNotifications,
  ReadAllNotifications,
  UnreadNotification,
  ClearNotification,
  ClearNotificationGroup,
  ReadNotificationGroup,
  UnreadNotificationGroup,
} from './notification.state.actions';
import { AddNotifications, ResetState } from '../shared/state.model';
import { AddMessageAction, DeletePersistedMessages } from '../pubnub/pubnub.state.actions';
import { UserPreferencesState } from '../features/manager-portal/user-manage/user-preferences/user-preferences.state';
import { NotificationFilters, NotificationTypes } from '@fundmoreai/models';
import { EventMessageType } from '../pubnub/model';

interface FundmoreNotificationModel {
  version: number;
  notifications: FundmoreNotification[];
  mute: boolean;
  unreadOnly: boolean;
}

@State<FundmoreNotificationModel>({
  name: NotificationState.NAME,
  defaults: { ...NotificationState.DEFAULT_STATE_VALUES },
})
@Injectable()
export class NotificationState {
  static OTHER_GROUP = 'OTHER';
  static NAME = 'notification';
  static DEFAULT_STATE_VALUES: FundmoreNotificationModel = {
    version: 1,
    notifications: [],
    mute: false,
    unreadOnly: false,
  };
  constructor(private snackBar: MatSnackBar, private zone: NgZone, private store: Store) {}

  @Selector()
  static notifications(state: FundmoreNotificationModel) {
    return [...state.notifications].sort((a, b) =>
      a.addedAt && b.addedAt ? new Date(b.addedAt).getTime() - new Date(a.addedAt).getTime() : 0,
    );
  }

  @Selector([NotificationState.notifications, UserPreferencesState.notificationFilters])
  static filteredNotifications(
    notifications?: FundmoreNotification[],
    notificationFilters?: NotificationFilters,
  ) {
    if (!notifications) {
      return [];
    }

    if (!notificationFilters) {
      return notifications;
    }

    return NotificationState.filterNotifications(notifications, notificationFilters);
  }

  @Selector([NotificationState.filteredNotifications]) static notificationsGroupedByApplication(
    notifications: FundmoreNotification[],
  ): { [key: string]: FundmoreNotification[] } {
    return notifications.reduce((acc, n) => {
      if (!n.applicationId || this.isNonApplicationEvent(n.eventType)) {
        if (!acc[NotificationState.OTHER_GROUP]) {
          acc[NotificationState.OTHER_GROUP] = [];
        }

        acc[NotificationState.OTHER_GROUP].push(n);

        return acc;
      }

      if (!acc[n.applicationId]) {
        acc[n.applicationId] = [];
      }

      acc[n.applicationId].push(n);

      return acc;
    }, {} as { [key: string]: FundmoreNotification[] });
  }

  static isNonApplicationEvent(eventType?: EventMessageType) {
    return (
      eventType === EventMessageType.USER_DETAILS_CHANGED ||
      eventType === EventMessageType.DELEGATE_SET
    );
  }

  static filterNotifications(notifications: FundmoreNotification[], filters: NotificationFilters) {
    let filteredNotifications = [...notifications];

    if (filters?.showOnlyUnread) {
      filteredNotifications = filteredNotifications.filter((n) => !n.seen);
    }

    if (filters?.stages && filters.stages.length > 0) {
      filteredNotifications = filteredNotifications.filter(
        (n) => n.applicationStage && filters?.stages?.includes(n.applicationStage),
      );
    }

    if (filters?.notificationTypes && filters.notificationTypes.length > 0) {
      filteredNotifications = filteredNotifications.filter((n) => {
        const type = this.getMessageType(n.eventType);

        return type && filters?.notificationTypes?.includes(type);
      });
    }

    return filteredNotifications;
  }

  static getMessageType(eventType?: EventMessageType): NotificationTypes | null {
    if (!eventType) {
      return null;
    }

    if (
      eventType === EventMessageType.APPROVAL_REQUESTED ||
      eventType === EventMessageType.APPROVAL_REQUEST_CANCELED ||
      eventType === EventMessageType.APPROVAL_REQUEST_REVIEWED ||
      eventType === EventMessageType.APPROVAL_RECOMMENDED_FOR_APPROVAL
    ) {
      return NotificationTypes.APPROVAL;
    } else if (
      eventType === EventMessageType.TASK_ASSIGNED ||
      eventType === EventMessageType.TASK_UPDATED ||
      eventType === EventMessageType.TASK_DELETED ||
      eventType === EventMessageType.TASK_MARK_AS_COMPLETED
    ) {
      return NotificationTypes.TASK;
    } else if (
      eventType === EventMessageType.CONDITION_DOCUMENT_UPLOADED ||
      eventType === EventMessageType.CONDITION_DOCUMENT_REVIEWED ||
      eventType === EventMessageType.APPLICATION_CONDITION_DOCUMENT_COMMENT_ADDED ||
      eventType === EventMessageType.DOCUMENT_REQUEST_CREATED ||
      eventType === EventMessageType.DOCUMENT_SIGNATURE_REQUEST_CREATED ||
      eventType === EventMessageType.DOCUMENT_SIGNATURE_REQUEST_UPDATED ||
      eventType === EventMessageType.DOCUMENT_REQUEST_DELETED ||
      eventType === EventMessageType.DOCUMENT_REQUEST_NOTE_CREATED
    ) {
      return NotificationTypes.DOCUMENT;
    } else if (eventType === EventMessageType.CHAT_MENTION) {
      return NotificationTypes.CHAT;
    } else if (eventType === EventMessageType.APPLICATION_WARNINGS_UPDATED) {
      return NotificationTypes.WARNING;
    } else if (eventType === EventMessageType.INSURANCE_QUOTE_UPDATED) {
      return NotificationTypes.INSURANCE;
    } else {
      return NotificationTypes.APPLICATION;
    }
  }

  @Selector([NotificationState.filteredNotifications]) static filteredUnreadNotificationsCount(
    notifications: FundmoreNotification[],
  ) {
    return notifications.filter((a) => a.seen !== true).length;
  }

  @Selector([
    NotificationState.unreadNotificationsCount,
    NotificationState.filteredUnreadNotificationsCount,
  ])
  static notificationsTooltip(
    unreadNotificationsCount: number,
    filteredUnreadNotificationsCount: number,
  ) {
    if (unreadNotificationsCount === filteredUnreadNotificationsCount) {
      return `${unreadNotificationsCount} Notifications`;
    }

    return `${filteredUnreadNotificationsCount} filtered notifications (${unreadNotificationsCount} total).`;
  }

  @Selector()
  static hasUnreadNotifications(state: FundmoreNotificationModel) {
    return state.notifications.some((a) => a.seen !== true);
  }

  @Selector()
  static unreadNotificationsCount(state: FundmoreNotificationModel) {
    return state.notifications.filter((a) => a.seen !== true).length;
  }

  @Selector()
  static isMuted(state: FundmoreNotificationModel) {
    return state.mute;
  }

  @Selector()
  static unreadOnly(state: FundmoreNotificationModel) {
    return state.unreadOnly;
  }

  @Action(AddNotification)
  add(ctx: StateContext<FundmoreNotificationModel>, action: AddNotification) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      notifications: [
        ...state.notifications,
        { ...action.notification, addedAt: action.notification.addedAt ?? new Date() },
      ],
    });

    if (state.mute) {
      return;
    }

    const userPreference = this.store.selectSnapshot(UserPreferencesState.userPreference);

    const filters = userPreference?.notificationFilters;

    if (!filters) {
      this.showMessage(action.notification);

      return;
    }

    const [filteredNotification] = NotificationState.filterNotifications(
      [action.notification],
      filters,
    );

    if (filteredNotification) {
      this.showMessage(filteredNotification);
    }
  }

  @Action(AddNotifications)
  addNotifications(ctx: StateContext<FundmoreNotificationModel>, action: AddNotifications) {
    const state = ctx.getState();

    const newNotifications = action.notifications.filter(
      (n) => !state.notifications.some((f) => this.isTheSameNotification(f, n)),
    );

    // favor the pubnub notifications over the old ones, in case of changes from other sessions
    // replace the same notifications
    const existingNotifications = action.notifications.filter((n) =>
      state.notifications.some((f) => this.isTheSameNotification(f, n)),
    );
    const restoreNotifications = [
      ...state.notifications.filter(
        (n) => !action.notifications.some((f) => this.isTheSameNotification(f, n)),
      ),
      ...newNotifications.map((n) => ({ ...n, addedAt: n.addedAt ?? new Date() })),
      ...existingNotifications,
    ];

    ctx.setState({
      ...state,
      notifications: restoreNotifications,
    });
  }

  @Action(ReadAllNotifications)
  readAll(ctx: StateContext<FundmoreNotificationModel>) {
    const state = ctx.getState();

    state.notifications.forEach((n) => {
      if (n.seen !== true) {
        ctx.setState(
          patch({
            notifications: updateItem<FundmoreNotification>(
              (f) => f?.seen !== true,
              patch({ seen: true, readAt: new Date() }),
            ),
          }),
        );
      }
    });
  }

  @Action(ReadNotification)
  readOne(ctx: StateContext<FundmoreNotificationModel>, action: ReadNotification) {
    ctx.setState(
      patch({
        notifications: updateItem<FundmoreNotification>(
          (f) => f && this.isTheSameNotification(f, action.notification),
          patch({ seen: true, readAt: new Date() }),
        ),
      }),
    );
    action.notification.timetoken &&
      ctx.dispatch(new AddMessageAction(action.notification.timetoken.toString(), 'read'));
  }

  @Action(MuteNotifications)
  muteUnmute(ctx: StateContext<FundmoreNotificationModel>, action: MuteNotifications) {
    ctx.patchState({ mute: action.mute });
  }

  @Action(UnreadOnlyNotifications)
  unreadOnly(ctx: StateContext<FundmoreNotificationModel>, action: UnreadOnlyNotifications) {
    ctx.patchState({ unreadOnly: action.unread });
  }

  @Action(ClearNotifications)
  clear(ctx: StateContext<FundmoreNotificationModel>) {
    ctx.patchState({ notifications: [] });
    ctx.dispatch(new DeletePersistedMessages(undefined));
  }

  @Action(ClearNotificationGroup) clearGroup(
    ctx: StateContext<FundmoreNotificationModel>,
    action: ClearNotificationGroup,
  ) {
    throw new Error('Not implemented!');

    // const groups = this.store.selectSnapshot(NotificationState.notificationsGroupedByApplication);

    // const notifications = groups[action.groupKey] ?? [];

    // return ctx.dispatch(notifications.map((n) => new ClearNotification(n)));
  }

  @Action(ReadNotificationGroup) readGroup(
    ctx: StateContext<FundmoreNotificationModel>,
    action: ReadNotificationGroup,
  ) {
    const groups = this.store.selectSnapshot(NotificationState.notificationsGroupedByApplication);

    const notifications = groups[action.groupKey] ?? [];

    const state = ctx.getState();

    state.notifications.forEach((n) => {
      if (n.seen !== true) {
        ctx.setState(
          patch({
            notifications: updateItem<FundmoreNotification>(
              (f) =>
                f?.seen !== true && notifications.some((n) => this.isTheSameNotification(f, n)),
              patch({ seen: true, readAt: new Date() }),
            ),
          }),
        );
      }
    });
  }

  @Action(UnreadNotificationGroup) unreadGroup(
    ctx: StateContext<FundmoreNotificationModel>,
    action: UnreadNotificationGroup,
  ) {
    const groups = this.store.selectSnapshot(NotificationState.notificationsGroupedByApplication);

    const notifications = groups[action.groupKey] ?? [];

    const state = ctx.getState();

    state.notifications.forEach((n) => {
      if (n.seen !== false) {
        ctx.setState(
          patch({
            notifications: updateItem<FundmoreNotification>(
              (f) =>
                f?.seen !== false && notifications.some((n) => this.isTheSameNotification(f, n)),
              patch({ seen: false, readAt: undefined }),
            ),
          }),
        );
      }
    });
  }

  @Action(ResetState)
  reset(ctx: StateContext<FundmoreNotificationModel>) {
    ctx.dispatch(new ClearNotifications());
  }

  @Action(UnreadNotification)
  unreadNotification(ctx: StateContext<FundmoreNotificationModel>, action: UnreadNotification) {
    ctx.setState(
      patch({
        notifications: updateItem<FundmoreNotification>(
          (f) => f && this.isTheSameNotification(f, action.notification),
          patch({ seen: false, readAt: undefined }),
        ),
      }),
    );
    action.notification.timetoken &&
      ctx.dispatch(new AddMessageAction(action.notification.timetoken.toString(), 'unread'));
  }

  @Action(ClearNotification)
  clearNotification(ctx: StateContext<FundmoreNotificationModel>, action: ClearNotification) {
    ctx.patchState({
      notifications: ctx.getState().notifications.filter((n) => n !== action.notification),
    });
    action.notification.timetoken &&
      ctx.dispatch(new DeletePersistedMessages(action.notification.timetoken));
  }

  private isTheSameNotification = (state: FundmoreNotification, verify: FundmoreNotification) =>
    state.message === verify.message &&
    state.channel === verify.channel &&
    (state.addedAt instanceof Date ? state.addedAt.toISOString() : state.addedAt) ===
      (verify.addedAt instanceof Date ? verify.addedAt.toISOString() : verify.addedAt) &&
    state.applicationId === verify.applicationId &&
    state.title === verify.title;

  private showMessage(notification: FundmoreNotification) {
    const data = {
      message: notification.message,
      title: notification.title,
      duration: notification.notificationDuration ?? 10000,
    } as MessageData;

    this.zone.run(() =>
      this.snackBar.openFromComponent(MessageInfoComponent, {
        data: data,
        duration: data.duration,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['bg-grey', 'text-dark', 'text-pre-wrap', 'contrast'],
      }),
    );
  }
}
