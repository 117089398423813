import { FeePayer, FeeType } from './enums';

export interface DefaultFee {
  id: string;
  type: FeeType | null;
  paidBy: FeePayer | null;
  percent: number | null;
  amount: number | null;
  subtractFromPrincipal: boolean | null;
  includeInApr: boolean;
  products: string[] | null;
  creditTiers: string[] | null;
  applicationPurposes: string[] | null;
  termTypes: string[] | null;
  new?: boolean;
  capFeesPercentage: number;
  createdAt?: string;
  description: string | null;
}

export enum DefaultFeeKey {
  TYPE = 'type',
  PAID_BY = 'paidBy',
  DESCRIPTION = 'description',
  PRODUCTS = 'products',
  CREDIT_TIERS = 'creditTiers',
  APPLICATION_PURPOSE = 'applicationPurposes',
  TERM_TYPES = 'termTypes',
  PERCENT = 'percent',
  AMOUNT = 'amount',
  SUBTRACT_FROM_PRINCIPAL = 'subtractFromPrincipal',
  CAP_FEES_PERCENTAGE = 'capFeesPercentage',
  INCLUDE_IN_APR = 'includeInApr',
}
