import { AgentAddressExpanded } from './agentAddressExpanded';
import { BrokerUserType, LanguagePreference } from './applicant';
import { ApplicationSource } from './application';
import { AgentContact, AgentContactType, AgentSource } from './enums';

export interface Broker {
  id: string;
  AgentAddressExpanded?: AgentAddressExpanded[] | null;
  firstName: string;
  lastName: string;
  cellPhoneNumber?: string | null;
  email: string | null;
  firmCode?: string | null;
  firmName: string | null;
  riskScore?: number | null;
  source: ApplicationSource | AgentSource | null;
  notes: string | null;
  updatedAt: Date | string | null;
  createdAt: Date | string | null;
  tenantId: string;
  homePhoneNumber?: string | null;
  workPhoneNumber?: string | null;
  fax?: string | null;
  address?: string | null;
  languagePreference?: LanguagePreference | null;
  preferredContactMethod?: AgentContactType | null;
  contacts?: AgentContact[] | null;
  userType?: BrokerUserType | null;
  licenseRegistrationNumber?: string | null;
  approved?: boolean | null;
  externalRef?: string | null;
  deletedAt?: any;
  verified?: boolean | null;
  verifiedBy?: string | null;
  externalId?: { [key in ApplicationSource]?: string | null } | null;
}

export const RISK_SCORES = [0, 1, 2, 3, 4, 5];
