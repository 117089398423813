import { LoanType, MortgagePosition, SecurityType } from './enums';
import { FinancialLiability } from './liability';
import { Mortgage } from './mortgage';

type LiabilityInput = Pick<
  FinancialLiability,
  | 'securityType'
  | 'description'
  | 'id'
  | 'applicationId'
  | 'balance'
  | 'value'
  | 'liability'
  | 'payoffPaydown'
>;

export interface AggregatesLiabilityInput
  extends Omit<
    LiabilityInput,
    'description' | 'balance' | 'securityType' | 'value' | 'liability' | 'payoffPaydown'
  > {
  description?: string;
  balance?: number;
  securityType?: SecurityType | null;
  value?: number;
  liability?: string;
  payoffPaydown: string;
}

type MortgageInput = Pick<
  Mortgage,
  | 'totalLoanAmount'
  | 'id'
  | 'applicationId'
  | 'type'
  | 'loanType'
  | 'mortgageType'
  | 'securityType'
  | 'mortgageBalance'
>;

export interface AggregatesMortgageInput
  extends Omit<MortgageInput, 'totalLoanAmount' | 'mortgageType' | 'loanType' | 'mortgageBalance'> {
  totalLoanAmount?: number | null;
  loanType?: LoanType | null;
  mortgageType?: MortgagePosition | null;
  mortgageBalance?: number | null;
}

export interface ApplicationAggregateComputationInput {
  mortgages?: AggregatesMortgageInput[] | undefined;
  liabilities?: AggregatesLiabilityInput[] | undefined;
}

export interface NonMortgageLiabilitiesComputationInput {
  nonMortgageCashSecured?: number | null;
  nonMortgageSecured?: number | null;
  nonMortgageUnsecured?: number | null;
}

export interface TotalRetailAggregatesInput {
  mortgageSecured?: number | null;
  bridgeSecured?: number | null;
  nonMortgageLiabilities?: number | null;
}

export interface TotalConnectionInput {
  totalRetail?: number | null;
  businessBankingRelation?: number | null;
}

export type FutureAggregate = Pick<
  ApplicationAggregate,
  | 'id'
  | 'applicationId'
  | 'bridgeSecuredFutureInput'
  | 'computedBridgeSecuredFuture'
  | 'mortgageSecuredFutureInput'
  | 'computedMortgageSecuredFuture'
  | 'nonMortgageLiabilitiesFutureInput'
  | 'computedNonMortgageLiabilitiesFuture'
  | 'totalConnectionFutureInput'
  | 'computedTotalConnectionFuture'
>;

export interface ApplicationAggregate {
  id: string;
  applicationId: string;
  mortgageSecuredCurrentInput?: ApplicationAggregateComputationInput;
  mortgageSecuredFutureInput?: ApplicationAggregateComputationInput;
  computedMortgageSecuredCurrent?: number | null;
  computedMortgageSecuredFuture?: number | null;
  bridgeSecuredCurrentInput?: ApplicationAggregateComputationInput;
  bridgeSecuredFutureInput?: ApplicationAggregateComputationInput;
  computedBridgeSecuredCurrent?: number | null;
  computedBridgeSecuredFuture?: number | null;
  nonMortgageLiabilitiesCurrentInput?: NonMortgageLiabilitiesComputationInput;
  nonMortgageLiabilitiesFutureInput?: NonMortgageLiabilitiesComputationInput;
  computedNonMortgageLiabilitiesCurrent?: number | null;
  computedNonMortgageLiabilitiesFuture?: number | null;
  nonMortgageCashSecuredCurrentInput?: ApplicationAggregateComputationInput;
  nonMortgageCashSecuredFutureInput?: ApplicationAggregateComputationInput;
  computedNonMortgageCashSecuredCurrent?: number | null;
  computedNonMortgageCashSecuredFuture?: number | null;
  nonMortgageSecuredCurrentInput?: ApplicationAggregateComputationInput;
  nonMortgageSecuredFutureInput?: ApplicationAggregateComputationInput;
  computedNonMortgageSecuredCurrent?: number | null;
  computedNonMortgageSecuredFuture?: number | null;
  nonMortgageUnsecuredCurrentInput?: ApplicationAggregateComputationInput;
  nonMortgageUnsecuredFutureInput?: ApplicationAggregateComputationInput;
  computedNonMortgageUnsecuredCurrent?: number | null;
  computedNonMortgageUnsecuredFuture?: number | null;
  totalConnectionCurrentInput?: TotalConnectionInput;
  totalConnectionFutureInput?: TotalConnectionInput;
  computedTotalConnectionCurrent?: number | null;
  computedTotalConnectionFuture?: number | null;
  totalRetailAggregatesCurrentInput?: TotalRetailAggregatesInput;
  totalRetailAggregatesFutureInput?: TotalRetailAggregatesInput;
  computedRetailAggregatesCurrent?: number | null;
  computedRetailAggregatesFuture?: number | null;
  businessBankingRelationCurrent?: number | null;
  businessBankingRelationFuture?: number | null;
}

export enum ApplicationAggregateKey {
  COMPUTED_MORTGAGE_SECURED_CURRENT = 'computedMortgageSecuredCurrent',
  COMPUTED_MORTGAGE_SECURED_FUTURE = 'computedMortgageSecuredFuture',
  COMPUTED_BRIDGE_SECURED_CURRENT = 'computedBridgeSecuredCurrent',
  COMPUTED_BRIDGE_SECURED_FUTURE = 'computedBridgeSecuredFuture',
  COMPUTED_NON_MORTGAGE_LIABILITIES_CURRENT = 'computedNonMortgageLiabilitiesCurrent',
  COMPUTED_NON_MORTGAGE_LIABILITIES_FUTURE = 'computedNonMortgageLiabilitiesFuture',
  COMPUTED_NON_MORTGAGE_CASH_SECURED_CURRENT = 'computedNonMortgageCashSecuredCurrent',
  COMPUTED_NON_MORTGAGE_CASH_SECURED_FUTURE = 'computedNonMortgageCashSecuredFuture',
  COMPUTED_NON_MORTGAGE_SECURED_CURRENT = 'computedNonMortgageSecuredCurrent',
  COMPUTED_NON_MORTGAGE_SECURED_FUTURE = 'computedNonMortgageSecuredFuture',
  COMPUTED_NON_MORTGAGE_UNSECURED_CURRENT = 'computedNonMortgageUnsecuredCurrent',
  COMPUTED_NON_MORTGAGE_UNSECURED_FUTURE = 'computedNonMortgageUnsecuredFuture',
  COMPUTED_TOTAL_CONNECTION_CURRENT = 'computedTotalConnectionCurrent',
  COMPUTED_TOTAL_CONNECTION_FUTURE = 'computedTotalConnectionFuture',
  COMPUTED_RETAIL_AGGREGATES_CURRENT = 'computedRetailAggregatesCurrent',
  COMPUTED_RETAIL_AGGREGATES_FUTURE = 'computedRetailAggregatesFuture',
  BUSINESS_BANKING_RELATION_CURRENT = 'businessBankingRelationCurrent',
  BUSINESS_BANKING_RELATION_FUTURE = 'businessBankingRelationFuture',
}
