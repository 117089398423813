import {
  CalculationResult,
  Application,
  MortgageType,
  PropertyType,
  CalculationItemType,
  CalculationAutomationSettings,
  ComputedRatioRateType,
  LoanType,
} from '@fundmoreai/models';
import { FundmoreCalculator } from '..';
import { computeDebt } from './debt.calculator';
import { computeExistingMortgagePayments } from './existing-mortgage-payments.calculator';
import {
  ComputeRentalIncomePropertyInput,
  computeRentalIncomeValues,
} from './rental-offset.calculator';
import { ExistingMortgageForMonthlyCostToCarry } from './gds.calculator';
import { computeAnnualRentalPropertyExpenses } from './property-expenses.calculator';

export function computeTDS(
  monthlyPayment: number | undefined | null,
  primaryProperty: ComputeRentalIncomePropertyInput | undefined,
  otherProperties: ComputeRentalIncomePropertyInput[] | undefined,
  existingMortgages: ExistingMortgageForMonthlyCostToCarry[],
  existingMortgagePayments: number | undefined,
  totalIncomeWithoutPropertyRental: number,
  totalDebt: number,
  principal: number,
  interest: number,
  calculationAutomationSettings: CalculationAutomationSettings,
  computedRatioRateType = ComputedRatioRateType.NET,
  isLOC = false,
): CalculationResult {
  if (calculationAutomationSettings?.ANY_MORTGAGE?.setTDSToZero) {
    return {
      formula: 'TDS is 0 because there are errors on update to P+',
      items: [],
      result: 0,
    };
  }
  let tdsValue = 0;

  const { totalRentalIncome, totalCtc } = computeRentalIncomeValues(
    otherProperties,
    existingMortgages,
    primaryProperty,
    monthlyPayment,
    existingMortgagePayments,
  );

  const totalIncome = totalIncomeWithoutPropertyRental + totalRentalIncome;

  if (totalIncome !== 0) {
    tdsValue = ((totalCtc + totalDebt) / totalIncome) * 100;
  }

  const annuallyExpenses = FundmoreCalculator.computeAnnualExpensesForTDS(primaryProperty);

  tdsValue = Math.round(tdsValue * 100) / 100;
  const data = computeTDSFormula(
    existingMortgagePayments,
    annuallyExpenses,
    primaryProperty,
    totalCtc,
    principal,
    interest,
    totalDebt,
    totalIncome,
    monthlyPayment,
    tdsValue,
    computedRatioRateType,
    isLOC,
  );

  return data;
}

function computeTDSFormula(
  existingMortgagePayments: number | undefined,
  annualExpenses: number,
  property,
  totalCtc: number,
  principal: number,
  interest: number,
  totalDebt: number,
  totalIncome: number,
  monthlyPayment: number | null | undefined,
  tdsValue: number,
  computedRatioRateType: ComputedRatioRateType,
  isLOC = false,
): CalculationResult {
  const paymentPrefixText = (() => {
    switch (computedRatioRateType) {
      case ComputedRatioRateType.NET:
      default:
        return '';
      case ComputedRatioRateType.QUALIFYING:
        return 'Qualifying ';
      case ComputedRatioRateType.BENCHMARK:
        return 'Benchmark ';
    }
  })();
  const monthlyPaymentText = `${paymentPrefixText}${isLOC ? 'Amortized ' : ''}Monthly Payment`;
  const otherExpenses = computeAnnualRentalPropertyExpenses(property);
  const monthlyExpenses = annualExpenses ? annualExpenses / 12 : null;
  return {
    formula:
      '(Principal + Interest + Taxes + Heat + 50% Condo Fees + Other Expenses + Total Debt) / Total Income',
    items: [
      {
        text: 'Existing Mortgage Payments',
        value: existingMortgagePayments?.toString(),
        type: CalculationItemType.CURRENCY,
      },
      { text: 'Total Debt', value: totalDebt?.toString(), type: CalculationItemType.CURRENCY },
      {
        text: 'Total Monthly Cost to Carry',
        value: totalCtc?.toString(),
        type: CalculationItemType.CURRENCY,
        subitems: [
          {
            text: monthlyPaymentText,
            value: monthlyPayment?.toString(),
            type: CalculationItemType.CURRENCY,
            subitems: [
              {
                text: 'Principal',
                value: principal?.toString(),
                type: CalculationItemType.CURRENCY,
              },
              {
                text: 'Interest',
                value: interest?.toString(),
                type: CalculationItemType.CURRENCY,
              },
            ],
          },
          {
            text: 'Total Monthly Expenses',
            value: monthlyExpenses?.toString(),
            type: CalculationItemType.CURRENCY,
            subitems: [
              {
                text: 'Total Taxes (Annually)',
                value: property?.includeAnnualTaxesInTDS ? property?.annualTaxes?.toString() : null,
                type: CalculationItemType.CURRENCY,
              },
              {
                text: 'Total Heating Cost (Annually)',
                value: property?.includeHeatingCostInTDS ? property?.heatingCost?.toString() : null,
                type: CalculationItemType.CURRENCY,
              },
              {
                text: 'Total Condo Fees (Annually)',
                value: property?.includeCondoFeesInTDS ? property?.condoFees?.toString() : null,
                type: CalculationItemType.CURRENCY,
              },
              {
                text: 'Others (Annually)',
                value: otherExpenses?.toString(),
                type: CalculationItemType.CURRENCY,
              },
            ],
          },
        ],
      },
      { text: 'Total Income', value: totalIncome?.toString(), type: CalculationItemType.CURRENCY },
    ],
    result: tdsValue,
  };
}

export function computeTDSFromApplication(
  application: Application,
  calculationAutomationSettings: CalculationAutomationSettings,
) {
  const primaryProperty = application.Properties.find((x) => x.type == PropertyType.PRIMARY);
  const existingMortgagesOnPrimaryProperty = application.Mortgages.filter(
    (x) => x.type == MortgageType.EXISTING && x.propertyId == primaryProperty?.id,
  );
  const existingMortgages = application.Mortgages.filter((x) => x.type == MortgageType.EXISTING);
  // TODO consider all requested mortgages for rate matrix?
  const requestedMortgage = application.Mortgages.find((x) => x.type == MortgageType.REQUESTED);
  const totalLoanAmount = FundmoreCalculator.computeTotalLoanAmountFromApplication(application);
  const otherProperties = application.Properties.filter((x) => x.type == PropertyType.OTHER);
  const isLOC =
    requestedMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
    requestedMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX;

  const mortgagePayments = isLOC
    ? requestedMortgage.amortizedMonthlyPayment
    : !calculationAutomationSettings ||
      !requestedMortgage?.id ||
      !calculationAutomationSettings[requestedMortgage?.id]?.isMonthlyPaymentDisabled
    ? FundmoreCalculator.computeMortgageMonthlyPayment(
        requestedMortgage,
        totalLoanAmount,
        requestedMortgage?.netRate,
      )
    : requestedMortgage?.monthlyPayment;
  const totalIncomes = FundmoreCalculator.computeTotalMonthlyIncomeWithoutPropertyRentalIncome(
    application?.applicants,
    application?.OtherIncomes,
  );
  const existingMortgagePayments = computeExistingMortgagePayments(
    existingMortgagesOnPrimaryProperty,
  );
  const debt = computeDebt(application.FinancialLiabilities, application.applicants);
  const { interest, principal } = FundmoreCalculator.computePrincipalAndInterest(requestedMortgage);

  const tds = computeTDS(
    mortgagePayments,
    primaryProperty,
    otherProperties,
    existingMortgages,
    existingMortgagePayments,
    totalIncomes,
    debt,
    principal,
    interest,
    calculationAutomationSettings,
  );
  return tds;
}
