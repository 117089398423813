import { Application, Fee, MortgageType, PropertyType } from '@fundmoreai/models';
import { FundmoreCalculator } from '..';

export type ComputeCappedFeesInput = Pick<
  Fee,
  'amount' | 'capFeesPercentage' | 'subtractFromPrincipal'
>;

export function computeCappedFees(
  fees: ComputeCappedFeesInput[],
  capFeesMaxPercentage: number | undefined,
): number {
  let totalFees = 0.0;

  for (const fee of fees) {
    let capFeesPercentage: number | null | undefined = fee.capFeesPercentage;
    if (!capFeesPercentage) {
      capFeesPercentage = capFeesMaxPercentage;
    }
    if (fee.amount && capFeesPercentage && fee.subtractFromPrincipal === false) {
      totalFees += (fee.amount * capFeesPercentage) / 100;
    }
  }
  return totalFees;
}

export function computeCapFeesMaxPercentage(
  fees: Fee[],
  totalLoanAmount?: number,
  existingMortgageAmount?: number,
  primaryPropertyValue?: number | null,
): number | undefined {
  if (!primaryPropertyValue || !totalLoanAmount) {
    return undefined;
  }
  const capFeesMaxLTV = 0.8;

  let capFeesMax = capFeesMaxLTV * primaryPropertyValue - totalLoanAmount;
  if (existingMortgageAmount) {
    capFeesMax -= existingMortgageAmount;
  }
  const feesToCap = fees.filter((f) => f.subtractFromPrincipal === false);
  const totalFees = FundmoreCalculator.computeTotalFees(feesToCap);

  const percentage = (capFeesMax / totalFees) * 100;

  if (percentage < 0 || isNaN(percentage)) {
    return 0;
  }
  if (percentage > 100) {
    return 100;
  }

  return percentage;
}

export function computeCapFeesMaxPercentageFromApplication(
  application: Application,
): number | undefined {
  const fees = application.Fees;
  const existingMortgages = application.Mortgages.filter((m) => m.type == MortgageType.EXISTING);
  const primaryProperty = application.Properties.find((p) => p.type == PropertyType.PRIMARY);
  const totalLoanAmountWithoutCapFees =
    FundmoreCalculator.computeTotalLoanAmountWithoutCapFeesFromApplication(application);
  const existingMortgageAmount =
    FundmoreCalculator.computeExistingMortgageAmount(existingMortgages);
  const primaryPropertyValue = FundmoreCalculator.computePropertyValue({
    property: primaryProperty,
  });

  return FundmoreCalculator.computeCapFeesMaxPercentage(
    fees,
    totalLoanAmountWithoutCapFees,
    existingMortgageAmount,
    primaryPropertyValue,
  );
}

export function computeCappedFeesFromApplication(application: Application): number | undefined {
  const fees = application.Fees;
  const capFeesMaxPercentage =
    FundmoreCalculator.computeCapFeesMaxPercentageFromApplication(application);

  return FundmoreCalculator.computeCappedFees(fees, capFeesMaxPercentage);
}
