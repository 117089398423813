import { Injectable } from '@angular/core';
import { FundmoreCalculator } from '@fundmoreai/calculator';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  FlipDefaultsCostItemType,
  FlipType,
  FlipPropertyProvince,
  Property,
} from '@fundmoreai/models';
import { debounceTime, finalize, map, switchMap, tap } from 'rxjs/operators';
import {
  Flip,
  FlipCostOperating,
  FlipAnalysisModel,
  DownPayment,
  Mortgage,
  FlipHumanizedType,
} from '../../shared';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { LoadingEnd, LoadingStart, LoadingState } from '../../core/loading.state';
import { FlipRenovationScheduleState } from './flip-renovation.state';
import { FlipService } from './flip.service';
import { FlipCostState } from './flipCost.state';
import { PropertiesState } from '../properties.state';
import {
  FlipAddApplicationDownPayments,
  FlipAddFlipDownPayment,
  FlipComputeAnalysis,
  FlipDeleteDownPayment,
  FlipGetApplicationFlip,
  FlipHighlightInterestCostCalcValue,
  FlipResetFlipDefaults,
  FlipSetDownPayments,
  FlipSetEnableFlipModule,
  FlipSetFlipType,
  FlipSetFlipValidMsg,
  FlipSetSelectedMonthIndex,
  FlipUpdateDetails,
  FlipUpdateDownPayment,
  FlipUpdateMortgageFields,
  FlipUpdatePropertyFields,
  SetFlipCosts,
  SetRenovationSchedules,
  UpdateFlipCost,
} from './flip.state.action';
import { MortgagesV2State } from '../mortgages-v2/mortgages-v2.state';
import { UpdateRequestedMortgage } from '../mortgages-v2/mortgages-v2.actions';
import { UpdateProperty } from '../properties.actions';
import { AddBulkDownPaymentsAndComputeFlip, UpsertDownPayment } from '../downpayments.actions';
import { ApplicationResetState } from '../../shared/state.model';

export class FlipComputeAnalysisOnDownPaymentChange {
  static readonly type = '@flipState.computeAnalysisOnDownPaymentChange';
  constructor(public downPayments: DownPayment[], public downPaymentsTotal?: number) {}
}

const FLIP_DOWN_PAYMENT_PREFIX = 'FLIP-';

const { FlipAnalysisCalculator } = FundmoreCalculator;
interface FlipStateModel {
  loading: boolean;
  applicationId: string | undefined;
  enableFlipModule: boolean;
  flip: Flip | undefined;
  analysisSelectedMonthIndex: number;
  flipValidMessage: string | undefined;
  highlightInterestCostCalcValue: boolean;
  flipType: FlipType;
}

const defaults = {
  applicationId: undefined,
  enableFlipModule: false,
  loading: false,
  analysisSelectedMonthIndex: 0,
  flipValidMessage: undefined,
  highlightInterestCostCalcValue: false,
  flipType: FlipType.SALES_PRICE_FOCUSED,
  flip: undefined,
};
@State<FlipStateModel>({
  name: 'flipState',
  defaults: { ...defaults },
  children: [FlipCostState, FlipRenovationScheduleState],
})
@Injectable()
export class FlipState {
  constructor(private flipService: FlipService, private store: Store) {}

  @Action(ApplicationResetState)
  reset(ctx: StateContext<FlipStateModel>) {
    ctx.patchState({ ...defaults });
  }

  public loading$: Observable<boolean> = this.store.select(LoadingState.isLoading('FlipState'));

  @Selector()
  static flipState(state: FlipStateModel) {
    return state;
  }

  @Selector()
  static flipModuleEnabled(state: FlipStateModel) {
    return state.enableFlipModule;
  }

  @Selector()
  static flip(state: FlipStateModel) {
    return state.flip;
  }
  public flip$: Observable<Flip | undefined> = this.store.select(FlipState.flip);

  private analysis$: Observable<FlipAnalysisModel | undefined> = this.flip$.pipe(
    map((flip) => flip?.analysisResult),
  );
  get analysis(): FlipAnalysisModel | undefined {
    return this.store.selectSnapshot(FlipState.flip)?.analysisResult;
  }

  public flipAnalysisFlipDownPayments$: Observable<DownPayment[]> = this.analysis$.pipe(
    map((flipAnalysisModel) => {
      if (!flipAnalysisModel?.downPayments) {
        return [];
      }
      return flipAnalysisModel.downPayments.filter((downPayment) =>
        downPayment.id.startsWith(FLIP_DOWN_PAYMENT_PREFIX),
      );
    }),
  );

  @Selector()
  static flipValidMessage(state: FlipStateModel) {
    return state.flipValidMessage;
  }
  public flipValidMessage$: Observable<string | undefined> = this.store.select(
    FlipState.flipValidMessage,
  );

  @Selector()
  static highlightInterestCostCalcValue(state: FlipStateModel) {
    return state.highlightInterestCostCalcValue;
  }
  public highlightInterestCostCalcValue$: Observable<boolean> = this.store.select(
    FlipState.highlightInterestCostCalcValue,
  );

  public get flipAnalysisFlipDownPayments() {
    const flip = this.store.selectSnapshot(FlipState.flip);
    if (!flip) {
      return [];
    }
    return flip.analysisResult.downPayments.filter((downPayment) =>
      downPayment.id.startsWith(FLIP_DOWN_PAYMENT_PREFIX),
    );
  }

  public flipAnalysisLoanDetailsDownPayments$: Observable<DownPayment[]> = this.analysis$.pipe(
    map((flipAnalysisModel) => {
      if (!flipAnalysisModel?.downPayments) {
        return [];
      }
      return flipAnalysisModel.downPayments.filter(
        (downPayment) => !downPayment.id.startsWith(FLIP_DOWN_PAYMENT_PREFIX),
      );
    }),
  );

  public get flipAnalysisLoanDetailsDownPayments() {
    const flip = this.store.selectSnapshot(FlipState.flip);
    if (!flip) {
      return [];
    }
    return flip.analysisResult.downPayments.filter(
      (downPayment) => !downPayment.id.startsWith(FLIP_DOWN_PAYMENT_PREFIX),
    );
  }

  public flipCostOperating$: Observable<FlipCostOperating[]> = combineLatest([
    this.store.select(FlipCostState.flipOperatingCosts),
    this.store.select(FlipCostState.flipOperatingCostsTotal),
    this.store.select(FlipRenovationScheduleState.renovationSchedulesTotalMonths),
  ]).pipe(
    map(([costs, totalCost, totalMonths]) => {
      const flipOperatingCosts: FlipCostOperating[] = costs.map((cost) => {
        const projectTotal = FundmoreCalculator.calculateFlipCostProjectTotal(cost, totalMonths);
        const percentageOfTotal = FundmoreCalculator.calculateFlipCostPercentageOfTotal(
          cost,
          totalCost,
        );
        return { ...cost, projectTotal, percentageOfTotal };
      });
      return flipOperatingCosts;
    }),
  );
  public flipCostOperatingProjectTotal$: Observable<number> = this.flipCostOperating$.pipe(
    map((costs) => {
      const total = FundmoreCalculator.calculateFlipCostTotalProjectTotal(costs);
      return total;
    }),
  );

  updatedCostAndScheduleAnalysisInput$ = combineLatest([
    this.store.select(FlipCostState.flipOperatingCostsTotal),
    this.store.select(FlipCostState.flipPurchaseCostsTotal),
    this.store.select(FlipCostState.flipSellingCostsTotal),
    this.store.select(FlipRenovationScheduleState.renovationSchedulesTotalMonths),
  ]).pipe(
    debounceTime(200),
    map(([totalOperatingCost, purchaseCost, sellingCost, renovationScheduleTotalMonths]) => {
      const flip = this.store.selectSnapshot(FlipState.flip);
      if (!flip) {
        return undefined;
      }
      const analysisModel: FlipAnalysisModel = {
        ...flip.analysisResult,
        totalOperatingCost,
        totalPurchaseCost: purchaseCost,
        totalSellingCost: sellingCost,
        renovationScheduleTotalMonths,
      };
      return analysisModel;
    }),
  );

  public flipFinancingCostsTotal$: Observable<number> = combineLatest([
    this.store.select(FlipState.flipState),
    this.store.select(FlipRenovationScheduleState.renovationSchedulesTotalMonths),
  ]).pipe(
    map(([flipState, totalMonths]) => {
      if (!flipState.flip) {
        return 0;
      }
      const primaryProperty = this.store.selectSnapshot(PropertiesState.primaryProperty);

      const totalInterestCosts = primaryProperty
        ? FundmoreCalculator.computeTotalInterestCosts(
            totalMonths,
            flipState.flip.interestCostByMonth,
          )
        : 0;
      return totalInterestCosts + flipState.flip?.financingFeeAmount;
    }),
  );

  humanisedFlipAnalysisType$ = this.analysis$.pipe(
    map((analysis) => {
      if (!analysis) {
        return;
      }
      if (analysis.type === FlipType.PROFIT_FOCUSED) {
        return FlipHumanizedType.PROFIT_FOCUSED;
      }
      if (analysis.type === FlipType.SALES_PRICE_FOCUSED) {
        return FlipHumanizedType.SALES_PRICE_FOCUSED;
      }
      return undefined;
    }),
  );

  @Action(FlipSetFlipValidMsg)
  private setFlipValidMsg(ctx: StateContext<FlipStateModel>, action: FlipSetFlipValidMsg) {
    ctx.patchState({
      flipValidMessage: action.message,
    });
  }

  @Action(FlipHighlightInterestCostCalcValue)
  private highlightInterestCostCalcValue(
    ctx: StateContext<FlipStateModel>,
    action: FlipHighlightInterestCostCalcValue,
  ) {
    ctx.patchState({
      highlightInterestCostCalcValue: action.highlight,
    });
  }

  @Action(FlipSetSelectedMonthIndex)
  private setSelectedMonthIndex(
    ctx: StateContext<FlipStateModel>,
    action: FlipSetSelectedMonthIndex,
  ) {
    ctx.patchState({
      analysisSelectedMonthIndex: action.index,
    });
  }

  @Selector()
  static selectedMonthIndex(state: FlipStateModel) {
    return state.analysisSelectedMonthIndex;
  }
  public selectedMonthIndex$: Observable<number> = this.store
    .select(FlipState.selectedMonthIndex)
    .pipe(debounceTime(10));

  @Action(FlipGetApplicationFlip)
  private getFlip(ctx: StateContext<FlipStateModel>, action: FlipGetApplicationFlip) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.flipService.getFlipDetails(action.applicationId).pipe(
      switchMap((flip) => {
        ctx.patchState({ flip: flip, applicationId: action.applicationId });

        return this.store.dispatch([
          new SetFlipCosts(!flip ? [] : flip.FlipCosts),
          new SetRenovationSchedules(!flip ? [] : flip.FlipRenovationSchedules),
        ]);
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(FlipUpdateDetails)
  private updateFlipDetails(ctx: StateContext<FlipStateModel>, action: FlipUpdateDetails) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.flipService.updateFlipDetails(action.flipId, action.flipDetails).pipe(
      tap(() => {
        const updatedFlip: Flip = Object.assign(
          {},
          this.store.selectSnapshot(FlipState.flip),
          action.flipDetails,
        );
        ctx.patchState({ flip: updatedFlip });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(FlipComputeAnalysis)
  private recalculateFlipAnalysis(ctx: StateContext<FlipStateModel>, action: FlipComputeAnalysis) {
    const flip = this.store.selectSnapshot(FlipState.flip);
    if (!flip) {
      return of(undefined);
    }
    const analysisResult: FlipAnalysisModel = { ...flip.analysisResult };
    analysisResult.interestCostByMonth = flip.interestCostByMonth;
    analysisResult.interestCostByDay = flip.interestCostByDay;
    analysisResult.financingFeeAmount = flip.financingFeeAmount;
    analysisResult.renovationBudget = flip.renovationBudget;
    analysisResult.afterRepairValue = flip.afterRepairValue;
    const updatedAnalysisModel: FlipAnalysisModel = {
      ...analysisResult,
      ...action.analysisModel,
    };
    const flipAnalysis = FlipAnalysisCalculator.analyseFlip(updatedAnalysisModel);

    return ctx.dispatch(new FlipUpdateDetails(flip.id, { analysisResult: flipAnalysis })).pipe(
      switchMap(() => {
        const { analysisModel } = action;
        if (
          !analysisModel.propertyPurchasePrice ||
          analysisModel.propertyPurchasePrice === analysisResult.propertyPurchasePrice ||
          analysisResult.propertyProvince?.toLowerCase() !== FlipPropertyProvince.ONTARIO
        ) {
          return of(undefined);
        }
        const cost = this.store
          .selectSnapshot(FlipCostState.flipPurchaseCosts)
          .find((cost) => cost.itemType === FlipDefaultsCostItemType.LAND_TRANSFER_TAX_ONTARIO);
        if (!cost) {
          return of(undefined);
        }
        const updatedCost = {
          ...cost,
          amount: FundmoreCalculator.computeOntarioPurchaseLandTransferTax(
            analysisModel.propertyPurchasePrice,
          ),
        };
        return this.store.dispatch(new UpdateFlipCost(updatedCost));
      }),
      switchMap(() => {
        const { analysisModel } = action;
        if (
          !analysisModel.afterRepairValue ||
          analysisModel.afterRepairValue === analysisResult.afterRepairValue ||
          analysisResult.propertyProvince?.toLocaleLowerCase() !== FlipPropertyProvince.ONTARIO
        ) {
          return of(undefined);
        }
        const cost = this.store
          .selectSnapshot(FlipCostState.flipSellingCosts)
          .find((cost) => cost.itemType === FlipDefaultsCostItemType.REAL_ESTATE_AGENT_FEE_ONTARIO);
        if (!cost) {
          return of(undefined);
        }
        const updatedCost = {
          ...cost,
          amount: FundmoreCalculator.computeOntarioSellingRealEstateAgentFee(
            analysisModel.afterRepairValue,
          ),
        };
        return this.store.dispatch(new UpdateFlipCost(updatedCost));
      }),
      switchMap(() => {
        const { analysisModel } = action;
        if (
          !analysisModel.afterRepairValue ||
          analysisModel.afterRepairValue === analysisResult.afterRepairValue ||
          analysisResult.propertyProvince?.toLowerCase() !== FlipPropertyProvince.ALBERTA
        ) {
          return of(undefined);
        }
        const cost = this.store
          .selectSnapshot(FlipCostState.flipSellingCosts)
          .find((cost) => cost.itemType === FlipDefaultsCostItemType.REAL_ESTATE_AGENT_FEE_ALBERTA);
        if (!cost) {
          return of(undefined);
        }
        const updatedCost = {
          ...cost,
          amount: FundmoreCalculator.computeAlbertaSellingRealEstateAgentFee(
            analysisModel.afterRepairValue,
          ),
        };
        return this.store.dispatch(new UpdateFlipCost(updatedCost));
      }),
    );
  }

  @Action(FlipComputeAnalysisOnDownPaymentChange)
  private updateAnalysisDownPayment(
    ctx: StateContext<FlipStateModel>,
    action: FlipComputeAnalysisOnDownPaymentChange,
  ) {
    const flip = this.store.selectSnapshot(FlipState.flip);
    const flipModuleEnabled = this.store.selectSnapshot(FlipState.flipModuleEnabled);
    if (!flip || !flipModuleEnabled) {
      return of(undefined);
    }

    const calculator = new FlipAnalysisCalculator(flip.analysisResult);
    calculator.updateDownPayments(action.downPayments, action.downPaymentsTotal);
    const updatedFlip: Partial<Flip> = { analysisResult: calculator.getModel() };
    return ctx.dispatch(new FlipUpdateDetails(flip.id, updatedFlip));
  }

  @Action(FlipSetDownPayments)
  private setFlipAnalysisDownPayments(
    ctx: StateContext<FlipStateModel>,
    action: FlipSetDownPayments,
  ) {
    const flip = this.store.selectSnapshot(FlipState.flip);
    const flipModuleEnabled = this.store.selectSnapshot(FlipState.flipModuleEnabled);
    if (!flip || !flipModuleEnabled) {
      return of(undefined);
    }

    return ctx.dispatch(
      new FlipComputeAnalysisOnDownPaymentChange(action.downPayments, action.downPaymentsTotal),
    );
  }

  @Action(FlipUpdateDownPayment)
  private updateFlipAnalysisDownPayment(
    ctx: StateContext<FlipStateModel>,
    action: FlipUpdateDownPayment,
  ) {
    const flip = this.store.selectSnapshot(FlipState.flip);
    const flipModuleEnabled = this.store.selectSnapshot(FlipState.flipModuleEnabled);
    if (!flip || !flipModuleEnabled) {
      return of(undefined);
    }
    const downPayments = [...flip.analysisResult.downPayments];
    const downPaymentIndex = downPayments.findIndex(
      (downPayment) => downPayment.id === action.downPaymentId,
    );
    if (downPaymentIndex === -1) {
      return of(undefined);
    }
    let downPaymentsTotal =
      flip.analysisResult.downPaymentsTotal - (downPayments[downPaymentIndex].amount || 0);
    const updatedDownPayment = {
      ...downPayments[downPaymentIndex],
      ...action.downPayment,
      id: action.downPaymentId,
    };
    downPayments[downPaymentIndex] = updatedDownPayment;
    downPaymentsTotal += updatedDownPayment.amount || 0;
    return ctx.dispatch(
      new FlipComputeAnalysisOnDownPaymentChange(downPayments, downPaymentsTotal),
    );
  }

  @Action(FlipAddFlipDownPayment)
  private addFlipAnalysisDownPayment(
    ctx: StateContext<FlipStateModel>,
    action: FlipAddFlipDownPayment,
  ) {
    const flip = this.store.selectSnapshot(FlipState.flip);
    const flipModuleEnabled = this.store.selectSnapshot(FlipState.flipModuleEnabled);
    if (!flip || !flipModuleEnabled) {
      return of(undefined);
    }
    const downPayment = {
      ...action.downPaymentPayload,
      id: `${FLIP_DOWN_PAYMENT_PREFIX}${new Date().getTime()}`,
    } as DownPayment;
    const downPaymentsTotal = flip.analysisResult.downPaymentsTotal + (downPayment.amount || 0);
    const updatedDownPayments = [...flip.analysisResult.downPayments, downPayment];
    return ctx.dispatch(
      new FlipComputeAnalysisOnDownPaymentChange(updatedDownPayments, downPaymentsTotal),
    );
  }

  @Action(FlipDeleteDownPayment)
  private deleteFlipAnalysisDownPayment(
    ctx: StateContext<FlipStateModel>,
    action: FlipDeleteDownPayment,
  ) {
    const flip = this.store.selectSnapshot(FlipState.flip);
    const flipModuleEnabled = this.store.selectSnapshot(FlipState.flipModuleEnabled);
    if (!flip || !flipModuleEnabled) {
      return of(undefined);
    }
    const downPayments = [...flip.analysisResult.downPayments];
    const downPaymentIndex = downPayments.findIndex(
      (downPayment) => downPayment.id === action.downPaymentId,
    );
    if (downPaymentIndex === -1) {
      return of(undefined);
    }
    const downPaymentsTotal =
      flip.analysisResult.downPaymentsTotal - (downPayments[downPaymentIndex].amount || 0);
    downPayments.splice(downPaymentIndex, 1);
    return ctx.dispatch(
      new FlipComputeAnalysisOnDownPaymentChange(downPayments, downPaymentsTotal),
    );
  }

  @Action(FlipAddApplicationDownPayments)
  private addApplicationDownPayment(
    ctx: StateContext<FlipStateModel>,
    action: FlipAddApplicationDownPayments,
  ) {
    const flipModuleEnabled = this.store.selectSnapshot(FlipState.flipModuleEnabled);
    if (!this.analysis || !flipModuleEnabled) {
      return of(undefined);
    }
    const updatedDownPayment = [...this.analysis.downPayments, ...action.downPayments];
    const updatedDownPaymentTotal = updatedDownPayment.reduce(
      (sum, downPayment) => sum + (downPayment.amount ?? 0),
      0,
    );
    return ctx.dispatch(
      new FlipComputeAnalysis({
        downPayments: updatedDownPayment,
        downPaymentsTotal: updatedDownPaymentTotal,
      }),
    );
  }

  public updateLoanDetails(applicationId: string): Observable<(DownPayment[] | Mortgage | void)[]> {
    const updateLoanDetailsObservables: Observable<DownPayment[] | Mortgage | void>[] = [];
    const flip = this.store.selectSnapshot(FlipState.flip);
    if (!flip) {
      return of([]);
    }
    const { analysisResult } = flip;
    this.changePrimaryPropertyInfo(applicationId, {
      purchasePrice: analysisResult.propertyPurchasePrice,
      estimatedValue: analysisResult.afterRepairValue,
    });
    updateLoanDetailsObservables.push(...this.updateApplicationDownPaymentsWithFlip(applicationId));
    updateLoanDetailsObservables.push(
      this.changeRequestedMortgageInfo(applicationId, {
        netRate: analysisResult.netRate,
        loanAmount: analysisResult.loanAmount,
      }),
    );

    return combineLatest(updateLoanDetailsObservables);
  }

  private updateApplicationDownPaymentsWithFlip(applicationId: string) {
    const observables: Observable<DownPayment[] | void>[] = [];
    const newDownPayment$ = this.store.dispatch(
      new AddBulkDownPaymentsAndComputeFlip(applicationId, this.flipAnalysisFlipDownPayments),
    );
    observables.push(newDownPayment$);
    for (const loanDetailsDownPayment of this.flipAnalysisLoanDetailsDownPayments) {
      observables.push(
        this.store.dispatch(new UpsertDownPayment(applicationId, loanDetailsDownPayment)),
      );
    }

    return observables;
  }

  private changePrimaryPropertyInfo(applicationId: string, value: Partial<Property>) {
    const primaryProperty = this.store.selectSnapshot(PropertiesState.primaryProperty);

    this.store.dispatch(new UpdateProperty(applicationId, { ...value, id: primaryProperty?.id }));
  }

  private changeRequestedMortgageInfo(applicationId: string, value: Partial<Mortgage>) {
    // TODO flip not supported in combo application for now
    const requestedMortgage = this.store.selectSnapshot(
      MortgagesV2State.firstFoundRequestedMortgage,
    );

    if (!requestedMortgage) {
      return EMPTY;
    }

    return this.store.dispatch(
      new UpdateRequestedMortgage(applicationId, { ...value, id: requestedMortgage.id }),
    );
  }

  @Action(FlipSetFlipType)
  private updateFlipType(ctx: StateContext<FlipStateModel>, action: FlipSetFlipType) {
    const flipType =
      action.filterNumber === 0 ? FlipType.SALES_PRICE_FOCUSED : FlipType.PROFIT_FOCUSED;
    return ctx.dispatch(new FlipComputeAnalysis({ type: flipType }));
  }

  @Action(FlipResetFlipDefaults)
  private resetFlipDefaults(ctx: StateContext<FlipStateModel>, action: FlipResetFlipDefaults) {
    const flip = this.store.selectSnapshot(FlipState.flip);
    const flipModuleEnabled = this.store.selectSnapshot(FlipState.flipModuleEnabled);
    if (!flip || !flipModuleEnabled) {
      return EMPTY;
    }
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.flipService.resetFlipDefaults(flip.id, action.province).pipe(
      switchMap((flip) => {
        ctx.patchState({ flip: flip });

        return this.store.dispatch([
          new SetFlipCosts(flip.FlipCosts),
          new SetRenovationSchedules(flip.FlipRenovationSchedules),
        ]);
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(FlipSetEnableFlipModule)
  private setEnableFlipModule(ctx: StateContext<FlipStateModel>, action: FlipSetEnableFlipModule) {
    ctx.patchState({ enableFlipModule: action.enabled });
  }

  @Action(FlipUpdateMortgageFields)
  private updateFlipMortgageFields(
    ctx: StateContext<FlipStateModel>,
    action: FlipUpdateMortgageFields,
  ) {
    const flipSnap = this.store.selectSnapshot(FlipState.flip);
    const flipModuleEnabled = this.store.selectSnapshot(FlipState.flipModuleEnabled);
    if (
      !flipSnap ||
      !flipModuleEnabled ||
      (flipSnap.analysisResult.netRate === action.mortgage.netRate &&
        flipSnap.analysisResult.loanAmount === action.mortgage.loanAmount)
    ) {
      return;
    }
    const interestCostByDay = FundmoreCalculator.computeInterestCostByDay(
      action.mortgage.loanAmount,
      action.mortgage.netRate,
      flipSnap.financingFeeAmount,
    );
    const interestCostByDayRounded = Math.round(interestCostByDay * 100) / 100;
    const interestCostByMonthRounded = FundmoreCalculator.computeMonthlyInterestCost(
      action.mortgage.loanAmount,
      action.mortgage.netRate,
      flipSnap.financingFeeAmount,
    );
    const flipDetails: Partial<Flip> = {
      interestCostByDay: interestCostByDayRounded,
      interestCostByMonth: interestCostByMonthRounded,
    };
    const flipAnalysisInput: Partial<FlipAnalysisModel> = {
      interestCostByDay: interestCostByDayRounded,
      interestCostByMonth: interestCostByMonthRounded,
      netRate: action.mortgage.netRate !== null ? action.mortgage.netRate : undefined,
    };

    if (action.mortgage.loanAmount) {
      flipDetails.financingFeeAmount = FundmoreCalculator.computeAmountFromPercentage(
        action.mortgage.loanAmount,
        flipSnap.financingFeePercentage,
      );
      flipAnalysisInput.financingFeeAmount = flipDetails.financingFeeAmount;
      flipAnalysisInput.loanAmount = action.mortgage.loanAmount;
    }

    ctx
      .dispatch(new FlipUpdateDetails(flipSnap.id, flipDetails))
      .pipe(switchMap(() => ctx.dispatch(new FlipComputeAnalysis(flipAnalysisInput))))
      .subscribe();
  }

  @Action(FlipUpdatePropertyFields)
  private updateFlipPropertyFields(
    ctx: StateContext<FlipStateModel>,
    action: FlipUpdatePropertyFields,
  ) {
    const flipSnap = this.store.selectSnapshot(FlipState.flip);
    const flipModuleEnabled = this.store.selectSnapshot(FlipState.flipModuleEnabled);
    if (!flipSnap || !flipModuleEnabled) {
      return;
    }
    const flipAnalysisInput: Partial<FlipAnalysisModel> = {};
    if (
      action.property.purchasePrice &&
      flipSnap.analysisResult.propertyPurchasePrice !== action.property.purchasePrice
    ) {
      flipAnalysisInput.propertyPurchasePrice = action.property.purchasePrice;
    }

    if (
      action.property.estimatedValue &&
      flipSnap.analysisResult.afterRepairValue !== action.property.estimatedValue
    ) {
      flipAnalysisInput.afterRepairValue = action.property.estimatedValue;
    }

    if (Object.keys(flipAnalysisInput).length === 0) {
      return;
    }

    return ctx
      .dispatch(
        new FlipUpdateDetails(flipSnap.id, {
          afterRepairValue: flipAnalysisInput.afterRepairValue,
        }),
      )
      .pipe(switchMap(() => ctx.dispatch(new FlipComputeAnalysis(flipAnalysisInput))));
  }
}
