import { FundmoreCalculator } from '@fundmoreai/calculator';
import { Mortgage } from '@fundmoreai/models';
import { inject, injectable } from 'tsyringe';
import { BlendedRateRenewalCalculationModel } from '../models/blended-rate-renewal.model';
import { TYPES } from '../container/types';
import { RemainingMortgageTermCalculator } from './remaining-mortgage-term.calculator';

export interface IBlendedRateRenewalCalculator {
  getBlendRate(): number | null;
}

@injectable()
export class BlendedRateRenewalCalculator implements IBlendedRateRenewalCalculator {
  private currentMortgage: Mortgage;
  private newMortgage: Mortgage;

  constructor(
    @inject(BlendedRateRenewalCalculationModel)
    blendedRateRenewalCalculationModel: BlendedRateRenewalCalculationModel,
    @inject(TYPES.IRemainingMortgageTermCalculator)
    public mortgageTermCalculator: RemainingMortgageTermCalculator,
  ) {
    this.currentMortgage = blendedRateRenewalCalculationModel.currentMortgage;
    this.newMortgage = blendedRateRenewalCalculationModel.newMortgage;
  }

  getBlendRate(): number | null {
    //calculate proportion of new
    const existingMortgageProportionOfNew = this.getExistingMortgageProportionOfNew();
    if (!existingMortgageProportionOfNew) {
      return null;
    }
    const newMortgageIncreaseProportionOfNew = 100 - existingMortgageProportionOfNew;

    const existingMortgageInterestRate = this.currentMortgage.netRate;
    const newMortgageIncreaseInterestRate = this.newMortgage.netRate;

    if (existingMortgageInterestRate == null || newMortgageIncreaseInterestRate === null) {
      return null;
    }

    //calculate blended rate after mortgage increase
    const existingMortgageBlendedRate = this.computePercentage(
      existingMortgageProportionOfNew,
      existingMortgageInterestRate,
    );
    const newMortgageIncreaseBlendedRate = this.computePercentage(
      newMortgageIncreaseProportionOfNew,
      newMortgageIncreaseInterestRate,
    );
    const blendedRateAfterMortgageIncrease =
      existingMortgageBlendedRate + newMortgageIncreaseBlendedRate;

    //use blended rate unless the term is extended
    if (!this.newMortgage.termMonths) {
      return blendedRateAfterMortgageIncrease;
    }

    //calculate impact of extended term
    //proportion of new
    const existingRemainingTermProportionOfNew =
      this.getExistingMortgageRemainingTermProportionOfNew();

    if (existingRemainingTermProportionOfNew === undefined) {
      return null;
    }

    const mortgageIncreaseTermProportionOfNew = 100 - existingRemainingTermProportionOfNew;

    //calculate blended rate after extended term
    const existingMortgageBlendedRateAfterExtendedTerm = this.computePercentage(
      existingRemainingTermProportionOfNew,
      blendedRateAfterMortgageIncrease,
    );
    const newMortgageBlendedRateAfterExtendedTerm = this.computePercentage(
      mortgageIncreaseTermProportionOfNew,
      newMortgageIncreaseInterestRate,
    );

    const blendedRateAfterExtendedTerm =
      existingMortgageBlendedRateAfterExtendedTerm + newMortgageBlendedRateAfterExtendedTerm;

    return blendedRateAfterExtendedTerm;
  }

  private getExistingMortgageProportionOfNew() {
    let existingMortgageBalance = this.currentMortgage.mortgageBalance;
    const newMortgageAmount = this.newMortgage.loanAmount;

    if (!existingMortgageBalance) {
      return null;
    }
    if (!newMortgageAmount) {
      return null;
    }
    if (existingMortgageBalance > newMortgageAmount) {
      existingMortgageBalance = newMortgageAmount;
    }

    return this.computeDifferenceInPercentage(existingMortgageBalance, newMortgageAmount);
  }

  private getExistingMortgageRemainingTermProportionOfNew() {
    const maturityDate =
      this.currentMortgage.maturityDate ||
      FundmoreCalculator.computeMaturityDate(this.currentMortgage);

    if (!maturityDate) {
      return;
    }

    //include start and end month as separate months
    const existingMortgageRemainingTerm =
      this.mortgageTermCalculator.getRemainingMortgageTerm(maturityDate);

    const newTerm = this.newMortgage.termMonths;
    return newTerm
      ? this.computeDifferenceInPercentage(existingMortgageRemainingTerm, newTerm)
      : 100;
  }

  private computeDifferenceInPercentage = (num1: number, num2: number) => (num1 / num2) * 100;

  private computePercentage = (num1: number, num2: number) => (num1 / 100) * num2;
}
