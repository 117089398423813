import {
  ExcludeLiabilityFromCalculationReasonType,
  FinancialLiabilityRecordTypeState,
  FinancialLiabilitySource,
  LiabilityType,
  PaymentFrequency,
  PayoffPaydownType,
  PayoffType,
  SecurityType,
} from './enums';

export interface ApplicantFinancialLiability {
  id: string;
  applicationId?: string;
  applicantId: string | null;
  financialLiabilityId: string | null;
}

export interface FinancialLiability {
  id: string;
  tenantId: string;
  applicationId: string;
  payoff: PayoffType;
  payoffPaydown: PayoffPaydownType;
  paydownValue?: number | null;
  paydownValueLimit?: number | null;
  paydownBalance?: number | null;
  paydownBalanceLimit?: number | null;
  createdAt: string;
  isNew?: boolean;
  externalSequenceNumber?: number | null;
  externalAccountId?: string | null;
  excludeFromCalculation?: ExcludeLiabilityFromCalculationReasonType | null;
  securityType?: SecurityType | null;
  financialInstitution?: string | null;
  paymentFrequency?: PaymentFrequency | null;
  source: FinancialLiabilitySource | null;
  description: string | null;
  liability: LiabilityType | CustomEQLiabilityTypes | null;
  ApplicantFinancialLiabilities: ApplicantFinancialLiability[];
  value: number | null;
  balance: number | null;
  monthlyPayment: number | null;
  crHasWrittenOff: boolean | null;
  crStatus: string | null;
  FinancialLiabilityRecordTypes: FinancialLiabilityRecordType[];
}

export interface FinancialLiabilityRecordType {
  id?: string;
  recordType?: string | null;
  type: FinancialLiabilityRecordTypeState | null;
  reportedDate?: string | null;
}

export enum FinancialLiabilityKey {
  PAYOFF = 'payoff',
  PAYOFF_PAYDOWN = 'payoffPaydown',
  PAYDOWN_VALUE = 'paydownValue',
  PAYDOWN_VALUE_LIMIT = 'paydownValueLimit',
  PAYDOWN_BALANCE = 'paydownBalance',
  PAYDOWN_BALANCE_LIMIT = 'paydownBalanceLimit',
  DESCRIPTION = 'description',
  LIABILITY = 'liability',
  SOURCE = 'source',
  CURRENT_TYPE = 'currentRecordType',
  VALUE = 'value',
  BALANCE = 'balance',
  MONTHLY_PAYMENT = 'monthlyPayment',
  APPLICANT_ID = 'applicantId',
  APPLICANTS = 'applicants',
  EXCLUDE_FROM_CALCULATION = 'excludeFromCalculation',
  SECURITY_TYPE = 'securityType',
  FINANCIAL_INSTITUTION = 'financialInstitution',
  PAYMENT_FREQUENCY = 'paymentFrequency',
}

export enum CustomEQLiabilityTypes {
  PROPERTY = 'd89bf0aa-2f0a-48f5-aa5c-1ecfe4a63223',
  PROPERTY_RENTAL = 'c7b96e47-07e6-43a6-9861-e0b6c02d1fc2',
  PROPERTY_TAXES = '7d25988e-720b-42dd-b2e0-1c0a0e5a0ec2',
  PROPERTY_HEATING = '6344ddcb-4a50-44d1-8723-5c1e6d6bfb6b',
  PROPERTY_CONDO_FEES = '6f96c8e2-1a2d-4a1a-b6f5-28de15ca9e12',
  PROPERTY_TAXES_RENTAL = 'ea7a04f1-1d9c-4c68-9ac1-d23a678f0f2f',
  PROPERTY_HEATING_RENTAL = '486e1887-98c1-4a02-9de0-731b95c493df',
  PROPERTY_CONDO_FEES_RENTAL = '9c9be44e-cd0f-4ee9-9c0a-75d8fda7f086',
  PROPERTY_GENERAL_RENTAL = '9fc3d0b1-c1a3-419f-82fc-5bfa4f298a2e',
  PROPERTY_HYDRO_RENTAL = '3aef4c6a-3470-4e96-9d8b-735c8355452f',
  PROPERTY_INSURANCE_RENTAL = '0abfa773-7dd1-477a-9784-1f7c19e47d62',
  PROPERTY_INTEREST_RENTAL = '4b82eb48-45da-4955-b384-d497b1c6b48d',
  PROPERTY_MANAGEMENT_RENTAL = '6a0c52d9-45f6-47f7-9063-aa1c13e3a79f',
  PROPERTY_REPAIRS_RENTAL = 'e462d07a-1bf1-4a21-8235-2f8797db7d5a',
}
