import { MMSDealStatus, MMSTransactionStatus } from './enums';
import {
  MMSDisbursement,
  MMSFieldMetadata,
  MMSMilestone,
  MMSNote,
  MMSRequest,
  MMSResponse,
  MMSUpdateResponse,
} from './models';

export interface FCTMMSResponse {
  id: string;
  tenantId: string;
  applicationId: string;
  fctURN: string;
  request?: MMSRequest;
  response?: MMSResponse | MMSUpdateResponse;
  notes?: MMSNote[];
  disbursements?: MMSDisbursement[];
  milestones?: MMSMilestone[];
  dealStatus?: MMSDealStatus;
  transactionStatus?: MMSTransactionStatus;
  requestMetadata?: MMSFieldMetadata[];
  responseMetadata?: MMSFieldMetadata[];
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}
export interface MMSCreateRequest {
  applicationId: string;
  propertyId: string;
  mortgageId: string;
  bridgeMortgageId?: string | null;
  bridgePropertyId?: string | null;
  lawyerId: string;
  productId?: string;
  lenderRemarks?: string | null;
  registeredAmount?: number;
  metadata?: MMSFieldMetadata[];
}

export interface MMSUpdateRequest extends MMSCreateRequest {
  fctURN: string;
}

export class MMSMessageCounters {
  newDisbursementsCount: number;
  newNotesCount: number;
  newDocumentsCount: number;
  newUpdatesCount: number;
  fctURN: string;

  constructor(data: {
    fctURN: string;
    newDisbursementsCount?: number;
    newNotesCount?: number;
    newDocumentsCount?: number;
    newUpdatesCount?: number;
  }) {
    this.newDisbursementsCount = data.newDisbursementsCount || 0;
    this.newNotesCount = data.newNotesCount || 0;
    this.newDocumentsCount = data.newDocumentsCount || 0;
    this.newUpdatesCount = data.newUpdatesCount || 0;
    this.fctURN = data.fctURN;
  }

  add(counters: MMSMessageCounters): void {
    this.newDisbursementsCount += counters.newDisbursementsCount;
    this.newNotesCount += counters.newNotesCount;
    this.newDocumentsCount += counters.newDocumentsCount;
    this.newUpdatesCount += counters.newUpdatesCount;
  }

  addDisbursement(count?: number): void {
    this.newDisbursementsCount += count || 1;
  }

  addNote(count?: number): void {
    this.newNotesCount += count || 1;
  }

  addDocument(count?: number): void {
    this.newDocumentsCount += count || 1;
  }

  addUpdate(count?: number): void {
    this.newUpdatesCount += count || 1;
  }

  hasNewMessages(): boolean {
    return (
      this.newDisbursementsCount > 0 ||
      this.newNotesCount > 0 ||
      this.newDocumentsCount > 0 ||
      this.newUpdatesCount > 0
    );
  }

  clearDisbursements(): void {
    this.newDisbursementsCount = 0;
  }

  clearNotes(): void {
    this.newNotesCount = 0;
  }

  clearDocuments(): void {
    this.newDocumentsCount = 0;
  }

  clearUpdates(): void {
    this.newUpdatesCount = 0;
  }

  toJSON() {
    return {
      newDisbursementsCount: this.newDisbursementsCount,
      newNotesCount: this.newNotesCount,
      newDocumentsCount: this.newDocumentsCount,
      newUpdatesCount: this.newUpdatesCount,
      fctURN: this.fctURN,
    };
  }

  static from(data: MMSMessageCounters): MMSMessageCounters {
    return new MMSMessageCounters(data);
  }
}

export function isMMSUpdateRequest(body: any): body is MMSUpdateRequest {
  return (body as MMSUpdateRequest).fctURN !== undefined;
}

export enum MMSDocumentUploadStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
}

export interface MMSDocumentUploadNotificationPayload {
  mmsDocumentId: string;
  documentName: string;
  documentUploader: MMSDocumentUploader;
}

export enum MMSDocumentUploader {
  MMS = 'MMS',
  FUNDMORE = 'FUNDMORE',
}
