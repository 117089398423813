import {
  AmortizationSchedule,
  assertUnreachable,
  CompoundPeriod,
  Mortgage,
  PaymentFrequency,
  RepaymentType,
} from '@fundmoreai/models';

import {
  fromShortISODateToMiddleLocalDate,
  fromLocalDateToShortISODate,
} from '@fundmoreai/helpers';

import {
  addDays,
  addMonths,
  addYears,
  endOfMonth,
  isFirstDayOfMonth,
  isLeapYear,
  setDate,
  startOfYear,
} from 'date-fns';
import { paymentFrequencyMap } from './mortgage-payment.calculator';
import {
  compoundingMap,
  differenceInDaysBetweenDates,
  subtractOneMonth,
} from '../fundmore.calculator';

export type AmortizationScheduleMortgageInput = Pick<
  Mortgage,
  | 'firstRegularPaymentDate'
  | 'maturityDate'
  | 'totalLoanAmount'
  | 'netRate'
  | 'paymentAmount'
  | 'paymentFrequency'
  | 'compounding'
  | 'amortizationMonths'
  | 'repaymentType'
  | 'termMonths'
  | 'closingDate'
  | 'interestAdjustmentDate'
>;

/**
 *
 * @param mortgage The mortgage to generate the amortization schedule for
 * @returns The amortization schedule for the requested mortgage
 */
export function generateAmortizationSchedule(
  mortgage: AmortizationScheduleMortgageInput | undefined,
  takeFullFirstPayment: boolean,
): AmortizationSchedule[] {
  if (
    !mortgage ||
    !mortgage.amortizationMonths ||
    mortgage.repaymentType === RepaymentType.INTEREST_ONLY
  ) {
    return [];
  }

  const interestAdjustmentDateOrClosingDate = fromShortISODateToMiddleLocalDate(
    mortgage.interestAdjustmentDate || mortgage.closingDate || '',
  );
  const firstRegularPaymentDate = fromShortISODateToMiddleLocalDate(
    mortgage.firstRegularPaymentDate || '',
  );
  const maturityDate = fromShortISODateToMiddleLocalDate(mortgage.maturityDate || '');
  const balance = mortgage.totalLoanAmount;
  const interestRate = mortgage.netRate ?? 0;
  const paymentFrequency = mortgage.paymentFrequency;
  const compounding = mortgage.compounding;
  const term = mortgage.termMonths;
  const paymentAmount = mortgage.paymentAmount;

  const amortization = generateAmortizationList(
    balance,
    interestRate / 100,
    paymentAmount,
    interestAdjustmentDateOrClosingDate,
    firstRegularPaymentDate,
    maturityDate,
    paymentFrequency,
    compounding,
    term,
    takeFullFirstPayment,
  );
  return amortization;
}

function isEndOfMonth(date: Date) {
  if (!date) {
    return false;
  }

  //When comparing dates, we need to use UTC dates to avoid timezone issues.
  //If converting end of month to UTC it will not be the same day.
  return date.getUTCDate() === endOfMonth(date).getDate();
}

function isOnThe30th(date: Date) {
  if (!date) {
    return false;
  }

  //When comparing dates, we need to use UTC dates to avoid timezone issues.
  //If converting end of month to UTC it will not be the same day.
  return date.getUTCDate() === 30;
}

function isEndOfFebruary(date: Date) {
  if (!date) {
    return false;
  }

  //When comparing dates, we need to use UTC dates to avoid timezone issues.
  //If converting end of month to UTC it will not be the same day.
  const endOfMonthDate = endOfMonth(date);
  const isEndOfFebruary = date.getUTCDate() === endOfMonthDate.getDate() && date.getMonth() === 1;
  return isEndOfFebruary;
}

function getMiddleOfCurrentYear(date: Date): Date {
  return new Date(date.getFullYear(), 6, 2);
}

function getStartOfNextYear(date: Date): Date {
  return startOfYear(addYears(date, 1));
}

function valueByPaymentFrequencyForAmortization(
  firstRegularPaymentDate: Date,
  paymentFrequency: PaymentFrequency | null,
) {
  if (!firstRegularPaymentDate) {
    return null;
  }
  const daysInYear = isLeapYear(firstRegularPaymentDate.getFullYear()) ? 366 : 365;
  const lastMonthDate = subtractOneMonth(firstRegularPaymentDate);
  const numberOfDaysBetweenDates = differenceInDaysBetweenDates(
    firstRegularPaymentDate,
    lastMonthDate,
  ) as number;

  switch (paymentFrequency) {
    case PaymentFrequency.MONTHLY:
      return numberOfDaysBetweenDates;
    case PaymentFrequency.SEMI_MONTHLY:
      return Math.round(numberOfDaysBetweenDates / 2);
    case PaymentFrequency.BI_WEEKLY:
    case PaymentFrequency.ACCELERATED_BI_WEEKLY:
      return 14;
    case PaymentFrequency.WEEKLY:
    case PaymentFrequency.ACCELERATED_WEEKLY:
      return 7;
    case PaymentFrequency.DAILY:
      return 1;
    case PaymentFrequency.QUARTERLY:
      return Math.round(daysInYear / 4);
    case PaymentFrequency.ANNUALLY:
      return daysInYear;
    case PaymentFrequency.SEMI_ANNUALLY:
      return Math.round(daysInYear / 2);
    default:
      return null;
  }
}

function generateNextPaymentDate(
  frequency: PaymentFrequency | null,
  startDate: Date,
  closingDate: Date,
) {
  const isClosingDateOrIADEndOfMonthButNot30thOrFebruary =
    isEndOfMonth(closingDate) && !isOnThe30th(closingDate) && !isEndOfFebruary(closingDate);

  const firstRegularPaymentDay = closingDate?.getUTCDate();
  let nextPaymentDate: Date | undefined = undefined;
  switch (frequency) {
    case PaymentFrequency.SEMI_MONTHLY:
      if (isFirstDayOfMonth(startDate)) {
        nextPaymentDate = addDays(startDate, 14);
      } else {
        nextPaymentDate = addDays(endOfMonth(startDate), 1);
      }
      break;
    case PaymentFrequency.BI_WEEKLY:
    case PaymentFrequency.ACCELERATED_BI_WEEKLY:
      nextPaymentDate = addDays(startDate, 14);
      break;
    case PaymentFrequency.WEEKLY:
    case PaymentFrequency.ACCELERATED_WEEKLY:
      nextPaymentDate = addDays(startDate, 7);
      break;
    case PaymentFrequency.MONTHLY:
      nextPaymentDate = !isClosingDateOrIADEndOfMonthButNot30thOrFebruary
        ? !isEndOfFebruary(startDate)
          ? addMonths(startDate, 1)
          : setDate(addMonths(startDate, 1), firstRegularPaymentDay)
        : endOfMonth(addMonths(startDate, 1));
      break;
    case PaymentFrequency.ANNUALLY:
      nextPaymentDate = addYears(startDate, 1);
      nextPaymentDate = !isClosingDateOrIADEndOfMonthButNot30thOrFebruary
        ? !isEndOfFebruary(startDate)
          ? addYears(startDate, 1)
          : setDate(addYears(startDate, 1), firstRegularPaymentDay)
        : endOfMonth(addYears(startDate, 1));
      break;
    case PaymentFrequency.DAILY:
      nextPaymentDate = addDays(startDate, 1);
      break;
    case PaymentFrequency.QUARTERLY:
      nextPaymentDate = !isClosingDateOrIADEndOfMonthButNot30thOrFebruary
        ? !isEndOfFebruary(startDate)
          ? addMonths(startDate, 3)
          : setDate(addMonths(startDate, 3), firstRegularPaymentDay)
        : endOfMonth(addMonths(startDate, 3));
      break;
    case PaymentFrequency.SEMI_ANNUALLY:
      {
        const isMonthBeforeJuly = startDate.getMonth() < 6;
        const isBeforeJulySecond = startDate.getMonth() === 6 && startDate.getDate() < 2;
        const isBeforeMiddleOfYear = isMonthBeforeJuly || isBeforeJulySecond;
        nextPaymentDate = isBeforeMiddleOfYear
          ? getMiddleOfCurrentYear(startDate)
          : getStartOfNextYear(startDate);
      }
      break;
    case null:
      nextPaymentDate = !isClosingDateOrIADEndOfMonthButNot30thOrFebruary
        ? !isEndOfFebruary(startDate)
          ? addMonths(startDate, 1)
          : setDate(addMonths(startDate, 1), firstRegularPaymentDay)
        : endOfMonth(addMonths(startDate, 1));
      break;
    default:
      assertUnreachable(frequency, 'Unhandled PaymentFrequency');
      break;
  }

  if (!nextPaymentDate) {
    throw new Error('Next payment date is not defined!');
  }

  return nextPaymentDate;
}

function generateAmortizationList(
  balance: number | null,
  interestRate: number,
  paymentAmount: number | null,
  closingDate: Date,
  firstRegularPaymentDate: Date,
  maturityDate: Date,
  frequency: PaymentFrequency | null,
  compounding: CompoundPeriod | null,
  term: number | null,
  takeFullFirstPayment: boolean,
) {
  const scheduleArray: AmortizationSchedule[] = [];
  let startDate = firstRegularPaymentDate;
  let balanceAfterPrincipal = balance !== null ? Math.round(balance * 100) / 100 : 0;
  const endDate = maturityDate;
  let isFirstPayment = true;

  const mappedPaymentFrequency = frequency ? paymentFrequencyMap.get(frequency) : undefined;
  const frequencyNumber =
    mappedPaymentFrequency ?? (paymentFrequencyMap.get(PaymentFrequency.MONTHLY) as number);

  const mappedCompounding = compounding ? compoundingMap.get(compounding) : undefined;
  const compoundingNumber = mappedCompounding ?? 2;

  const numberOfPaymentsInTerm = Math.floor(term != null ? (term / 12) * frequencyNumber : 0);

  let interest = 0;
  let principal = 0;
  let paymentDueDate = new Date();

  for (let i = 0; i < numberOfPaymentsInTerm && balanceAfterPrincipal > 0; i++) {
    interest = 0;
    principal = 0;
    paymentDueDate = new Date();

    if (isFirstPayment) {
      paymentDueDate = startDate;
    } else {
      paymentDueDate = generateNextPaymentDate(frequency, startDate, closingDate);
    }

    const rate =
      (1 + interestRate / compoundingNumber) ** (compoundingNumber / frequencyNumber) - 1;

    interest = Math.round(balanceAfterPrincipal * rate * 100) / 100;
    principal = paymentAmount !== null ? Math.round((paymentAmount - interest) * 100) / 100 : 0;
    let reducedPaymentAmount = paymentAmount;

    if (isFirstPayment) {
      const diff = differenceInDaysBetweenDates(firstRegularPaymentDate, closingDate) as number;
      const valueByPaymentFrequencyInDays = valueByPaymentFrequencyForAmortization(
        firstRegularPaymentDate,
        frequency,
      ) as number;

      if (diff < valueByPaymentFrequencyInDays) {
        interest = Math.round((interest / valueByPaymentFrequencyInDays) * diff * 100) / 100;

        if (!takeFullFirstPayment) {
          reducedPaymentAmount =
            paymentAmount !== null
              ? Math.round((paymentAmount / valueByPaymentFrequencyInDays) * diff * 100) / 100
              : 0;
          principal = Math.round((reducedPaymentAmount - interest) * 100) / 100;
        } else {
          principal =
            paymentAmount !== null ? Math.round((paymentAmount - interest) * 100) / 100 : 0;
        }
      }
    }

    balanceAfterPrincipal = Math.round((balanceAfterPrincipal - principal) * 100) / 100;

    if (balanceAfterPrincipal <= 0) {
      balanceAfterPrincipal = 0;
      paymentDueDate = new Date(endDate);
    }

    startDate = paymentDueDate;

    scheduleArray.push({
      paymentDueDate: fromLocalDateToShortISODate(paymentDueDate) as string,
      interest,
      principal,
      balance: balanceAfterPrincipal,
      payment: isFirstPayment ? reducedPaymentAmount ?? 0 : paymentAmount ?? 0,
    });

    if (isFirstPayment) {
      isFirstPayment = false;
    }
  }

  // Adding an extra payment if fits in the term
  if (paymentDueDate < endDate) {
    const nextPaymentDueDate = generateNextPaymentDate(frequency, startDate, closingDate);

    if (nextPaymentDueDate <= endDate) {
      const rate =
        (1 + interestRate / compoundingNumber) ** (compoundingNumber / frequencyNumber) - 1;

      interest = Math.round(balanceAfterPrincipal * rate * 100) / 100;

      principal = paymentAmount !== null ? Math.round((paymentAmount - interest) * 100) / 100 : 0;

      balanceAfterPrincipal = Math.round((balanceAfterPrincipal - principal) * 100) / 100;

      scheduleArray.push({
        paymentDueDate: fromLocalDateToShortISODate(nextPaymentDueDate) as string,
        interest,
        principal,
        balance: balanceAfterPrincipal,
        payment: paymentAmount ?? 0,
      });
    }
  }

  return scheduleArray;
}
