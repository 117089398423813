import { Applicant, CustomerType } from './applicant';
import { ApplicationStage, ApplicationPurposeType } from './application';
import { FinancialAsset, FinancialAssetKey } from './asset';
import { DownPayment, DownPaymentKey } from './downPayment';
import {
  CompoundPeriod,
  FinancialLiabilitySource,
  IncomePeriod,
  MortgageType,
  PaymentFrequency,
  PayoffType,
  PropertyType,
  RepaymentType,
} from './enums';
import { Fee } from './fee';
import { FinancialLiability } from './liability';
import { ConstructionMortgage, DrawSchedule, Mortgage } from './mortgage';
import { ApplicationOtherIncome } from './otherIncome';
import { Property } from './property';

export interface ApplicationCompareDTO {
  // commented fields are not compared at the moment
  id: string;
  currentStage?: ApplicationStage | null;
  docsDueDate?: string | null;
  source?: string | null;
  fundId?: string | null;
  ConstructionSchedules: DrawSchedule[];
  purpose: ApplicationPurposeType | null;
  mortgageClassification?: string | null;
  filogixDealId?: string | null;
  externalDealId?: string | null;
  ezidoxApplicationId?: number | null;
  // declineComment: string;
  // declineReasonId: string;
  // declineDate?: Date;
  // clonedApplicationId: string;

  applicants: Applicant[];
  OtherIncomes: ApplicationOtherIncome[];
  DownPayments: DownPayment[];
  FinancialAssets: FinancialAsset[];
  FinancialLiabilities: FinancialLiability[];
  Properties: Property[];
  Fees: Fee[];
  Mortgages: Mortgage[];
  // createdBy: User;
  // ApplicationDolphinIntegrations: ApplicationDolphinIntegration[];
  // ApplicationVerifications: ApplicationVerification[];
  // ApplicationAssignedUsers: ApplicationAssignedUser[];
  // ApplicationStatus: ApplicationStatus;
}

export interface ApplicationDiff {
  id: string[];
  primaryApplicantName: string[];
  currentStage?: ApplicationStage[];
  docsDueDate: string[];
  source: string[];
  fundId: string[];
  ConstructionSchedules: DrawScheduleDiff[];
  purpose: ApplicationPurposeType[];
  mortgageClassification: string[];
  filogixDealId: string[];
  externalDealId: string[];
  ezidoxApplicationId: number[];

  applicants: { [key: string]: ApplicantDiff };
  otherApplicants: { [key: string]: ApplicantDiff };
  OtherIncomes: { [key: string]: OtherIncomeDiff };
  DownPayments: { [key: string]: DownPaymentDiff };
  FinancialAssets: { [key: string]: FinancialAssetDiff };
  FinancialLiabilities: { [key: string]: FinancialLiabilityDiff };
  Mortgages: { [key: string]: MortgageDiff };
  Properties: { [key: string]: PropertyDiff };
  requestedMortgage: { [key: string]: MortgageDiff };
  Fees: { [key: string]: FeeDiff };
}

export interface ApplicantDiff {
  // ApplicantAddressesExpanded: AddressExpanded[];
  // ApplicantCompanyDetails: ApplicantCompanyDetailsType[];
  // ApplicantCompanyDirectorDetails: ApplicantCompanyDirectorDetailsType[];
  // currentResidence?: AddressExpanded;
  // Jobs: Job[];
  cellPhone: string[];
  crDescription: string[] | null;
  crScore: number[] | null;
  crScoreCode: string[] | null;
  dateOfBirth: string[];
  placeOfBirth: string[];
  countryOfResidence: string[];
  registeredCountry: string[];
  nationality: string[];
  dependents: number[];
  email: string[];
  ezidoxStakeholderId: string[] | null;
  faxNumber: string[];
  homePhone: string[];
  id: string[];
  isPrimary: boolean[];
  maritalStatus: string[];
  name: string[];
  sin: string[] | null;
  surname: string[];
  templateSetId: number[];
  workPhone: string[];
  isUploader: boolean[] | null;
  isFirstTimeHomeBuyer: boolean[];
  customerType: CustomerType[];
  brokerId?: string[];
  brokerage?: string[];
  type?: string[];
  lawyerId?: string[];
  company?: string[];
  companyId?: string[];
  isCompany: boolean[];
}

export interface OtherIncomeDiff {
  type: string[];
  period: IncomePeriod[];
  description: string[];
  amount: number[];
  createdAt: string[];
  updatedAt: string[];
}

export interface FeeDiff {
  type: string[];
  paidBy: string[];
  percent: number[];
  amount: number[];
  subtractFromPrincipal: boolean[];
  includeInApr: boolean[];
  capFeesPercentage: number[];
}

export interface DrawScheduleDiff {
  drawDate: string[];
  availableAdvanceAmount: number[];
  hardCostCompletedToDate: number[];
  estimatedInterestToBeDeducted: number[];
  fundsHeldBack: number[];
  daysFromPreviousAdvance: number[];
  ctc: number[];
}

export interface DownPaymentDiff {
  [DownPaymentKey.APPLICATION_ID]: string[];
  [DownPaymentKey.AMOUNT]?: number[];
  [DownPaymentKey.CREATED_AT]: string[];
  [DownPaymentKey.DESCRIPTION]: string[] | null;
  [DownPaymentKey.ID]: string[];
  [DownPaymentKey.SOURCE]: string[];
  [DownPaymentKey.UPDATED_AT]: string[];
  [DownPaymentKey.FINANCIAL_ASSET_ID]: string[];
}

export interface FinancialAssetDiff {
  [FinancialAssetKey.ID]: string[];
  [FinancialAssetKey.APPLICATION_ID]: string[];
  [FinancialAssetKey.ASSET]: string[];
  [FinancialAssetKey.DESCRIPTION]: string[];
  [FinancialAssetKey.VALUE]: number[];
  [FinancialAssetKey.UPDATED_AT]: string[];
  [FinancialAssetKey.USE_AS_DOWN_PAYMENT]: string[];
}

export interface FinancialLiabilityDiff {
  source: FinancialLiabilitySource[];
  description: string[];
  liability: string[];
  value: number[];
  balance: number[];
  monthlyPayment: number[];
  payoff: PayoffType[];
}

export interface MortgageDiff {
  amortizationMonths: number[] | null;
  closingDate: string[] | null;
  insuranceAmount: number[] | null;
  insurancePremium: number[] | null;
  insurer: string[] | null;
  lender: string[] | null;
  loanType: string[] | null;
  monthlyPayment: number[] | null;
  mortgageBalance: number[] | null;
  mortgageType: string[] | null;
  netRate: number[];
  originalNetRate: number[] | null;
  paymentFrequency: PaymentFrequency[];
  compounding: CompoundPeriod[];
  payoutBalance: number[] | null;
  purpose: string[] | null;
  rateType: string[] | null;
  repaymentType: RepaymentType[];
  termMonths: number[] | null;
  termType: string[] | null;
  loanAmount: number[] | null;
  type: MortgageType[];
  interestAdjustmentDate: string[] | null;
  firstRegularPaymentDate: string[] | null;
  maturityDate: string[] | null;
  deadlineToAcceptDate: string[] | null;
  pst: number[] | null;
  payoff: PayoffType[];
  ConstructionMortgage: ConstructionMortgage[];
}

export interface PropertyDiff {
  yearBuilt?: number[] | null;
  annualTaxes: number[];
  applicationId: string[];
  appraisedDate: string[] | null;
  appraisedValue: string[] | null;
  block: string[] | null;
  concessionTownship: string[] | null;
  condoFees: number[];
  constructionType: string[] | null;
  createdAt: string[];
  dwellingStyle: string[];
  dwellingType: string[];
  isEnvironmentalHazard: boolean[];
  estimatedValue: number[];
  estimatedValueDate: string[];
  garageSize: string[];
  garageType: string[] | null;
  heatingCost: number[];
  heatingType: string[];
  id: string[];
  improvements: string[] | null;
  livingSpace: number[];
  lot: string[] | null;
  lotSize: number[];
  noOfUnits: number[] | null;
  occupancy: string[] | null;
  propertyAddress?: string[];
  legalDescription?: string[];
  shortLegalDescription?: string[];
  pid?: string[];
  plan: string[] | null;
  zoningType: string[] | null;
  propertyType: string[] | null;
  purchasePrice: number[] | null;
  rpeGeneralExpenses: number[] | null;
  rpeHydro: number[] | null;
  rpeInsurance: number[] | null;
  rpeInterestCharges: number[] | null;
  rpeManagementExpenses: number[] | null;
  rpeMonthlyRentalIncome: number[] | null;
  rpeOffset: number[] | null;
  rpeRentalOffsetOption: string[] | null;
  rpeRepairs: number[] | null;
  sewageInfo: string[] | null;
  taxationYear: number[];
  taxesPaidBy: string[];
  tenure: string[] | null;
  totalExpenses: number[] | null;
  type: PropertyType[];
  updatedAt: string[];
  valueOfImprovements: number[] | null;
  waterType: string[] | null;
  foundationType: string[] | null;
  nameOnTitle: string[] | null;
  isCollateralized: boolean[];
  otherSecurityConditions?: string[];
  hasOtherSecurityConditions: boolean[];
  includeInCLTV: boolean[];
}
