import { Province, State } from '@fundmoreai/models';

const pstPercentPerRegion: Map<Province | State, number> = new Map([
  [Province.Ontario, 8],
  [Province.Quebec, 9],
  [Province.Saskatchewan, 6],
]);

export function calculateInsuranceAmount(
  insurancePremium: number | null,
  loanAmount: number | null,
): number | null {
  if (insurancePremium === 0) {
    return 0;
  }

  if (!insurancePremium || !loanAmount) {
    return null;
  }
  const insuranceAmount = (loanAmount * insurancePremium) / 100;
  return Math.round(insuranceAmount * 100) / 100;
}

export function calculateInsurancePremium(
  insuranceAmount: number | null,
  loanAmount: number | null,
) {
  if (insuranceAmount === 0) {
    return 0;
  }
  if (!insuranceAmount || !loanAmount) {
    return null;
  }

  const insurancePremium = (insuranceAmount / loanAmount) * 100;
  return Math.round(insurancePremium * 100) / 100;
}

export function calculatePST(
  insuranceAmount: number | null,
  province: Province | State | null | undefined,
): number | null {
  const insurance = insuranceAmount;
  if (insurance && province) {
    const pstPercent = pstPercentPerRegion.get(province);
    if (pstPercent) {
      return (insurance * pstPercent) / 100;
    }
  }
  return null;
}

export function getPSTPercentPerRegion(province: Province | State): number | undefined {
  return pstPercentPerRegion.get(province);
}
