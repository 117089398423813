import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Property, PropertyInsurance } from '../../shared/model';

@Injectable({
  providedIn: 'root',
})
export class PropertyInsurancesService {
  constructor(private http: HttpClient) {}

  postInsurance(
    propertyId: string,
    applicationId: string,
    insurance: Partial<PropertyInsurance>,
  ): Observable<PropertyInsurance> {
    return this.http.post<PropertyInsurance>(
      `${environment.api_url}/properties/${propertyId}/insurances`,
      { ...insurance, applicationId },
    );
  }

  patchInsurance(insurance: PropertyInsurance): Observable<PropertyInsurance> {
    return this.http
      .patch<{ affectedRows: PropertyInsurance }>(
        `${environment.api_url}/properties/insurances/${insurance.id}`,
        {
          ...insurance,
          id: undefined,
        },
      )
      .pipe(map((data) => data.affectedRows));
  }

  deleteInsurance(propertyId: string, insuranceId: string): Observable<void> {
    return this.http.delete<void>(`${environment.api_url}/properties/insurances/${insuranceId}`);
  }

  fetchApplicationInsurances(applicationId: string): Observable<PropertyInsurance[]> {
    return this.http
      .get<(PropertyInsurance & { Property?: Property })[]>(
        `${environment.api_url}/properties/insurances/${applicationId}`,
      )
      .pipe(
        map((insurances) =>
          (insurances ?? []).map((x) => {
            // the response contains a Property which we don't need
            delete x.Property;

            return x;
          }),
        ),
      );
  }
}
