import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, combineLatest, map, switchMap, EMPTY, of } from 'rxjs';
import { DownPaymentState } from 'src/app/portal/downpayments.state';
import { MortgageApplicationState } from 'src/app/portal/mortgage-application.state';
import { FundmoreScoreType } from 'src/app/shared/enums';
import { PropertiesState } from 'src/app/portal/properties.state';
import { PropertyAppraisalState } from 'src/app/portal/property-appraisals/property-appraisals.state';
import { FinancialLiabilityState } from 'src/app/features/application/widgets/credit/financial-liability.state';
import { UserAccountsState } from 'src/app/portal/user-accounts.state';
import { ConditionsDisplayService } from 'src/app/shared/services/conditions-display.service';
import { FundsState } from 'src/app/portal/funds.state';
import {
  AmortizationSchedule,
  BaseDocumentContext,
  Condition,
  GenerateDocumentsPackageServiceBase,
  JoinedApplicationCondition,
  MortgageExpandedForContext,
} from '../model';
import {
  Applicant,
  Fee,
  FinancialAsset,
  FinancialLiability,
  Mortgage,
  Property,
  CustomerType,
  FundmoreResultType,
  MortgageType,
  IncomeType,
  MortgagePosition,
  FeeType,
  PaymentFrequency,
  CalculationAutomationSettings,
  FundmoreScoreOutput,
  DownPayment,
  Summary,
  ServicingApplicationPurpose,
  ApplicationPurposeType,
} from '@fundmoreai/models';
import { ApplicantsState } from 'src/app/portal/applicants.state';
import { Fund, UserAccount } from 'src/app/shared';
import { Application, Lawyer, PropertyAppraisal } from 'src/app/shared/model';
import { MortgagesServices } from 'src/app/portal/mortgages.services';
import { AmortizationScheduleService } from './amortization-schedule.service';
import { LawyersState } from 'src/app/portal/lawyers.state';
import { Role, RoleState } from '../../user';
import { IncomeState } from 'src/app/features/application/widgets/income/income.state';
import { MortgagesV2State } from 'src/app/portal/mortgages-v2/mortgages-v2.state';
// eslint-disable-next-line max-len
import { ApplicationConditionsState } from 'src/app/features/application/widgets/conditions/application-conditions.state';
import { DocumentTemplate } from 'src/app/features/manager-portal/documents-management/model';
import {
  getAppLanguage,
  LANGUAGES,
} from 'src/app/portal/navigation/language-picker/language.const';
import { FundmoreCalculator } from '@fundmoreai/calculator';
import { IncomeTypeRecord } from '../../../../shared/enum-records';
import { NEW_LINE_SEPARATOR } from 'src/app/shared/handlebars.service';
import { FinancialAssetsState } from 'src/app/features/application/widgets/networth/financial-assets.state';
import { SummaryState } from 'src/app/portal/summary.state';
import { FeesState } from 'src/app/portal/fees.state';
import { FundmoreScoreLenderState } from 'src/app/portal/fundmore-score-lender.state';
import { AuthState } from 'src/app/auth/auth.state';
import { ApplicationNotesState } from 'src/app/features/application/notes/notes.state';
import { Note } from 'src/app/features/application/notes/notes.model';
import { ApplicationApprovalState } from 'src/app/features/application/approvals/application-approval.state';
import { ApplicationApproval } from 'src/app/features/application/approvals/application-approval.model';
import {
  EmployedIncome,
  Income,
  OtherIncome,
  SelfEmployedIncome,
} from 'src/app/features/application/widgets/income/income.model';
import { AppFeaturesState } from 'src/app/shared/app-features.state';
import { MortgageComputedDataState } from 'src/app/portal/mortgage-computed-data.state';

const FundmoreResultTypeRecord: Record<FundmoreResultType, string> = {
  [FundmoreResultType.Pass]: $localize`FundMore Recommendation is to Approve`,
  [FundmoreResultType.Fail]: $localize`FundMore Recommendation is to Decline`,
  // eslint-disable-next-line max-len
  [FundmoreResultType.ManualReview]: $localize`A FundMore Recommendation cannot be made. Please review manually the application`,
  [FundmoreResultType.Unknown]: $localize`A FundMore Recommendation cannot be made. There is some missing information`,
};

@Injectable()
export class DefaultPackageService implements GenerateDocumentsPackageServiceBase {
  constructor(
    public amortizationScheduleService: AmortizationScheduleService,
    public applicantsState: ApplicantsState,
    public conditionDisplayService: ConditionsDisplayService,
    public mortgagesServices: MortgagesServices,
    public store: Store,
  ) {}

  getContext$(documentTemplate: DocumentTemplate): Observable<BaseDocumentContext> {
    return combineLatest([
      this.store.select(ApplicantsState.applicantsListExcludingThirdParty),
      this.store.select(ApplicantsState.applicantsList),
      this.store.select(ApplicantsState.broker),
      this.store.select(FinancialLiabilityState.financialLiabilitiesList),
      this.store.select(DownPaymentState.totalDownPayment()),
      this.store.select(FeesState.feesList),
      this.store.select(FinancialAssetsState.financialAssetsList),
      this.store.select(FundmoreScoreLenderState.lenderScore),
      this.store.select(FundsState.funds),
      this.store.select(IncomeState.incomesList),
      this.store.select(IncomeState.totalIncome),
      this.store.select(MortgageApplicationState.application),
      // firstFoundRequestedMortgage for backwards compatibility with new  mortgages state
      // specifically multiple requested mortgages
      this.store.select(MortgagesV2State.firstFoundRequestedMortgage).pipe(
        switchMap((mortgage) => {
          if (!mortgage) {
            return of(undefined);
          }
          return this.store.select(MortgageComputedDataState.apr(mortgage.id));
        }),
      ),
      this.store.select(MortgagesV2State.servicingNewMortgage).pipe(
        switchMap((mortgage) => {
          if (!mortgage) {
            return of(undefined);
          }
          return this.store.select(MortgageComputedDataState.apr(mortgage.id));
        }),
      ),
      this.store.select(MortgagesV2State.mortgages),
      this.store.select(MortgagesV2State.primaryPropertyExistingAndRefinanceMortgages),
      this.store.select(MortgagesV2State.firstFoundRequestedMortgage),
      this.store.select(MortgagesV2State.servicingNewMortgage),
      this.store.select(MortgagesV2State.requestedMortgages),
      this.store.select(PropertiesState.primaryProperty),
      this.store.select(PropertiesState.propertiesListWithOwners),
      this.store.select(ApplicationConditionsState.joinedApplicationConditions),
      this.store.select(ApplicationApprovalState.approvalsList),
      this.store.select(SummaryState.summaryAllRequestedMortgagesForDocumentContext),
      this.store.select(UserAccountsState.userAccountsList),
      this.store.select(ApplicantsState.lawyers),
      this.store.select(LawyersState.lawyers),
      this.store.select(RoleState.roles),
      this.store.select(MortgagesV2State.totalLoanAmountForAllRequestedMortgages),
      this.store.select(MortgagesV2State.calculationAutomationSettings),
      this.store.select(AppFeaturesState.iadByFpdEnabled),
      this.store.select(ApplicationNotesState.notesList),
      this.store.select(DownPaymentState.downPaymentsList),
      this.store.select(AppFeaturesState.takeFullFirstPayment),
      this.store.select(MortgageApplicationState.servicingMortgageRemainingTerm),
    ]).pipe(
      map(
        ([
          applicants,
          stakeholders,
          broker,
          liabilities,
          totalDownPayment,
          fees,
          financialAssets,
          fundmoreScoreState,
          funds,
          incomesList,
          totalIncome,
          application,
          apr,
          servicingApr,
          mortgages,
          primaryPropertyExistingAndRefinanceMortgages,
          requestedMortgage,
          servicingMortgage,
          requestedMortgages,
          primaryProperty,
          properties,
          selectedConditions,
          approvals,
          summary,
          userAccounts,
          stakeholderLawyers,
          lawyers,
          roles,
          totalLoanAmount,
          calculationAutomationSettings,
          iadByFpdEnabled,
          notes,
          downPaymentsList,
          takeFullFirstPayment,
          servicingMortgageRemainingTerm,
        ]) => {
          return this.getDefaultContext(
            documentTemplate,
            applicants || [],
            stakeholders,
            application,
            broker,
            fees,
            financialAssets,
            fundmoreScoreState.results,
            funds,
            summary.gds?.result,
            incomesList.map((x) => ({
              ...x,
              name: IncomeTypeRecord[x.type as IncomeType],
            })),
            liabilities || [],
            summary.ltv?.result,
            apr,
            servicingApr,
            [...mortgages.map((x) => ({ ...x, totalMortgageAmount: x?.loanAmount }))],
            [
              ...primaryPropertyExistingAndRefinanceMortgages.map((x) => ({
                ...x,
                totalMortgageAmount: x?.loanAmount,
              })),
            ],
            requestedMortgage
              ? { ...requestedMortgage, totalMortgageAmount: requestedMortgage?.loanAmount }
              : undefined,
            servicingMortgage
              ? { ...servicingMortgage, totalMortgageAmount: servicingMortgage?.loanAmount }
              : undefined,
            requestedMortgages,
            primaryProperty,
            properties,
            selectedConditions,
            approvals || [],
            summary.tds?.result,
            totalDownPayment,
            totalIncome,
            userAccounts ?? [],
            stakeholderLawyers,
            lawyers,
            roles,
            totalLoanAmount,
            calculationAutomationSettings,
            iadByFpdEnabled,
            notes,
            downPaymentsList,
            summary,
            takeFullFirstPayment,
            servicingMortgageRemainingTerm,
          );
        },
      ),
    );
  }

  private getDefaultContext(
    documentTemplate: DocumentTemplate,
    applicantsContext: Applicant[] | undefined,
    stakeholders: Applicant[] | undefined,
    application: Application,
    broker: Applicant | undefined,
    fees: Fee[] | undefined,
    financialAssets: FinancialAsset[] | undefined,
    fundmoreScoreLender: { score: FundmoreScoreOutput; type: FundmoreScoreType }[],
    funds: Fund[],
    gds: number,
    incomesList: Income[],
    liabilities: FinancialLiability[],
    ltv: number,
    apr: number | undefined,
    servicingApr: number | undefined,
    mortgages: Mortgage[],
    primaryPropertyExistingAndRefinanceMortgages: Mortgage[],
    requestedMortgageInput: Mortgage | undefined,
    servicingMortgageInput: Mortgage | undefined,
    requestedMortgagesInput: Mortgage[],
    primaryProperty: Property | undefined,
    properties: Property[] | undefined,
    applicationConditions: JoinedApplicationCondition[],
    approvals: ApplicationApproval[],
    tds: number,
    totalDownPayment: number,
    totalIncome: number,
    users: UserAccount[],
    stakeholderLawyers: Applicant[] | undefined,
    lawyers: Lawyer[],
    roles: Role[],
    totalLoanAmount: number | undefined,
    calculationAutomationSettings: CalculationAutomationSettings,
    iadByFpdEnabled: boolean,
    notes: Note[],
    downPaymentsList: DownPayment[],
    summary: Summary,
    takeFullFirstPayment: boolean,
    servicingMortgageRemainingTerm: number | undefined,
  ): BaseDocumentContext {
    const applicants = applicantsContext?.map((applicant) => ({
      ...applicant,
      age:
        new Date().getFullYear() -
        (applicant.dateOfBirth
          ? new Date(applicant.dateOfBirth).getFullYear()
          : new Date().getFullYear()),
    }));

    const requestedMortgage = this.mortgagesServices.addDefaultConditionDatesIfMissing(
      Object.assign({}, requestedMortgageInput),
      calculationAutomationSettings,
      iadByFpdEnabled,
    );
    const servicingMortgage = servicingMortgageInput
      ? { ...servicingMortgageInput, apr: servicingApr }
      : undefined;
    const requestedMortgages = requestedMortgagesInput.map((requestedMortgage) => {
      const rm: MortgageExpandedForContext =
        this.mortgagesServices.addDefaultConditionDatesIfMissing(
          Object.assign({}, requestedMortgage),
          calculationAutomationSettings,
          iadByFpdEnabled,
        );

      const apr = this.store.selectSnapshot(MortgageComputedDataState.apr(requestedMortgage.id));
      const summaryByRequestedMortgage = this.store.selectSnapshot(
        SummaryState.summaryByRequestedMortgage(requestedMortgage.id),
      );
      const amortizationSchedule = this.amortizationScheduleService.getAmortizationSchedule(
        requestedMortgage,
        requestedMortgage.amountToBeAdvanced,
        calculationAutomationSettings,
        iadByFpdEnabled,
        takeFullFirstPayment,
      );
      const atEndOfTermValues = this.makeAtEndOfTermValues(amortizationSchedule);
      const totalPayment = this.amortizationScheduleService
        .getAmortizationSchedule(
          requestedMortgage,
          requestedMortgage.amountToBeAdvanced,
          calculationAutomationSettings,
          iadByFpdEnabled,
          takeFullFirstPayment,
        )
        .reduce((a, b) => a + b.payment, 0);

      rm.amortizationSchedule = amortizationSchedule;
      rm.apr = apr;
      rm.atEndOfTermValues = atEndOfTermValues;
      rm.totalPayment = totalPayment;
      rm.ltv = summaryByRequestedMortgage.ltv.result;
      rm.gds = summaryByRequestedMortgage.gds.result;
      rm.tds = summaryByRequestedMortgage.tds.result;

      return rm;
    });

    const existingAndRefinanceMortgages = this.store
      .selectSnapshot(MortgagesV2State.existingAndRefinanceMortgages)
      .map((x) => ({ ...x }));
    const amortizationSchedule = this.amortizationScheduleService.getAmortizationSchedule(
      requestedMortgage,
      requestedMortgage.amountToBeAdvanced,
      calculationAutomationSettings,
      iadByFpdEnabled,
      takeFullFirstPayment,
    );
    const termMonths =
      application.purpose === ServicingApplicationPurpose.COST_OF_BORROWING ||
      application.purpose === ServicingApplicationPurpose.PAYMENT_CHANGE
        ? servicingMortgageRemainingTerm
        : servicingMortgage?.termMonths;
    const servicingAmortizationSchedule = servicingMortgage
      ? this.amortizationScheduleService.getAmortizationSchedule(
          {
            ...servicingMortgage,
            firstRegularPaymentDate: servicingMortgage.nextPaymentDate || null,
            totalLoanAmount:
              application.purpose === ApplicationPurposeType.SERVICING_RENEWAL
                ? servicingMortgage.projectedBalance ?? 0
                : servicingMortgage.mortgageBalance ?? 0,
            termMonths: termMonths || null,
          },
          servicingMortgage.amountToBeAdvanced,
          calculationAutomationSettings,
          iadByFpdEnabled,
          takeFullFirstPayment,
        )
      : [];
    const fundmoreScore = fundmoreScoreLender.find((x) => x.type === FundmoreScoreType.Default)
      ?.score.results[0];
    const localRequestedMortgage = this.mortgagesServices.toCommitmentMortgage(
      requestedMortgage,
      requestedMortgage.amountToBeAdvanced,
      calculationAutomationSettings,
      iadByFpdEnabled,
    );
    const localServicingMortgage = servicingMortgage
      ? this.mortgagesServices.toCommitmentMortgage(
          servicingMortgage,
          servicingMortgage.amountToBeAdvanced,
          calculationAutomationSettings,
          iadByFpdEnabled,
        )
      : undefined;
    const propertyAppraisal: PropertyAppraisal | undefined = this.store.selectSnapshot(
      PropertyAppraisalState.propertyValueAppraisal(primaryProperty?.id),
    );
    const firstMortgage = this.getExistingMortgageOfType(
      MortgagePosition.FIRST,
      primaryProperty,
      mortgages,
    );
    const secondMortgage = this.getExistingMortgageOfType(
      MortgagePosition.SECOND,
      primaryProperty,
      mortgages,
    );
    const thirdMortgage = this.getExistingMortgageOfType(
      MortgagePosition.THIRD,
      primaryProperty,
      mortgages,
    );
    const lenderFee = this.getLenderFee(fees || []);
    const brokerFee = this.getBrokerFee(fees || []);
    const otherFeesWithoutBrokerAndLender = this.getOtherFeesAmountWithoutBrokerAndLender(
      fees || [],
    );
    let shortAddressStreet, shortAddressDetails;
    if (
      primaryProperty?.propertyAddressExpanded?.rawGoogleGeocodeResponse?.formatted_address
        ?.length &&
      primaryProperty.propertyAddressExpanded.rawGoogleGeocodeResponse.formatted_address.length > 6
    ) {
      shortAddressStreet = this.getShortAddressStreet(primaryProperty);
      shortAddressDetails = this.getShortAddressWithoutStreet(primaryProperty);
    }
    const numberOfStakeholder = applicants?.filter(
      (a) =>
        a.customerType === CustomerType.GUARANTOR ||
        a.customerType === CustomerType.CUSTOMER ||
        a.customerType === CustomerType.CO_BORROWER ||
        a.customerType === CustomerType.COMPANY,
    ).length;
    let isOnlyOneStakeholder = false;
    if (numberOfStakeholder == 1) {
      isOnlyOneStakeholder = true;
    }

    const selectedStakeholder =
      documentTemplate.options?.applicants &&
      applicants?.find((a) =>
        documentTemplate.options
          ? documentTemplate.options.applicants?.some((x) => x.id === a.id)
          : false,
      );

    const selectedProperty = documentTemplate.options?.properties
      ? documentTemplate.options.properties[0]
      : undefined;

    const selectedStakeholderLawyer = documentTemplate.options?.lawyers
      ? documentTemplate.options.lawyers[0]
      : undefined;

    const selectedLawyer = lawyers.find((l) => l.id === selectedStakeholderLawyer?.lawyerId);

    // add payments on mortgages in all frequencies
    // round amortizationMonths to the next whole number
    [
      ...mortgages,
      ...primaryPropertyExistingAndRefinanceMortgages,
      ...existingAndRefinanceMortgages,
      requestedMortgage,
      ...requestedMortgages,
      localRequestedMortgage,
      servicingMortgage,
      localServicingMortgage,
    ]
      .filter((x) => !!x)
      .forEach((mortgage: Mortgage) => {
        this.addPaymentsToMortgage(mortgage);
        this.roundMortgageValues(mortgage);
      });

    const tenantCode = this.store.selectSnapshot(AuthState.tenantCode);
    const totalCostOfBorrowing = FundmoreCalculator.getTotalCostOfBorrowing(
      amortizationSchedule,
      requestedMortgage,
      totalLoanAmount,
      fees ?? [],
      tenantCode ?? '',
    );
    const servicingTotalCostOfBorrowing = servicingMortgage
      ? FundmoreCalculator.getTotalCostOfBorrowing(
          servicingAmortizationSchedule,
          { ...servicingMortgage, termMonths: termMonths || null },
          application.purpose === ApplicationPurposeType.SERVICING_RENEWAL
            ? servicingMortgage.projectedBalance ?? 0
            : servicingMortgage.mortgageBalance ?? 0,
          fees ?? [],
          tenantCode ?? '',
        )
      : undefined;

    const partialContext = {
      amortizationSchedule,
      servicingAmortizationSchedule,
      amountToBeAdvanced: requestedMortgage.amountToBeAdvanced,
      servicingAmountToBeAdvanced: servicingMortgage?.amountToBeAdvanced,
      appLanguage: getAppLanguage(),
      applicants,
      application,
      apr,
      servicingApr,
      assignedUsers: this.makeAssignedUsers(application, users),
      atEndOfTermValues: this.makeAtEndOfTermValues(amortizationSchedule),
      servicingAtEndOfTermValues: this.makeAtEndOfTermValues(servicingAmortizationSchedule),
      borrowers: this.makeBorrowers(applicants),
      broker: broker,
      brokerName: `${broker?.name} ${broker?.surname}`,
      currentDate: new Date(),
      dayToPay: requestedMortgage.firstRegularPaymentDate
        ? new Date(requestedMortgage.firstRegularPaymentDate).getDate()
        : undefined,
      servicingDayToPay: servicingMortgage?.firstRegularPaymentDate
        ? new Date(servicingMortgage.firstRegularPaymentDate).getDate()
        : undefined,
      documentLanguage: LANGUAGES.find((x) => x.code === documentTemplate.languageCode),
      downPayments: downPaymentsList,
      existingAndRefinanceMortgages,
      fees: fees || [],
      financialAssets: this.makeFinancialAssets(financialAssets),
      fundmoreNarratives: fundmoreScore?.narratives,
      fundmoreRecommendation:
        FundmoreResultTypeRecord[fundmoreScore?.result || FundmoreResultType.Unknown],
      fundmoreResultNarratives: fundmoreScore?.resultNarrative,
      fundmoreScoreRecommendations: fundmoreScore?.recommendations,
      fund: funds.find((f) => f.id === application.fundId),
      gds,
      gdsDisplay: gds?.toFixed(2),
      guarantors: applicants?.filter((a) => a.customerType === CustomerType.GUARANTOR),
      incomes: incomesList,
      lawyers,
      liabilities: this.makeLiabilities(liabilities),
      ltv,
      ltvDisplay: ltv?.toFixed(2),
      mortgage: localRequestedMortgage,
      localServicingMortgage,
      notes,
      primaryApplicant: applicants?.find((applicant) => applicant.isPrimary),
      primaryProperty,
      primaryPropertyExistingAndRefinanceMortgages,
      properties,
      propertyAppraisal,
      requestedMortgage,
      servicingMortgage: {
        ...servicingMortgage,
        remainingTerm: servicingMortgageRemainingTerm,
        ...(application.purpose === ApplicationPurposeType.SERVICING_RENEWAL && {
          totalLoanAmount: servicingMortgage?.projectedBalance,
        }),
      },
      requestedMortgages,
      stakeholderLawyers,
      approvals,
      tds,
      tdsDisplay: tds?.toFixed(2),
      totalDownPayment: totalDownPayment.toFixed(2),
      totalIncome,
      totalPayment: this.amortizationScheduleService
        .getAmortizationSchedule(
          requestedMortgage,
          requestedMortgage.amountToBeAdvanced,
          calculationAutomationSettings,
          iadByFpdEnabled,
          takeFullFirstPayment,
        )
        .reduce((a, b) => a + b.payment, 0),
      servicingTotalPayment: servicingMortgage
        ? this.amortizationScheduleService
            .getAmortizationSchedule(
              servicingMortgage,
              servicingMortgage.amountToBeAdvanced,
              calculationAutomationSettings,
              iadByFpdEnabled,
              takeFullFirstPayment,
            )
            .reduce((a, b) => a + b.payment, 0)
        : undefined,
      firstMortgage,
      secondMortgage,
      thirdMortgage,
      lenderFee,
      brokerFee,
      otherFeesWithoutBrokerAndLender,
      shortAddressStreet,
      shortAddressDetails,
      isOnlyOneStakeholder,
      selectedStakeholder,
      selectedProperty,
      selectedStakeholderLawyer,
      selectedLawyer,
      summary,
      totalCostOfBorrowing,
      servicingTotalCostOfBorrowing,
    };

    const enhancedApplicants =
      applicants?.map((a) => ({
        ...a,
        properties:
          properties?.filter((p) =>
            (p.ApplicantProperties ?? []).some((b) => b.applicantId === a.id),
          ) ?? [],
        financialAssets: this.makeFinancialAssets(
          financialAssets?.filter((f) =>
            (f.ApplicantFinancialAssets ?? []).some((afa) => afa.applicantId === a.id),
          ),
        ),
        liabilities: liabilities?.filter(
          (l) =>
            (l.ApplicantFinancialLiabilities ?? []).some((afl) => afl.applicantId === a.id) ?? [],
        ),
      })) || [];

    const formattedApplicationConditions = this.formatApplicationConditions(
      applicationConditions,
      { ...partialContext, applicants: enhancedApplicants },
      stakeholders,
    );

    const documentContext: BaseDocumentContext = {
      ...partialContext,
      applicants:
        enhancedApplicants?.map((a) => ({
          ...a,
          applicationConditions: formattedApplicationConditions.filter(
            (c) => c.stakeholderId === a.id,
          ),
          income: incomesList.filter((income) => {
            if (
              [IncomeType.EMPLOYED, IncomeType.SELF_EMPLOYED, IncomeType.OTHER].includes(
                income.type,
              )
            ) {
              const job = income as EmployedIncome | SelfEmployedIncome;

              return job.stakeholderId === a.id;
            }

            const otherIncome = income as OtherIncome;

            return otherIncome.stakeholderIds?.includes(a.id);
          }),
        })) || [],
      applicationConditions: formattedApplicationConditions,
      conditions: this.conditionDisplayService.mapConditionNames(formattedApplicationConditions),
      selectedConditions: formattedApplicationConditions
        .map((x) => x.condition)
        .filter((x) => x !== undefined)
        .map((condition: Condition) => condition.text),
    };

    return documentContext;
  }

  public getLenderFee(fees: Fee[]) {
    return fees.find((x) => x.type === FeeType.LENDER);
  }

  public getBrokerFee(fees: Fee[]) {
    return fees.find((x) => x.type === FeeType.BROKER);
  }

  public getOtherFeesAmountWithoutBrokerAndLender(fees: Fee[]) {
    const otherFees = fees.filter((x) => x.type !== FeeType.BROKER && x.type !== FeeType.LENDER);
    const otherFeesSum = otherFees?.reduce((n, { amount }) => n + (amount ?? 0), 0);
    return otherFeesSum;
  }

  public getShortAddressStreet(property: Property) {
    const route = property.propertyAddressExpanded?.rawGoogleGeocodeResponse?.address_components
      .filter((x) => x.types.includes('route'))
      .map((x) => x.long_name)
      .toString();
    const streetNumber =
      property.propertyAddressExpanded?.rawGoogleGeocodeResponse?.address_components
        .filter((x) => x.types.includes('street_number'))
        .map((x) => x.long_name)
        .toString();
    return `${streetNumber ?? ''} ${route ?? ''}`.trim();
  }

  public getShortAddressWithoutStreet(property: Property) {
    const city = property.propertyAddressExpanded?.rawGoogleGeocodeResponse?.address_components
      .filter((x) => x.types.includes('locality'))
      .map((x) => x.long_name)
      .toString();
    const province = property.propertyAddressExpanded?.rawGoogleGeocodeResponse?.address_components
      .filter((x) => x.types.includes('administrative_area_level_1'))
      .map((x) => x.short_name)
      .toString();
    const postalCode =
      property.propertyAddressExpanded?.rawGoogleGeocodeResponse?.address_components
        .filter((x) => x.types.includes('postal_code'))
        .map((x) => x.short_name)
        .toString();
    return `${city ?? ''}, ${province ?? ''} ${postalCode ?? ''}`.trim();
  }

  private addPaymentsToMortgage(mortgage: Mortgage) {
    if (mortgage.monthlyPayment === undefined || mortgage.monthlyPayment === null) {
      return;
    }

    const acceleratedBiWeeklyPayment = FundmoreCalculator.paymentPerFrequency(
      PaymentFrequency.ACCELERATED_BI_WEEKLY,
      mortgage.monthlyPayment,
    );
    const acceleratedWeeklyPayment = FundmoreCalculator.paymentPerFrequency(
      PaymentFrequency.ACCELERATED_WEEKLY,
      mortgage.monthlyPayment,
    );
    const annualPayment = FundmoreCalculator.paymentPerFrequency(
      PaymentFrequency.ANNUALLY,
      mortgage.monthlyPayment,
    );
    const biWeeklyPayment = FundmoreCalculator.paymentPerFrequency(
      PaymentFrequency.BI_WEEKLY,
      mortgage.monthlyPayment,
    );
    const dailyPayment = FundmoreCalculator.paymentPerFrequency(
      PaymentFrequency.DAILY,
      mortgage.monthlyPayment,
    );
    const monthlyPayment = FundmoreCalculator.paymentPerFrequency(
      PaymentFrequency.MONTHLY,
      mortgage.monthlyPayment,
    );
    const quarterlyPayment = FundmoreCalculator.paymentPerFrequency(
      PaymentFrequency.QUARTERLY,
      mortgage.monthlyPayment,
    );
    const semiAnnuallyPayment = FundmoreCalculator.paymentPerFrequency(
      PaymentFrequency.SEMI_ANNUALLY,
      mortgage.monthlyPayment,
    );
    const semiMonthlyPayment = FundmoreCalculator.paymentPerFrequency(
      PaymentFrequency.SEMI_MONTHLY,
      mortgage.monthlyPayment,
    );
    const weeklyPayment = FundmoreCalculator.paymentPerFrequency(
      PaymentFrequency.WEEKLY,
      mortgage.monthlyPayment,
    );

    mortgage.payments = {
      [PaymentFrequency.ACCELERATED_BI_WEEKLY]: Math.round(acceleratedBiWeeklyPayment * 100) / 100,
      [PaymentFrequency.ACCELERATED_WEEKLY]: Math.round(acceleratedWeeklyPayment * 100) / 100,
      [PaymentFrequency.ANNUALLY]: Math.round(annualPayment * 100) / 100,
      [PaymentFrequency.BI_WEEKLY]: Math.round(biWeeklyPayment * 100) / 100,
      [PaymentFrequency.DAILY]: Math.round(dailyPayment * 100) / 100,
      [PaymentFrequency.MONTHLY]: Math.round(monthlyPayment * 100) / 100,
      [PaymentFrequency.QUARTERLY]: Math.round(quarterlyPayment * 100) / 100,
      [PaymentFrequency.SEMI_ANNUALLY]: Math.round(semiAnnuallyPayment * 100) / 100,
      [PaymentFrequency.SEMI_MONTHLY]: Math.round(semiMonthlyPayment * 100) / 100,
      [PaymentFrequency.WEEKLY]: Math.round(weeklyPayment * 100) / 100,
    };
  }

  private roundMortgageValues(mortgage: Mortgage) {
    mortgage.amortizationMonths = mortgage.amortizationMonths
      ? Math.ceil(mortgage.amortizationMonths)
      : null;
  }

  private makeAtEndOfTermValues(amortizationSchedule: AmortizationSchedule[]) {
    return {
      totalInterest: amortizationSchedule.reduce((a, b) => a + b.interest, 0),
      totalPrincipal: amortizationSchedule.reduce((a, b) => a + b.principal, 0),
      outstandingBalance: amortizationSchedule.reduce((a, b) => b.balance, 0),
      totalPayment: amortizationSchedule.reduce((a, b) => a + b.payment, 0),
    };
  }

  private makeAssignedUsers(application: Application, users: UserAccount[]) {
    const assignedUsersIds = (application.ApplicationAssignedUsers ?? []).map((a) => a.userId);
    const filteredUser = users.filter(
      (u) => assignedUsersIds.findIndex((a) => a === u.user.id) !== -1,
    );

    return filteredUser.map((f) => {
      return {
        name: f.user.firstName,
        surname: f.user.lastName,
        specialTitle: f.user.roles?.reduce(
          (acc, role) => `${role.name} ${acc ? ',' : ''} ${acc}`,
          '',
        ),
      };
    });
  }

  private makeBorrowers(applicants: Applicant[] | undefined) {
    return applicants?.filter(
      (a) =>
        a.customerType === CustomerType.CUSTOMER ||
        a.customerType === CustomerType.CO_BORROWER ||
        a.customerType === CustomerType.COMPANY,
    );
  }

  private makeFinancialAssets(financialAssets: FinancialAsset[] | undefined) {
    return {
      total: financialAssets?.reduce((a, b) => a + (b.value ?? 0), 0) ?? 0,
      assets: financialAssets ?? [],
    };
  }

  private makeLiabilities(liabilities: FinancialLiability[]) {
    const nonFilteredLiabilities = liabilities.filter((liability) => {
      // check if the liability is assigned to an applicant
      return !liability.ApplicantFinancialLiabilities.length;
    });

    return {
      nonFilteredLiabilities,
      totalBalance: nonFilteredLiabilities.reduce(
        (sum, liability) => sum + (liability.balance ?? 0),
        0,
      ),
      totalPayment: nonFilteredLiabilities.reduce(
        (sum, liability) => sum + (liability.monthlyPayment ?? 0),
        0,
      ),
    };
  }

  private formatApplicationConditions(
    applicationConditions: JoinedApplicationCondition[],
    documentContext: Partial<BaseDocumentContext>,
    stakeholders: Applicant[] | undefined,
  ): JoinedApplicationCondition[] {
    const result = [];
    for (const applicationCondition of applicationConditions) {
      if (!applicationCondition.condition) {
        result.push(applicationCondition);

        continue;
      }

      if (applicationCondition.condition.text?.includes(NEW_LINE_SEPARATOR)) {
        const duplicatedConditionTexts = this.getTextFragments(
          applicationCondition.condition.text,
          documentContext,
          applicationCondition,
        );
        const duplicatedConditionTextsFrench = this.getTextFragments(
          applicationCondition.condition.text_french,
          documentContext,
          applicationCondition,
        );

        if (duplicatedConditionTexts.length !== duplicatedConditionTextsFrench.length) {
          console.warn(
            $localize`French condition text must contain the same number of @newline decorators as the condition text!`,
          );
        }

        for (let i = 0; i < duplicatedConditionTexts.length; i++) {
          result.push(
            this.formatApplicationCondition(
              {
                ...applicationCondition,
                condition: {
                  ...applicationCondition.condition,
                  text: duplicatedConditionTexts[i],
                  text_french:
                    duplicatedConditionTextsFrench?.[i] ??
                    $localize`Matching french newline not found!`,
                },
              },
              documentContext,
              stakeholders,
            ),
          );
        }

        continue;
      }

      result.push(
        this.formatApplicationCondition(applicationCondition, documentContext, stakeholders),
      );
    }

    return result.sort((a, b) => {
      if (a?.condition?.index == b?.condition?.index) {
        if (a?.condition?.index == null) {
          return new Date(a?.createdAt ?? 0).getTime() > new Date(b?.createdAt ?? 0).getTime()
            ? 1
            : -1;
        }

        return 0;
      }

      if (a?.condition?.index == null) {
        return 1;
      }

      if (b?.condition?.index == null) {
        return -1;
      }

      return a.condition.index > b.condition.index ? 1 : -1;
    });
  }

  private getTextFragments(
    textToFrag: string,
    documentContext: Partial<BaseDocumentContext>,
    applicationCondition: JoinedApplicationCondition,
  ) {
    const compiledText = this.conditionDisplayService.assignValuesToObjects(textToFrag, {
      ...documentContext,
      currentCondition: applicationCondition,
    });

    const textFragments = compiledText.split(NEW_LINE_SEPARATOR);

    const [firstFragment] = textFragments.splice(0, 1);

    if (firstFragment.trim()) {
      return [firstFragment, ...textFragments];
    }

    return textFragments;
  }

  private formatApplicationCondition(
    applicationCondition: JoinedApplicationCondition,
    documentContext: Partial<BaseDocumentContext>,
    stakeholders: Applicant[] | undefined,
  ) {
    if (!applicationCondition.condition) {
      return applicationCondition;
    }

    return {
      ...applicationCondition,
      applicant:
        documentContext.applicants?.find((a) => a.id === applicationCondition.stakeholderId) ??
        stakeholders?.find((a) => a.id === applicationCondition.stakeholderId),
      condition: {
        ...applicationCondition.condition,
        text: this.conditionDisplayService.assignValuesToObjects(
          applicationCondition.condition.text,
          { ...documentContext, currentCondition: applicationCondition },
        ),
        text_french: this.conditionDisplayService.assignValuesToObjects(
          applicationCondition.condition.text_french,
          { ...documentContext, currentCondition: applicationCondition },
        ),
      },
    };
  }

  private getExistingMortgageOfType(
    positionType: string,
    primaryProperty: Property | undefined,
    mortgages: Mortgage[],
  ) {
    const primaryPropertyFirstMortgages = mortgages?.find(
      (m) =>
        m.propertyId === primaryProperty?.id &&
        m.type?.trim().toLowerCase() === MortgageType.EXISTING.toLowerCase() &&
        m.mortgageType?.trim().toLowerCase() === positionType.toString().toLowerCase(),
    );
    return primaryPropertyFirstMortgages;
  }
}
