import {
  Flip,
  FlipAnalysisModel,
  FlipCost,
  FlipRenovationSchedule,
  Mortgage,
  Property,
  Province,
  State,
  DownPayment,
} from '@fundmoreai/models';

export type FlipPropertyDetails = Pick<Property, 'purchasePrice' | 'estimatedValue'>;
export type FlipMortgageDetails = Pick<Mortgage, 'loanAmount' | 'netRate'>;

export class FlipGetApplicationFlip {
  static readonly type = '@flipState.getFlip';
  constructor(public applicationId: string) {}
}

export class FlipUpdateDetails {
  static readonly type = '@flipState.updateDetails';
  constructor(public flipId: string, public flipDetails: Partial<Flip>) {}
}

export class FlipComputeAnalysis {
  static readonly type = '@flipState.computeAnalysis';
  constructor(public analysisModel: Partial<FlipAnalysisModel>) {}
}

export class FlipSetFlipValidMsg {
  static readonly type = '@flipState.setFlipValidMsg';
  constructor(public message: string | undefined) {}
}

export class FlipHighlightInterestCostCalcValue {
  static readonly type = '@flipState.highlightInterestCostCalcValue';
  constructor(public highlight: boolean) {}
}

export class FlipSetSelectedMonthIndex {
  static readonly type = '@flipState.setSelectedMonthIndex';
  constructor(public index: number) {}
}

export class FlipResetFlipDefaults {
  static readonly type = '@flipState.resetFlipDefaults';
  constructor(public province: Province | State) {}
}

export class FlipSetEnableFlipModule {
  static readonly type = '@flipState.setEnableFlipModule';
  constructor(public enabled: boolean) {}
}

export class FlipSetFlipType {
  static readonly type = '@flipState.setFlipType';
  constructor(public filterNumber: number) {}
}

export class FlipSetDownPayments {
  static readonly type = '@flipState.setDownPayment';
  constructor(public downPayments: DownPayment[], public downPaymentsTotal?: number) {}
}

export class FlipAddFlipDownPayment {
  static readonly type = '@flipState.addFlipDownPayment';
  constructor(public downPaymentPayload: Partial<DownPayment>) {}
}

export class FlipAddApplicationDownPayments {
  static readonly type = '@flipState.addApplicationDownPayment';
  constructor(public downPayments: DownPayment[]) {}
}

export class FlipDeleteDownPayment {
  static readonly type = '@flipState.deleteApplicationDownPayment';
  constructor(public downPaymentId: string) {}
}
export class FlipUpdateDownPayment {
  static readonly type = '@flipState.updateApplicationDownPayment';
  constructor(public downPaymentId: string, public downPayment: DownPayment) {}
}

export class FlipUpdatePropertyFields {
  static readonly type = '@flipState.updatePropertyFields';
  constructor(public property: Partial<FlipPropertyDetails>) {}
}

export class FlipUpdateMortgageFields {
  static readonly type = '@flipState.updateMortgageFields';
  constructor(public mortgage: FlipMortgageDetails) {}
}

export class CreateFlipCost {
  static readonly type = '@flipCost.CreateFlipCost';
  constructor(public flipCost: Partial<FlipCost>) {}
}

export class UpdateFlipCost {
  static readonly type = '@flipCost.UpdateFlipCost';
  constructor(public flipCost: FlipCost) {}
}

export class DeleteFlipCost {
  static readonly type = '@flipCost.DeleteFlipCost';
  constructor(public flipCostId: string) {}
}

export class SetFlipCosts {
  static readonly type = '@flipCost.SetFlipCosts';
  constructor(public flipCosts: FlipCost[]) {}
}

export class CreateRenovationSchedule {
  static readonly type = '@flipRenovationScheduleState.CreateRenovationSchedule';
  constructor(public schedule: FlipRenovationSchedule) {}
}

export class UpdateRenovationSchedule {
  static readonly type = '@flipRenovationScheduleState.UpdateRenovationSchedule';
  constructor(public schedule: FlipRenovationSchedule) {}
}

export class DeleteRenovationSchedule {
  static readonly type = '@flipRenovationScheduleState.DeleteRenovationSchedule';
  constructor(public id: string) {}
}

export class SetRenovationSchedules {
  static readonly type = '@flipRenovationScheduleState.SetRenovationSchedules';
  constructor(public schedules: FlipRenovationSchedule[]) {}
}
