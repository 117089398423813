import { CompanySettingsModel } from './company-settings.model';

export class CompanySettingsLoaded {
  static readonly type = '@companySettings.CompanySettingsLoaded';
  constructor(public companySettings: CompanySettingsModel) {}
}

export class CompanySettingsResetLogos {
  static readonly type = '@companySettings.CompanySettingsResetLogos';
}

export class CompanySettingsResetIqLogos {
  static readonly type = '@companySettings.CompanySettingsResetIqLogos';
}

export class CompanySettingsResetStyle {
  static readonly type = '@companySettings.CompanySettingsResetStyle';
}

export class CompanySettingsResetIqStyle {
  static readonly type = '@companySettings.CompanySettingsResetIqStyle';
}

export class FetchAndSetCompanySettings {
  static readonly type = '@companySettings.fetchAndSetCompanySettings';
}

export class UpdateCompanySettings {
  static readonly type = '@companySettings.updateCompanySettings';
  constructor(public companySettings: Partial<CompanySettingsModel>) {}
}
