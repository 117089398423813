import {
  CompoundPeriod,
  Mortgage,
  PaymentFrequency,
  RepaymentType,
  assertUnreachable,
} from '@fundmoreai/models';

type ComputeMonthlyPaymentMortgageInput = Pick<
  Partial<Mortgage>,
  'compounding' | 'amortizationMonths' | 'monthlyPayment' | 'repaymentType' | 'paymentFrequency'
>;
export function computeMortgagePaymentAmount(
  requestedMortgage: ComputeMonthlyPaymentMortgageInput | undefined,
  totalLoanAmount: number | undefined | null,
  netRate: number | undefined | null,
): number | undefined {
  if (!requestedMortgage || !totalLoanAmount || !netRate || !requestedMortgage.paymentFrequency) {
    return undefined;
  }

  const compounding = extractCompoundingPeriod(requestedMortgage);
  const paymentFrequencyValue = extractPaymentFrequencyValue(requestedMortgage.paymentFrequency);
  const amortizationMonths = requestedMortgage.amortizationMonths || 0;
  const amortizationYears = amortizationMonths / 12;
  const originalPayment = requestedMortgage?.monthlyPayment ?? 0.0;
  const repaymentType = requestedMortgage?.repaymentType ?? RepaymentType.PRINCIPAL_AND_INTEREST;

  const paymentAmount = calculateMortgagePayment(
    netRate,
    compounding,
    paymentFrequencyValue,
    repaymentType,
    amortizationYears,
    originalPayment,
    totalLoanAmount,
  );

  return paymentAmount;
}

export function computeMortgageMonthlyPayment(
  requestedMortgage: ComputeMonthlyPaymentMortgageInput | undefined,
  totalLoanAmount: number | undefined | null,
  netRate: number | undefined | null,
): number | undefined {
  if (!requestedMortgage || !totalLoanAmount || !netRate) {
    return undefined;
  }

  const compounding = extractCompoundingPeriod(requestedMortgage);
  const paymentFrequencyValue = extractPaymentFrequencyValue(PaymentFrequency.MONTHLY);
  const amortizationMonths = requestedMortgage.amortizationMonths || 0;
  const amortizationYears = amortizationMonths / 12;
  const originalPayment = requestedMortgage?.monthlyPayment ?? 0.0;
  const repaymentType = requestedMortgage?.repaymentType ?? RepaymentType.PRINCIPAL_AND_INTEREST;

  const monthlyPayment = calculateMortgagePayment(
    netRate,
    compounding,
    paymentFrequencyValue,
    repaymentType,
    amortizationYears,
    originalPayment,
    totalLoanAmount,
  );

  return monthlyPayment;
}

type ExtractCompoundingPeriodMortgageInput = Pick<Partial<Mortgage>, 'compounding'>;
export function extractCompoundingPeriod(mortgage: ExtractCompoundingPeriodMortgageInput): number {
  const compoundingPeriod = mortgage?.compounding;
  const compounding = compoundingPeriod ? compoundingMap.get(compoundingPeriod) : undefined;
  return compounding ? compounding : 2.0;
}
export function extractPaymentFrequencyValue(paymentFrequency: PaymentFrequency): number {
  if (
    paymentFrequency === PaymentFrequency.ACCELERATED_BI_WEEKLY ||
    paymentFrequency === PaymentFrequency.ACCELERATED_WEEKLY
  ) {
    return paymentFrequencyMap.get(PaymentFrequency.MONTHLY) as number;
  }

  return paymentFrequencyMap.get(paymentFrequency) as number;
}
export function calculateMortgagePayment(
  originalInterestRate: number,
  compounding: number,
  paymentFrequencyValue: number,
  repaymentType: RepaymentType,
  amortizationYears: number,
  originalPayment: number,
  totalLoanAmount: number | undefined,
): number | undefined {
  if (!totalLoanAmount) {
    return undefined;
  }

  let interestRate = 0.0;
  let payment = originalPayment;

  if (originalInterestRate) {
    if (repaymentType === RepaymentType.INTEREST_ONLY || !amortizationYears) {
      interestRate = originalInterestRate / 100 / paymentFrequencyValue;
      payment = totalLoanAmount * interestRate;
    } else {
      if (compounding) {
        interestRate = Math.pow(
          1 + originalInterestRate / 100 / compounding,
          compounding / paymentFrequencyValue,
        );
        payment =
          (totalLoanAmount * (interestRate - 1)) /
          (1 - Math.pow(interestRate, -(amortizationYears * paymentFrequencyValue)));
      }
    }
  }

  if (payment) {
    return Math.round(payment * 100) / 100;
  } else {
    return undefined;
  }
}

export const compoundingMap: Map<CompoundPeriod, number> = new Map([
  [CompoundPeriod.ANNUALLY, 1],
  [CompoundPeriod.BI_WEEKLY, 26],
  [CompoundPeriod.DAILY, 365],
  [CompoundPeriod.MONTHLY, 12],
  [CompoundPeriod.QUARTERLY, 4],
  [CompoundPeriod.SEMI_ANNUAL, 2],
  [CompoundPeriod.SEMI_MONTHLY, 24],
  [CompoundPeriod.WEEKLY, 52],
  [CompoundPeriod.SIMPLE_INTEREST, -1],
]);

export const paymentFrequencyMap: Map<PaymentFrequency, number> = new Map([
  [PaymentFrequency.MONTHLY, 12],
  [PaymentFrequency.ANNUALLY, 1],
  [PaymentFrequency.DAILY, 365],
  [PaymentFrequency.QUARTERLY, 4],
  [PaymentFrequency.SEMI_ANNUALLY, 2],
  [PaymentFrequency.SEMI_MONTHLY, 24],
  [PaymentFrequency.BI_WEEKLY, 26],
  [PaymentFrequency.WEEKLY, 52],
  [PaymentFrequency.ACCELERATED_BI_WEEKLY, 26],
  [PaymentFrequency.ACCELERATED_WEEKLY, 52],
]);

export function getMonthlyPaymentByFrequency(
  paymentFrequency: PaymentFrequency,
  paymentAmount: number,
) {
  switch (paymentFrequency) {
    case PaymentFrequency.SEMI_MONTHLY:
      return (paymentAmount * 24) / 12;
    case PaymentFrequency.BI_WEEKLY:
      return (paymentAmount * 26) / 12;
    case PaymentFrequency.ACCELERATED_BI_WEEKLY:
      return (paymentAmount * 26) / 13;
    case PaymentFrequency.WEEKLY:
      return (paymentAmount * 52) / 12;
    case PaymentFrequency.ACCELERATED_WEEKLY:
      return (paymentAmount * 52) / 13;
    case PaymentFrequency.MONTHLY:
      return paymentAmount;
    case PaymentFrequency.ANNUALLY:
      return paymentAmount / 12;
    case PaymentFrequency.DAILY:
      return (paymentAmount * 365) / 12;
    case PaymentFrequency.QUARTERLY:
      return (paymentAmount * 4) / 12;
    case PaymentFrequency.SEMI_ANNUALLY:
      return (paymentAmount * 2) / 12;
    default:
      assertUnreachable(paymentFrequency, 'Unhandled PaymentFrequency');
      return paymentAmount;
  }
}
