import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PropertyAppraisal } from '../../shared/model';

type BackendPropertyAppraisal = Omit<
  PropertyAppraisal,
  'appraisalDate' | 'createdAt' | 'updatedAt'
> & {
  appraisalDate: string;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: 'root',
})
export class PropertyAppraisalsService {
  constructor(private http: HttpClient) {}

  private toClientAppraisal(backendPropertyAppraisal: BackendPropertyAppraisal): PropertyAppraisal {
    return {
      ...backendPropertyAppraisal,
      createdAt: new Date(backendPropertyAppraisal.appraisalDate),
      updatedAt: new Date(backendPropertyAppraisal.appraisalDate),
    };
  }

  postAppraisal(
    propertyId: string,
    applicationId: string,
    appraisal: PropertyAppraisal,
  ): Observable<PropertyAppraisal> {
    return this.http
      .post<BackendPropertyAppraisal>(
        `${environment.api_url}/properties/${propertyId}/appraisals`,
        { ...appraisal, applicationId },
      )
      .pipe(map((appraisal) => this.toClientAppraisal(appraisal)));
  }

  patchAppraisal(
    propertyId: string,
    appraisalId: string,
    appraisal: PropertyAppraisal,
  ): Observable<PropertyAppraisal> {
    return this.http
      .patch<BackendPropertyAppraisal>(
        `${environment.api_url}/properties/${propertyId}/appraisals/${appraisalId}`,
        appraisal,
      )
      .pipe(map((appraisal) => this.toClientAppraisal(appraisal)));
  }

  setDefaultAppraisal(propertyId: string, appraisal: PropertyAppraisal): Observable<void> {
    return this.http.post<void>(
      `${environment.api_url}/properties/${propertyId}/appraisals/setPropertyAppraisal`,
      appraisal,
    );
  }

  deleteAppraisal(propertyId: string, appraisalId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.api_url}/properties/${propertyId}/appraisals/${appraisalId}`,
    );
  }

  fetchPropertiesAppraisals(applicationId: string): Observable<PropertyAppraisal[]> {
    return this.http
      .get<PropertyAppraisal[]>(`${environment.api_url}/properties/appraisals`, {
        params: { applicationId },
      })
      .pipe(
        map((appraisals) => {
          appraisals?.forEach((appraisal) => {
            appraisal.createdAt = new Date(appraisal.createdAt);
            appraisal.updatedAt = new Date(appraisal.updatedAt);
          });
          return appraisals ?? [];
        }),
      );
  }

  fetchAppraisalData(appraisalId: string): Observable<PropertyAppraisal> {
    return this.http
      .get<PropertyAppraisal>(`${environment.api_url}/properties/appraisals/${appraisalId}`)
      .pipe(
        map((appraisal) => {
          appraisal.createdAt = new Date(appraisal.createdAt);
          appraisal.updatedAt = new Date(appraisal.updatedAt);
          return appraisal;
        }),
      );
  }
}
