export const MfaStrategies = {
  SMS: 'SMS_MFA',
  TOTP: 'SOFTWARE_TOKEN_MFA',
};

export const DateFormats = {
  ISO_WITHOUT_TIMEZONE: 'yyyy-MM-dd',
};

export const RegexEscapeCharacters = /[.*+?^${}()|[\]\\]/g;

export const DebouncePeriods = {
  LONG: 500,
  SHORT: 200,
};

export const STORE_ACTIONS_QUEUE_DELAY = 100;
